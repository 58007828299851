import { Instance } from 'mobx-state-tree';

import { getCurrencyFormat } from '@shared-util/get-currency-format';

import { AccountTypeUnion } from '@model/account-model/account-model.type';

export const getClientAdministrationOptions = (ccAccounts: Instance<typeof AccountTypeUnion>[]) =>
    ccAccounts.map(account => ({
        label: `${account.currency.currency} (${getCurrencyFormat(account.balance, account.currency.code)})`,
        value: account.id,
    }));
