import { observer } from 'mobx-react-lite';
import React from 'react';

import { BackButton } from '@shared-atom/back-button/back-button';
import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { PrivateDocumentForm } from '@shared-form/private-document-form/private-document-form';
import { usePrivateDocumentForm } from '@shared-form/private-document-form/private-document-form.hook';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { ButtonWrapper } from '@page/private-client-profile/private-client-profile.styles';
import { usePrivateClientActionsSelector } from '@selector/private-client/use-private-client.actions-selector';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const PrivateClientProfileDocuments = observer(() => {
    const { params } = useCurrentNavigation();
    const [{ documents }] = usePrivateClientSelector();
    const { updatePrivateClientDocuments } = usePrivateClientActionsSelector();

    const documentForm = usePrivateDocumentForm(documents, data => updatePrivateClientDocuments(params.userId, data));

    const shouldRenderUpdate = documentForm.selectedUploadType !== null;

    return (
        <PrivateDocumentForm documentForm={documentForm} hasOtherTypeDocuments>
            <ButtonWrapper>
                {!shouldRenderUpdate && (
                    <ButtonPrimary type={ButtonTypeEnum.Submit} disabled={documentForm.isSubmitDisabled}>
                        Update documents
                    </ButtonPrimary>
                )}
                {shouldRenderUpdate && (
                    <BackButton onClick={documentForm.handleSelectedUploadType(null)}>
                        <ButtonPrimary
                            onClick={documentForm.handleSelectedUploadType(null)}
                            disabled={documentForm.isSubmitDisabled}
                        >
                            Continue
                        </ButtonPrimary>
                    </BackButton>
                )}
            </ButtonWrapper>
        </PrivateDocumentForm>
    );
});
