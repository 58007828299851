import { MutableRefObject, useEffect } from 'react';
import {
    TableState,
    useExpanded,
    useFlexLayout,
    usePagination,
    useRowSelect,
    useTable,
    UseTableOptions,
} from 'react-table';

import { Translate } from '@shared-atom/translate/translate';
import { AnimationLoading } from '@shared-component/animation/animation-loading/animation-loading';
import { Scroll } from '@shared-component/scroll/scroll';
import { TableBody } from '@shared-component/table/table-body/table-body';
import { TableRowInterface } from '@shared-component/table/table-body/table-row/table-row';
import { TableHead } from '@shared-component/table/table-head/table-head';
import { TablePagination } from '@shared-component/table/table-pagination/table-pagination';
import { TableHookInterface, TableInterface } from '@shared-component/table/table.interface';
import {
    EmptyTableContainer,
    EmptyTableText,
    Footer,
    Header,
    HeaderContainer,
    RootWrapper,
    Table,
    Title,
} from '@shared-component/table/table.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

export const TableComponent = <T extends Record<string, any>>({
    data,
    totalData,
    limitRowsPerPage,
    columns,
    title,
    header,
    footer,
    row,
    children,
    toggleableRow,
    emptyTable,
    tableRef,
    hasTableHead = false,
    isDataLoading = false,
    hasInnerScroll = false,
    hasPagination = false,
    pageIndex: initialPageIndex,
    pageCount: initialPageCount,
    onPageChange,
    getTableInstance,
}: TableInterface<T>) => {
    const tableInstanceState = useTable(
        {
            columns,
            data,
            totalData,
            limitRowsPerPage,
            manualPagination: true,
            initialState: { pageIndex: initialPageIndex ?? 0 } as Partial<TableState<T>>,
            pageCount: initialPageCount,
        } as UseTableOptions<T>,
        useExpanded,
        usePagination,
        useRowSelect,
        useFlexLayout
    ) as TableHookInterface<T>;
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        page,
        prepareRow,
        state: { pageIndex },
        pageCount,
        gotoPage,
        toggleAllRowsExpanded,
    } = tableInstanceState;

    useEffect(() => void (onPageChange && onPageChange(pageIndex)), [pageIndex]);

    useEffect(
        () => void (getTableInstance && getTableInstance(tableInstanceState)),
        [getTableInstance, tableInstanceState]
    );

    useEffect(
        () => void (initialPageIndex !== undefined && pageIndex !== initialPageIndex && gotoPage(initialPageIndex)),
        [initialPageIndex]
    );

    const shouldRenderTable = data.length > 0;

    const childrenArray = Array.isArray(children) || typeof children === 'function' ? children : [children];
    const tableBodyData = hasPagination ? page : rows;

    const shouldRenderHeaderContainer = isExist(header);
    const shouldRenderFooterContainer = isExist(footer);
    const shouldRenderTitle = title !== undefined;
    const shouldRenderPagination = hasPagination && pageCount > 1;
    const shouldRenderHeader = shouldRenderHeaderContainer || shouldRenderTitle;
    const shouldRenderFooter = shouldRenderFooterContainer || shouldRenderPagination;
    const shouldRenderInnerScroll = hasInnerScroll && shouldRenderTable;
    const shouldRenderTableBody = !hasInnerScroll && shouldRenderTable;

    return (
        <RootWrapper>
            {shouldRenderHeader && (
                <Header>
                    {shouldRenderTitle && (
                        <Title>
                            <Translate langKey={title as LocalizationEnum} />
                        </Title>
                    )}
                    {shouldRenderHeaderContainer && (
                        <HeaderContainer>
                            {typeof header === 'function' ? header(tableInstanceState) : header}
                        </HeaderContainer>
                    )}
                </Header>
            )}

            <Table ref={tableRef as unknown as MutableRefObject<HTMLTableElement>} {...getTableProps()}>
                {hasTableHead && <TableHead headerGroups={headerGroups} />}
                {isDataLoading ? (
                    <EmptyTableContainer>
                        <AnimationLoading />
                    </EmptyTableContainer>
                ) : (
                    <>
                        {!shouldRenderTable && (
                            <EmptyTableContainer>
                                {emptyTable ?? (
                                    <EmptyTableText>
                                        <Translate langKey={LocalizationEnum.CommonTableEmptyTitle} />
                                    </EmptyTableText>
                                )}
                            </EmptyTableContainer>
                        )}
                        {shouldRenderInnerScroll && (
                            <Scroll>
                                <TableBody
                                    getTableBodyProps={getTableBodyProps}
                                    prepareRow={prepareRow as unknown as OnEventType<TableRowInterface<T>>}
                                    rows={tableBodyData as unknown as TableRowInterface<T>[]}
                                    toggleableRow={toggleableRow}
                                    rowWrapper={row}
                                    toggleAllRowsExpanded={toggleAllRowsExpanded}
                                >
                                    {childrenArray}
                                </TableBody>
                            </Scroll>
                        )}
                        {shouldRenderTableBody && (
                            <TableBody
                                getTableBodyProps={getTableBodyProps}
                                prepareRow={prepareRow as unknown as OnEventType<TableRowInterface<T>>}
                                rows={tableBodyData as unknown as TableRowInterface<T>[]}
                                toggleableRow={toggleableRow}
                                rowWrapper={row}
                                toggleAllRowsExpanded={toggleAllRowsExpanded}
                            >
                                {childrenArray}
                            </TableBody>
                        )}
                    </>
                )}
            </Table>

            {shouldRenderFooter && (
                <Footer>
                    {shouldRenderFooterContainer && footer}
                    {shouldRenderPagination && <TablePagination tableInstanceState={tableInstanceState} />}
                </Footer>
            )}
        </RootWrapper>
    );
};
