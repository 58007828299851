import { parse } from 'date-fns';

import { isTrue } from '@shared-util/is-data';

import { TransactionsTableEnum } from '@page/transactions/transactions-table/transactions-table.enum';

export const getFilters = ({ createdTo, createdFrom, limit, offset, search, elcoinStatus }: Record<string, any>) => ({
    limit,
    offset,
    paymentDate: {
        gte: isTrue(createdFrom) ? parse(createdFrom, 'dd-MM-yyyy', new Date()) : null,
        lte: isTrue(createdTo) ? parse(createdTo, 'dd-MM-yyyy', new Date()) : null,
    },
    search,
    elcoinStatus: isTrue(elcoinStatus) && elcoinStatus !== TransactionsTableEnum.All ? elcoinStatus : null,
});
