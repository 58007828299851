import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useLegalServiceFormLocalization = () => {
    const eurTitle = useLocalizationText(LocalizationEnum.LegalServiceEuroAccountTitle);
    const swiftTitle = useLocalizationText(LocalizationEnum.LegalServiceSwiftAccountTitle);
    const multicurrencyTitle = useLocalizationText(LocalizationEnum.LegalServiceMulticurrancyAccountTitle);
    const currencyTitle = useLocalizationText(LocalizationEnum.PrivateServiceCurrencyTitle);
    const moneyTitle = useLocalizationText(LocalizationEnum.PrivateServiceMoneyTurnoverTitle);

    return {
        eurTitle,
        swiftTitle,
        multicurrencyTitle,
        currencyTitle,
        moneyTitle,
    };
};
