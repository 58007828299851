import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Red, SelectiveYellow, GreenHaze } from '@shared-style/colors';

const ButtonView = styled.p`
    width: 100%;
    min-width: 160px;
    padding: 8px;
    border-radius: 8px;
    ${Typography.Center.White.Style}
`;

export const ButtonColor = styled(ButtonView)`
    background-color: ${GreenHaze};
`;

export const ButtonColorStroke = styled(ButtonView)`
    background-color: transparent;
    border: 1px solid ${GreenHaze};
    color: ${GreenHaze};
`;

export const ButtonSendCompliance = styled(ButtonView)`
    background-color: ${SelectiveYellow};
`;

export const ButtonReject = styled(ButtonView)`
    background-color: ${Red};
`;
