// import { Instance, flow, getParent } from 'mobx-state-tree';
//
// import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';
//
// import { CurrenciesModel } from '@model/currencies-model/currencies-model';
//
// interface CurrencyProps {
//     code: string;
//     isActive: boolean;
// }

export const currencyModelMainActions = () =>
    // self: Instance<typeof CurrencyModelType>
    ({
        // updateCurrency: flow(function* updateCurrency(currency: CurrencyProps) {
        //     const currenciesInstanceModel = getParent(getParent(self)) as Instance<typeof CurrenciesModel>;
        //
        //     if (currenciesInstanceModel.loading.isLoading) {
        //         return;
        //     }
        //
        //     currenciesInstanceModel.setLoading(true);
        //
        //     try {
        //         const result = yield mutateCurrency(currency);
        //
        //         if (result.status as boolean) {
        //             self.isActive = currency.isActive;
        //         }
        //     } catch (error) {
        //         throw new Error(error);
        //     } finally {
        //         currenciesInstanceModel.setLoading(false);
        //     }
        // }),
    });
