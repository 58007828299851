import { observer } from 'mobx-react-lite';

import { TableComponent } from '@shared-component/table/table';
import { Cell } from '@shared-component/table/table-cell/table-cell';

import { ActionsCell } from './cells/actions-cell';
import { StatusCell } from './cells/status-cell';
import { ClientsTableColumns } from './currencies-table-columns';
import { useCurrenciesTable } from './currencies-table.hook';
import { TableWrapper } from './currencies-table.styles';

export const CurrenciesTable = observer(() => {
    const [{ currencies }, isLoading] = useCurrenciesTable();

    return (
        <TableWrapper>
            <TableComponent
                data={currencies}
                columns={ClientsTableColumns}
                isDataLoading={isLoading}
                hasTableHead
                hasInnerScroll
                hasPagination
            >
                <Cell />
                <Cell />
                <Cell />
                <Cell>{StatusCell}</Cell>
                <Cell>{ActionsCell}</Cell>
            </TableComponent>
        </TableWrapper>
    );
});
