import { values } from 'mobx';
import { Instance, types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserActivatedStatus, UserType } from '@shared-graphql';
import { AddressType } from '@shared-model/address-type/address.type';
import { CommentsModelType } from '@shared-model/comment-model/comments-model';
import { UserDeclarationType } from '@shared-model/user-model/user-model-private/user-declaration.type';
import { UserDocumentsType } from '@shared-model/user-model/user-model-private/user-documents.type';
import { UserPrivateStatusModel } from '@shared-model/user-model/user-model-private/user-private-status-model';
import { UserProfileType } from '@shared-model/user-model/user-model-private/user-profile/user-profile.type';
import { UserServiceType } from '@shared-model/user-model/user-model-private/user-service.type';

import { initialPrivateClient } from '@model/private-client-model/private-client-model-initials/private-client-model.initial';

import { privateClientAddressActions } from './private-client-actions/private-client-address.actions';
import {
    loadPrivateClientCommentsActions,
    addPrivateClientCommentsActions,
} from './private-client-actions/private-client-comments.actions';
import { privateClientDeclarationActions } from './private-client-actions/private-client-declaration.actions';
import { privateClientDocumentsActions } from './private-client-actions/private-client-documents.actions';
import { privateClientProfileActions } from './private-client-actions/private-client-profile.actions';
import { privateClientServiceActions } from './private-client-actions/private-client-service.actions';
import { PrivateClientStatusesType } from './private-client-statuses.type';

const userActivatedStatusValues = $enum(UserActivatedStatus).getValues();

export const PrivateClientTypes = types.model({
    id: types.string,
    email: types.string,
    elcoinId: types.maybeNull(types.string),
    phone: types.string,
    type: types.maybeNull(types.enumeration([UserType.Private, UserType.Legal])),
    status: types.enumeration(userActivatedStatusValues),
    statuses: types.array(PrivateClientStatusesType),
    privateStatus: UserPrivateStatusModel,
    profile: types.maybeNull(UserProfileType),
    address: types.maybeNull(AddressType),
    service: types.maybeNull(UserServiceType),
    declaration: types.maybeNull(UserDeclarationType),
    documents: types.array(UserDocumentsType),
    isAcceptAgreement: types.maybeNull(types.boolean),
    comment: types.optional(
        types.model({
            elements: types.map(CommentsModelType),
        }),
        initialPrivateClient.comment
    ),
});

export const PrivateClientTypesModel = PrivateClientTypes.actions(privateClientProfileActions)
    .actions(privateClientAddressActions)
    .actions(privateClientServiceActions)
    .actions(privateClientDocumentsActions)
    .actions(privateClientDeclarationActions)
    .actions(loadPrivateClientCommentsActions)
    .actions(addPrivateClientCommentsActions)
    .views(self => ({
        get fullName() {
            return self.profile !== null ? `${self.profile.name} ${self.profile.lastName}`.trim() : '';
        },
        get comments() {
            return values(self.comment.elements) as unknown as Instance<typeof CommentsModelType>[];
        },
    }));
