import styled from 'styled-components';

import Checked from '@shared-atom/choose/assets/checked.svg?react';
import { Typography } from '@shared-atom/typography/typography';
import { JungleGreen } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const BlockDescription = styled.span`
    padding-top: 8px;
    ${Typography.S.Normal.Left.Raven.Style}
`;

export const Wrapper = styled.div`
    flex: 1;
    margin-left: 8px;

    ${Flex.Column.Style}
`;

export const BlockTitle = styled.span`
    ${Typography.M.Medium.Left.Dark.Style}
`;

export const CheckedIcon = styled(Checked)`
    fill: ${JungleGreen};
`;
