import { Instance } from 'mobx-state-tree';

import { CompanyModelInstance } from '@model/company-model/company-model';

export const companyModelActions = (self: Instance<typeof CompanyModelInstance>) => ({
    setCompanyLoading: (loading: boolean) => {
        self.loading.isCompanyLoading = loading;
    },
    setCompanyAddressLoading: (loading: boolean) => {
        self.loading.isCompanyAddressLoading = loading;
    },
    setCompanyPersonsLoading: (loading: boolean) => {
        self.loading.isCompanyPersonsLoading = loading;
    },
    setCompanyDocumentsLoading: (loading: boolean) => {
        self.loading.isCompanyDocumentsLoading = loading;
    },
    setCompanyServiceLoading: (loading: boolean) => {
        self.loading.isCompanyServiceLoading = loading;
    },
});
