import React from 'react';

import { HeaderWrapper, PageTitle } from '@page/common/common.styles';

import { CreateClient } from './create-client';
import { PageWrapper, CreateClientWrapper } from './create-client.styles';

export const CreateClientPage = () => (
    <>
        <HeaderWrapper>
            <PageTitle>Create client</PageTitle>
        </HeaderWrapper>
        <PageWrapper>
            <CreateClientWrapper>
                <CreateClient />
            </CreateClientWrapper>
        </PageWrapper>
    </>
);
