import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { DarkBlue, Geyser, Mystic, WhiteDark } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

import Statement from './assets/statement.svg?react';

export const Header = styled.div`
    margin: 0 32px;
    box-shadow: 0 1px 0 ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? WhiteDark : Mystic)};

    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const StatementIcon = styled(Statement)`
    fill: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? Geyser : DarkBlue)};
`;

export const ButtonTitle = styled.span`
    padding-left: 8px;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? Geyser : DarkBlue)};
    ${Typography.S.Medium.Style};
`;
