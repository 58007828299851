import { useLazyQuery, useMutation } from '@apollo/client';
import { useContext, useEffect, useMemo, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import {
    AdminGetClientFeatureTags,
    AdminNextStatusFeatureTag,
    AdminRejectFeatureTag,
    DefaultAnswer,
    FeatureStatus,
    Maybe,
    Mutation,
    MutationAdminRejectFeatureTagArgs,
    Query,
    UserFeatureTag,
} from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { isTrue } from '@shared-util/is-data';

export const useClientServices = () => {
    const { params } = useCurrentNavigation();
    const { onOpen: onInfoModalOpen } = useContext(InformationModalContext);
    const { onOpen: onModalOpen } = useContext(ModelContext);
    const clientId = useMemo(() => (isTrue(params.userId) ? params.userId : params.companyId), [params]);

    const [featureTags, setFeatureTags] = useState<Array<Maybe<FeatureStatus>>>([]);
    const [adminGetClientFeatureTags, { data: getClientFeatureTagsData, loading: isLoading }] = useLazyQuery<
    Pick<Query, 'adminGetClientFeatureTags'>
    >(AdminGetClientFeatureTags, { variables: { clientId } });

    const [
        adminRejectFeatureTag,
        { data: rejectFeatureTagData, loading: isRejectFeatureTagLoading, error: rejectFeatureTagError },
    ] = useMutation<Pick<Mutation, 'adminRejectFeatureTag'>>(AdminRejectFeatureTag);

    const [
        adminNextStatusFeatureTag,
        { data: nextStatusFeatureTagData, loading: isNextStatusFeatureTagLoading, error: nextStatusFeatureTagError },
    ] = useMutation<Pick<Mutation, 'adminNextStatusFeatureTag'>>(AdminNextStatusFeatureTag);

    const onRejectFeatureTag = ({
        featureTag,
        status,
    }: Pick<MutationAdminRejectFeatureTagArgs, 'featureTag' | 'status'>) => {
        adminRejectFeatureTag({ variables: { clientId, featureTag, status } });
    };

    const onNextStatusFeatureTag = (userFeatureTag: UserFeatureTag) => {
        adminNextStatusFeatureTag({ variables: { clientId, userFeatureTag } });
    };

    const onSetRequiredFields = (featureTag: FeatureStatus) => {
        onModalOpen(ModalEnum.AddServiceRequiredFieldsModal, {
            componentProps: { featureTag, adminGetClientFeatureTags },
        });
    };

    useEffect(() => {
        adminGetClientFeatureTags();
    }, []);

    useEffect(() => {
        if (getClientFeatureTagsData?.adminGetClientFeatureTags) {
            setFeatureTags(getClientFeatureTagsData?.adminGetClientFeatureTags);
        }
    }, [getClientFeatureTagsData]);

    useEffect(() => {
        if (isTrue((rejectFeatureTagData?.adminRejectFeatureTag as DefaultAnswer)?.status)) {
            adminGetClientFeatureTags();
        }
    }, [rejectFeatureTagData]);

    useEffect(() => {
        if (isTrue((nextStatusFeatureTagData?.adminNextStatusFeatureTag as DefaultAnswer)?.status)) {
            adminGetClientFeatureTags();
        }
    }, [nextStatusFeatureTagData]);

    useEffect(() => {
        if (isTrue(nextStatusFeatureTagError?.message)) {
            onInfoModalOpen(InformationModalEnum.FailureInformationModal, {
                text: nextStatusFeatureTagError?.message,
                timeout: 6000,
            });
        }
    }, [nextStatusFeatureTagError]);

    useEffect(() => {
        if (isTrue(rejectFeatureTagError?.message)) {
            onInfoModalOpen(InformationModalEnum.FailureInformationModal, {
                text: rejectFeatureTagError?.message,
            });
        }
    }, [rejectFeatureTagError]);

    return {
        isLoading,
        featureTags,
        isRejectFeatureTagLoading,
        isNextStatusFeatureTagLoading,
        onRejectFeatureTag,
        onNextStatusFeatureTag,
        onSetRequiredFields,
        adminGetClientFeatureTags,
    };
};
