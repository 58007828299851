import { useMemo, useState } from 'react';

import { OperationTypeEnum } from '@page/transactions/transactions-table/transactions-table.enum';

export const useOperationTypeFilter = (initialOperationType: OperationTypeEnum | string) => {
    const [operationType, setOperationType] = useState<OperationTypeEnum | string>(initialOperationType);

    const onOperationTypeChange = (newOperationType: OperationTypeEnum) => setOperationType(newOperationType);

    return useMemo(() => [{ operationType }, { onOperationTypeChange }, {}], [operationType]);
};
