import { useMemo } from 'react';

import { UserFeatureTag, UserType } from '@shared-graphql';

export const useServicesByUserType = (userType: UserType) => {
    const allowedFeaturesByTypeMapping = useMemo(
        () => ({
            [UserType.Legal]: [
                UserFeatureTag.acquiring,
                UserFeatureTag.iban,
                UserFeatureTag.gbpSepaRequisites,
                UserFeatureTag.exchange,
            ],
            [UserType.Private]: [UserFeatureTag.iban, UserFeatureTag.gbpSepaRequisites, UserFeatureTag.exchange],
        }),
        []
    );

    return {
        allowedFeaturesByTypeMapping: allowedFeaturesByTypeMapping[userType],
        options: allowedFeaturesByTypeMapping[userType].map(value => ({ value, label: value })),
    };
};
