import { flow, getParent, Instance } from 'mobx-state-tree';

import {
    AdminCompanyActivatedStatusUpdate,
    CompanyActivatedStatus,
    AdminGetCompanyStatuses,
    Mutation,
    Query,
    UserCompany,
} from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { CompanyModel } from '@model/company-model/company-model';
import { companyApplyActions } from '@model/company-model/company-model-actions/company-apply.actions ';
import { CompanyModelType } from '@model/company-model/company-model.type';

interface CompanyStatusActionsInterface
    extends Instance<typeof CompanyModelType>,
    ReturnType<typeof companyApplyActions> {}

const mutationCompanyStatus = (companyId: string, status: CompanyActivatedStatus) =>
    apolloClient
        .query<Pick<Mutation, 'adminCompanyActivatedStatusUpdate'>>({
        query: AdminCompanyActivatedStatusUpdate,
        variables: { companyId, status },
    })
        .then(result => result.data.adminCompanyActivatedStatusUpdate);

const queryCompanyStatuses = (companyId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetCompany'>>({ query: AdminGetCompanyStatuses, variables: { companyId } })
        .then(result => (result.data.adminGetCompany as UserCompany).statuses);

export const companyStatusesUpdateActions = (self: CompanyStatusActionsInterface) => ({
    changeCompanyStatus: flow(function* changeClientStatus(companyId: string, status: CompanyActivatedStatus) {
        const companyModel = getParent(self) as Instance<typeof CompanyModel>;

        if (companyModel.loading.isCompanyLoading) {
            return;
        }

        companyModel.setCompanyLoading(true);

        try {
            const result = yield mutationCompanyStatus(companyId, status);

            if (result.status as boolean) {
                self.status = status;
                self.statuses = yield queryCompanyStatuses(companyId);
            }
        } catch (error) {
            throw new Error(error as string);
        } finally {
            companyModel.setCompanyLoading(false);
        }
    }),
});

export const companyStatusUpdateActions = (self: CompanyStatusActionsInterface) => ({
    updateCompanyStatus: (status: CompanyActivatedStatus) => {
        try {
            if (self.status === status) {
                return;
            }

            (self as unknown as ReturnType<typeof companyStatusesUpdateActions>).changeCompanyStatus(self.id, status);
        } catch (error) {
            throw new Error(error as string);
        }
    },
});
