import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { isString } from '@shared-util/is-data';

import { Breadcrumbs } from '@component/breadcrumbs/breadcrumbs';
import { Tabs } from '@component/tabs/tabs';
import { PageEnum } from '@enum/page.enum';
import { HeaderWrapper, PageTitle, BreadCrumbsWrapper } from '@page/common/common.styles';
import { useCompanyActionsSelector } from '@selector/company/use-company.actions-selector';
import { useCompanySelector } from '@selector/company/use-company.selector';

import { legalClientTabOptions, breadcrumbList } from './legal-client-profile.options';
import { LegalClientProfileInnerWrapper, LegalClientProfileWrapper } from './legal-client-profile.styles';

export const LegalClientProfilePage = observer(() => {
    const { params } = useCurrentNavigation();
    const [{ id }] = useCompanySelector();
    const { loadCompany } = useCompanyActionsSelector();
    const navigate = useRouterNavigate();

    useEffect(() => {
        if (isString(id) && id !== params.companyId) {
            loadCompany(id).catch(() => navigate(PageEnum.Login));
        }
    }, [id, params.companyId]);

    return (
        <>
            <BreadCrumbsWrapper>
                <Breadcrumbs breadcrumbs={breadcrumbList} />
            </BreadCrumbsWrapper>
            <HeaderWrapper>
                <PageTitle>Legal profile</PageTitle>
            </HeaderWrapper>
            <LegalClientProfileWrapper>
                <Tabs params={params} options={legalClientTabOptions}>
                    <LegalClientProfileInnerWrapper>
                        <Outlet />
                    </LegalClientProfileInnerWrapper>
                </Tabs>
            </LegalClientProfileWrapper>
        </>
    );
});
