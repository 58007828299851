import { countries } from 'countries-list';
import { $enum } from 'ts-enum-util';

import { BaseOptionInterface } from '@shared-component/select/select.props';
import { UserActivatedStatus, UserType } from '@shared-graphql';

const userStatusOptions = $enum(UserActivatedStatus).map(value => ({
    value,
    label: value,
}));

const userTypeOptions = $enum(UserType).map(value => ({
    value,
    label: value,
}));

const userCountryOptions = Object.entries(countries).map(([key, value]) => ({
    value: key,
    label: value.name,
}));

export const clientsTableFilterStatusFormOptions = [
    { value: null, label: 'All' },
    ...userStatusOptions,
] as BaseOptionInterface[];

export const clientsTableFilterTypeFormOptions = [
    { value: null, label: 'All' },
    ...userTypeOptions,
] as BaseOptionInterface[];

export const clientsTableFilterCountryFormOptions = [
    { value: null, label: 'All' },
    ...userCountryOptions,
] as BaseOptionInterface[];
