import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { DarkBlue, PrimeBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const AnimationWrapper = styled.div`
    max-height: 300px;
    max-width: 460px;
`;

export const LoginWrapper = styled.div`
    background-color: ${DarkBlue};
    height: 100%;
    ${Flex.Row.Style}
`;

export const LoginLeftWrapper = styled.div`
    max-width: 632px;
    border-radius: 0 16px 16px 0;
    flex: 1;
    padding: 48px;
    background-color: ${White};
    ${Flex.Column.VerticalCenter.Style}
`;

export const LoginLeftContentWrapper = styled.div`
    width: 360px;
    height: 100%;
    ${Flex.Column.HorizontalCenter.Style}
`;

export const LoginLeftHeader = styled.div`
    ${Flex.Row.SpaceBetween.VerticalCenter.Style}
`;

export const LoginTitle = styled.h3`
    position: relative;
    ${Typography.Medium.Fiord.Style}

    ::before {
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${PrimeBlue};
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
    }
`;

export const LoginLeftFormWrapper = styled.div`
    margin: auto 0;
`;

export const LoginRightWrapper = styled.div`
    flex: 1;
    ${Flex.Column.HorizontalCenter.VerticalCenter.Style}
`;

export const LoginSlideTitle = styled.h3`
    margin-top: 48px;
    ${Typography.XL.White.Style}
`;

export const LoginSlideDescription = styled.p`
    margin-top: 16px;
    text-align: center;
    ${Typography.M.Iron.Style}
`;

export const LogoWrapper = styled.div`
    width: 98px;
    height: 28px;
`;
