import styled from 'styled-components';

import Copy from '@shared-asset/copy.svg?react';
import { Typography } from '@shared-atom/typography/typography';
import { PrimeBlue } from '@shared-style/colors';

import { ClientInformationModalWrapper } from '@component/informaton-modal/client-information-modal.styles';

export const CopyInfoBlockWrapper = styled(ClientInformationModalWrapper)`
    height: 52px;
    background-color: ${PrimeBlue};
    box-shadow: 0 20px 40px 20px rgba(16, 24, 40, 0.11);
    padding: 0 16px;
`;

export const CopykIcon = styled(Copy)`
    width: 16px;
    height: 16px;
    margin-right: 10px;
    fill: white;
`;

export const CopyInfoBlockMessageText = styled.span`
    ${Typography.Paragraph14.White.Medium.Style}
`;
