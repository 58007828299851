import React, { FC } from 'react';

import {
    OtpStepDescription,
    OtpStepPhoneWrapper,
    OtpStepTitle,
    OtpWrapper,
} from '@shared-component/otp-login-step/otp-login-step.styles';
import { Otp, OtpProps } from '@shared-component/otp/otp';

export const GoogleAuthLoginStep: FC<OtpProps> = ({ error, onSubmit, onResendOtpCode }) => (
    <>
        <OtpStepTitle>Two-factor authentication</OtpStepTitle>
        <OtpStepDescription>
            <OtpStepPhoneWrapper>
                Enter the code to your account generated by Google Authenticator application
            </OtpStepPhoneWrapper>
        </OtpStepDescription>
        <OtpWrapper>
            <Otp error={error} onSubmit={onSubmit} onResendOtpCode={onResendOtpCode} />
        </OtpWrapper>
    </>
);
