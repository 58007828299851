import { Field, FieldProps, useField } from 'formik';
import { FieldInputProps } from 'formik/dist/types';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, FocusEvent, useState } from 'react';
import { v4 } from 'uuid';

import { FieldError } from '@shared-component/field-error/field-error';
import { InputProps } from '@shared-component/input/input';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';
import { isTrue } from '@shared-util/is-data';

import { otpMaskRegExp, OtpSubstrate, PositionTargetType } from './input-otp.options';
import { InputCodeWrapper, InputSubstrate, OtpInputStyle } from './input-otp.styles';

export const InputOtp = observer(({ name }: Pick<InputProps, 'name'>) => {
    const [activePosition, setActivePosition] = useState<number>(-1);
    const [, { error }] = useField(name);

    const hasError = isTrue(error);

    const handleCursorPosition = ({ target }: PositionTargetType) => {
        const selectionStart = (target as HTMLInputElement).selectionStart as number;
        const selectionStartIndex = selectionStart / 2;
        setActivePosition(Math.floor(selectionStartIndex));
    };

    const handleChange = (field: FieldInputProps<string>) => (event: ChangeEvent<HTMLInputElement>) => {
        field.onChange(event);
        handleCursorPosition(event);
    };

    const handleBlur = (field: FieldInputProps<string>) => (event: FocusEvent<HTMLInputElement>) => {
        field.onBlur(event);
        setActivePosition(-1);
    };

    const autocompleteProps = { autocomplete: 'one-time-code' };

    const OtpInputField = ({ field }: FieldProps<string>) => (
        <OtpInputStyle
            {...field}
            {...autocompleteProps}
            type={InputTypeEnum.Text}
            mask={otpMaskRegExp}
            onChange={handleChange(field)}
            onBlur={handleBlur(field)}
            onClick={handleCursorPosition}
            showMask={false}
            guide={false}
            autoFocus
        />
    );

    return (
        <>
            <InputCodeWrapper>
                <Field name={name} render={OtpInputField} />
                {OtpSubstrate.map((_, index) => (
                    <InputSubstrate key={v4()} index={index} isActive={activePosition === index} hasError={hasError} />
                ))}
            </InputCodeWrapper>
            <FieldError name={name} />
        </>
    );
});
