import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { StyledComponent } from 'styled-components';

import { LinkProps } from '@shared-atom/link/link';

export interface ButtonLinkProps extends LinkProps {
    Component: StyledComponent<'button', any>;
}

export const LinkButton = ({
    to,
    children,
    Component,
    isDisable = false,
    isSecondary = false,
    hasLinkBehaviour = true,
    ...props
}: PropsWithChildren<ButtonLinkProps>) => (
    <Component
        routeName={to}
        hasLinkBehaviour={hasLinkBehaviour}
        isSecondary={isSecondary}
        isDisable={isDisable}
        as={Link as any}
        {...props}
    >
        {children}
    </Component>
);
