import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserFinancialStatus, UserGender } from '@shared-graphql';
import { isString } from '@shared-util/is-data';

const genderValues = $enum(UserGender).getValues();
const financialStatusValues = $enum(UserFinancialStatus).getValues();

export const UserProfileType = types
    .model({
        name: types.maybeNull(types.string),
        lastName: types.maybeNull(types.string),
        thirdName: types.maybeNull(types.string),
        dateBirthday: types.maybeNull(types.Date),
        gender: types.maybeNull(types.enumeration(genderValues)),
        financialStatus: types.maybeNull(types.enumeration(financialStatusValues)),
        nationality: types.maybeNull(types.string),
    })
    .views(self => ({
        get fullName() {
            if (!isString(self.name)) {
                return null;
            }

            return `${self.name} ${self.lastName ?? ''}`.trim();
        },
    }));
