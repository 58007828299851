import styled, { css } from 'styled-components';

interface AnimationWrapperProps {
    isVisible: boolean;
}

export const AnimationWrapper = styled.div<AnimationWrapperProps>`
    min-width: 1px;
    min-height: 1px;
`;

interface AnimationWrapperInterface {
    isVisible: boolean;
}

const animationButtonStyles = ({ isVisible }: AnimationWrapperInterface) => {
    if (isVisible) {
        return css`
            opacity: 1;
            height: auto;
            width: auto;
        `;
    }

    return css`
        opacity: 0;
        height: 0;
        width: 0;
    `;
};

export const AnimationButtonWrapper = styled.div`
    ${animationButtonStyles};
`;
