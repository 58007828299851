// import { darken } from 'polished';
import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { BondiBlue } from '@shared-style/colors'; // PrimeBlue , Mystic
import { Flex } from '@shared-style/flex';

// import { getDashed } from '@shared-style/style-utils';
import Cancel from './assets/cancel.svg?react';
import Delete from './assets/deleteFile.svg?react';
import errorFile from './assets/errorFile.svg';
import ReplaceFile from './assets/replace.svg?react';
import selectedFile from './assets/selectedFile.svg';
import Upload from './assets/upload.svg?react';
import uploadInfo from './assets/uploadInfo.svg';

// const strokeColor = Mystic.replace('#', '%23');
// const hoverStrokeColor = darken(0.12, Mystic).replace('#', '%23');
// const dropStrokeColor = darken(0.2, Mystic).replace('#', '%23');

interface DocumentUploadProps {
    isDrop: boolean;
}

export const UploadIcon = styled(Upload)`
    background: unset;
`;
export const DeleteIcon = styled(Delete)``;
export const ReplaceFileIcon = styled(ReplaceFile)``;
export const CancelIcon = styled(Cancel)``;
// fill: ${PrimeBlue};
export const DocumentErrorText = styled.span`
    ${Typography.S.Red.Style}
`;

export const DocumentUploadWrapper = styled.div<DocumentUploadProps>`
    padding: 16px;
    display: inline-block;
    box-sizing: border-box;
    min-width: 40px;
    min-height: 40px;
    border-radius: 16px;
    border: 1px dashed #00997a;
    background-color: #ffffff;

    :hover {
        border: 1px dashed #00997a;
    }
    ${Flex.Column.VerticalCenter.HorizontalCenter.Style}

    ${UploadIcon} {
        fill: ${({ isDrop }) => isDrop && BondiBlue};
    }
`;
// background-image: url(${({ isDrop }) => getDashed(isDrop ? dropStrokeColor : strokeColor)});background-image: url(${getDashed(hoverStrokeColor)});
export const DocumentUploadTitle = styled.span`
    margin-top: 8px;
    ${Typography.S.Raven.Style}
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: black;
`;
export const GreenText = styled.span`
    color: #00997a;
`;
export const DocumentUploadInfo = styled.span`
    margin-top: 8px;
    ${Typography.S.GrayChateau.Style}
`;
export const UploadStatus = styled.div`
    background: #ffffff;
    border-radius: 8px;
    width: 100%;
    padding: 16px;
    margin-top: 8px;
`;
export const SelectedFileWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const EmptyUpload = styled.p`
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    position: relative;
    text-align: start;
    padding-left: 30px;
    ::before {
        content: ' ';
        display: block;
        position: absolute;
        background-image: url(${uploadInfo});
        background-size: contain;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        left: 0;
    }
`;
export const SelectedFile = styled.p`
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    position: relative;
    text-align: start;
    padding-left: 30px;
    ::before {
        content: ' ';
        display: block;
        position: absolute;
        background-image: url(${selectedFile});
        background-size: contain;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        left: -5px;
    }
`;
export const ErrorWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
export const ErrorText = styled.p`
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ff5e5b;
    position: relative;
    text-align: start;
    padding-left: 30px;
    ::before {
        content: ' ';
        display: block;
        position: absolute;
        background-image: url(${errorFile});
        background-size: contain;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        left: -5px;
    }
`;
export const LoaderText = styled.p`
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: black;
    position: relative;
    text-align: start;
    padding-left: 30px;
`;
