import { FC, ReactNode } from 'react';

import { ExternalLinkStyles } from '@shared-atom/external-link/external-link.styles';

export interface ExternalLinkProps {
    href: string;
    isDisabled?: boolean;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const ExternalLink: FC<ExternalLinkProps> = ({ href, isDisabled = false, children, ...props }) => (
    <ExternalLinkStyles href={href} target="_blank" rel="external nofollow noopener" {...props} isDisabled={isDisabled}>
        {children}
    </ExternalLinkStyles>
);
