import { useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { DEBOUNCE_DELAY } from '@shared-util/constants';

export const useSearchFilter = (search = '') => {
    const [actualSearch, setSearch] = useState(search);

    const debounced = useDebouncedCallback(value => {
        setSearch(value);
    }, DEBOUNCE_DELAY);

    const onSearch = (value: string) => debounced(value);
    const isDebounce = debounced.isPending();

    return useMemo(
        () => [
            { search: actualSearch },
            {
                onSearch,
            },
            { isDebounce },
        ],
        [actualSearch, isDebounce]
    );
};
