import styled from 'styled-components';

import { ChathamsBlue, PrimeBlue, White } from '@shared-style/colors';

export interface LogoIconProps {
    isWhite?: boolean;
    isIconWhite?: boolean;
}

export const LogoIcon = styled.svg<LogoIconProps>`
    height: inherit;
    width: 100%;

    path:first-child {
        fill: ${({ isWhite = false }) => (isWhite ? White : ChathamsBlue)};
    }

    path:not(:first-child) {
        fill: ${({ isIconWhite = false }) => (isIconWhite ? White : PrimeBlue)};
        stroke: ${({ isIconWhite = false }) => (isIconWhite ? White : PrimeBlue)};
    }
`;
