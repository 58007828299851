import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const CheckboxInformation = styled.p`
    margin-top: 8px;
    margin-left: 24px;

    ${Typography.XS.Normal.Raven.Style}
`;
