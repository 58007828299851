import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const usePrivateAddressFormLocalization = () => {
    const streetTitle = useLocalizationText(LocalizationEnum.PrivateAddressStreetTitle);
    const postTitle = useLocalizationText(LocalizationEnum.PrivateAddressPostTitle);
    const cityTitle = useLocalizationText(LocalizationEnum.PrivateAddressCityTitle);
    const countryTitle = useLocalizationText(LocalizationEnum.PrivateAddressCountyTitle);

    return {
        streetTitle,
        postTitle,
        cityTitle,
        countryTitle,
    };
};
