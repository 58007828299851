import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { PageTitle } from '@shared-atom/page-title/page-title';
import { Translate } from '@shared-atom/translate/translate';
import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { RegistrationLegalPersonTitle } from '@shared-component/registration/common.styles';
import { RegistrationShareholdersList } from '@shared-component/registration/components/registration-legal-shareholders-list/registration-shareholders-list';
import { UserCompanyRelationType, UserType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { useCompanySelector } from '@selector/company/use-company.selector';

export const LegalClientProfileShareholdersPage = observer(() => {
    const [{ persons, getRelationPersons }] = useCompanySelector();
    const { onOpen } = useContext(ModelContext);

    const handleDeletePerson = (id: string) => () => console.log(id);

    const ownerships = getRelationPersons(new Set([UserCompanyRelationType.Ownership]));

    const handlePersonType = (type: UserType, id?: string) =>
        onOpen(
            UserType.Legal === type
                ? ModalEnum.RegistrationLegalPersonLegalModal
                : ModalEnum.RegistrationLegalPrivatePersonModal,
            { componentProps: { relation: persons.get(id as string)?.relation, type, id } }
        );

    return (
        <>
            <PageTitle title={LocalizationEnum.RegistrationLegalPersonsDirectShareholdersTitle} />
            <RegistrationLegalPersonTitle>
                <Translate langKey={LocalizationEnum.RegistrationLegalPersonsDirectShareholdersTitle} />
            </RegistrationLegalPersonTitle>
            <RegistrationShareholdersList
                onChooseType={handlePersonType}
                onDeletePerson={handleDeletePerson}
                relationPersons={ownerships}
            />
        </>
    );
});
