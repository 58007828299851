import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';

import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { isExist } from '@shared-util/is-data';

import { AccountCardType } from '@model/account-model/account-card-model/account-card-model.type';

import { NameView } from './cell.styles';

interface InternetCellRenderProps {
    card: Instance<typeof AccountCardType>;
}

const CashCellRender = observer(({ card }: InternetCellRenderProps) => {
    const value = useCurrencyFormat(card.limits.cashWithdrawal ?? 0, card.currency.code);

    return <NameView>{isExist(card.limits.cashWithdrawal) ? value : 'No limit'}</NameView>;
});

export const CashCell = (card: Instance<typeof AccountCardType>) => <CashCellRender card={card} />;
