import { flow, getParent, Instance } from 'mobx-state-tree';

import { AdminGetCompanyPersons, Legal, Person, PersonRelation, Query } from '@shared-graphql';
import { initialAddress } from '@shared-model/address-type/address.initial';
import { initialUserProfileModel } from '@shared-model/user-model/user-model-private/user-profile/user-profile.initial';
import { isExist } from '@shared-util/is-data';

import { apolloClient } from '@connection/apollo-client';
import { CompanyModel } from '@model/company-model/company-model';
import { CompanyModelType } from '@model/company-model/company-model.type';

type CompanyPersonRelationActionsType = Instance<typeof CompanyModelType>;

const getCompanyPersons = (companyId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetCompany'>>({
        query: AdminGetCompanyPersons,
        variables: { companyId },
    })
        .then(result => result.data?.adminGetCompany);

const companyPersonMap = <T>(person: PersonRelation) =>
    ({
        id: person.id,
        relation: person.relation,
        type: person.type,
        person: isExist(person.person)
            ? {
                ...person.person,
                profile: (person.person as Person).profile ?? initialUserProfileModel,
                address: (person.person as Person).address ?? initialAddress,
            }
            : null,
        legal: isExist(person.legal)
            ? {
                ...person.legal,
                id: person.id ?? null,
                registrationDate: person.legal?.registrationDate ?? null,
                isCompanyAddressSame: (person.legal as Legal).isCompanyAddressSame ?? false,
            }
            : null,
    } as unknown as T);

export const companyPersonsActions = (self: CompanyPersonRelationActionsType) => ({
    loadPersons: flow(function* loadPersons(companyId: string) {
        const companyModel = getParent(self) as Instance<typeof CompanyModel>;

        if (companyModel.loading.isCompanyPersonsLoading) {
            return;
        }

        companyModel.setCompanyPersonsLoading(true);

        self.persons.clear();

        try {
            const { persons } = yield getCompanyPersons(companyId);

            persons.forEach((person: PersonRelation) => {
                self.persons.set(person.id, companyPersonMap(person));
            });

            companyModel.setCompanyPersonsLoading(false);
        } catch (error) {
            throw new Error(error as string);
        } finally {
            companyModel.setCompanyPersonsLoading(false);
        }
    }),
});
