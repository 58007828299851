import { useMemo } from 'react';

import { Notification } from '@shared-atom/elpaso-kit/notification';
import { NotificationSizeEnum, NotificationTypeEnum } from '@shared-atom/elpaso-kit/notification/types';
import { CompanyActivatedStatus, UserActivatedStatus } from '@shared-graphql';

import {
    AccountCredentialsAssignedSuccess,
    AccountCredentialsRow,
    AccountCredentialsRowTitle,
    AccountCredentialsRowValue,
    AccountCredentialsTitle,
    AccountCredentialsWrapper,
} from '@page/accounts/accounts-add-form/account-credentials/account-credentials.styles';

export interface AccountCredentialsInterface {
    ibanqClientId: string;
    ibanqUsername: string;
    userStatus: UserActivatedStatus | CompanyActivatedStatus;
}

export const AccountCredentials = ({ ibanqClientId, ibanqUsername, userStatus }: AccountCredentialsInterface) => {
    const shouldShowMustActivateNotify = useMemo(() => userStatus !== UserActivatedStatus.Approved, [userStatus]);

    return (
        <AccountCredentialsWrapper>
            <AccountCredentialsTitle>Financial account credentials</AccountCredentialsTitle>
            <AccountCredentialsRow>
                <AccountCredentialsRowTitle>ibanq Client ID</AccountCredentialsRowTitle>
                <AccountCredentialsRowValue>{ibanqClientId}</AccountCredentialsRowValue>
            </AccountCredentialsRow>
            <AccountCredentialsRow>
                <AccountCredentialsRowTitle>ibanq Username</AccountCredentialsRowTitle>
                <AccountCredentialsRowValue>{ibanqUsername}</AccountCredentialsRowValue>
            </AccountCredentialsRow>
            {shouldShowMustActivateNotify && (
                <AccountCredentialsAssignedSuccess>
                    <Notification
                        text="Now you must activate user sends account  in the Summary section."
                        size={NotificationSizeEnum.M}
                        type={NotificationTypeEnum.ACCENT}
                    />
                </AccountCredentialsAssignedSuccess>
            )}
        </AccountCredentialsWrapper>
    );
};
