import { useField } from 'formik';
import React, { FC } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { RadioButton } from '@shared-component/radio-button/radio-button';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import CheckIcon from '../assets/check-circle-fill.svg?react';
import { BeneficiaryRadioButtonStyle, IconWrapper, LabelWrapper } from './payment-beneficiary-radio-button.styles';

export interface PaymentBeneficiaryRadioButtonProps {
    name: string;
    value: string | number;
    label: LocalizationEnum;
    children?: React.ReactNode;
}

export const PaymentBeneficiaryRadioButton: FC<PaymentBeneficiaryRadioButtonProps> = ({
    name,
    value,
    label,
    children,
}) => {
    const [fieldType] = useField(name);
    const isChecked = value === fieldType.value;

    return (
        <RadioButton name={name} value={value}>
            <BeneficiaryRadioButtonStyle isChecked={isChecked}>
                <IconWrapper>{isChecked ? <CheckIcon /> : children}</IconWrapper>
                <LabelWrapper isChecked={isChecked}>
                    <Translate langKey={label} />
                </LabelWrapper>
            </BeneficiaryRadioButtonStyle>
        </RadioButton>
    );
};
