import { usePrivateDocumentForm } from '@shared-form/private-document-form/private-document-form.hook';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { usePrivateClientActionsSelector } from '@selector/private-client/use-private-client.actions-selector';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const usePrivateClientDocuments = () => {
    const { params } = useCurrentNavigation();

    const [{ documents }] = usePrivateClientSelector();
    const { updatePrivateClientDocuments } = usePrivateClientActionsSelector();

    return usePrivateDocumentForm(documents, data => updatePrivateClientDocuments(params.userId, data));
};
