import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const Description = styled.p`
    margin-top: 16px;
    ${Typography.Raven.Style}
`;

export const FormWrapper = styled.div`
    margin-top: 24px;
`;
