import { values } from 'mobx';
import { Instance } from 'mobx-state-tree';

import { UserCompanyRelationType, UserDocumentType, UserType } from '@shared-graphql';
import { CompanyModelType as CompanyModel } from '@shared-model/company-model/company-model.type';
import { CompanyPersonRelationType } from '@shared-model/company-model/company-persons-model/company-persons-model.type';
import { isExist, isString } from '@shared-util/is-data';

import { companyAddressActions } from './company-model-actions/company-address.actions';
import { companyApplyActions } from './company-model-actions/company-apply.actions ';
import { companyDocumentsActions } from './company-model-actions/company-documents.actions';
import { companyMainActions } from './company-model-actions/company-main.actions ';
import { companyPersonsActions } from './company-model-actions/company-persons.actions';
import { companyServiceActions } from './company-model-actions/company-service.actions';
import {
    companyStatusUpdateActions,
    companyStatusesUpdateActions,
} from './company-model-actions/company-status-update.actions';

const REQUIRED_COMPANY_DOCUMENT_TYPES = new Set([
    UserDocumentType.Corporate,
    UserDocumentType.Articles,
    UserDocumentType.Business,
]);

export const CompanyModelType = CompanyModel.views(self => ({
    get personValues() {
        return values(self.persons) as unknown as Instance<typeof CompanyPersonRelationType>[];
    },
    hasPersonProfile(personId: string) {
        const person = self.persons.get(personId);

        if (person !== undefined) {
            return person.type === UserType.Legal ? isExist(person.legal) : isExist(person.person);
        }

        return false;
    },
    get hasCompany() {
        return isString(self.id);
    },
}))
    .views(self => ({
        get privatePersons() {
            return self.personValues.filter(person => person.type === UserType.Private);
        },
        getRelationPersons(relation: Set<UserCompanyRelationType>, hasOnly = false) {
            if (relation.size > 0) {
                return self.personValues.filter(
                    person =>
                        person.relation.companyRelation.some(current => relation.has(current)) &&
                        (!hasOnly || (hasOnly && person.relation.isUserCreate))
                );
            }

            if (hasOnly) {
                return self.personValues.filter(person => person.relation.isUserCreate);
            }

            return self.personValues;
        },
    }))
    .views(self => ({
        get isDocumentsDone() {
            return (
                self.documents.filter(upload => REQUIRED_COMPANY_DOCUMENT_TYPES.has(upload.type)).length >=
                REQUIRED_COMPANY_DOCUMENT_TYPES.size
            );
        },
        get isOwnershipDocumentsDone() {
            const persons = self.getRelationPersons(new Set([UserCompanyRelationType.Ownership]));
            return (
                persons.length > 0 &&
                persons.every(person => (person.type === UserType.Legal ? person.legal?.isDone : person.person?.isDone))
            );
        },
        get isBeneficiaryDocumentsDone() {
            const persons = self.getRelationPersons(new Set([UserCompanyRelationType.Beneficiary]));
            return persons.length > 0 && persons.every(person => person.person?.isDone);
        },
        get isDirectorDocumentsDone() {
            const persons = self.getRelationPersons(new Set([UserCompanyRelationType.Director]));
            return persons.length > 0 && persons.every(person => person.person?.isDone);
        },
        get isUserDocumentsDone() {
            const persons = self.getRelationPersons(new Set(), true);
            return persons.length > 0 && persons.every(person => person.person?.isDone);
        },
    }));

export const CompanyModelTypeInstance = CompanyModelType.actions(companyApplyActions)
    .actions(companyMainActions)
    .actions(companyAddressActions)
    .actions(companyPersonsActions)
    .actions(companyDocumentsActions)
    .actions(companyServiceActions)
    .actions(companyStatusUpdateActions)
    .actions(companyStatusesUpdateActions);
