import { observer } from 'mobx-react-lite';

import { RegistrationLegalPersonTitle, PersonsWrapper } from '@shared-component/registration/common.styles';
import { PrivateDocumentForm } from '@shared-form/private-document-form/private-document-form';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { LegalClientProfileFormsButton } from '@page/legal-client-profile/legal-client-profile-forms-button/legal-client-profile-forms-button';

import { useRegistrationLegalDocumentsPersonView } from './registration-legal-documents-person-view.hook';

interface RegistrationLegalDocumentsPersonViewProps {
    personId: string;
    onBack: OnEventEmptyType;
}

export const RegistrationLegalDocumentsPersonView = observer(
    ({ personId, onBack }: RegistrationLegalDocumentsPersonViewProps) => {
        const { documentForm, handleBack, title, listChooses } = useRegistrationLegalDocumentsPersonView(
            personId,
            onBack
        );
        return (
            <>
                <RegistrationLegalPersonTitle>{title}</RegistrationLegalPersonTitle>
                <PersonsWrapper>
                    <PrivateDocumentForm documentForm={documentForm} listChooses={listChooses} hasOtherTypeDocuments>
                        <LegalClientProfileFormsButton
                            onBack={handleBack}
                            isSubmitDisabled={documentForm.isSubmitDisabled}
                        />
                    </PrivateDocumentForm>
                </PersonsWrapper>
            </>
        );
    }
);
