const MAX_COUNT_PAGES_SHOW = 2;
const MAX_COUNT_PAGES_BEFORE = 1;

export const useTablePagination = (currentPageIndex: number, pageCount: number) => {
    const lastPageIndex = pageCount - 1;
    const beforeLastPageIndex = lastPageIndex + 1 - MAX_COUNT_PAGES_BEFORE;
    const maxDotsIndex = beforeLastPageIndex;
    const isPagesCountLessMaxCountPagesShow = pageCount > MAX_COUNT_PAGES_SHOW;

    const minStartIndex = isPagesCountLessMaxCountPagesShow ? pageCount - MAX_COUNT_PAGES_SHOW : 0;
    const paginationsLength = isPagesCountLessMaxCountPagesShow ? MAX_COUNT_PAGES_SHOW : pageCount;

    const startIndex = Math.min(Math.max(currentPageIndex - MAX_COUNT_PAGES_BEFORE, 0), minStartIndex);

    const paginations = Array.from(Array(paginationsLength), (_, index) => index + startIndex) as Array<number | null>;

    if (
        currentPageIndex < maxDotsIndex &&
        MAX_COUNT_PAGES_SHOW !== lastPageIndex &&
        MAX_COUNT_PAGES_SHOW !== pageCount
    ) {
        paginations.push(null);
    }

    if (currentPageIndex < beforeLastPageIndex && isPagesCountLessMaxCountPagesShow) {
        paginations.push(lastPageIndex);
    }

    return paginations;
};
