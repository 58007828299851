import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { PaymentEntityType } from '@shared-graphql';

export const paymentEntityTypes = $enum(PaymentEntityType).getValues();

export const BeneficiaryModelTypeSchema = {
    acctNumber: types.maybeNull(types.string),
    bicSwift: types.maybeNull(types.string),
    beneficiaryEntityType: types.enumeration(paymentEntityTypes),
    beneficiaryAddress: types.maybeNull(types.string),
    beneficiaryCity: types.maybeNull(types.string),
    beneficiaryCountry: types.maybeNull(types.string),
    beneficiaryCompanyName: types.maybeNull(types.string),
    iban: types.maybeNull(types.string),
    bankCountry: types.string,
    beneficiaryFirstName: types.maybeNull(types.string),
    beneficiaryLastName: types.maybeNull(types.string),
    beneficiaryPostcode: types.maybeNull(types.string),
    beneficiaryStateOrProvince: types.maybeNull(types.string),
    cnaps: types.maybeNull(types.string),
    bankName: types.maybeNull(types.string),
    bankCode: types.maybeNull(types.string),
    branchCode: types.maybeNull(types.string),
    bankAddress: types.maybeNull(types.string),
    sortCode: types.maybeNull(types.string),
    ifsc: types.maybeNull(types.string),
    clabe: types.maybeNull(types.string),
    nationality: types.maybeNull(types.string),
};
