import { types } from 'mobx-state-tree';

import { privateClientMainActions } from '@model/private-client-model/private-client-actions/private-client-main.actions';

import { privateClientStatusUpdateActions } from './private-client-actions/private-client-status-update.actions';
import { privateClientStatusesActions } from './private-client-actions/private-client-statuses.actions';
import { initialPrivateClient } from './private-client-model-initials/private-client-model.initial';
import { PrivateClientTypesModel } from './private-client-model.type';

export const PrivateClientModelType = types.model({
    client: PrivateClientTypesModel,
    loading: types.model({
        isLoading: types.boolean,
        isLoadingComment: types.boolean,
    }),
    error: types.optional(types.string, ''),
});

export const PrivateClientModel = PrivateClientModelType.actions(privateClientMainActions)
    .actions(privateClientStatusesActions)
    .actions(privateClientStatusUpdateActions);

export const getInitialPrivateClientModel = () =>
    PrivateClientModel.create({
        client: initialPrivateClient,
        loading: {
            isLoading: false,
            isLoadingComment: false,
        },
    });
