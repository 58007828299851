import { memo, useMemo } from 'react';

import { isString } from '@shared-util/is-data';

import { DefaultFlagIcon, FlagIcon } from './flag.styles';

export interface FlagProps {
    countryCode?: string;
}

const getIconPath = (code: string) => `${import.meta.env.VITE_REACT_APP_FLAG_ICON_PATH}/1x1/${code.toLowerCase()}.svg`;

export const Flag = memo(({ countryCode }: FlagProps) => {
    const iconPath = useMemo(() => isString(countryCode) && getIconPath(countryCode as string), [countryCode]);

    return iconPath !== false ? <FlagIcon alt="flag" src={iconPath} /> : <DefaultFlagIcon />;
});
