import { Field, FieldProps } from 'formik';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { Flag } from '@shared-atom/flag/flag';
import { FieldError } from '@shared-component/field-error/field-error';
import { InputProps } from '@shared-component/input/input';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';

import { useInputPhone } from './input-phone.hook';
import { PhoneMaskedStyle, ErrorWrapper, PhoneWrapper, LabelPhoneWrapper } from './input-phone.styles';

export type InputPhoneType = Pick<InputProps, 'title' | 'name' | 'disabled'>;

export const InputPhone = observer(({ title, disabled, name = 'phone', ...props }: InputPhoneType) => {
    const [mask, handleChange, currentCountryCode] = useInputPhone(name);
    const [isFocused, setFocused] = useState(false);
    const InputPhoneField = ({ field }: FieldProps<string>) => (
        <PhoneMaskedStyle
            {...field}
            type={InputTypeEnum.Phone}
            mask={mask}
            guide={false}
            disabled={disabled}
            showMask={false}
            onChange={handleChange}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            placeholder={title}
            autoFocus
            {...props}
        />
    );

    return (
        <PhoneWrapper isFocused={isFocused}>
            <LabelPhoneWrapper>
                <Flag countryCode={currentCountryCode} />
                <Field name={name} render={InputPhoneField} />
            </LabelPhoneWrapper>
            <ErrorWrapper>
                <FieldError name={name} />
            </ErrorWrapper>
        </PhoneWrapper>
    );
});
