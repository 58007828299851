import { Instance } from 'mobx-state-tree';

import { ClientsModelTypeInstance } from '@model/clients-model/clients-model.type';
import { useStore } from '@store/store-context';

export const useClientsSelector = (): [Instance<typeof ClientsModelTypeInstance>, boolean, null | string] => {
    const { clients, loading, error } = useStore().clients;

    return [clients, loading.isLoading, error];
};
