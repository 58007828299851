import { object, string } from 'yup';

export const AddressValidation = object().shape({
    street: string().required(),
    zip: string().required(),
    city: string().required(),
    country: string().required(),
    building: string(),
});

export const LegalAddressCompanyFormValidation = object().shape({
    legalAddress: AddressValidation,
});

export const PostalAddressCompanyFormValidation = object().shape({
    postalAddress: AddressValidation,
});
