import { useMemo, useState } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { Translate } from '@shared-atom/translate/translate';
import { AccountAdministration, UserType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { useCommissionProfileInfoAdministration } from '@page/commission-profile/commission-profile-info/commission-profile-info-hook/use-commission-profile-info-administration.hook';
import {
    administrationList,
    CommissionListAdministrationProps,
} from '@page/commission-profile/commission-profile-info/commission-profile-info.options';
import {
    Wrapper,
    Title,
    TitleWrapper,
    ButtonEdit,
} from '@page/commission-profile/commission-profile-info/commission-profile-info.styles';

import { CommissionProfileInfoAdministrationForm } from './commission-profile-info-administration-form/commission-profile-info-administration-form';
import { CommissionProfileInfoAdministrationList } from './commission-profile-info-administration-list/commission-profile-info-administration-list';

export const CommissionProfileInfoAdministration = ({ currentUserType }: { currentUserType: UserType }) => {
    console.log({ currentUserType });
    const [isEditMode, setEditMode] = useState(false);
    const accountAdministrationList = useMemo(
        () =>
            administrationList.filter(({ id }: CommissionListAdministrationProps) =>
                currentUserType === UserType.Private ? id !== 'complexStructure' : true
            ),
        [currentUserType, administrationList]
    );

    const [accountAdministration, handleUpdateAccountAdministration] = useCommissionProfileInfoAdministration();

    const onSubmit = (values: Omit<AccountAdministration, 'balanceFee' | 'userType'>) => {
        handleUpdateAccountAdministration(values);
        setEditMode(false);
    };

    const handleMode = () => setEditMode(prevMode => !prevMode);
    const onClose = () => setEditMode(false);

    return (
        <Wrapper>
            <TitleWrapper>
                <Title>
                    <Translate langKey={LocalizationEnum.CommissionProfileAdministrationTitle} />
                </Title>
                <ButtonTransparent onClick={handleMode}>
                    <ButtonEdit>
                        <ButtonEdit>Edit</ButtonEdit>
                    </ButtonEdit>
                </ButtonTransparent>
            </TitleWrapper>
            {isEditMode && (
                <CommissionProfileInfoAdministrationForm
                    accountAdministration={accountAdministration}
                    onSubmit={onSubmit}
                    onClose={onClose}
                    accountAdministrationList={accountAdministrationList}
                />
            )}
            {!isEditMode && (
                <CommissionProfileInfoAdministrationList
                    list={accountAdministrationList}
                    accountAdministration={accountAdministration}
                />
            )}
        </Wrapper>
    );
};
