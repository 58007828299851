import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { Input, InputProps } from '@shared-component/input/input';
import { InputTagEnum } from '@shared-component/input/input-tag.enum';
import { isExist } from '@shared-util/is-data';

import { TextAreaWrapper, LabelMaxLength } from './textarea.styles';

export interface TextAreaProps extends InputProps {
    maxLength?: number;
}

export const TextArea: FC<TextAreaProps> = observer(({ maxLength, name, ...props }) => {
    const [, { error }] = useField(name);

    const shouldRenderMaxLength = isExist(maxLength) && !isExist(error);

    return (
        <TextAreaWrapper>
            <Input {...props} name={name} tag={InputTagEnum.TextArea} />
            {shouldRenderMaxLength && <LabelMaxLength>{`Max ${maxLength} symbols`}</LabelMaxLength>}
        </TextAreaWrapper>
    );
});
