import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useLegalCompanyFormLocalization = () => {
    const companyNameTitle = useLocalizationText(LocalizationEnum.LegalCompanyNameTitle);
    const registrationCountryTitle = useLocalizationText(LocalizationEnum.LegalCompanyCountryRegistrationTitle);
    const companyNumberTitle = useLocalizationText(LocalizationEnum.LegalCompanyNumberTitle);
    const companyTaxNumberTitle = useLocalizationText(LocalizationEnum.LegalCompanyTaxNumberTitle);
    const companySiteTitle = useLocalizationText(LocalizationEnum.LegalCompanySiteTitle);
    const registrationDateTitle = useLocalizationText(LocalizationEnum.LegalCompanyRegistrationDateTitle);
    const conductBusinessTitle = useLocalizationText(LocalizationEnum.LegalCompanyBusinessConductionCountryTitle);
    const groupPartTitle = useLocalizationText(LocalizationEnum.LegalCompanyPartGroupTitle);
    const conductBusinessInformation = useLocalizationText(
        LocalizationEnum.LegalCompanyBusinessConductionCountryInformation
    );
    const groupPartInformation = useLocalizationText(LocalizationEnum.LegalCompanyPartGroupInformation);

    return {
        companyNameTitle,
        registrationCountryTitle,
        companyNumberTitle,
        companyTaxNumberTitle,
        companySiteTitle,
        registrationDateTitle,
        conductBusinessTitle,
        groupPartTitle,
        conductBusinessInformation,
        groupPartInformation,
    };
};
