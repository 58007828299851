import { memo, ReactNode, Suspense } from 'react';

import { AnimationLoading } from '@shared-component/animation/animation-loading/animation-loading';

import { LoaderAnimationWrapper, PageLoaderWrapper } from './page-loader.styles';

export const ViewPageLoader = memo(() => (
    <PageLoaderWrapper>
        <LoaderAnimationWrapper>
            <AnimationLoading isWhite />
        </LoaderAnimationWrapper>
    </PageLoaderWrapper>
));

export const PageLoader = ({ children }: { children?: ReactNode | ReactNode[] | JSX.Element }) => (
    <Suspense fallback={<ViewPageLoader />}>{children}</Suspense>
);
