import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Dark, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

interface WrapperProps {
    isSmall: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
    max-width: ${({ isSmall }) => (isSmall ? 290 : 500)}px;
    margin: 24px auto 32px;
    ${Flex.Column.VerticalCenter.Style}
`;

export const Title = styled.h3`
    margin: 16px 0 8px;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.M.Medium.Style};
`;

export const Text = styled.p`
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.S.Normal.Center.Style};
`;
