import { useField } from 'formik';

import { FormRow } from '@shared-form/form-common/form-common.styles';
import { DocumentComment } from '@shared-form/private-document-form/document-comment/document-comment';
import { DocumentUploaded } from '@shared-form/private-document-form/document-uploaded/document-uploaded';
import { File as FileUploadType } from '@shared-graphql';

import { AttachFile } from './attach-file/attach-file';

interface PaymentFilesProps {
    name: string;
    limit: number;
}

export const PaymentFiles = ({ name, limit }: PaymentFilesProps) => {
    const [, { value: uploadedFiles = [] }, { setValue }] = useField<FileUploadType[]>(name);

    const handleUploadedFile = (newFile: FileUploadType) => setValue([...uploadedFiles, newFile]);

    const handleDeleteFile = (path: string) => () => setValue(uploadedFiles.filter(file => file.path !== path));

    const shouldRenderAttachFile = limit !== uploadedFiles.length;

    return (
        <>
            {uploadedFiles.map((uploadedFile, index) => (
                <FormRow key={uploadedFile.path}>
                    <DocumentUploaded
                        filename={uploadedFile.filename}
                        keyId={uploadedFile.key}
                        onDelete={handleDeleteFile(uploadedFile.path)}
                    />
                    <DocumentComment path={`${name}.${index}.comment`} />
                </FormRow>
            ))}

            {shouldRenderAttachFile && <AttachFile onUploaded={handleUploadedFile} />}
        </>
    );
};
