import styled, { css } from 'styled-components';

import { ButtonTransparentStyle } from '@shared-atom/button/button.style';
import { Typography } from '@shared-atom/typography/typography';
import { JungleGreen, SelectiveYellow, White } from '@shared-style/colors';

export const BlockWrapper = styled.div`
    padding: 32px;
    border-radius: 0 0 16px 16px;
    background-color: ${White};
    margin-bottom: 32px;
    height: calc(100% - 60px);
`;

export const ButtonsWrapper = styled.div`
    ${ButtonTransparentStyle} {
        :not(:first-child) {
            margin-left: 40px;
        }
    }
`;

const ButtonText = styled.p`
    ${Typography.White.Style}
`;

export const buttonStyles = css`
    width: 100%;
    padding: 16px;
    border-radius: 8px;
`;

export const ButtonActiveDocument = styled(ButtonText)`
    ${buttonStyles};

    background-color: ${JungleGreen};
`;

export const ButtonArchiveDocument = styled(ButtonText)`
    ${buttonStyles};

    background-color: ${SelectiveYellow};
`;
