import { useState } from 'react';

import { OnEventEmptyType } from '@shared-type/on-event.type';

import { RegistrationLegalDocumentsEnum } from '@page/legal-client-profile/legal-client-profile-documents/registration-legal-documents.enum';

import { RegistrationLegalDocumentsPersonChoose } from './registration-legal-documents-person-choose/registration-legal-documents-person-choose';
import { RegistrationLegalDocumentsPersonView } from './registration-legal-documents-person-view/registration-legal-documents-person-view';

interface RegistrationLegalDocumentsPersonProps {
    relation: RegistrationLegalDocumentsEnum;
    onBack: OnEventEmptyType;
}

export const RegistrationLegalDocumentsPerson = ({ relation, onBack }: RegistrationLegalDocumentsPersonProps) => {
    const [personId, setPersonId] = useState<string | null>(null);

    const handlePerson = (selectedPersonId: string | null) => setPersonId(selectedPersonId);
    const handleBack = () => handlePerson(null);

    return (
        <>
            {personId === null && (
                <RegistrationLegalDocumentsPersonChoose relation={relation} onClick={handlePerson} onBack={onBack} />
            )}
            {personId !== null && <RegistrationLegalDocumentsPersonView personId={personId} onBack={handleBack} />}
        </>
    );
};
