import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { isString } from '@shared-util/is-data';

import { useCompanySelector } from '@selector/company/use-company.selector';

export const LegalClientNameTab = observer(() => {
    const [{ companyName }] = useCompanySelector();
    const clientName = useMemo(() => (isString(companyName) ? companyName : 'Company'), [companyName]);

    return (
        <>
            {Boolean(clientName) === true && clientName}
            {Boolean(clientName) === false && null}
        </>
    );
});
