import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { PrivateSelfDeclarationForm } from '@shared-form/private-self-declaration-form/private-self-declaration-form';
import { UserDeclaration, UserDeclarationInput } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { ButtonWrapper } from '@page/private-client-profile/private-client-profile.styles';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const PrivateClientProfileDeclarations = observer(() => {
    const { params } = useCurrentNavigation();

    const [{ declaration }] = usePrivateClientSelector();

    const handleSubmit = (newDeclarations: UserDeclarationInput) => console.log(params.userId, newDeclarations);

    return (
        <Formik initialValues={(declaration ?? {}) as UserDeclaration} onSubmit={handleSubmit} enableReinitialize>
            <Form>
                <PrivateSelfDeclarationForm />
                <ButtonWrapper>
                    <ButtonPrimary type={ButtonTypeEnum.Submit}>Update self declaration</ButtonPrimary>
                </ButtonWrapper>
            </Form>
        </Formik>
    );
});
