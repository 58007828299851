import { useField } from 'formik';
import { $enum } from 'ts-enum-util';

import { CheckBox } from '@shared-component/checkbox/checkbox';
import { Input } from '@shared-component/input/input';
import { InputPhone } from '@shared-component/input/input-phone/input-phone';
import { Select } from '@shared-component/select/select';
import { FormRow, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { RegistrationLegalPersonPrivateProfileFormEnum } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.enum';
import { UserCompanyRelationType, UserRolesType } from '@shared-graphql';

import { useLegalPrivateProfileUserCreate } from './registration-legal-person-private-profile-form-user-create.hook';
import { LegalPrivateProfileUserCreateInterface } from './registration-legal-person-private-profile-form-user-create.interface';
import { useRegistrationLegalPersonPrivateProfileFormUserCreateLocalization } from './registration-legal-person-private-profile-form-user-create.localization-hook';
import {
    LegalPersonRoleSelectItem,
    PersonRoleSelectItemInterface,
} from './registration-legal-person-private-role-select-item';

const userRoles = $enum(UserRolesType).map(value => ({ value, label: value }));

const LegalPersonRoleSelectItemComponent = (props: PersonRoleSelectItemInterface) => (
    <LegalPersonRoleSelectItem {...props} />
);

export const RegistrationLegalPersonPrivateProfileFormUserCreate = ({
    isCurrentUser,
}: LegalPrivateProfileUserCreateInterface) => {
    const [{ value: isCreateUser }] = useField<boolean>(RegistrationLegalPersonPrivateProfileFormEnum.UserCreate);
    const [{ value: companyRelation }] = useField<UserCompanyRelationType[]>(
        RegistrationLegalPersonPrivateProfileFormEnum.UserRelation
    );

    useLegalPrivateProfileUserCreate();

    const { phoneTitle, userTitle, roleTitle, emailTitle } =
        useRegistrationLegalPersonPrivateProfileFormUserCreateLocalization();

    const isOwner = companyRelation.includes(UserCompanyRelationType.Owner) ?? false;

    const isUserExist = isCurrentUser || isOwner || !isCreateUser;

    return (
        <FormWrapper>
            <CheckBox
                title={userTitle}
                name={RegistrationLegalPersonPrivateProfileFormEnum.UserCreate}
                disabled={isOwner || isCurrentUser}
            />
            <FormRow>
                <Select
                    title={roleTitle}
                    name={RegistrationLegalPersonPrivateProfileFormEnum.UserRole}
                    options={userRoles}
                    isDisabled={isUserExist}
                    rowRender={LegalPersonRoleSelectItemComponent}
                />
            </FormRow>
            <FormRow>
                <Input
                    title={emailTitle}
                    name={RegistrationLegalPersonPrivateProfileFormEnum.Email}
                    disabled={isUserExist}
                />
            </FormRow>
            <FormRow>
                <InputPhone
                    title={phoneTitle}
                    name={RegistrationLegalPersonPrivateProfileFormEnum.Phone}
                    disabled={isOwner || !isCreateUser}
                />
            </FormRow>
        </FormWrapper>
    );
};
