import { UserType } from '@shared-graphql';

import { CurrentTabName } from '@component/breadcrumbs/breadcrumb-tabs/current-tab-name';
import { PrivateClientNameTab } from '@component/breadcrumbs/breadcrumb-tabs/private-client-name';
import { OptionsInterface } from '@component/tabs/tabs.interface';
import { PageEnum } from '@enum/page.enum';

export const privateClientTabsOptions: OptionsInterface[] = [
    {
        title: 'Profile',
        name: PageEnum.PrivateClientProfilePersonal,
    },
    {
        title: 'Address',
        name: PageEnum.PrivateClientProfileAddress,
    },
    {
        title: 'Services',
        name: PageEnum.PrivateClientProfileServices,
    },
    {
        title: 'Documents',
        name: PageEnum.PrivateClientProfileDocuments,
    },
    {
        title: 'Self-Declaration',
        name: PageEnum.PrivateClientProfileDeclarations,
    },
    {
        title: 'Accept Agreement',
        name: PageEnum.PrivateClientProfileAcceptAgreement,
    },
];

export const breadcrumbList = [
    {
        path: PageEnum.StatusClients,
        name: 'Clients',
        params: {
            type: UserType.Private,
        },
    },
    {
        name: <PrivateClientNameTab />,
        path: PageEnum.PrivateClientSummary,
    },
    {
        name: <CurrentTabName options={privateClientTabsOptions} />,
    },
];
