import { FC } from 'react';

import { Link } from '@shared-atom/link/link';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { CommonObjectType } from '@shared-type/common.type';
import { getPathnameWithParams } from '@shared-util/get-pathname-with-params';
import { isTrue } from '@shared-util/is-data';

import { BreadcrumbsProps } from '@component/breadcrumbs/breadcrumbs.interface';
import { PageEnum } from '@enum/page.enum';

import { Wrapper, LinkWrapper } from './breadcrumbs.styles';

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
    const { params } = useCurrentNavigation();

    return (
        <Wrapper>
            {breadcrumbs?.map(breadcrumb => (
                <LinkWrapper key={JSON.stringify(breadcrumb.name)}>
                    {isTrue(breadcrumb?.path) ? (
                        <>
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <Link
                                to={{
                                    pathname: getPathnameWithParams(
                                        breadcrumb.path as PageEnum,
                                        { ...params, ...breadcrumb.params } as CommonObjectType
                                    ),
                                }}
                                isDisable={breadcrumb.path === undefined}
                            >
                                {breadcrumb.name}
                            </Link>
                        </>
                    ) : (
                        breadcrumb.name
                    )}
                </LinkWrapper>
            ))}
        </Wrapper>
    );
};
