import { ModalPropsInterface } from '@shared-component/modal/modal.interface';

import { ModalEnum } from './modal.enum';

type ModalOptionType = {
    [key in ModalEnum]?: ModalPropsInterface;
};

export const ModalOptionProps: ModalOptionType = {
    [ModalEnum.RegistrationLegalPrivatePersonModal]: {
        isFull: true,
    },
    [ModalEnum.RegistrationLegalPersonLegalModal]: {
        isFull: true,
    },
};
