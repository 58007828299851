import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral200, Neutral900, PrimeBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { isTrue } from '@shared-util/is-data';

import ArrowRight from '@page/assets/outlined-arrow-right.svg?react';

interface ButtonContentWrapperProps {
    isActive?: boolean;
    isDisabled?: boolean;
}

export const PaginationContainer = styled.div`
    flex: 1;
    flex-direction: row-reverse;
    padding: 24px;
    ${Flex.SpaceBetween.VerticalCenter.Style}
`;
export const ButtonContentWrapper = styled.p<ButtonContentWrapperProps>`
    width: 32px;
    height: 32px;
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    padding: 8px;
    ${Typography.SemiBold.Style};
    border-radius: 8px;
    ${({ isDisabled, isActive }) =>
        isTrue(isDisabled)
            ? `
        border: 1px solid  ${Neutral200};
        background-color: ${Neutral200};
    `
            : `
        border: 1px solid ${isTrue(isActive) ? 'transparent' : Neutral200}};
        color: ${isTrue(isActive) ? White : Neutral900};
        background-color: ${isTrue(isActive) ? PrimeBlue : 'transparent'};
    `};
`;
export const PaginationContentWrapper = styled.div`
    ${Flex.VerticalCenter.Style};
    gap: 16px;
`;

export const PaginationButtonsWrapper = styled.div`
    ${Flex.VerticalCenter.Style};
    gap: 8px;
    ${ButtonContentWrapper} {
        width: 39px;
    }
`;

export const PaginationInfoWrapper = styled.div`
    ${Typography.Paragraph14.Normal.Neutral500.Style};
    line-height: 16px;
`;

export const ArrowRightIcon = styled(ArrowRight)``;
export const ArrowLeftIcon = styled(ArrowRight)`
    transform: rotate(180deg);
`;
