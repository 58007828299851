import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { TableComponent } from '@shared-component/table/table';
import { Cell } from '@shared-component/table/table-cell/table-cell';
import { isString } from '@shared-util/is-data';

import { useAccountActionsSelector } from '@selector/account/use-account.actions-selector';
import { useAccountSelector } from '@selector/account/use-account.selector';
import { useUsersActionsSelector } from '@selector/user/use-users.actions-selector';

import { CardsTableColumns } from './cards-table-columns';
import { AccountTableWrapper } from './cards-table.styles';
import { ActionsCell } from './cells/actions-cell';
import { BalanceCell } from './cells/balance-cell';
import { CashCell } from './cells/cash-cell';
import { InternetCell } from './cells/internet-cell';

export const CardsTable = observer(() => {
    const [{ value: valueUserId }] = useField('userId');
    const [{ value: valueAccountId }] = useField('accountId');
    const [account] = useAccountSelector();
    const { loadUsers } = useUsersActionsSelector();
    const { loadClientAccounts } = useAccountActionsSelector();

    const isUserSelected = isString(valueUserId);

    useEffect(() => void loadUsers(), []);

    useEffect(() => void (isUserSelected && loadClientAccounts(valueUserId)), [valueUserId]);

    const cards = account.getCards(valueUserId, valueAccountId);

    return (
        <AccountTableWrapper>
            <TableComponent data={cards} columns={CardsTableColumns} hasTableHead hasInnerScroll>
                <Cell />
                <Cell>{BalanceCell}</Cell>
                <Cell />
                <Cell>{InternetCell}</Cell>
                <Cell>{CashCell}</Cell>
                <Cell>{ActionsCell}</Cell>
            </TableComponent>
        </AccountTableWrapper>
    );
});
