// eslint-disable-next-line import/no-extraneous-dependencies
import { PropsWithChildren, ReactNode, useLayoutEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { Link } from '@shared-atom/link/link';
import { Logo } from '@shared-atom/logo/logo';
import { InformationModalContextProvider } from '@shared-component/information-modal/information-modal.provider';
import { ModelContextProvider } from '@shared-component/modal/modal-context';
import { PageLoader } from '@shared-component/page-loader/page-loader';
import {
    PageWrapperNavigation,
    PageWrapperNavigationProps,
} from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation';
import {
    LogoWrapper,
    PageContentWrapper,
    PageNavigationContentWrapper,
    PageNavigationWrapper,
    PageWrapperRoot,
} from '@shared-component/page-wrapper/page-wrapper.styles';
import { Scroll } from '@shared-component/scroll/scroll';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { isFalse } from '@shared-util/is-data';

import { ClientInformationModal } from '@component/informaton-modal/client-information-modal';
import { AdminModal } from '@component/modal/admin-modal';
import { PageEnum } from '@enum/page.enum';
import { useAdminActionsSelector } from '@selector/admin/use-admin.actions-selector';
import { useAdminSelector } from '@selector/admin/use-admin.selector';

interface PageWrapperInterface<T> extends PropsWithChildren<PageWrapperNavigationProps<T>> {
    additionalNavigationComponent?: ReactNode;
    additionalUserComponent?: ReactNode;
}

export const PageWrapper = <T extends string>({
    pageMenuList,
    additionalMenuList,
    additionalNavigationComponent,
    commissionList,
    additionalUserComponent,
}: PageWrapperInterface<T>) => {
    const [admin] = useAdminSelector();
    const { loadAdmin } = useAdminActionsSelector();
    const navigate = useRouterNavigate();

    useLayoutEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const checkUserExist = async () => {
            if (isFalse(admin.id)) {
                try {
                    await loadAdmin();
                } catch (error) {
                    navigate(PageEnum.Login);
                }
            }
        };
        checkUserExist();
    }, [admin]);

    return (
        <ModelContextProvider>
            <InformationModalContextProvider>
                <PageWrapperRoot>
                    <PageNavigationWrapper>
                        <Scroll>
                            <PageNavigationContentWrapper>
                                <LogoWrapper>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <Link to={{ pathname: PageEnum.Login }}>
                                        <Logo isWhite />
                                    </Link>
                                </LogoWrapper>
                                <PageWrapperNavigation
                                    pageMenuList={pageMenuList}
                                    additionalMenuList={additionalMenuList}
                                    commissionList={commissionList}
                                />
                                {additionalNavigationComponent}
                            </PageNavigationContentWrapper>
                            {additionalUserComponent !== undefined && (
                                <PageNavigationContentWrapper>{additionalUserComponent}</PageNavigationContentWrapper>
                            )}
                        </Scroll>
                    </PageNavigationWrapper>
                    <PageLoader>
                        <PageContentWrapper>
                            <Outlet />
                        </PageContentWrapper>
                    </PageLoader>
                    <AdminModal />
                    <ClientInformationModal />
                </PageWrapperRoot>
            </InformationModalContextProvider>
        </ModelContextProvider>
    );
};

PageWrapper.defaultProps = {
    additionalNavigationComponent: null,
    additionalUserComponent: null,
};
