import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Iron } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

interface GridProps {
    hasOne: boolean;
}

export const GridHeading = styled.div`
    ${Flex.SpaceBetween.Style};
`;

export const GridTitle = styled.h3`
    margin-bottom: 24px;
    ${Typography.M.Medium.Style};
`;
export const ActionsContainer = styled.div`
    ${Flex.VerticalCenter.Style};
    gap: 8px;
    padding-bottom: 24px;
`;

export const Grid = styled.div<GridProps>`
    display: grid;
    grid-template-columns: ${({ hasOne }) => (hasOne ? '1fr' : '1fr 1fr')};
    grid-gap: 0 8px;

    :not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid ${Iron};
    }
`;

export const Column = styled.div`
    display: grid;
    grid-template-columns: 192px 1fr 56px;
    grid-auto-rows: min-content;
    grid-gap: 16px 8px;
`;

export const BlockFooter = styled.div`
    margin: 32px 0 24px;
    ${Flex.JustifyRight.Style};
    flex-wrap: wrap;
    gap: 10px;
`;
