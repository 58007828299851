import { types } from 'mobx-state-tree';

import { AddressType } from '@shared-model/address-type/address.type';
import { FileModel } from '@shared-model/file-model/file-model';
import { UserProfileType } from '@shared-model/user-model/user-model-private/user-profile/user-profile.type';

export const CompanyPersonType = types.model({
    email: types.string,
    phone: types.optional(types.string, ''),
    profile: types.maybeNull(UserProfileType),
    address: types.maybeNull(AddressType),
    documents: types.maybeNull(types.array(FileModel)),
});
