import { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { noop } from '@shared-util/noop';

import { ModalEnum } from './modal.enum';
import { ModelContextInterface } from './modal.interface';

export const ModelContext = createContext<ModelContextInterface>({
    selectedModal: null,
    props: {},
    removeModalProps: noop,
    onClose: noop,
    onOpen: noop,
});

export const ModelContextProvider = ({ children }: { children?: ReactNode | ReactNode[] | JSX.Element }) => {
    const [selectedModal, setModal] = useState<ModalEnum | null>(null);
    const [props, setProps] = useState<Record<string, any>>({});
    const location = useLocation();

    useEffect(() => {
        setModal(null);
    }, [location]);

    const handleOpen = (modal: ModalEnum, newModalProps?: Record<string, any>) => {
        setModal(modal);
        if (newModalProps !== undefined) {
            setProps({ ...props, ...newModalProps });
        }
    };

    const handleClose = () => {
        setModal(null);
    };

    const removeModalProps = () => setProps({});

    const contextValue: ModelContextInterface = useMemo(
        () => ({
            selectedModal,
            props,
            removeModalProps,
            onClose: handleClose,
            onOpen: handleOpen,
        }),
        [selectedModal, props, removeModalProps, handleClose, handleOpen]
    );

    return <ModelContext.Provider value={contextValue}>{children}</ModelContext.Provider>;
};
