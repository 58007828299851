import { flow, getParent, Instance } from 'mobx-state-tree';

import { AdminGetClientAccounts, AdminUpdateAccountCurrencies, Mutation, Query } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { AccountModel } from '@model/account-model/account-model';
import { AccountCcType, AccountModelInstanceType, AccountModelType } from '@model/account-model/account-model.type';

const queryClientAccounts = (clientId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetAccounts'>>({ query: AdminGetClientAccounts, variables: { clientId } })
        .then(result => result.data?.adminGetAccounts ?? [])
        .catch(() => []);

// const mutationAccount = (variables: MutationUpdateAccountArgs) =>
//     apolloClient
//         .mutate<Pick<Mutation, 'updateAccount'>>({ mutation: addAccountSchema, variables })
//         .then(result => result.data?.updateAccount);

const mutationAccountCurrencies = (clientId: string, currencies: string[]) =>
    apolloClient
        .query<Pick<Mutation, 'adminUpdateAccountCurrencies'>>({
        query: AdminUpdateAccountCurrencies,
        variables: { clientId, currencies },
    })
        .then(result => result.data.adminUpdateAccountCurrencies);

export const accountModelActions = (self: Instance<typeof AccountModelType>) => ({
    setClientAccounts: (accounts: Instance<typeof AccountCcType>[]) => {
        accounts.forEach(account => {
            self.clientAccountMap.set(account.id, account);
        });
    },
    clearClientAccounts: () => self.clientAccountMap.clear(),
    loadClientAccounts: flow(function* loadClientAccounts(clientId: string) {
        const accountInstanceModel = getParent(self) as Instance<typeof AccountModel>;

        if (accountInstanceModel.loading.isLoading) {
            return;
        }
        accountInstanceModel.setAccountLoading(true);

        (self as unknown as ReturnType<typeof accountModelActions>).clearClientAccounts();

        try {
            const accounts: Instance<typeof AccountCcType>[] = yield queryClientAccounts(clientId);

            self.elements.clear();
            (self as unknown as ReturnType<typeof accountModelActions>).setClientAccounts(accounts);
        } catch (error) {
            console.log(error);
            throw error;
        } finally {
            accountInstanceModel.setAccountLoading(false);
        }
    }),
    // updateAccount: flow(function* updateAccount(_account: AccountInput, _id?: string) {
    //     const accountInstanceModel = getParent(self) as Instance<typeof AccountModel>;

    //     if (accountInstanceModel.loading.isLoading) {
    //         return;
    //     }
    //     accountInstanceModel.setAccountLoading(true);

    //     try {
    //         // const newAccount = yield mutationAccount({
    //         //     account: {
    //         //         ...account,
    //         //         id: id === undefined ? null : id,
    //         //         balance: parseFloat(account.balance as unknown as string) * 100,
    //         //     },
    //         // });
    //         // self.elements.set(newAccount.id, newAccount);
    //     } catch (error) {
    //         console.log(error);
    //         throw error;
    //     } finally {
    //         accountInstanceModel.setAccountLoading(false);
    //     }
    // }),
    updateAccountCurrencies: flow(function* updateAccountCurrencies(clientId: string, currencies: string[]) {
        const result = yield mutationAccountCurrencies(clientId, currencies);

        if (result.status as boolean) {
            (self as Instance<typeof AccountModelInstanceType>).loadClientAccounts(clientId);
        }
    }),
    setSelectedAccount: (id: any) => {
        self.selectedId = id;
    },
});
