import { types } from 'mobx-state-tree';

import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';

export const UserServiceType = types.model({
    id: types.maybeNull(types.string),
    isEurIban: types.boolean,
    isPaymentAccount: types.boolean,
    currency: CurrencyModelType,
    moneyTurnover: types.maybeNull(types.string),
    reasonAccount: types.maybeNull(types.string),
    sourceOfFunds: types.maybeNull(types.string),
});
