import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const OtpStepPhone = styled.p`
    ${Typography.SemiBold.Style}
`;

export const OtpStepPhoneWrapper = styled.div`
    margin-top: 8px;
`;

export const OtpWrapper = styled.div`
    margin-top: 16px;
`;

export const OtpStepTitle = styled.h1`
    ${Typography.XXL.Style}
`;

export const OtpStepDescription = styled.p`
    ${Typography.Fiord.Style}
`;
