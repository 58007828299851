import { useLazyQuery } from '@apollo/client';
import { useContext, useEffect, useMemo, useState } from 'react';

import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { AccountType, Query, AdminGetIbanqCredentials, IbanqCredentialsResponse } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { isTrue } from '@shared-util/is-data';

import { AccountsAddFormInterface } from '@page/accounts/accounts-add-form/accounts-add-form.interface';

export interface AccountIFXHookInterface extends AccountsAddFormInterface {
    ibanqCredentials: IbanqCredentialsResponse | null;
}

export const useAccountIFX = (): AccountIFXHookInterface => {
    const { params } = useCurrentNavigation();
    const { onOpen } = useContext(ModelContext);
    const [isIFXMode, setIFXMode] = useState(false);
    const [ibanqCredentials, setIbanqCredentials] = useState<IbanqCredentialsResponse | null>(null);
    const clientId = useMemo(() => (isTrue(params.userId) ? params.userId : params.companyId), [params]);
    const [getIbanqCredentials, { data }] = useLazyQuery<Pick<Query, 'adminGetIbanqCredentials'>>(
        AdminGetIbanqCredentials,
        { variables: { clientId } }
    );

    const handleAccountChanged = (accountType: AccountType) => {
        if (isTrue(accountType === AccountType.IFX)) {
            getIbanqCredentials();
            setIFXMode(true);
        } else {
            setIFXMode(false);
        }
    };

    const onAssignCredentials = () => {
        onOpen(ModalEnum.CreateIFXCredentialsModal, { componentProps: { getIbanqCredentials } });
    };

    useEffect(() => {
        if (isTrue(data?.adminGetIbanqCredentials)) {
            setIbanqCredentials(data?.adminGetIbanqCredentials as IbanqCredentialsResponse);
        }
    }, [data]);

    return {
        isIfxMode: isIFXMode,
        ibanqCredentials,
        handleAccountChanged,
        onAssignCredentials,
    };
};
