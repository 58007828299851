import { UserDocument, UserDocumentType } from '@shared-graphql';

export const initialUserDocument: Pick<UserDocument, 'type' | 'file'> = {
    type: '' as UserDocumentType,
    file: {
        key: '',
        path: '',
        filename: '',
        comment: null,
        comments: [],
    },
};
