import CurrencyInput from 'react-currency-input-field';
import styled from 'styled-components';

import { Dark, White } from '@shared-style/colors';
import { resetInputStyle } from '@shared-style/reset-style';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const InputAmountView = styled(CurrencyInput)`
    border: none;
    flex: 1;
    font-size: 16px;
    padding: 24px 16px 8px;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    background-color: transparent;
    width: 100%;

    ${resetInputStyle}
`;
