import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC, useMemo } from 'react';

import { Choose } from '@shared-atom/choose/choose';
import { Translate } from '@shared-atom/translate/translate';
import { DocumentFileUpload } from '@shared-component/file-upload/document-file-upload/document-file-upload';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { UserDocument, UserDocumentType } from '@shared-graphql';

import { DocumentComment } from './document-comment/document-comment';
import { DocumentUploaded } from './document-uploaded/document-uploaded';
import { PrivateDocumentFormProps } from './private-document-form.interface';
import { privateDocumentFormChooses, otherTypeDocument } from './private-document-form.options';

const MAX_OTHER_FILES_COUNT = 20;

export const PrivateDocumentForm: FC<PrivateDocumentFormProps> = observer(
    ({
        children,
        documentForm: {
            uploadedFiles,
            selectedUploadType,
            selectedUploadKey,
            selectedUpload,
            handleSelectedUploadType,
            handleUploadedFile,
            handleDeleteFile,
            handleDone,
        },
        listChooses = privateDocumentFormChooses,
        hasOtherTypeDocuments = false,
    }) => {
        const isSelectedUploadExist = selectedUpload !== null;
        const isSelectedUploadType = selectedUploadType !== null;
        const isSelectedUploadKey = selectedUploadKey !== undefined;
        const shouldRenderUpload =
            selectedUploadType === UserDocumentType.Other
                ? isSelectedUploadExist && isSelectedUploadKey
                : isSelectedUploadExist;

        const selectedUploadIndex = useMemo(
            () => uploadedFiles.findIndex(upload => upload === selectedUpload),
            [selectedUpload, uploadedFiles]
        );

        const filesUploaded = new Set(uploadedFiles.map(file => file.type));
        const otherTypeFilesUploaded = uploadedFiles.filter(file => file.type === UserDocumentType.Other);
        const commentFilePath = `${selectedUploadIndex}.file.comment`;
        const isOtherTypeVisible = hasOtherTypeDocuments && otherTypeFilesUploaded.length < MAX_OTHER_FILES_COUNT;

        return (
            <Formik initialValues={uploadedFiles} onSubmit={handleDone} enableReinitialize>
                <Form>
                    {!isSelectedUploadType && (
                        <>
                            {listChooses.map(choose => (
                                <FormRow key={choose.type}>
                                    <Choose
                                        onClick={handleSelectedUploadType(choose.type)}
                                        isChosen={filesUploaded.has(choose.type)}
                                        icon={choose.icon}
                                    >
                                        <Translate langKey={choose.title} />
                                    </Choose>
                                </FormRow>
                            ))}
                            {otherTypeFilesUploaded.map(choose => (
                                <FormRow key={choose?.file?.key}>
                                    <Choose
                                        onClick={handleSelectedUploadType(choose.type, choose?.file?.key)}
                                        isChosen={filesUploaded.has(choose.type)}
                                        icon={otherTypeDocument.icon}
                                    >
                                        <Translate langKey={choose?.file?.filename ?? ''} />
                                    </Choose>
                                </FormRow>
                            ))}
                            {isOtherTypeVisible && (
                                <FormRow>
                                    <Choose
                                        onClick={handleSelectedUploadType(otherTypeDocument.type)}
                                        icon={otherTypeDocument.icon}
                                    >
                                        <Translate langKey={otherTypeDocument.title} />
                                    </Choose>
                                </FormRow>
                            )}
                        </>
                    )}
                    {isSelectedUploadType && (
                        <>
                            {!shouldRenderUpload && (
                                <FormRow>
                                    <DocumentFileUpload onUploaded={handleUploadedFile} />
                                </FormRow>
                            )}

                            {shouldRenderUpload && (
                                <>
                                    <DocumentUploaded
                                        filename={(selectedUpload as UserDocument)?.file?.filename as string}
                                        keyId={(selectedUpload as UserDocument)?.file?.key}
                                        onDelete={handleDeleteFile((selectedUpload as UserDocument)?.file?.key ?? '')}
                                    />
                                    <DocumentComment path={commentFilePath} />
                                </>
                            )}
                        </>
                    )}
                    {children}
                </Form>
            </Formik>
        );
    }
);
