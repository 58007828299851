import { Form } from 'formik';
import { observer } from 'mobx-react-lite';

import { CardsChooseForm } from './cards-choose.form';
import { AccountsWrapper } from './cards-choose.styles';

export const CardsChoose = observer(() => (
    <AccountsWrapper>
        <Form>
            <CardsChooseForm />
        </Form>
    </AccountsWrapper>
));
