import { useQuery } from '@apollo/client';
import { Instance } from 'mobx-state-tree';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { AccountAdministration, AdminGetAccountAdministration, Query, SystemPaymentInput } from '@shared-graphql';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { AccountTypeUnion } from '@model/account-model/account-model.type';
import { initialAccountAdministration } from '@page/commission-profile/commission-profile-initials/commission-profile.initials';
import { useAccountActionsSelector } from '@selector/account/use-account.actions-selector';
import { useAccountSelector } from '@selector/account/use-account.selector';

import { LegalClientPaymentCreateInterface } from './legal-client-administration.interface';

export const useLegalClientAdministration = (): [
    Omit<AccountAdministration, 'balanceFee' | 'userType'>,
    Instance<typeof AccountTypeUnion>[],
    OnEventType<LegalClientPaymentCreateInterface>
] => {
    const params = useParams();
    const navigate = useRouterNavigate();

    const { data: accountAdministrationData } =
        useQuery<Pick<Query, 'adminGetAccountAdministration'>>(AdminGetAccountAdministration);

    const [{ ccAccounts }] = useAccountSelector();
    const { loadClientAccounts } = useAccountActionsSelector();

    const accountAdministration = isExist(accountAdministrationData)
        ? {
            ...initialAccountAdministration,
            ...accountAdministrationData?.adminGetAccountAdministration,
        }
        : initialAccountAdministration;

    useEffect(() => void loadClientAccounts(params.companyId as string), [params.companyId]);

    const handleSubmit = (paymentData: LegalClientPaymentCreateInterface) => {
        const account = ccAccounts.find(
            current => (current.id as unknown as string) === (paymentData.accountId as string)
        );

        const payment: SystemPaymentInput = {
            amount: paymentData.amount ?? 0,
            reason: paymentData.reason,
            accountId: account?.accountId as unknown as string,
            currency: (account?.currency as any).code,
            clientId: params.companyId as string,
        };

        const isAllFieldsExist = Object.values(payment).every(value => isExist(value));

        if (isAllFieldsExist) {
            // createSystemPayment({
            //     variables: {
            //         payment,
            //     },
            // });
        }
    };

    // const paymentId = (data?.adminCreateSystemPayment as Payment)?.id;
    const paymentId = undefined;

    useEffect(
        () =>
            void (
                paymentId !== undefined && navigate(PageEnum.PaymentSummary, { paymentId, clientId: params.companyId })
            ),
        [paymentId]
    );

    return [accountAdministration, ccAccounts, handleSubmit];
};
