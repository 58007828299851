import { CreateIfxCredentialsFormEnum } from '@component/modal/modals/create-ifx-credentials/create-ifx-credentials.enum';

export const CreateIfxCredentialsFormInitial = {
    [CreateIfxCredentialsFormEnum.ClientId]: '',
    [CreateIfxCredentialsFormEnum.IbanqClientSecret]: '',
    [CreateIfxCredentialsFormEnum.IbanqClientId]: '',
    [CreateIfxCredentialsFormEnum.IbanqPassword]: '',
    [CreateIfxCredentialsFormEnum.IbanqUsername]: '',
    [CreateIfxCredentialsFormEnum.IbanqCallbackSecret]: '',
};
