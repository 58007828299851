import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import ChevronDown from '@shared-component/input/assets/chevron-bottom.svg?react';
import { Neutral200, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

interface IsDateRangeVisibleInterface {
    isVisible: boolean;
}
interface DateRangePreviewDataInterface {
    clickable?: boolean;
    isHighlighted?: boolean;
}

export const Wrapper = styled.div`
    position: relative;
    ${Flex.VerticalCenter.Style};
`;
export const DateRangePreview = styled.div`
    ${Flex.VerticalCenter.Style};
    ${Typography.Paragraph14.Medium.Neutral600.Style};
    gap: 4px;
    padding: 8px;
`;
export const DateRangePreviewTitle = styled.div`
    ${Typography.Paragraph14.SemiBold.Neutral900.Style};
    margin-right: 4px;
    cursor: default;
`;
export const DateRangePreviewData = styled.div<DateRangePreviewDataInterface>`
    ${Flex.VerticalCenter.Style};
    gap: 4px;
    white-space: nowrap;
    cursor: ${({ clickable = true }) => (clickable ? 'pointer' : 'default')};
    ${({ isHighlighted = false }) =>
        isHighlighted &&
        `
        background:rgba(23, 187, 200, 0.24);
        border-radius: 26px;
        padding: 0 8px;
    `};
`;

export const DateRangeWrapper = styled.div<IsDateRangeVisibleInterface>`
    display: ${({ isVisible }) => (isVisible ? 'inline-flex' : 'none')};
    position: absolute;
    top: 40px;
    left: 0;
    padding: 16px;
    border: 1px solid ${Neutral200};
    border-radius: 8px;
    z-index: 1;
    background-color: ${White};
`;
export const ChevronDownIcon = styled(ChevronDown)<IsDateRangeVisibleInterface>`
    transform: rotate(${({ isVisible }) => (isVisible ? '180deg' : '0deg')});
    cursor: pointer;
`;
