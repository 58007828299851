import { ApolloProvider } from '@apollo/client';
import { routes } from '@router/routes';
import { memo } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { RouterProvider } from 'react-router-dom';

import { apolloClient } from '@connection/apollo-client';
import { rootStore } from '@store/root-store';
import { StoreContext } from '@store/store-context';

import { ApplicationContextProvider } from './application.context';
import { RootWrapper } from './application.styles';

const MainApplication = memo(() => (
    <RootWrapper>
        <RouterProvider router={routes} />
    </RootWrapper>
));
export const Application = () => (
    <StoreContext.Provider value={rootStore}>
        <GoogleReCaptchaProvider
            reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
            container={{
                parameters: {
                    badge: 'bottomleft',
                },
            }}
        >
            <ApolloProvider client={apolloClient}>
                <ApplicationContextProvider>
                    <MainApplication />
                </ApplicationContextProvider>
            </ApolloProvider>
        </GoogleReCaptchaProvider>
    </StoreContext.Provider>
);
