export enum LocalizationEnum {
    TransactionFxFee = 'transaction.fx.fee',
    CommonInsufficientFunds = 'common.insufficient.funds',
    CommonSave = 'common.save',
    CommonSummary = 'common.summary',
    CreatePartnerFormLastName = 'create.partner.form.last.name',
    CreatePartnerFormCompanyName = 'create.partner.form.company.name',
    CreatePartnerFormAlreadyExist = 'create.partner.form.already.exist',
    PaymentFormCurrencyAmountStepAddressBook = 'payment.form.currency.amount.step.address.book',
    PaymentFormCurrencyAmountStepAddressBookSelect = 'payment.form.currency.amount.step.address.book.select',
    PaymentFormError = 'payment.form.error',
    CurrencyExchangeFormError = 'currency.exchange.form.error',
    PaymentSummaryRowOrderIdTitle = 'payment.summary.row.order.id.title',
    PaymentFormCurrencyAmountStepAddressBookSelectEmpty = 'payment.form.currency.amount.step.address.book.select.empty',
    PaymentSummaryRowSettlementDateTitlе = 'payment.summary.row.settlement.date.titlе',
    TaskListEmptyTitle = 'task.list.empty.title',
    TaskListEmptyText = 'task.list.empty.text',
    PaymentSummaryRowRepeatTransferTitle = 'payment.summary.row.repeat.transfer.title',
    PaymentSummaryRowTransferTitleReceipt = 'payment.summary.row.transfer.title.receipt',
    AccountDetailsInfoTitle = 'account.details.info.title',
    AccountDetailsInfoText = 'account.details.info.text',
    AccountDetailsInfoCountriesList = 'account.details.info.countries.list',
    PermittedCountriesTitle = 'permitted.countries.title',
    DashboardTransactionsTaskListTitle = 'dashboard.transactions.task.list.title',
    PermittedCountriesDescription1 = 'permitted.countries.description_1',
    PermittedCountriesDescription2 = 'permitted.countries.description_2',
    PermittedCountriesDescription3 = 'permitted.countries.description_3',
    PermittedCountriesDescription4 = 'permitted.countries.description_4',
    PermittedCountriesDescription5 = 'permitted.countries.description_5',
    DayPickerRangeFrom = 'day.picker.range.from',
    DayPickerRangeTo = 'day.picker.range.to',
    PrivateFormNameTitleCopy = 'private.form.name.title_copy',
    CommissionProfileCustomerListTitle9 = 'commission.profile.customer.list.title_9',
    CommissionProfilePaymentListTitle6 = 'commission.profile.payment.list.title_6',
    CommissionProfilePaymentListTitle7 = 'commission.profile.payment.list.title_7',
    CommissionProfilePaymentListTitle8 = 'commission.profile.payment.list.title_8',
    CommissionProfilePaymentListTitle9 = 'commission.profile.payment.list.title_9',
    PaymentFormBeneficiaryStepCompanyName = 'payment.form.beneficiary.step.company.name',
    PaymentFormBeneficiaryStepStateOrProvince = 'payment.form.beneficiary.step.state.or.province',
    PaymentFormBeneficiaryStepPostCode = 'payment.form.beneficiary.step.post.code',
    PaymentFormCurrencyAmountStepAccountNumber = 'payment.form.currency.amount.step.account.number',
    PaymentFormCurrencyAmountStepSortCode = 'payment.form.currency.amount.step.sort.code',
    DashboardTransactionsEmptyTitle = 'dashboard.transactions.empty.title',
    DashboardTransactionsEmptyText = 'dashboard.transactions.empty.text',
    DashboardAccountsEmptyTitle = 'dashboard.accounts.empty.title',
    DashboardAccountsEmptyText = 'dashboard.accounts.empty.text',
    PaymentFormCreatedStepTitle = 'payment.form.created.step.title',
    CommonCounterAccount = 'common.counter.account',
    CommonPaymentAmount = 'common.payment.amount',
    CommonPaymentFee = 'common.payment.fee',
    CommonBalanceAfterTransaction = 'common.balance.after.transaction',
    TransactionCurrencyExchangeRate = 'transaction.currency.exchange.rate',
    DashboardAddressBookEmptyTitle = 'dashboard.address.book.empty.title',
    DashboardAddressBookEmptyText = 'dashboard.address.book.empty.text',
    AddressBookModalEditTitle = 'address.book.modal.edit.title',
    ExchangeModalInformationTotalAmount = 'exchange.modal.information.total.amount',
    PaymentCreatedTitle = 'payment.created.title',
    PaymentSignedTitle = 'payment.signed.title',
    SmsVerificationTitle = 'sms.verification.title',
    SmsVerificationDescription1 = 'sms.verification.description1',
    SmsVerificationDescription2 = 'sms.verification.description2',
    PaymentSignedDescription1 = 'payment.signed.description1',
    PaymentSignedDescription2 = 'payment.signed.description2',
    CommonSign = 'common.sign',
    CommissionProfileTitle = 'commission.profile.title',
    CommissionProfileDownload = 'commission.profile.download',
    CommissionProfileAdministrationTitle = 'commission.profile.administration.title',
    CommissionProfileAdministrationListTitle1 = 'commission.profile.administration.list.title_1',
    CommissionProfileAdministrationListTitle2 = 'commission.profile.administration.list.title_2',
    CommissionProfileAdministrationListTitle3 = 'commission.profile.administration.list.title_3',
    CommissionProfileAdministrationListTitle4 = 'commission.profile.administration.list.title_4',
    CommissionProfileAdministrationListTitle5 = 'commission.profile.administration.list.title_5',
    CommissionProfileAdministrationListTitle6 = 'commission.profile.administration.list.title_6',
    CommissionProfileAdministrationListInfo = 'commission.profile.administration.list.info',
    CommissionProfileAdministrationFree = 'commission.profile.administration.free',
    CommissionProfilePaymentTitle = 'commission.profile.payment.title',
    CommissionProfilePaymentListTitle1 = 'commission.profile.payment.list.title_1',
    CommissionProfilePaymentListTitle2 = 'commission.profile.payment.list.title_2',
    CommissionProfilePaymentListTitle3 = 'commission.profile.payment.list.title_3',
    CommissionProfilePaymentListTitle4 = 'commission.profile.payment.list.title_4',
    CommissionProfilePaymentListTitle5 = 'commission.profile.payment.list.title_5',
    CommissionProfilePaymentListUp = 'commission.profile.payment.list.up',
    CommissionProfilePaymentListOver = 'commission.profile.payment.list.over',
    CommissionProfileExchangeTitle = 'commission.profile.exchange.title',
    CommissionProfileExchangeListTitle1 = 'commission.profile.exchange.list.title_1',
    CommissionProfileExchangeListDescription1 = 'commission.profile.exchange.list.description_1',
    CommissionProfileCustomerTitle = 'commission.profile.customer.title',
    CommissionProfileCustomerListTitle1 = 'commission.profile.customer.list.title_1',
    CommissionProfileCustomerListTitle2 = 'commission.profile.customer.list.title_2',
    CommissionProfileCustomerListTitle3 = 'commission.profile.customer.list.title_3',
    CommissionProfileCustomerListTitle4 = 'commission.profile.customer.list.title_4',
    CommissionProfileCustomerListTitle5 = 'commission.profile.customer.list.title_5',
    CommissionProfileCustomerListTitle6 = 'commission.profile.customer.list.title_6',
    CommissionProfileCustomerListTitle7 = 'commission.profile.customer.list.title_7',
    CommissionProfileCustomerListTitle8 = 'commission.profile.customer.list.title_8',
    PaymentFormBeneficiaryStepTitle = 'payment.form.beneficiary.step.title',
    PaymentFormDetailsStepTitle = 'payment.form.details.step.title',
    PaymentFormBeneficiaryStepName = 'payment.form.beneficiary.step.name',
    PaymentFormBeneficiaryStepLastName = 'payment.form.beneficiary.step.last.name',
    PaymentFormBeneficiaryStepBeneficiaryTypeBlockTitle = 'payment.form.beneficiary.step.beneficiary.type.block.title',
    PaymentFormBeneficiaryStepNameBlockTitle = 'payment.form.beneficiary.step.name.block.title',
    PaymentFormBeneficiaryStepAddressBlockTitle = 'payment.form.beneficiary.step.address.block.title',
    PaymentFormBeneficiaryStepAddress = 'payment.form.beneficiary.step.address',
    PaymentFormBeneficiaryStepCity = 'payment.form.beneficiary.step.city',
    PaymentFormBeneficiaryStepCountry = 'payment.form.beneficiary.step.country',
    PaymentFormDetailsStepDetailsBlockTitle = 'payment.form.details.step.details.block.title',
    PaymentFormDetailsStepDescription = 'payment.form.details.step.description',
    PaymentFormDetailsStepAttachFileTitle = 'payment.form.details.step.attach.file.title',
    PaymentFormDetailsStepAttachFileDescription = 'payment.form.details.step.attach.file.description',
    CommonNext = 'common.next',
    PaymentFormConfirmationStepTitle = 'payment.form.confirmation.step.title',
    PaymentFormConfirmationStepBeneficiaryName = 'payment.form.confirmation.step.beneficiary.name',
    PaymentFormConfirmationStepBeneficiaryAccount = 'payment.form.confirmation.step.beneficiary.account',
    PaymentFormConfirmationStepBalanceAfter = 'payment.form.confirmation.step.balance.after',
    PaymentFormConfirmationStepPaymentFee = 'payment.form.confirmation.step.payment.fee',
    PaymentFormConfirmationStepConversion = 'payment.form.confirmation.step.conversion',
    PaymentFormConfirmationStepFxRate = 'payment.form.confirmation.step.fx.rate',
    PaymentFormConfirmationStepFxFee = 'payment.form.confirmation.step.fx.fee',
    PaymentFormConfirmationStepCurrencySell = 'payment.form.confirmation.step.currency.sell',
    PaymentFormConfirmationStepCurrencyBuy = 'payment.form.confirmation.step.currency.buy',
    PaymentFormConfirmationStepCurrencyPayIn = 'payment.form.confirmation.step.currency.pay.in',
    PaymentFormConfirmationStepCurrency = 'payment.form.confirmation.step.currency',
    PaymentFormConfirmationStepAmount = 'payment.form.confirmation.step.amount',
    ResetPasswordWelcomeClub = 'reset.password.welcome.club',
    ResetPasswordTitle = 'reset.password.title',
    CommonApplyForBusinessAccount = 'common.apply.for.business.account',
    RegistrationUserTypeLegalRightTitle = 'registration.user.type.legal.right.title',
    RegistrationUserTypeLegalRightDescription = 'registration.user.type.legal.right.description',
    RegistrationLegalAddressPostalPageDescription = 'registration.legal.address.postal.page.description',
    RegistrationLegalPersonsDocumentsPassportTitle = 'registration.legal.persons.documents.passport.title',
    RegistrationLegalPersonsPrivateAddressTitle = 'registration.legal.persons.private.address.title',
    CommonCompanyRelationDirectorsProxy = 'common.company.relation.directors.proxy',
    CommonCompanyRelationUsers = 'common.company.relation.users',
    RegistrationLegalDocumentsDirectorsDescription = 'registration.legal.documents.directors.description',
    PrivateDocumentsPassportDescription = 'private.documents.passport.description',
    PrivateDocumentsProofOfAddressDescription = 'private.documents.proof.of.address.description',
    PrivateDocumentsSelfieDescription = 'private.documents.selfie.description',
    RegistrationLegalShareholdersCompanyDescription = 'registration.legal.shareholders.company.description',
    CommonUserA = 'common.user.a',
    CommonUserB = 'common.user.b',
    CommonUserC = 'common.user.c',
    PaymentFormCurrencyAmountStepAmount = 'payment.form.currency.amount.step.amount',
    PaymentFormCurrencyAmountStepCurrency = 'payment.form.currency.amount.step.currency',
    PaymentFormCurrencyAmountStepCurrencyAmountBlockTitle = 'payment.form.currency.amount.step.currency.amount.block.title',
    PaymentFormCurrencyAmountStepBeneficiaryBlockTitle = 'payment.form.currency.amount.step.beneficiary.block.title',
    PaymentFormCurrencyAmountStepIban = 'payment.form.currency.amount.step.iban',
    PaymentFormCurrencyAmountStepBic = 'payment.form.currency.amount.step.bic',
    PaymentFormCurrencyAmountStepBankName = 'payment.form.currency.amount.step.bank.name',
    PaymentFormCurrencyAmountStepFeesBlockTitle = 'payment.form.currency.amount.step.fees.block.title',
    PaymentFormCurrencyAmountStepTotalBlockTitle = 'payment.form.currency.amount.step.total.block.title',
    PaymentFormCurrencyAmountStepPaymentFee = 'payment.form.currency.amount.step.payment.fee',
    PaymentFormCurrencyAmountStepFxRate = 'payment.form.currency.amount.step.fx.rate',
    PaymentFormCurrencyAmountStepFxFee = 'payment.form.currency.amount.step.fx.fee',
    PaymentFormCurrencyAmountStepPayIn = 'payment.form.currency.amount.step.pay.in',
    PaymentFormCurrencyAmountStepTitle = 'payment.form.currency.amount.step.title',
    SupportUkraineModalTitle = 'support.ukraine.modal.title',
    SupportUkraineModalItem1 = 'support.ukraine.modal.item_1',
    SupportUkraineModalItem2 = 'support.ukraine.modal.item_2',
    SupportUkraineModalItem3 = 'support.ukraine.modal.item_3',
    SupportUkraineModalItem4 = 'support.ukraine.modal.item_4',
    SupportUkraineModalButton = 'support.ukraine.modal.button',
    SupportUkraineModalHint = 'support.ukraine.modal.hint',
    SupportUkraineModalClose = 'support.ukraine.modal.close',
    TermsAndConditionItem11 = 'terms.and.condition.item_11',
    TermsAndConditionItem12 = 'terms.and.condition.item_12',
    TermsAndConditionItem13 = 'terms.and.condition.item_13',
    TermsAndConditionItem14 = 'terms.and.condition.item_14',
    TermsAndConditionItem15 = 'terms.and.condition.item_15',
    TermsAndConditionItemPromotion = 'terms.and.condition.item_promotion',
    TermsAndConditionText11 = 'terms.and.condition.text_11',
    TermsAndConditionText12 = 'terms.and.condition.text_12',
    TermsAndConditionText14 = 'terms.and.condition.text_14',
    TermsAndConditionText13 = 'terms.and.condition.text_13',
    TermsAndConditionText15 = 'terms.and.condition.text_15',
    TermsAndConditionTextPromotion = 'terms.and.condition.text_promotion',
    LegalCompanyBusinessConductionCountryInformation = 'legal.company.business.conduction.country.information',
    LegalCompanyPartGroupInformation = 'legal.company.part.group.information',
    LegalCompanySiteTitle = 'legal.company.site.title',
    PrivateAcceptAgreementTitle1 = 'private.accept.agreement.title_1',
    PrivateAcceptAgreementTitle2 = 'private.accept.agreement.title_2',
    PrivateAcceptAgreementPageButton = 'private.accept.agreement.page.button',
    LegalServiceEuroAccountTitle = 'legal.service.euro.account.title',
    LegalServiceSwiftAccountTitle = 'legal.service.swift.account.title',
    LegalServiceMulticurrancyAccountTitle = 'legal.service.multicurrancy.account.title',
    ComplaintsDescription6 = 'complaints.description_6',
    CommonCompanyRelationShareholder = 'common.company.relation.shareholder',
    CommonCompanyRelationUbo = 'common.company.relation.ubo',
    RegistrationLegalSuccessTitle = 'registration.legal.success.title',
    RegistrationLegalSuccessDescription = 'registration.legal.success.description',
    CommonExplore = 'common.explore',
    RegistrationUserTypeLegalLeftTitle = 'registration.user.type.legal.left.title',
    RegistrationUserTypeLegalLeftDescription = 'registration.user.type.legal.left.description',
    RegistrationUserTypeLegalLeftListItem1 = 'registration.user.type.legal.left.list.item_1',
    RegistrationUserTypeLegalLeftListItem2 = 'registration.user.type.legal.left.list.item_2',
    RegistrationUserTypeLegalLeftListItem3 = 'registration.user.type.legal.left.list.item_3',
    PrivacyPolicyText12 = 'privacy.policy.text_12',
    PrivacyPolicyText13 = 'privacy.policy.text_13',
    RegistrationLegalPersonsDocumentsPassportDescription = 'registration.legal.persons.documents.passport.description',
    RegistrationLegalPersonsPrivateProfileTitle = 'registration.legal.persons.private.profile.title',
    RegistrationLegalPersonsPrivateProfileDescription = 'registration.legal.persons.private.profile.description',
    RegistrationLegalPersonsPrivateAddressDescription = 'registration.legal.persons.private.address.description',
    LegalPersonsPrivatePersonalTitle = 'legal.persons.private.personal.title',
    LegalPersonsPrivateDescription = 'legal.persons.private.description',
    RegistrationLegalDocumentsCompanyDescription = 'registration.legal.documents.company.description',
    RegistrationLegalDocumentsCompanyCorporateDescription = 'registration.legal.documents.company.corporate.description',
    RegistrationLegalDocumentsCompanyArticlesDescription = 'registration.legal.documents.company.articles.description',
    RegistrationLegalDocumentsCompanyBusinessDescription = 'registration.legal.documents.company.business.description',
    RegistrationLegalDocumentsCompanyOwnershipStructureChartDescription = 'registration.legal.documents.company.ownership.structure.chart.description',
    RegistrationLegalDocumentsCompanyGroupStructureChartDescription = 'registration.legal.documents.company.group.structure.chart.description',
    RegistrationLegalDocumentsShareholdersDescription = 'registration.legal.documents.shareholders.description',
    RegistrationLegalDocumentsUboDescription = 'registration.legal.documents.ubo.description',
    RegistrationLegalDocumentsUsersDescription = 'registration.legal.documents.users.description',
    FooterNavLegalComplaintsTitle = 'footer.nav.legal.complaints.title',
    RegistrationLegalPersonsPrivateFormShareholder = 'registration.legal.persons.private.form.shareholder',
    RegistrationLegalPersonsPrivateFormShareholderPercent = 'registration.legal.persons.private.form.shareholder.percent',
    RegistrationLegalPersonsPrivateFormUbo = 'registration.legal.persons.private.form.ubo',
    RegistrationLegalPersonsPrivateFormUboPercent = 'registration.legal.persons.private.form.ubo.percent',
    RegistrationLegalPersonsPrivateFormDirector = 'registration.legal.persons.private.form.director',
    RegistrationLegalPersonsPrivateFormPowerOfAttorney = 'registration.legal.persons.private.form.power.of.attorney',
    RegistrationLegalPersonsPrivateFormUser = 'registration.legal.persons.private.form.user',
    RegistrationLegalPersonsPrivateFormRole = 'registration.legal.persons.private.form.role',
    RegistrationLegalPersonsPrivateFormEmail = 'registration.legal.persons.private.form.email',
    RegistrationLegalPersonsPrivateFormPhone = 'registration.legal.persons.private.form.phone',
    RegistrationLegalPersonsPrivatePersonalTitle = 'registration.legal.persons.private.personal.title',
    RegistrationLegalPersonsPrivatePersonalDescription = 'registration.legal.persons.private.personal.description',
    FooterNavProductBankingTitle = 'footer.nav.product.banking.title',
    FooterNavProductExchangeTitle = 'footer.nav.product.exchange.title',
    FooterNavProductPriceTitle = 'footer.nav.product.price.title',
    FooterNavProductInternationalTitle = 'footer.nav.product.international.title',
    FooterNavCompanyTitle = 'footer.nav.company.title',
    FooterNavCompanyAboutTitle = 'footer.nav.company.about.title',
    FooterNavCompanyContactTitle = 'footer.nav.company.contact.title',
    FooterNavLegalTitle = 'footer.nav.legal.title',
    FooterNavLegalTermsTitle = 'footer.nav.legal.terms.title',
    FooterNavLegalPrivacyTitle = 'footer.nav.legal.privacy.title',
    FooterNavLegalSecurityTitle = 'footer.nav.legal.security.title',
    FooterNavProductBusinessTitle = 'footer.nav.product.business.title',
    TermsAndConditionText3 = 'terms.and.condition.text_3',
    TermsAndConditionText4 = 'terms.and.condition.text_4',
    TermsAndConditionText5 = 'terms.and.condition.text_5',
    TermsAndConditionText6 = 'terms.and.condition.text_6',
    TermsAndConditionText7 = 'terms.and.condition.text_7',
    TermsAndConditionText8 = 'terms.and.condition.text_8',
    TermsAndConditionText9 = 'terms.and.condition.text_9',
    TermsAndConditionText10 = 'terms.and.condition.text_10',
    PrivacyPolicyText2 = 'privacy.policy.text_2',
    PrivacyPolicyText3 = 'privacy.policy.text_3',
    PrivacyPolicyText4 = 'privacy.policy.text_4',
    PrivacyPolicyText5 = 'privacy.policy.text_5',
    PrivacyPolicyText6 = 'privacy.policy.text_6',
    PrivacyPolicyText7 = 'privacy.policy.text_7',
    PrivacyPolicyText8 = 'privacy.policy.text_8',
    PrivacyPolicyText9 = 'privacy.policy.text_9',
    PrivacyPolicyText10 = 'privacy.policy.text_10',
    PrivacyPolicyText11 = 'privacy.policy.text_11',
    PrivacyPolicyItem7 = 'privacy.policy.item_7',
    PrivacyPolicyItem8 = 'privacy.policy.item_8',
    PrivacyPolicyItem9 = 'privacy.policy.item_9',
    PrivacyPolicyItem10 = 'privacy.policy.item_10',
    PrivacyPolicyItem11 = 'privacy.policy.item_11',
    PrivacyPolicyItem12 = 'privacy.policy.item_12',
    ComplaintsList1Description2 = 'complaints.list_1.description_2',
    ComplaintsList1Description3 = 'complaints.list_1.description_3',
    ComplaintsList1Description4 = 'complaints.list_1.description_4',
    ComplaintsList1Description5 = 'complaints.list_1.description_5',
    ComplaintsList1Description1 = 'complaints.list_1.description_1',
    ComplaintsList2Description1 = 'complaints.list_2.description_1',
    ComplaintsList2Description2 = 'complaints.list_2.description_2',
    ComplaintsList2Description3 = 'complaints.list_2.description_3',
    ComplaintsList2Description4 = 'complaints.list_2.description_4',
    DownloadOtherDocumentButtonTitle = 'download.other.document.button.title',
    TermsAndConditionItemsTitle = 'terms.and.condition.items.title',
    TermsAndConditionItem1 = 'terms.and.condition.item_1',
    TermsAndConditionItem2 = 'terms.and.condition.item_2',
    TermsAndConditionItem3 = 'terms.and.condition.item_3',
    TermsAndConditionItem4 = 'terms.and.condition.item_4',
    TermsAndConditionItem5 = 'terms.and.condition.item_5',
    TermsAndConditionItem6 = 'terms.and.condition.item_6',
    TermsAndConditionItem7 = 'terms.and.condition.item_7',
    TermsAndConditionItem8 = 'terms.and.condition.item_8',
    TermsAndConditionItem9 = 'terms.and.condition.item_9',
    TermsAndConditionItem10 = 'terms.and.condition.item_10',
    HomeAboutExploreButtonTitle = 'home.about.explore.button.title',
    HomeAboutQuestionButtonTitle = 'home.about.question.button.title',
    FooterNavProductTitle = 'footer.nav.product.title',
    HelpFaqAccordionItem7Description2 = 'help.faq.accordion.item_7.description_2',
    HelpFaqAccordionItem7Description3 = 'help.faq.accordion.item_7.description_3',
    HelpFaqAccordionItem7Description4 = 'help.faq.accordion.item_7.description_4',
    HelpFaqAccordionItem7Title4 = 'help.faq.accordion.item_7.title_4',
    FooterDescription1 = 'footer.description_1',
    FooterDescription2 = 'footer.description_2',
    FooterDescription3 = 'footer.description_3',
    FooterDescription4 = 'footer.description_4',
    FooterDescription5 = 'footer.description_5',
    ComplaintsTitle = 'complaints.title',
    ComplaintsDescription1 = 'complaints.description_1',
    ComplaintsSubTitle1 = 'complaints.sub.title_1',
    ComplaintsDescription2 = 'complaints.description_2',
    ComplaintsDescription3 = 'complaints.description_3',
    ComplaintsDescription4 = 'complaints.description_4',
    ComplaintsDescription5 = 'complaints.description_5',
    ComplaintsSubTitle2 = 'complaints.sub.title_2',
    ComplaintsSubTitle3 = 'complaints.sub.title_3',
    ComplaintsSubTitle4 = 'complaints.sub.title_4',
    ComplaintsByPost = 'complaints.by.post',
    ComplaintsByPhone = 'complaints.by.phone',
    ComplaintsByEmail = 'complaints.by.email',
    PrivacyPolicyText1 = 'privacy.policy.text_1',
    PrivacyPolicyItemsTitle = 'privacy.policy.items.title',
    ReadPrivacyPolicy = 'read.privacy.policy',
    PrivacyPolicyItem1 = 'privacy.policy.item_1',
    PrivacyPolicyItem2 = 'privacy.policy.item_2',
    PrivacyPolicyItem3 = 'privacy.policy.item_3',
    PrivacyPolicyItem4 = 'privacy.policy.item_4',
    PrivacyPolicyItem5 = 'privacy.policy.item_5',
    PrivacyPolicyItem6 = 'privacy.policy.item_6',
    HelpFaqAccordionItem5Title5 = 'help.faq.accordion.item_5.title_5',
    HelpFaqAccordionItem5Title6 = 'help.faq.accordion.item_5.title_6',
    HelpFaqAccordionItem5Title7 = 'help.faq.accordion.item_5.title_7',
    HelpFaqAccordionItem5Title8 = 'help.faq.accordion.item_5.title_8',
    HelpFaqAccordionItem5Title9 = 'help.faq.accordion.item_5.title_9',
    HelpFaqAccordionItem5Description1 = 'help.faq.accordion.item_5.description_1',
    HelpFaqAccordionItem5Description2 = 'help.faq.accordion.item_5.description_2',
    HelpFaqAccordionItem5Description3 = 'help.faq.accordion.item_5.description_3',
    HelpFaqAccordionItem5Description4 = 'help.faq.accordion.item_5.description_4',
    HelpFaqAccordionItem5Description5 = 'help.faq.accordion.item_5.description_5',
    HelpFaqAccordionItem5Description6 = 'help.faq.accordion.item_5.description_6',
    HelpFaqAccordionItem5Description7 = 'help.faq.accordion.item_5.description_7',
    HelpFaqAccordionItem5Description8 = 'help.faq.accordion.item_5.description_8',
    HelpFaqAccordionItem5Description9 = 'help.faq.accordion.item_5.description_9',
    HelpFaqAccordionItem6Title1 = 'help.faq.accordion.item_6.title_1',
    HelpFaqAccordionItem6Title2 = 'help.faq.accordion.item_6.title_2',
    HelpFaqAccordionItem6Title3 = 'help.faq.accordion.item_6.title_3',
    HelpFaqAccordionItem6Title4 = 'help.faq.accordion.item_6.title_4',
    HelpFaqAccordionItem6Title5 = 'help.faq.accordion.item_6.title_5',
    HelpFaqAccordionItem6Title6 = 'help.faq.accordion.item_6.title_6',
    HelpFaqAccordionItem6Description1 = 'help.faq.accordion.item_6.description_1',
    HelpFaqAccordionItem6Description2 = 'help.faq.accordion.item_6.description_2',
    HelpFaqAccordionItem6Description3 = 'help.faq.accordion.item_6.description_3',
    HelpFaqAccordionItem6Description4 = 'help.faq.accordion.item_6.description_4',
    HelpFaqAccordionItem6Description5 = 'help.faq.accordion.item_6.description_5',
    HelpFaqAccordionItem6Description6 = 'help.faq.accordion.item_6.description_6',
    HelpFaqAccordionItem7Title1 = 'help.faq.accordion.item_7.title_1',
    HelpFaqAccordionItem7Title2 = 'help.faq.accordion.item_7.title_2',
    HelpFaqAccordionItem7Title3 = 'help.faq.accordion.item_7.title_3',
    HelpFaqAccordionItem7Description1 = 'help.faq.accordion.item_7.description_1',
    PricesTabInformationColumn2Title1 = 'prices.tab.information.column_2.title_1',
    PricesTabInformationColumn2Title2 = 'prices.tab.information.column_2.title_2',
    PricesTabInformationColumn2Title3 = 'prices.tab.information.column_2.title_3',
    PricesTabInformationColumn3Title1 = 'prices.tab.information.column_3.title_1',
    PricesTabInformationListDescription1 = 'prices.tab.information.list.description_1',
    PricesTabInformationListDescription2 = 'prices.tab.information.list.description_2',
    HelpFaqAccordionItem3Title1 = 'help.faq.accordion.item_3.title_1',
    HelpFaqAccordionItem3Title2 = 'help.faq.accordion.item_3.title_2',
    HelpFaqAccordionItem3Title3 = 'help.faq.accordion.item_3.title_3',
    HelpFaqAccordionItem3Title4 = 'help.faq.accordion.item_3.title_4',
    HelpFaqAccordionItem3Title5 = 'help.faq.accordion.item_3.title_5',
    HelpFaqAccordionItem3Title6 = 'help.faq.accordion.item_3.title_6',
    HelpFaqAccordionItem3Description1 = 'help.faq.accordion.item_3.description_1',
    HelpFaqAccordionItem3Description2 = 'help.faq.accordion.item_3.description_2',
    HelpFaqAccordionItem3Description3 = 'help.faq.accordion.item_3.description_3',
    HelpFaqAccordionItem3Description4 = 'help.faq.accordion.item_3.description_4',
    HelpFaqAccordionItem3Description5 = 'help.faq.accordion.item_3.description_5',
    HelpFaqAccordionItem3Description6 = 'help.faq.accordion.item_3.description_6',
    HelpFaqAccordionItem4Title1 = 'help.faq.accordion.item_4.title_1',
    HelpFaqAccordionItem4Title2 = 'help.faq.accordion.item_4.title_2',
    HelpFaqAccordionItem4Title3 = 'help.faq.accordion.item_4.title_3',
    HelpFaqAccordionItem4Title4 = 'help.faq.accordion.item_4.title_4',
    HelpFaqAccordionItem4Description1 = 'help.faq.accordion.item_4.description_1',
    HelpFaqAccordionItem4Description2 = 'help.faq.accordion.item_4.description_2',
    HelpFaqAccordionItem4Description3 = 'help.faq.accordion.item_4.description_3',
    HelpFaqAccordionItem4Description4 = 'help.faq.accordion.item_4.description_4',
    HelpFaqAccordionItem5Title1 = 'help.faq.accordion.item_5.title_1',
    HelpFaqAccordionItem5Title2 = 'help.faq.accordion.item_5.title_2',
    HelpFaqAccordionItem5Title3 = 'help.faq.accordion.item_5.title_3',
    HelpFaqAccordionItem5Title4 = 'help.faq.accordion.item_5.title_4',
    HelpFaqAccordionItem2Title4 = 'help.faq.accordion.item_2.title_4',
    HelpFaqAccordionItem2Title5 = 'help.faq.accordion.item_2.title_5',
    HelpFaqAccordionItem2Title6 = 'help.faq.accordion.item_2.title_6',
    HelpFaqAccordionItem2Title7 = 'help.faq.accordion.item_2.title_7',
    HelpFaqAccordionItem2Title8 = 'help.faq.accordion.item_2.title_8',
    HelpFaqAccordionItem2Description1 = 'help.faq.accordion.item_2.description_1',
    HelpFaqAccordionItem2Description2 = 'help.faq.accordion.item_2.description_2',
    HelpFaqAccordionItem2Description3 = 'help.faq.accordion.item_2.description_3',
    HelpFaqAccordionItem2Description4 = 'help.faq.accordion.item_2.description_4',
    HelpFaqAccordionItem2Description5 = 'help.faq.accordion.item_2.description_5',
    HelpFaqAccordionItem2Description6 = 'help.faq.accordion.item_2.description_6',
    HelpFaqAccordionItem2Description7 = 'help.faq.accordion.item_2.description_7',
    HelpFaqAccordionItem2Description8 = 'help.faq.accordion.item_2.description_8',
    ExchangeModalInformationConversionDate = 'exchange.modal.information.conversion.date',
    CommonClose = 'common.close',
    ExchangeModalErrorMessage = 'exchange.modal.error.message',
    ExchangeModalSuccessMessage = 'exchange.modal.success.message',
    PricesDescription = 'prices.description',
    PricesCardTitle1 = 'prices.card.title_1',
    PricesCardTitle2 = 'prices.card.title_2',
    PricesCardTitle3 = 'prices.card.title_3',
    PricesCardDescription1 = 'prices.card.description_1',
    PricesCardDescription2 = 'prices.card.description_2',
    PricesCardDescription3 = 'prices.card.description_3',
    PricesTabInformationTitle1 = 'prices.tab.information.title_1',
    PricesTabInformationTitle2 = 'prices.tab.information.title_2',
    PricesTabInformationTitle3 = 'prices.tab.information.title_3',
    PricesTabInformationColumn1Title1 = 'prices.tab.information.column_1.title_1',
    PricesTabInformationColumn1Title2 = 'prices.tab.information.column_1.title_2',
    PricesTabInformationColumn1Title3 = 'prices.tab.information.column_1.title_3',
    HelpFaqAccordionItemTitle6 = 'help.faq.accordion.item.title_6',
    HelpFaqAccordionItemTitle7 = 'help.faq.accordion.item.title_7',
    HelpFaqAccordionItemTitle8 = 'help.faq.accordion.item.title_8',
    HelpFaqAccordionItemTitle9 = 'help.faq.accordion.item.title_9',
    HelpFaqAccordionItemTitle10 = 'help.faq.accordion.item.title_10',
    HelpFaqAccordionItemDescription1 = 'help.faq.accordion.item.description_1',
    HelpFaqAccordionItemDescription2 = 'help.faq.accordion.item.description_2',
    HelpFaqAccordionItemDescription3 = 'help.faq.accordion.item.description_3',
    HelpFaqAccordionItemDescription4 = 'help.faq.accordion.item.description_4',
    HelpFaqAccordionItemDescription5 = 'help.faq.accordion.item.description_5',
    HelpFaqAccordionItemDescription6 = 'help.faq.accordion.item.description_6',
    HelpFaqAccordionItemDescription7 = 'help.faq.accordion.item.description_7',
    HelpFaqAccordionItemDescription8 = 'help.faq.accordion.item.description_8',
    HelpFaqAccordionItemDescription9 = 'help.faq.accordion.item.description_9',
    HelpFaqAccordionItemDescription10 = 'help.faq.accordion.item.description_10',
    HelpFaqAccordionItemTitle11 = 'help.faq.accordion.item.title_11',
    HelpFaqAccordionItemTitle12 = 'help.faq.accordion.item.title_12',
    HelpFaqAccordionItemTitle13 = 'help.faq.accordion.item.title_13',
    HelpFaqAccordionItemTitle14 = 'help.faq.accordion.item.title_14',
    HelpFaqAccordionItemTitle15 = 'help.faq.accordion.item.title_15',
    HelpFaqAccordionItemDescription11 = 'help.faq.accordion.item.description_11',
    HelpFaqAccordionItemDescription12 = 'help.faq.accordion.item.description_12',
    HelpFaqAccordionItemDescription13 = 'help.faq.accordion.item.description_13',
    HelpFaqAccordionItemDescription14 = 'help.faq.accordion.item.description_14',
    HelpFaqAccordionItemDescription15 = 'help.faq.accordion.item.description_15',
    HelpFaqAccordionItemDescription16 = 'help.faq.accordion.item.description_16',
    HelpFaqAccordionItemTitle16 = 'help.faq.accordion.item.title_16',
    HelpFaqAccordionItem2Title1 = 'help.faq.accordion.item_2.title_1',
    HelpFaqAccordionItem2Title2 = 'help.faq.accordion.item_2.title_2',
    HelpFaqAccordionItem2Title3 = 'help.faq.accordion.item_2.title_3',
    FreelancersEnterpriseTabsInformationTitle1 = 'freelancers.enterprise.tabs.information.title_1',
    FreelancersEnterpriseTabsInformationTitle2 = 'freelancers.enterprise.tabs.information.title_2',
    FreelancersEnterpriseTabsInformationTitle3 = 'freelancers.enterprise.tabs.information.title_3',
    FreelancersEnterpriseTabsInformationDescription1 = 'freelancers.enterprise.tabs.information.description_1',
    FreelancersEnterpriseTabsInformationDescription2 = 'freelancers.enterprise.tabs.information.description_2',
    FreelancersEnterpriseTabsInformationDescription3 = 'freelancers.enterprise.tabs.information.description_3',
    FreelancersFaqAccordionItemTitle2 = 'freelancers.faq.accordion.item.title_2',
    FreelancersFaqAccordionItemDescription2 = 'freelancers.faq.accordion.item.description_2',
    FreelancersPricingItemDescription1 = 'freelancers.pricing.item.description_1',
    FreelancersPricingItemDescription2 = 'freelancers.pricing.item.description_2',
    FreelancersPricingItemDescription3 = 'freelancers.pricing.item.description_3',
    FreelancersPricingItemTitle1 = 'freelancers.pricing.item.title_1',
    FreelancersPricingItemTitle2 = 'freelancers.pricing.item.title_2',
    FreelancersPricingItemTitle3 = 'freelancers.pricing.item.title_3',
    PricesTabsTitle2 = 'prices.tabs.title_2',
    PricesTabsTitle3 = 'prices.tabs.title_3',
    PricesTabsTitle4 = 'prices.tabs.title_4',
    PricesTabsDescription1 = 'prices.tabs.description_1',
    PricesTabsDescription2 = 'prices.tabs.description_2',
    PricesTabsDescription3 = 'prices.tabs.description_3',
    PricesTabsDescription4 = 'prices.tabs.description_4',
    HelpLinksItemTitle4 = 'help.links.item.title_4',
    HelpLinksItemTitle5 = 'help.links.item.title_5',
    HelpLinksItemTitle6 = 'help.links.item.title_6',
    HelpLinksItemTitle7 = 'help.links.item.title_7',
    HelpFaqAccordionItemTitle1 = 'help.faq.accordion.item.title_1',
    HelpFaqAccordionItemTitle2 = 'help.faq.accordion.item.title_2',
    HelpFaqAccordionItemTitle3 = 'help.faq.accordion.item.title_3',
    HelpFaqAccordionItemTitle4 = 'help.faq.accordion.item.title_4',
    HelpFaqAccordionItemTitle5 = 'help.faq.accordion.item.title_5',
    DashboardAccountAddCurrency = 'dashboard.account.add.currency',
    AccountCardCopyTooltip = 'account.card.copy.tooltip',
    CommonCopyClipboard = 'common.copy.clipboard',
    AddCurrencyModalTitle = 'add.currency.modal.title',
    AddCurrencyModalDescription = 'add.currency.modal.description',
    AccountDetailsModalTitle = 'account.details.modal.title',
    AccountDetailsCardHolderName = 'account.details.card.holder.name',
    AccountDetailsCardIban = 'account.details.card.iban',
    AccountDetailsCardBic = 'account.details.card.bic',
    AccountDetailsCardBankName = 'account.details.card.bank.name',
    AccountDetailsCardBankAddress = 'account.details.card.bank.address',
    AccountDetailsCardBankCountry = 'account.details.card.bank.country',
    AccountDetailsCardSortCode = 'account.details.card.sortCode',
    AccountDetailsCardSepaTitle = 'account.details.card.sepa.title',
    AccountDetailsCardSwiftTitle = 'account.details.card.swift.title',
    AccountDetailsCardSepaDescription = 'account.details.card.sepa.description',
    AccountDetailsCardSwiftDescription = 'account.details.card.swift.description',
    AccountDetailsCardCopyDescriptionSepa = 'account.details.card.sepa.description.copy',
    AccountDetailsCardCopyDescriptionSwift = 'account.details.card.swift.description.copy',
    AccountDetailsCardCopyDescriptionLocal = 'account.details.card.local.description.copy',
    AccountDetailsCardMulticurrencyAccountTitle = 'account.details.card.multicurrency.account.title',
    AccountDetailsCardEurAccountTitle = 'account.details.card.eur.account.title',
    AccountDetailsCopyButtonTitle = 'account.details.copy.button.title',
    AccountDetailsCardHeaderCurrenciesTitle = 'account.details.card.header.currencies.title',
    HomeStepsContentSecondStepDescription1 = 'home.steps.content.second.step.description_1',
    HomeStepsContentSecondStepDescription2 = 'home.steps.content.second.step.description_2',
    HomeStepsContentThirdStepDescription1 = 'home.steps.content.third.step.description_1',
    HomeStepsContentThirdStepDescription2 = 'home.steps.content.third.step.description_2',
    CompaniesEnterpriseTabsInformationDescription2 = 'companies.enterprise.tabs.information.description_2',
    CompaniesEnterpriseTabsInformationDescription3 = 'companies.enterprise.tabs.information.description_3',
    CompaniesFaqAccordionItemDescription2 = 'companies.faq.accordion.item.description_2',
    CompaniesPricingItemDescription2 = 'companies.pricing.item.description_2',
    CompaniesPricingItemDescription3 = 'companies.pricing.item.description_3',
    FeaturesTitle = 'features.title',
    ForgotPasswordSentSuccessMessage = 'forgot.password.sent.success.message',
    UpdatePasswordSuccessMessage = 'update.password.success.message',
    RegistrationLegalDocumentsCompanyCorporateButton = 'registration.legal.documents.company.corporate.button',
    RegistrationLegalDocumentsCompanyArticlesButton = 'registration.legal.documents.company.articles.button',
    RegistrationLegalDocumentsCompanyBusinessButton = 'registration.legal.documents.company.business.button',
    RegistrationLegalDocumentsCompanyGroupButton = 'registration.legal.documents.company.group.button',
    RegistrationLegalDocumentsChartDataButton = 'registration.legal.documents.chart.data.button',
    RegistrationLegalDocumentsChartSelfButton = 'registration.legal.documents.chart.self.button',
    LoginFormError = 'login.form.error',
    ExchangeConfirmationModalTitle = 'exchange.confirmation.modal.title',
    ExchangeModalDescription = 'exchange.modal.description',
    ExchangeModalInformationSelling = 'exchange.modal.information.selling',
    ExchangeModalInformationBuying = 'exchange.modal.information.buying',
    ExchangeModalInformationExchangeRate = 'exchange.modal.information.exchange.rate',
    ExchangeModalInformationExchangeFee = 'exchange.modal.information.exchange.fee',
    ExchangeModalApplyQuote = 'exchange.modal.apply.quote',
    ExchangeModalError = 'exchange.modal.error',
    ExchangeModalFormTitle = 'exchange.modal.form.title',
    ExchangeFormSellTitle = 'exchange.form.sell.title',
    ExchangeFormBuyTitle = 'exchange.form.buy.title',
    ExchangeFormAmountToTitle = 'exchange.form.amount_to.title',
    ExchangeFormTypeTitle = 'exchange.form.type.title',
    ExchangeFormAmountTitle = 'exchange.form.amount.title',
    UserShortProfileConfirmPasswordTitle = 'user.short.profile.confirm.password.title',
    ExchangeFormQuoteTitle = 'exchange.form.quote.title',
    ChangePasswordFormError = 'change.password.form.error',
    RegistrationLegalDeclarationSanctionedTitle = 'registration.legal.declaration.sanctioned.title',
    RegistrationLegalDeclarationPoliticallyTitle = 'registration.legal.declaration.politically.title',
    RegistrationLegalDeclarationSuspectedTitle = 'registration.legal.declaration.suspected.title',
    RegistrationLegalDeclarationConvictedTitle = 'registration.legal.declaration.convicted.title',
    PricesTabsInformationDescription1 = 'prices.tabs.information.description_1',
    PricesTabsInformationDescription2 = 'prices.tabs.information.description_2',
    PricesTabsInformationDescription2UpTo = 'prices.tabs.information.description_2.up.to',
    PricesTabsInformationDescription3 = 'prices.tabs.information.description_3',
    TermsAndConditionText1 = 'terms.and.condition.text_1',
    TermsAndConditionText2 = 'terms.and.condition.text_2',
    PricesTabsTitle1 = 'prices.tabs.title_1',
    ForgotPassword = 'forgot.password',
    ForgotPasswordWelcomeClub = 'forgot.password.welcome.club',
    ForgotPasswordSendLink = 'forgot.password.send.link',
    ForgotPasswordFormTitle = 'forgot.password.form.title',
    SecurityTitle = 'security.title',
    SecurityOptionTitle1 = 'security.option.title_1',
    SecurityOptionTitle2 = 'security.option.title_2',
    SecurityOptionTitle3 = 'security.option.title_3',
    SecurityOptionTitle4 = 'security.option.title_4',
    SecurityOptionText1 = 'security.option.text_1',
    SecurityOptionText2 = 'security.option.text_2',
    SecurityOptionText3 = 'security.option.text_3',
    SecurityOptionText4 = 'security.option.text_4',
    LoginOtpStepTitle = 'login.otp.step.title',
    LoginOtpStepDescription = 'login.otp.step.description',
    OtpFormDescriptionWait = 'otp.form.description.wait',
    OtpPhoneFormDescriptionWait = 'otp.phone.form.description.wait',
    OtpPhoneFormResendSms = 'otp.phone.form.resend.sms',
    OtpFormDescriptionResend = 'otp.form.description.resend',
    OtpFormDescriptionResendSms = 'otp.form.description.resend.sms.',
    RegistrationLegalDocumentsPageTitle = 'registration.legal.documents.page.title',
    RegistrationLegalDocumentsPageDescription = 'registration.legal.documents.page.description',
    OtpFormError = 'otp.form.error',
    RegistrationLegalDocumentsCompanyTitle = 'registration.legal.documents.company.title',
    RegistrationLegalDocumentsLocationTitle = 'registration.legal.documents.location.title',
    RegistrationLegalDocumentsFinancialStatementTitle = 'registration.legal.documents.financialStatement.title',
    RegistrationLegalDocumentsChartTitle = 'registration.legal.documents.chart.title',
    CommonFileUploadError = 'common.file.upload.error',
    HelpContactsSocialTitle = 'help.contacts.social.title',
    HelpLinksTitle = 'help.links.title',
    CookieInfoDescription = 'cookie.info.description',
    CookieInfoTitle = 'cookie.info.title',
    CommonAcceptAll = 'common.accept.all',
    AboutTitle = 'about.title',
    AboutDescription1Company = 'about.description_1.company',
    AboutDescription1 = 'about.description_1',
    AboutDescription2 = 'about.description_2',
    AboutDescription2Here = 'about.description_2.here',
    AboutDescription3 = 'about.description_3',
    HelpContactFormTitle = 'help.contact.form.title',
    CommonSend = 'common.send',
    CommonFirstName = 'common.first.name',
    CommonLastName = 'common.last.name',
    CommonMessage = 'common.message',
    FreelancersFaqAccordionItemDescription1 = 'freelancers.faq.accordion.item.description_1',
    FreelancersFaqAccordionItemTitle1 = 'freelancers.faq.accordion.item.title_1',
    HelpLocationTitle = 'help.location.title',
    HelpLocationDescription1 = 'help.location.description_1',
    HelpLocationDescription2 = 'help.location.description_2',
    PricesTitle = 'prices.title',
    PricesButtonDescription = 'prices.button.description',
    PricesTabsInformationItemTitle1 = 'prices.tabs.information.item.title_1',
    PricesTabsInformationItemTitle2 = 'prices.tabs.information.item.title_2',
    PricesTabsInformationItemTitle3 = 'prices.tabs.information.item.title_3',
    PricesTabsInformationItemList1Description1 = 'prices.tabs.information.item.list_1.description_1',
    PricesTabsInformationItemList1Description2 = 'prices.tabs.information.item.list_1.description_2',
    PricesTabsInformationItemList1Description3 = 'prices.tabs.information.item.list_1.description_3',
    PricesTabsInformationItemList3Description1 = 'prices.tabs.information.item.list_3.description_1',
    CompaniesPricingItemDescription1 = 'companies.pricing.item.description_1',
    AboutPageDescription = 'about.page.description',
    FeaturesManageAccountFirefox = 'features.manage.account.firefox',
    FeaturesManageAccountIe = 'features.manage.account.ie',
    FeaturesManageAccountChrome = 'features.manage.account.chrome',
    HomeInternationallyTitle = 'home.internationally.title',
    HomeInternationallyDescription = 'home.internationally.description',
    HomeInternationallyCardTitle1 = 'home.internationally.card.title_1',
    HomeInternationallyCardTitle2 = 'home.internationally.card.title_2',
    HomeInternationallyCardDescription1 = 'home.internationally.card.description_1',
    HomeInternationallyCardDescription2 = 'home.internationally.card.description_2',
    HelpLinksItemTitle1 = 'help.links.item.title_1',
    HelpLinksItemTitle2 = 'help.links.item.title_2',
    HelpLinksItemTitle3 = 'help.links.item.title_3',
    HelpContactsTitle = 'help.contacts.title',
    HelpContactsCardsItemTitle1 = 'help.contacts.cards.item.title_1',
    HelpContactsCardsItemTitle2 = 'help.contacts.cards.item.title_2',
    HelpContactsCardsItemDescription = 'help.contacts.cards.item.description',
    HelpContactsCardsItemDescriptionPhone = 'help.contacts.cards.item.description.phone',
    CommonCompanyRelationOwner = 'common.company.relation.owner',
    CommonCompanyRelationOwnership = 'common.company.relation.ownership',
    CommonCompanyRelationBeneficiary = 'common.company.relation.beneficiary',
    CommonCompanyRelationDirector = 'common.company.relation.director',
    CommonCompanyRelationRepresentative = 'common.company.relation.representative',
    CommonCompanyRelationUBO = 'common.company.relation.UBO',
    CommonYou = 'common.you',
    BusinessCardAboutCard = 'business.card.about.card',
    BusinessCardDescription = 'business.card.description',
    FreelancersEnterpriseTabsItemTitle1 = 'freelancers.enterprise.tabs.Item.title_1',
    FreelancersEnterpriseTabsItemTitle2 = 'freelancers.enterprise.tabs.Item.title_2',
    FreelancersEnterpriseTabsItemTitle3 = 'freelancers.enterprise.tabs.Item.title_3',
    CommonCompanyRelationUserCreated = 'common.company.relation.user.created',
    DashboardAccountEmpty = 'dashboard.account.empty',
    FeaturesBusinessAccountTitle = 'features.business_account.title',
    HomeFirstScreenTitle = 'home.first.screen.title',
    HomeStepsContentFirstStepDescription1 = 'home.steps.content.first.step.description_1',
    HomeStepsContentFirstStepDescription2 = 'home.steps.content.first.step.description_2',
    HomeStepsContentFirstStepDescription3 = 'home.steps.content.first.step.description_3',
    HomeStepsTitle = 'home.steps.title',
    CommonOpenAccount = 'common.open.account',
    ExchangeBtnTitle = 'exchange.btn.title',
    GetDemo = 'common.demo',
    HomeStepsButtonStepDescription1 = 'home.steps.button.step.description_1',
    HomeStepsButtonStepDescription2 = 'home.steps.button.step.description_2',
    HomeStepsButtonStepDescription3 = 'home.steps.button.step.description_3',
    CardTransactionsHeaderButtonTitle = 'card.transactions.header.button.title',
    FeaturesManageAccountDescription = 'features.manage.account.description',
    FeaturesManageAccountListTitle1 = 'features.manage.account.list.title_1',
    FeaturesManageAccountListTitle2 = 'features.manage.account.list.title_2',
    FeaturesManageAccountListTitle3 = 'features.manage.account.list.title_3',
    FeaturesManageAccountListDescription1 = 'features.manage.account.list.description_1',
    FeaturesManageAccountListDescription2 = 'features.manage.account.list.description_2',
    FeaturesManageAccountListDescription3 = 'features.manage.account.list.description_3',
    CardTransactionsFilterSearchInputTitle = 'card.transactions.filter.search.input.title',
    CardTransactionsFilterPeriodInputTitle = 'card.transactions.filter.period.input.title',
    CardTransactionsFilterTypeInputTitle = 'card.transactions.filter.type.input.title',
    FeaturesCardTitle1 = 'features.card.title_1',
    FeaturesCardTitle2 = 'features.card.title_2',
    FeaturesCardDescription1 = 'features.card.description_1',
    FeaturesCardDescription2 = 'features.card.description_2',
    PricingBlockTitle = 'pricing.block.title',
    PricingBlockMoreLink = 'pricing.block.more.link',
    CompaniesPricingItemTitle1 = 'companies.pricing.item.title_1',
    CompaniesPricingItemTitle2 = 'companies.pricing.item.title_2',
    CompaniesPricingItemTitle3 = 'companies.pricing.item.title_3',
    FeaturesCurrencyExchangeDescription = 'features.currency_exchange.description',
    FeaturesPaymentsDescription = 'features.payments.description',
    FeaturesPaymentsTitle = 'features.payments.title',
    CommonTableEmptyTitle = 'common.table.empty.title',
    HomeAboutTitle = 'home.about.title',
    HomeAboutDescription = 'home.about.description',
    HeaderLinkFeatures = 'header.link.features',
    HeaderLinkPricing = 'header.link.pricing',
    HeaderLinkBlog = 'header.link.blog',
    HeaderLinkHelp = 'header.link.help',
    HeaderLinkCompanies = 'header.link.companies',
    HeaderLinkFreelancers = 'header.link.freelancers',
    PricingPricingCardCommissionDescription1 = 'pricing.pricing_card.commission_description_1',
    PricingPricingCardMaintenanceDescription1 = 'pricing.pricing_card.maintenance_description_1',
    BusinessCardTitle = 'business.card.title',
    BusinessCardWant = 'business.card.want',
    BusinessCardText = 'business.card.text',
    BusinessCardButton1 = 'business.card.button_1',
    BusinessCardButton2 = 'business.card.button_2',
    CommonLegal = 'common.legal',
    CommonPrivate = 'common.private',
    BusinessCardGold = 'business.card.gold',
    BusinessCardBusiness = 'business.card.business',
    FeaturesBusinessAccountCard1Title = 'features.business_account.card_1.title',
    FeaturesBusinessAccountCard2Title = 'features.business_account.card_2.title',
    FeaturesBusinessAccountCard3Title = 'features.business_account.card_3.title',
    FeaturesBusinessAccountCard1Description = 'features.business_account.card_1.description',
    FeaturesBusinessAccountCard2Description = 'features.business_account.card_2.description',
    FeaturesBusinessAccountCard3Description = 'features.business_account.card_3.description',
    FeaturesBusinessAccountDescription = 'features.business_account.description',
    HomeMobileAppTitle = 'home.mobile.app.title',
    HomeBankingFromBrowserTitle = 'home.banking.from.browser.title',
    CompaniesEnterpriseTabsItemTitle2 = 'companies.enterprise.tabs.item.title_2',
    CompaniesEnterpriseTabsItemTitle3 = 'companies.enterprise.tabs.item.title_3',
    CompaniesEnterpriseTabsInformationTitle1 = 'companies.enterprise.tabs.information.title_1',
    CompaniesEnterpriseTabsInformationTitle2 = 'companies.enterprise.tabs.information.title_2',
    CompaniesEnterpriseTabsInformationTitle3 = 'companies.enterprise.tabs.information.title_3',
    CompaniesEnterpriseTabsInformationDescription1 = 'companies.enterprise.tabs.information.description_1',
    CompaniesEnterpriseTabsItemTitle1 = 'companies.enterprise.tabs.item.title_1',
    HomeOpenAccountText = 'home.open.account.text',
    HomeManageAccountText = 'home.manage.account.text',
    HomePaymentServicesText = 'home.payment.services.text',
    HomeMobileAppDescription = 'home.mobile.app.description',
    HomeMobileAppTabTitle1 = 'home.mobile.app.tab.title_1',
    HomeMobileAppTabTitle2 = 'home.mobile.app.tab.title_2',
    HomeMobileAppTabTitle3 = 'home.mobile.app.tab.title_3',
    HomeMobileAppTabTitle4 = 'home.mobile.app.tab.title_4',
    HomeMobileAppTabTitle5 = 'home.mobile.app.tab.title_5',
    HomeMobileAppTabTitle6 = 'home.mobile.app.tab.title_6',
    HomeMobileAppTabTitle7 = 'home.mobile.app.tab.title_7',
    HomeFaqTitle = 'home.faq.title',
    CompaniesFaqAccordionItemTitle1 = 'companies.faq.accordion.item.title_1',
    CompaniesFaqAccordionItemDescription1 = 'companies.faq.accordion.item.description_1',
    HomeOnlineAccessMobileAppTitle = 'home.online_access.mobile_app.title',
    HomeOnlineAccessMobileAppDescription = 'home.online_access.mobile_app.description',
    HomeOnlineAccessTitle = 'home.online_access.title',
    CommonMoreInfo = 'common.more_info',
    HomeOnlineAccessBankingBrowserTitle = 'home.online_access.banking_browser.title',
    HomeOnlineAccessBankingBrowserDescription = 'home.online_access.banking_browser.description',
    FeaturesCurrencyExchangeTitle = 'features.currency_exchange.title',
    UserShortProfileExit = 'user.short.profile.exit',
    UserShortProfileButtonChangePasswordTitle = 'user.short.profile.button.change.password.title',
    RegistrationLegalPersonsDirectShareholdersTitle = 'registration.legal.persons.direct.shareholders.title',
    RegistrationLegalPersonsDirectShareholdersDescription = 'registration.legal.persons.direct.shareholders.description',
    RegistrationLegalPersonsDirectShareholdersLegal = 'registration.legal.persons.direct.shareholders.legal',
    RegistrationLegalPersonsDirectShareholdersPrivate = 'registration.legal.persons.direct.shareholders.private',
    CommonConfirm = 'common.confirm',
    RequestStatementModalSubmitButtonTitle = 'request.statement.modal.submit.button.title',
    TransactionsTitle = 'transactions.title',
    AddressBookTitle = 'address.book.title',
    ServicesTitle = 'services.title',
    AddressBookTableTitle = 'address.book.table.title',
    AddressBookPageButtonTitle = 'address.book.page.button.title',
    ThemeSwitcherNightModeLabel = 'theme.switcher.night.mode.label',
    ThemeSwitcherDayModeLabel = 'theme.switcher.day.mode.label',
    MenuSwitcherCloseMenu = 'menu.switcher.close.menu',
    AddressBookModalCreateTitle = 'address.book.modal.create.title',
    AddressBookTableFilterTitle = 'address.book.table.filter.title',
    HomeOpenAccountTitle = 'home.open.account.title',
    HomeManageAccountTitle = 'home.manage.account.title',
    HomePaymentServicesTitle = 'home.payment.services.title',
    CommonMore = 'common.more',
    HomeServiceTitle = 'home.service.title',
    HomeServiceItemTitle1 = 'home.service.item.title_1',
    HomeServiceItemTitle2 = 'home.service.item.title_2',
    HomeServiceItemTitle3 = 'home.service.item.title_3',
    HomeServiceItemTitle4 = 'home.service.item.title_4',
    HomeServiceItemDescription1 = 'home.service.item.description_1',
    HomeServiceItemDescription2 = 'home.service.item.description_2',
    HomeServiceItemDescription3 = 'home.service.item.description_3',
    HomeServiceItemDescription4 = 'home.service.item.description_4',
    HomeMoreInfoButtonTitle = 'home.more.info.button.title',
    StatementFormSelectMonth = 'statement.form.select.month',
    StatementFormSelectYear = 'statement.form.select.year',
    CreatePartnerFormBic = 'create.partner.form.bic',
    CreatePartnerFormAccount = 'create.partner.form.account',
    CreatePartnerFormFirstName = 'create.partner.form.first.name',
    CreatePartnerFormBeneficiaryCountry = 'create.partner.form.beneficiary.country',
    CreatePartnerFormBeneficiaryCity = 'create.partner.form.beneficiary.city',
    CreatePartnerFormBeneficiaryZip = 'create.partner.form.beneficiary.zip',
    CreatePartnerFormBeneficiaryAddress = 'create.partner.form.beneficiary.address',
    CreatePartnerFormBeneficiaryInfoTitle = 'create.partner.form.beneficiary.info.title',
    CreatePartnerFormBeneficiaryInfoDescription = 'create.partner.form.beneficiary.info.description',
    PaymentFormCommission = 'payment.form.commission',
    PaymentFormNewBalance = 'payment.form.new.balance',
    PaymentFormPayInTotal = 'payment.form.pay.in.total',
    PaymentFormAmount = 'payment.form.amount',
    CommonPhone = 'common.phone',
    ChangePasswordFormNewPassword = 'change.password.form.new.password',
    ChangePasswordFormRepeatNewPassword = 'change.password.form.repeat.new.password',
    CommonCancel = 'common.cancel',
    RegistrationLegalPersonsPageTitle = 'registration.legal.persons.page.title',
    RegistrationLegalPersonsPageDescription = 'registration.legal.persons.page.description',
    InputDateRangeOption = 'input.date.range.option',
    RegistrationLegalPersonsOwnership = 'registration.legal.persons.ownership',
    RegistrationLegalPersonsDirector = 'registration.legal.persons.director',
    RegistrationLegalPersonsUser = 'registration.legal.persons.user',
    UserShortProfileLinkCommisionProfile = 'user.short.profile.link.commision.profile',
    UserShortProfileLinkContactUs = 'user.short.profile.link.contact.us',
    UserShortProfileLinkHelpCenter = 'user.short.profile.link.help.center',
    ChangePasswordSubmitButtonTitle = 'change.password.submit.button.title',
    UserShortProfileAddPhoto = 'user.short.profile.add.photo',
    PaymentFormPaymentDetails = 'payment.form.payment.details',
    PaymentFormPaymentCurrency = 'payment.form.payment.currency',
    PaymentFormBeneficiaryBic = 'payment.form.beneficiary.bic',
    PaymentFormPaymentDescription = 'payment.form.payment.description',
    PaymentFormSwiftPaymentDetails = 'payment.form.swift.payment.details',
    PaymentFormBeneficiaryCountry = 'payment.form.beneficiary.country',
    PaymentFormBeneficiaryCity = 'payment.form.beneficiary.city',
    PaymentFormBeneficiaryPostcode = 'payment.form.beneficiary.postcode',
    PaymentFormBeneficiaryAddress = 'payment.form.beneficiary.address',
    PaymentFormAddFile = 'payment.form.add.file',
    PaymentFormAddFileDescription = 'payment.form.add.file.description',
    AmountNewBalance = 'amount.new.balance',
    AmountCommission = 'amount.commission',
    AmountPayInTotal = 'amount.pay.in.total',
    RegistrationUserTypeTitle = 'registration.user.type.title',
    WithdrawalFormAccountTitle = 'withdrawal.form.account.title',
    WithdrawalFormEnrolmentCard = 'withdrawal.form.enrolment.card',
    WithdrawalFormCardNumber = 'withdrawal.form.card.number',
    WithdrawalFormPaymentPurpose = 'withdrawal.form.payment.purpose',
    WithdrawalFormPaymentDetails = 'withdrawal.form.payment.details',
    WithdrawalFormTitle = 'withdrawal.form.title',
    StatementFormAccountTitle = 'statement.form.account.title',
    StatementFormDateRange = 'statement.form.date.range',
    StatementFormType = 'statement.form.type',
    StatementFormFormat = 'statement.form.format',
    StatementFormTypeAll = 'statement.form.type.all',
    StatementFormTypeDebit = 'statement.form.type.debit',
    StatementFormTypeCredit = 'statement.form.type.credit',
    StatementFormSelectToday = 'statement.form.select.today',
    StatementFormSelectWeek = 'statement.form.select.week',
    RegistrationSlideTitle3 = 'registration.slide.title_3',
    RegistrationSlideDescription3 = 'registration.slide.description_3',
    RegistrationPhonePhoneFieldTitle = 'registration.phone.phone.field.title',
    CommonResend = 'common.resend',
    PaymentFormTitle = 'payment.form.title',
    LegacyIndustryDivisionA = 'legacy.industry.division_a',
    LegacyIndustryDivisionB = 'legacy.industry.division_b',
    LegacyIndustryDivisionC = 'legacy.industry.division_c',
    LegacyIndustryDivisionD = 'legacy.industry.division_d',
    LegacyIndustryDivisionE = 'legacy.industry.division_e',
    LegacyIndustryDivisionF = 'legacy.industry.division_f',
    LegacyIndustryDivisionG = 'legacy.industry.division_g',
    LegacyIndustryDivisionH = 'legacy.industry.division_h',
    LegacyIndustryDivisionI = 'legacy.industry.division_i',
    LegacyIndustryDivisionJ = 'legacy.industry.division_j',
    LegacyIndustryDivisionK = 'legacy.industry.division_k',
    LegacyIndustryDivisionL = 'legacy.industry.division_l',
    LegacyIndustryDivisionM = 'legacy.industry.division_m',
    LegacyIndustryDivisionN = 'legacy.industry.division_n',
    LegacyIndustryDivisionO = 'legacy.industry.division_o',
    LegacyIndustryDivisionP = 'legacy.industry.division_p',
    LegacyIndustryDivisionQ = 'legacy.industry.division_q',
    LegacyIndustryDivisionR = 'legacy.industry.division_r',
    LegacyIndustryDivisionS = 'legacy.industry.division_s',
    LegacyIndustryDivisionT = 'legacy.industry.division_t',
    LegacyIndustryDivisionU = 'legacy.industry.division_u',
    PaymentFormAccountTitle = 'payment.form.account.title',
    PaymentFormBeneficiaryName = 'payment.form.beneficiary.name',
    PaymentFormBeneficiaryAccountNumber = 'payment.form.beneficiary.account.number',
    CommonAmount = 'common.amount',
    CardsDetailsButtonTitle = 'cards.details.button.title',
    CardBalanceTitle = 'card.balance.title',
    RegistrationLegalIndustryPageTitle = 'registration.legal.industry.page.title',
    RegistrationLegalIndustryPageDescription = 'registration.legal.industry.page.description',
    RegistrationLegalIndustryAddNewButtonTitle = 'registration.legal.industry.add.new.button.title',
    RegistrationPasswordTooltipValidationDigit = 'registration.password.tooltip.validation.digit',
    RegistrationPasswordTooltipValidationSpecialCharacter = 'registration.password.tooltip.validation.special.character',
    RegistrationPasswordTooltipValidationLowerCase = 'registration.password.tooltip.validation.lower.case',
    RegistrationPasswordTooltipValidationUpperCase = 'registration.password.tooltip.validation.upper.case',
    RegistrationPasswordTooltipValidationMinLength = 'registration.password.tooltip.validation.min.length',
    RegistrationPasswordTooltipValidationMaxLength = 'registration.password.tooltip.validation.max.length',
    PrivateFormGenderFemaleOption = 'private.form.gender.female.option',
    PrivateFormGenderMaleOption = 'private.form.gender.male.option',
    CardInternalPurchaseLimitTitle = 'card.internal.purchase.limit.title',
    CardCashWithdrawalLimitTitle = 'card.cash.withdrawal.limit.title',
    RegistrationLegalIndustryAddButtonTitle = 'registration.legal.industry.add.button.title',
    RegistrationPasswordTooltipValidationLatinLetter = 'registration.password.tooltip.validation.latin.letter',
    RegistrationLegalIndustryCodeInputTitle = 'registration.legal.industry.code.input.title',
    RegistrationLegalIndustryDescriptionActivitiesInputTitle = 'registration.legal.industry.description.activities.input.title',
    DocumentFormPassportTitle = 'document.form.passport.title',
    DocumentFormLocationTitle = 'document.form.location.title',
    DocumentFormSelfieTitle = 'document.form.selfie.title',
    UploadFileErrorFormat = 'upload.file.error.format',
    UploadFileErrorNotSelected = 'upload.file.error.not.selected',
    UploadFileErrorSize = 'upload.file.error.size',
    CommonStatusOfRegistration = 'common.status.of.registration',
    LetterFirstRegistrationTitle = 'letter.first.registration.title',
    CommonPasswordPassword = 'common.password.password',
    RegistrationSlideTitle2 = 'registration.slide.title_2',
    RegistrationSlideDescription2 = 'registration.slide.description_2',
    PeriodTitle = 'period.title',
    DashboardAccountsTitle = 'dashboard.accounts.title',
    DashboardAccountsButtonTitle = 'dashboard.accounts.button.title',
    DashboardTransactionsApprovalTitle = 'dashboard.transactions.approval.title',
    DashboardTransactionsApprovalButtonTitle = 'dashboard.transactions.approval.button.title',
    DashboardTransactionsTitle = 'dashboard.transactions.title',
    DashboardTransactionsButtonTitle = 'dashboard.transactions.button.title',
    LegalCompanyNameTitle = 'legal.company.name.title',
    LegalCompanyCountryRegistrationTitle = 'legal.company.country.registration.title',
    LegalCompanyNumberTitle = 'legal.company.number.title',
    LegalCompanyTaxNumberTitle = 'legal.company.tax.number.title',
    LegalCompanyRegistrationDateTitle = 'legal.company.registration.date.title',
    LegalCompanyBusinessConductionCountryTitle = 'legal.company.business.conduction.country.title',
    LegalCompanyPartGroupTitle = 'legal.company.part.group.title',
    DashboardAccountsMoreTitle = 'dashboard.accounts.more.title',
    DashboardTitle = 'dashboard.title',
    DashboardPaymentNewButtonTitle = 'dashboard.payment.new.button.title',
    RegistrationLegalCompanyPageTitle = 'registration.legal.company.page.title',
    RegistrationLegalCompanyPageDescription = 'registration.legal.company.page.description',
    RegistrationLegalCompanyButtonTitle = 'registration.legal.company.button.title',
    RegistrationLegalAddressPageTitle = 'registration.legal.address.page.title',
    RegistrationLegalAddressPageDescription = 'registration.legal.address.page.description',
    RegistrationLegalAddressPostalPageTitle = 'registration.legal.address.postal.page.title',
    RegistrationLegalAddressCheckInputTitle = 'registration.legal.address.check.input.title',
    PrivateProfileFormEmployedFullOption = 'private.profile.form.employed.full.option',
    PrivateProfileFormEmployedPartOption = 'private.profile.form.employed.part.option',
    PrivateProfileFormSelfEmployedOption = 'private.profile.form.self.employed.option',
    PrivateProfileFormRetiredOption = 'private.profile.form.retired.option',
    PrivateProfileFormUnemployedOption = 'private.profile.form.unemployed.option',
    CardsTitle = 'cards.title',
    PrivateAddressCountyTitle = 'private.address.county.title',
    PrivateServiceEuroIbanTitle = 'private.service.euro.iban.title',
    PrivateServicePaymentAccountTitle = 'private.service.payment.account.title',
    PrivateServiceCurrencyTitle = 'private.service.currency.title',
    PrivateServiceMoneyTurnoverTitle = 'private.service.money.turnover.title',
    PrivateServiceReasonAccountTitle = 'private.service.reason.account.title',
    PrivateSelfDeclarationPageTitle = 'private.self.declaration.page.title',
    PrivateSelfDeclarationPageDescription = 'private.self.declaration.page.description',
    PrivateSelfDeclarationPoliticallyPersonTitle = 'private.self.declaration.politically.person.title',
    PrivateSelfDeclarationPepTitle = 'private.self.declaration.pep.title',
    PrivateAcceptAgreementPageTitle = 'private.accept.agreement.page.title',
    PrivateAcceptAgreementCheckboxTitle = 'private.accept.agreement.checkbox.title',
    PrivateAcceptAgreementPageDescription1 = 'private.accept.agreement.page.description_1',
    PrivateAcceptAgreementPageDescription2 = 'private.accept.agreement.page.description_2',
    PrivateAcceptAgreementPageDescription3 = 'private.accept.agreement.page.description_3',
    PrivateAcceptAgreementPageDescription4 = 'private.accept.agreement.page.description_4',
    PrivateAcceptAgreementPageDescription5 = 'private.accept.agreement.page.description_5',
    PrivateAcceptAgreementPageDescription6 = 'private.accept.agreement.page.description_6',
    CommonEur = 'common.eur',
    CommonUsd = 'common.usd',
    PrivateSelfDeclarationTooltipDescription = 'private.self.declaration.tooltip.description',
    LoginFormTitle = 'login.form.title',
    LoginFormWelcome = 'login.form.welcome',
    LoginFormDescription = 'login.form.description',
    LoginFormDescription2 = 'login.form.description2',
    CardBlockedTitle = 'card.blocked.title',
    LoginFormInputTitle = 'login.form.input.title',
    LoginFormInputTitle2 = 'login.form.input.title2',
    PrivateDocumentCommentShow = 'private.document.comment.show',
    PrivateDocumentCommentTitle = 'private.document.comment.title',
    UserTypeLegacyDescription = 'user.type.legacy.description',
    UserTypePrivateDescription = 'user.type.private.description',
    CommonSelect = 'common.select',
    PrivateFormNameTitle = 'private.form.name.title',
    PrivateFormLastnameTitle = 'private.form.lastname.title',
    PrivateFormThirdnameTitle = 'private.form.thirdname.title',
    PrivateFormBirthdayTitle = 'private.form.birthday.title',
    PrivateFormGenderTitle = 'private.form.gender.title',
    PrivateFormFinancialSituationTitle = 'private.form.financial.situation.title',
    PrivateFormNationalityTitle = 'private.form.nationality.title',
    PrivateFormButtonContinueTitle = 'private.form.button.continue.title',
    CommonBack = 'common.back',
    PrivatePesonalPageTitle = 'private.pesonal.page.title',
    PrivatePesonalPageDescription = 'private.pesonal.page.description',
    PrivateAddressPageTitle = 'private.address.page.title',
    PrivateAddressPageDescription = 'private.address.page.description',
    PrivateServicesPageTitle = 'private.services.page.title',
    PrivateServicesPageDescription = 'private.services.page.description',
    PrivateDocumentsPageTitle = 'private.documents.page.title',
    PrivateDocumentsPageDescription = 'private.documents.page.description',
    UploadDocumentSelectTitle = 'upload.document.select.title',
    DragAndDropFile = 'drag.and.drop.file',
    UploadDocumentSelectInfo = 'upload.document.select.info',
    UploadDocumentSelectUploading = 'upload.document.select.uploading',
    UploadDocumentSelectUploaded = 'upload.document.select.uploaded',
    DownloadPassportButtonTitle = 'download.passport.button.title',
    DownloadLocationButtonTitle = 'download.location.button.title',
    DownloadSelfieButtonTitle = 'download.selfie.button.title',
    PrivateAddressStreetTitle = 'private.address.street.title',
    PrivateAddressPostTitle = 'private.address.post.title',
    PrivateAddressCityTitle = 'private.address.city.title',
    RegistrationFormWelcome = 'registration.form.welcome',
    RegistrationFormDescription = 'registration.form.description',
    RegistrationFormDescription1 = 'registration.form.description1',
    CommonLogin = 'common.login',
    CommonRegister = 'common.register',
    WaitingList = 'waiting.list',
    CommonEmail = 'common.email',
    RegistrationFormInfoText1 = 'registration.form.info.text_1',
    CommonTerms = 'common.terms',
    RegistrationFormInfoText2 = 'registration.form.info.text_2',
    CommonRules = 'common.rules',
    RegistrationFormTitle = 'registration.form.title',
    RegistrationFormTitleSignin = 'registration.form.title.signin',
    RegistrationFormTitle1 = 'registration.form.title1',
    RegistrationSlideTitle1 = 'registration.slide.title_1',
    RegistrationSlideDescription1 = 'registration.slide.description_1',
    RegistrationCheckMailTitle = 'registration.check.mail.title',
    RegistrationCheckMailTitleForIndividual = 'registration.check.mail.title_individual',
    RegistrationCheckMailDescription1 = 'registration.check.mail.description_1',
    RegistrationCheckMailDescription2 = 'registration.check.mail.description_2',
    RegistrationCheckMailButtonChangeMailTitle = 'registration.check.mail.button_change_mail_title',
    RegistrationCheckMailDescription3 = 'registration.check.mail.description_3',
    RegistrationPasswordTitle = 'registration.password.title',
    RegistrationPasswordDescription = 'registration.password.description',
    CommonPassword = 'common.password',
    CommonPasswordRepeat = 'common.password.repeat',
    RegistrationPhoneTitle = 'registration.phone.title',
    RegistrationPhoneDescription = 'registration.phone.description',
    RegistrationOtpTitle = 'registration.otp.title',
    RegistrationOtpDescription = 'registration.otp.description',
    UserTypeLegacy = 'user.type.legacy',
    UserTypePrivate = 'user.type.private',
    PricesBreadcrumb = 'prices.breadcrumb',
    PricesCost = 'prices.сost',
    PricesCurrencyExchange = 'prices.сurrency.exchange',
    FirstScreenTitle = 'first.screen.title',
    FirstScreenDiscription = 'first.screen.discription',
    FirstScreenTrust = 'first.screen.trust',
    HomeServiceMainTitle = 'home.service.main.title',
    OpenAccTitle = 'open.acc.title',
    OpenAccDiscription = 'open.acc.discription',
    OpenAccMembersInfo = 'open.acc.members.info',
    ManageAccTitle = 'manage.acc.title',
    ManageAccDiscription = 'manage.acc.discription',
    ManageAccSell = 'manage.acc.sell',
    ManageAccGet = 'manage.acc.get',
    ManageAccTransferFee = 'manage.acc.transfer.fee',
    ManageAccCourse = 'manage.acc.course',
    FaqSectionTitle = 'faq.section.title',
    FaqSectionTab1 = 'faq.section.tab1',
    CompanyNameTableTitle = 'company.name.table.title',
    SubscriptionCostTableTitle = 'subscription.cost.table.title',
    SepaPaymentsTableTitle = 'sepa.payments.table.title',
    SwiftPaymentsTableTitle = 'swift.payments.table.title',
    FxTableTitle = 'fx.table.title',
    SwiftCoastTableTitle = 'swift.coast.table.title',
    PricesItemTitle1 = 'prices.item.title1',
    PricesItemDescription1 = 'prices.item.description1',
    PricesItemTitle2 = 'prices.item.title2',
    PricesItemDescription2 = 'prices.item.description2',
    PricesItemTitle3 = 'prices.item.title3',
    PricesItemDescription3 = 'prices.item.description3',
    OfficeLocationWebsite = 'office_location_website',
    CompanyDescriptionMainpageLeft = 'company_description_mainpage_left',
    CompanyDescriptionMainpageRight = 'company_description_mainpage_right',
    CopyrightTextBeforeYear = 'copyright-text-before-year',
    CopyrightTextAfterYear = 'copyright-text-after-year',
    SepaFromFive = 'sepa.from.five',
    SepaToTen = 'sepa.to.ten',
    SepaToFifty = 'sepa.to.fifty',
    SepaOverOneHundred = 'sepa.over.one.hundred',
    SwiftFromFifty = 'swift.from.fifty',
    SwiftToTen = 'swift.to.ten',
    SwiftToFifty = 'swift.to.fifty',
    SwiftOverOneHundred = 'swift.over.one.hundred',
    CreatePassWordTitle = 'create.password.title',
    RegistrationCompanyNameTitle = 'registration.company.name.title',
    HeaderRightTextRegistration = 'header.right.text.registration',
    HeaderRightTextSignin = 'header.right.text.signin',
    RecoveryPassword = 'recovery.password',
    RegistrationBtn = 'registration.btn',
    PrivateIndividualInfo = 'private.individual.info',
    EnterRegistrationPhone = 'enter.registration.phone',
    SuccessAddToWaitingList = 'success.add.to.waiting.list',
    SuccessAddToWaitingListDiscription = 'success.add.to.waiting.list.discription',
    SecurityPolicy = 'security.policy',

    RegistrationCompanyStepOneTitle = 'registration.company.step.one.title',
    RegistrationCompanyStepTwoTitle = 'registration.company.step.two.title',
    RegistrationCompanyStepThreeTitle = 'registration.company.step.three.title',
    RegistrationCompanyStepFourTitle = 'registration.company.step.four.title',
    RegistrationCompanyStepFourPostalTitle = 'registration.company.step.four.postal.title',
    RegistrationCompanyStepFourLabelStreet = 'registration.company.step.four.label.street',
    RegistrationCompanyStepFourLabelCity = 'registration.company.step.four.label.city',
    RegistrationCompanyStepFourLabelHouse = 'registration.company.step.four.label.house',
    RegistrationCompanyStepFourLabelPostcode = 'registration.company.step.four.label.postcode',
    RegistrationCompanyStepFourCheckbox = 'registration.company.step.four.checkbox',
    RegistrationCompanyStepFiveTitle = 'registration.company.step.five.title',
    RegistrationCompanyStepSixTitle = 'registration.company.step.six.title',
    RegistrationCompanyStepSevenTitle = 'registration.company.step.seven.title',
    RegistrationCompanyStepSevenDescription = 'registration.company.step.seven.description',
    RegistrationCompanyStepSevenEmptyUpload = 'registration.company.step.seven.empty.upload',
    RegistrationCompanyStepSevenWait = 'registration.company.step.seven.wait',
    RegistrationCompanyStepEightTitle = 'registration.company.step.eight.title',
    RegistrationCompanyStepNineTitle = 'registration.company.step.nine.title',
    RegistrationCompanyStepSuccess = 'registration.company.step.success',
    RegistrationCompanyStepSuccessNext = 'registration.company.step.success.next',
    RegistrationCompanyStepSuccessDescription = 'registration.company.step.success.description',
    RegistrationCompanyStepSuccessBtn = 'registration.company.step.success.btn',
    StepOne = 'step.one',
    StepTwo = 'step.two',
    StepThree = 'step.three',
    RegistrationPersonStepOneTitle = 'registration.person.step.one.title',
    RegistrationPersonStepOneDescription = 'registration.person.step.one.description',
    RegistrationPersonStepOneRole1 = 'registration.person.step.one.role1',
    RegistrationPersonStepOneRole2 = 'registration.person.step.one.role2',
    RegistrationPersonStepOneRole3 = 'registration.person.step.one.role3',
    RegistrationPersonStepOneRole4 = 'registration.person.step.one.role4',
    RegistrationPersonStepTwoTitle = 'registration.person.step.two.title',
    RegistrationPersonStepThreeTitle = 'registration.person.step.three.title',
    RegistrationPersonStepThreeField1 = 'registration.person.step.three.field1',
    RegistrationPersonStepThreeField2 = 'registration.person.step.three.field2',
    RegistrationPersonStepFourTitle = 'registration.person.step.four.title',
    RegistrationPersonStepFiveTitle = 'registration.person.step.five.title',
    RegistrationPersonStepFiveDescription = 'registration.person.step.five.description',
    RegistrationPersonStepFiveVerifyNow = 'registration.person.step.five.verify.now',
    RegistrationPersonStepSixTitle = 'registration.person.step.six.title',
    RegistrationPersonStepSevenTitle = 'registration.person.step.seven.title',
    RegistrationPersonStepSevenDescription = 'registration.person.step.seven.description',
    RegistrationPersonStepEightTitle = 'registration.person.step.eight.title',
    RegistrationPersonStepEightRadiobtn1 = 'registration.person.step.eight.radiobtn1',
    RegistrationPersonStepEightRadiobtn2 = 'registration.person.step.eight.radiobtn2',
    RegistrationPersonStepNineTitle = 'registration.person.step.nine.title',
    RegistrationPersonStepNineDescription = 'registration.person.step.nine.description',
    RegistrationPersonStepNineAddowner = 'registration.person.step.nine.addowner',
    RegistrationPersonSuccessTitle = 'registration.person.success.title',
    RegistrationPersonSuccessTitle2 = 'registration.person.success.title2',
    RegistrationPersonSuccessDescription = 'registration.person.success.description',
    RegistrationBusinessStepOneTitle = 'registration.business.step.one.title',
    RegistrationBusinessStepOneDescription = 'registration.business.step.one.description',
    RegistrationBusinessStepOneCategory1 = 'registration.business.step.one.category1',
    RegistrationBusinessStepOneCategory2 = 'registration.business.step.one.category2',
    RegistrationBusinessStepOneCategory3 = 'registration.business.step.one.category3',
    RegistrationBusinessStepOneCategory4 = 'registration.business.step.one.category4',
    RegistrationBusinessStepOneCategory5 = 'registration.business.step.one.category5',
    RegistrationBusinessStepOneCategory6 = 'registration.business.step.one.category6',
    RegistrationBusinessStepOneCategory7 = 'registration.business.step.one.category7',
    RegistrationBusinessStepTwoTitle = 'registration.business.step.two.title',
    RegistrationBusinessStepThreeTitle = 'registration.business.step.three.title',
    RegistrationBusinessStepFourTitle = 'registration.business.step.four.title',
    RegistrationBusinessStepFourField1 = 'registration.business.step.four.field1',
    RegistrationBusinessStepFourField2 = 'registration.business.step.four.field2',
    RegistrationBusinessStepFourField3 = 'registration.business.step.four.field3',
    RegistrationBusinessStepFourField4 = 'registration.business.step.four.field4',
    RegistrationBusinessStepFiveTitle = 'registration.business.step.five.title',
    RegistrationBusinessStepFiveTrue = 'registration.business.step.five.true',
    RegistrationBusinessStepFiveFalse = 'registration.business.step.five.false',
    RegistrationBusinessStepSixTitle = 'registration.business.step.six.title',
    RegistrationBusinessStepSixTrue = 'registration.business.step.six.true',
    RegistrationBusinessStepSixFalse = 'registration.business.step.six.false',
    RegistrationBusinessStepSevenTitle = 'registration.business.step.seven.title',
    RegistrationBusinessStepSevenDescription = 'registration.business.step.seven.description',
    RegistrationBusinessStepEightDescription = 'registration.business.step.eight.description',
    RegistrationBusinessStepEightTitle = 'registration.business.step.eight.title',
    RegistrationBusinessStepNineTitle = 'registration.business.step.nine.title',
    RegistrationBusinessStepNineDescription = 'registration.business.step.nine.description',
    RegistrationBusinessStepTenTitle = 'registration.business.step.ten.title',
    RegistrationBusinessStepTenDescription = 'registration.business.step.ten.description',
    RegistrationBusinessStepElevenDescription = 'registration.business.step.eleven.description',
    RegistrationBusinessStepElevenTitle = 'registration.business.step.eleven.title',
    RegistrationBusinessSuccessTitle = 'registration.business.success.title',
    RegistrationBusinessSuccessDescription = 'registration.business.success.description',
    RegistrationBusinessSuccessBtn = 'registration.business.success.btn',
    RegistrationBack = 'registration.back',
    EditDocsOverlayTitle = 'edit.docks.overlay.title',
    EditDocsOverlayBtnText = 'edit.docks.overlay.btn.text',
    RegistrationPrivateNationalityTitle = 'registration.private.nationality.title',
    RegistrationPrivateFullNameTitle = 'registration.private.full.name.title',
    RegistrationPrivateAddressTitle = 'registration.private.address.title',
    RegistrationPrivateUtilityBillTitle = 'registration.private.utility.bill.title',
    RegistrationPrivateUploadRequiredText = 'registration.private.upload.required.text',
    RegistrationPrivateIncorrectCountryTitle = 'registration.private.incorrect.country.title',
    ShowPermittedCountriesBtnText = 'show.permitted.countries.btn.text',
    RegistrationCompanyStepEightDescription = 'registration.company.step.eight.description',

    SavingsAndExpensesForOwnNeeds = 'savings.and.expenses.for.own.needs',
    CommonInvestment = 'common.investment',
    BuyingAndSellingRealEstate = 'buying.and.selling.real.estate',
    SalePurchaseOfACompany = 'sale.purchase.of.a.company',
    LessThanTwoThousands = 'less.than.two.thousands',
    CommonSalary = 'common.salary',
    CommonSaving = 'common.saving',
    CommonInterestOrDividends = 'common.interest.or.dividends',
    CommonSaleOfProperty = 'common.sale.of.property',
    CommonSaleOfShares = 'common.sale.of.shares',
    IncomeFromSelfEmployedActivities = 'income.from.self.employed.activities',
    InheritanceOrGift = 'inheritance.or.gift',
    RentalIncome = 'rental.income',
    SorrySomethingWentWrongPleaseTryAgainLater = 'sorry.something.went.wrong.please.try.again.later',
}
