import * as colors from '@shared-style/colors';

type ColorType = {
    [key in keyof typeof colors]: {
        color: string;
    };
};

export const typographyColors = Object.entries(colors).reduce(
    (acc, [key, color]) => ({ ...acc, [key]: { color } }),
    {}
) as ColorType;
