import { flow, getParent, Instance } from 'mobx-state-tree';

import { AdminGetCompanyAddress, Query } from '@shared-graphql';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { apolloClient } from '@connection/apollo-client';
import { CompanyModel } from '@model/company-model/company-model';
import { CompanyModelType } from '@model/company-model/company-model.type';

import { companyApplyActions } from './company-apply.actions ';

interface CompanyAddressActionsInterface
    extends Instance<typeof CompanyModelType>,
    ReturnType<typeof companyApplyActions> {}

const queryCompanyAddress = (companyId: string) => () =>
    apolloClient
        .query<Pick<Query, 'adminGetCompany'>>({ query: AdminGetCompanyAddress, variables: { companyId } })
        .then(result => result.data?.adminGetCompany);

export const companyAddressActions = (self: CompanyAddressActionsInterface) => ({
    applyCompanyAddressMutation: flow(function* loadCompanyAddress<T>(mutation: OnEventEmptyType<Promise<T>>) {
        const companyModel = getParent(self) as Instance<typeof CompanyModel>;

        if (companyModel.loading.isCompanyAddressLoading) {
            return;
        }

        companyModel.setCompanyAddressLoading(true);

        try {
            const address = yield mutation();

            self.legalAddress?.applyAddress(address.legalAddress);
            self.postalAddress?.applyAddress(address.postalAddress);
            self.isCompanyAddressSame = address.isCompanyAddressSame;
        } catch (error) {
            throw new Error(error as string);
        } finally {
            companyModel.setCompanyAddressLoading(false);
        }
    }),

    loadCompanyAddress: (companyId: string) =>
        (self as unknown as ReturnType<typeof companyAddressActions>).applyCompanyAddressMutation(
            queryCompanyAddress(companyId)
        ),
});
