import styled from 'styled-components';

import { White } from '@shared-style/colors';

export const BlockWrapper = styled.div`
    padding: 32px;
    border-radius: 0 0 16px 16px;
    background-color: ${White};
    height: calc(100% - 60px);
`;
