import { CommissionLimits } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { CommissionProfileInfoCurrency } from '@page/commission-profile/commission-profile-info/commission-profile-info-currency/commission-profile-info-currency';

import { CommissionProfileInfoItem } from './commission-profile-info-item/commission-profile-info-item';

export interface CommissionProfileInfoPaymentsListProps {
    commissions: CommissionLimits[];
}

export const CommissionProfileInfoList = ({ commissions }: CommissionProfileInfoPaymentsListProps) => (
    <>
        {commissions.map(({ maxAmount, minAmount, staticCommission, percentCommission }) => {
            if (maxAmount !== null && maxAmount > 0) {
                return (
                    <CommissionProfileInfoItem
                        key={minAmount}
                        amount={maxAmount}
                        title={LocalizationEnum.CommissionProfilePaymentListUp}
                    >
                        <span>- {percentCommission}%</span>
                        {staticCommission !== null && staticCommission > 0 && (
                            <>
                                <span> + </span>
                                <CommissionProfileInfoCurrency currency={staticCommission ?? 0} />
                            </>
                        )}
                    </CommissionProfileInfoItem>
                );
            }

            return (
                <CommissionProfileInfoItem
                    key={minAmount}
                    amount={minAmount}
                    title={LocalizationEnum.CommissionProfilePaymentListOver}
                >
                    <span>- {percentCommission}%</span>
                    {staticCommission !== null && staticCommission > 0 && (
                        <>
                            <span> + </span>
                            <CommissionProfileInfoCurrency currency={staticCommission ?? 0} />
                        </>
                    )}
                </CommissionProfileInfoItem>
            );
        })}
    </>
);
