import styled from 'styled-components';

import { PrimeBlue } from '@shared-style/colors';

export const Text = styled.p`
    white-space: nowrap;
    margin-left: 4px;
    color: ${PrimeBlue};

    &:hover {
        text-decoration: underline;
        color: ${PrimeBlue};
    }
`;
