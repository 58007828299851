import { Instance } from 'mobx-state-tree';

import { CompanyRelationLocaleEnum } from '@shared-enum/company-relation.locale-enum';
import { UserCompanyRelationType, UserRolesType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { CompanyPersonRelationType } from '@shared-model/company-model/company-persons-model/company-persons-model.type';
import { isExist } from '@shared-util/is-data';

import { PersonsRoleOptions } from './persons.options';

export const usePersonsLocalize = (
    isLegal: boolean,
    person: Instance<typeof CompanyPersonRelationType>,
    isCurrentUser = false
) => {
    let companyRelation = isLegal
        ? [LocalizationEnum.CommonLegal]
        : person.relation.companyRelation.reduce((acc, relation) => {
            if (relation === UserCompanyRelationType.Owner) {
                return acc;
            }

            return [...acc, CompanyRelationLocaleEnum[relation]];
        }, [] as Array<string | LocalizationEnum>);

    const privateName =
        isCurrentUser && !isExist(person.person?.profile.fullName)
            ? LocalizationEnum.CommonYou
            : person.person?.profile.fullName;

    const infoTitle = (isLegal ? person.legal?.companyName : privateName) ?? person.person?.email;

    if (person.relation.isUserCreate) {
        companyRelation = [...companyRelation, PersonsRoleOptions[person.relation.companyRole as UserRolesType]];
    }

    return [infoTitle ?? '', companyRelation];
};
