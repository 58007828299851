export enum PrivateAddressFormEnum {
    Street = 'street',
    Zip = 'zip',
    City = 'city',
    Country = 'country',
}

export enum CompanyAddressEnum {
    Legal = 'legalAddress',
    Postal = 'postalAddress',
}
