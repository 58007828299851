import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { TableComponent } from '@shared-component/table/table';
import { TableWrapper } from '@shared-component/table/table.styles';
import { noop } from '@shared-util/noop';

import { TransactionsHeader } from '@page/transactions/transactions-header/transactions-header';
import { TransactionRow } from '@page/transactions/transactions-table/transaction-row/transaction-row';
import { TransactionsTableFilter } from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter';
import { useTransactions } from '@page/transactions/transactions-table/transactions-table.hook';
import { TransactionsTableOptions } from '@page/transactions/transactions-table/transactions-table.options';

export const TransactionsTable = observer(() => {
    const {
        transactions,
        filters,
        isLoading,
        pageSize,
        currentPage,
        onPageChange,
        onSearch,
        // onStatusChange,
        // onPaymentTypeChange,
        onElcoinStatusChange,
        onCreatedFromChange,
        onCreatedToChange,
        tableRef,
        onResetFilters,
        totalTransactionsCount,
        limitRowsPerPage,
        onOperationTypeChange,
    } = useTransactions();

    const Row = TransactionRow as unknown as ReactNode;

    return (
        <>
            <TransactionsHeader onSearchChange={onSearch} filters={filters} />
            <TransactionsTableFilter
                transactionsCount={transactions.length}
                filters={filters}
                onStatusChange={noop}
                onPaymentTypeChange={noop}
                onElcoinStatusChange={onElcoinStatusChange}
                onCreatedFromChange={onCreatedFromChange}
                onCreatedToChange={onCreatedToChange}
                onOperationTypeChange={onOperationTypeChange}
                onResetFilters={onResetFilters}
            />
            <TableWrapper>
                <TableComponent
                    data={transactions}
                    totalData={totalTransactionsCount}
                    limitRowsPerPage={limitRowsPerPage}
                    columns={TransactionsTableOptions}
                    isDataLoading={isLoading}
                    pageCount={pageSize}
                    pageIndex={currentPage}
                    onPageChange={onPageChange}
                    tableRef={tableRef}
                    hasPagination
                    hasTableHead
                    hasInnerScroll
                >
                    {Row}
                </TableComponent>
            </TableWrapper>
        </>
    );
});
