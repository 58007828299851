import { rgba } from 'polished';
import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Dark, Polar, PrimeBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

interface BeneficiaryRadioButtonProps extends ThemeTypeInterface {
    isChecked: boolean;
}

export const BeneficiaryRadioButtonStyle = styled.div<BeneficiaryRadioButtonProps>`
    margin-right: 16px;
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;
    background-color: ${({ isChecked }) => (isChecked ? rgba(PrimeBlue, 0.2) : Polar)};

    ${Flex.VerticalCenter.HorizontalCenter.Style}
`;

export const IconWrapper = styled.div`
    width: 24px;
    height: 24px;
`;

const getLabelWrapperColor = ({ isChecked, theme: { isDarkTheme } }: BeneficiaryRadioButtonProps) => {
    if (isChecked) {
        return isDarkTheme ? White : Dark;
    }
    return Dark;
};

export const LabelWrapper = styled.p`
    margin-left: 8px;
    color: ${getLabelWrapperColor};
    ${Typography.S.Style}
`;
