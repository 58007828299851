import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';

import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

interface PageTitleProps {
    title: LocalizationEnum;
}

export const PageTitle = observer(({ title }: PageTitleProps) => {
    const text = useLocalizationText(title);

    return text !== undefined ? (
        <Helmet>
            <title>SENDS - {text}</title>
        </Helmet>
    ) : null;
});
