import styled from 'styled-components';

import { FlagIcon } from '@shared-atom/flag/flag.styles';
import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

export const ItemWrapper = styled.div`
    position: relative;
    ${Flex.Row.JustifyLeft.VerticalCenter.Style}

    ${FlagIcon} {
        top: initial;
        left: initial;
    }
`;

export const CountryTitle = styled.span`
    margin-left: 32px;
    ${Typography.S.Normal.Dark.Style};
`;
