import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { RegistrationLegalPersonTitle } from '@shared-component/registration/common.styles';
import { UserType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { useCompanySelector } from '@selector/company/use-company.selector';

import { RegistrationLegalPersonsList } from './registration-legal-persons-list/registration-legal-persons-list';

export const RegistrationLegalPersonsPage = observer(() => {
    const [{ persons, privatePersons }] = useCompanySelector();
    const { onOpen } = useContext(ModelContext);

    const handlePersonType = (type: UserType, id?: string) =>
        onOpen(
            UserType.Legal === type
                ? ModalEnum.RegistrationLegalPersonLegalModal
                : ModalEnum.RegistrationLegalPrivatePersonModal,
            { componentProps: { relation: persons.get(id ?? '')?.relation, type, id } }
        );

    return (
        <>
            <RegistrationLegalPersonTitle>
                <Translate langKey={LocalizationEnum.RegistrationLegalPersonsPageTitle} />
            </RegistrationLegalPersonTitle>
            <RegistrationLegalPersonsList relationPersons={privatePersons} onChooseType={handlePersonType} />
        </>
    );
});
