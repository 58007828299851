import styled from 'styled-components';

const HEADER_HEIGHT = 96;

export const Wrapper = styled.div`
    max-width: 1094px;
    width: 100%;
    margin: 0 auto;
`;

export const InfoWrapper = styled.div`
    height: calc(100vh - ${HEADER_HEIGHT}px);
`;
