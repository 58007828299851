import { Instance } from 'mobx-state-tree';
import { Column } from 'react-table';

import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { TableHeaderCellTitle, TableHeaderSortingIcon } from '@shared-component/table/table-head/table-head.styles';

import { ClientsModelType } from '@model/clients-model/clients-model.type';

export const ClientsTableColumns: Column<Partial<Instance<typeof ClientsModelType>>>[] = [
    {
        Header: (
            <>
                <TableHeaderCellTitle>Name</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-fullName-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        accessor: 'fullName',
        id: 'fullName',
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Nationality</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-nationality-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        accessor: 'nationality',
        id: 'nationality',
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Client type</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-type-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        accessor: 'type',
        id: 'type',
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Status</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-status-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        accessor: 'status',
        id: 'status',
    },
    {
        Header: '',
        id: 'actions',
    },
];
