export const MIN_START_YEAR = 1900;

export const TEXT_AREA_LENGTH = 3_000;

export const DIGIT_REAL_PERCENT = 100;

export const DIGIT_TO_PERCENT = 1 / DIGIT_REAL_PERCENT;

export const DEBOUNCE_DELAY = 500;

export const PLUS = '+';
export const MINUS = '-';

export const PAYMENT_SWIFT = 'SWIFT';
export const PAYMENT_SEPA = 'SEPA';

export const REQUIRED_FIELD_ERROR = 'Complete this field';

export const DEFAULT_COUNTRY_CODE = 'GB';

export const PAYMENT_SEPARATOR = '; ';

export const CURRENCY_CLOUD_LINK = 'https://www.currencycloud.com/legal/terms/terms-of-use-eu-cc-bv';
