import styled from 'styled-components';

import { DarkBlue, White } from '@shared-style/colors';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const RequestStatementWrapper = styled.div`
    position: relative;
    width: 808px;
    height: 100%;
    background-color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? DarkBlue : White)};
    border-radius: 16px 0 0 16px;
`;

export const RequestStatementFormWrapper = styled.div`
    padding: 40px;
`;

export const ButtonSubmitWrapper = styled.div`
    margin-top: 32px;
`;
