import { types } from 'mobx-state-tree';

import { ClientsModelTypeInstance } from '@model/clients-model/clients-model.type';

import { clientMainActions } from './clients-actions/clients-model.actions';
import { initialClientsModel } from './clients-model-initials/client-model.initial';

export const ClientModelType = types.model({
    clients: ClientsModelTypeInstance,
    loading: types.model({
        isLoading: types.boolean,
    }),
    error: types.optional(types.string, ''),
});

export const ClientsModel = ClientModelType.actions(clientMainActions);

export const getInitialClientModel = () =>
    ClientsModel.create({
        clients: initialClientsModel as any,
        loading: {
            isLoading: false,
        },
    });
