export enum LegalServiceFormEnum {
    AnnualGross = 'annualGross',
    CompanyTradingName = 'companyTradingName',
    CrimeRelation = 'crimeRelation',
    Currency = 'currency',
    DebtsRelation = 'debtsRelation',
    ExternalBankAccount = 'externalBankAccount',
    ID = 'id',
    IsAccountInAnotherBank = 'isAccountInAnotherBank',
    IsAnotherUboExists = 'isAnotherUboExists',
    IsEuroAccount = 'isEurAccount',
    IsLicenceAvailable = 'isLicenceAvailable',
    IsMulticurrencyAccount = 'isMulticurrencyAccount',
    IsParentCompanyExists = 'isParentCompanyExists',
    IsRepresentativeExists = 'isRepresentativeExists',
    IsSeparateDivisionsExists = 'isSeparateDivisionsExists',
    IsSubmitFinancialTaxReport = 'isSubmitFinancialTaxReport',
    IsSwiftAccount = 'isSwiftAccount',
    MoneyTurnover = 'moneyTurnover',
    MonthlySpendPlan = 'monthlySpendPlan',
    NumberOfCustomers = 'numberOfCustomers',
    NumberOfPersons = 'numberOfPersons',
    OperationCountry = 'operationCountry',
    ParentCompanyInfo = 'parentCompanyInfo',
    PreferredAccountCurrency = 'preferredAccountCurrency',
    ProsecutionRelation = 'prosecutionRelation',
    SeparateDivisionsInfo = 'separateDivisionsInfo',
    SpendPlan = 'spendPlan',
    VoluntarilyDisclosedCrime = 'voluntarilyDisclosedCrime',
}
