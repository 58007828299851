import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Input } from '@shared-component/input/input';
import { Select } from '@shared-component/select/select';
import { isString } from '@shared-util/is-data';

import { useAccountActionsSelector } from '@selector/account/use-account.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';
import { useUsersActionsSelector } from '@selector/user/use-users.actions-selector';

import { typeCardValues } from './cards-choose.options';
import { AccountAddWrapper } from './cards-choose.styles';

const allUsers = { value: '', label: 'All Users' };
const allAccounts = { value: '', label: 'All Accounts' };

export const CardsChooseForm = observer(() => {
    const [users] = useUserSelector();
    const [{ value: valueUserId }] = useField('userId');
    const [{ value: valueAccountId }, , { setValue: setAccountValue }] = useField('accountId');
    const { loadUsers } = useUsersActionsSelector();
    const { loadClientAccounts } = useAccountActionsSelector();

    const isUserSelected = isString(valueUserId);

    useEffect(() => {
        setAccountValue('');
    }, [valueUserId]);

    useEffect(() => void loadUsers(), []);

    useEffect(() => void (isUserSelected && loadClientAccounts(valueUserId)), [valueUserId]);

    return (
        <AccountAddWrapper>
            <Select name="userId" title="Choose user" options={[allUsers, ...users.usersSelect]} />
            <Select name="accountId" title="Choose account" options={[allAccounts]} />
            <Select name="type" title="Card type" options={typeCardValues} />
            <Input name="limits.internetPayment" title="Limit internet payment" />
            <Input name="limits.cashWithdrawal" title="Limit cash payment" />
            <ButtonPrimary isLarge type={ButtonTypeEnum.Submit} isDisabled={!isString(valueAccountId)}>
                Create card
            </ButtonPrimary>
        </AccountAddWrapper>
    );
});
