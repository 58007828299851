import { useContext } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { PaymentStatus } from '@shared-graphql';

import { ButtonColor, ButtonReject } from '@page/common/summary-buttons/summary-buttons.styles';
import { usePaymentActionsSelector } from '@selector/payment/use-payment.actions-selector';

interface PaymentSummaryButtonsProps {
    id: string;
    status: PaymentStatus;
}

export const PaymentSummaryButtons = ({ status, id }: PaymentSummaryButtonsProps) => {
    const { approvePayment } = usePaymentActionsSelector();
    const { onOpen } = useContext(ModelContext);

    const handleApprovePayment = () => approvePayment(id);
    const handleRejectPayment = () => onOpen(ModalEnum.RejectPaymentModal, { componentProps: { paymentId: id } });

    return (
        <>
            <ButtonTransparent onClick={handleApprovePayment} isDisabled={PaymentStatus.Signed !== status}>
                <ButtonColor>Accept</ButtonColor>
            </ButtonTransparent>
            <ButtonTransparent onClick={handleRejectPayment}>
                <ButtonReject>Reject</ButtonReject>
            </ButtonTransparent>
        </>
    );
};
