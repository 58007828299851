import { PropsWithChildren } from 'react';

import { LinkProps } from '@shared-atom/link/link';

import { LinkButton } from './link-button';
import { LinkButtonPrimaryStyle } from './link.styles';

export const LinkButtonPrimary = (props: PropsWithChildren<LinkProps>) => (
    <LinkButton Component={LinkButtonPrimaryStyle} {...props} />
);
