import { createContext, ReactNode, useMemo, useState } from 'react';

import { CurrenciesEnum } from '@shared-enum/currencies.enum';
import { noop } from '@shared-util/noop';

const initialProps = {
    currency: CurrenciesEnum.EUR,
    date: {
        from: null,
        to: null,
    },
    operation: null,
};

export const ApplicationContext = createContext({
    props: initialProps,
    setProps: noop,
});

export const ApplicationContextProvider = ({ children }: { children?: ReactNode | ReactNode[] | JSX.Element }) => {
    const [props, setProps] = useState(initialProps);

    const appProviderProps = useMemo(() => ({ props, setProps }), [props, setProps]);

    return <ApplicationContext.Provider value={appProviderProps}>{children}</ApplicationContext.Provider>;
};
