import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTransparent } from '@shared-atom/button/button-transparent';

import { useClientServicesHeading } from '@page/common/client-services/client-services-heading/client-services-heading.hook';
import { ClientServicesHeadingInterface } from '@page/common/client-services/client-services-heading/client-services-heading.interface';
import {
    ClientServicesHeadingWrapper,
    ClientServicesTitle,
} from '@page/common/client-services/client-services-heading/client-services-heading.styles';

export const ClientServicesHeading = ({
    userType,
    featureTags,
    adminGetClientFeatureTags,
}: ClientServicesHeadingInterface) => {
    const { onAddServiceClick, shouldAddServiceDisabled } = useClientServicesHeading({
        userType,
        featureTags,
        adminGetClientFeatureTags,
    });

    return (
        <ClientServicesHeadingWrapper>
            <ClientServicesTitle>Currently available services</ClientServicesTitle>
            <ButtonTransparent onClick={onAddServiceClick} disabled={shouldAddServiceDisabled}>
                <ButtonPrimary>Add service</ButtonPrimary>
            </ButtonTransparent>
        </ClientServicesHeadingWrapper>
    );
};
