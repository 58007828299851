import { applySnapshot, flow, getSnapshot, types } from 'mobx-state-tree';
import { createContext } from 'react';
import { $enum } from 'ts-enum-util';

import { setLocalStorageValue, getLocalStorageValue } from '@shared-hook/utils/use-local-storage.hook';
import { isObjectEmpty } from '@shared-util/is-object';

import { LangKeysEnum, LocalizationType } from './store-localization.interface';

const browserLang = navigator.language.split('-')[0] as LangKeysEnum;

const langKeyValues = $enum(LangKeysEnum).getValues();
const Localization = types
    .model({
        lang: types.enumeration('LangKey', langKeyValues),
        keys: types.frozen<LocalizationType>(),
        isLoading: false,
    })
    .views(self => ({
        get isKeysEmpty() {
            return isObjectEmpty(self.keys);
        },
    }))
    .actions(self => ({
        setLoading(isLoading: boolean) {
            self.isLoading = isLoading;
        },
        setLangKeys(keys: LocalizationType) {
            const currentSnapshot = getSnapshot(self);
            applySnapshot(self, { ...currentSnapshot, keys });
        },
        setLangKey(langKey: LangKeysEnum) {
            self.lang = langKey;
        },
    }))
    .actions(self => ({
        loadingLanguage: flow(function* loadingLanguage() {
            self.setLoading(true);
            const langKeys = yield import(`../locale/${self.lang}.json`).then(result => result.default);

            self.setLangKeys(langKeys);
            self.setLoading(false);
        }),
    }))
    .actions(self => ({
        afterCreate() {
            self.loadingLanguage();
        },
        setLanguage(lang: LangKeysEnum) {
            self.setLangKey(lang);
            self.loadingLanguage();
            setLocalStorageValue('defaultLanguage', lang);
        },
    }));
function isHasLang(value: LangKeysEnum) {
    const arr: string[] = Object.values(LangKeysEnum);
    const RU = 'ru' as LangKeysEnum;
    if (arr.includes(value)) {
        return value;
    }
    if (value === RU) {
        return LangKeysEnum.UK;
    }
    return LangKeysEnum.EN;
}

function defaultLanguage() {
    const storageLang: LangKeysEnum | string | null = getLocalStorageValue('defaultLanguage');

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (storageLang) return isHasLang(JSON.parse(storageLang));

    return isHasLang(browserLang);
}
export const localizationStore = Localization.create({
    lang: defaultLanguage(),
    keys: {} as LocalizationType,
});

export const LocalizationStoreContext = createContext(localizationStore);
