import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { White } from '@shared-style/colors';

export const BlockWrapper = styled.div`
    width: 100%;
    padding: 32px;
    border-radius: 0 0 16px 16px;
    background-color: ${White};
    height: calc(100% - 60px);
`;

export const AccountsPageBlockWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 0 0 16px 16px;
    background-color: ${White};
`;

export const Title = styled.h3`
    ${Typography.XL.SemiBold.Black.Style}
`;

export const Description = styled.p`
    margin-top: 16px;

    ${Typography.L.Black.Style}
`;
