import { Form } from 'formik';
import styled from 'styled-components';

import { White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const TitleWrapper = styled.div`
    margin-top: 24px;
    padding: 0 24px;
    ${Flex.SpaceBetween.Style}
`;

export const Wrapper = styled.div`
    margin: 8px 0 32px;
    padding: 0 24px;
    border-radius: 16px;
    background-color: ${White};
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
`;

export const FormWrapper = styled(Form)`
    margin: 24px 0 32px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 2fr;
    grid-row-gap: 16px;
`;
