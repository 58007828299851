import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { DateComponent } from '@shared-atom/date/date';
import { CommentsModelType } from '@shared-model/comment-model/comments-model';

import { Item, List, DateWrapper } from './comments-list.styles';

interface CommentsListProps {
    comments: Instance<typeof CommentsModelType>[];
}

export const CommentList: FC<CommentsListProps> = observer(({ comments }) => (
    <List>
        {comments.map(({ createdAt, comment: { id, comment } }) => (
            <Item key={id}>
                <DateWrapper>
                    <DateComponent date={createdAt} />
                </DateWrapper>
                {comment}
            </Item>
        ))}
    </List>
));
