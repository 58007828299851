import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserType } from '@shared-graphql';

export const CommissionProfilePageModel = types
    .model({
        userType: types.maybeNull(types.enumeration($enum(UserType).getValues())),
    })
    .actions(self => ({
        setUserType(userType: UserType) {
            self.userType = userType;
        },
    }));

export const getInitialCommissionProfilePageModel = () =>
    CommissionProfilePageModel.create({
        userType: null,
    });
