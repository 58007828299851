import { FC, SVGProps } from 'react';

import { SubMenuListInterface } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.interface';
import { ClickEventType } from '@shared-type/click-event.type';

import { PageEnum } from '@enum/page.enum';

import ClientsActiveIcon from './assets/clients-fill.svg?react';
import ClientsIcon from './assets/clients.svg?react';
import CommissionProfileActiveIcon from './assets/commission-profile-fill.svg?react';
import CommissionProfileIcon from './assets/commission-profile.svg?react';
import ServicesActiveIcon from './assets/services-fill.svg?react';
import ServicesIcon from './assets/services.svg?react';
import TransactionsActiveIcon from './assets/transactions-fill.svg?react';
import TransactionsIcon from './assets/transactions.svg?react';

export interface PageMenuListInterface {
    title: string;
    icon: FC<SVGProps<SVGSVGElement>>;
    iconActive?: FC<SVGProps<SVGSVGElement>>;
    pageName?: PageEnum;
    onClick?: ClickEventType;
    children?: SubMenuListInterface<PageEnum>[];
}

export const ReferencesMenuList: SubMenuListInterface<PageEnum>[] = [
    {
        title: 'Currencies',
        pageName: PageEnum.Currencies,
    },
];
export const PageMenuList: PageMenuListInterface[] = [
    {
        title: 'Clients',
        icon: ClientsIcon,
        iconActive: ClientsActiveIcon,
        pageName: PageEnum.StatusClients,
    },
    {
        title: 'Payments',
        icon: TransactionsIcon,
        iconActive: TransactionsActiveIcon,
        pageName: PageEnum.Transactions,
    },
    {
        title: 'Commission Profile',
        icon: CommissionProfileIcon,
        iconActive: CommissionProfileActiveIcon,
        pageName: PageEnum.CommissionProfile,
    },
    {
        title: 'References',
        icon: ServicesIcon,
        iconActive: ServicesActiveIcon,
        children: ReferencesMenuList,
    },
];
