import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

export const TableCell = styled.td`
    padding: 11px 20px;
    ${Flex.Column.HorizontalCenter.Style}
    ${Typography.Paragraph14.SemiBold.Neutral900.Style};
    :last-child {
        ${Flex.FlexEnd.Style};
        padding: 0 20px;
    }
    @media (max-width: 991px) {
        min-width: unset !important;
        width: unset !important;
    }
`;

export const CellText = styled.span`
    ${Typography.Paragraph14.SemiBold.Neutral900.HiddenEllipsis.Style};
`;
export const CellHoveredText = styled(CellText)`
    cursor: pointer;
`;
