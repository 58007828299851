import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import {
    InformationModalContextInterface,
    InformationModalProps,
} from '@shared-component/information-modal/information-modal.interface';
import { isTrue } from '@shared-util/is-data';

export const InformationModalContextProvider = ({ children }: { children?: ReactNode | ReactNode[] | JSX.Element }) => {
    const [selectedInformationModal, setInformationModal] = useState<InformationModalEnum | null>(null);
    const [isInformationModalVisible, setInformationModalVisible] = useState(false);
    const [informationModalTimout, setInformationModalTimout] = useState<number>(3000);
    const [props, setProps] = useState({});
    const location = useLocation();

    useEffect(() => {
        setInformationModal(null);
        setProps({});
    }, [location]);

    const onOpen = (modal: InformationModalEnum, newModalProps?: InformationModalProps) => {
        if (newModalProps !== undefined) {
            if (isTrue(newModalProps.timeout)) {
                setInformationModalTimout(newModalProps.timeout as number);
            }
            setProps({ ...props, ...newModalProps });
        }
        setInformationModal(modal);
        setInformationModalVisible(true);
    };

    useEffect(() => {
        let copyMessageTimeoutId: ReturnType<typeof setTimeout> | undefined;

        if (isInformationModalVisible) {
            copyMessageTimeoutId = setTimeout(() => {
                setInformationModal(null);
                setInformationModalVisible(false);
                setInformationModalTimout(3000);
                setProps({});
            }, informationModalTimout);
        }
        return () => void clearTimeout(copyMessageTimeoutId as unknown as number);
    }, [isInformationModalVisible]);

    const contextValue: InformationModalContextInterface = useMemo(
        () => ({
            selectedInformationModal,
            isInformationModalVisible,
            onOpen,
            props,
        }),
        [selectedInformationModal, isInformationModalVisible, onOpen, props]
    );

    return <InformationModalContext.Provider value={contextValue}>{children}</InformationModalContext.Provider>;
};
