import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { Input } from '@shared-component/input/input';
import { InputAmount } from '@shared-component/input/input-amount/input-amount';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';
import { CommissionLimits } from '@shared-graphql';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { CommissionProfileInfoEnum } from '@page/commission-profile/commission-profile-info/commission-profile-info.enum';
import {
    ButtonPlus,
    DeleteIcon,
    EditCommissionWrapper,
    InputWrapper,
    PlusIcon,
    TextItem,
    TextList,
} from '@page/commission-profile/commission-profile-info/commission-profile-info.styles';
import { CommissionProfileEnum } from '@page/commission-profile/commission-profile.enum';

interface CommissionProfileFormListItemProps {
    commissions: CommissionLimits[];
    onAdd: OnEventEmptyType;
    onRemove: OnEventType<number>;
}

export const CommissionProfileFormListItem = ({ commissions, onAdd, onRemove }: CommissionProfileFormListItemProps) => (
    <TextList>
        {commissions.map((template, index) => {
            const handleRemove = () => onRemove(index);
            const commissionPath = `${CommissionProfileEnum.Commissions}.${index}`;

            return (
                <TextItem hasBlueDot={false} key={commissionPath}>
                    <EditCommissionWrapper>
                        <InputWrapper>
                            <InputAmount
                                name={`${commissionPath}.maxAmount`}
                                title={CommissionProfileInfoEnum.MaxAmount}
                            />
                            <InputAmount
                                name={`${commissionPath}.minAmount`}
                                title={CommissionProfileInfoEnum.MinAmount}
                            />
                            <Input
                                name={`${commissionPath}.percentCommission`}
                                title={CommissionProfileInfoEnum.PercentCommission}
                                type={InputTypeEnum.Number}
                            />
                            <InputAmount
                                name={`${commissionPath}.staticCommission`}
                                title={CommissionProfileInfoEnum.StaticCommission}
                            />
                        </InputWrapper>
                        {commissions.length > 1 && (
                            <ButtonTransparent onClick={handleRemove}>
                                <DeleteIcon />
                            </ButtonTransparent>
                        )}
                    </EditCommissionWrapper>
                </TextItem>
            );
        })}
        <ButtonPlus>
            <ButtonTransparent onClick={onAdd}>
                <PlusIcon />
            </ButtonTransparent>
        </ButtonPlus>
    </TextList>
);
