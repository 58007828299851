export const Whisper = '#F6F5FA';

export const DarkBlue = '#2D475D';
export const White = '#FFFFFF';
export const Fiord = '#435664';
export const FiordLight = '#415B72';
export const ChathamsBlue = '#0F4C81';

export const PrimeBlue = '#10A3AE';
export const BondiBlue = '#049DB2';
export const WaterLeaf = '#A3E4E9';
export const NileBlue = '#16324B';
export const Polar = '#E8F8FA';
export const DarkGrey = '#9A9EAE';
export const WhiteGrey = '#F1F4F6';
export const WhiteDark = '#42596D';
export const Geyser = '#D5DADF';
export const BlueBayoux = '#576C7D';
export const Portage = '#A683EE';

export const Mystic = '#DEE5EE';
export const Raven = '#72808B';
export const GrayChateau = '#A1ABB1';
export const PacificBlue = '#05A7BE';
export const Dark = '#142C3D';
export const Red = '#F37575';

export const LinkWater = '#F2F6FB';
export const Iron = '#D0D5D8';

export const SelectiveYellow = '#FDB600';
export const JungleGreen = '#31B676';

export const Black = '#030406';
export const Abbey = '#454749';
export const Bunker = '#161C22';
export const Ebony = '#04070A';

export const TulipTree = '#E6B041';

export const Wedgewood = '#4C7CA5';
export const Blumine = '#1F5B8E';
export const VeniceBlue = '#0D4E85';
export const ToryBlue = '#0E5D96';
export const Tarawera = '#072F4B';
export const Danube = '#5195C6';
export const Genoa = '#166167';
export const PeriwinkleGray = '#C3D9EA';
export const JaggedIce = '#C3EAE9';
export const Iceberg = '#DFF5F4';
export const BlizzardBlue = '#99E8EF';
export const DarkLinkWater = '#DFECF5';

export const Orient = '#004C82';
export const CuriousBlue = '#148DE3';
export const Concrete = '#F2F2F2';
export const AthensGray = '#F4F6F8';

export const Alabaster = '#F8F8F8';
export const Spindle = '#BAD9EF';

export const SlateGray = '#6A8292';

export const Endeavour = '#0064AC';
export const Midnight = '#00233E';
export const AzureRadiance = '#008CF0';

export const Tacha = '#D9C15C';
export const LuxorGold = '#AF8B39';

export const Lochmara = '#0086C5';
export const Mercury = '#E6E6E6';
export const SantasGray = '#9B9EB1';

export const Lynch = '#5C6F84';

export const DodgerBlue = '#38AAFA';

export const Casal = '#295F72';
export const Java = '#19BBC8';

export const EerieBlack = '#1F1F1F';
export const GreenHaze = '#00997A';
export const Gray65 = '#A6A6A6';
export const CodGray = '#090909';

export const Neutral50 = '#FFFFFF';
export const Neutral100 = '#F3F5F5';
export const Neutral200 = '#E8E8E8';
export const Neutral400 = '#A6A6A6';
export const Neutral500 = '#8C8C8C';
export const Neutral600 = '#292929';
export const Neutral700 = '#1F1F1F';
export const Neutral900 = '#090909';

export const Primary50 = '#E9FFFA';
export const Primary100 = '#D2FFF6';
export const Primary300 = '#90D1C4';
export const Primary500 = '#00997A';
export const Primary800 = '#007D64';

export const AddedGreen = '#CEF2E0';
export const AddedViolet = '#CED4F2';
export const AddedOrange = '#F2D8CE';
export const AddedPink = '#F2CEDE';

export const Success50 = '#F0FAF1';
export const Success100 = '#C9F5CE';
export const Success500 = '#32A43E';

export const Warning50 = '#FFFBEB';
export const Warning100 = '#FEF5D0';
export const Warning500 = '#F59E0B';

export const Error50 = '#FFEBEB';
export const Error100 = '#FFE0E0';
export const Error500 = '#FF5E5B';
