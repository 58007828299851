import 'css-reset-and-normalize';
import styled from 'styled-components';

import { Whisper } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

import '@shared-style/global.css';

export const RootWrapper = styled.div`
    flex: 1;
    background-color: ${Whisper};
    ${Flex.Column.Style}
`;
