import { UserCompanyRelationType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { RegistrationLegalDocumentsEnum } from '@page/legal-client-profile/legal-client-profile-documents/registration-legal-documents.enum';

export const RegistrationLegalDocumentsPersonOptions = {
    [RegistrationLegalDocumentsEnum.Ownership]: new Set([UserCompanyRelationType.Ownership]),
    [RegistrationLegalDocumentsEnum.Director]: new Set([UserCompanyRelationType.Director]),
    [RegistrationLegalDocumentsEnum.Beneficiary]: new Set([UserCompanyRelationType.Beneficiary]),
    [RegistrationLegalDocumentsEnum.UserCreated]: new Set([]),
    [RegistrationLegalDocumentsEnum.Chart]: new Set([]),
    [RegistrationLegalDocumentsEnum.Company]: new Set([]),
};

export const RegistrationLegalDocumentsPersonTitle = {
    [RegistrationLegalDocumentsEnum.Ownership]: LocalizationEnum.CommonCompanyRelationOwnership,
    [RegistrationLegalDocumentsEnum.Beneficiary]: LocalizationEnum.CommonCompanyRelationBeneficiary,
    [RegistrationLegalDocumentsEnum.Director]: LocalizationEnum.CommonCompanyRelationDirector,
    [RegistrationLegalDocumentsEnum.UserCreated]: LocalizationEnum.CommonCompanyRelationUserCreated,
    [RegistrationLegalDocumentsEnum.Chart]: LocalizationEnum.CommonCompanyRelationUserCreated,
    [RegistrationLegalDocumentsEnum.Company]: LocalizationEnum.CommonCompanyRelationUserCreated,
};
