import styled from 'styled-components';

import { White } from '@shared-style/colors';

export const ArchiveWrapper = styled.div`
    width: 100%;
    margin-top: 40px;
`;

export const TableWrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: ${White};
    border-radius: 16px;
    padding: 16px 0 40px;
`;
