import { observer } from 'mobx-react-lite';

import { CheckBox } from '@shared-component/checkbox/checkbox';
import { CountrySelect } from '@shared-component/country-select/country-select';
import { Input } from '@shared-component/input/input';
import { InputDate } from '@shared-component/input/input-date/input-date';
import { BaseOptionInterface } from '@shared-component/select/select.props';
import { FormHalfRow, FormRow } from '@shared-form/form-common/form-common.styles';

import { LegalCompanyFormEnum } from './legal-company-form.enum';
import { useLegalCompanyFormLocalization } from './legal-company-form.localization-hook';
import { CheckboxInformation } from './legal-company-form.styles';

interface LegalCompanyFormProps {
    countries?: BaseOptionInterface[];
}

export const LegalCompanyForm = observer(({ countries }: LegalCompanyFormProps) => {
    const {
        companyNameTitle,
        registrationCountryTitle,
        companyNumberTitle,
        companyTaxNumberTitle,
        companySiteTitle,
        registrationDateTitle,
        conductBusinessTitle,
        groupPartTitle,
        conductBusinessInformation,
        groupPartInformation,
    } = useLegalCompanyFormLocalization();

    return (
        <>
            <Input name={LegalCompanyFormEnum.CompanyName} title={companyNameTitle} />
            <FormRow>
                <CountrySelect
                    name={LegalCompanyFormEnum.RegistrationCountry}
                    title={registrationCountryTitle}
                    countries={countries}
                />
            </FormRow>
            <FormHalfRow>
                <Input name={LegalCompanyFormEnum.CompanyNumber} title={companyNumberTitle} />
                <Input name={LegalCompanyFormEnum.CompanyTaxNumber} title={companyTaxNumberTitle} />
            </FormHalfRow>
            <FormRow>
                <Input name={LegalCompanyFormEnum.CompanySite} title={companySiteTitle} />
            </FormRow>
            <FormRow>
                <InputDate name={LegalCompanyFormEnum.RegistrationDate} title={registrationDateTitle} />
            </FormRow>
            <FormRow>
                <CheckBox name={LegalCompanyFormEnum.ConductBusiness} title={conductBusinessTitle} />
                <CheckboxInformation>{conductBusinessInformation}</CheckboxInformation>
            </FormRow>
            <FormRow>
                <CheckBox name={LegalCompanyFormEnum.GroupPart} title={groupPartTitle} />
                <CheckboxInformation>{groupPartInformation}</CheckboxInformation>
            </FormRow>
        </>
    );
});
