import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';

import { EmptyState } from '@shared-atom/empty-state/empty-state';
import { AccountCard } from '@shared-component/account-card/account-card';
import { AnimationLoading } from '@shared-component/animation/animation-loading/animation-loading';
import { EventEmitterEnum } from '@shared-enum/event-emitter.enum';
import { Account, Maybe } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { useOn } from '@shared-util/event-emitter';
import { noop } from '@shared-util/noop';

import { ContentWrapper, EmptyContentWrapper } from './accounts-list.styles';
import EmptyAccountsImage from './assets/empty-accounts.svg?react';

interface AccountsListProps {
    isShowMore: boolean;
    isLoading: boolean;
    clientId?: string;
    ccAccounts: Account[];
    limitCcAccounts: Account[];
    selectedAccount: Maybe<Account>;
    onClick?: OnEventType<string>;
    loadClientAccounts?: OnEventType<string>;
}

export const AccountsList: FC<AccountsListProps> = observer(
    ({
        isShowMore,
        isLoading,
        ccAccounts,
        limitCcAccounts,
        selectedAccount,
        clientId,
        loadClientAccounts = noop,
        onClick = noop,
    }) => {
        const itemList = isShowMore ? ccAccounts : limitCcAccounts;
        const shouldRenderEmptyState = itemList.length === 0;

        const handleClick = (id: string) => () => onClick(id);
        useEffect(() => void loadClientAccounts(clientId), []);

        useOn(EventEmitterEnum.AccountsUpdate, ({ detail: { clientId: id } }) => loadClientAccounts(id));

        if (isLoading) {
            return (
                <EmptyContentWrapper>
                    <AnimationLoading />
                </EmptyContentWrapper>
            );
        }

        if (shouldRenderEmptyState) {
            return (
                <EmptyState
                    icon={EmptyAccountsImage}
                    titleKey={LocalizationEnum.DashboardAccountsEmptyTitle}
                    textKey={LocalizationEnum.DashboardAccountsEmptyText}
                />
            );
        }

        return (
            <ContentWrapper>
                {itemList.map((account: any) => (
                    <AccountCard
                        key={account.id}
                        onClick={handleClick(account.id)}
                        account={account}
                        clientId={clientId}
                        isSelected={account.id === selectedAccount?.id}
                        hasCopy
                    />
                ))}
            </ContentWrapper>
        );
    }
);
