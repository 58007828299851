import styled from 'styled-components';

import { LinkDisable } from '@shared-atom/link/link.styles';
import { Black } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const Wrapper = styled.ul`
    margin-bottom: 24px;

    ${Flex.Style};
`;

export const LinkWrapper = styled.li`
    position: relative;

    ${LinkDisable} {
        cursor: auto;
        color: ${Black};
    }

    &::after {
        content: '>';
        position: relative;
        margin-left: 8px;
    }

    &:last-child {
        &::after {
            content: '';
        }
    }

    :not(:first-child) {
        margin-left: 8px;
    }
`;
