import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { ButtonBordered } from '@shared-atom/button/button-bordered';

import { AccountCcType } from '@model/account-model/account-model.type';

import Approve from './assets/approve.svg?react';
import Blocked from './assets/blocked.svg?react';
import { ActionsWrapper } from './cell.styles';

interface ActionsCellRenderProps {
    account: Instance<typeof AccountCcType>;
}

const ActionsCellRender: FC<ActionsCellRenderProps> = ({ account }) => (
    <ActionsWrapper>
        <ButtonBordered title={`Approve ${account}`} onClick={console.log}>
            <Approve />
        </ButtonBordered>
        <ButtonBordered title={`Blocking ${account}`} onClick={console.log}>
            <Blocked />
        </ButtonBordered>
    </ActionsWrapper>
);

export const ActionsCell = (account: Instance<typeof AccountCcType>) => <ActionsCellRender account={account} />;
