import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { FormWrapper } from '@shared-component/forms/form-common/form-common.styles';
import { ButtonWrapper, RegistrationLegalPersonTitle } from '@shared-component/registration/common.styles';
import { RegistrationLegalServiceForm } from '@shared-form/registration-legal-service-form/registration-legal-service-form';
import { LegalServiceFormInitial } from '@shared-form/registration-legal-service-form/registration-legal-service-form.initial';
import { LegalServiceFormValidation } from '@shared-form/registration-legal-service-form/registration-legal-service-form.validation';
import { CompanyService, CompanyServiceInput } from '@shared-graphql';

import { useCompanySelector } from '@selector/company/use-company.selector';
import { useCurrenciesSelector } from '@selector/currencies/use-currencies.selector';

import { registrationLegalServiceMap } from './registration-legal-service.options';

export const LegalClientProfileService = observer(() => {
    const [{ companyService }] = useCompanySelector();
    const [{ currenciesActiveSelect }] = useCurrenciesSelector();

    const handleServiceUpdate = (data: CompanyServiceInput) => console.log(data);

    return (
        <>
            <RegistrationLegalPersonTitle>Services</RegistrationLegalPersonTitle>
            <FormWrapper>
                <Formik
                    initialValues={
                        registrationLegalServiceMap(companyService as CompanyService) ?? LegalServiceFormInitial
                    }
                    onSubmit={handleServiceUpdate}
                    validationSchema={LegalServiceFormValidation}
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize
                >
                    <Form>
                        <RegistrationLegalServiceForm currencies={currenciesActiveSelect} />
                        <ButtonWrapper>
                            <ButtonPrimary isLarge type={ButtonTypeEnum.Submit}>
                                Save
                            </ButtonPrimary>
                        </ButtonWrapper>
                    </Form>
                </Formik>
            </FormWrapper>
        </>
    );
});
