import { CurrentTabName } from '@component/breadcrumbs/breadcrumb-tabs/current-tab-name';
import { LegalClientNameTab } from '@component/breadcrumbs/breadcrumb-tabs/legal-client-name';
import { OptionsInterface } from '@component/tabs/tabs.interface';
import { PageEnum } from '@enum/page.enum';

export const legalClientTabOptions: OptionsInterface[] = [
    {
        title: 'Information',
        name: PageEnum.LegalClientProfileInformation,
    },
    {
        title: 'Address',
        name: PageEnum.LegalClientProfileAddress,
    },
    {
        title: 'Your People',
        name: PageEnum.LegalClientProfilePersons,
    },
    {
        title: 'Shareholders',
        name: PageEnum.LegalClientProfileShareholders,
    },
    {
        title: 'Documents',
        name: PageEnum.LegalClientProfileDocuments,
    },
    {
        title: 'Services',
        name: PageEnum.LegalClientProfileServices,
    },
];

export const breadcrumbList = [
    {
        path: PageEnum.StatusClients,
        name: 'Clients',
    },
    {
        name: <LegalClientNameTab />,
        path: PageEnum.LegalClientSummary,
    },
    {
        name: <CurrentTabName options={legalClientTabOptions} />,
    },
];
