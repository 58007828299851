import { Badge } from '@shared-component/badge';
import { BadgeSizeEnum } from '@shared-component/badge/types';
import { Button } from '@shared-component/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-component/button/types';
import { FeatureTagStatus } from '@shared-graphql';

import { ClientServicesListItemInterface } from '@page/common/client-services/client-services-list/client-services-list-item/client-services-list-item.interface';
import { featureTagStatusToBadgeTypeMapping } from '@page/common/client-services/client-services-list/client-services-list-item/client-services-list-item.options';
import {
    ButtonsGroup,
    ClientServiceName,
    ClientServicesListItemWrapper,
    ClientServiceStatus,
} from '@page/common/client-services/client-services-list/client-services-list-item/client-services-list-item.styles';

export const ClientServicesListItem = ({
    featureTag,
    onRejectFeatureTag,
    onNextStatusFeatureTag,
    onSetRequiredFields,
    isRejectFeatureTagLoading,
    isNextStatusFeatureTagLoading,
}: ClientServicesListItemInterface) => (
    <ClientServicesListItemWrapper>
        <ClientServiceName>{featureTag.tagTitle}</ClientServiceName>
        <ClientServiceStatus>
            <Badge
                type={featureTagStatusToBadgeTypeMapping[featureTag.status]}
                size={BadgeSizeEnum.S}
                title={featureTag.status}
            />
        </ClientServiceStatus>
        <ButtonsGroup>
            {featureTag.requiredFields?.length > 0 && (
                <Button
                    type={ButtonTypeEnum.BLACK_STROKE}
                    size={ButtonSizeEnum.M}
                    onClick={() => onSetRequiredFields(featureTag)}
                    title="Set required fields"
                />
            )}
            <Button
                type={ButtonTypeEnum.COLOR_STROKE}
                size={ButtonSizeEnum.M}
                onClick={() => onNextStatusFeatureTag(featureTag.featureTag)}
                title="Approve"
                isDisabled={FeatureTagStatus.Approved === featureTag.status || featureTag.requiredFields?.length > 0}
                isLoading={isNextStatusFeatureTagLoading}
            />
            <Button
                type={ButtonTypeEnum.DANGER}
                size={ButtonSizeEnum.M}
                onClick={() => onRejectFeatureTag({ featureTag: featureTag.featureTag, status: featureTag.status })}
                title="Reject"
                isLoading={isRejectFeatureTagLoading}
                isDisabled={FeatureTagStatus.Rejected === featureTag.status}
            />
        </ButtonsGroup>
    </ClientServicesListItemWrapper>
);
