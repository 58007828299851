import styled from 'styled-components';

import { getAnimationStyle } from '@shared-style/animation';
import { Mystic, WhiteDark } from '@shared-style/colors';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

interface TableRowWrapperProps {
    height: number;
    isVisible: boolean;
}

export const TableRow = styled.tr`
    padding-bottom: 14px;
`;

export const TableRowWrapper = styled.tr<TableRowWrapperProps>`
    display: block;
    overflow: hidden;
    height: ${({ height }) => height}px;

    box-shadow: 0 ${({ isVisible }) => (isVisible ? 0 : '1px')} 0
        ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? WhiteDark : Mystic)};

    ${getAnimationStyle(['height', 'box-shadow'])};
    @media (max-width: 560px) {
        overflow: scroll;
    }
`;

export const TableCellWrapper = styled.td`
    width: 100vw;
    padding: 8px;
`;
