export enum RegistrationLegalPersonPrivateProfileFormEnum {
    UserRelation = 'companyRelation',
    UserRole = 'companyRole',
    UserCreate = 'isUserCreate',
    Ownership = 'isOwnership',
    Director = 'isDirector',
    PowerOfAttorney = 'isPowerOfAttorney',
    Beneficiary = 'isBeneficiary',
    Email = 'email',
    Phone = 'phone',
    OwnershipPercent = 'ownershipPercent',
    BeneficiaryPercent = 'beneficiaryPercent',
}
