import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, FC, SVGProps, useState, RefObject } from 'react';

import { FieldError } from '@shared-component/field-error/field-error';
import { InputTagEnum } from '@shared-component/input/input-tag.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist, isString } from '@shared-util/is-data';

import { InputTypeEnum } from './input-type.enum';
import { IconWrapper, InputStyle, Label, LabelWrapper, ErrorWrapper, InputWrapper } from './input.styles';

export interface InputProps extends Partial<Pick<HTMLInputElement, 'type' | 'tabIndex' | 'disabled'>> {
    title: string;
    name: string;
    onFocus?: OnEventType<boolean>;
    icon?: FC<SVGProps<SVGSVGElement>>;
    inputRef?: RefObject<HTMLInputElement>;
    tag?: InputTagEnum;
    children?: React.ReactNode;
}

export const Input: FC<InputProps> = observer(
    ({ title, name, type = InputTypeEnum.Text, icon, tag, inputRef, onFocus, disabled, children, ...props }) => {
        const [field, { error }, { setError, setValue }] = useField(name);
        const [isFocused, setFocused] = useState(false);

        const hasValue = typeof field.value === 'string' ? isString(field.value) : isExist(field.value);
        const hasIcon = icon !== undefined;
        const hasError = error !== undefined;
        const shouldRenderLabel = !hasIcon && isString(title);

        const toggleFocus = (isFocus: boolean) => () => {
            setFocused(isFocus);
            if (onFocus instanceof Function) {
                onFocus(isFocus);
            }
        };

        const handleBlur = () => {
            toggleFocus(false)();
            if (type === InputTypeEnum.Text) {
                setValue(field.value?.trim() ?? null);
            }
        };

        const handleChange = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
            setValue(type === InputTypeEnum.Number && value !== '' ? parseFloat(value) : value);
            if (hasError && tag !== InputTagEnum.TextArea) {
                setError(undefined);
            }
        };

        const Icon = icon !== undefined && IconWrapper(icon);
        const taggedInput = { as: tag } as unknown;
        return (
            <InputWrapper>
                <LabelWrapper hasError={hasError} isFocused={isFocused} isDisabled={disabled}>
                    {Icon !== false && <Icon hasValue={hasValue} />}
                    <InputStyle
                        {...props}
                        {...field}
                        {...(taggedInput as any)}
                        ref={inputRef}
                        type={type}
                        value={field.value ?? ''}
                        disabled={disabled}
                        hasIcon={hasIcon}
                        placeholder={hasIcon ? title : ''}
                        onFocus={toggleFocus(true)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {shouldRenderLabel && (
                        <Label hasValue={hasValue} isFocused={isFocused} hasError={hasError}>
                            {title}
                        </Label>
                    )}
                    {children}
                </LabelWrapper>
                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
            </InputWrapper>
        );
    }
);
