import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral200 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const ClientServicesListItemWrapper = styled.div`
    padding: 7px 0;
    border-bottom: 1px solid ${Neutral200};
    display: flex;
`;
export const ClientServiceName = styled.span`
    ${Typography.Paragraph14.Neutral900.Style};
    line-height: 16px;
    padding: 13px 20px;
    min-width: 200px;
`;
export const ClientServiceStatus = styled.div`
    padding: 11px 20px;
    min-width: 160px;
`;
export const ButtonsGroup = styled.div`
    ${Flex.Row.Style};
    padding: 0 20px;
    gap: 24px;
    margin-left: auto;
`;
