import { Instance } from 'mobx-state-tree';
import { Column } from 'react-table';

import { AccountCardType } from '@model/account-model/account-card-model/account-card-model.type';

export const CardsTableColumns: Column<Partial<Instance<typeof AccountCardType>>>[] = [
    {
        id: 'number',
        accessor: 'number',
        Header: 'Number',
    },
    {
        id: 'balance',
        accessor: 'balance',
        Header: 'Balance',
    },
    {
        id: 'type',
        accessor: 'type',
        Header: 'Type',
        maxWidth: 64,
    },
    {
        Header: 'Limit',
        columns: [
            {
                Header: 'Internet',
                maxWidth: 64,
            },
            {
                Header: 'Cash',
                maxWidth: 64,
            },
        ],
    },
    {
        id: 'button',
        maxWidth: 96,
    },
];
