import { Form } from 'formik';

import { PageTitle } from '@shared-atom/page-title/page-title';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { CommissionProfileHeaderForm } from './commission-profile-header-form/commission-profile-header-form';
import { Title, HeaderWrapper } from './commission-profile-header.styles';

export const CommissionProfileHeader = () => (
    <HeaderWrapper>
        <PageTitle title={LocalizationEnum.CommissionProfileTitle} />
        <Title>
            <Translate langKey={LocalizationEnum.CommissionProfileTitle} />
        </Title>
        <Form>
            <CommissionProfileHeaderForm />
        </Form>
    </HeaderWrapper>
);
