import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { RefObject, useRef, useState } from 'react';
import ReactSelect, { ValueType, SelectComponentsConfig, components } from 'react-select';
import { ElRef } from 'react-select/base';
import { StateManager } from 'react-select/src/stateManager';
import { OptionTypeBase } from 'react-select/src/types';

import { FieldError } from '@shared-component/field-error/field-error';
import { useBoundingSize } from '@shared-hook/utils/use-bounding-size.hook';
import { isExist, isString } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

import { SelectMenuList } from './select-menu-list/select-menu-list';
import { selectRowRender } from './select.initial';
import { BaseOptionInterface, RowRenderInterface, SelectProps } from './select.props';
import {
    containerStyle,
    controlStyle,
    inputStyle,
    placeholderStyle,
    selectMenuStyle,
    SelectWrapper,
    ErrorWrapper,
    Wrapper,
    singleValueStyle,
    valueContainerStyle,
    indicatorSeparatorStyle,
    indicatorsContainerStyle,
    noOptionsMessageStyle,
} from './select.styles';

export const Select = observer(
    <T extends BaseOptionInterface>({
        name,
        title,
        options = [],
        rowHeight = 48,
        rowCountView = 5,
        menuHeight,
        menuWidth,
        isUseVal,
        isDisabled = false,
        rowRender = selectRowRender,
        onChange = noop,
        onValueChanged,
        valueRender,
        defaultValue,
        ...selectProps
    }: SelectProps<T>) => {
        const [field, { error }, { setValue, setError }] = useField<ValueType<T, boolean> | undefined>(name);

        const [isMenuOpened, setMenuOpened] = useState(false);
        const [filterValue, setFilterValue] = useState('');
        const selectRef = useRef() as RefObject<StateManager<T, boolean>>;
        const controlSize = useBoundingSize(selectRef.current?.select?.controlRef);
        const isDarkTheme = false;
        const selectOptions = isString(filterValue)
            ? options.filter(option =>
                option[isUseVal !== undefined ? 'value' : 'label'].toLowerCase().includes(filterValue)
            )
            : options;
        const rowCount = selectOptions.length;
        const hasError = isExist(error);

        if (onValueChanged == null) {
            onValueChanged = () => {};
        }

        const menuListHeight = menuHeight ?? rowHeight * (rowCountView > rowCount ? rowCount : rowCountView);

        const handleChange = (selectValue: any) => {
            setValue(selectValue?.value);
            onChange();
            onValueChanged(selectValue?.value);
        };

        const toggleFocus = (isOpen: boolean) => () => {
            setMenuOpened(isOpen);
            if (hasError) {
                setError(undefined);
            }
        };
        const handleInputChange = (value: string) => setFilterValue(value.toLowerCase());

        const selectComponents = {
            SingleValue: valueRender ?? components.SingleValue,
            MenuList:
                rowCount > 0
                    ? SelectMenuList({
                        menuHeight: menuListHeight,
                        rowHeight,
                        rowCount,
                        rowRender: rowRender as RowRenderInterface<OptionTypeBase>,
                        menuWidth: menuWidth ?? controlSize?.width ?? 0,
                    })
                    : components.MenuList,
        } as unknown as SelectComponentsConfig<T, boolean>;

        const optionValue = selectOptions.find(({ value }) => value === (field.value as any as string));

        return (
            <Wrapper>
                <SelectWrapper>
                    <ReactSelect
                        {...selectProps}
                        isDisabled={isDisabled}
                        ref={selectRef as ElRef}
                        placeholder={title}
                        value={optionValue ?? null}
                        options={selectOptions}
                        defaultValue={defaultValue}
                        components={selectComponents}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onMenuOpen={toggleFocus(true)}
                        onMenuClose={toggleFocus(false)}
                        openMenuOnFocus
                        styles={{
                            container: containerStyle,
                            placeholder: placeholderStyle,
                            valueContainer: valueContainerStyle,
                            singleValue: singleValueStyle(isDarkTheme),
                            input: inputStyle(isDarkTheme),
                            menu: selectMenuStyle(isDarkTheme),
                            indicatorSeparator: indicatorSeparatorStyle(isDarkTheme),
                            indicatorsContainer: indicatorsContainerStyle,
                            control: controlStyle(isMenuOpened, hasError, isDarkTheme),
                            noOptionsMessage: noOptionsMessageStyle,
                        }}
                    />
                </SelectWrapper>
                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
            </Wrapper>
        );
    }
);
