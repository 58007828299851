import styled from 'styled-components';

import { FieldErrorWrapper } from '@shared-component/field-error/field-error.styles';
import CalendarIcon from '@shared-component/input/assets/calendar.svg?react';
import { MaskedInputStyle } from '@shared-component/input/input.styles';
import { Dark, DarkGrey, White } from '@shared-style/colors';

export const DateIcon = styled(CalendarIcon)`
    position: absolute;
    right: 16px;
    top: 16px;
    cursor: pointer;
    fill: ${DarkGrey};
`;

export const TimeWrapper = styled.div`
    position: absolute;
    background-color: ${White};
    border-radius: 4px;
    z-index: 1;
    bottom: -16px;
    transform: translateY(100%);
    outline: none;
`;

export const InputDateWrapper = styled.div`
    position: relative;
`;

export const DateMaskedStyle = styled(MaskedInputStyle)`
    border: none;
    flex: 1;
    font-size: 16px;
    color: ${Dark};
    padding-left: 16px;
    margin-top: 20px;
    background-color: transparent;
    line-height: 32px;
    @media screen and (max-device-width: 480px) {
        margin-top: 10px;
    }
`;

export const ErrorWrapper = styled(FieldErrorWrapper)``;
