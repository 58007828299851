import { FC, useMemo } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ExternalLink } from '@shared-atom/external-link/external-link';
import { UserActivatedStatus } from '@shared-graphql';
import { ClickEventType } from '@shared-type/click-event.type';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

import { useUserEvents } from '@hook/user/user-events.hook';
import { useUserExternal } from '@hook/user/user-external.hook';
import {
    ButtonColor,
    ButtonColorStroke,
    ButtonReject,
    ButtonSendCompliance,
} from '@page/common/summary-buttons/summary-buttons.styles';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

interface SummaryStatusButtonsProps {
    handleAction: OnEventType<UserActivatedStatus, OnEventEmptyType>;
    status: UserActivatedStatus;
    isApplicationProfileCompleted: boolean;
    getIbanqCallbackUrl: ClickEventType;
}

const REJECT_BUTTON_VISIBLE_STATUSES = new Set([
    UserActivatedStatus.Compliance,
    UserActivatedStatus.Created,
    UserActivatedStatus.Updated,
]);

export const SummaryStatusButtons: FC<SummaryStatusButtonsProps> = ({
    handleAction,
    status,
    isApplicationProfileCompleted,
    getIbanqCallbackUrl,
}) => {
    const [{ email, id }] = usePrivateClientSelector();

    const hasStatusApproved = useMemo(() => UserActivatedStatus.Approved === status, [status]);
    const hasStatusActivated = useMemo(() => UserActivatedStatus.Activated === status, [status]);
    const hasStatusCompliance = useMemo(() => UserActivatedStatus.Compliance === status, [status]);
    const hasStatusBlocked = useMemo(() => UserActivatedStatus.Blocked === status, [status]);

    const shouldRenderBackOnboardingButton = useMemo(
        () => hasStatusBlocked && isApplicationProfileCompleted,
        [hasStatusBlocked, isApplicationProfileCompleted]
    );
    const shouldRenderBackRegistrationButton = useMemo(
        () => hasStatusBlocked && !isApplicationProfileCompleted,
        [hasStatusBlocked, isApplicationProfileCompleted]
    );

    const shouldRenderRejectButton = useMemo(() => REJECT_BUTTON_VISIBLE_STATUSES.has(status), [status]);
    const [handleResetPassword] = useUserEvents(email);
    const [externalApplicantLink] = useUserExternal(id);

    return (
        <>
            {hasStatusApproved && (
                <ButtonTransparent onClick={handleAction(UserActivatedStatus.Activated)} isSmall>
                    <ButtonColor>Activate Client</ButtonColor>
                </ButtonTransparent>
            )}
            {hasStatusCompliance && (
                <ButtonTransparent onClick={handleAction(UserActivatedStatus.Approved)} isSmall>
                    <ButtonColor>Approve Client</ButtonColor>
                </ButtonTransparent>
            )}

            <ButtonTransparent onClick={getIbanqCallbackUrl} isSmall>
                <ButtonColor>Generate callback URL</ButtonColor>
            </ButtonTransparent>

            {isExist(externalApplicantLink) && (
                <ButtonTransparent isSmall>
                    <ExternalLink href={externalApplicantLink}>
                        <ButtonColorStroke>Edit profile</ButtonColorStroke>
                    </ExternalLink>
                </ButtonTransparent>
            )}

            <ButtonTransparent onClick={handleResetPassword} isSmall>
                <ButtonColorStroke>Reset password</ButtonColorStroke>
            </ButtonTransparent>

            {shouldRenderBackRegistrationButton && (
                <ButtonTransparent onClick={handleAction(UserActivatedStatus.Created)} isSmall>
                    <ButtonSendCompliance>Back to Registration</ButtonSendCompliance>
                </ButtonTransparent>
            )}
            {shouldRenderBackOnboardingButton && (
                <ButtonTransparent onClick={handleAction(UserActivatedStatus.Updated)} isSmall>
                    <ButtonSendCompliance>Back to Onboarding</ButtonSendCompliance>
                </ButtonTransparent>
            )}

            {(hasStatusApproved || hasStatusActivated) && (
                <ButtonTransparent onClick={handleAction(UserActivatedStatus.Blocked)} isSmall>
                    <ButtonReject>Block</ButtonReject>
                </ButtonTransparent>
            )}

            {shouldRenderRejectButton && (
                <ButtonTransparent onClick={handleAction(UserActivatedStatus.Rejected)} isSmall>
                    <ButtonReject>Reject</ButtonReject>
                </ButtonTransparent>
            )}
        </>
    );
};
