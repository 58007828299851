export enum LegalCompanyFormEnum {
    OwnershipPercent = 'ownershipPercent',
    CompanyName = 'companyName',
    RegistrationCountry = 'registrationCountry',
    CompanyNumber = 'companyNumber',
    CompanyTaxNumber = 'companyTaxNumber',
    CompanySite = 'companySite',
    RegistrationDate = 'registrationDate',
    ConductBusiness = 'isConductBusiness',
    GroupPart = 'isGroupPart',
}
