import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { CustomInputWrapper } from '@shared-component/input/input.styles';
import { Dark } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const TransactionsHeaderWrapper = styled.div`
    ${Flex.VerticalCenter.SpaceBetween.Style};
`;

export const TransactionsTitle = styled.h1`
    color: ${Dark};
    ${Typography.XXL.Normal.Style};
`;

export const SearchWrapper = styled.div`
    margin: 0 16px 0 auto;
    min-width: 278px;
    ${CustomInputWrapper} {
        row-gap: 0;
    }
`;
