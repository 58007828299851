import { components, ControlProps } from 'react-select';

import SearchIcon from '@shared-component/input/assets/outlined-search.svg?react';
import { BaseOptionInterface } from '@shared-component/table/table-filters/select-filter/select-filter.props';

export const Control = ({ children, ...props }: ControlProps<BaseOptionInterface, false>) => {
    const style = { cursor: 'pointer', marginLeft: '16px' };

    return (
        <components.Control {...props}>
            <SearchIcon style={style} />
            {children}
        </components.Control>
    );
};
