import { memo } from 'react';

import { Animation } from '@shared-component/animation/animation';

import DefaultLoader from './assets/default-loader.animation.json';
import WhiteLoader from './assets/white-loader.animation.json';

interface AnimationLoadingProps {
    isWhite?: boolean;
}

export const AnimationLoading = memo(({ isWhite = false }: AnimationLoadingProps) => (
    <Animation animationData={isWhite ? WhiteLoader : DefaultLoader} isLoop />
));
