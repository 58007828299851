import { useCallback, useEffect, useRef, useState } from 'react';

import { UserCredentialsStepFormInterface } from '@shared-form/user-credentials-step-form/user-credentials-step-form.interface';
import { UserLogin, UserStartLogin, WebPreferredLoginMethod } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useUserConfirmLogin } from '@shared-hook/login-steps/user-confirm-login.hook';
import { useUserGoogleAuthLogin } from '@shared-hook/login-steps/user-google-auth-login.hook';
import { useUserStartLogin } from '@shared-hook/login-steps/user-start-login.hook';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { getLocalStorageValue, removeLocalStorageValue } from '@shared-hook/utils/use-local-storage.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isExist, isString, isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { useUserSelector } from '@selector/user/use-user.selector';

const initialLoginCredentials = {
    email: '',
    password: '',
    biometry: false,
};

export const useLoginSteps = () => {
    const navigate = useRouterNavigate();
    const [userPhone, setUserPhone] = useState<string>('');

    const [, isUserLoading] = useUserSelector();

    const [isLoginStarted, setLoginStarted] = useState(false);
    const otpErrorText = useLocalizationText(LocalizationEnum.OtpFormError);
    const loginErrorText = useLocalizationText(LocalizationEnum.LoginFormError);

    const loginCredentials = useRef<UserCredentialsStepFormInterface>(initialLoginCredentials);

    const [userStartLoginData, userStartLoginError, isUserStartLoginProcessed, userStartLogin] = useUserStartLogin();
    const [
        userGoogleAuthLoginData,
        userGoogleAuthLoginError,
        isUserGoogleAuthLoginProcessed,
        userGoogleAuthenticatorLogin,
    ] = useUserGoogleAuthLogin();
    const [userConfirmLoginData, userConfirmLoginError, isUserConfirmLoginProcessed, userConfirmLogin] =
        useUserConfirmLogin();

    const onSuccess = useCallback(() => {
        const { name, params } = getLocalStorageValue('redirectPage') ?? {};
        removeLocalStorageValue('redirectPage');
        navigate(isString(name) ? name : PageEnum.StatusClients, params);
    }, []);

    const [errorMessage, setErrorMessage] = useState<string>();

    const webPreferredLoginMethod =
        (userStartLoginData?.userStartLogin as UserStartLogin)?.webPreferredLoginMethod ?? WebPreferredLoginMethod.Sms;

    const handleUserCredentialsSubmit = useCallback(
        ({ email, password }: UserCredentialsStepFormInterface) => {
            loginCredentials.current.email = email;
            loginCredentials.current.password = password;

            userStartLogin({ email, password, recaptchaToken: '', loginMethod: null });
        },
        [userStartLogin]
    );

    const handleOtpSubmit = useCallback((otp: string) => {
        setErrorMessage(undefined);
        userConfirmLogin({
            ...loginCredentials.current,
            otp,
        });
    }, []);

    const handleGoogeAuthSubmit = useCallback((totp: string) => {
        setErrorMessage(undefined);
        userGoogleAuthenticatorLogin({
            ...loginCredentials.current,
            totp,
        });
    }, []);

    const onLoginWithAnotherMethod = useCallback(
        (loginMethod: WebPreferredLoginMethod) =>
            userStartLogin({
                email: loginCredentials.current.email,
                password: loginCredentials.current.password,
                recaptchaToken: '',
                loginMethod,
            }),
        []
    );

    useEffect(
        () =>
            void (
                isExist((userStartLoginData?.userStartLogin as UserStartLogin)?.phone) &&
                setUserPhone((userStartLoginData?.userStartLogin as UserStartLogin).phone as string)
            ),
        [userStartLoginData]
    );

    useEffect(() => {
        if (isTrue((userStartLoginData?.userStartLogin as UserStartLogin)?.webPreferredLoginMethod)) {
            setLoginStarted(true);
        }
    }, [userStartLoginData]);

    useEffect(
        () => void (isExist((userConfirmLoginData?.userConfirmLogin as UserLogin)?.id) && onSuccess()),
        [userConfirmLoginData]
    );
    useEffect(
        () => void (isExist((userGoogleAuthLoginData?.userGoogleAuthenticatorLogin as UserLogin)?.id) && onSuccess()),
        [userGoogleAuthLoginData]
    );

    useEffect(() => {
        if (isExist(userStartLoginError)) {
            setErrorMessage(userStartLoginError?.message ?? loginErrorText);
        } else {
            setErrorMessage(undefined);
        }
    }, [userStartLoginError]);

    useEffect(() => {
        if (isExist(userConfirmLoginError)) {
            setErrorMessage(userConfirmLoginError?.message ?? otpErrorText);
        } else {
            setErrorMessage(undefined);
        }
    }, [userConfirmLoginError]);

    useEffect(() => {
        if (isExist(userGoogleAuthLoginError)) {
            setErrorMessage(userGoogleAuthLoginError?.message ?? otpErrorText);
        } else {
            setErrorMessage(undefined);
        }
    }, [userGoogleAuthLoginError]);

    return {
        phone: isLoginStarted ? userPhone : '',
        errorMessage:
            isUserStartLoginProcessed || isUserConfirmLoginProcessed || isUserGoogleAuthLoginProcessed
                ? ''
                : errorMessage,
        handleOtpSubmit:
            webPreferredLoginMethod === WebPreferredLoginMethod.GoogleAuthenticator
                ? handleGoogeAuthSubmit
                : handleOtpSubmit,
        handleUserCredentialsSubmit,
        onLoginWithAnotherMethod,
        setErrorMessage,
        isLoading:
            isUserStartLoginProcessed || isUserGoogleAuthLoginProcessed || isUserConfirmLoginProcessed || isUserLoading,
        webPreferredLoginMethod,
    };
};
