import { types } from 'mobx-state-tree';

import {
    CommissionProfilePageModel,
    getInitialCommissionProfilePageModel,
} from '@model/page-models/commission-profile-page.model';

export const PageStore = types.model({
    commissionProfilePage: CommissionProfilePageModel,
});

export const getInitialPageStore = () =>
    PageStore.create({
        commissionProfilePage: getInitialCommissionProfilePageModel(),
    });
