import { useEffect, useState } from 'react';

import { OnEventEmptyType } from '@shared-type/on-event.type';

const THREE_SECONDS = 3000;

export const useCopyMessage = (): [boolean, OnEventEmptyType] => {
    const [isCopyMessageVisible, setCopyMessageVisible] = useState(false);

    const onCopy = () => setCopyMessageVisible(true);

    useEffect(() => {
        let copyMessageTimeoutId: ReturnType<typeof setTimeout> | undefined;

        if (isCopyMessageVisible) {
            copyMessageTimeoutId = setTimeout(() => setCopyMessageVisible(false), THREE_SECONDS);
        }
        return () => void clearTimeout(copyMessageTimeoutId as unknown as number);
    }, [isCopyMessageVisible]);

    return [isCopyMessageVisible, onCopy];
};
