import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const usePrivateProfileFormLocalization = () => {
    const nameTitle = useLocalizationText(LocalizationEnum.CommonFirstName);
    const lastNameTitle = useLocalizationText(LocalizationEnum.CommonLastName);
    const thirdNameTitle = useLocalizationText(LocalizationEnum.PrivateFormThirdnameTitle);
    const birthdateTitle = useLocalizationText(LocalizationEnum.PrivateFormBirthdayTitle);
    const genderTitle = useLocalizationText(LocalizationEnum.PrivateFormGenderTitle);
    const financialSituationTitle = useLocalizationText(LocalizationEnum.PrivateFormFinancialSituationTitle);
    const nationalityTitle = useLocalizationText(LocalizationEnum.PrivateFormNationalityTitle);

    return {
        nameTitle,
        lastNameTitle,
        thirdNameTitle,
        birthdateTitle,
        genderTitle,
        financialSituationTitle,
        nationalityTitle,
    };
};
