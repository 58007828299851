import { useMemo, useState } from 'react';

import { SortEnum } from '@shared-graphql';

export const useSortFilter = (sorts: Record<string, SortEnum> = {}) => {
    const [sort, setSortChange] = useState(sorts);

    const onSortChange = (name: string, type: SortEnum) =>
        setSortChange(currentSort => ({ ...currentSort, [name]: type }));

    const onSortsChange = (newSorts: Record<string, SortEnum>) => setSortChange(newSorts);

    return useMemo(() => [{ sort }, { onSortChange, onSortsChange }, {}], [sort]);
};
