import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { CellHoveredText } from '@shared-component/table/table-cell/table-cell.styles';
import { getBeneficiaryName } from '@shared-component/transaction-cells/transactions-cells.utils';
import { Transaction } from '@shared-graphql';

export const BeneficiaryAccountCell = (transaction: Transaction) => {
    const name = getBeneficiaryName(transaction);

    return (
        <CellHoveredText>
            <Tooltip content={`${name}`} dataFor={`tooltip-for-transaction-beneficiar-account-${name}`}>
                {name}
            </Tooltip>
        </CellHoveredText>
    );
};
