import { Formik } from 'formik';
import { FC } from 'react';

import { ButtonPrimaryStyle } from '@shared-atom/button/button.style';
import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { CustomInputSearch } from '@shared-component/input/input-search/custom-input-search';
import { OnEventType } from '@shared-type/on-event.type';
import { noop } from '@shared-util/noop';

import {
    TransactionsTitle,
    TransactionsHeaderWrapper,
    SearchWrapper,
} from '@page/transactions/transactions-header/transactions-header.styles';

interface TransactionsHeaderInterface {
    onSearchChange: OnEventType<string>;
    filters: Record<string, any>;
}

export const TransactionsHeader: FC<TransactionsHeaderInterface> = ({ filters: { search }, onSearchChange }) => (
    <TransactionsHeaderWrapper>
        <TransactionsTitle>Payments</TransactionsTitle>
        <SearchWrapper>
            <Formik initialValues={{ search }} onSubmit={noop} enableReinitialize>
                <CustomInputSearch placeholder="Search by payment ID" onChange={onSearchChange} />
            </Formik>
        </SearchWrapper>
        <Tooltip content="Will be available soon" dataFor="export–to-csv-button-tooltip">
            <ButtonPrimaryStyle>Export to CSV</ButtonPrimaryStyle>
        </Tooltip>
    </TransactionsHeaderWrapper>
);
