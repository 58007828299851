import { Instance } from 'mobx-state-tree';
import { useMemo } from 'react';

import { BeneficiaryDetails } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { initialBeneficiary } from '@shared-model/beneficiary-model/beneficiary-model.initial';
import { OnEventType } from '@shared-type/on-event.type';

import { AccountTypeUnion } from '@model/account-model/account-model.type';
import { useAccountSelector } from '@selector/account/use-account.selector';
import { usePaymentSelector } from '@selector/payment/use-payment.selector';

type BeneficiaryFormType = Omit<BeneficiaryDetails, 'paymentType' | 'charityNumber' | 'invoiceNumber' | 'invoiceDate'>;

export interface PaymentEditFormInterface {
    amount: number;
    reason: string;
    currency: string;
    payerName: string | null;
    payerAccountNumber: string;
    beneficiary: BeneficiaryFormType;
}

export const usePaymentEdit = (): [PaymentEditFormInterface, OnEventType<PaymentEditFormInterface>] => {
    const {
        params: { clientId, paymentId },
    } = useCurrentNavigation();
    const [
        {
            payment: {
                amount,
                accountId,
                reason,
                type,
                payerName,
                beneficiary,
                documents: initialDocuments,
                currency: { code: currency },
            },
        },
    ] = usePaymentSelector();
    const [{ ccAccounts }] = useAccountSelector();

    const payerAccount = useMemo(
        () =>
            ccAccounts.find(account => (account.accountId as unknown as string) === accountId) as Instance<
                typeof AccountTypeUnion
            >,
        [ccAccounts, accountId]
    );

    const payerAccountNumber = useMemo(() => payerAccount?.banks?.number ?? '', [payerAccount, type]);

    const documentIds = initialDocuments?.map(d => d.key) ?? [];

    const handleUpdatePayment = ({
        payerAccountNumber: account,
        payerName: name,
        ...fields
    }: PaymentEditFormInterface) =>
        console.log({ ...fields, id: paymentId, type, accountId, documentIds, purpose: null }, clientId);

    return [
        {
            amount,
            reason,
            currency,
            payerName,
            beneficiary: beneficiary ?? initialBeneficiary,
            payerAccountNumber,
        },
        handleUpdatePayment,
    ];
};
