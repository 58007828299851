import { observer } from 'mobx-react-lite';
import React, { Fragment, useMemo } from 'react';
import { v4 } from 'uuid';

import { UserDocument, UserDocumentType } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

import {
    DocumentPreview,
    DocumentsRow,
    DocumentsWrapper,
    DocumentTitle,
    OtherDocumentsList,
} from '@page/private-client/private-client-documents/private-client-documents-active/private-client-documents-active.styles';
import { useCompanySelector } from '@selector/company/use-company.selector';

// interface RegistrationLegalDocumentsCompanyProps {
//     onBack: OnEventEmptyType;
// }

// const REQUIRED_DOCUMENT_TYPES = new Set([
//     UserDocumentType.Corporate,
//     UserDocumentType.Articles,
//     UserDocumentType.Business,
// ]);

export const RegistrationLegalDocumentsCompany = observer(() => {
    // export const RegistrationLegalDocumentsCompany = observer(({ onBack }: RegistrationLegalDocumentsCompanyProps) => {
    const [{ documents }] = useCompanySelector();

    // const documentForm = usePrivateDocumentForm(documents, () => {}, REQUIRED_DOCUMENT_TYPES);

    // const title =
    //     RegistrationLegalDocumentsCompanyTitles[documentForm.selectedUploadType as CompanyDocumentType] ??
    //     LocalizationEnum.RegistrationLegalDocumentsCompanyTitle;
    const proofOfAddressDocument = useMemo(
        () => documents.find(({ type }) => type === UserDocumentType.Location),
        [documents]
    );
    const businessDocument = useMemo(
        () => documents.find(({ type }) => type === UserDocumentType.Business),
        [documents]
    );
    const corporateDocument = useMemo(
        () => documents.find(({ type }) => type === UserDocumentType.Corporate),
        [documents]
    );
    const otherDocuments = useMemo(
        () =>
            documents.filter(
                ({ type }) =>
                    ![UserDocumentType.Corporate, UserDocumentType.Location, UserDocumentType.Business].includes(type)
            ),
        [documents]
    ) as UserDocument[];

    return (
        <DocumentsWrapper>
            <DocumentsRow>
                <DocumentTitle>1. Proof of address</DocumentTitle>
                {isTrue(proofOfAddressDocument?.file?.path) ? (
                    <DocumentPreview src={proofOfAddressDocument?.file?.path} />
                ) : (
                    '-'
                )}
            </DocumentsRow>
            <DocumentsRow>
                <DocumentTitle>2. Memorandum</DocumentTitle>
                {isTrue(businessDocument?.file?.path) ? <DocumentPreview src={businessDocument?.file?.path} /> : '-'}
            </DocumentsRow>
            <DocumentsRow>
                <DocumentTitle>3. Certificate of incorporation</DocumentTitle>
                {isTrue(corporateDocument?.file?.path) ? <DocumentPreview src={corporateDocument?.file?.path} /> : '-'}
            </DocumentsRow>
            <DocumentsRow>
                <DocumentTitle>4. Other documents</DocumentTitle>
                {otherDocuments.length > 0 ? (
                    <OtherDocumentsList>
                        {otherDocuments.map(document => (
                            <Fragment key={v4()}>
                                {isTrue(document?.file?.path) ? <DocumentPreview src={document?.file?.path} /> : '-'}
                            </Fragment>
                        ))}
                    </OtherDocumentsList>
                ) : (
                    <>-</>
                )}
            </DocumentsRow>
            {/* <RegistrationLegalPersonTitle> */}
            {/*    <Translate langKey={title} /> */}
            {/* </RegistrationLegalPersonTitle> */}
            {/* <PersonsWrapper> */}
            {/*    <PrivateDocumentForm */}
            {/*        documentForm={documentForm} */}
            {/*        listChooses={RegistrationLegalDocumentsCompanyOptions} */}
            {/*        hasOtherTypeDocuments */}
            {/*    > */}
            {/*        <LegalClientProfileFormsButton */}
            {/*            onBack={ */}
            {/*                documentForm.selectedUploadType === null */}
            {/*                    ? onBack */}
            {/*                    : documentForm.handleSelectedUploadType(null) */}
            {/*            } */}
            {/*            isSubmitDisabled={documentForm.isSubmitDisabled} */}
            {/*        /> */}
            {/*    </PrivateDocumentForm> */}
            {/* </PersonsWrapper> */}
        </DocumentsWrapper>
    );
});
