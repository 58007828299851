import { object, string } from 'yup';

import { isString } from '@shared-util/is-data';
import { emailValidation } from '@shared-util/validation';

import { CreateClientFormEnum } from './create-client-form.enum';

export const CreateClientFormValidation = object().shape({
    [CreateClientFormEnum.Type]: string().required(),
    [CreateClientFormEnum.Email]: string().when(CreateClientFormEnum.UserId, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        is: (userId: string) => !isString(userId),
        then: emailValidation(),
        otherwise: string().nullable(),
    }),
});
