import { values } from 'mobx';
import { Instance, types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserActivatedStatus, UserType } from '@shared-graphql';
import { AddressType } from '@shared-model/address-type/address.type';
import {
    UserLegalStatusModel,
    UserPermissions,
} from '@shared-model/user-model/user-model-legal/user-legal-status-model';
import { UserDeclarationType } from '@shared-model/user-model/user-model-private/user-declaration.type';
import { UserDocumentsType } from '@shared-model/user-model/user-model-private/user-documents.type';
import { UserPrivateStatusModel } from '@shared-model/user-model/user-model-private/user-private-status-model';
import { UserProfileType } from '@shared-model/user-model/user-model-private/user-profile/user-profile.type';
import { UserServiceType } from '@shared-model/user-model/user-model-private/user-service.type';

import { UserModelActions } from './users-actions/user-model.actions';

const userActivatedStatusValues = $enum(UserActivatedStatus).getValues();

export const UserModelTypes = types
    .model({
        id: types.identifier,
        email: types.string,
        phone: types.string,
        type: types.maybeNull(types.enumeration([UserType.Private, UserType.Legal])),
        status: types.enumeration(userActivatedStatusValues),
        privateStatus: UserPrivateStatusModel,
        legalStatus: UserLegalStatusModel,
        profile: types.maybeNull(UserProfileType),
        address: types.maybeNull(AddressType),
        service: types.maybeNull(UserServiceType),
        isAcceptAgreement: types.maybeNull(types.boolean),
        declaration: types.maybeNull(UserDeclarationType),
        documents: types.array(UserDocumentsType),
        createdAt: types.Date,
        companyPermissions: types.maybeNull(UserPermissions),
    })
    .views(self => ({
        get fullName() {
            return self.profile && `${self.profile.name} ${self.profile.lastName}`;
        },
    }));

export const UserModelType = UserModelTypes.actions(UserModelActions);

export const UserModelTypeInstance = types
    .model('UserModel', {
        elements: types.map(UserModelType),
        totalCount: types.number,
        selectedId: types.maybeNull(types.string),
    })
    .views(self => ({
        get users() {
            return values(self.elements) as unknown as Instance<typeof UserModelTypes>[];
        },
    }))
    .views(self => ({
        get usersSelect() {
            return self.users.map(user => ({
                value: user.id,
                label: `${user.email} (${user.fullName})`,
            }));
        },
    }));
