import styled from 'styled-components';

import { DarkBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const NavigationRoot = styled.section`
    position: relative;
    padding-left: 432px;
    height: 100%;
    flex: 1;
    background-color: ${White};
    ${Flex.Column.VerticalCenter.Style}
`;

export const NavigationWrapper = styled.nav`
    position: absolute;
    width: 432px;
    height: 100%;
    padding: 40px 0 48px;
    top: 0;
    left: 0;
    background-color: ${DarkBlue};
    border-radius: 0 24px 24px 0;
    ${Flex.Column.Style}
`;

export const NavigationHeader = styled.div`
    height: 48px;
    padding: 0 48px;
    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const NavigationContentWrapper = styled.div`
    width: 100%;
    flex: 1;
`;

export const NavigationChildWrapper = styled.div`
    max-width: 440px;
    padding: 48px 0;
    margin: 0 auto;
    height: 100%;
`;

export const NavigationList = styled.ul`
    margin-top: 40px;
    flex: 1;
`;

export const LogoWrapper = styled.div`
    width: 98px;
    height: 28px;
`;

export const AvatarWrapper = styled.div`
    width: 48px;
    height: 48px;
    @media (max-width: 991px) {
        width: 35px;
        height: 35px;
    }
`;
