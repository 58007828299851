import { useLazyQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';

import {
    AccountAdministration,
    AccountAdministrationInput,
    AdminUpdateAccountAdministrationByType,
    GetAccountAdministrationByType,
    Mutation,
    Query,
} from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

import {
    initialAccountAdministration,
    initialCommissionUserType,
} from '@page/commission-profile/commission-profile-initials/commission-profile.initials';
import { useStore } from '@store/store-context';

export const useCommissionProfileInfoAdministration = (): [
    Omit<AccountAdministration, 'balanceFee' | 'userType'>,
    OnEventType<Omit<AccountAdministrationInput, 'balanceFee'>>
] => {
    const {
        page: {
            commissionProfilePage: { userType },
        },
    } = useStore();
    const [accountAdministration, setAccountAdministration] = useState(initialAccountAdministration);

    const [loadAccountAdministration, { data: accountAdministrationData }] = useLazyQuery<
    Pick<Query, 'getAccountAdministrationByType'>
    >(GetAccountAdministrationByType, { variables: { userType: userType ?? initialCommissionUserType } });

    const [updateAccountAdministrationByType, { data: dataUpdateAdministration }] = useMutation<
    Pick<Mutation, 'adminUpdateAccountAdministrationByType'>
    >(AdminUpdateAccountAdministrationByType);

    const handleUpdateAccountAdministration = (values: Omit<AccountAdministrationInput, 'balanceFee'>) => {
        updateAccountAdministrationByType({ variables: { userType, values } });
    };

    useEffect(() => {
        loadAccountAdministration();
    }, []);

    useEffect(
        () => void (isExist(dataUpdateAdministration) && loadAccountAdministration()),
        [dataUpdateAdministration]
    );

    useEffect(() => {
        if (isExist(accountAdministrationData)) {
            setAccountAdministration({
                ...initialAccountAdministration,
                ...accountAdministrationData?.getAccountAdministrationByType,
            });
        }
    }, [accountAdministrationData]);

    return [accountAdministration, handleUpdateAccountAdministration];
};
