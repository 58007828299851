import { useField } from 'formik';
import { Instance } from 'mobx-state-tree';
import { FC, useEffect } from 'react';

import { TextArea } from '@shared-component/input/textarea/textarea';
import { CommentsModelType } from '@shared-model/comment-model/comments-model';
import { isString } from '@shared-util/is-data';

import { TextAreaEnum } from './comments.enum';

interface FormTextAreaProps {
    comments: Instance<typeof CommentsModelType>[];
}

export const FormTextArea: FC<FormTextAreaProps> = ({ comments }) => {
    const [{ value }, , { setValue }] = useField(TextAreaEnum.Comment);

    useEffect(() => void (isString(value) && setValue('')), [comments.length]);

    return <TextArea title="Comment Text" name={TextAreaEnum.Comment} />;
};
