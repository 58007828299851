import { observer } from 'mobx-react-lite';

import { useRegistrationLegalAddress } from '@shared-hook/registration-legal/registration-legal-address/registration-legal-address.hook';

import { useCompanySelector } from '@selector/company/use-company.selector';

import { LegalClientProfileAddress } from './legal-client-profile-address';

export const LegalClientProfileAddressPage = observer(() => {
    const [initialData]: any[] = useCompanySelector();

    const { currentAddress, isSelectedPostal, validationRules, handleSubmit, onBack } = useRegistrationLegalAddress(
        () => () => {}
    );

    return (
        <LegalClientProfileAddress
            isSelectedPostal={isSelectedPostal}
            initialData={initialData}
            validationRules={validationRules}
            onSubmit={handleSubmit}
            customPathName={currentAddress}
            onBack={onBack}
        />
    );
});
