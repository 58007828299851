import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { PaymentFiles } from '@shared-component/payment-files/payment-files';
import { FormButtonWrapper } from '@shared-form/form-common/form-common.styles';

import { PaymentDocumentsEnum } from './payment-documents.enum';
import { usePaymentDocuments } from './payment-documents.hook';

const PAYMENT_DOCUMENT_SIZE = parseInt(import.meta.env.VITE_REACT_APP_PAYMENT_DOCUMENT_SIZE, 10);

export const PaymentDocuments = observer(() => {
    const [initialDocuments, onSubmit, isLoading] = usePaymentDocuments();

    return (
        <Formik
            initialValues={initialDocuments}
            onSubmit={onSubmit}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
        >
            <Form>
                <PaymentFiles name={PaymentDocumentsEnum.Documents} limit={PAYMENT_DOCUMENT_SIZE} />
                <FormButtonWrapper>
                    <ButtonPrimary type={ButtonTypeEnum.Submit} disabled={isLoading}>
                        Save
                    </ButtonPrimary>
                </FormButtonWrapper>
            </Form>
        </Formik>
    );
});
