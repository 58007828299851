import { RegistrationLegalPersonCommonWrapper } from '@shared-component/registration/components/registration-legal-person-common-wrapper/registration-legal-person-common-wrapper';
import { RegistrationLegalPersonLegalCompany } from '@shared-component/registration/registration-legal-person-legal/registration-legal-person-legal-company/registration-legal-person-legal-company';
import { CompanyAddressInput } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

import { RegistrationNavigation } from '@component/registration-navigation/registration-navigation';

import { RegistrationLegalPersonLegalAddress } from './registration-legal-person-legal-address/registration-legal-person-legal-address';
import { useRegistrationLegalPersonLegal } from './registration-legal-person-legal.hook';
import { useRegistrationLegalPersonLegalModal } from './registration-legal-person-legal.modal-hook';
import {
    RegistrationLegalPersonLegalModalEnum,
    RegistrationLegalPersonLegalModalNavigationOptions,
} from './registration-legal-person-legal.modal-options';

export const RegistrationLegalPersonLegalModal = () => {
    const [view, setView] = useRegistrationLegalPersonLegalModal();
    const { status, company, handleUpdateCompany } = useRegistrationLegalPersonLegal();

    const handleSubmit = (type?: RegistrationLegalPersonLegalModalEnum) => (data: Partial<CompanyAddressInput>) => {
        handleUpdateCompany(data);
        if (isTrue(type)) {
            setView(type as RegistrationLegalPersonLegalModalEnum)();
        }
    };

    return (
        <RegistrationLegalPersonCommonWrapper>
            <RegistrationNavigation<RegistrationLegalPersonLegalModalEnum>
                options={RegistrationLegalPersonLegalModalNavigationOptions}
                name={view}
                valueEnable={status}
                valueCheck={status}
                avatarName={company.companyName}
                onClick={setView}
            >
                {view === RegistrationLegalPersonLegalModalEnum.Company && (
                    <RegistrationLegalPersonLegalCompany
                        initialData={company}
                        onSubmit={handleSubmit(RegistrationLegalPersonLegalModalEnum.Address)}
                    />
                )}
                {view === RegistrationLegalPersonLegalModalEnum.Address && (
                    <RegistrationLegalPersonLegalAddress
                        initialData={company}
                        onSubmit={handleSubmit()}
                        onBack={setView(RegistrationLegalPersonLegalModalEnum.Company)}
                    />
                )}
            </RegistrationNavigation>
        </RegistrationLegalPersonCommonWrapper>
    );
};
