import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { CompanyAddressInput } from '@shared-graphql';
import { useRegistrationLegalAddress } from '@shared-hook/registration-legal/registration-legal-address/registration-legal-address.hook';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { LegalClientProfileAddress } from '@page/legal-client-profile/legal-client-profile-address/legal-client-profile-address';

interface RegistrationLegalPersonLegalAddressProps {
    initialData: CompanyAddressInput;
    onSubmit: OnEventType<CompanyAddressInput>;
    onBack: OnEventEmptyType;
}

export const RegistrationLegalPersonLegalAddress: FC<RegistrationLegalPersonLegalAddressProps> = observer(
    ({ initialData, onSubmit, onBack: handleBack }) => {
        const { currentAddress, isSelectedPostal, validationRules, handleSubmit, onBack } = useRegistrationLegalAddress(
            onSubmit,
            handleBack
        );

        return (
            <LegalClientProfileAddress
                isSelectedPostal={isSelectedPostal}
                initialData={initialData}
                validationRules={validationRules}
                onSubmit={handleSubmit}
                customPathName={currentAddress}
                onBack={onBack}
            />
        );
    }
);
