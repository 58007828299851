import { object, string } from 'yup';

import { emailValidation } from '@shared-util/validation';

import { UserCredentialsStepFormEnum } from './user-credentials-step-form.enum';

export const UserCredentialsStepFormSchema = object().shape({
    [UserCredentialsStepFormEnum.Email]: emailValidation(),
    [UserCredentialsStepFormEnum.Password]: string().required('Password is required field'),
});
