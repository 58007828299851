import { Outlet } from 'react-router-dom';

import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { Breadcrumbs } from '@component/breadcrumbs/breadcrumbs';
import { Tabs } from '@component/tabs/tabs';
import { BreadCrumbsWrapper } from '@page/common/common.styles';

import { breadcrumbList, privateClientTabsOptions } from './private-client-profile.options';
import { PrivateClientProfileInnerWrapper, PrivateClientProfileWrapper } from './private-client-profile.styles';

export const PrivateClientProfilePage = () => {
    const { params } = useCurrentNavigation();

    return (
        <>
            <BreadCrumbsWrapper>
                <Breadcrumbs breadcrumbs={breadcrumbList} />
            </BreadCrumbsWrapper>
            <PrivateClientProfileWrapper>
                <Tabs params={params} options={privateClientTabsOptions}>
                    <PrivateClientProfileInnerWrapper>
                        <Outlet />
                    </PrivateClientProfileInnerWrapper>
                </Tabs>
            </PrivateClientProfileWrapper>
        </>
    );
};
