import styled from 'styled-components';

import { Flex } from '@shared-style/flex';

export const Wrapper = styled.div`
    ${Flex.VerticalCenter.Style}
`;

export const FullName = styled.span`
    width: 160px;
`;
