import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';

import { Translate } from '@shared-atom/translate/translate';
import { FieldError } from '@shared-component/field-error/field-error';
import { ErrorWrapper, Label } from '@shared-component/input/input.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { LangKeysEnum } from '@shared-store/store-localization.interface';
import { isExist } from '@shared-util/is-data';

import { DISABLE_AUTOCOMPLETE, FIRST_DAY_OF_WEEK, WEEK_MODIFIERS } from './day-picker-range.options';
import { DayPickerRangeWrapper, DayPickerLabelWrapper, Dash, DayPickerInputWrapper } from './day-picker-range.styles';

import 'react-day-picker/src/style.css';

function parseDate(str: string, format: string, locale: string) {
    const parsed = dateFnsParse(str, format, new Date(), { locale: locale as unknown as Locale | undefined });
    if (DateUtils.isDate(parsed)) {
        return parsed;
    }
    return undefined;
}

function formatDate(date: Date, format: string, locale: string) {
    return dateFnsFormat(date, format, { locale: locale as unknown as Locale | undefined });
}

interface DayPickerRangeProps {
    nameFrom: string;
    nameTo: string;
    locale?: LangKeysEnum;
    fromKey?: LocalizationEnum;
    toKey?: LocalizationEnum;
}

export const DayPickerRange: FC<DayPickerRangeProps> = observer(
    ({
        nameFrom,
        nameTo,
        locale,
        fromKey = LocalizationEnum.DayPickerRangeFrom,
        toKey = LocalizationEnum.DayPickerRangeTo,
    }) => {
        const [{ value: from }, { error: errorFrom }, { setValue: setValueFrom }] = useField<Date>(nameFrom);
        const [{ value: to }, { error: errorTo }, { setValue: setValueTo }] = useField<Date>(nameTo);
        const [isFocusedFrom, setFocusedFrom] = useState(false);
        const [isFocusedTo, setFocusedTo] = useState(false);

        const modifiers = useMemo(() => ({ start: from, end: to, ...WEEK_MODIFIERS }), [from, to]);
        const selectedDays = useMemo(() => [from, { from, to }], [from, to]);
        const hasErrorFrom = useMemo(() => isExist(errorFrom), [errorFrom]);
        const hasErrorTo = useMemo(() => isExist(errorTo), [errorTo]);

        const handleFromChange = useCallback((day: Date) => setValueFrom(day), []);
        const handleToChange = useCallback((day: Date) => setValueTo(day), []);

        const handleToggleFocusFrom = useCallback((value: boolean) => () => setFocusedFrom(value), []);
        const handleToggleFocusTo = useCallback((value: boolean) => () => setFocusedTo(value), []);
        const FORMAT = 'dd-MM-yyyy';

        return (
            <DayPickerRangeWrapper>
                <DayPickerInputWrapper>
                    <DayPickerLabelWrapper isFocused={isFocusedFrom} hasError={hasErrorFrom}>
                        <DayPickerInput
                            placeholder=""
                            value={from}
                            formatDate={formatDate}
                            format={FORMAT}
                            parseDate={parseDate}
                            onDayChange={handleFromChange}
                            onDayPickerShow={handleToggleFocusFrom(true)}
                            onDayPickerHide={handleToggleFocusFrom(false)}
                            inputProps={{ name: nameFrom, readOnly: true, ...DISABLE_AUTOCOMPLETE }}
                            dayPickerProps={{
                                locale,
                                selectedDays,
                                modifiers,
                                firstDayOfWeek: FIRST_DAY_OF_WEEK,
                                toMonth: to,
                                ...(isExist(to) && { disabledDays: { after: to } }),
                            }}
                        />
                        <Label isFocused={isFocusedFrom} hasValue={isExist(from)} hasError={hasErrorFrom}>
                            <Translate langKey={fromKey} />
                        </Label>
                    </DayPickerLabelWrapper>
                    <ErrorWrapper>
                        <FieldError name={nameFrom} />
                    </ErrorWrapper>
                </DayPickerInputWrapper>
                <Dash>—</Dash>
                <DayPickerInputWrapper>
                    <DayPickerLabelWrapper isFocused={isFocusedTo} hasError={hasErrorTo}>
                        <DayPickerInput
                            placeholder=""
                            value={to}
                            formatDate={formatDate}
                            format={FORMAT}
                            parseDate={parseDate}
                            onDayChange={handleToChange}
                            onDayPickerShow={handleToggleFocusTo(true)}
                            onDayPickerHide={handleToggleFocusTo(false)}
                            inputProps={{ name: nameTo, readOnly: true, ...DISABLE_AUTOCOMPLETE }}
                            dayPickerProps={{
                                locale,
                                selectedDays,
                                modifiers,
                                firstDayOfWeek: FIRST_DAY_OF_WEEK,
                                month: from,
                                fromMonth: from,
                                ...(isExist(from) && { disabledDays: { before: from } }),
                            }}
                        />
                        <Label isFocused={isFocusedTo} hasValue={isExist(to)} hasError={hasErrorTo}>
                            <Translate langKey={toKey} />
                        </Label>
                    </DayPickerLabelWrapper>
                    <ErrorWrapper>
                        <FieldError name={nameTo} />
                    </ErrorWrapper>
                </DayPickerInputWrapper>
            </DayPickerRangeWrapper>
        );
    }
);
