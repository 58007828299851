import styled from 'styled-components';

import EyeCloseIcon from '@shared-component/input/assets/magnifier.svg?react';
import SearchIcon from '@shared-component/input/assets/search.svg?react';
import { DarkGrey } from '@shared-style/colors';

export const MagnifierIcon = styled(EyeCloseIcon)`
    fill: ${DarkGrey};
    width: 24px;
    height: 24px;
`;
export const CustomMagnifierIcon = styled(SearchIcon)`
    width: 20px;
    height: 20px;
`;

export const SeachWrapper = styled.div`
    padding: 16px;
`;
export const CustomSearchWrapper = styled.div`
    width: 20px;
    height: 20px;
`;
