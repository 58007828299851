import { observer } from 'mobx-react-lite';

import { RegistrationLegalPersonCommonWrapper } from '@shared-component/registration/components/registration-legal-person-common-wrapper/registration-legal-person-common-wrapper';
import { RegistrationLegalPersonPrivateAddress } from '@shared-component/registration/registration-legal-person-private/registration-legal-person-private-address/registration-legal-person-private-address';
import { RegistrationLegalPersonPrivateDocument } from '@shared-component/registration/registration-legal-person-private/registration-legal-person-private-document/registration-legal-person-private-document';
import { RegistrationLegalPersonPrivatePersonal } from '@shared-component/registration/registration-legal-person-private/registration-legal-person-private-personal/registration-legal-person-private-personal';
import { RegistrationLegalPersonPrivateProfile } from '@shared-component/registration/registration-legal-person-private/registration-legal-person-private-profile/registration-legal-person-private-profile';

import { RegistrationNavigation } from '@component/registration-navigation/registration-navigation';

import { useRegistrationLegalPersonPrivate } from './registration-legal-person-private.hook';
import { useRegistrationLegalPersonPrivateModal } from './registration-legal-person-private.modal-hook';
import {
    RegistrationLegalPrivatePersonModalEnum,
    RegistrationLegalPrivatePersonModalNavigationOptions,
} from './registration-legal-person-private.modal-options';

export const RegistrationLegalPersonPrivateModal = observer(() => {
    const [view, setView] = useRegistrationLegalPersonPrivateModal();
    const {
        status,
        personal,
        passport,
        profile,
        address,
        onUploaded,
        onSaveProfile,
        onSavePassport,
        onSavePersonal,
        onSaveAddress,
        onDeleteUpload,
    } = useRegistrationLegalPersonPrivate(setView);

    const handleChoose = (type: RegistrationLegalPrivatePersonModalEnum) => () => setView(type)();

    return (
        <RegistrationLegalPersonCommonWrapper>
            <RegistrationNavigation<RegistrationLegalPrivatePersonModalEnum>
                options={RegistrationLegalPrivatePersonModalNavigationOptions}
                name={view}
                valueEnable={status}
                valueCheck={status}
                avatarName={profile.name ?? undefined}
                onClick={handleChoose}
            >
                {view === RegistrationLegalPrivatePersonModalEnum.Role && (
                    <RegistrationLegalPersonPrivateProfile personal={personal} onSubmit={onSavePersonal} />
                )}
                {view === RegistrationLegalPrivatePersonModalEnum.Passport && (
                    <RegistrationLegalPersonPrivateDocument
                        passport={passport}
                        onUploaded={onUploaded}
                        onDeleteUpload={onDeleteUpload}
                        onSubmit={onSavePassport}
                        onBack={setView(RegistrationLegalPrivatePersonModalEnum.Role)}
                    />
                )}
                {view === RegistrationLegalPrivatePersonModalEnum.PersonalDetails && (
                    <RegistrationLegalPersonPrivatePersonal
                        profile={profile}
                        onBack={setView(RegistrationLegalPrivatePersonModalEnum.Passport)}
                        onSubmit={onSaveProfile}
                    />
                )}
                {view === RegistrationLegalPrivatePersonModalEnum.Address && (
                    <RegistrationLegalPersonPrivateAddress
                        address={address}
                        onBack={setView(RegistrationLegalPrivatePersonModalEnum.PersonalDetails)}
                        onSubmit={onSaveAddress}
                    />
                )}
            </RegistrationNavigation>
        </RegistrationLegalPersonCommonWrapper>
    );
});
