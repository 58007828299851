import { useMutation } from '@apollo/client';
import { Form, Formik } from 'formik';
import { useContext, useEffect, useMemo, useState } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { Input } from '@shared-component/input/input';
import { ModelContext } from '@shared-component/modal/modal-context';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { AdminCreateIbanqCredentials, IbanqCredentialsInput, Mutation } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { ModalWrapper } from '@component/modal/admin-modal.styles';
import { CreateIfxCredentialsFormEnum } from '@component/modal/modals/create-ifx-credentials/create-ifx-credentials.enum';
import { CreateIfxCredentialsFormInitial } from '@component/modal/modals/create-ifx-credentials/create-ifx-credentials.initial';
import { ButtonSubmitWrapper } from '@component/modal/modals/create-ifx-credentials/create-ifx-credentials.styles';
import { CreateIfxCredentialsFormValidation } from '@component/modal/modals/create-ifx-credentials/create-ifx-credentials.validation';

export const CreateIFXCredentialsModal = () => {
    const { params } = useCurrentNavigation();
    const { onOpen } = useContext(InformationModalContext);

    const { onClose, props } = useContext(ModelContext);
    const [adminCreateIbanqCredentials, { data: dataAdminCreateIbanqCredentials }] =
        useMutation<Pick<Mutation, 'adminCreateIbanqCredentials'>>(AdminCreateIbanqCredentials);
    const [ifxCredentialsFormInitial, setIfxCredentialsFormInitial] = useState(CreateIfxCredentialsFormInitial);
    const clientId = useMemo(() => (isTrue(params.userId) ? params.userId : params.companyId), [params]);

    useEffect(() => {
        setIfxCredentialsFormInitial({
            ...ifxCredentialsFormInitial,
            [CreateIfxCredentialsFormEnum.ClientId]: clientId,
        });
    }, [clientId]);

    const handleSubmit = (values: IbanqCredentialsInput) => {
        adminCreateIbanqCredentials({ variables: { credentials: values } })
            .then(({ data }) => {
                if (isTrue(data)) {
                    onClose();
                    onOpen(InformationModalEnum.SuccessInformationModal, {
                        text: 'The financial account has been assigned successfully.',
                        timeout: 20000,
                    });
                } else {
                    onOpen(InformationModalEnum.FailureInformationModal, {
                        text: 'An error occurred. The financial account was not assigned to the user.',
                    });
                }
            })
            .catch(() => {
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: 'An error occurred. The financial account was not assigned to the user.',
                });
            });
    };

    useEffect(() => {
        if (isTrue(dataAdminCreateIbanqCredentials) && isTrue(props?.componentProps?.getIbanqCredentials)) {
            props.componentProps?.getIbanqCredentials();
        }
    }, [dataAdminCreateIbanqCredentials]);

    return (
        <ModalWrapper>
            <Formik
                initialValues={ifxCredentialsFormInitial}
                validationSchema={CreateIfxCredentialsFormValidation}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
            >
                <Form>
                    <FormRow>
                        <Input name={CreateIfxCredentialsFormEnum.IbanqClientSecret} title="Ibanq Client Secret" />
                    </FormRow>
                    <FormRow>
                        <Input name={CreateIfxCredentialsFormEnum.IbanqClientId} title="Ibanq Client ID" />
                    </FormRow>
                    <FormRow>
                        <Input name={CreateIfxCredentialsFormEnum.IbanqPassword} title="Ibanq Password" />
                    </FormRow>
                    <FormRow>
                        <Input name={CreateIfxCredentialsFormEnum.IbanqUsername} title="Ibanq User Name" />
                    </FormRow>
                    <FormRow>
                        <Input name={CreateIfxCredentialsFormEnum.IbanqCallbackSecret} title="Ibanq Callback Secret" />
                    </FormRow>
                    <ButtonSubmitWrapper>
                        <ButtonPrimary type={ButtonTypeEnum.Submit}>
                            <Translate langKey={LocalizationEnum.CommonSave} />
                        </ButtonPrimary>
                    </ButtonSubmitWrapper>
                </Form>
            </Formik>
        </ModalWrapper>
    );
};
