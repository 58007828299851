import { useField } from 'formik';
import { useEffect } from 'react';

import { UserCompanyRelationType } from '@shared-graphql';
import { isExist } from '@shared-util/is-data';

import { RegistrationLegalPersonPrivateProfileFormEnum } from './registration-legal-person-private-profile-form.enum';
import { getCompanyRelationByFieldName } from './registration-legal-person-private-profile-form.utils';

export const useRegistrationLegalPersonPrivateProfileForm = (
    fieldName: RegistrationLegalPersonPrivateProfileFormEnum
): boolean => {
    const [{ value: isSelectRelation }] = useField<boolean>(fieldName);
    const [{ value: companyRelations }, , { setValue: setCompanyRelations }] = useField<UserCompanyRelationType[]>(
        RegistrationLegalPersonPrivateProfileFormEnum.UserRelation
    );

    const relation = getCompanyRelationByFieldName(fieldName);
    const hasCompanyRelation = companyRelations.includes(relation);

    useEffect(() => {
        if (isExist(isSelectRelation)) {
            if (isSelectRelation && !hasCompanyRelation) {
                setCompanyRelations([...companyRelations, relation]);
            }

            if (!isSelectRelation && hasCompanyRelation) {
                setCompanyRelations(companyRelations.filter(item => item !== relation));
            }
        }
    }, [isSelectRelation]);

    return hasCompanyRelation;
};
