import { UserType } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { isTrue } from '@shared-util/is-data';

import { CommissionProfileInfoPayment } from '@page/commission-profile/commission-profile-info/commission-profile-info-payment/commission-profile-info-payment';
import { BlockWrapper } from '@page/legal-client/legal-client-pricing/legal-client-pricing.styles';

export const LegalClientPricing = () => {
    const { params } = useCurrentNavigation();

    return (
        <BlockWrapper>
            <CommissionProfileInfoPayment
                userId={isTrue(params.userId) ? params.userId : params.companyId}
                userType={UserType.Legal}
            />
        </BlockWrapper>
    );
};
