import { values } from 'mobx';
import { Instance, types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { PaymentType } from '@shared-graphql';
import { BeneficiaryModelType } from '@shared-model/beneficiary-model/beneficiary-model.type';
import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';
import { FileModel } from '@shared-model/file-model/file-model';

import { operationTypes, PaymentFeeModelType } from '@model/payment-model/payment-model-types/payment-fee-model.type';
import { PaymentSenderModelType } from '@model/payment-model/payment-model-types/payment-sender-model.type';

import { paymentStatuses, PaymentStatusesModel } from './payment-model-types/payment-statuses-model.type';

const paymentTypes = $enum(PaymentType).getValues();

export const PaymentInstanceModelType = types.model({
    id: types.identifier,
    payerName: types.optional(types.string, ''),
    accountId: types.string,
    clientId: types.string,
    currency: CurrencyModelType,
    amount: types.number,
    total: types.number,
    type: types.enumeration(paymentTypes),
    operation: types.maybeNull(types.enumeration(operationTypes)),
    status: types.maybeNull(types.enumeration(paymentStatuses)),
    statuses: types.array(PaymentStatusesModel),
    sender: types.maybeNull(PaymentSenderModelType),
    reason: types.string,
    documents: types.maybeNull(types.array(FileModel)),
    beneficiary: types.maybeNull(BeneficiaryModelType),
    fee: PaymentFeeModelType,
    createdAt: types.Date,
    updatedAt: types.Date,
});

export const PaymentModelTypeInstance = types
    .model({
        elements: types.map(PaymentInstanceModelType),
        totalCount: types.number,
        payment: PaymentInstanceModelType,
        activeLoadedPaymentId: types.string,
    })
    .views(self => ({
        get payments() {
            return values(self.elements) as unknown as Instance<typeof PaymentInstanceModelType>[];
        },
    }));
