import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { v4 } from 'uuid';

import { Translate } from '@shared-atom/translate/translate';
import { EmptyContentWrapper } from '@shared-component/accounts-list/accounts-list.styles';
import { AnimationLoading } from '@shared-component/animation/animation-loading/animation-loading';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isFalse } from '@shared-util/is-data';

import {
    TemplateListInterface,
    useCommissionProfileInfoPaymentHook,
} from '@page/commission-profile/commission-profile-info/commission-profile-info-hook/use-commission-profile-info-payment.hook';
import {
    Wrapper,
    Title,
    TitleWrapper,
    List,
    CommissionItem,
    CommissionTitle,
    Description,
    CommissionSubTitle,
} from '@page/commission-profile/commission-profile-info/commission-profile-info.styles';
import { CommissionProfileInfoInterface } from '@page/commission-profile/commission-profile-info/commission-profile.interface';

// import { CommissionProfileInfoPaymentDescription } from './commission-profile-info-payment-description/commission-profile-info-payment-description';
import { CommissionProfileInfoPaymentText } from './commission-profile-info-payment-text/commission-profile-info-payment-text';
import { CommissionProfilePaymentItem } from './commission-profile-payment-item/commission-profile-payment-item';

export const CommissionProfileInfoPayment = observer(({ userId, userType }: CommissionProfileInfoInterface) => {
    const templateList = useCommissionProfileInfoPaymentHook({ userId, userType });

    if (isFalse(templateList))
        return (
            <EmptyContentWrapper>
                <AnimationLoading />
            </EmptyContentWrapper>
        );

    return (
        <Wrapper>
            <TitleWrapper>
                <Title>
                    <Translate langKey={LocalizationEnum.CommissionProfilePaymentTitle} />
                </Title>
            </TitleWrapper>
            <List>
                <CommissionItem>
                    <CommissionTitle hasCommission>
                        <Translate langKey={LocalizationEnum.CommissionProfilePaymentListTitle1} />
                    </CommissionTitle>
                    <Description>
                        <Translate langKey={LocalizationEnum.CommissionProfileAdministrationFree} />
                    </Description>
                </CommissionItem>
                {(templateList as TemplateListInterface[]).map(
                    ({
                        title,
                        subTitle,
                        handleEdit,
                        handleUpdate,
                        handleDelete,
                        handleClose,
                        isEditMode,
                        template,
                    }) => {
                        const hasSubTitle = subTitle !== undefined;

                        return (
                            <Fragment key={v4()}>
                                {hasSubTitle && (
                                    <CommissionItem>
                                        <CommissionSubTitle hasCommission={hasSubTitle}>
                                            <Translate langKey={subTitle ?? ''} />
                                        </CommissionSubTitle>
                                    </CommissionItem>
                                )}
                                <CommissionProfilePaymentItem title={title} onEdit={handleEdit}>
                                    {hasSubTitle && (
                                        <CommissionProfileInfoPaymentText
                                            isEditMode={isEditMode}
                                            template={template}
                                            onSubmit={handleUpdate}
                                            onDelete={handleDelete}
                                            onClose={handleClose}
                                        />
                                    )}
                                    {!hasSubTitle && (
                                        // <CommissionProfileInfoPaymentDescription
                                        //     isEditMode={isEditMode}
                                        //     template={template}
                                        //     onSubmit={handleUpdate}
                                        //     onClose={handleClose}
                                        // />
                                        <CommissionProfileInfoPaymentText
                                            isEditMode={isEditMode}
                                            template={template}
                                            onSubmit={handleUpdate}
                                            onDelete={handleDelete}
                                            onClose={handleClose}
                                        />
                                    )}
                                </CommissionProfilePaymentItem>
                            </Fragment>
                        );
                    }
                )}
            </List>
        </Wrapper>
    );
});
