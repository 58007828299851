import { useMemo, useState } from 'react';

import { Maybe } from '@shared-graphql';

export const useTypeFilter = <T>(initialType: Maybe<T> = null) => {
    const [type, setType] = useState<Maybe<T>>(initialType);

    const onTypeChange = (newType: T) => setType(newType);

    return useMemo(() => [{ type }, { onTypeChange }, {}], [type]);
};
