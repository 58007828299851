import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { DarkGrey, DodgerBlue } from '@shared-style/colors';

export const List = styled.ul`
    margin-top: 40px;
`;

export const Item = styled.li`
    margin-top: 24px;
    padding: 16px 24px 32px;
    border-top: 1px solid ${DarkGrey};
    border-bottom: 1px solid ${DarkGrey};
    border-left: 4px solid ${DodgerBlue};
    border-right: 4px solid ${DodgerBlue};
    white-space: break-spaces;
    word-break: break-all;
`;

export const DateWrapper = styled.p`
    ${Typography.SemiBold.Black.Style}
`;
