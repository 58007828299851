import { observer } from 'mobx-react-lite';
import { PropsWithChildren } from 'react';

import { Logo } from '@shared-atom/logo/logo';
import { Avatar } from '@shared-component/avatar/avatar';
import { RegistrationMenuListInterface } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.interface';
import { Scroll } from '@shared-component/scroll/scroll';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { RegistrationNavigationItem } from './registration-navigation-item/registration-navigation-item';
import {
    AvatarWrapper,
    NavigationContentWrapper,
    LogoWrapper,
    NavigationList,
    NavigationHeader,
    NavigationWrapper,
    NavigationRoot,
    NavigationChildWrapper,
} from './registration-navigation.styles';

interface RegistrationPrivateWrapperProps<T extends string, S = any, P = any> {
    options: RegistrationMenuListInterface<T, S, P>[];
    onClick: OnEventType<T, OnEventEmptyType>;
    name: T;
    avatarName?: string;
    valueCheck?: S;
    valueEnable?: P;
}

export const RegistrationNavigation = observer(
    <T extends string, S = any, P = any>({
        children,
        options,
        name,
        avatarName,
        valueEnable,
        valueCheck,
        onClick,
    }: PropsWithChildren<RegistrationPrivateWrapperProps<T, S, P>>) => {
        const renderRegistrationPrivateWrapperItem = (option: RegistrationMenuListInterface<T, S, P>) => (
            <RegistrationNavigationItem
                isDisabled={option.checkEnable !== undefined && !option.checkEnable(valueEnable)}
                key={option.chooseKey}
                isActive={name === option.chooseKey}
                icon={option.icon}
                title={option.title}
                isDone={option.checkDone(valueCheck)}
                onClick={onClick(option.chooseKey)}
            />
        );

        return (
            <NavigationRoot>
                <NavigationWrapper>
                    <NavigationHeader>
                        <LogoWrapper>
                            <Logo isWhite />
                        </LogoWrapper>
                        <AvatarWrapper>
                            <Avatar name={avatarName} />
                        </AvatarWrapper>
                    </NavigationHeader>
                    <NavigationList>
                        <Scroll>{options.map(renderRegistrationPrivateWrapperItem)}</Scroll>
                    </NavigationList>
                </NavigationWrapper>
                <NavigationContentWrapper>
                    <Scroll>
                        <NavigationChildWrapper>{children}</NavigationChildWrapper>
                    </Scroll>
                </NavigationContentWrapper>
            </NavigationRoot>
        );
    }
);
