import { useField } from 'formik';
import { useEffect } from 'react';

import { CheckBox } from '@shared-component/checkbox/checkbox';
import { InputPercent } from '@shared-component/input/input-percent/input-percent';
import { parsePercent } from '@shared-component/input/input-percent/input-percent.helper';
import { FormRow, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { RegistrationLegalPersonPrivateProfileFormEnum } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.enum';
import { useRegistrationLegalPersonPrivateProfileForm } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.hook';
import { Maybe } from '@shared-graphql';
import { isExist, isNumber, isString } from '@shared-util/is-data';

import { useRegistrationLegalPersonPrivateProfileFormBeneficiaryLocalization } from './registration-legal-person-private-profile-form-beneficiary.localization-hook';

interface LegalPersonPrivateProfileFormBeneficiaryProps {
    initialBeneficiaryPercent: Maybe<number>;
}

const initialZeroPercent = parsePercent(0);

export const RegistrationLegalPersonPrivateProfileFormBeneficiary = ({
    initialBeneficiaryPercent,
}: LegalPersonPrivateProfileFormBeneficiaryProps) => {
    const [{ value: beneficiaryPercent }, , { setValue: setBeneficiaryPercent }] = useField<string>(
        RegistrationLegalPersonPrivateProfileFormEnum.BeneficiaryPercent
    );
    const [, , { setValue: setBeneficiary }] = useField<boolean>(
        RegistrationLegalPersonPrivateProfileFormEnum.Beneficiary
    );
    const isBeneficiary = useRegistrationLegalPersonPrivateProfileForm(
        RegistrationLegalPersonPrivateProfileFormEnum.Beneficiary
    );

    const { beneficiaryTitle, beneficiaryPercentTitle } =
        useRegistrationLegalPersonPrivateProfileFormBeneficiaryLocalization();

    useEffect(() => {
        if (isBeneficiary && initialZeroPercent === beneficiaryPercent && isNumber(initialBeneficiaryPercent)) {
            setBeneficiaryPercent(parsePercent(initialBeneficiaryPercent));
        }

        if ((!isBeneficiary && isString(beneficiaryPercent)) || !isExist(beneficiaryPercent)) {
            setBeneficiaryPercent(initialZeroPercent);
        }
    }, [isBeneficiary, beneficiaryPercent, initialBeneficiaryPercent]);

    useEffect(() => {
        setBeneficiary(isBeneficiary);
    }, [isBeneficiary]);

    return (
        <FormWrapper>
            <CheckBox title={beneficiaryTitle} name={RegistrationLegalPersonPrivateProfileFormEnum.Beneficiary} />
            <FormRow>
                <InputPercent
                    title={beneficiaryPercentTitle}
                    name={RegistrationLegalPersonPrivateProfileFormEnum.BeneficiaryPercent}
                    disabled={!isBeneficiary}
                />
            </FormRow>
        </FormWrapper>
    );
};
