import { AccountBank, PaymentType } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useCopyAccountDetailsText = ({
    name,
    number,
    bic,
    bankName,
    bankAddress,
    bankCountry,
    paymentType,
    sortCode,
}: Pick<
AccountBank,
'name' | 'number' | 'bic' | 'bankName' | 'bankAddress' | 'bankCountry' | 'paymentType' | 'sortCode'
>) => {
    const accountHolderNameTitle = useLocalizationText(LocalizationEnum.AccountDetailsCardHolderName);
    const ibanTitle = useLocalizationText(LocalizationEnum.AccountDetailsCardIban);
    const bicTitle = useLocalizationText(LocalizationEnum.AccountDetailsCardBic);
    const bankNameTitle = useLocalizationText(LocalizationEnum.AccountDetailsCardBankName);
    const bankAddressTitle = useLocalizationText(LocalizationEnum.AccountDetailsCardBankAddress);
    const bankCountryTitle = useLocalizationText(LocalizationEnum.AccountDetailsCardBankCountry);
    const sepaDescription = useLocalizationText(LocalizationEnum.AccountDetailsCardCopyDescriptionSepa);
    const swiftDescription = useLocalizationText(LocalizationEnum.AccountDetailsCardCopyDescriptionSwift);
    const localDescription = useLocalizationText(LocalizationEnum.AccountDetailsCardCopyDescriptionLocal);
    const sortCodeTitle = useLocalizationText(LocalizationEnum.AccountDetailsCardSortCode);

    let description = swiftDescription;

    if (paymentType === PaymentType.local) {
        description = localDescription;
    }

    if (paymentType === PaymentType.sepa) {
        description = sepaDescription;
    }

    const sortCodeField = sortCode !== null ? `${sortCodeTitle} — ${sortCode}` : '';

    return `
${description}
${accountHolderNameTitle} — ${name}
${ibanTitle} — ${number}
${bicTitle} — ${bic}
${bankNameTitle} — ${bankName}
${bankAddressTitle} — ${bankAddress}
${bankCountryTitle} — ${bankCountry}
${sortCodeField}`;
};
