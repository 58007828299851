import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const usePrivateSelfDeclarationFormLocalization = () => {
    const politicallyPersonTitle = useLocalizationText(LocalizationEnum.PrivateSelfDeclarationPoliticallyPersonTitle);
    const pepTitle = useLocalizationText(LocalizationEnum.PrivateSelfDeclarationPepTitle);
    const tooltipDescription = useLocalizationText(LocalizationEnum.PrivateSelfDeclarationTooltipDescription);

    return {
        politicallyPersonTitle,
        pepTitle,
        tooltipDescription,
    };
};
