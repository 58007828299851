import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserCompanyRelationType, UserRolesType, UserType } from '@shared-graphql';

const companyRelationValues = $enum(UserCompanyRelationType).getValues();
const companyRoleValues = $enum(UserRolesType).getValues();
const typeValues = $enum(UserType).getValues();

export const CompanyRelationModelType = types.model('CompanyRelation', {
    companyRelation: types.array(types.enumeration(companyRelationValues)),
    companyRole: types.enumeration(companyRoleValues),
    type: types.enumeration(typeValues),
});
