import { useLazyQuery } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { useCreatedFromFilter } from '@shared-component/filters/created-from-filter/created-from-filter.hook';
import { useCreatedToFilter } from '@shared-component/filters/created-to-filter/created-to-filter.hook';
import { useElcoinStatusFilter } from '@shared-component/filters/elcoin-status-filter/elcoin-status-filter.hook';
import { useFiltersCompose } from '@shared-component/filters/filters.hook';
import { useOperationTypeFilter } from '@shared-component/filters/getting-type-filter/getting-type.hook';
import { usePaginationFilter } from '@shared-component/filters/pagination-filter/pagination-filter.hook';
// import {usePaymentTypeFilter} from "@shared-component/filters/payment-type-filter/payment-type.hook";
import { useSearchFilter } from '@shared-component/filters/search-filter/search-filter.hook';
import {
    AdminGetInternalPayments,
    AdminGetInwardPayments,
    AdminGetOutwardPayments,
    AdminGetInternalPaymentsQuery,
    AdminGetInwardPaymentsQuery,
    AdminGetOutwardPaymentsQuery,
    ElcoinPaymentStatus,
} from '@shared-graphql';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { usePrevious } from '@shared-hook/utils/use-previous';
import { isTrue } from '@shared-util/is-data';
import { isDeepEqualObject } from '@shared-util/is-object';

import { PageEnum } from '@enum/page.enum';
import {
    OperationTypeEnum,
    TransactionsTableEnum,
} from '@page/transactions/transactions-table/transactions-table.enum';
import { getFilters } from '@page/transactions/transactions-table/transactions-table.utils';

const FIRST_INDEX = 0;
const LIMIT_ROWS_PER_PAGE = 20;

export const useTransactions = () => {
    const navigate = useRouterNavigate();
    const { searchParams: params } = useCurrentNavigation();
    const {
        // status,
        // paymentType,
        elcoinStatus,
        createdFrom,
        createdTo,
        operationType,
    } = params;

    const CurrentLoadSchema: DocumentNode = useMemo(() => {
        if (operationType === OperationTypeEnum.Internal) {
            return AdminGetInternalPayments;
        }
        if (operationType === OperationTypeEnum.Outward) {
            return AdminGetOutwardPayments;
        }
        return AdminGetInwardPayments;
    }, [operationType, operationType]);

    const [loadTransactions, { data, loading: isLoading }] = useLazyQuery<
    AdminGetInternalPaymentsQuery | AdminGetOutwardPaymentsQuery | AdminGetInwardPaymentsQuery
    >(CurrentLoadSchema);

    const transactions = useMemo(() => {
        if (operationType === OperationTypeEnum.Internal) {
            return (data as AdminGetInternalPaymentsQuery)?.adminGetInternalPayments?.elements ?? [];
        }
        if (operationType === OperationTypeEnum.Outward) {
            return (data as AdminGetOutwardPaymentsQuery)?.adminGetOutwardPayments?.elements ?? [];
        }
        return (data as AdminGetInwardPaymentsQuery)?.adminGetInwardPayments?.elements ?? [];
    }, [data, operationType]);

    const totalTransactionsData = useMemo(() => {
        if (operationType === OperationTypeEnum.Internal) {
            return (data as AdminGetInternalPaymentsQuery)?.adminGetInternalPayments?.totalCount ?? 0;
        }
        if (operationType === OperationTypeEnum.Outward) {
            return (data as AdminGetOutwardPaymentsQuery)?.adminGetOutwardPayments?.totalCount ?? 0;
        }
        if (operationType === OperationTypeEnum.Inward) {
            return (data as AdminGetInwardPaymentsQuery)?.adminGetInwardPayments?.totalCount ?? 0;
        }
        return 0;
    }, [data, operationType]);

    const [
        filters,
        { onPageChange, onSearch, onElcoinStatusChange, onCreatedFromChange, onCreatedToChange, onOperationTypeChange },
        { pageSize, currentPage, isDebounce },
    ] = useFiltersCompose(
        usePaginationFilter({ total: totalTransactionsData, limit: LIMIT_ROWS_PER_PAGE }),
        useSearchFilter(),
        useElcoinStatusFilter<ElcoinPaymentStatus | string>(
            elcoinStatus === TransactionsTableEnum.All ? TransactionsTableEnum.All : elcoinStatus
        ),
        useCreatedFromFilter<Date | string>(isTrue(createdFrom) ? createdFrom : ''),
        useCreatedToFilter<Date | string>(isTrue(createdTo) ? createdTo : ''),
        useOperationTypeFilter(isTrue(operationType) ? operationType : OperationTypeEnum.Inward)
    );

    const tableRef = useRef();

    const previousFilters = usePrevious(filters);

    useEffect(() => {
        if (!isDeepEqualObject(previousFilters, filters)) {
            loadTransactions({
                variables: {
                    filters: {
                        ...getFilters(filters),
                    },
                },
            });
        }
    }, [isLoading, filters]);

    useEffect(
        () => void onPageChange(FIRST_INDEX),
        [
            filters.search,
            // filters.status,
            // filters.paymentType,
            filters.elcoinStatus,
            filters.createdTo,
            filters.country,
            filters.operationType,
        ]
    );

    const onResetFilters = useCallback(() => {
        if (
            isTrue(filters.elcoinStatus) ||
            isTrue(filters.createdFrom) ||
            isTrue(filters.createdTo) ||
            isTrue(filters.operationType)
            // || isTrue(filters.paymentType)
            // || isTrue(filters.status)
        ) {
            navigate(PageEnum.Transactions, {
                ...(isTrue(filters.elcoinStatus) && { elcoinStatus: TransactionsTableEnum.All }),
                ...(isTrue(filters.createdFrom) && { createdFrom: '' }),
                ...(isTrue(filters.createdTo) && { createdTo: '' }),
                ...(isTrue(filters.operationType) && { operationType: OperationTypeEnum.Inward }),
                // ...(isTrue(filters.paymentType) && {paymentType: TransactionsTableEnum.All}),
                // ...(isTrue(filters.status) && {status: TransactionsTableEnum.All}),
            });
        }
        if (isTrue(filters.search)) {
            onSearch('');
        }
    }, [
        // filters.status,
        // filters.paymentType,
        filters.elcoinStatus,
        filters.search,
        filters.createdTo,
        filters.country,
        filters.operationType,
    ]);

    return {
        filters,
        transactions,
        isLoading: isLoading || (pageSize === 1 && totalTransactionsData > LIMIT_ROWS_PER_PAGE) || isDebounce,
        pageSize,
        currentPage,
        onPageChange,
        onSearch,
        // onStatusChange,
        // onPaymentTypeChange,
        onElcoinStatusChange,
        onCreatedFromChange,
        onCreatedToChange,
        tableRef,
        onResetFilters,
        totalTransactionsCount: totalTransactionsData,
        limitRowsPerPage: LIMIT_ROWS_PER_PAGE,
        onOperationTypeChange,
    };
};
