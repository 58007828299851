import { FC, ReactNode, Fragment } from 'react';
import { TableBodyProps } from 'react-table';
import { v4 } from 'uuid';

import { RowWrapperInterface } from '@shared-component/table/table.interface';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { TableBodyStyle } from './table-body.styles';
import { TableRowComponent, TableRowInterface } from './table-row/table-row';

interface TableBodyInterface<D extends Record<string, unknown>> {
    rows: TableRowInterface<D>[];
    rowWrapper?: FC<RowWrapperInterface<D>>;
    toggleableRow?: OnEventType<TableRowInterface<D>, ReactNode>;
    children: OnEventType<TableRowInterface<D>> | FC | ReactNode;
    prepareRow: OnEventType<TableRowInterface<D>>;
    getTableBodyProps: OnEventEmptyType<TableBodyProps>;
    toggleAllRowsExpanded: OnEventType<boolean | undefined>;
}

export const TableBody = <D extends Record<string, unknown>>({
    getTableBodyProps,
    rows,
    rowWrapper,
    prepareRow,
    toggleableRow,
    toggleAllRowsExpanded,
    children,
}: TableBodyInterface<D>) => {
    const RowWrapper = rowWrapper ?? Fragment;
    return (
        <TableBodyStyle {...getTableBodyProps()}>
            {rows.map(row => {
                prepareRow(row);
                const rowWrapperProps =
                    rowWrapper !== undefined ? { data: row.original, isExpanded: row.isExpanded } : {};

                return (
                    <RowWrapper key={v4()} {...rowWrapperProps}>
                        <TableRowComponent
                            row={row}
                            toggleableRow={toggleableRow}
                            toggleAllRowsExpanded={toggleAllRowsExpanded}
                        >
                            {typeof children === 'function' ? children(row) : children}
                        </TableRowComponent>
                    </RowWrapper>
                );
            })}
        </TableBodyStyle>
    );
};
