import { CompanyServiceInput } from '@shared-graphql';

import { LegalServiceFormEnum } from './registration-legal-service-form.enum';

export const LegalServiceFormInitial: CompanyServiceInput = {
    [LegalServiceFormEnum.AnnualGross]: null,
    [LegalServiceFormEnum.CompanyTradingName]: null,
    [LegalServiceFormEnum.CrimeRelation]: null,
    [LegalServiceFormEnum.Currency]: '',
    [LegalServiceFormEnum.DebtsRelation]: null,
    [LegalServiceFormEnum.ExternalBankAccount]: null,
    [LegalServiceFormEnum.ID]: null,
    [LegalServiceFormEnum.IsAccountInAnotherBank]: false,
    [LegalServiceFormEnum.IsAnotherUboExists]: null,
    [LegalServiceFormEnum.IsEuroAccount]: false,
    [LegalServiceFormEnum.IsLicenceAvailable]: null,
    [LegalServiceFormEnum.IsMulticurrencyAccount]: false,
    [LegalServiceFormEnum.IsParentCompanyExists]: null,
    [LegalServiceFormEnum.IsRepresentativeExists]: null,
    [LegalServiceFormEnum.IsSeparateDivisionsExists]: null,
    [LegalServiceFormEnum.IsSubmitFinancialTaxReport]: false,
    [LegalServiceFormEnum.IsSwiftAccount]: false,
    [LegalServiceFormEnum.MoneyTurnover]: '0',
    [LegalServiceFormEnum.MonthlySpendPlan]: null,
    [LegalServiceFormEnum.NumberOfCustomers]: null,
    [LegalServiceFormEnum.NumberOfPersons]: null,
    [LegalServiceFormEnum.OperationCountry]: null,
    [LegalServiceFormEnum.ParentCompanyInfo]: null,
    [LegalServiceFormEnum.PreferredAccountCurrency]: null,
    [LegalServiceFormEnum.ProsecutionRelation]: null,
    [LegalServiceFormEnum.SeparateDivisionsInfo]: null,
    [LegalServiceFormEnum.SpendPlan]: null,
    [LegalServiceFormEnum.VoluntarilyDisclosedCrime]: null,
};
