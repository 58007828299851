import { FC } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { Otp, OtpProps } from '@shared-component/otp/otp';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import {
    OtpStepPhone,
    OtpStepPhoneWrapper,
    OtpWrapper,
    OtpStepTitle,
    OtpStepDescription,
} from './otp-login-step.styles';

interface OtpLoginStepProps extends OtpProps {
    phone: string;
}

export const OtpLoginStep: FC<OtpLoginStepProps> = ({ phone, error, onSubmit, onResendOtpCode }) => (
    <>
        <OtpStepTitle>
            <Translate langKey={LocalizationEnum.LoginOtpStepTitle} />
        </OtpStepTitle>
        <OtpStepDescription>
            <Translate langKey={LocalizationEnum.LoginOtpStepDescription} />
            <OtpStepPhoneWrapper>
                <OtpStepPhone>{phone}</OtpStepPhone>
            </OtpStepPhoneWrapper>
        </OtpStepDescription>
        <OtpWrapper>
            <Otp error={error} onSubmit={onSubmit} onResendOtpCode={onResendOtpCode} />
        </OtpWrapper>
    </>
);
