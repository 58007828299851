import { useMemo, useState } from 'react';

import { Maybe } from '@shared-graphql';

export const useCreatedToFilter = <T>(initialType: Maybe<T> = null) => {
    const [createdTo, setCreatedTo] = useState<Maybe<T>>(initialType);

    const onCreatedToChange = (newCreatedTo: T) => setCreatedTo(newCreatedTo);

    return useMemo(() => [{ createdTo }, { onCreatedToChange }, {}], [createdTo]);
};
