import { useContext, useEffect, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal-context';
import { MutationAdminRejectPaymentArgs } from '@shared-graphql';

import { RejectPaymentFormInitial } from '@component/modal/modals/reject-payment/reject-payment-form/reject-payment-form.initial';
import { usePaymentActionsSelector } from '@selector/payment/use-payment.actions-selector';

export const useRejectPaymentForm = () => {
    const { onOpen } = useContext(InformationModalContext);
    const { onClose, props } = useContext(ModelContext);
    const { rejectPayment } = usePaymentActionsSelector();
    const [initialValues, setInitialValues] = useState(RejectPaymentFormInitial);

    useEffect(() => {
        setInitialValues({
            ...RejectPaymentFormInitial,
            paymentId: props.componentProps?.paymentId,
        });
    }, [props.componentProps?.paymentId]);

    const handleSubmit = ({ paymentId, message }: MutationAdminRejectPaymentArgs) => {
        rejectPayment(paymentId, message)
            .then(() => onClose())
            .catch(error => {
                onClose();
                onOpen(InformationModalEnum.FailureInformationModal, { text: error.message });
            });
    };

    return { initialValues, handleSubmit };
};
