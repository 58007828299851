import { useMemo } from 'react';

import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { findMatchingOption } from '@shared-util/get-pathname-with-params';

import { OptionsInterface } from '@component/tabs/tabs.interface';

interface CurrentTabNameProps {
    options: OptionsInterface[];
}

export const CurrentTabName = ({ options }: CurrentTabNameProps) => {
    const { name } = useCurrentNavigation();

    const currentLink = useMemo(() => findMatchingOption(name, options), [options, name]) as OptionsInterface;

    return (
        <>
            {Boolean(currentLink?.title) === true && currentLink?.title}
            {Boolean(currentLink?.title) === false && null}
        </>
    );
};
