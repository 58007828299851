import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { Scroll } from '@shared-component/scroll/scroll';
import { noop } from '@shared-util/noop';

import { initialCommissionClient } from '@page/commission-profile/commission-profile-initials/commission-profile.initials';
import { useStore } from '@store/store-context';

import { CommissionProfileHeader } from './commission-profile-header/commission-profile-header';
import { CommissionProfileInfoAdministration } from './commission-profile-info/commission-profile-info-administration/commission-profile-info-administration';
// import { CommissionProfileInfoCustomer } from './commission-profile-info/commission-profile-info-customer/commission-profile-info-customer';
// import { CommissionProfileInfoFee } from './commission-profile-info/commission-profile-info-fee/commission-profile-info-fee';
import { CommissionProfileInfoPayment } from './commission-profile-info/commission-profile-info-payment/commission-profile-info-payment';
import { InfoWrapper, Wrapper } from './commission.profile.styles';

export const CommissionProfilePage = observer(() => {
    const {
        page: {
            commissionProfilePage: { userType },
        },
    } = useStore();

    return (
        <Wrapper>
            <Formik initialValues={initialCommissionClient} onSubmit={noop}>
                <>
                    <CommissionProfileHeader />

                    <InfoWrapper>
                        {userType !== null ? (
                            <Scroll>
                                <CommissionProfileInfoAdministration currentUserType={userType} />
                                <CommissionProfileInfoPayment />
                                {/* <CommissionProfileInfoFee /> */}
                                {/* <CommissionProfileInfoCustomer /> */}
                            </Scroll>
                        ) : null}
                    </InfoWrapper>
                </>
            </Formik>
        </Wrapper>
    );
});
