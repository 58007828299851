import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { Choose } from '@shared-atom/choose/choose';
import { Translate } from '@shared-atom/translate/translate';
import { PersonsWrapper } from '@shared-component/registration/common.styles';
import { RegistrationLegalPersonsListItem } from '@shared-component/registration/components/registration-legal-persons-list-item/registration-legal-persons-list-item';
import { UserType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { CompanyPersonRelationType } from '@shared-model/company-model/company-persons-model/company-persons-model.type';
import { OnEventsType } from '@shared-type/on-event.type';

import Private from '@page/legal-client-profile/assets/private.svg?react';

interface RegistrationLegalPersonsChoosePersonInterface {
    relationPersons: Instance<typeof CompanyPersonRelationType>[];
    onChooseType: OnEventsType<[UserType, string | undefined]>;
}

export const RegistrationLegalPersonsList: FC<RegistrationLegalPersonsChoosePersonInterface> = observer(
    ({ relationPersons, onChooseType }) => {
        const handleChoosePerson = (type: UserType, id?: string) => () => onChooseType(type, id);

        const handleDeletePerson = (id: string) => () => console.log(id);

        return (
            <PersonsWrapper>
                <Choose icon={Private} onClick={handleChoosePerson(UserType.Private)}>
                    <Translate langKey={LocalizationEnum.RegistrationLegalPersonsDirectShareholdersPrivate} />
                </Choose>
                {relationPersons.map(person => (
                    <RegistrationLegalPersonsListItem
                        key={person.id}
                        person={person}
                        onClick={handleChoosePerson(person.type, person.id)}
                        onDelete={handleDeletePerson(person.id)}
                    />
                ))}
            </PersonsWrapper>
        );
    }
);
