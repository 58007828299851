import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { LabelWrapper, InputStyle } from '@shared-component/input/input.styles';

export const TextAreaWrapper = styled.div`
    ${LabelWrapper} {
        min-height: 152px;
    }

    ${InputStyle} {
        resize: none;
    }
`;

export const LabelMaxLength = styled.p`
    padding: 8px 16px;
    margin: 0;
    ${Typography.XS.Normal.Raven.Style}
`;
