import { CommonObjectType } from '@shared-type/common.type';

import { PageEnum } from '@enum/page.enum';

export const getPathnameWithParams = (pathName: PageEnum, options: CommonObjectType) =>
    pathName.replace(/:([a-zA-Z0-9_]+)/g, (_, key) => {
        if (options[key] !== undefined) {
            return options[key];
        }
        throw new Error(`Parameter '${key}' is not defined`);
    });

const matchTemplate = (template: string, optionName: string): boolean => {
    const templateParts = template.split('/');
    const optionParts = optionName.split('/');

    if (templateParts.length !== optionParts.length) {
        return false;
    }

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < templateParts.length; i++) {
        if (optionParts[i].startsWith(':')) {
            // eslint-disable-next-line no-continue
            continue;
        }
        if (templateParts[i] !== optionParts[i]) {
            return false;
        }
    }
    return true;
};

export const findMatchingOption = (
    template: string,
    options: Array<{ title: string; name: string | string[] }>
): { title: string; name: string | string[] } | undefined => {
    // eslint-disable-next-line no-restricted-syntax
    for (const option of options) {
        if (Array.isArray(option.name)) {
            // eslint-disable-next-line no-restricted-syntax
            for (const name of option.name) {
                if (matchTemplate(template, name)) {
                    return option;
                }
            }
        } else if (matchTemplate(template, option.name)) {
            return option;
        }
    }
    return undefined;
};
