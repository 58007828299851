import { $enum } from 'ts-enum-util';

import { BaseOptionInterface } from '@shared-component/select/select.props';
import { ElcoinPaymentStatus, PaymentStatus, PaymentType } from '@shared-graphql';

import { TransactionsTableEnum } from '@page/transactions/transactions-table/transactions-table.enum';

const paymentStatusOptions = $enum(PaymentStatus).map(value => ({
    value,
    label: value,
}));

const elcoinStatusOptions = $enum(ElcoinPaymentStatus).map(value => ({
    value,
    label: value,
}));

const paymentTypeOptions = $enum(PaymentType).map(value => ({
    value,
    label: value,
}));

export const transactionStatusOptions = [
    { value: null, label: TransactionsTableEnum.All },
    ...paymentStatusOptions,
] as BaseOptionInterface[];

export const transactionElcoinStatusOptions = [
    { value: null, label: TransactionsTableEnum.All },
    ...elcoinStatusOptions,
] as BaseOptionInterface[];

export const transactionTypeOptions = [
    { value: null, label: TransactionsTableEnum.All },
    ...paymentTypeOptions,
] as BaseOptionInterface[];
