import { Fragment, ReactNode, useRef } from 'react';
import { CellProps, Row, UseExpandedRowProps } from 'react-table';
import { v4 } from 'uuid';

import { Cell } from '@shared-component/table/table-cell/table-cell';
import { cloneWithProps } from '@shared-component/table/table.helpers';
import { useBoundingSize } from '@shared-hook/utils/use-bounding-size.hook';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

import { TableCellWrapper, TableRow, TableRowWrapper } from './table-row.styles';

interface TableCellInterface<D extends Record<string, unknown>> extends Omit<CellProps<D>, 'row'> {
    row: TableRowInterface<D>;
}

export interface TableRowInterface<D extends Record<string, unknown>>
    extends Omit<Row<D>, 'cells'>,
    UseExpandedRowProps<D> {
    cells: TableCellInterface<D>[];
}

interface TableRowComponentProps<D extends Record<string, unknown>> {
    row: TableRowInterface<D>;
    toggleableRow?: OnEventType<TableRowInterface<D>, ReactNode>;
    children?: any;
    toggleAllRowsExpanded: OnEventType<boolean | undefined>;
}

export const TableRowComponent = <D extends Record<string, unknown>>({
    row,
    toggleableRow,
    toggleAllRowsExpanded,
    children,
}: TableRowComponentProps<D>) => {
    const cellWrapperRef = useRef<HTMLTableDataCellElement>(null);
    const cellWrapperHeight = useBoundingSize(cellWrapperRef.current)?.height ?? 0;

    return (
        <>
            <TableRow {...row.getRowProps()}>
                {row.cells.map((cell, index) => (
                    <Fragment key={v4()}>
                        {isExist(children) ? (
                            cloneWithProps(children[index], { ...cell, toggleAllRowsExpanded })
                        ) : (
                            <Cell {...cell} />
                        )}
                    </Fragment>
                ))}
            </TableRow>
            {toggleableRow !== undefined && (
                <TableRowWrapper isVisible={row.isExpanded} height={row.isExpanded ? cellWrapperHeight : 0}>
                    <TableCellWrapper ref={cellWrapperRef}>{toggleableRow({ ...row })}</TableCellWrapper>
                </TableRowWrapper>
            )}
        </>
    );
};
