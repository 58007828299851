import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useMemo } from 'react';

import { CreateClientForm as ClientForm } from '@shared-form/create-client-form/create-client-form';
import { CreateClientFormEnum } from '@shared-form/create-client-form/create-client-form.enum';
import { CreateClientFormInterface } from '@shared-form/create-client-form/create-client-form.interface';
import { UserType } from '@shared-graphql';
import { isString } from '@shared-util/is-data';

import { useUserSelector } from '@selector/user/use-user.selector';
import { useUsersActionsSelector } from '@selector/user/use-users.actions-selector';

interface CreateClientFormProps {
    emailError?: string;
}

const MAX_USER_ELEMENTS = 300;

export const CreateClientForm: FC<CreateClientFormProps> = observer(({ emailError }) => {
    const [{ usersSelect }] = useUserSelector();
    const { loadUsers } = useUsersActionsSelector();

    const { values, setErrors } = useFormikContext();

    const isLegalType = (values as CreateClientFormInterface).type === UserType.Legal;
    const shouldLoadUsers = usersSelect.length === 0 && isLegalType;

    const newUsersSelect = useMemo(() => usersSelect.slice(0, MAX_USER_ELEMENTS), [usersSelect]);

    useEffect(
        () => void (isString(emailError) && setErrors({ [CreateClientFormEnum.Email]: emailError })),
        [emailError]
    );

    useEffect(() => void (shouldLoadUsers && loadUsers()), [isLegalType]);

    return <ClientForm users={newUsersSelect} />;
});
