import { LocalizationEnum } from '@shared-locale/localization.enum';

export enum LangKeysEnum {
    UK = 'uk',
    EN = 'en',
    // RU = 'ru',
}

export type LocalizationType = {
    [key in LocalizationEnum]: string;
};
