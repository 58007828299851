import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { Input, InputProps } from '@shared-component/input/input';

import { parsePercent } from './input-percent.helper';

export type InputPercentType = Pick<InputProps, 'title' | 'name' | 'disabled'>;

export const InputPercent = observer(({ title, disabled = false, name = 'percent' }: InputPercentType) => {
    const [field, , { setValue }] = useField(name);

    const handleParse = (isFocus: boolean) => {
        if (!isFocus) {
            setTimeout(() => {
                setValue(parsePercent(field.value));
            }, 0);
        }
    };

    useEffect(() => {
        setValue(parsePercent(field.value));
    }, []);

    return <Input title={title} name={name} onFocus={handleParse} disabled={disabled} />;
});
