import { useEffect, useMemo, useState } from 'react';

export const usePaginationFilter = ({ limit = 10, total = 0, page = 0 } = {}) => {
    const [limitValue, onLimitChange] = useState(limit);
    const [totalCount, onTotalChange] = useState(total);
    const [currentPage, onPageChange] = useState(page);

    const pageSize = useMemo(() => Math.max(Math.ceil(totalCount * (1 / limitValue)), 1), [limitValue, totalCount]);
    const offset = useMemo(() => Math.max(limitValue * currentPage, 0), [currentPage, limitValue]);

    useEffect(() => {
        onLimitChange(limit);
    }, [limit]);
    useEffect(() => {
        onTotalChange(total);
    }, [total]);
    useEffect(() => {
        onPageChange(page);
    }, [page]);

    return useMemo(
        () => [
            {
                limit: limitValue,
                offset,
            },
            {
                onLimitChange,
                onPageChange,
                onTotalChange,
            },
            {
                total: totalCount,
                pageSize,
                currentPage,
            },
        ],
        [limitValue, offset, totalCount, pageSize, currentPage]
    );
};
