import { Form, Formik } from 'formik';
import { FC } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { InputPercent } from '@shared-component/input/input-percent/input-percent';
import {
    RegistrationLegalDescription,
    RegistrationLegalPersonTitle,
} from '@shared-component/registration/common.styles';
import { FormButtonWrapper, FormRow, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { LegalCompanyForm } from '@shared-form/legal-company-form/legal-company-form';
import { LegalCompanyFormEnum } from '@shared-form/legal-company-form/legal-company-form.enum';
import { LegalCompanyPersonFormValidation } from '@shared-form/legal-company-form/legal-company-form.validation';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';

import { LegalCompanyInterface } from './registration-legal-person-legal-company.interface';

interface RegistrationLegalPersonLegalCompanyProps {
    initialData: LegalCompanyInterface;
    onSubmit: OnEventType<LegalCompanyInterface>;
}

export const RegistrationLegalPersonLegalCompany: FC<RegistrationLegalPersonLegalCompanyProps> = ({
    initialData,
    onSubmit,
}) => (
    <>
        <RegistrationLegalPersonTitle>
            <Translate langKey={LocalizationEnum.RegistrationLegalCompanyPageTitle} />
        </RegistrationLegalPersonTitle>
        <RegistrationLegalDescription>
            <Translate langKey={LocalizationEnum.RegistrationLegalShareholdersCompanyDescription} />
        </RegistrationLegalDescription>
        <Formik
            initialValues={initialData}
            validationSchema={LegalCompanyPersonFormValidation}
            validateOnChange={false}
            onSubmit={onSubmit}
            enableReinitialize
        >
            <Form>
                <FormWrapper>
                    <InputPercent title="Percent" name={LegalCompanyFormEnum.OwnershipPercent} />
                    <FormRow />
                    <LegalCompanyForm />
                </FormWrapper>
                <FormButtonWrapper>
                    <ButtonPrimary isLarge type={ButtonTypeEnum.Submit}>
                        <Translate langKey={LocalizationEnum.RegistrationLegalCompanyButtonTitle} />
                    </ButtonPrimary>
                </FormButtonWrapper>
            </Form>
        </Formik>
    </>
);
