import { FC } from 'react';

import { BackButton } from '@shared-atom/back-button/back-button';
import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { ClickEventType } from '@shared-type/click-event.type';
import { OnEventEmptyType } from '@shared-type/on-event.type';

interface LegalClientProfileFormsButtonProps {
    onBack: ClickEventType | null;
    isSubmitDisabled?: boolean;
}

export const LegalClientProfileFormsButton: FC<LegalClientProfileFormsButtonProps> = ({
    onBack,
    isSubmitDisabled = false,
}) => (
    <BackButton onClick={onBack as OnEventEmptyType}>
        <ButtonPrimary type={ButtonTypeEnum.Submit} isDisabled={isSubmitDisabled}>
            <Translate langKey={LocalizationEnum.PrivateFormButtonContinueTitle} />
        </ButtonPrimary>
    </BackButton>
);
