import { useLazyQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';

import { File as FileUploadType, GetUploadPassportData, Passport, Query, UserDocument } from '@shared-graphql';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

export const useRegistrationLegalPersonPrivateDocument = (
    onUploaded: OnEventType<FileUploadType>,
    onNext: OnEventType<Passport>,
    initialFile?: Pick<UserDocument, 'type' | 'file'>
): [OnEventType<FileUploadType>, OnEventEmptyType, boolean] => {
    const fileRef = useRef<FileUploadType | null>(initialFile?.file ?? null);
    const [loadPassportData, { called: isCalled, data, loading: isLoading }] = useLazyQuery<
    Pick<Query, 'getUploadPassportData'>
    >(GetUploadPassportData, {
        variables: { fileId: fileRef.current?.key },
    });

    const handlePassportUploaded = (file: FileUploadType) => {
        onUploaded(file);
        fileRef.current = file;
    };

    const handleSavePersonalDocument = () => loadPassportData();

    useEffect(
        () => void (isCalled && !isLoading && onNext((data?.getUploadPassportData ?? {}) as Passport)),
        [isCalled, isLoading]
    );

    return [handlePassportUploaded, handleSavePersonalDocument, isLoading];
};
