import { observer } from 'mobx-react-lite';

import { TableComponent } from '@shared-component/table/table';
import { Cell } from '@shared-component/table/table-cell/table-cell';
import { TableWrapper } from '@shared-component/table/table.styles';

import { ClientsHeader } from '@page/clients/clients-header/clients-header';
import { ActionsCell } from '@page/clients/clients-table/cells/actions-cell';
import { NationalityCell } from '@page/clients/clients-table/cells/nationality-cell';
import { StatusCell } from '@page/clients/clients-table/cells/status-cell';
import { UserCell } from '@page/clients/clients-table/cells/user-cell';
import { ClientsTableFilter } from '@page/clients/clients-table/clients-table-filter/clients-table-filter';
import { ClientsTableColumns } from '@page/clients/clients-table/clients-table.columns';
import { useClientsTable } from '@page/clients/clients-table/clients-table.hook';

export const ClientsTable = observer(() => {
    const {
        clients,
        totalClientsCount,
        limitRowsPerPage,
        isLoading,
        onPageChange,
        onSearch,
        pageSize,
        onStatusChange,
        onTypeChange,
        onCreatedFromChange,
        onCreatedToChange,
        onCountryChange,
        filters,
        currentPage,
        tableRef,
        onResetFilters,
    } = useClientsTable();

    return (
        <>
            <ClientsHeader onSearchChange={onSearch} filters={filters} />
            <ClientsTableFilter
                clientsCount={clients.length}
                filters={filters}
                onResetFilters={onResetFilters}
                onStatusChange={onStatusChange}
                onTypeChange={onTypeChange}
                onCreatedFromChange={onCreatedFromChange}
                onCreatedToChange={onCreatedToChange}
                onCountryChange={onCountryChange}
            />
            <TableWrapper>
                <TableComponent
                    data={clients}
                    totalData={totalClientsCount}
                    limitRowsPerPage={limitRowsPerPage}
                    columns={ClientsTableColumns}
                    isDataLoading={isLoading}
                    pageCount={pageSize}
                    pageIndex={currentPage}
                    onPageChange={onPageChange}
                    tableRef={tableRef}
                    hasPagination
                    hasTableHead
                    hasInnerScroll
                >
                    <Cell>{UserCell}</Cell>
                    <Cell>{NationalityCell}</Cell>
                    <Cell />
                    <Cell>{StatusCell}</Cell>
                    <Cell>{ActionsCell}</Cell>
                </TableComponent>
            </TableWrapper>
        </>
    );
});
