import { rgba } from 'polished';
import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { getInputStyles, LabelWrapper } from '@shared-component/input/input.styles';
import { PrimeBlue, Raven, White, BondiBlue, Polar, Fiord, Geyser } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { resetInputStyle } from '@shared-style/reset-style';

export const DayPickerLabelWrapper = styled(LabelWrapper)`
    overflow: initial;
`;
export const DayPickerInputWrapper = styled.div`
    ${Flex.Column.Style};
`;

export const DayPickerRangeWrapper = styled.div`
    display: inline-grid;
    grid-template-columns: 170px 16px 170px;
    grid-gap: 8px;

    .DayPickerInput {
        width: 100%;

        input {
            ${getInputStyles}
            ${resetInputStyle}
        }

        &-Overlay {
            box-shadow: 0 0 10px ${rgba(Fiord, 0.12)};
            border-radius: 4px;
        }
    }

    .DayPicker {
        &-Caption {
            & > div {
                ${Typography.M.Normal.Raven.Style}
            }
        }

        &-NavButton {
            margin-top: 0;
            right: 1rem;

            &--prev,
            &--next {
                background-image: none;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    left: 3px;
                    width: 10px;
                    height: 2px;
                    border-radius: 1px;
                    background-color: ${BondiBlue};
                }

                &::before {
                    top: 6px;
                }

                &::after {
                    top: 12px;
                }
            }

            &--prev {
                &::before {
                    transform: rotate(-45deg);
                }

                &::after {
                    transform: rotate(45deg);
                }
            }

            &--next {
                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }

        &-Month {
            border-collapse: separate;
            border-spacing: 0 4px;
        }

        &-Weekday {
            ${Typography.S.Medium.Raven.Style}
        }

        &-Day {
            padding: 3px 0 !important;
            min-width: 32px;
            border-radius: 10px;
            color: ${Raven};
            ${Typography.S.Normal.Style}

            &--disabled {
                color: ${Geyser};
            }

            &--today {
                ${Typography.SemiBold.Style}
            }

            &--sunday {
                background-color: transparent;
            }

            &--selected {
                &:not(.DayPicker-Day--outside) {
                    background-color: ${PrimeBlue} !important;
                    color: ${White} !important;
                }

                &:not(
                        .DayPicker-Day--start,
                        .DayPicker-Day--end,
                        .DayPicker-Day--outside,
                        .DayPicker-Day--monday,
                        .DayPicker-Day--sunday
                    ) {
                    border-radius: 0;
                }

                &:not(.DayPicker-Day--start, .DayPicker-Day--end) {
                    &.DayPicker-Day--monday {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    &.DayPicker-Day--sunday {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                }

                &:not(.DayPicker-Day--start, .DayPicker-Day--end, .DayPicker-Day--outside) {
                    background-color: ${Polar} !important;
                    color: ${BondiBlue} !important;
                }
            }
        }
    }
    @media (max-width: 560px) {
        grid-template-columns: 110px 16px 110px;
    }
`;

export const Dash = styled.span`
    ${Flex.HorizontalCenter.VerticalCenter.Style}
`;
