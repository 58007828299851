import { flow, applySnapshot, getParent, getSnapshot, Instance } from 'mobx-state-tree';

import { AdminGetPrivateClientDeclaration, Query, User } from '@shared-graphql';
import { isExist } from '@shared-util/is-data';

import { apolloClient } from '@connection/apollo-client';
import { PrivateClientModel } from '@model/private-client-model/private-client-model';
import { PrivateClientTypes } from '@model/private-client-model/private-client-model.type';

const queryPrivateClientDeclaration = (userId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetPrivateClient'>>({ query: AdminGetPrivateClientDeclaration, variables: { userId } })
        .then(result => (result.data.adminGetPrivateClient as User).declaration);

export const privateClientDeclarationActions = (self: Instance<typeof PrivateClientTypes>) => ({
    loadPrivateClientDeclaration: flow(function* loadPrivateClientDeclaration(userId: string) {
        const clientModel = getParent(self) as Instance<typeof PrivateClientModel>;

        if (clientModel.loading.isLoading) {
            return;
        }

        clientModel.setLoading(true);

        try {
            const declaration = yield queryPrivateClientDeclaration(userId);

            const currentSnapshot = getSnapshot(self);

            applySnapshot(self, {
                ...currentSnapshot,
                declaration: isExist(declaration) ? declaration : null,
            });
        } catch (e) {
            throw new Error(e as string);
        } finally {
            clientModel.setLoading(false);
        }
    }),
});
