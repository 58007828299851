import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { RegistrationLegalDocumentsCompany } from './registration-legal-documents-company/registration-legal-documents-company';
import { RegistrationLegalDocumentsPerson } from './registration-legal-documents-person/registration-legal-documents-person';
import { RegistrationLegalDocumentsEnum } from './registration-legal-documents.enum';

const PERSON_DOCUMENTS_PAGE = [
    RegistrationLegalDocumentsEnum.Ownership,
    RegistrationLegalDocumentsEnum.Beneficiary,
    RegistrationLegalDocumentsEnum.Director,
    RegistrationLegalDocumentsEnum.UserCreated,
];

export const LegalClientProfileDocumentsPage = observer(() => {
    const [selectedType, setSelectedType] = useState<RegistrationLegalDocumentsEnum | null>(null);

    // const hasSelectedType = selectedType !== null;

    const handleChoosePerson = (type: RegistrationLegalDocumentsEnum | null) => () => setSelectedType(type);

    const isPersonPage = PERSON_DOCUMENTS_PAGE.includes(selectedType as RegistrationLegalDocumentsEnum);

    return (
        <>
            {/* {!hasSelectedType && <RegistrationLegalDocumentsChoose onChoosePerson={handleChoosePerson} />} */}
            {!isPersonPage && <RegistrationLegalDocumentsCompany />}
            {isPersonPage && (
                <RegistrationLegalDocumentsPerson
                    relation={selectedType as RegistrationLegalDocumentsEnum}
                    onBack={handleChoosePerson(null)}
                />
            )}
        </>
    );
});
