import { PropsWithChildren } from 'react';

import { OnEventEmptyType } from '@shared-type/on-event.type';

import { ItemLink, LinkDisable } from './link.styles';

export interface LinkProps {
    to: { pathname: string; params?: any };
    isDisable?: boolean;
    isSecondary?: boolean;
    hasLinkBehaviour?: boolean;
    onClick?: OnEventEmptyType;
}

export const Link = ({
    to,
    children,
    isDisable = false,
    isSecondary = false,
    hasLinkBehaviour = true,
    ...props
}: PropsWithChildren<LinkProps>) => (
    <>
        {isDisable && (
            <LinkDisable isSecondary={isSecondary} {...props}>
                {children}
            </LinkDisable>
        )}
        {!isDisable && (
            <ItemLink to={to} hasLinkBehaviour={hasLinkBehaviour} isSecondary={isSecondary} {...props}>
                {children}
            </ItemLink>
        )}
    </>
);
