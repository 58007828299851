import { useMemo } from 'react';

import { CurrenciesEnum } from '@shared-enum/currencies.enum';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { LangKeysEnum } from '@shared-store/store-localization.interface';
import { getCurrencyFormat } from '@shared-util/get-currency-format';
import { isExist, isString } from '@shared-util/is-data';

const hasCurrency = (currency?: string): boolean => isExist(currency) && isString(currency);

export const useCurrencyFormat = (amount: number, currencyCode?: string, withSymbol = false) => {
    const { lang: langFromStore } = useLocalizationStore();

    const lang = useMemo(() => (withSymbol ? LangKeysEnum.EN : langFromStore), [withSymbol, langFromStore]);

    const currency = hasCurrency(currencyCode) ? currencyCode : CurrenciesEnum.EUR;

    return useMemo(() => getCurrencyFormat(amount, currency, lang), [amount, currency, lang]);
};
