import { Form, Formik } from 'formik';

import { BackButton } from '@shared-atom/back-button/back-button';
import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import {
    RegistrationLegalDescription,
    RegistrationLegalPersonTitle,
} from '@shared-component/registration/common.styles';
import { FormWrapper } from '@shared-form/form-common/form-common.styles';
import { PrivateProfileForm } from '@shared-form/private-profile-form/private-profile-form';
import { PrivateProfileFormValidation } from '@shared-form/private-profile-form/private-profile-form.validation';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { InitialUserProfileInterface } from '@shared-model/user-model/user-model-private/user-profile/user-profile.initial';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

interface RegistrationLegalPersonPrivateProfileProps {
    profile: any;
    onSubmit: OnEventType<InitialUserProfileInterface>;
    onBack: OnEventEmptyType;
}

export const RegistrationLegalPersonPrivatePersonal = ({
    profile,
    onSubmit,
    onBack,
}: RegistrationLegalPersonPrivateProfileProps) => (
    <>
        <RegistrationLegalPersonTitle>
            <Translate langKey={LocalizationEnum.RegistrationLegalPersonsPrivateProfileTitle} />
        </RegistrationLegalPersonTitle>
        <RegistrationLegalDescription>
            <Translate langKey={LocalizationEnum.RegistrationLegalPersonsPrivateProfileDescription} />
        </RegistrationLegalDescription>
        <Formik
            initialValues={profile}
            onSubmit={onSubmit}
            validationSchema={PrivateProfileFormValidation}
            validateOnBlur={false}
            validateOnChange={false}
        >
            <Form>
                <FormWrapper>
                    <PrivateProfileForm />
                </FormWrapper>
                <BackButton onClick={onBack}>
                    <ButtonPrimary isLarge type={ButtonTypeEnum.Submit}>
                        <Translate langKey={LocalizationEnum.RegistrationLegalCompanyButtonTitle} />
                    </ButtonPrimary>
                </BackButton>
            </Form>
        </Formik>
    </>
);
