import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { InfoBlock } from '@shared-atom/info-block/info-block';
import { Avatar } from '@shared-component/avatar/avatar';
import { AvatarWrapper } from '@shared-component/registration/common.styles';
import { UserCompanyRelationType, UserType } from '@shared-graphql';
import { usePersonsLocalize } from '@shared-hook/legal-client-profile/persons/persons.localize-hook';
import { CompanyPersonRelationType } from '@shared-model/company-model/company-persons-model/company-persons-model.type';
import { OnEventEmptyType } from '@shared-type/on-event.type';

interface RegistrationLegalPersonsListItemProps {
    person: Instance<typeof CompanyPersonRelationType>;
    onClick: OnEventEmptyType;
    onDelete: OnEventEmptyType;
    isCurrentUser?: boolean;
}

export const RegistrationLegalPersonsListItem: FC<RegistrationLegalPersonsListItemProps> = observer(
    ({ person, onDelete, onClick, isCurrentUser = false }) => {
        const isLegal = person.type === UserType.Legal;

        const isPersonOwner = person.relation.companyRelation.includes(UserCompanyRelationType.Owner);

        const [infoTitle, companyRelation] = usePersonsLocalize(isLegal, person, isCurrentUser);

        const canDelete = !isPersonOwner && !isCurrentUser;

        return (
            <InfoBlock
                title={infoTitle}
                description={companyRelation}
                onDelete={canDelete ? onDelete : undefined}
                isButton={!canDelete}
                onClick={onClick}
            >
                <AvatarWrapper>
                    <Avatar name={person.person?.profile.fullName ?? person.legal?.companyName ?? ''} />
                </AvatarWrapper>
            </InfoBlock>
        );
    }
);
