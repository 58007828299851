import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';

import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';

import { AccountCardType } from '@model/account-model/account-card-model/account-card-model.type';

import { NameView } from './cell.styles';

interface InternetCellRenderProps {
    card: Instance<typeof AccountCardType>;
}

const BalanceCellRender = observer(({ card }: InternetCellRenderProps) => {
    const value = useCurrencyFormat(card.balance, card.currency.code);

    return <NameView>{value}</NameView>;
});

export const BalanceCell = (card: Instance<typeof AccountCardType>) => <BalanceCellRender card={card} />;
