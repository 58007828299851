import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { ButtonBordered } from '@shared-atom/button/button-bordered';

import { CurrencyModelTypeInstance } from '@model/currencies-model/currencies-model.type';

import Approve from './assets/approve.svg?react';
import Blocked from './assets/blocked.svg?react';
import { ActionsWrapper } from './cell.styles';

interface ActionsCellRenderProps {
    currency: Instance<typeof CurrencyModelTypeInstance>;
}

const ActionsCellRender: FC<ActionsCellRenderProps> = ({ currency }) => {
    const handleStatusChange = (isActive: boolean) => () => {
        console.log('Currency Update method has been removed', { isActive });
        // currency.updateCurrency({ code: currency.code, isActive });
    };

    return (
        <ActionsWrapper>
            <ButtonBordered
                title={`Approve ${currency.currency}`}
                disabled={currency.isActive}
                onClick={handleStatusChange(true)}
            >
                <Approve />
            </ButtonBordered>
            <ButtonBordered
                title={`Blocking ${currency.currency}`}
                disabled={!(currency.isActive as boolean)}
                onClick={handleStatusChange(false)}
            >
                <Blocked />
            </ButtonBordered>
        </ActionsWrapper>
    );
};

export const ActionsCell = (currency: Instance<typeof CurrencyModelTypeInstance>) => (
    <ActionsCellRender currency={currency} />
);
