import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { useContext } from 'react';

import { BackButton } from '@shared-atom/back-button/back-button';
import { Translate } from '@shared-atom/translate/translate';
import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { RegistrationLegalPersonTitle, PersonsWrapper } from '@shared-component/registration/common.styles';
import { RegistrationLegalDocumentsPersonItem } from '@shared-component/registration/components/registration-legal-documents-person-item/registration-legal-documents-person-item';
import { UserType } from '@shared-graphql';
import { CompanyPersonRelationType } from '@shared-model/company-model/company-persons-model/company-persons-model.type';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { RegistrationLegalDocumentsEnum } from '@page/legal-client-profile/legal-client-profile-documents/registration-legal-documents.enum';
import { useCompanySelector } from '@selector/company/use-company.selector';

import {
    RegistrationLegalDocumentsPersonOptions,
    RegistrationLegalDocumentsPersonTitle,
} from './registration-legal-documents-person.options';

interface RegistrationLegalDocumentsPersonProps {
    relation: RegistrationLegalDocumentsEnum;
    onClick: OnEventType<string>;
    onBack: OnEventEmptyType;
}

export const RegistrationLegalDocumentsPersonChoose = observer(
    ({ relation, onClick, onBack }: RegistrationLegalDocumentsPersonProps) => {
        const [{ getRelationPersons, hasPersonProfile, persons }] = useCompanySelector();
        const { onOpen } = useContext(ModelContext);

        const relationPersons = getRelationPersons(
            RegistrationLegalDocumentsPersonOptions[relation],
            relation === RegistrationLegalDocumentsEnum.UserCreated
        );

        const handlePersonClick = (id: string) => () => {
            if (!hasPersonProfile(id)) {
                const { relation: personRelation, type } = persons.get(id) as Instance<
                    typeof CompanyPersonRelationType
                >;

                return onOpen(
                    UserType.Legal === type
                        ? ModalEnum.RegistrationLegalPersonLegalModal
                        : ModalEnum.RegistrationLegalPrivatePersonModal,
                    { componentProps: { relation: personRelation, type, id } }
                );
            }

            onClick(id);
        };

        return (
            <>
                <RegistrationLegalPersonTitle>
                    <Translate langKey={RegistrationLegalDocumentsPersonTitle[relation]} />
                </RegistrationLegalPersonTitle>
                <PersonsWrapper>
                    {relationPersons.map(person => (
                        <RegistrationLegalDocumentsPersonItem
                            key={person.id}
                            person={person}
                            onClick={handlePersonClick(person.id)}
                        />
                    ))}
                </PersonsWrapper>
                <BackButton onClick={onBack} />
            </>
        );
    }
);
