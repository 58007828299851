import { useMutation } from '@apollo/client';
import {
    mutationAddComment as addComment,
    mutationAddFileComment as addFileComment,
} from '@queries/comment/comment.mutation';
import { useContext, useEffect } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { AdminPaymentAddDocuments, File as FileUploadType, Mutation } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { OnEventType } from '@shared-type/on-event.type';
import { addDocumentComments } from '@shared-util/add-document-comments';
import { isTrue } from '@shared-util/is-data';

import { usePaymentSelector } from '@selector/payment/use-payment.selector';

import { PaymentDocumentsInterface } from './payment-documents.interface';

export const usePaymentDocuments = (): [PaymentDocumentsInterface, OnEventType<PaymentDocumentsInterface>, boolean] => {
    const [
        {
            payment: { documents: initialDocuments },
        },
    ] = usePaymentSelector();
    const {
        params: { paymentId },
    } = useCurrentNavigation();

    const { onOpen } = useContext(InformationModalContext);

    const [adminPaymentAddDocuments, { loading: isLoading, error: adminPaymentAddDocumentsDataError }] =
        useMutation<Pick<Mutation, 'adminPaymentAddDocuments'>>(AdminPaymentAddDocuments);

    useEffect(() => {
        if (isTrue(adminPaymentAddDocumentsDataError)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: adminPaymentAddDocumentsDataError?.message ?? 'An error occurred',
            });
        }
    }, [adminPaymentAddDocumentsDataError]);

    const onSubmit = ({ documents }: PaymentDocumentsInterface) => {
        adminPaymentAddDocuments({
            variables: {
                paymentId,
                documentIds: (documents as FileUploadType[])?.map(document => document?.key),
            },
        });

        addDocumentComments({
            initialDocuments: initialDocuments ?? null,
            documents: documents ?? null,
            addComment,
            addFileComment,
        });
    };

    return [{ documents: initialDocuments }, onSubmit, isLoading];
};
