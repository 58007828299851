import styled from 'styled-components';

import { White } from '@shared-style/colors';

export const AccountsWrapper = styled.div`
    height: 192px;
    width: 100%;
    max-width: 1366px;
    margin: 0 auto;
`;

export const AccountAddWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 2fr;
    gap: 16px;
    background-color: ${White};
    padding: 32px;
    border-radius: 16px;
`;
