import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ClickEventType } from '@shared-type/click-event.type';

import { IconWrapper, MinusIcon, PlusIcon, Wrapper } from './info-right-block.styles';

export interface InfoRightBlockProps {
    isButton?: boolean;
    onClick?: ClickEventType;
}

export const InfoRightBlock = ({ isButton, onClick }: InfoRightBlockProps) => (
    <Wrapper>
        <IconWrapper>
            <ButtonTransparent onClick={onClick}>{isButton === true ? <PlusIcon /> : <MinusIcon />}</ButtonTransparent>
        </IconWrapper>
    </Wrapper>
);
