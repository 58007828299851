import { UserActivatedStatus, CompanyActivatedStatus } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { useAccountActionsSelector } from '@selector/account/use-account.actions-selector';
import { useCompanySelector } from '@selector/company/use-company.selector';
import { useCurrenciesSelector } from '@selector/currencies/use-currencies.selector';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

import { FormDataInterface } from './accounts-add-form.initial';

export const useAccountAddForm = () => {
    const [company] = useCompanySelector();
    const [privateClient] = usePrivateClientSelector();
    const [{ currenciesActiveSelect }] = useCurrenciesSelector();
    const { updateAccountCurrencies } = useAccountActionsSelector();

    const { name } = useCurrentNavigation();

    const handleSubmit = ({ currency }: FormDataInterface): void => {
        const isPrivateAccount = name.includes('private');

        if (isPrivateAccount) {
            if (privateClient !== null && privateClient.status === UserActivatedStatus.Approved) {
                return void updateAccountCurrencies(privateClient.id, [currency]);
            }
        } else if (company !== null && company.status === CompanyActivatedStatus.Approved) {
            return void updateAccountCurrencies(company.id, [currency]);
        }
    };

    return {
        currenciesOptions: currenciesActiveSelect,
        handleSubmit,
    };
};
