import { rgba } from 'polished';
import { CSSProperties } from 'react';
import styled, { CSSObject } from 'styled-components';

import { ButtonTransparentStyle } from '@shared-atom/button/button.style';
import { getAnimationObjectStyle } from '@shared-style/animation';
import { DarkGrey, Mystic, NileBlue, Raven, White } from '@shared-style/colors';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

import Cross from './assets/cross.svg?react';

export const Overlay = (isVisible: boolean, isModalFull: boolean): CSSProperties => ({
    ...(!isModalFull && getAnimationObjectStyle(['backdrop-filter'])),
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: rgba(NileBlue, 0.6),
    backdropFilter: `blur(${isVisible ? 2 : 0}px)`,
    zIndex: 10,
});

export const getModalStylesContentWidth = (isVisible: boolean, isModalFull: boolean): CSSObject => ({
    ...(!isModalFull && getAnimationObjectStyle(['transform'])),
    transform: isVisible ? 'translateX(0)' : 'translateX(100%)',
    position: 'absolute',
    left: 'unset',
    right: 0,
    top: 0,
    bottom: 0,
    width: 'fit-content',
    borderRadius: 0,
    border: 'none',
    background: 'none',
    padding: 0,
    overflow: 'unset',
});

export const ModalFullWidth: CSSObject = {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 0,
    border: 'none',
    background: 'none',
    padding: 0,
};

export const CrossIcon = styled(Cross)`
    fill: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : DarkGrey)};
`;

export const ButtonCloseWrapper = styled.div`
    position: absolute;
    width: 48px;
    height: 48px;
    transform: translateX(-100%);
    left: -32px;
    top: 32px;
    @media (max-width: 560px) {
        left: -10px;
        width: 35px;
        height: 35px;
    }
`;

export const ButtonRightCloseWrapper = styled(ButtonCloseWrapper)`
    z-index: 15;
    left: unset;
    right: 32px;
    @media (max-width: 425px) {
        display: none;
    }
`;

export const ButtonClose = styled(ButtonTransparentStyle)`
    width: inherit;
    border-radius: 50%;
    border: 1px solid ${Mystic};
    background-color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? rgba(White, 0.2) : White)};
`;

export const ModalFullCloseWrapper = styled.div`
    position: absolute;
    width: 48px;
    height: 48px;
    right: 32px;
    top: 32px;
`;

export const ButtonCloseGray = styled(ButtonClose)`
    width: inherit;
    border-radius: 50%;
    box-shadow: 0 0 0 1px ${Mystic};
    background: transparent;

    ${CrossIcon} {
        fill: ${DarkGrey};
    }

    :hover {
        ${CrossIcon} {
            fill: ${Raven};
        }
    }
`;
