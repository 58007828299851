import { Field, useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, useEffect } from 'react';

import { FieldError } from '@shared-component/field-error/field-error';
import { OnEventType } from '@shared-type/on-event.type';
import { isString } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

import { Check, CheckBoxWrapper, CheckIconWrapper, CheckTitle, CheckBoxStyle, ErrorWrapper } from './checkbox.styles';

export interface CheckBoxProps {
    name: string;
    title?: string;
    disabled?: boolean;
    onChange?: OnEventType<boolean>;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const CheckBox: FC<CheckBoxProps> = observer(
    ({ name, title, disabled = false, onChange = noop, children, ...props }) => {
        const [field] = useField(name);
        const isChecked = Boolean(field.value);
        const shouldRenderTitle = isString(title);

        useEffect(() => void onChange(field.value), [field.value, onChange]);

        return (
            <>
                <CheckBoxWrapper>
                    <CheckBoxStyle isDisabled={disabled}>
                        <Field type="checkbox" name={name} disabled={disabled} hidden />
                        <CheckIconWrapper isChecked={isChecked} isDisabled={disabled} {...props}>
                            {isChecked && <Check />}
                        </CheckIconWrapper>
                        {shouldRenderTitle && <CheckTitle isExist={children !== null}>{title}</CheckTitle>}
                    </CheckBoxStyle>
                    {children}
                </CheckBoxWrapper>
                <ErrorWrapper>
                    <FieldError name={name} />
                </ErrorWrapper>
            </>
        );
    }
);
