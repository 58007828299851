import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { PaymentStatus } from '@shared-graphql';

export const paymentStatuses = $enum(PaymentStatus).getValues();

export const PaymentStatusesModel = types.model({
    id: types.string,
    status: types.enumeration(paymentStatuses),
    date: types.Date,
});
