import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { DayPickerRange } from '@shared-component/day-picker/day-picker-range/day-picker-range';
import { RadioButton } from '@shared-component/radio-button/radio-button';
import { FormHalfRow } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { StatementFormEnum } from './statement-form.enum';
import { typeOptions } from './statement-form.options';
import {
    StatementFormComponentWrapper,
    StatementTypeWrapper,
    StatementTypeStyle,
    StatementFormTitle,
    StatementFormHead,
} from './statement-form.styles';

type StatementFormType = {
    children?: ReactNode | ReactNode[] | JSX.Element;
};

export const StatementForm = observer(({ children }: StatementFormType) => {
    const [fieldType] = useField(StatementFormEnum.Operation);

    return (
        <>
            <StatementFormTitle>
                <Translate langKey={LocalizationEnum.StatementFormAccountTitle} />
            </StatementFormTitle>
            {children}
            <StatementFormComponentWrapper>
                <StatementFormHead>
                    <Translate langKey={LocalizationEnum.StatementFormDateRange} />
                </StatementFormHead>
                <FormHalfRow>
                    <DayPickerRange nameFrom={StatementFormEnum.From} nameTo={StatementFormEnum.To} />
                </FormHalfRow>
            </StatementFormComponentWrapper>
            <StatementFormComponentWrapper>
                <StatementFormHead>
                    <Translate langKey={LocalizationEnum.StatementFormType} />
                </StatementFormHead>
                <StatementTypeWrapper>
                    {typeOptions.map(({ key, title }) => (
                        <RadioButton key={key} name={StatementFormEnum.Operation} value={key}>
                            <StatementTypeStyle isChecked={key === fieldType.value}>
                                <Translate langKey={title} />
                            </StatementTypeStyle>
                        </RadioButton>
                    ))}
                </StatementTypeWrapper>
            </StatementFormComponentWrapper>
        </>
    );
});
