import React, { FC } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationTextType } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { ClickEventType } from '@shared-type/click-event.type';

import { BlockDescription, BlockTitle, CheckedIcon, Wrapper } from './info-main-block.styles';

export interface InfoMainBlockProps {
    customTitle?: string;
    isChosen?: boolean;
    title?: LocalizationTextType;
    description?: LocalizationTextType;
    onClick?: ClickEventType;
    children?: React.ReactNode;
}

export const InfoMainBlock: FC<InfoMainBlockProps> = ({
    title,
    customTitle,
    description,
    children,
    onClick,
    isChosen = false,
}) => (
    <ButtonTransparent onClick={onClick}>
        {children}
        <Wrapper>
            <BlockTitle>{customTitle ?? <Translate langKey={title as LocalizationEnum} />}</BlockTitle>
            {description !== undefined && (
                <BlockDescription>
                    <Translate langKey={description} separator=", " />
                </BlockDescription>
            )}
        </Wrapper>
        {isChosen && <CheckedIcon />}
    </ButtonTransparent>
);
