import { Form, Formik } from 'formik';
import { FC, ReactNode } from 'react';

import { MutationCheckPhoneCodeArgs } from '@shared-graphql';
import { OnEventEmptyType } from '@shared-type/on-event.type';
import { getClearText } from '@shared-util/remove-spaces';

import { OtpForm } from './otp.form';
import { OtpInitial } from './otp.initial';
import { OtpEnum, OtpValidationSchema } from './otp.options';

export interface OtpProps {
    error?: string;
    onSubmit: any;
    children?: ReactNode | ReactNode[] | JSX.Element;
    onResendOtpCode: OnEventEmptyType;
}

export const Otp: FC<OtpProps> = ({ error, onSubmit, children, onResendOtpCode }) => {
    const handleOtpSubmit = ({ code }: Pick<MutationCheckPhoneCodeArgs, OtpEnum.Code>) => onSubmit(getClearText(code));

    return (
        <Formik
            initialValues={OtpInitial}
            validationSchema={OtpValidationSchema}
            validateOnChange={false}
            onSubmit={handleOtpSubmit}
        >
            <Form>
                <OtpForm name={OtpEnum.Code} error={error} onResendOtpCode={onResendOtpCode} />
                {children}
            </Form>
        </Formik>
    );
};
