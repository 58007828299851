import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

export const DocumentsWrapper = styled.div`
    width: 100%;
    max-width: 704px;
    ${Flex.Column.Style};
    gap: 36px;
`;
export const DocumentsRow = styled.div`
    ${Flex.Row.Style};
    gap: 36px;
`;
export const OtherDocumentsList = styled.div`
    ${Flex.Column.Style};
    gap: 36px;
`;
export const DocumentTitle = styled.h3`
    padding: 8px 0;
    width: 240px;
    ${Typography.Paragraph14.Neutral500.Normal.Style};
`;
export const DocumentPreview = styled.img`
    width: 250px;
    max-height: 320px;
    object-fit: contain;
    object-position: top;
`;

export const ButtonWrapper = styled.div`
    margin-top: 40px;
`;
