import styled from 'styled-components';

import EyeCloseIcon from '@shared-component/input/assets/eye-close.svg?react';
import EyeOpenIcon from '@shared-component/input/assets/eye-open.svg?react';
import { DarkBlue, DarkGrey, White } from '@shared-style/colors';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const EyeClose = styled(EyeCloseIcon)`
    fill: ${DarkGrey};
`;
export const EyeOpen = styled(EyeOpenIcon)`
    fill: ${DarkGrey};
`;

export const PasswordWrapper = styled.div`
    padding-right: 16px;

    :hover {
        ${EyeClose}, ${EyeOpen} {
            fill: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : DarkBlue)};
        }
    }
`;
