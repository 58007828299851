import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';

interface MoneyCellProps {
    amount?: number;
    currency?: string;
}

export const MoneyCell = ({ amount, currency }: MoneyCellProps) => {
    const formatedMoneyAmount = useCurrencyFormat(amount ?? 0, currency);

    return (
        <>
            {Boolean(formatedMoneyAmount) === true && formatedMoneyAmount}
            {Boolean(formatedMoneyAmount) === false && null}
        </>
    );
};
