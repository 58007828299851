import { Commission } from '@shared-graphql';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { CommissionProfileInfoForm } from '@page/commission-profile/commission-profile-info/commission-profile-info-form/commission-profile-info-form';
import { CommissionProfileInfoList } from '@page/commission-profile/commission-profile-info/commission-profile-info-list/commission-profile-info-list';
import { TextList } from '@page/commission-profile/commission-profile-info/commission-profile-info.styles';
import {
    HandleDeleteInterface,
    HandleUpdateInterface,
} from '@page/commission-profile/commission-profile-info/commission-profile.interface';

interface CommissionProfileInfoPaymentTextProps {
    isEditMode: boolean;
    template: Commission;
    onSubmit: OnEventType<HandleUpdateInterface>;
    onDelete: OnEventType<HandleDeleteInterface>;
    onClose: OnEventEmptyType;
}

export const CommissionProfileInfoPaymentText = ({
    isEditMode,
    template,
    onSubmit,
    onDelete,
    onClose,
}: CommissionProfileInfoPaymentTextProps) => (
    <TextList>
        {isEditMode && (
            <CommissionProfileInfoForm template={template} onSubmit={onSubmit} onClose={onClose} onDelete={onDelete} />
        )}
        {!isEditMode && <CommissionProfileInfoList commissions={template.commissions} />}
    </TextList>
);
