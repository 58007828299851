import { observer } from 'mobx-react-lite';

import { CheckBox } from '@shared-component/checkbox/checkbox';
import { Input } from '@shared-component/input/input';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';
import { Select } from '@shared-component/select/select';
import { BaseOptionInterface } from '@shared-component/select/select.props';
import { FieldColumnWrapper, FormHalfRow, FormRow } from '@shared-form/form-common/form-common.styles';

import { LegalServiceFormEnum } from './registration-legal-service-form.enum';
import { useLegalServiceFormLocalization } from './registration-legal-service-form.localization-hook';

interface RegistrationLegalServiceFormProps {
    currencies: BaseOptionInterface[];
}

export const RegistrationLegalServiceForm = observer(({ currencies }: RegistrationLegalServiceFormProps) => {
    const { eurTitle, swiftTitle, multicurrencyTitle, currencyTitle, moneyTitle } = useLegalServiceFormLocalization();

    return (
        <>
            <CheckBox name={LegalServiceFormEnum.IsEuroAccount} title={eurTitle} />
            <FormRow>
                <CheckBox name={LegalServiceFormEnum.IsSwiftAccount} title={swiftTitle} />
            </FormRow>
            <FormRow>
                <CheckBox name={LegalServiceFormEnum.IsMulticurrencyAccount} title={multicurrencyTitle} />
            </FormRow>
            <FormHalfRow>
                <Select name={LegalServiceFormEnum.Currency} title={currencyTitle} options={currencies} />
                <FieldColumnWrapper>
                    <Input type={InputTypeEnum.Number} name={LegalServiceFormEnum.MoneyTurnover} title={moneyTitle} />
                </FieldColumnWrapper>
            </FormHalfRow>
        </>
    );
});
