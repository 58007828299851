import { object, string } from 'yup';

import { nameValidation, fieldRequiredValidation, thirdNameValidation } from '@shared-util/validation';

import { PrivateProfileFormEnum } from './private-profile-form.enum';

export const PrivateProfileFormValidation = object().shape({
    [PrivateProfileFormEnum.Name]: nameValidation(),
    [PrivateProfileFormEnum.LastName]: nameValidation(),
    [PrivateProfileFormEnum.ThirdName]: thirdNameValidation(),
    [PrivateProfileFormEnum.Nationality]: string().required(),
    [PrivateProfileFormEnum.DateBirthday]: fieldRequiredValidation(),
    [PrivateProfileFormEnum.Gender]: fieldRequiredValidation(),
    [PrivateProfileFormEnum.FinancialStatus]: fieldRequiredValidation(),
});
