import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { AcceptHeader } from '@shared-form/form-common/accept-header/accept-header';
import { PrivateAcceptAgreementForm } from '@shared-form/private-accept-agreement-form/private-accept-agreement-form';
import { PrivateAcceptAgreementFormEnum } from '@shared-form/private-accept-agreement-form/private-accept-agreement-form.enum';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { PageEnum } from '@enum/page.enum';
import { ButtonWrapper } from '@page/private-client-profile/private-client-profile.styles';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

import { usePrivateClientAcceptAgreement } from './private-client-profile-accept-agreement.hook';
import { Description, FormWrapper } from './private-client-profile-accept-agreement.styles';

export const PrivateClientProfileAcceptAgreement = observer(() => {
    const [{ phone, isAcceptAgreement }] = usePrivateClientSelector();

    const { params } = useCurrentNavigation();
    const [hasUserFullRegistration, formInitial, formValidation, handleSubmit] = usePrivateClientAcceptAgreement(
        params.userId
    );

    const hasAcceptAgreement = isAcceptAgreement !== null && isAcceptAgreement;
    const isDisabled =
        (formInitial[PrivateAcceptAgreementFormEnum.AcceptAgreement] as boolean) || !hasUserFullRegistration;

    return (
        <Formik
            initialValues={formInitial}
            onSubmit={handleSubmit as any}
            validationSchema={formValidation}
            enableReinitialize
        >
            <Form>
                <Description>
                    <AcceptHeader hasAcceptAgreement={hasAcceptAgreement} phone={phone} link={PageEnum.AdminCards} />
                </Description>
                <FormWrapper>
                    <PrivateAcceptAgreementForm
                        disabled={isDisabled}
                        isAcceptAgreement={hasAcceptAgreement}
                        linkTerms="https://sends.co/terms-and-conditions"
                        phone={phone}
                    />
                </FormWrapper>
                <ButtonWrapper>
                    <ButtonPrimary type={ButtonTypeEnum.Submit} disabled={isDisabled}>
                        Accept Agreement
                    </ButtonPrimary>
                </ButtonWrapper>
            </Form>
        </Formik>
    );
});
