import { useMemo } from 'react';

import { getCountryISO3 } from '@shared-util/countries';

import { Wrapper } from '@page/clients/clients-table/cells/cell.styles';

import { ClientCellType } from './cell.type';

export const NationalityCell = ({ nationality }: ClientCellType) => {
    const clientNationality = useMemo(() => getCountryISO3(nationality as string) ?? '-', [nationality]);

    return <Wrapper>{clientNationality}</Wrapper>;
};
