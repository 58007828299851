import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { ViewPageLoader } from '@shared-component/page-loader/page-loader';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { Breadcrumbs } from '@component/breadcrumbs/breadcrumbs';
import { Tabs } from '@component/tabs/tabs';
import { BreadCrumbsWrapper, HeaderWrapper, PageContainerWrapper, PageTitle } from '@page/common/common.styles';
import { useCompanySelector } from '@selector/company/use-company.selector';

import { breadcrumbList, legalClientTabOptions } from './legal-client.options';

export const LegalClientPage = observer(() => {
    const { params } = useCurrentNavigation();
    const [
        ,
        {
            isCompanyDocumentsLoading,
            isCompanyAddressLoading,
            isCompanyServiceLoading,
            isCompanyLoading,
            isCompanyPersonsLoading,
        },
    ] = useCompanySelector();

    const isLoading = useMemo(
        () =>
            isCompanyDocumentsLoading ||
            isCompanyAddressLoading ||
            isCompanyServiceLoading ||
            isCompanyLoading ||
            isCompanyPersonsLoading,
        [
            isCompanyDocumentsLoading,
            isCompanyAddressLoading,
            isCompanyServiceLoading,
            isCompanyLoading,
            isCompanyPersonsLoading,
        ]
    );

    return (
        <>
            <BreadCrumbsWrapper>
                <Breadcrumbs breadcrumbs={breadcrumbList} />
            </BreadCrumbsWrapper>
            <HeaderWrapper>
                <PageTitle>Legal profile</PageTitle>
            </HeaderWrapper>
            <PageContainerWrapper>
                <Tabs params={params} options={legalClientTabOptions}>
                    {isLoading ? <ViewPageLoader /> : <Outlet />}
                </Tabs>
            </PageContainerWrapper>
        </>
    );
});
