import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { PrivateServiceForm } from '@shared-form/private-service-form/private-service-form';

import { ButtonWrapper } from '@page/private-client-profile/private-client-profile.styles';

import { usePrivateClientProfileServices } from './private-client-profile-services.hook';

export const PrivateClientProfileServices = observer(() => {
    const [initialValues, onUpdateServices, currenciesActive] = usePrivateClientProfileServices();

    return (
        <Formik onSubmit={onUpdateServices} initialValues={initialValues} enableReinitialize>
            <Form>
                <PrivateServiceForm currencies={currenciesActive} />
                <ButtonWrapper>
                    <ButtonPrimary type={ButtonTypeEnum.Submit}>Update services</ButtonPrimary>
                </ButtonWrapper>
            </Form>
        </Formik>
    );
});
