import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Mystic, Dark, WhiteDark, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const Header = styled.div`
    flex: 0 0 auto;
    padding-bottom: 24px;
    box-shadow: 0 1px 0 ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? WhiteDark : Mystic)};

    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const Title = styled.span`
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.L.Medium.Style};
`;

export const HeaderContainer = styled.div`
    flex: 1;
    margin-left: 8px;
    flex-direction: row-reverse;
    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const Table = styled.table`
    width: 100%;
    height: calc(100% - 80px);
    flex: 1;
    position: relative;
    ${Flex.Column.Style}
`;

export const Footer = styled.div`
    flex: 0 0 auto;
    height: 80px;
    margin-top: auto;
`;

export const EmptyTableContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 16px;
    ${Flex.HorizontalCenter.VerticalCenter.Style}
`;

export const EmptyTableText = styled.p`
    width: 100%;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.Center.Style}
`;

export const RootWrapper = styled.div`
    height: 100%;
    ${Flex.Column.Style};
    @media (max-width: 560px) {
        width: 575px;
    }
`;

export const TableFiltersWrapper = styled.div`
    margin-top: 24px;
    ${Flex.VerticalCenter.SpaceBetween.Style};
    gap: 24px;
`;

export const TableFiltersFormWrapper = styled.div`
    ${Flex.Style};
    gap: 16px;
`;

export const TablePageWrapper = styled.div`
    max-width: 1366px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    padding: 0 0 16px 40px;

    ${Flex.Column.Style}
`;

export const TableWrapper = styled.div`
    height: calc(100% - 120px);
    margin-top: 9px;
    padding: 0;
    background-color: ${White};
`;
