import styled from 'styled-components';

import { White } from '@shared-style/colors';

import { PageContainerWrapper } from '@page/common/common.styles';

interface PaymentInnerWrapperProps {
    isLarge: boolean;
}

export const PaymentWrapper = styled(PageContainerWrapper)`
    background-color: ${White};
`;

export const PaymentInnerWrapper = styled.div<PaymentInnerWrapperProps>`
    padding: 40px;
    max-width: ${({ isLarge }) => (isLarge ? 1200 : 840)}px;
`;
