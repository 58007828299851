import { observer } from 'mobx-react-lite';

import { OtpLoginStep } from '@shared-component/otp-login-step/otp-login-step';
import { WebPreferredLoginMethod } from '@shared-graphql';
import { useLoginSteps } from '@shared-hook/login-steps/login-steps.hook';
import { isTrue } from '@shared-util/is-data';

import { GoogleAuthLoginStep } from '@page/login/google-auth-login-step/google-auth-login-step';

import { UserCredentialsStep } from './user-credentials-step/user-credentials-step';

export const LoginSteps = observer(() => {
    const {
        phone,
        webPreferredLoginMethod,
        errorMessage,
        handleUserCredentialsSubmit,
        onLoginWithAnotherMethod,
        handleOtpSubmit,
    } = useLoginSteps();

    if (isTrue(phone)) {
        return webPreferredLoginMethod === WebPreferredLoginMethod.GoogleAuthenticator ? (
            <GoogleAuthLoginStep
                onSubmit={handleOtpSubmit}
                error={errorMessage}
                onResendOtpCode={() => onLoginWithAnotherMethod(WebPreferredLoginMethod.GoogleAuthenticator)}
            />
        ) : (
            <OtpLoginStep
                phone={phone}
                onSubmit={handleOtpSubmit}
                error={errorMessage}
                onResendOtpCode={() => onLoginWithAnotherMethod(WebPreferredLoginMethod.Sms)}
            />
        );
    }

    return <UserCredentialsStep onSubmit={handleUserCredentialsSubmit} error={errorMessage} />;
});
