import { mutationAddComment } from '@queries/comment/comment.mutation';
import { flow, getParent, Instance } from 'mobx-state-tree';

import {
    AdminAddPrivateClientComment,
    AdminGetPrivateClientComments,
    ClientComments,
    DefaultAnswer,
    Mutation,
    Query,
} from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { PrivateClientModel } from '@model/private-client-model/private-client-model';
import { PrivateClientTypes } from '@model/private-client-model/private-client-model.type';

const queryPrivateClientComments = (userId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetPrivateClientComments'>>({
        query: AdminGetPrivateClientComments,
        variables: { userId },
    })
        .then(result => result.data?.adminGetPrivateClientComments);

const mutationPrivateClientComment = (userId: string, commentId: string) =>
    apolloClient
        .mutate<Pick<Mutation, 'adminAddPrivateClientComment'>>({
        mutation: AdminAddPrivateClientComment,
        variables: { userId, commentId },
    })
        .then(result => result.data?.adminAddPrivateClientComment);

export const loadPrivateClientCommentsActions = (self: Instance<typeof PrivateClientTypes>) => ({
    loadPrivateClientComments: flow(function* loadPrivateClientComments(userId) {
        const clientModel = getParent(self) as Instance<typeof PrivateClientModel>;

        if (clientModel.loading.isLoadingComment) {
            return;
        }

        clientModel.setCommentLoading(true);

        self.comment.elements.clear();

        try {
            const { elements }: ClientComments = yield queryPrivateClientComments(userId);

            elements.forEach(element => {
                self.comment.elements.set(element.comment.id, {
                    comment: { ...element.comment },
                    createdAt: element.createdAt,
                });
            });
        } catch (error) {
            throw new Error(error as string);
        } finally {
            clientModel.setCommentLoading(false);
        }
    }),
});

export const addPrivateClientCommentsActions = (self: Instance<typeof PrivateClientTypes>) => ({
    addPrivateClientComments: flow(function* addPrivateClientComments(userId: string, comment: string) {
        const clientModel = getParent(self) as Instance<typeof PrivateClientModel>;

        if (clientModel.loading.isLoadingComment) {
            return;
        }

        clientModel.setCommentLoading(true);

        try {
            const { id } = yield mutationAddComment(comment);
            const clientComment = yield mutationPrivateClientComment(userId, id);

            clientModel.setCommentLoading(false);

            if ((clientComment as DefaultAnswer).status) {
                (self as any).loadPrivateClientComments(userId);
            }
        } catch (error) {
            throw new Error(error as string);
        } finally {
            clientModel.setCommentLoading(false);
        }
    }),
});
