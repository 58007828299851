import { CurrentTabName } from '@component/breadcrumbs/breadcrumb-tabs/current-tab-name';
import { OptionsInterface } from '@component/tabs/tabs.interface';
import { PageEnum } from '@enum/page.enum';

export const paymentTabsOptions: OptionsInterface[] = [
    {
        title: 'Summary',
        name: PageEnum.PaymentSummary,
    },
    {
        title: 'Order',
        name: PageEnum.PaymentEdit,
    },
    {
        title: 'Documents',
        name: PageEnum.PaymentDocuments,
    },
];

export const paymentBreadcrumbs = [
    {
        path: PageEnum.Transactions,
        name: 'Payments',
    },
    {
        name: <CurrentTabName options={paymentTabsOptions} />,
    },
];
