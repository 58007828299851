import styled from 'styled-components';

import { White } from '@shared-style/colors';

export const BlockWrapper = styled.div`
    width: 100%;
    padding: 16px 0;
    border-radius: 0 0 16px 16px;
    background-color: ${White};
    height: calc(100% - 60px);
`;
