import { observer } from 'mobx-react-lite';

import { CustomInput } from '@shared-component/input/custom-input';
import { CustomInputPassword } from '@shared-component/input/input-password/custom-input-password';
import { FormRow } from '@shared-form/form-common/form-common.styles';

import { UserCredentialsStepFormEnum } from './user-credentials-step-form.enum';
import { useUserCredentialsStepFormFields } from './user-credentials-step-form.hook';

interface UserCredentialsStepFormFieldsProps {
    error?: string;
}

export const UserCredentialsStepForm = observer(({ error }: UserCredentialsStepFormFieldsProps) => {
    const { emailTitle, passwordTitle } = useUserCredentialsStepFormFields(error);
    return (
        <>
            <CustomInput
                title={emailTitle}
                name={UserCredentialsStepFormEnum.Email}
                placeholder="Enter your mail"
                autofocus
            />
            <FormRow>
                <CustomInputPassword title={passwordTitle} name={UserCredentialsStepFormEnum.Password} />
            </FormRow>
        </>
    );
});
