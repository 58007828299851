import useEvent from '@react-hook/event';
import { MutableRefObject, RefObject } from 'react';

import { EventEnum } from '@shared-enum/keyboard.enum';
import { EventTargetInterface, OnEventEmptyType } from '@shared-type/on-event.type';

export const useOutsideClick = <T extends HTMLElement>(
    ref: RefObject<T> | MutableRefObject<T> | null,
    handler: OnEventEmptyType
) => {
    const listener = (event: Event) => {
        if (!ref?.current || ref.current.contains((event as unknown as EventTargetInterface<T>).target)) {
            return;
        }

        handler();
    };

    useEvent(document, EventEnum.MouseDown, listener);
    useEvent(document, EventEnum.TouchStart, listener);
};
