import styled from 'styled-components';

import Individual from '@shared-asset/legal.svg?react';
import Company from '@shared-asset/private.svg?react';
import { DarkGrey } from '@shared-style/colors';

export const IndividualIcon = styled(Individual)`
    fill: ${DarkGrey};
`;

export const CompanyIcon = styled(Company)`
    fill: ${DarkGrey};
`;
