import { Instance } from 'mobx-state-tree';

import { AdminModelType } from '@model/admin-model/admin-model.type';
import { useStore } from '@store/store-context';

export const useAdminSelector = (): [Instance<typeof AdminModelType>, boolean, null | string] => {
    const { admin, loading, error } = useStore().admin;

    return [admin, loading.isLoading, error];
};
