import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { EmptyContentWrapper } from '@shared-component/accounts-list/accounts-list.styles';
import { AnimationLoading } from '@shared-component/animation/animation-loading/animation-loading';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { Breadcrumbs } from '@component/breadcrumbs/breadcrumbs';
import { Tabs } from '@component/tabs/tabs';
import { BreadCrumbsWrapper, HeaderWrapper, PageTitle } from '@page/common/common.styles';
import { PaymentInnerWrapper, PaymentWrapper } from '@page/payment/payment.styles';
import { useAccountSelector } from '@selector/account/use-account.selector';
import { usePaymentSelector } from '@selector/payment/use-payment.selector';

import { paymentBreadcrumbs, paymentTabsOptions } from './payment.options';

export const PaymentPage = observer(() => {
    const { name, params } = useCurrentNavigation();
    const [, isPaymentLoading] = usePaymentSelector();
    const [, { isLoading: isAccountsLoading }] = useAccountSelector();

    const isLoading = useMemo(() => isPaymentLoading || isAccountsLoading, [isPaymentLoading, isAccountsLoading]);

    return (
        <>
            <BreadCrumbsWrapper>
                <Breadcrumbs breadcrumbs={paymentBreadcrumbs} />
            </BreadCrumbsWrapper>
            <HeaderWrapper>
                <PageTitle>Payment</PageTitle>
            </HeaderWrapper>
            <PaymentWrapper>
                <Tabs params={params} options={paymentTabsOptions}>
                    <PaymentInnerWrapper isLarge={name.includes('summary')}>
                        {isLoading ? (
                            <EmptyContentWrapper>
                                <AnimationLoading />
                            </EmptyContentWrapper>
                        ) : (
                            <Outlet />
                        )}
                    </PaymentInnerWrapper>
                </Tabs>
            </PaymentWrapper>
        </>
    );
});
