import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Dark, DarkBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

import Clip from './assets/clip.svg?react';

export const AttachFileWrapper = styled.div`
    ${Flex.Style}
`;

export const ClipIcon = styled(Clip)`
    height: 24px;
    width: 24px;
    fill: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : DarkBlue)};
`;

export const AttachFileLabelWrapper = styled.p`
    margin-left: 8px;
`;

export const AttachFileLabel = styled.p`
    ${Typography.M.Left.Style}
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
`;

export const AttachFileDescription = styled.p`
    ${Typography.S.DarkGrey.Style}
`;
