import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Row } from '@shared-style/common.styles';

export const RejectPaymentTitle = styled.h3`
    ${Typography.Paragraph18.SemiBold.Neutral700.Style};

    margin-bottom: 16px;
`;
export const RejectPaymentText = styled.p`
    ${Typography.Paragraph14.Medium.Neutral700.Style};

    margin-bottom: 16px;
`;
export const RejectPaymentButtons = styled(Row)`
    gap: 12px;
    button {
        padding: 10px 48px;
    }
`;

export const ButtonSubmitWrapper = styled.div`
    margin-top: 32px;
`;
