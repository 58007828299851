export enum CommissionProfileInfoEnum {
    PercentCommission = 'percentCommission',
    StaticCommission = 'staticCommission',
    MaxAmount = 'maxAmount',
    MinAmount = 'minAmount',
}

export enum CommissionProfileInfoSupportEnum {
    Amount = 'amount',
    Title = 'title',
    Reason = 'reason',
}
