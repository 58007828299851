import styled from 'styled-components';

import { Flex } from '../../../styles/flex';
import { Typography } from '../../typography/typography';
import { NotificationSizeEnum, NotificationTypeEnum } from './types';
import { getNotificationPaddingBySize, getStyleByType } from './utils';

interface ElpasoNotificationProps {
    size: NotificationSizeEnum;
    typeStyle: NotificationTypeEnum;
}

interface ElpasoNotificationTitleProps {
    size: NotificationSizeEnum;
}

interface ElpasoNotificationIconProps {
    size: NotificationSizeEnum;
}

export const ElpasoNotification = styled.div<ElpasoNotificationProps>`
    ${Flex.Row.VerticalCenter.Style};
    ${({ size }) =>
        size === NotificationSizeEnum.M ? Typography.Paragraph12.Medium.Style : Typography.Paragraph14.Medium.Style};
    ${({ typeStyle }) => getStyleByType(typeStyle)}
    padding: ${({ size }) => getNotificationPaddingBySize(size)};
    svg,
    path {
        ${({ typeStyle }) => getStyleByType(typeStyle)};
    }
`;
export const ElpasoNotificationTitle = styled.span<ElpasoNotificationTitleProps>`
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    ${({ size }) =>
        size === NotificationSizeEnum.M
            ? Typography.Paragraph12.Medium.Left.Style
            : Typography.Paragraph14.Medium.Left.Style};
`;
export const ElpasoNotificationIcon = styled.div<ElpasoNotificationIconProps>`
    width: 20px;
    height: 20px;
    margin-right: 10px;
    align-self: flex-start;
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    svg {
        width: 20px;
        height: 20px;
    }
`;
