import { DocumentNode } from 'graphql';
import { flow, Instance } from 'mobx-state-tree';

import {
    AdminApprovePayment,
    AdminGetFullPayment,
    AdminGetPaymentStatus,
    AdminRejectPayment,
    DefaultAnswer,
    Mutation,
    // PaymentInput,
    PaymentStatus,
    Query,
} from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { PaymentModelType } from '@model/payment-model/payment-model';
import { initialFeeModel } from '@model/payment-model/payment-model-initials/payment-fee-model.initial';
import { initialPayment } from '@model/payment-model/payment-model-initials/payment-model.initial';
import { PaymentInstanceModelType } from '@model/payment-model/payment-model.type';

import { initialUpdateStatus, initialPaymentStatus } from './payments.initials-result';

interface PaymentModelInterface extends Instance<typeof PaymentModelType>, ReturnType<typeof paymentsActions> {}

const queryPayment = <T = Pick<Query, 'getPayment'>>(paymentId: string, clientId: string, query: DocumentNode) =>
    apolloClient.query<T>({ query, variables: { paymentId, clientId } }).then(result => result.data);

// const mutationUpdatePayment = (payment: PaymentInput, clientId: string) =>
//     apolloClient
//         .mutate<Pick<Mutation, 'updatePayment'>>({ mutation: updatePaymentSchema, variables: { payment, clientId } })
//         .then(result => result.data?.updatePayment ?? initialPaymentModel);

const mutationApprovePayment = (paymentId: string) =>
    apolloClient
        .mutate<Pick<Mutation, 'adminApprovePayment'>>({ mutation: AdminApprovePayment, variables: { paymentId } })
        .then(result => result.data?.adminApprovePayment ?? initialUpdateStatus);

const mutationRejectPayment = (paymentId: string, message: string) =>
    apolloClient
        .mutate<Pick<Mutation, 'adminRejectPayment'>>({
        mutation: AdminRejectPayment,
        variables: { paymentId, message },
    })
        .then(result => {
            if (result.errors) {
                return result.errors[0];
            }
            return result.data?.adminRejectPayment ?? initialUpdateStatus;
        });

export const paymentsActions = (self: Instance<typeof PaymentModelType>) => ({
    setLoading: (loading: boolean) => {
        self.loading.isLoading = loading;
    },
    clearPayment: () => {
        self.payments.payment = { ...initialPayment, payerName: '' } as unknown as Instance<
            typeof PaymentInstanceModelType
        >;
    },
    setPayment: (payment: Instance<typeof PaymentInstanceModelType>) => {
        self.payments.payment = {
            ...payment,
            payerName: self.payments.payment.payerName,
            fee: payment.fee ?? initialFeeModel,
            beneficiary: payment.beneficiary ?? null,
        };
    },
    setStatus: (status: PaymentStatus) => {
        self.payments.payment.status = status;
    },
    setPayerName: (payerName: string) => {
        self.payments.payment.payerName = payerName;
    },
    setActiveLoadedPayment: (paymentId: string) => {
        self.payments.activeLoadedPaymentId = paymentId;
    },
    loadPaymentStatus: flow(function* loadPaymentStatus() {
        const result = yield queryPayment(
            self.payments.payment.id,
            self.payments.payment.clientId,
            AdminGetPaymentStatus
        );

        (self as PaymentModelInterface).setStatus(result.getPayment.status ?? initialPaymentStatus.status);
    }),
    loadPayment: flow(function* loadPayment(paymentId: string, clientId: string) {
        if (self.loading.isLoading) {
            return;
        }

        (self as PaymentModelInterface).setLoading(true);
        (self as PaymentModelInterface).clearPayment();
        (self as PaymentModelInterface).setActiveLoadedPayment(paymentId);

        try {
            const data = yield queryPayment<Pick<Query, 'adminGetPayment' | 'adminGetClient'>>(
                paymentId,
                clientId,
                AdminGetFullPayment
            );

            if (paymentId === (self as PaymentModelInterface).payments.activeLoadedPaymentId) {
                (self as PaymentModelInterface).setPayment(data.adminGetPayment ?? initialPayment);
                (self as PaymentModelInterface).setPayerName(data.adminGetClient.name);
            }
        } catch (error) {
            throw new Error(error as string);
        } finally {
            (self as PaymentModelInterface).setLoading(false);
        }
    }),
    // updatePayment: flow(function* updatePayment(payment: PaymentInput, clientId: string) {
    //     if (self.loading.isLoading) {
    //         return;
    //     }

    //     (self as PaymentModelInterface).setLoading(true);

    //     try {
    //         const savedPayment = yield mutationUpdatePayment(payment, clientId);

    //         (self as PaymentModelInterface).setPayment(savedPayment);
    //     } catch (error) {
    //         throw new Error(error);
    //     } finally {
    //         (self as PaymentModelInterface).setLoading(false);
    //     }
    // }),
    approvePayment: flow(function* approvePayment(id: string) {
        if (self.loading.isLoading) {
            return;
        }

        (self as PaymentModelInterface).setLoading(true);

        try {
            const result: DefaultAnswer = yield mutationApprovePayment(id);

            if (result.status) {
                (self as PaymentModelInterface).loadPaymentStatus();
            }
        } catch (error) {
            throw new Error(error as string);
        } finally {
            (self as PaymentModelInterface).setLoading(false);
        }
    }),
    rejectPayment: flow(function* rejectPayment(id: string, message: string) {
        if (self.loading.isLoading) {
            return;
        }

        (self as PaymentModelInterface).setLoading(true);

        try {
            const result: DefaultAnswer | Error = yield mutationRejectPayment(id, message);

            if ((result as DefaultAnswer).status) {
                (self as PaymentModelInterface).loadPaymentStatus();
            } else {
                throw new Error((result as Error).message);
            }
        } catch (error) {
            throw new Error(error as string);
        } finally {
            (self as PaymentModelInterface).setLoading(false);
        }
    }),
});
