import { useFormikContext } from 'formik';
import { observer } from 'mobx-react-lite';

import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { CheckBox } from '@shared-component/checkbox/checkbox';
import { Input } from '@shared-component/input/input';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { UserDeclarationInput } from '@shared-graphql';

import { PrivateSelfDeclarationFormEnum } from './private-self-declaration-form.enum';
import { usePrivateSelfDeclarationFormLocalization } from './private-self-declaration-form.localization-hook';
import { TitleText, TitleWrapper, TooltipWrapper } from './private-self-declaration-form.styles';

export const PrivateSelfDeclarationForm = observer(() => {
    const { politicallyPersonTitle, pepTitle, tooltipDescription } = usePrivateSelfDeclarationFormLocalization();

    const { values } = useFormikContext<UserDeclarationInput>();

    return (
        <>
            <CheckBox name={PrivateSelfDeclarationFormEnum.PoliticallyPerson}>
                <TitleWrapper>
                    <TitleText>{politicallyPersonTitle}</TitleText>
                    <TooltipWrapper>
                        <Tooltip content={tooltipDescription} dataFor={politicallyPersonTitle} />
                    </TooltipWrapper>
                </TitleWrapper>
            </CheckBox>
            {Boolean(values?.isPoliticallyPerson) && (
                <FormRow>
                    <Input name={PrivateSelfDeclarationFormEnum.Pep} title={pepTitle} />
                </FormRow>
            )}
        </>
    );
});
