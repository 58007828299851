import { types } from 'mobx-state-tree';

import { userMainActions } from '@model/users-model/users-actions/user-main.actions';

import { UserModelTypeInstance } from './user-model.type';
import { initialUserModel } from './users-model-initials/user-model.initial';

export const UsersModelType = types.model('UserModel', {
    users: UserModelTypeInstance,
    loading: types.model('UserLoading', {
        isLoading: types.boolean,
    }),
    error: types.optional(types.string, ''),
});

export const UserModel = UsersModelType.actions(userMainActions);

export const getInitialUsersModel = () =>
    UserModel.create({
        users: initialUserModel as any,
        loading: {
            isLoading: false,
        },
    });
