import { Form, Formik } from 'formik';

import { BackButton } from '@shared-atom/back-button/back-button';
import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { CreateClientFormInterface } from '@shared-form/create-client-form/create-client-form.interface';
import { CreateClientFormValidation } from '@shared-form/create-client-form/create-client-form.validation';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';

import { PageEnum } from '@enum/page.enum';

import { CreateClientForm } from './create-client-form/create-client-form';
import { useCreateClient } from './create-client.hook';

export const CreateClient = () => {
    const navigate = useRouterNavigate();
    const [error, handleSubmit] = useCreateClient();

    const handleBack = () => navigate(PageEnum.StatusClients);

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={{} as CreateClientFormInterface}
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={CreateClientFormValidation}
        >
            <Form>
                <CreateClientForm emailError={error} />
                <BackButton onClick={handleBack}>
                    <ButtonPrimary type={ButtonTypeEnum.Submit}>Create client</ButtonPrimary>
                </BackButton>
            </Form>
        </Formik>
    );
};
