import { rgba } from 'polished';
import styled from 'styled-components';

import Done from '@shared-asset/done.svg?react';
import { Typography } from '@shared-atom/typography/typography';
import { NileBlue, PrimeBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

interface NavigationItemProps {
    isDisabled: boolean;
}

interface NavigationItemTitleProps {
    isActive: boolean;
}

const itemSize = 56;

export const NavigationWrapperItem = styled.li`
    position: relative;
    height: ${itemSize}px;
    padding: 0 48px;
    ${Flex.VerticalCenter.Style}

    :not(:first-child) {
        margin-top: 48px;

        :after {
            content: '';
            position: absolute;
            display: block;
            width: 2px;
            height: 32px;
            left: ${48 + itemSize / 2}px;
            top: -8px;
            background-color: ${rgba(White, 0.4)};
            transform: translateY(-100%) translateX(-50%);
        }
    }
`;

export const NavigationItemIconWrapper = styled.div`
    width: ${itemSize}px;
    height: ${itemSize}px;
    border-radius: 50%;
    background-color: ${({ isDisabled }: NavigationItemProps) => (isDisabled ? rgba(White, 0.1) : NileBlue)};
    ${Flex.HorizontalCenter.VerticalCenter.InlineFlex.Style}
`;

export const NavigationItemIcon = styled.svg<NavigationItemProps>`
    fill: ${({ isDisabled }) => (isDisabled ? rgba(White, 0.4) : PrimeBlue)};
`;

export const NavigationItemTitle = styled.span<NavigationItemTitleProps>`
    margin-left: 16px;
    color: ${({ isActive }) => (isActive ? White : rgba(White, 0.6))};
    ${Typography.L.Medium.Style}
`;

export const DoneIcon = styled(Done)`
    position: absolute;
    top: 50%;
    right: 72px;
    transform: translateY(-50%) translateX(50%);
`;
