import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { AccountsList } from '@shared-component/accounts-list/accounts-list';
import { StatementForm } from '@shared-form/statement-form/statement-form';
import { StatementFormEnum } from '@shared-form/statement-form/statement-form.enum';
import { Account } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

import { useLegalClientSummary } from '@page/legal-client/legal-client-summary/legal-client-summary.hook';
import { useAccountActionsSelector } from '@selector/account/use-account.actions-selector';
import { useAccountSelector } from '@selector/account/use-account.selector';

export const RequestStatementAccounts = observer(() => {
    const [{ id: clientId }] = useLegalClientSummary();
    const [{ ccAccounts, limitCcAccounts, selectedAccount }, { isLoading }] = useAccountSelector();
    const { loadClientAccounts, setSelectedAccount } = useAccountActionsSelector();

    const [, , { setValue: setClientIdValue }] = useField<string>(StatementFormEnum.ClientId);
    const [, , { setValue: setAccountIdValue }] = useField<string>(StatementFormEnum.AccountId);

    useEffect(() => {
        setClientIdValue(clientId);
    }, [clientId]);

    useEffect(() => {
        if (isTrue(selectedAccount?.currency.code)) {
            setAccountIdValue(selectedAccount?.accountId);
        }
    }, [selectedAccount]);

    return (
        <StatementForm>
            <AccountsList
                isShowMore
                clientId={clientId}
                isLoading={isLoading}
                ccAccounts={ccAccounts as unknown as Account[]}
                onClick={setSelectedAccount}
                limitCcAccounts={limitCcAccounts as unknown as Account[]}
                loadClientAccounts={loadClientAccounts}
                selectedAccount={selectedAccount as unknown as Account}
            />
        </StatementForm>
    );
});
