import React, { FC } from 'react';

import { Fiord, White } from '@shared-style/colors';

import { Icon, TooltipText, TooltipWrapper } from './tooltip.styles';

export interface TooltipProps {
    content: string;
    dataFor: string;
    effect?: 'float' | 'solid';
    place?: 'top' | 'right' | 'bottom' | 'left';
    isShow?: boolean;
    children?: React.ReactNode;
}

export const Tooltip: FC<TooltipProps> = ({
    content,
    children,
    dataFor,
    place = 'bottom',
    effect = 'float',
    isShow = true,
}) =>
    isShow ? (
        <>
            <TooltipWrapper
                id={dataFor}
                getContent={() => content}
                backgroundColor={White}
                textColor={Fiord}
                place={place}
                effect={effect}
            />
            <TooltipText data-tip="tooltip" data-for={dataFor}>
                {children ?? <Icon />}
            </TooltipText>
        </>
    ) : (
        <div>{children}</div>
    );
