import { types } from 'mobx-state-tree';

import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';

export const AccountBankTypeSchema = {
    bankAddress: types.string,
    bankCountry: types.string,
    bankName: types.string,
    currency: CurrencyModelType,
    name: types.string,
    number: types.string,
    paymentType: types.string,
    sortCode: types.maybeNull(types.string),
};
