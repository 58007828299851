import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { RefObject, useRef, useState } from 'react';
import ReactSelect, { ValueType } from 'react-select';
import { ElRef } from 'react-select/base';
import { StateManager } from 'react-select/src/stateManager';

import { Control } from '@shared-component/table/table-filters/select-filter/control/control';
import {
    BaseOptionInterface,
    SelectFilterProps,
} from '@shared-component/table/table-filters/select-filter/select-filter.props';
import {
    ChevronDownIcon,
    SelectPreview,
    SelectPreviewData,
    SelectPreviewTitle,
    SelectWrapper,
    Wrapper,
    containerStyle,
    controlStyle,
    indicatorsContainerStyle,
    indicatorSeparatorStyle,
    inputStyle,
    noOptionsMessageStyle,
    optionStyle,
    placeholderStyle,
    selectMenuStyle,
    singleValueStyle,
} from '@shared-component/table/table-filters/select-filter/select-filter.styles';
import { useOutsideClick } from '@shared-hook/utils/use-outside-click';
import { isString } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

export const SelectFilter = observer(
    <T extends BaseOptionInterface>({
        name,
        title,
        options = [],
        menuHeight,
        menuWidth,
        isUseVal,
        isDisabled = false,
        onChange = noop,
        onValueChanged,
        valueRender,
        defaultValue,
        ...selectProps
    }: SelectFilterProps<T>) => {
        const [field, , { setValue }] = useField<ValueType<T, boolean> | undefined>(name);

        const [isSelectVisible, setSelectVisible] = useState(false);
        const [filterValue, setFilterValue] = useState('');
        const selectRef = useRef() as RefObject<StateManager<T, boolean>>;
        const selectWrapperRef = useRef() as RefObject<HTMLDivElement>;

        useOutsideClick(selectWrapperRef, () => setSelectVisible(false));

        const selectOptions = isString(filterValue)
            ? options.filter(option =>
                option[isUseVal !== undefined ? 'value' : 'label'].toLowerCase().includes(filterValue)
            )
            : options;

        if (onValueChanged == null) {
            onValueChanged = () => {};
        }

        const handleChange = (selectValue: any) => {
            setValue(selectValue?.value);
            onChange();
            onValueChanged(selectValue?.value);
            setSelectVisible(false);
        };

        const handleInputChange = (value: string) => setFilterValue(value.toLowerCase());

        const optionValue = selectOptions.find(({ value }) => value === (field.value as any as string));

        return (
            <Wrapper ref={selectWrapperRef}>
                <SelectPreview onClick={() => setSelectVisible(true)}>
                    <SelectPreviewTitle>{title}:</SelectPreviewTitle>
                    <SelectPreviewData>
                        {(optionValue?.label as string) ?? ''}
                        <ChevronDownIcon isSelectVisible={isSelectVisible} />
                    </SelectPreviewData>
                </SelectPreview>
                <SelectWrapper isSelectVisible={isSelectVisible}>
                    <ReactSelect
                        {...selectProps}
                        isDisabled={isDisabled}
                        ref={selectRef as ElRef}
                        placeholder="Search"
                        value={optionValue ?? null}
                        options={selectOptions}
                        defaultValue={defaultValue}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        components={{ Control } as any}
                        controlShouldRenderValue={false}
                        closeMenuOnSelect={false}
                        defaultMenuIsOpen
                        isSearchable
                        autoFocus
                        menuIsOpen
                        styles={{
                            container: containerStyle,
                            placeholder: placeholderStyle,
                            singleValue: singleValueStyle,
                            input: inputStyle,
                            menu: selectMenuStyle,
                            indicatorSeparator: indicatorSeparatorStyle,
                            indicatorsContainer: indicatorsContainerStyle,
                            control: controlStyle,
                            noOptionsMessage: noOptionsMessageStyle,
                            option: optionStyle,
                        }}
                    />
                </SelectWrapper>
            </Wrapper>
        );
    }
);
