import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { InfoBlock } from '@shared-atom/info-block/info-block';
import { Avatar } from '@shared-component/avatar/avatar';
import { parsePercent } from '@shared-component/input/input-percent/input-percent.helper';
import { AvatarWrapper } from '@shared-component/registration/common.styles';
import { UserCompanyRelationType, UserType } from '@shared-graphql';
import { CompanyPersonRelationType } from '@shared-model/company-model/company-persons-model/company-persons-model.type';
import { OnEventEmptyType } from '@shared-type/on-event.type';
import { DIGIT_TO_PERCENT } from '@shared-util/constants';
import { parseRealNumber } from '@shared-util/parse-real-number';

interface RegistrationLegalPersonsListItemProps {
    person: Instance<typeof CompanyPersonRelationType>;
    onClick?: OnEventEmptyType;
    onDelete?: OnEventEmptyType;
}

export const RegistrationLegalShareholdersListItem: FC<RegistrationLegalPersonsListItemProps> = observer(
    ({ person, onDelete, onClick }) => {
        const isLegal = person.type === UserType.Legal;

        const percent = parsePercent(parseRealNumber(person.relation?.ownershipPercent, DIGIT_TO_PERCENT));

        const infoTitle = isLegal ? person.legal?.companyName ?? '' : person.person?.profile.fullName ?? '';

        const isPersonOwner = person.relation.companyRelation.includes(UserCompanyRelationType.Owner);

        return (
            <InfoBlock
                title={infoTitle}
                description={percent}
                onDelete={isPersonOwner ? undefined : onDelete}
                onClick={onClick}
                isButton={onDelete === undefined}
            >
                <AvatarWrapper>
                    <Avatar name={person.person?.profile.fullName ?? person.legal?.companyName ?? ''} />
                </AvatarWrapper>
            </InfoBlock>
        );
    }
);
