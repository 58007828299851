import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { Select } from '@shared-component/select/select';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { FreeDesignModalButtonsWrapper } from '@component/modal/admin-modal.styles';
import { AddServiceFormEnum } from '@component/modal/modals/add-service/add-service-form/add-service-form.enum';
import { AddServiceFormInterface } from '@component/modal/modals/add-service/add-service-form/add-service-form.interface';

export const AddServiceForm = ({ serviceOptions, onCancelClick }: AddServiceFormInterface) => (
    <>
        <FormRow>
            <Select name={AddServiceFormEnum.FeatureTag} title="Select service" options={serviceOptions} />
        </FormRow>
        <FreeDesignModalButtonsWrapper>
            <ButtonPrimary onClick={onCancelClick}>
                <Translate langKey={LocalizationEnum.CommonCancel} />
            </ButtonPrimary>
            <ButtonPrimary type={ButtonTypeEnum.Submit}>
                <Translate langKey={LocalizationEnum.CommonSave} />
            </ButtonPrimary>
        </FreeDesignModalButtonsWrapper>
    </>
);
