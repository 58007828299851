import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Dark, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const HeaderWrapper = styled.div`
    max-width: 1094px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 16px;

    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const Title = styled.h1`
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.XXL.Normal.Style};
`;
