import { countries } from 'countries-list';
import { memo } from 'react';
import { OptionTypeBase } from 'react-select/src/types';

import { Flag } from '@shared-atom/flag/flag';
import { CountryCodeType } from '@shared-component/country-select/country-select.type';
import { isString } from '@shared-util/is-data';

import { CountryTitle, ItemWrapper } from './private-client-summary-country-label.styles';

interface CountryLabelProps<T = CountryCodeType | null | string> extends OptionTypeBase {
    countryCode: T;
}

export const CountryLabel = memo(({ countryCode }: CountryLabelProps) => (
    <ItemWrapper>
        {isString(countryCode) ? (
            <>
                <Flag countryCode={(countryCode as CountryCodeType)?.toLowerCase()} />
                <CountryTitle>{countries[countryCode as CountryCodeType]?.name}</CountryTitle>
            </>
        ) : (
            <>-</>
        )}
    </ItemWrapper>
));
