import { FC } from 'react';

import { Link } from '@shared-atom/link/link';
import { Scroll } from '@shared-component/scroll/scroll';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { CommonObjectType } from '@shared-type/common.type';
import { getPathnameWithParams } from '@shared-util/get-pathname-with-params';

import { PageEnum } from '@enum/page.enum';

import { TabsProps } from './tabs.interface';
import { Wrapper, Navigation, List, Item, Text, ContentWrapper } from './tabs.styles';

export const Tabs: FC<TabsProps> = ({ params, options, children }) => {
    const { name } = useCurrentNavigation();

    return (
        <Wrapper>
            <Navigation>
                <List>
                    {options.map(({ title, name: pageName }) => {
                        const isActive = (pageName as string).split('/').at(-1) === (name as string).split('/').at(-1);

                        return (
                            <Item key={title} isActive={isActive}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <Link
                                    to={{
                                        pathname: getPathnameWithParams(
                                            pageName as PageEnum,
                                            params as CommonObjectType
                                        ),
                                    }}
                                    hasLinkBehaviour={false}
                                >
                                    <Text>{title}</Text>
                                </Link>
                            </Item>
                        );
                    })}
                </List>
            </Navigation>
            <ContentWrapper>
                <Scroll>{children}</Scroll>
            </ContentWrapper>
        </Wrapper>
    );
};
