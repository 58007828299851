import styled from 'styled-components';

import Delete from '@shared-asset/delete.svg?react';
import Plus from '@shared-asset/plus.svg?react';
import { ButtonReject } from '@shared-atom/button/button-reject';
import { Typography } from '@shared-atom/typography/typography';
import { DarkGrey, Mystic, PrimeBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const Wrapper = styled.div`
    margin: 24px 0 32px;
    padding: 24px;
    border-radius: 16px;
    background-color: ${White};
`;

export const Title = styled.p`
    ${Typography.LLL.Medium.Style};
`;

export const List = styled.ul`
    margin-top: 24px;
    border: 1px solid ${Mystic};
`;

export const CommissionItem = styled.li`
    ${Flex.Style}

    &:not(:last-child) {
        border-bottom: 1px solid ${Mystic};
    }
`;

interface CommissionTitleProps {
    hasCommission: boolean;
}

interface DescriptionProps {
    isHalf?: boolean;
}

export const CommissionTitle = styled.p<CommissionTitleProps>`
    padding: 16px 24px;
    min-width: 144px;
    box-shadow: 1px 0 0 ${({ hasCommission }) => (hasCommission ? Mystic : 'transparent')};
    flex: 1 1 ${({ hasCommission }) => (hasCommission ? 40 : 100)}%;
    ${Typography.Style};
    ${Flex.SpaceBetween.Style};
`;
export const CommissionSubTitle = styled(CommissionTitle)`
    ${Typography.Bold.Style};
`;

export const Description = styled.div<DescriptionProps>`
    position: relative;
    padding: 16px 24px;
    flex: 1 1 ${({ isHalf = false }) => (isHalf ? 30 : 60)}%;
    ${Typography.Style}
`;

export const DescriptionInfo = styled.span`
    display: block;
    margin-top: 8px;
    ${Typography.S.Fiord.Style}
`;

interface TextProps {
    hasBlueDot: boolean;
}

export const TextList = styled.ul`
    flex: 1 1 60%;
`;

export const Price = styled.span`
    margin-left: 4px;
`;

export const TextItem = styled.li<TextProps>`
    position: relative;
    padding: 16px 40px;
    ${Typography.Style}

    ::before {
        content: '';
        visibility: ${({ hasBlueDot }) => (hasBlueDot ? 'visible' : 'hidden')};
        position: absolute;
        left: 24px;
        top: 20px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${PrimeBlue};
    }

    &:not(:last-child) {
        box-shadow: 0 1px 0 ${Mystic};
    }
`;

export const TitleWrapper = styled.div`
    width: 280px;

    ${Flex.VerticalCenter.SpaceBetween.Style}
`;

export const ButtonWrapper = styled.div`
    margin: 16px 0 16px;
    padding: 0 16px;
    gap: 16px;
    ${Flex.SpaceAround.VerticalCenter.Style}
`;
export const DeleteButton = styled(ButtonReject)`
    margin-left: auto;
`;

export const EditCommissionWrapper = styled.div`
    ${Flex.VerticalCenter.Style}
`;

export const InputWrapper = styled.div`
    flex: 1;
    ${Flex.Column.Style};
    gap: 8px;
`;

export const ButtonEdit = styled.div`
    margin-left: 16px;
    color: ${PrimeBlue};
`;

export const ButtonPlus = styled.div`
    ${Flex.VerticalCenter.HorizontalCenter.Style}
`;

export const DeleteIcon = styled(Delete)`
    width: 24px;
    height: 24px;
    margin: 0 16px;
    fill: ${DarkGrey};
`;

export const PlusIcon = styled(Plus)`
    margin-top: 20px;
    fill: ${DarkGrey};
`;
