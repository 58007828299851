import { types } from 'mobx-state-tree';

import { paymentsActions } from './payment-actions/payments.actions';
import { initialPaymentModel } from './payment-model-initials/payment-model.initial';
import { PaymentModelTypeInstance } from './payment-model.type';

export const PaymentModelType = types.model({
    payments: PaymentModelTypeInstance,
    loading: types.model({
        isLoading: types.boolean,
    }),
    error: types.optional(types.string, ''),
});

export const PaymentModel = PaymentModelType.actions(paymentsActions);

export const getInitialPaymentModel = () =>
    PaymentModel.create({
        payments: initialPaymentModel as any,
        loading: {
            isLoading: false,
        },
    });
