import { useMemo, useState } from 'react';

import { Maybe } from '@shared-graphql';

export const useStatusFilter = <T>(initialStatus: Maybe<T> = null) => {
    const [status, setStatus] = useState<Maybe<T>>(initialStatus);

    const onStatusChange = (newStatus: T) => setStatus(newStatus);

    return useMemo(() => [{ status }, { onStatusChange }, {}], [status]);
};
