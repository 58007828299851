import { useField } from 'formik';
import { useEffect } from 'react';

import { CheckBox } from '@shared-component/checkbox/checkbox';
import { InputPercent } from '@shared-component/input/input-percent/input-percent';
import { parsePercent } from '@shared-component/input/input-percent/input-percent.helper';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { RegistrationLegalPersonPrivateProfileFormEnum } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.enum';
import { useRegistrationLegalPersonPrivateProfileForm } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.hook';
import { Maybe } from '@shared-graphql';
import { isExist, isNumber, isString } from '@shared-util/is-data';

import { useRegistrationLegalPersonPrivateProfileFormOwnershipLocalization } from './registration-legal-person-private-profile-form-ownership.localization-hook';

interface LegalPersonPrivateProfileFormOwnershipProps {
    initialOwnershipPercent: Maybe<number>;
}

const initialZeroPercent = parsePercent(0);

export const RegistrationLegalPersonPrivateProfileFormOwnership = ({
    initialOwnershipPercent,
}: LegalPersonPrivateProfileFormOwnershipProps) => {
    const [, , { setValue: setOwnership }] = useField(RegistrationLegalPersonPrivateProfileFormEnum.Ownership);
    const [{ value: ownershipPercent }, , { setValue: setOwnershipPercent }] = useField(
        RegistrationLegalPersonPrivateProfileFormEnum.OwnershipPercent
    );
    const isOwnership = useRegistrationLegalPersonPrivateProfileForm(
        RegistrationLegalPersonPrivateProfileFormEnum.Ownership
    );

    const { shareholderTitle, shareholderPercentTitle } =
        useRegistrationLegalPersonPrivateProfileFormOwnershipLocalization();

    useEffect(() => {
        if (isOwnership && isNumber(initialOwnershipPercent) && initialZeroPercent === ownershipPercent) {
            setOwnershipPercent(parsePercent(initialOwnershipPercent));
        }

        if ((!isOwnership && isString(ownershipPercent)) || !isExist(ownershipPercent)) {
            setOwnershipPercent(initialZeroPercent);
        }
    }, [isOwnership, ownershipPercent, initialOwnershipPercent]);

    useEffect(() => {
        setOwnership(isOwnership);
    }, [isOwnership]);

    return (
        <>
            <CheckBox title={shareholderTitle} name={RegistrationLegalPersonPrivateProfileFormEnum.Ownership} />
            <FormRow>
                <InputPercent
                    title={shareholderPercentTitle}
                    name={RegistrationLegalPersonPrivateProfileFormEnum.OwnershipPercent}
                    disabled={!isOwnership}
                />
            </FormRow>
        </>
    );
};
