import { useMemo, useState } from 'react';

import { Maybe } from '@shared-graphql';

export const useCountryFilter = <T>(initialType: Maybe<T> = null) => {
    const [country, setCountry] = useState<Maybe<T>>(initialType);

    const onCountryChange = (newCountry: T) => setCountry(newCountry);

    return useMemo(() => [{ country }, { onCountryChange }, {}], [country]);
};
