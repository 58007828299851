import { UserDocumentType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import Attach from '@page/legal-client-profile/assets/attach.svg?react';

export type CompanyDocumentType = keyof typeof RegistrationLegalDocumentLocalizations;

export const DEFAULT_DOCUMENT_LOCALIZATIONS = [
    LocalizationEnum.PrivateDocumentsPageTitle,
    LocalizationEnum.PrivateDocumentsPageDescription,
];

export const RegistrationLegalDocumentLocalizations = {
    [UserDocumentType.Passport]: [
        LocalizationEnum.DocumentFormPassportTitle,
        LocalizationEnum.PrivateDocumentsPageDescription,
    ],
    [UserDocumentType.Selfie]: [
        LocalizationEnum.DocumentFormSelfieTitle,
        LocalizationEnum.PrivateDocumentsPageDescription,
    ],
    [UserDocumentType.Location]: [
        LocalizationEnum.DocumentFormLocationTitle,
        LocalizationEnum.PrivateDocumentsPageDescription,
    ],
    [UserDocumentType.Chart]: [
        LocalizationEnum.RegistrationLegalDocumentsChartSelfButton,
        LocalizationEnum.PrivateDocumentsPageDescription,
    ],
    [UserDocumentType.Other]: DEFAULT_DOCUMENT_LOCALIZATIONS,
};

export const personLegalDocumentsChooses = [
    {
        type: UserDocumentType.Chart,
        title: LocalizationEnum.RegistrationLegalDocumentsChartSelfButton,
        icon: Attach,
    },
];
