import makeInspectable from 'mobx-devtools-mst';
import { types } from 'mobx-state-tree';

import { AccountModel, getInitialAccountModel } from '@model/account-model/account-model';
import { AdminModel, getInitialUserModel } from '@model/admin-model/admin-model';
import { ClientsModel, getInitialClientModel } from '@model/clients-model/clients-model';
import { CompanyModel, getInitialCompanyModel } from '@model/company-model/company-model';
import { CurrenciesModel, getInitialCurrenciesModel } from '@model/currencies-model/currencies-model';
import { PaymentModel, getInitialPaymentModel } from '@model/payment-model/payment-model';
import { PrivateClientModel, getInitialPrivateClientModel } from '@model/private-client-model/private-client-model';
import { UserModel, getInitialUsersModel } from '@model/users-model/user-model';

import { getInitialPageStore, PageStore } from './page-store';

const RootStore = types.model('RootStore', {
    account: AccountModel,
    admin: AdminModel,
    user: UserModel,
    privateClient: PrivateClientModel,
    clients: ClientsModel,
    company: CompanyModel,
    payment: PaymentModel,
    currencies: CurrenciesModel,
    page: PageStore,
});

const accountStore = getInitialAccountModel();
const adminStore = getInitialUserModel();
const userStore = getInitialUsersModel();
const privateClientStore = getInitialPrivateClientModel();
const clientsStore = getInitialClientModel();
const paymentStore = getInitialPaymentModel();
const currenciesStore = getInitialCurrenciesModel();
const companyStore = getInitialCompanyModel();
const pageStore = getInitialPageStore();

const createStore = () =>
    RootStore.create({
        account: accountStore as any,
        admin: adminStore as any,
        user: userStore as any,
        privateClient: privateClientStore as any,
        clients: clientsStore as any,
        payment: paymentStore as any,
        currencies: currenciesStore as any,
        company: companyStore as any,
        page: pageStore as any,
    });

export const rootStore = createStore();

makeInspectable(rootStore);
