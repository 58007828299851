import React, { MouseEventHandler } from 'react';

import { NavigationItemCustom } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/navigation-item-custom';
import { PageWrapperNavigationItem } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item';
import {
    NavigationList,
    NavigationWrapper,
} from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation.styles';
import { PageMenuListInterface } from '@shared-component/page-wrapper/page-wrapper.interface';

export interface PageWrapperNavigationProps<T> {
    closeSidebar?: MouseEventHandler<HTMLButtonElement>;
    pageMenuList: PageMenuListInterface<T>[];
    additionalMenuList?: PageMenuListInterface<T>[];
    commissionList?: PageMenuListInterface<T>[];
    isAdmin?: boolean;
    optionsList?: PageMenuListInterface<T>[];
}

const renderDashboardWrapperItem = <T extends string>(isAdmin: boolean, option: PageMenuListInterface<T>) =>
    isAdmin ? (
        <PageWrapperNavigationItem {...option} key={option.pageName ?? option.title} />
    ) : (
        <NavigationItemCustom {...option} key={option.pageName ?? option.title} />
    );

export const PageWrapperNavigation = <T extends string>({
    closeSidebar,
    pageMenuList,
    additionalMenuList = [],
    commissionList = [],
    isAdmin = true,
    optionsList = [],
}: PageWrapperNavigationProps<T>) => (
    <NavigationWrapper isAdmin={isAdmin}>
        <NavigationList>
            {pageMenuList.map(item => renderDashboardWrapperItem(isAdmin, { ...item, onClick: closeSidebar }))}
        </NavigationList>
        {additionalMenuList.length > 0 && (
            <NavigationList>
                {additionalMenuList.map(item =>
                    renderDashboardWrapperItem(isAdmin, { ...item, onClick: closeSidebar })
                )}
            </NavigationList>
        )}
        {commissionList.length > 0 && (
            <NavigationList>
                {commissionList.map(item => renderDashboardWrapperItem(isAdmin, { ...item, onClick: closeSidebar }))}
            </NavigationList>
        )}
        {optionsList.length > 0 && (
            <NavigationList>
                {optionsList.map(item => renderDashboardWrapperItem(isAdmin, { ...item, onClick: closeSidebar }))}
            </NavigationList>
        )}
    </NavigationWrapper>
);
