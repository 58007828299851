import styled from 'styled-components';

import { Neutral200 } from '@shared-style/colors';
import { Row } from '@shared-style/common.styles';

export const TopLevelOfFilters = styled(Row)`
    border-bottom: 1px solid ${Neutral200};
    justify-content: space-between;
    margin-top: 19px;
`;
