import { UserFinancialStatus } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useProfileFinancialStatusOptions = () => {
    const employedFullLabel = useLocalizationText(LocalizationEnum.PrivateProfileFormEmployedFullOption);
    const employedPartLabel = useLocalizationText(LocalizationEnum.PrivateProfileFormEmployedPartOption);
    const selfEmployedLabel = useLocalizationText(LocalizationEnum.PrivateProfileFormSelfEmployedOption);
    const retired = useLocalizationText(LocalizationEnum.PrivateProfileFormRetiredOption);
    const unemployed = useLocalizationText(LocalizationEnum.PrivateProfileFormUnemployedOption);

    return [
        {
            value: UserFinancialStatus.EmployedFull,
            label: employedFullLabel,
        },
        {
            value: UserFinancialStatus.EmployedPart,
            label: employedPartLabel,
        },
        {
            value: UserFinancialStatus.SelfEmployed,
            label: selfEmployedLabel,
        },
        {
            value: UserFinancialStatus.Unemployed,
            label: unemployed,
        },
        {
            value: UserFinancialStatus.Retired,
            label: retired,
        },
    ];
};
