import styled from 'styled-components';

export const FormWrapper = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const FormButtonWrapper = styled.div`
    max-width: 240px;
    margin-top: 40px;
`;
