import { ReactNode } from 'react';

import { TableRowInterface } from '@shared-component/table/table-body/table-row/table-row';
import { Cell } from '@shared-component/table/table-cell/table-cell';
import { Transaction } from '@shared-graphql';

import { paymentRowOptions } from './payment-row/payment-row.options';

export const TransactionRow = ({ id }: TableRowInterface<Transaction>) =>
    paymentRowOptions.map((item: ReactNode) => <Cell key={`${id}-${item?.toString()}`}>{item}</Cell>);
