import { useField } from 'formik';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { FormButtonsRowWrapper } from '@shared-form/form-common/form-common.styles';
import { RegistrationLegalPersonPrivateProfileFormEnum } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.enum';
import { UserCompanyRelationType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const RegistrationLegalPersonPrivateProfileSubmit = () => {
    const [{ value: relations }] = useField<UserCompanyRelationType[]>(
        RegistrationLegalPersonPrivateProfileFormEnum.UserRelation
    );
    const [{ value: isCreate }] = useField<boolean>(RegistrationLegalPersonPrivateProfileFormEnum.UserCreate);

    const shouldNextStep = isCreate || relations.length > 0;

    return (
        <FormButtonsRowWrapper>
            <ButtonPrimary isLarge type={ButtonTypeEnum.Submit} disabled={!shouldNextStep}>
                <Translate langKey={LocalizationEnum.RegistrationLegalCompanyButtonTitle} />
            </ButtonPrimary>
        </FormButtonsRowWrapper>
    );
};
