import { Instance } from 'mobx-state-tree';

import { PaymentModelTypeInstance } from '@model/payment-model/payment-model.type';
import { useStore } from '@store/store-context';

export const usePaymentSelector = (): [Instance<typeof PaymentModelTypeInstance>, boolean, null | string] => {
    const { payments, loading, error } = useStore().payment;

    return [payments, loading.isLoading, error];
};
