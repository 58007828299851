import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { TableRowInterface } from '@shared-component/table/table-body/table-row/table-row';
import { noop } from '@shared-util/noop';

import { CellProps } from './table-cell.interface';
import { TableCell } from './table-cell.styles';

export const Cell: FC<CellProps<any>> = observer(
    <D extends Record<string, unknown>>({
        children,
        column,
        value,
        row = {} as TableRowInterface<D>,
        getCellProps = noop(),
        render = noop(),
        toggleAllRowsExpanded = noop(),
    }: CellProps<D>) => {
        const { original } = row;
        return (
            <TableCell {...getCellProps()}>
                {typeof children === 'function'
                    ? children(original, { row, column, value, toggleAllRowsExpanded })
                    : children ?? render('Cell')}
            </TableCell>
        );
    }
);
