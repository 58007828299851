import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useRegistrationLegalPersonPrivateProfileFormOwnershipLocalization = () => {
    const shareholderTitle = useLocalizationText(LocalizationEnum.RegistrationLegalPersonsPrivateFormShareholder);
    const shareholderPercentTitle = useLocalizationText(
        LocalizationEnum.RegistrationLegalPersonsPrivateFormShareholderPercent
    );

    return { shareholderTitle, shareholderPercentTitle };
};
