import { useCallback, useState } from 'react';

import { File as FileUpload } from '@shared-graphql';
import { useGoogleRecaptcha } from '@shared-hook/google-recaptcha/google-recaptcha';
import { OnEventType } from '@shared-type/on-event.type';
import { isTrue } from '@shared-util/is-data';

const abortController = new AbortController();
export const useFileUpload = (commentId?: string): [OnEventType<File>, boolean, FileUpload | undefined, any, any] => {
    // const [uploadFile, { loading: isLoading, data, error }] = useMutation(UploadFile, {
    //     client: apolloClientForUpload,
    // });

    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [data, setData] = useState<FileUpload | undefined>();

    const uploadFile = async ({ file, token }: { file: File; token: string }) => {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('token', token);
            if (isTrue(commentId)) {
                formData.append('commentId', commentId as string);
            }

            const response = await fetch('/rest-upload', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const result = await response.json();
                setData(result);
            } else {
                setLoading(false);
                setError('File upload failed.');
            }
        } catch (err) {
            setLoading(false);
            setError((err as Error)?.message ?? 'An error occurred while uploading the file.');
        }
    };

    const userStartFileUpload = useCallback((file: File, token: string) => {
        uploadFile({ file, token });
    }, []);

    const handleUploadFile = useGoogleRecaptcha(userStartFileUpload);

    const onCancel = () => {
        abortController.abort();
    };

    return [handleUploadFile, isLoading, data, onCancel, error];
};
