import { Instance } from 'mobx-state-tree';

import { PrivateClientTypesModel } from '@model/private-client-model/private-client-model.type';
import { useStore } from '@store/store-context';

export const usePrivateClientSelector = (): [Instance<typeof PrivateClientTypesModel>, boolean, string] => {
    const { client, loading, error } = useStore().privateClient;

    return [client, loading.isLoading, error];
};
