import { $enum } from 'ts-enum-util';

import { AccountType } from '@shared-graphql';

export const accountTypeOptions = $enum(AccountType).map(value => ({
    value,
    label: value,
}));

export const accountTypeValues = $enum(AccountType).getValues();
