import { $enum } from 'ts-enum-util';

import { BaseOptionInterface } from '@shared-component/select/select.props';
import { UserType } from '@shared-graphql';

import { getClientType } from './create-client-form.utils';

export const clientTypeValues: BaseOptionInterface[] = $enum(UserType).map(value => ({
    value,
    label: getClientType(value),
}));
