import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useRegistrationLegalPersonPrivateProfileFormUserCreateLocalization = () => {
    const userTitle = useLocalizationText(LocalizationEnum.RegistrationLegalPersonsPrivateFormUser);
    const emailTitle = useLocalizationText(LocalizationEnum.RegistrationLegalPersonsPrivateFormEmail);
    const roleTitle = useLocalizationText(LocalizationEnum.RegistrationLegalPersonsPrivateFormRole);
    const phoneTitle = useLocalizationText(LocalizationEnum.RegistrationLegalPersonsPrivateFormPhone);

    return { userTitle, emailTitle, roleTitle, phoneTitle };
};
