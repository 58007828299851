import { useLazyQuery, useMutation } from '@apollo/client';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
    AdminGetCommissionTemplates,
    AdminUpdateCommissionTemplate,
    GetCommissionTemplatesByType,
    Commission,
    Mutation,
    Query,
    UserType,
    AdminCreateCommissionTemplate,
    AdminDeleteCommissionTemplate,
} from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist, isTrue } from '@shared-util/is-data';

import {
    CommissionProfileInfoInterface,
    HandleDeleteInterface,
    HandleUpdateInterface,
} from '@page/commission-profile/commission-profile-info/commission-profile.interface';
import { initialCommissionUserType } from '@page/commission-profile/commission-profile-initials/commission-profile.initials';
import { useStore } from '@store/store-context';

export const useCommissionProfileInfoTemplateByUserType = ({
    userId,
    userType: userTypeFromProps,
}: CommissionProfileInfoInterface): [
    Commission[],
    OnEventType<HandleUpdateInterface>,
    OnEventType<HandleDeleteInterface>,
    boolean
] => {
    const {
        page: {
            commissionProfilePage: { userType: userTypeFromStore },
        },
    } = useStore();
    const userType = useMemo(
        () => (isTrue(userTypeFromProps) ? userTypeFromProps : userTypeFromStore),
        [userTypeFromProps, userTypeFromStore]
    ) as UserType;

    const [commissionTemplates, setCommissionTemplates] = useState<Commission[]>([]);

    const [loadCommissionTemplates, { data: commissionTemplatesData, loading: isLoadCommissionTemplatesLoading }] =
        useLazyQuery<Pick<Query, 'adminGetCommissionTemplates'>>(AdminGetCommissionTemplates, {
            variables: { clientId: userId },
        });
    const [
        loadCommissionTemplatesByType,
        { data: commissionTemplatesByTypeData, loading: isLoadCommissionTemplatesByType },
    ] = useLazyQuery<Pick<Query, 'getCommissionTemplatesByType'>>(GetCommissionTemplatesByType, {
        variables: { userType: userType ?? initialCommissionUserType },
    });

    const [updateCommissionTemplate, { data: dataUpdateCommissionTemplate, loading: isUpdateCommissionTemplate }] =
        useMutation<Pick<Mutation, 'adminUpdateCommissionTemplate'>>(AdminUpdateCommissionTemplate);

    const [createCommissionTemplate, { data: dataCreateCommissionTemplate, loading: isCreateCommissionTemplate }] =
        useMutation<Pick<Mutation, 'adminCreateCommissionTemplate'>>(AdminCreateCommissionTemplate);

    const [deleteCommissionTemplate, { data: dataDeleteCommissionTemplate, loading: isDeleteCommissionTemplate }] =
        useMutation<Pick<Mutation, 'adminDeleteCommissionTemplate'>>(AdminDeleteCommissionTemplate);

    const isLoading = useMemo(
        () =>
            isLoadCommissionTemplatesLoading ||
            isLoadCommissionTemplatesByType ||
            isUpdateCommissionTemplate ||
            isCreateCommissionTemplate ||
            isDeleteCommissionTemplate,
        [
            isLoadCommissionTemplatesLoading,
            isLoadCommissionTemplatesByType,
            isUpdateCommissionTemplate,
            isCreateCommissionTemplate,
            isDeleteCommissionTemplate,
        ]
    );

    const handleUpdateCommissionTemplate = useCallback(
        ({ commissions, id, isDefault, clientId }: HandleUpdateInterface) => {
            if (isTrue(isDefault) && isTrue(clientId)) {
                createCommissionTemplate({
                    variables: { commission: { clientId: userId, commissions, parentCommissionId: id } },
                });
            } else {
                updateCommissionTemplate({ variables: { commission: { commissions, id } } });
            }
        },
        []
    );

    const handleDeleteCommissionTemplate = useCallback(({ id }: HandleDeleteInterface) => {
        deleteCommissionTemplate({ variables: { adminDeleteCommissionTemplateId: id } });
    }, []);

    const loadTemplates = useCallback(() => {
        if (isTrue(userId)) {
            loadCommissionTemplates();
        } else {
            loadCommissionTemplatesByType();
        }
    }, [userId]);

    useEffect(() => {
        loadTemplates();
    }, []);

    useEffect(() => {
        if (isExist((dataUpdateCommissionTemplate?.adminUpdateCommissionTemplate as Commission)?.id)) {
            loadTemplates();
        }
    }, [dataUpdateCommissionTemplate]);

    useEffect(() => {
        if (isExist((dataCreateCommissionTemplate?.adminCreateCommissionTemplate as Commission)?.id)) {
            loadTemplates();
        }
    }, [dataCreateCommissionTemplate]);

    useEffect(() => {
        if (isTrue(dataDeleteCommissionTemplate?.adminDeleteCommissionTemplate?.status)) {
            loadTemplates();
        }
    }, [dataDeleteCommissionTemplate]);

    useEffect(() => {
        if (commissionTemplatesData && Array.isArray(commissionTemplatesData.adminGetCommissionTemplates)) {
            setCommissionTemplates(commissionTemplatesData.adminGetCommissionTemplates);
        }
    }, [commissionTemplatesData]);

    useEffect(() => {
        if (
            commissionTemplatesByTypeData &&
            Array.isArray(commissionTemplatesByTypeData.getCommissionTemplatesByType)
        ) {
            setCommissionTemplates(commissionTemplatesByTypeData.getCommissionTemplatesByType);
        }
    }, [commissionTemplatesByTypeData]);

    return [commissionTemplates, handleUpdateCommissionTemplate, handleDeleteCommissionTemplate, isLoading];
};
