import styled, { css } from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Dark } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

const containerStyles = css`
    width: 100%;
    max-width: 1366px;
`;

export const PageContainerWrapper = styled.div`
    height: 100%;
    margin: 16px auto 0;
    padding-bottom: 8px;

    ${containerStyles};
`;

export const PageContainerInnerWrapper = styled.div`
    height: 100%;
    padding: 32px;
`;

export const HeaderWrapper = styled.div`
    margin: 0 auto;

    ${containerStyles};

    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const PageTitle = styled.h1`
    color: ${Dark};
    ${Typography.XXL.Normal.Style};
`;

export const BreadCrumbsWrapper = styled.div`
    margin: 0 auto;

    ${containerStyles};
`;
