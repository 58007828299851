import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';

import { Wrapper } from './cell.styles';

export const StatusCell: FC<Instance<typeof CurrencyModelType>> = ({ isActive }) => (
    <Wrapper>{(isActive as boolean) ? 'active' : 'inactive'}</Wrapper>
);
