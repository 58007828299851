import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { ButtonWrapper, RegistrationLegalPersonTitle } from '@shared-component/registration/common.styles';
import { Scroll } from '@shared-component/scroll/scroll';
import { FormWrapper } from '@shared-form/form-common/form-common.styles';
import { LegalCompanyForm } from '@shared-form/legal-company-form/legal-company-form';
import { LegalCompanyFormValidation } from '@shared-form/legal-company-form/legal-company-form.validation';
import { UserCompanyInput } from '@shared-graphql';

import { useLegalClientCreate } from './legal-client-create.hook';
import { LegalClientCreateInnerWrapper, LegalClientCreateWrapper } from './legal-client-create.styles';

export const LegalClientCreatePage = observer(() => {
    const [initialData, onSubmit] = useLegalClientCreate();

    return (
        <LegalClientCreateWrapper>
            <Scroll>
                <LegalClientCreateInnerWrapper>
                    <RegistrationLegalPersonTitle>Information</RegistrationLegalPersonTitle>
                    <FormWrapper>
                        <Formik
                            initialValues={initialData as unknown as UserCompanyInput}
                            onSubmit={onSubmit}
                            validationSchema={LegalCompanyFormValidation}
                            validateOnChange={false}
                            enableReinitialize
                        >
                            <Form>
                                <LegalCompanyForm />
                                <ButtonWrapper>
                                    <ButtonPrimary isLarge type={ButtonTypeEnum.Submit}>
                                        Save
                                    </ButtonPrimary>
                                </ButtonWrapper>
                            </Form>
                        </Formik>
                    </FormWrapper>
                </LegalClientCreateInnerWrapper>
            </Scroll>
        </LegalClientCreateWrapper>
    );
});
