import { Badge } from '@shared-component/badge';
import { BadgeSizeEnum, BadgeTypeEnum } from '@shared-component/badge/types';
import { UserActivatedStatus } from '@shared-graphql';

import { Wrapper } from '@page/clients/clients-table/cells/cell.styles';
import { ClientCellType } from '@page/clients/clients-table/cells/cell.type';

const userStatusToBadgeTypeMapping = {
    [UserActivatedStatus.Activated]: BadgeTypeEnum.SUCCESS_FILLED,
    [UserActivatedStatus.Approved]: BadgeTypeEnum.SUCCESS_GHOST,
    [UserActivatedStatus.Compliance]: BadgeTypeEnum.WARNING_FILLED,
    [UserActivatedStatus.Created]: BadgeTypeEnum.WARNING_GHOST,
    [UserActivatedStatus.Updated]: BadgeTypeEnum.WARNING_GHOST,
    [UserActivatedStatus.UnsupportedCountry]: BadgeTypeEnum.WARNING_STROKE,
    [UserActivatedStatus.Rejected]: BadgeTypeEnum.ERROR_GHOST,
    [UserActivatedStatus.Blocked]: BadgeTypeEnum.ERROR_FILLED,
};

export const StatusCell = ({ status }: ClientCellType) => (
    <Wrapper>
        <Badge type={userStatusToBadgeTypeMapping[status]} size={BadgeSizeEnum.S} title={status} />
    </Wrapper>
);
