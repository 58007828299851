import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { DocumentFileUpload } from '@shared-component/file-upload/document-file-upload/document-file-upload';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { File } from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';

import {
    AttachFileDescription,
    AttachFileLabel,
    AttachFileLabelWrapper,
    AttachFileWrapper,
    ClipIcon,
} from './attach-file.styles';
import { useAttachFileLocalization } from './attach-files.localization-hook';

interface AttachFileProps {
    onUploaded: OnEventType<File>;
}

export const AttachFile = observer(({ onUploaded }: AttachFileProps) => {
    const [isUploadVisible, setUploadVisible] = useState(false);

    const { attachFileTitle, attachFileDescription } = useAttachFileLocalization();

    const handleFileUploadVisibility = (isVisible: boolean) => () => setUploadVisible(isVisible);

    const handleUploaded = (file: File) => {
        onUploaded(file);
        handleFileUploadVisibility(false)();
    };

    return (
        <FormRow>
            {!isUploadVisible && (
                <ButtonTransparent onClick={handleFileUploadVisibility(true)}>
                    <AttachFileWrapper>
                        <ClipIcon />
                        <AttachFileLabelWrapper>
                            <AttachFileLabel>{attachFileTitle}</AttachFileLabel>
                            <AttachFileDescription>{attachFileDescription}</AttachFileDescription>
                        </AttachFileLabelWrapper>
                    </AttachFileWrapper>
                </ButtonTransparent>
            )}

            {isUploadVisible && (
                <FormRow>
                    <DocumentFileUpload onUploaded={handleUploaded} />
                </FormRow>
            )}
        </FormRow>
    );
});
