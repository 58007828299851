import { Form, Formik } from 'formik';

import { FreeDesignModalTitle, ModalWrapper } from '@component/modal/admin-modal.styles';
import { AddServiceForm } from '@component/modal/modals/add-service/add-service-form/add-service-form';
import { AddServiceFormInitialValues } from '@component/modal/modals/add-service/add-service-form/add-service-form.enum.initial';
import { AddServiceFormValidation } from '@component/modal/modals/add-service/add-service-form/add-service-form.validation';
import { useAddServiceModal } from '@component/modal/modals/add-service/add-service.hook';

export const AddServiceModal = () => {
    const { serviceOptions, handleSubmit, onCancelClick } = useAddServiceModal();

    return (
        <ModalWrapper>
            <FreeDesignModalTitle>Add service</FreeDesignModalTitle>
            <Formik
                initialValues={AddServiceFormInitialValues}
                validationSchema={AddServiceFormValidation}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
            >
                <Form>
                    <AddServiceForm serviceOptions={serviceOptions} onCancelClick={onCancelClick} />
                </Form>
            </Formik>
        </ModalWrapper>
    );
};
