import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Dark } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const HeaderWrapper = styled.div`
    max-width: 1366px;
    width: 100%;
    margin: 0 auto;
    padding-left: 40px;

    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const CardsTitle = styled.h1`
    color: ${Dark};
    ${Typography.XXL.Normal.Style};
`;
