import { mutationAddComment, mutationAddFileComment } from '@queries/comment/comment.mutation';
import { applySnapshot, flow, getParent, Instance } from 'mobx-state-tree';

import { Comment, AdminGetCompanyDocuments, Query, UserDocument, UserDocumentStatus } from '@shared-graphql';
import { isString } from '@shared-util/is-data';

import { apolloClient } from '@connection/apollo-client';
import { CompanyModel } from '@model/company-model/company-model';
import { CompanyModelType } from '@model/company-model/company-model.type';

const getCompanyDocuments = (companyId: string, documentStatuses: UserDocumentStatus[]): Promise<UserDocument[]> =>
    apolloClient
        .query<Pick<Query, 'adminGetCompany'>>({
        query: AdminGetCompanyDocuments,
        variables: { companyId, documentStatuses },
    })
        .then(result => result.data?.adminGetCompany?.documents ?? []);

type CompanyPersonRelationActionsType = Instance<typeof CompanyModelType>;

export const companyDocumentsActions = (self: CompanyPersonRelationActionsType) => ({
    loadDocuments: flow(function* loadDocuments(
        companyId: string,
        documentStatus: UserDocumentStatus[] = [UserDocumentStatus.Uploaded]
    ) {
        const companyModel = getParent(self) as Instance<typeof CompanyModel>;

        if (companyModel.loading.isCompanyPersonsLoading) {
            return;
        }

        companyModel.setCompanyDocumentsLoading(true);

        try {
            const result = yield getCompanyDocuments(companyId, documentStatus);

            applySnapshot(self.documents, result);
        } catch (error) {
            throw new Error(error as string);
        } finally {
            companyModel.setCompanyDocumentsLoading(false);
        }
    }),
    addDocumentComments: (initialDocuments: UserDocument[], documents: UserDocument[]) => {
        const prevDocuments = new Map(initialDocuments.map((document: any) => [document.file.key, document]));

        const comments = documents.map((document: any) => {
            const { comment: prevComment } = prevDocuments.get(document.file.key)?.file.comment ?? {};
            const newComment = document.file.comment?.comment;

            if (isString(newComment) && newComment !== prevComment) {
                return mutationAddComment((document.file.comment as Comment).comment).then(comment =>
                    mutationAddFileComment(document.file.key, comment.id)
                );
            }

            return true;
        }) as Promise<any>[];

        return Promise.all(comments);
    },
});
