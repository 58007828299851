import { AmountCellWrapper } from '@shared-component/transaction-cells/amount-cell/amount-cell.styles';
import { getOperationSymbol } from '@shared-component/transaction-cells/amount-cell/amount-cell.utils';
import { Currency, OperationType, Transaction } from '@shared-graphql';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';

export const AmountCell = ({ total, currency, operation }: Transaction) => {
    const amount = useCurrencyFormat(total, (currency as Currency).code, true);

    return (
        <AmountCellWrapper hasFill={operation === OperationType.Credit}>{`${getOperationSymbol(
            operation
        )}${amount}`}</AmountCellWrapper>
    );
};
