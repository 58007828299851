import { FeatureStatus } from '@shared-graphql';

import { ClientServicesListItem } from '@page/common/client-services/client-services-list/client-services-list-item/client-services-list-item';
import { ClientServicesListInterface } from '@page/common/client-services/client-services-list/client-services-list.interface';
import { ClientServicesListWrapper } from '@page/common/client-services/client-services-list/client-services-list.styles';

export const ClientServicesList = ({
    featureTags,
    onRejectFeatureTag,
    onNextStatusFeatureTag,
    onSetRequiredFields,
    isRejectFeatureTagLoading,
    isNextStatusFeatureTagLoading,
}: ClientServicesListInterface) => (
    <ClientServicesListWrapper>
        {featureTags.map(featureTag => (
            <ClientServicesListItem
                key={featureTag?.featureTag}
                featureTag={featureTag as FeatureStatus}
                onRejectFeatureTag={onRejectFeatureTag}
                onNextStatusFeatureTag={onNextStatusFeatureTag}
                onSetRequiredFields={onSetRequiredFields}
                isRejectFeatureTagLoading={isRejectFeatureTagLoading}
                isNextStatusFeatureTagLoading={isNextStatusFeatureTagLoading}
            />
        ))}
    </ClientServicesListWrapper>
);
