import { useMutation } from '@apollo/client';
import { useContext, useEffect, useMemo } from 'react';
import { object, string } from 'yup';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal-context';
import {
    AdminSetFeatureTagData,
    DefaultAnswer,
    FeatureTagRequiredDataField,
    Mutation,
    UserFeatureTag,
} from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { isExist, isFalse, isTrue } from '@shared-util/is-data';

export const useAddServiceRequiredFieldsModal = () => {
    const { params } = useCurrentNavigation();
    const { onOpen } = useContext(InformationModalContext);
    const { props, onClose } = useContext(ModelContext);
    const requiredFields = useMemo(
        () => props?.componentProps?.featureTag.requiredFields as FeatureTagRequiredDataField[],
        [props?.componentProps?.requiredFields]
    );
    const clientId = useMemo(() => (isTrue(params.userId) ? params.userId : params.companyId), [params]);
    const userFeatureTag = useMemo(
        () => props?.componentProps?.featureTag.featureTag as UserFeatureTag,
        [props?.componentProps?.userFeaturetag]
    );

    const AddServiceRequiredFieldsInitial = useMemo(
        () =>
            requiredFields.reduce((acc: any, { name, value }) => {
                acc[name] = value ?? '';
                return acc;
            }, {}),
        [requiredFields]
    );

    const AddServiceRequiredFieldsValidation = useMemo(
        () =>
            object().shape(
                requiredFields.reduce((acc: any, { name, required }) => {
                    if (isTrue(required)) {
                        acc[name] = string().required();
                    }
                    return acc;
                }, {})
            ),
        [requiredFields]
    );

    const [adminSetFeatureTagData, { data: setFeatureTagData, error: setFeatureTagDError }] =
        useMutation<Pick<Mutation, 'adminSetFeatureTagData'>>(AdminSetFeatureTagData);

    const handleSubmit = (values: any) => {
        adminSetFeatureTagData({
            variables: {
                userFeatureTag,
                clientId,
                requiredData: { fields: Object.entries(values).map(([name, value]) => ({ name, value })) },
            },
        });
    };

    useEffect(() => {
        if (isTrue((setFeatureTagData?.adminSetFeatureTagData as DefaultAnswer)?.status)) {
            onOpen(InformationModalEnum.SuccessInformationModal, {
                text: 'Required fields have been successfully added',
            });
            props?.componentProps?.adminGetClientFeatureTags();
            onClose();
        }
        if (isExist((setFeatureTagData?.adminSetFeatureTagData as DefaultAnswer)?.status)) {
            if (isFalse((setFeatureTagData?.adminSetFeatureTagData as DefaultAnswer)?.status)) {
                onOpen(InformationModalEnum.FailureInformationModal, { text: 'Something went wrong' });
                onClose();
            }
        }
    }, [setFeatureTagData]);

    useEffect(() => {
        if (isTrue(setFeatureTagDError)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: setFeatureTagDError?.message ?? 'Something went wrong',
            });

            onClose();
        }
    }, [setFeatureTagDError]);

    const onCancelClick = () => {
        onClose();
    };

    return {
        requiredFields,
        onCancelClick,
        onSubmit: handleSubmit,
        validations: AddServiceRequiredFieldsValidation,
        initialValues: AddServiceRequiredFieldsInitial,
    };
};
