import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import ChevronDown from '@shared-component/input/assets/chevron-bottom.svg?react';
import { White, Neutral200 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { isTrue } from '@shared-util/is-data';

interface IsSelectVisibleInterface {
    isSelectVisible: boolean;
}
interface SelectPreviewDataInterface {
    clickable?: boolean;
}

export const Wrapper = styled.div`
    position: relative;
    ${Flex.VerticalCenter.Style};
`;
export const SelectPreview = styled.div`
    ${Flex.VerticalCenter.Style};
    ${Typography.Paragraph14.Medium.Neutral600.Style};
    gap: 4px;
    padding: 8px;
`;
export const SelectPreviewTitle = styled.div`
    ${Typography.Paragraph14.SemiBold.Neutral900.Style};
    margin-right: 4px;
    cursor: default;
    white-space: nowrap;
`;
export const SelectPreviewData = styled.div<SelectPreviewDataInterface>`
    ${Flex.VerticalCenter.Style};
    gap: 4px;
    cursor: ${({ clickable = true }) => (clickable ? 'pointer' : 'default')};
`;

export const SelectWrapper = styled.div<IsSelectVisibleInterface>`
    display: ${({ isSelectVisible }) => (isSelectVisible ? 'inline-flex' : 'none')};
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    min-width: 300px;
`;
export const ChevronDownIcon = styled(ChevronDown)<IsSelectVisibleInterface>`
    transform: rotate(${({ isSelectVisible }) => (isSelectVisible ? '180deg' : '0deg')});
`;
export const containerStyle = (provided: any) => ({
    ...provided,
    zIndex: 1,
    width: '100%',
    minWidth: '300px',
    borderRadius: '8px',
    border: `1px solid ${Neutral200}`,
    background: `${White}`,
    boxShadow:
        '0px 5px 5px 0px rgba(53, 53, 53, 0.09), 0px 11px 7px 0px rgba(53, 53, 53, 0.05), 0px 20px 8px 0px rgba(53, 53, 53, 0.01)',
});
export const controlStyle = (provided: any) => ({
    ...provided,
    border: 'none',
    outline: 'none',
    boxShadow: 'none',
    borderRadius: '8px 8px 0 0',
});
export const indicatorSeparatorStyle = (provided: any) => ({
    ...provided,
    display: 'none',
});
export const indicatorsContainerStyle = (provided: any) => ({
    ...provided,
    display: 'none',
});
export const selectMenuStyle = (provided: any) => ({
    ...provided,
    marginTop: '0',
    borderRadius: '0 0 8px 8px ',
    padding: '12px 8px',
    outline: 'none',
    border: `1px solid ${Neutral200}`,
    boxShadow:
        '0px 5px 5px 0px rgba(53, 53, 53, 0.09), 0px 11px 7px 0px rgba(53, 53, 53, 0.05), 0px 20px 8px 0px rgba(53, 53, 53, 0.01)',
    width: 'calc(100% + 2px)',
    marginLeft: '-1px',
});
export const singleValueStyle = (provided: any) => ({
    ...provided,
    ...Typography.Paragraph14.Medium.Neutral600.Object,
    lineHeight: '16px',
    cursor: 'text',
});
export const placeholderStyle = (provided: any) => ({
    ...provided,
    ...Typography.Paragraph14.Medium.Neutral500.Object,
    lineHeight: '16px',
});
export const inputStyle = (provided: any) => ({
    ...provided,
    ...Typography.Paragraph14.Medium.Neutral600.Object,
    lineHeight: '16px',
});
export const noOptionsMessageStyle = (provided: any) => ({
    ...provided,
    ...Typography.S.Normal.Object,
});
export const optionStyle = (provided: any, { isFocused, isSelected }: { isFocused: boolean; isSelected: boolean }) => {
    const backgroundColor = isFocused ? 'rgba(189, 232, 238, 0.18)' : `transparent`;
    return {
        ...provided,
        ...Typography.Paragraph14.Medium.Neutral900.Object,
        borderRadius: '8px',
        lineHeight: '16px',
        background: isTrue(isSelected) ? 'rgba(189, 232, 238, 0.48)' : backgroundColor,
    };
};
