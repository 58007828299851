import { TableHeaderCell, TableHeader, TableRow } from './table-head.styles';

export const TableHead = ({ headerGroups }: any) => (
    <TableHeader>
        {headerGroups?.map((headerGroup: any, index: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column: any, key: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <TableHeaderCell {...column.getHeaderProps()} key={`${index}_${key}`}>
                        {column.render('Header')}
                    </TableHeaderCell>
                ))}
            </TableRow>
        ))}
    </TableHeader>
);
