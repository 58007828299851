import { useContext, useRef, useState } from 'react';

import { ModelContext } from '@shared-component/modal/modal-context';
import { LegalCompanyInterface } from '@shared-component/registration/registration-legal-person-legal/registration-legal-person-legal-company/registration-legal-person-legal-company.interface';
import {
    LegalAddressCompanyFormValidation,
    PostalAddressCompanyFormValidation,
} from '@shared-form/address-form/address-form.validation';
import { LegalCompanyFormValidation } from '@shared-form/legal-company-form/legal-company-form.validation';
import { CompanyAddressInput } from '@shared-graphql';
import { isExist } from '@shared-util/is-data';

import { useCompanySelector } from '@selector/company/use-company.selector';

import { initialRegistrationLegalPersonLegal } from './registration-legal-person-legal.initial';

const DIGIT_REAL_PERCENT = 100;
const DIGIT_TO_PERCENT = 1 / DIGIT_REAL_PERCENT;

export const useRegistrationLegalPersonLegal = () => {
    const {
        props: { componentProps },
    } = useContext(ModelContext);
    const [{ persons }] = useCompanySelector();
    const currentCompany = persons.get(componentProps?.id ?? '');
    const [legal, setCompany] = useState<LegalCompanyInterface>(
        (isExist(currentCompany?.legal)
            ? {
                ...currentCompany?.legal,
                ownershipPercent: (currentCompany?.relation.ownershipPercent ?? 0) * DIGIT_TO_PERCENT,
            }
            : initialRegistrationLegalPersonLegal) as unknown as LegalCompanyInterface
    );

    const closeModalRef = useRef(false);

    const handleUpdateCompany = (data: Partial<CompanyAddressInput>) => {
        closeModalRef.current = false;
        const saveData = {
            ...legal,
            ...data,
        };
        setCompany({
            ...saveData,
            ownershipPercent: parseFloat(`${saveData.ownershipPercent ?? 0}`),
        });
        closeModalRef.current = true;
    };

    const status = {
        hasCompany: LegalCompanyFormValidation.isValidSync(legal),
        hasAddress: (Boolean(legal.isCompanyAddressSame) === true
            ? LegalAddressCompanyFormValidation
            : PostalAddressCompanyFormValidation
        ).isValidSync(legal),
    };

    return {
        status,
        company: legal,
        handleUpdateCompany,
    };
};
