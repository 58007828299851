import { types } from 'mobx-state-tree';

import { CommentModel } from '@shared-model/comment-model/comment-model';

export const FileModel = types.model('FileModel', {
    filename: types.string,
    path: types.string,
    key: types.identifier,
    comment: types.maybeNull(CommentModel),
    comments: types.optional(types.array(CommentModel), []),
});
