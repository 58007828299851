import styled from 'styled-components';

import { DarkBlue } from '@shared-style/colors';

import Location from './assets/location.svg?react';
import Other from './assets/other-document.svg?react';
import Passport from './assets/passport.svg?react';
import Selfie from './assets/selfie.svg?react';

export const PassportIcon = styled(Passport)`
    fill: ${DarkBlue};
`;

export const LocationIcon = styled(Location)`
    fill: ${DarkBlue};
`;

export const SelfieIcon = styled(Selfie)`
    fill: ${DarkBlue};
`;

export const OtherIcon = styled(Other)`
    fill: ${DarkBlue};
`;
