import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FormEvent, FC, SVGProps, RefObject, useState } from 'react';

import { FieldError } from '@shared-component/field-error/field-error';
import { InputTagEnum } from '@shared-component/input/input-tag.enum';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';
import {
    CustomLabel,
    CustomInputStyle,
    CustomInputWrapperStyle,
    CustomInputWrapper,
} from '@shared-component/input/input.styles';
import { OnEventType } from '@shared-type/on-event.type';
import { isTrue } from '@shared-util/is-data';

export interface InputProps extends Partial<Pick<HTMLInputElement, 'type' | 'tabIndex' | 'disabled'>> {
    title: string;
    name: string;
    onFocus?: OnEventType<boolean>;
    icon?: FC<SVGProps<SVGSVGElement>>;
    inputRef?: RefObject<HTMLInputElement>;
    tag?: InputTagEnum;
    placeholder?: string;
    isError?: string | undefined;
    autofocus?: boolean | undefined;
    children?: React.ReactNode;
}

export const CustomInput: FC<InputProps> = observer(
    ({
        title,
        name,
        type = InputTypeEnum.Text,
        icon,
        tag,
        inputRef,
        onFocus,
        disabled,
        children,
        placeholder,
        isError,
        autofocus,
        ...props
    }) => {
        const [field, { error }, { setError, setValue }] = useField(name);
        const [isFocused, setFocused] = useState(Boolean(autofocus));
        const hasIcon = icon !== undefined;
        const hasError = isTrue(isError) ? isError : isTrue(error?.trim());

        const toggleFocus = (isFocus: boolean) => () => {
            setFocused(isFocus);
            if (onFocus instanceof Function) {
                onFocus(isFocus);
            }
        };
        const handleBlur = () => {
            toggleFocus(false)();
            if (type === InputTypeEnum.Text) {
                setValue(field.value?.trim() ?? null);
            }
        };

        const handleChange = ({ currentTarget: { value } }: FormEvent<HTMLInputElement>) => {
            setValue(type === InputTypeEnum.Number && value !== '' ? parseFloat(value) : value);
            if (Boolean(hasError) && tag !== InputTagEnum.TextArea) {
                setError(undefined);
            }
        };

        return (
            <CustomInputWrapper>
                <CustomLabel>{title}</CustomLabel>
                <CustomInputWrapperStyle name={name} hasError={Boolean(hasError)} isFocused={isFocused}>
                    {children}
                    <CustomInputStyle
                        {...props}
                        {...field}
                        ref={inputRef}
                        type={type}
                        value={field.value ?? ''}
                        disabled={disabled}
                        hasIcon={hasIcon}
                        placeholder={placeholder}
                        onFocus={toggleFocus(true)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        id="input"
                        autoFocus={isFocused}
                    />
                </CustomInputWrapperStyle>
                <FieldError name={name} />
            </CustomInputWrapper>
        );
    }
);
