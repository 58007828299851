import { rgba } from 'polished';
import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { White, Fiord } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const PasswordDropdownWrapper = styled.div`
    position: absolute;
    background-color: ${White};
    border-radius: 4px;
    box-shadow: 0 0 10px ${rgba(Fiord, 0.12)};
    z-index: 1;
    padding: 24px;
    bottom: -8px;
    transform: translateY(100%);
`;

export const DropdownItem = styled.div`
    ${Flex.SpaceBetween.Style};
    margin-top: 16px;

    :first-child {
        margin-top: 0;
    }
`;

export const IconWrapper = styled.div`
    ${Flex.Style};
    padding-left: 16px;
`;

export const Label = styled.span`
    ${Typography.S.Style}
`;
