import { useField } from 'formik';
import { useEffect } from 'react';

import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { getDate } from '@shared-util/get-date';
import { isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { TransactionsTableFilterFormEnum } from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter-form/transactions-table-filter-form.enum';
import { FilterFormInterface } from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter-form/transactions-table-filter-form.interface';
import { TransactionsTableEnum } from '@page/transactions/transactions-table/transactions-table.enum';

export const useTransactionsTableFilterForm = ({
    // onStatusChange,
    // onPaymentTypeChange,
    onCreatedFromChange,
    onCreatedToChange,
    onElcoinStatusChange,
}: Omit<FilterFormInterface, 'transactionsCount'>) => {
    const navigate = useRouterNavigate();
    const { searchParams: params } = useCurrentNavigation();

    const {
        // status,
        // paymentType,
        createdFrom,
        createdTo,
        elcoinStatus,
    } = params;

    // const [{ value: statusValue }] = useField(TransactionsTableFilterFormEnum.Status);
    // const [{ value: paymentTypeValue }] = useField(TransactionsTableFilterFormEnum.PaymentType);
    const [{ value: createdFromValue }] = useField(TransactionsTableFilterFormEnum.CreatedFrom);
    const [{ value: createdToValue }] = useField(TransactionsTableFilterFormEnum.CreatedTo);
    const [{ value: elcoinStatusValue }] = useField(TransactionsTableFilterFormEnum.ElcoinStatus);

    // useEffect(
    //     () =>
    //         void (
    //             statusValue !== undefined &&
    //             navigate(PageEnum.Transactions, {
    //                 ...params,
    //                 status: statusValue === null ? TransactionsTableEnum.All : statusValue,
    //             })
    //         ),
    //     [statusValue]
    // );

    useEffect(
        () =>
            void (
                elcoinStatusValue !== undefined &&
                navigate(PageEnum.Transactions, {
                    ...params,
                    elcoinStatus: elcoinStatusValue === null ? TransactionsTableEnum.All : elcoinStatusValue,
                })
            ),
        [elcoinStatusValue]
    );
    //
    // useEffect(
    //     () =>
    //         void (
    //             paymentTypeValue !== undefined &&
    //             navigate(PageEnum.Transactions, {
    //                 ...params,
    //                 paymentType: paymentTypeValue === null ? TransactionsTableEnum.All : paymentTypeValue,
    //             })
    //         ),
    //     [paymentTypeValue]
    // );

    useEffect(() => {
        if (createdFromValue !== undefined) {
            navigate(PageEnum.Transactions, {
                ...params,
                createdFrom: isTrue(createdFromValue) ? getDate(createdFromValue, 'dd-MM-yyyy') : '',
            });
        }
    }, [createdFromValue]);

    useEffect(() => {
        if (createdToValue !== undefined) {
            navigate(PageEnum.Transactions, {
                ...params,
                createdTo: isTrue(createdToValue) ? getDate(createdToValue, 'dd-MM-yyyy') : '',
            });
        }
    }, [createdToValue]);

    // useEffect(
    //     () => void onStatusChange(status !== undefined && status !== TransactionsTableEnum.All ? status : ''),
    //     [status]
    // );
    useEffect(
        () =>
            void onElcoinStatusChange(
                elcoinStatus !== undefined && elcoinStatus !== TransactionsTableEnum.All
                    ? elcoinStatus
                    : TransactionsTableEnum.All
            ),
        [elcoinStatus]
    );
    // useEffect(
    //     () =>
    //         void onPaymentTypeChange(
    //             paymentType !== undefined && paymentType !== TransactionsTableEnum.All ? paymentType : ''
    //         ),
    //     [paymentType]
    // );
    useEffect(() => {
        void onCreatedFromChange(createdFrom !== undefined && isTrue(createdFrom) ? createdFrom : '');
    }, [createdFrom]);

    useEffect(() => {
        void onCreatedToChange(createdTo !== undefined && isTrue(createdTo) ? createdTo : '');
    }, [createdTo]);
};
