import { Form, Formik } from 'formik';
import { FC } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { UserCredentialsStepForm as UserCredentialsStepFormFields } from '@shared-component/forms/user-credentials-step-form/user-credentials-step-form';
import { FormButtonWrapper, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { UserCredentialsStepFormInterface } from '@shared-form/user-credentials-step-form/user-credentials-step-form.interface';
import { UserCredentialsStepFormSchema } from '@shared-form/user-credentials-step-form/user-credentials-step-form.validation';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';

import { initialsUserCredentialsStepForm } from './user-credentials-step-form.initials';

export interface UserCredentialsStepFormProps {
    onSubmit: OnEventType<UserCredentialsStepFormInterface>;
    error?: string;
}

export const UserCredentialsStepForm: FC<UserCredentialsStepFormProps> = ({ onSubmit, error }) => (
    <Formik
        initialValues={initialsUserCredentialsStepForm}
        validationSchema={UserCredentialsStepFormSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
    >
        <Form>
            <FormWrapper>
                <UserCredentialsStepFormFields error={error} />
                <FormButtonWrapper>
                    <ButtonPrimary type={ButtonTypeEnum.Submit} isFull>
                        <Translate langKey={LocalizationEnum.LoginFormTitle} />
                    </ButtonPrimary>
                </FormButtonWrapper>
            </FormWrapper>
        </Form>
    </Formik>
);
