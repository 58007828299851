import { ReactNode, useContext } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalWrapper } from '@shared-component/information-modal/information-modal.styles';

export const InformationModal = ({ children }: { children?: ReactNode | ReactNode[] | JSX.Element }) => {
    const { isInformationModalVisible } = useContext(InformationModalContext);

    return <InformationModalWrapper isVisible={isInformationModalVisible}>{children}</InformationModalWrapper>;
};
