import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { NileBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const FormWrapper = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const FormRow = styled.div`
    width: 100%;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : NileBlue)};

    :not(:first-child) {
        margin-top: 16px;
    }
`;

export const FormRowTitle = styled.p`
    width: 100%;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : NileBlue)};

    :not(:first-child) {
        margin-top: 24px;
    }

    ${Typography.L.Medium.Style};
    @media (max-width: 560px) {
        font-size: 16px;
    }
`;

export const FormHalfRow = styled(FormRow)`
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: 768px) {
        grid-template-columns: unset;
    }
`;

export const FormButtonWrapper = styled.div`
    width: 100%;
    margin-top: 24px;
`;

export const FormButtonsRowWrapper = styled(FormButtonWrapper)`
    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const FieldColumnWrapper = styled.div`
    ${Flex.Column.Style}
`;
