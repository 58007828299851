import styled from 'styled-components';

import { White } from '@shared-style/colors';

export const AccountsPageBlockWrapper = styled.div`
    width: 100%;
    height: 100%;
    border-radius: 0 0 16px 16px;
    background-color: ${White};
`;
