import { flow, getParent, Instance } from 'mobx-state-tree';

import { AdminGetCompanyService, Query } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { CompanyModel } from '@model/company-model/company-model';
import { CompanyModelType } from '@model/company-model/company-model.type';

import { companyApplyActions } from './company-apply.actions ';

interface CompanyAddressActionsInterface
    extends Instance<typeof CompanyModelType>,
    ReturnType<typeof companyApplyActions> {}

const queryCompanyService = (companyId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetCompany'>>({ query: AdminGetCompanyService, variables: { companyId } })
        .then(result => result.data?.adminGetCompany);

export const companyServiceActions = (self: CompanyAddressActionsInterface) => ({
    loadCompanyService: flow(function* loadCompanyService(companyId: string) {
        const companyModel = getParent(self) as Instance<typeof CompanyModel>;

        if (companyModel.loading.isCompanyServiceLoading) {
            return;
        }

        companyModel.setCompanyServiceLoading(true);

        try {
            const result = yield queryCompanyService(companyId);

            self.companyService = result.companyService;
        } catch (error) {
            throw new Error(error as string);
        } finally {
            companyModel.setCompanyServiceLoading(false);
        }
    }),
});
