import { Link } from '@shared-atom/link/link';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PageEnum } from '@enum/page.enum';

interface AcceptHeaderProps {
    hasAcceptAgreement: boolean;
    phone: string;
    link: string | PageEnum;
    isDisableLink?: boolean;
}

export const AcceptHeader = ({ hasAcceptAgreement, phone, link, isDisableLink }: AcceptHeaderProps) => (
    <>
        {!hasAcceptAgreement && (
            <>
                <Translate langKey={LocalizationEnum.PrivateAcceptAgreementPageDescription1} />
                {isDisableLink === true && (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to={{ pathname: link }}>
                            <Translate langKey={LocalizationEnum.PrivateAcceptAgreementPageDescription2} />
                        </Link>

                        <Translate langKey={LocalizationEnum.PrivateAcceptAgreementPageDescription3} />
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link to={{ pathname: link }}>
                            <Translate langKey={LocalizationEnum.PrivateAcceptAgreementPageDescription4} />
                        </Link>
                    </>
                )}
            </>
        )}
        {hasAcceptAgreement && (
            <Translate
                langKey={[
                    LocalizationEnum.PrivateAcceptAgreementPageDescription5,
                    phone,
                    LocalizationEnum.PrivateAcceptAgreementPageDescription6,
                ]}
            />
        )}
    </>
);
