import { useStore } from '@store/store-context';

export const useAccountActionsSelector = () => {
    const {
        setAccountLoading,
        account: {
            loadClientAccounts,
            // updateAccount,
            clearClientAccounts,
            updateAccountCurrencies,
            setSelectedAccount,
        },
    } = useStore().account;

    return {
        // updateAccount,
        setAccountLoading,
        loadClientAccounts,
        clearClientAccounts,
        updateAccountCurrencies,
        setSelectedAccount,
    };
};
