import { combine } from 'flexible-chain';
import styleToCss from 'style-object-to-css-string';
import styled, { CSSObject } from 'styled-components';

import { typographyPositions } from '@shared-atom/typography/typography.positions';

import { typographyColors } from './typography.colors';
import { typographyOverflow } from './typography.oveflow';
import { typographySizes } from './typography.sizes';
import { typographyWeight } from './typography.weight';

const initialStyles = {
    ...typographySizes,
    ...typographyWeight,
    ...typographyPositions,
    ...typographyOverflow,
    ...typographyColors,
};

const defaultStyles = {
    // fontFamily: '"Geometria"',
    letterSpacing: '0.02em',
};

const componentResult = {
    Text: (props: CSSObject) => styled.span`
        ${styleToCss({ ...defaultStyles, ...props })}
    `,
    Paragraph: (props: CSSObject) => styled.p`
        ${styleToCss({ ...defaultStyles, ...props })}
    `,
    H1: (props: CSSObject) => styled.h1`
        ${styleToCss({ ...defaultStyles, ...props })}
    `,
    H2: (props: CSSObject) => styled.h2`
        ${styleToCss({ ...defaultStyles, ...props })}
    `,
    H3: (props: CSSObject) => styled.h3`
        ${styleToCss({ ...defaultStyles, ...props })}
    `,
    H4: (props: CSSObject) => styled.h4`
        ${styleToCss({ ...defaultStyles, ...props })}
    `,
    H5: (props: CSSObject) => styled.h5`
        ${styleToCss({ ...defaultStyles, ...props })}
    `,
    H6: (props: CSSObject) => styled.h6`
        ${styleToCss({ ...defaultStyles, ...props })}
    `,
    Style: (props: CSSObject) => styleToCss({ ...defaultStyles, ...props }),
    Object: (props: CSSObject) => ({ ...defaultStyles, ...props }),
};

export const Typography = combine(initialStyles, componentResult);
