import { Form, Formik } from 'formik';

import { FreeDesignModalTitle, ModalWrapper } from '@component/modal/admin-modal.styles';
import { AddServiceRequiredFieldsForm } from '@component/modal/modals/add-service-required-fields/add-service-required-fields-form/add-service-required-fields-form';
import { useAddServiceRequiredFieldsModal } from '@component/modal/modals/add-service-required-fields/add-service-required-fields.hook';

export const AddServiceRequiredFieldsModal = () => {
    const { requiredFields, onCancelClick, onSubmit, validations, initialValues } = useAddServiceRequiredFieldsModal();

    return (
        <ModalWrapper>
            <FreeDesignModalTitle>Add service</FreeDesignModalTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={validations}
                onSubmit={onSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
            >
                <Form>
                    <AddServiceRequiredFieldsForm requiredFields={requiredFields} onCancelClick={onCancelClick} />
                </Form>
            </Formik>
        </ModalWrapper>
    );
};
