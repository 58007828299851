import { createContext, useState } from 'react';

import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';
import { OnEventEmptyType } from '@shared-type/on-event.type';
import { noop } from '@shared-util/noop';

export const MenuOpenContext = createContext<[boolean, OnEventEmptyType]>([false, noop]);

export const useNavigationMenu = (wd: number): [boolean, OnEventEmptyType] => {
    const isMob = wd <= MAX_MOBILE_SIZE;
    const [isMenuOpen, setMenuOpen] = useState<boolean>(isMob);

    const handleToggleMenuOpen = () => setMenuOpen((prevState: boolean) => (isMob ? false : !prevState));

    return [isMenuOpen, handleToggleMenuOpen];
};
