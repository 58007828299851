import { Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { PageContainerWrapper } from '@page/common/common.styles';

import { CardsChoose } from './cards-choose/cards-choose';
import { CardsHeader } from './cards-header/cards-header';
import { CardsTable } from './cards-table/cards-table';

interface CardFormInterface {
    userId: string;
    accountId: string;
}

export const CardsPage = observer(() => (
    <>
        <CardsHeader />
        <Formik initialValues={{ userId: '', accountId: '' } as CardFormInterface} onSubmit={console.log}>
            <>
                <CardsChoose />
                <PageContainerWrapper>
                    <CardsTable />
                </PageContainerWrapper>
            </>
        </Formik>
    </>
));
