import { UserActivatedStatus } from '@shared-graphql';

import { VerificationRejectionTitleEnum } from '@page/common/client-summary/client-summary.enum';

export const getVerificationRejectionTitle = (status: UserActivatedStatus) => {
    switch (status) {
        case UserActivatedStatus.Approved:
            return VerificationRejectionTitleEnum.Verification;
        case UserActivatedStatus.Blocked:
            return VerificationRejectionTitleEnum.Rejection;
        default:
            return null;
    }
};
