import { useField } from 'formik';
import { FC } from 'react';

import { Input } from '@shared-component/input/input';
import { Select } from '@shared-component/select/select';
import { BaseOptionInterface } from '@shared-component/select/select.props';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { UserType } from '@shared-graphql';

import { CreateClientFormEnum } from './create-client-form.enum';
import { clientTypeValues } from './create-client-form.options';

interface CreateClientFormProps {
    users: BaseOptionInterface[];
}

export const CreateClientForm: FC<CreateClientFormProps> = ({ users }) => {
    const [{ value: type }] = useField(CreateClientFormEnum.Type);

    const shouldRenderUsersSelect = type === UserType.Legal;

    return (
        <>
            <Input title="Email" name={CreateClientFormEnum.Email} />
            <FormRow>
                <Select title="Client type" name={CreateClientFormEnum.Type} options={clientTypeValues} />
            </FormRow>
            {shouldRenderUsersSelect && (
                <FormRow>
                    <Select title="Users" name={CreateClientFormEnum.UserId} options={users} />
                </FormRow>
            )}
        </>
    );
};
