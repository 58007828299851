import { getDate, getMonth, getYear, isValid } from 'date-fns';

import { LangKeysEnum } from '@shared-store/store-localization.interface';

export const DateFormatMap = {
    [LangKeysEnum.EN]: 'dd.MM.yyyy',
    // [LangKeysEnum.RU]: 'dd-MM-yyyy',
    [LangKeysEnum.UK]: 'dd/MM/yyyy',
};

export const DateFormatWithTime = {
    [LangKeysEnum.EN]: `${DateFormatMap[LangKeysEnum.EN]} - HH:mm`,
    // [LangKeysEnum.RU]: `${DateFormatMap[LangKeysEnum.RU]} - HH:mm`,
    [LangKeysEnum.UK]: `${DateFormatMap[LangKeysEnum.UK]} - HH:mm`,
};

const makeRegMask = (format: string) => format.split('').map(val => (/[a-z]/i.test(val) ? /\d/ : val));

export const DateLocaleMaskFormatMap = {
    [LangKeysEnum.EN]: makeRegMask(DateFormatMap[LangKeysEnum.EN]),
    // [LangKeysEnum.RU]: makeRegMask(DateFormatMap[LangKeysEnum.RU]),
    [LangKeysEnum.UK]: makeRegMask(DateFormatMap[LangKeysEnum.UK]),
};

export const getParseDate = (value?: string | null): Date => {
    const currentDateValue = new Date(value ?? '');

    if (isValid(new Date(currentDateValue)) && currentDateValue.toISOString() === value) {
        return currentDateValue;
    }

    const currentDate = new Date();
    let [day = getDate(currentDate), month = getMonth(currentDate) + 1, year = getYear(currentDate)] =
        (typeof value === 'string' ? value : '').match(/\d+/gi) ?? [];

    day = Math.max(Math.min(parseInt(day as string, 10), 31), 1);
    month = Math.max(Math.min(parseInt(month as string, 10), 12), 1) - 1;
    year = parseInt(year as string, 10);

    return new Date(year, month, day);
};
