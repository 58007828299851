import { FC } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { LoginFormTitle, LoginContentDescriptionText } from '@page/login/login-steps/common.styles';

import {
    UserCredentialsStepForm,
    UserCredentialsStepFormProps,
} from './user-credentials-step-form/user-credentials-step-form';

const loginTitle = [LocalizationEnum.LoginFormWelcome, '👋'];

export const UserCredentialsStep: FC<UserCredentialsStepFormProps> = ({ onSubmit, error }) => (
    <>
        <LoginFormTitle>
            <Translate langKey={loginTitle} />
        </LoginFormTitle>
        <LoginContentDescriptionText>Enter your details below</LoginContentDescriptionText>
        <UserCredentialsStepForm onSubmit={onSubmit} error={error} />
    </>
);
