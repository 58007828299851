import { useFilterByOperationType } from '@page/transactions/transactions-table/transactions-table-filter/filter-by-operation-type/filter-by-operation-type.hook';
import { FilterByOperationInterface } from '@page/transactions/transactions-table/transactions-table-filter/filter-by-operation-type/filter-by-operation-type.interface';
import {
    FilterByOperationTypeButton,
    FilterByOperationTypeContainer,
} from '@page/transactions/transactions-table/transactions-table-filter/filter-by-operation-type/filter-by-operation-type.styles';
import { OperationTypeEnum } from '@page/transactions/transactions-table/transactions-table.enum';

export const FilterByOperationType = ({ operationTypeFilter, onOperationTypeChange }: FilterByOperationInterface) => {
    useFilterByOperationType({ operationTypeFilter, onOperationTypeChange });

    return (
        <FilterByOperationTypeContainer>
            <FilterByOperationTypeButton
                isActive={operationTypeFilter === OperationTypeEnum.Inward}
                onClick={() => onOperationTypeChange(OperationTypeEnum.Inward)}
            >
                Incoming
            </FilterByOperationTypeButton>
            <FilterByOperationTypeButton
                isActive={operationTypeFilter === OperationTypeEnum.Outward}
                onClick={() => onOperationTypeChange(OperationTypeEnum.Outward)}
            >
                Outward
            </FilterByOperationTypeButton>
            <FilterByOperationTypeButton
                isActive={operationTypeFilter === OperationTypeEnum.Internal}
                onClick={() => onOperationTypeChange(OperationTypeEnum.Internal)}
            >
                Internal
            </FilterByOperationTypeButton>
        </FilterByOperationTypeContainer>
    );
};
