import { Address } from '@shared-graphql';

export const initialAddress: Address = {
    id: null,
    street: '',
    zip: '',
    city: '',
    country: '',
    building: '',
};
