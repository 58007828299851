import { useMemo, useState } from 'react';

import { Maybe } from '@shared-graphql';

export const useElcoinStatusFilter = <T>(initialElcoinStatus: Maybe<T> = null) => {
    const [elcoinStatus, setElcoinStatus] = useState<Maybe<T>>(initialElcoinStatus);

    const onElcoinStatusChange = (newElcoinStatus: T) => setElcoinStatus(newElcoinStatus);

    return useMemo(() => [{ elcoinStatus }, { onElcoinStatusChange }, {}], [elcoinStatus]);
};
