import styled, { css } from 'styled-components';

import { DarkGrey, Mystic, PrimeBlue, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

interface RadioButtonProps {
    isChecked: boolean;
}

const RadioButtonChecked = css`
    box-shadow: 0 0 0 2px ${PrimeBlue};
    background-color: ${PrimeBlue};
    border: 4px solid ${White};
`;

const RadioButtonNotChecked = css`
    box-shadow: 0 0 0 2px ${Mystic};
    background-color: inherit;
    border: none;
`;

export const RadioButtonWrapper = styled.label`
    cursor: pointer;
    ${Flex.InlineFlex.Row.Style};
`;

export const RadioButtonIconWrapper = styled.div<RadioButtonProps>`
    width: 22px;
    height: 22px;
    border-radius: 16px;
    cursor: pointer;
    ${({ isChecked }) => (isChecked ? RadioButtonChecked : RadioButtonNotChecked)};

    ${Flex.VerticalCenter.HorizontalCenter.Style}
    :hover {
        box-shadow: ${({ isChecked }) => !isChecked && `0 0 0 2px ${DarkGrey}`};
    }
`;
