import { useContext, useState } from 'react';

import { ButtonPrimaryStyle } from '@shared-atom/button/button.style';
import { ModelContext } from '@shared-component/modal/modal-context';

import { ModalWrapper } from '@component/modal/admin-modal.styles';
import { RejectPaymentForm } from '@component/modal/modals/reject-payment/reject-payment-form/reject-payment-form';
import {
    RejectPaymentButtons,
    RejectPaymentText,
    RejectPaymentTitle,
} from '@component/modal/modals/reject-payment/reject-payment-form/reject-payment.styles';

export const RejectPaymentModal = () => {
    const { onClose } = useContext(ModelContext);

    const [isRemovalConfirmed, setRemovalConfirmed] = useState(false);

    return (
        <ModalWrapper>
            {!isRemovalConfirmed && (
                <>
                    <RejectPaymentTitle>Do you want to reject this transaction?</RejectPaymentTitle>
                    <RejectPaymentText>This action is irreversible.</RejectPaymentText>
                    <RejectPaymentButtons>
                        <ButtonPrimaryStyle onClick={() => onClose()}>No</ButtonPrimaryStyle>
                        <ButtonPrimaryStyle onClick={() => setRemovalConfirmed(true)}>Yes</ButtonPrimaryStyle>
                    </RejectPaymentButtons>
                </>
            )}
            {isRemovalConfirmed && <RejectPaymentForm />}
        </ModalWrapper>
    );
};
