import styled from 'styled-components';

import { ExternalLinkStyles } from '@shared-atom/external-link/external-link.styles';
import { Typography } from '@shared-atom/typography/typography';
import { PrimeBlue } from '@shared-style/colors';

export const GoogleRecaptchaInfoComponent = styled.div`
    margin-top: 24px;
    ${Typography.Paragraph12.Neutral500.Center.Style};
    ${ExternalLinkStyles} {
        margin: 0 4px;
        color: ${PrimeBlue};
    }
`;
