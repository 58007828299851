import { values } from 'mobx';
import { Instance, types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserActivatedStatus, UserType } from '@shared-graphql';

const userActivatedStatuses = $enum(UserActivatedStatus).getValues();

export const ClientsModelType = types
    .model('ClientsModelType', {
        id: types.identifier,
        type: types.enumeration([UserType.Private, UserType.Legal]),
        name: types.string,
        nationality: types.maybeNull(types.string),
        isActivated: types.boolean,
        status: types.enumeration(userActivatedStatuses),
        updatedAt: types.Date,
        createdAt: types.Date,
    })
    .views(self => ({
        get fullName() {
            return self.name ?? '-';
        },
    }));

export const ClientsModelTypeInstance = types
    .model({
        elements: types.map(ClientsModelType),
        totalCount: types.number,
    })
    .views(self => ({
        get clients() {
            return values(self.elements) as unknown as Instance<typeof ClientsModelType>[];
        },
    }));
