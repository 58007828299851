import { CurrentTabName } from '@component/breadcrumbs/breadcrumb-tabs/current-tab-name';
import { LegalClientNameTab } from '@component/breadcrumbs/breadcrumb-tabs/legal-client-name';
import { OptionsInterface } from '@component/tabs/tabs.interface';
import { PageEnum } from '@enum/page.enum';

export const legalClientTabOptions: OptionsInterface[] = [
    {
        title: 'Summary',
        name: PageEnum.LegalClientSummary,
    },
    {
        title: 'Documents',
        name: PageEnum.LegalClientDocuments,
    },
    {
        title: 'Archived Documents',
        name: PageEnum.LegalClientArchivedDocuments,
    },
    {
        title: 'Accounts',
        name: PageEnum.LegalClientAccounts,
    },
    {
        title: 'Administration',
        name: PageEnum.LegalClientAdministration,
    },
    {
        title: 'Pricing',
        name: PageEnum.LegalClientPricing,
    },
    {
        title: 'Services',
        name: PageEnum.LegalClientServices,
    },
];

export const breadcrumbList = [
    {
        path: PageEnum.StatusClients,
        name: 'Clients',
    },
    {
        name: <LegalClientNameTab />,
    },
    {
        name: <CurrentTabName options={legalClientTabOptions} />,
    },
];
