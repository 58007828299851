import { flow, applySnapshot, getParent, getSnapshot, Instance } from 'mobx-state-tree';

import { AdminGetPrivateClientServices, Query, User, UserService } from '@shared-graphql';
import { isExist } from '@shared-util/is-data';

import { apolloClient } from '@connection/apollo-client';
import { PrivateClientModel } from '@model/private-client-model/private-client-model';
import { PrivateClientTypes } from '@model/private-client-model/private-client-model.type';

const queryPrivateClientService = (userId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetPrivateClient'>>({ query: AdminGetPrivateClientServices, variables: { userId } })
        .then(result => (result.data?.adminGetPrivateClient as User).service);

export const privateClientServiceActions = (self: Instance<typeof PrivateClientTypes>) => ({
    loadPrivateClientService: flow(function* loadPrivateClientService(clientId: string) {
        const clientModel = getParent(self) as Instance<typeof PrivateClientModel>;

        if (clientModel.loading.isLoading) {
            return;
        }

        clientModel.setLoading(true);

        const service: UserService = yield queryPrivateClientService(clientId);

        const currentSnapshot = getSnapshot(self);

        applySnapshot(self, {
            ...currentSnapshot,
            service: isExist(service) ? service : null,
        });

        clientModel.setLoading(false);
    }),
});
