import { AccountsPage } from '@page/accounts/accounts.page';
import { AccountsPageBlockWrapper } from '@page/legal-client/legal-client-accounts/legal-client-accounts.styles';
import { useCompanySelector } from '@selector/company/use-company.selector';

export const LegalClientAccounts = () => {
    const [{ status }] = useCompanySelector();

    return (
        <AccountsPageBlockWrapper>
            <AccountsPage userStatus={status} />
        </AccountsPageBlockWrapper>
    );
};
