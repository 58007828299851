import { FC } from 'react';

import { CountrySelect } from '@shared-component/country-select/country-select';
import { Input } from '@shared-component/input/input';
import { FormRow } from '@shared-form/form-common/form-common.styles';

import { PrivateAddressFormEnum } from './address-form.enum';
import { AddressFormInterface } from './address-form.interface';
import { usePrivateAddressFormLocalization } from './address-form.localization-hook';
import { getPath } from './address-form.utils';

export const AddressForm: FC<AddressFormInterface> = ({ customPathName = '' }) => {
    const { streetTitle, postTitle, cityTitle, countryTitle } = usePrivateAddressFormLocalization();

    return (
        <>
            <Input name={getPath(customPathName, PrivateAddressFormEnum.Street)} title={streetTitle} />
            <FormRow>
                <Input name={getPath(customPathName, PrivateAddressFormEnum.Zip)} title={postTitle} />
            </FormRow>
            <FormRow>
                <Input name={getPath(customPathName, PrivateAddressFormEnum.City)} title={cityTitle} />
            </FormRow>
            <FormRow>
                <CountrySelect name={getPath(customPathName, PrivateAddressFormEnum.Country)} title={countryTitle} />
            </FormRow>
        </>
    );
};
