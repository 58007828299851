import { flow, Instance } from 'mobx-state-tree';

import {
    AdminGetPrivateClientStatuses,
    AdminUserActivatedStatusUpdate,
    Mutation,
    Query,
    User,
    UserActivatedStatus,
} from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { PrivateClientModel, PrivateClientModelType } from '@model/private-client-model/private-client-model';

const mutationClientStatus = (userId: string, status: UserActivatedStatus) =>
    apolloClient
        .query<Pick<Mutation, 'adminUserActivatedStatusUpdate'>>({
        query: AdminUserActivatedStatusUpdate,
        variables: { userId, status },
    })
        .then(result => result.data.adminUserActivatedStatusUpdate);

const queryClientStatuses = (userId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetPrivateClient'>>({ query: AdminGetPrivateClientStatuses, variables: { userId } })
        .then(result => (result.data.adminGetPrivateClient as User).statuses);

export const privateClientStatusesActions = (self: Instance<typeof PrivateClientModelType>) => ({
    changeClientStatus: flow(function* changeClientStatus(userId: string, status: UserActivatedStatus) {
        if (self.loading.isLoading) {
            return;
        }

        (self as Instance<typeof PrivateClientModel>).setLoading(true);

        try {
            const result = yield mutationClientStatus(userId, status);

            if (result.status as boolean) {
                self.client.status = status;

                self.client.statuses = yield queryClientStatuses(userId);
            }
        } catch (error) {
            throw new Error(error as string);
        } finally {
            (self as Instance<typeof PrivateClientModel>).setLoading(false);
        }
    }),
});
