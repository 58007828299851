import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useInputPasswordTooltipValidationOptions = () => {
    const digitValidationOption = useLocalizationText(LocalizationEnum.RegistrationPasswordTooltipValidationDigit);
    const specialCharacterValidationOption = useLocalizationText(
        LocalizationEnum.RegistrationPasswordTooltipValidationSpecialCharacter
    );
    const lowerCaseValidationOption = useLocalizationText(
        LocalizationEnum.RegistrationPasswordTooltipValidationLowerCase
    );
    const upperCaseValidationOption = useLocalizationText(
        LocalizationEnum.RegistrationPasswordTooltipValidationUpperCase
    );
    const minLengthValidationOption = useLocalizationText(
        LocalizationEnum.RegistrationPasswordTooltipValidationMinLength
    );
    const maxLengthValidationOption = useLocalizationText(
        LocalizationEnum.RegistrationPasswordTooltipValidationMaxLength
    );
    const latinLetterValidationOption = useLocalizationText(
        LocalizationEnum.RegistrationPasswordTooltipValidationLatinLetter
    );

    return [
        {
            label: digitValidationOption,
            isValid: (value: string) => /\d+/.test(value),
        },
        {
            label: specialCharacterValidationOption,
            isValid: (value: string) => /[!@#$%^&*()_=+\-.,]/.test(value),
        },
        {
            label: lowerCaseValidationOption,
            isValid: (value: string) => /[a-z]/.test(value),
        },
        {
            label: upperCaseValidationOption,
            isValid: (value: string) => /[A-Z]/.test(value),
        },
        {
            label: minLengthValidationOption,
            isValid: (value: string) => value.length >= 8,
        },
        {
            label: maxLengthValidationOption,
            isValid: (value: string) => value.length > 0 && value.length <= 16,
        },
        {
            label: latinLetterValidationOption,
            isValid: (value: string) => /^[a-z]+$/i.test(value.replace(/\d+|\W+/gm, '')),
        },
    ];
};
