import { Logo } from '@shared-atom/logo/logo';
import { Translate } from '@shared-atom/translate/translate';
import { Animation } from '@shared-component/animation/animation';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { GoogleRecaptchaInfo } from '@component/google-recaptcha-info/google-recaptcha-info';
import PasswordAnimation from '@page/login/assets/password.animation.json';
import { LoginSteps } from '@page/login/login-steps/login-steps';
import {
    AnimationWrapper,
    LoginLeftContentWrapper,
    LoginLeftFormWrapper,
    LoginLeftHeader,
    LoginLeftWrapper,
    LoginRightWrapper,
    LoginSlideDescription,
    LoginSlideTitle,
    LoginTitle,
    LoginWrapper,
    LogoWrapper,
} from '@page/login/login.styles';

export const LoginPage = () => (
    <LoginWrapper>
        <LoginLeftWrapper>
            <LoginLeftContentWrapper>
                <LoginLeftHeader>
                    <LogoWrapper>
                        <Logo />
                    </LogoWrapper>
                    <LoginTitle>
                        <Translate langKey={LocalizationEnum.LoginFormTitle} />
                    </LoginTitle>
                </LoginLeftHeader>
                <LoginLeftFormWrapper>
                    <LoginSteps />
                </LoginLeftFormWrapper>
                <GoogleRecaptchaInfo />
            </LoginLeftContentWrapper>
        </LoginLeftWrapper>
        <LoginRightWrapper>
            <AnimationWrapper>
                <Animation animationData={PasswordAnimation} />
            </AnimationWrapper>
            <LoginSlideTitle>
                <Translate langKey={LocalizationEnum.RegistrationSlideTitle1} />
            </LoginSlideTitle>
            <LoginSlideDescription>
                <Translate langKey={LocalizationEnum.RegistrationSlideDescription1} />
            </LoginSlideDescription>
        </LoginRightWrapper>
    </LoginWrapper>
);
