import { FC, SVGProps } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ClickEventType } from '@shared-type/click-event.type';

import {
    DoneIcon,
    NavigationItemIcon,
    NavigationItemIconWrapper,
    NavigationItemTitle,
    NavigationWrapperItem,
} from './registration-navigation-item.styles';

interface RegistrationNavigationItemProps {
    title: string;
    icon: FC<SVGProps<SVGSVGElement>>;
    onClick: ClickEventType;
    isDone: boolean;
    isActive: boolean;
    isDisabled: boolean;
}

export const RegistrationNavigationItem = ({
    icon,
    title,
    isDone,
    isActive,
    isDisabled,
    onClick,
}: RegistrationNavigationItemProps) => (
    <NavigationWrapperItem>
        <ButtonTransparent onClick={onClick} isDisabled={isDisabled}>
            <NavigationItemIconWrapper isDisabled={isDisabled}>
                <NavigationItemIcon as={icon} isDisabled={isDisabled} />
            </NavigationItemIconWrapper>
            <NavigationItemTitle isActive={isActive}>{title}</NavigationItemTitle>
            {isDone && <DoneIcon />}
        </ButtonTransparent>
    </NavigationWrapperItem>
);
