import { parse } from 'date-fns';
import { Formik } from 'formik';
import { FC, useMemo } from 'react';

import { StateControlButtons } from '@shared-component/table/state-control-buttons/state-control-buttons';
import { TableFiltersWrapper } from '@shared-component/table/table.styles';
import { isTrue } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

import { ClientsTableFilterForm } from '@page/clients/clients-table/clients-table-filter/clients-table-filter-form/clients-table-filter-form';
import { ClientFilterInterface } from '@page/clients/clients-table/clients-table-filter/clients-table-filter.interface';

interface ClientsTableFilterInterface extends Omit<ClientFilterInterface, 'onSearchChange'> {
    onResetFilters: typeof noop;
    clientsCount: number;
}

export const ClientsTableFilter: FC<ClientsTableFilterInterface> = ({
    onResetFilters,
    onStatusChange,
    onTypeChange,
    onCreatedFromChange,
    onCreatedToChange,
    onCountryChange,
    filters: { status, type, createdFrom, createdTo, country },
    clientsCount,
}) => {
    const initialValues = useMemo(
        () => ({
            status,
            type,
            createdFrom: isTrue(createdFrom) ? parse(createdFrom, 'dd-MM-yyyy', new Date()) : null,
            createdTo: isTrue(createdTo) ? parse(createdTo, 'dd-MM-yyyy', new Date()) : null,
            country,
        }),
        [status, type, createdFrom, createdTo, country]
    );

    return (
        <TableFiltersWrapper>
            <Formik initialValues={initialValues} onSubmit={noop} enableReinitialize>
                <ClientsTableFilterForm
                    clientsCount={clientsCount}
                    onStatusChange={onStatusChange}
                    onTypeChange={onTypeChange}
                    onCreatedFromChange={onCreatedFromChange}
                    onCreatedToChange={onCreatedToChange}
                    onCountryChange={onCountryChange}
                />
            </Formik>
            <StateControlButtons onResetFilters={onResetFilters} />
        </TableFiltersWrapper>
    );
};
