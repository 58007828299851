import styled from 'styled-components';

import Plus from '@shared-asset/plus.svg?react';
import Minus from '@shared-atom/info-block/assets/minus.svg?react';
import { DarkGrey, Mystic } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const Wrapper = styled.div`
    ${Flex.VerticalCenter.Style}
`;

export const IconWrapper = styled.div`
    padding: 8px 0 8px 16px;
    margin-left: 16px;
    box-shadow: -1px 0 0 0 ${Mystic};

    ${Flex.VerticalCenter.Style}
`;

export const MinusIcon = styled(Minus)`
    fill: ${DarkGrey};
`;

export const PlusIcon = styled(Plus)`
    fill: ${DarkGrey};
`;
