import { useLazyQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal-context';
import { StatementFormInterface } from '@shared-form/statement-form/statement-form.interface';
import {
    AdminGetPdfTransactionReceipt,
    AdminGetPdfTransactions,
    AdminGetReport,
    AdminGetReportPresignedUrl,
    Query,
    ReportStatus,
} from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

export const useReportUrlGetting = (paymentId?: string) => {
    const { onClose } = useContext(ModelContext);
    const { onOpen } = useContext(InformationModalContext);

    const intervalId = useRef<number | null>(null);
    const globalWaitingTimerId = useRef<number | null>(null);
    const [isReportUrlLoading, setReportUrlLoading] = useState(false);
    const [url, setUrl] = useState('');

    const [
        adminGetPDFTransactionReceipt,
        { data: adminGetPDFTransactionReceiptData, error: adminGetPDFTransactionReceiptError },
    ] = useLazyQuery<Pick<Query, 'adminGetPDFTransactionReceipt'>>(AdminGetPdfTransactionReceipt);
    const [adminGetPDFTransactions, { data: adminGetPDFTransactionsData, error: adminGetPDFTransactionsError }] =
        useLazyQuery<Pick<Query, 'adminGetPDFTransactions'>>(AdminGetPdfTransactions);
    const [adminGetReport, { data: adminGetReportData, error: adminGetReportError }] =
        useLazyQuery<Pick<Query, 'adminGetReport'>>(AdminGetReport);
    const [
        adminGetReportPresignedUrl,
        { data: adminGetReportPresignedUrlData, error: adminGetReportPresignedUrlError },
    ] = useLazyQuery<Pick<Query, 'adminGetReportPresignedUrl'>>(AdminGetReportPresignedUrl);

    const reportId = useMemo(
        () =>
            isTrue(paymentId)
                ? adminGetPDFTransactionReceiptData?.adminGetPDFTransactionReceipt.reportId
                : adminGetPDFTransactionsData?.adminGetPDFTransactions.reportId,
        [paymentId, adminGetPDFTransactionReceiptData, adminGetPDFTransactionsData]
    );

    const finishProcessing = useCallback(() => {
        clearTimeout(globalWaitingTimerId.current as number);
        setReportUrlLoading(false);
        onClose();
    }, []);

    useEffect(() => {
        if (isTrue(url)) {
            window.location.href = url;
            finishProcessing();
        }
    }, [url]);

    useEffect(() => {
        if (isTrue(adminGetReportPresignedUrlData?.adminGetReportPresignedUrl.url)) {
            setUrl(adminGetReportPresignedUrlData?.adminGetReportPresignedUrl.url as string);
        }
    }, [adminGetReportPresignedUrlData]);

    useEffect(() => {
        if (adminGetReportData?.adminGetReport.status === ReportStatus.done) {
            adminGetReportPresignedUrl({
                variables: { reportId },
            });
        }
        if (adminGetReportData?.adminGetReport.status === ReportStatus.failed) {
            finishProcessing();
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: LocalizationEnum.SorrySomethingWentWrongPleaseTryAgainLater,
            });
        }
    }, [adminGetReportData, reportId]);

    useEffect(() => {
        if (isTrue(reportId)) {
            intervalId.current = setInterval(
                () =>
                    adminGetReport({
                        variables: { adminGetReportId: reportId },
                    }),
                5000
            );
        }

        if (
            [ReportStatus.failed, ReportStatus.done].includes(adminGetReportData?.adminGetReport.status as ReportStatus)
        ) {
            clearInterval(intervalId.current as number);
        }

        return () => clearInterval(intervalId.current as number);
    }, [reportId, adminGetReportData]);

    useEffect(() => {
        if (
            adminGetPDFTransactionsError ||
            adminGetReportError ||
            adminGetReportPresignedUrlError ||
            adminGetPDFTransactionReceiptError
        ) {
            clearInterval(intervalId.current as number);
            finishProcessing();
            onOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    adminGetPDFTransactionReceiptError?.message ??
                    adminGetReportPresignedUrlError?.message ??
                    adminGetReportError?.message ??
                    adminGetPDFTransactionsError?.message ??
                    LocalizationEnum.SorrySomethingWentWrongPleaseTryAgainLater,
            });
        }
    }, [
        adminGetPDFTransactionsError,
        adminGetReportError,
        adminGetReportPresignedUrlError,
        adminGetPDFTransactionReceiptError,
    ]);

    const handleSubmit = useCallback(
        ({ gte, lte, accountId, clientId, operation }: StatementFormInterface) => {
            setReportUrlLoading(true);
            globalWaitingTimerId.current = setTimeout(() => {
                finishProcessing();
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: LocalizationEnum.SorrySomethingWentWrongPleaseTryAgainLater,
                });
            }, 60 * 1000);
            void (isTrue(paymentId)
                ? adminGetPDFTransactionReceipt({
                    variables: {
                        paymentId,
                        clientId,
                        accountId,
                    },
                })
                : adminGetPDFTransactions({
                    variables: {
                        clientId,
                        filters: {
                            accountId,
                            ...(isTrue(operation) && { operationType: operation }),
                            updatedAt: {
                                gte: gte !== undefined ? startOfDay(gte) : null,
                                lte: lte !== undefined ? endOfDay(lte) : null,
                            },
                        },
                    },
                }));
        },
        [paymentId]
    );

    return { isReportUrlLoading, handleSubmit };
};
