import { Form, Formik } from 'formik';

import { BackButton } from '@shared-atom/back-button/back-button';
import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { FormWrapper } from '@shared-component/forms/form-common/form-common.styles';
import { LegalAddressForm } from '@shared-component/forms/legal-address-form/legal-address-form';
import { ButtonWrapper, RegistrationLegalPersonTitle } from '@shared-component/registration/common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

interface RegistrationLegalAddressProps {
    isSelectedPostal: boolean;
    initialData: any;
    validationRules: any;
    onSubmit: OnEventType<any>;
    customPathName: string;
    onBack: OnEventEmptyType;
}

export const LegalClientProfileAddress = ({
    isSelectedPostal,
    initialData,
    validationRules,
    onSubmit,
    customPathName,
    onBack,
}: RegistrationLegalAddressProps) => (
    <>
        <RegistrationLegalPersonTitle>
            <Translate
                langKey={
                    isSelectedPostal
                        ? LocalizationEnum.RegistrationLegalAddressPostalPageTitle
                        : LocalizationEnum.RegistrationLegalAddressPageTitle
                }
            />
        </RegistrationLegalPersonTitle>
        <FormWrapper>
            <Formik
                initialValues={initialData}
                validationSchema={validationRules}
                validateOnChange={false}
                validateOnBlur={false}
                onSubmit={onSubmit}
                enableReinitialize
            >
                <Form>
                    <LegalAddressForm customPathName={customPathName} isSelectedPostal={isSelectedPostal} />
                    {isSelectedPostal && (
                        <BackButton onClick={onBack}>
                            <ButtonPrimary isLarge type={ButtonTypeEnum.Submit}>
                                Save
                            </ButtonPrimary>
                        </BackButton>
                    )}
                    {!isSelectedPostal && (
                        <ButtonWrapper>
                            <ButtonPrimary isLarge type={ButtonTypeEnum.Submit}>
                                Save
                            </ButtonPrimary>
                        </ButtonWrapper>
                    )}
                </Form>
            </Formik>
        </FormWrapper>
    </>
);
