import { AccountsPage } from '@page/accounts/accounts.page';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

import { AccountsPageBlockWrapper } from './private-client-accounts.styles';

export const PrivateClientAccounts = () => {
    const [{ status }] = usePrivateClientSelector();

    return (
        <AccountsPageBlockWrapper>
            <AccountsPage userStatus={status} />
        </AccountsPageBlockWrapper>
    );
};
