export enum PaymentEditEnum {
    Currency = 'currency',
    Amount = 'amount',
    PayerName = 'payerName',
    PayerAccountNumber = 'payerAccountNumber',
    AccountNumber = 'beneficiary.acctNumber',
    SortCode = 'beneficiary.sortCode',
    Iban = 'beneficiary.iban',
    BicSwift = 'beneficiary.bicSwift',
    BankName = 'beneficiary.bankName',
    Reason = 'reason',
    Documents = 'documents',
    BeneficiaryEntityType = 'beneficiary.beneficiaryEntityType',
    BeneficiaryAddress = 'beneficiary.beneficiaryAddress',
    BeneficiaryCity = 'beneficiary.beneficiaryCity',
    BeneficiaryCountry = 'beneficiary.beneficiaryCountry',

    // for PRIVATE
    BeneficiaryFirstName = 'beneficiary.beneficiaryFirstName',
    BeneficiaryLastName = 'beneficiary.beneficiaryLastName',
    // for LEGAL
    BeneficiaryCompanyName = 'beneficiary.beneficiaryCompanyName',

    // for CAD and MXN
    BeneficiaryStateOrProvince = 'beneficiary.beneficiaryStateOrProvince',
    BeneficiaryPostcode = 'beneficiary.beneficiaryPostcode',
}
