import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { DarkGrey, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

interface NavigationItemProps {
    isActive: boolean;
}

const NavigationItemIconWrapperActive = css`
    background-color: ${rgba(White, 0.1)};
`;

export const NavigationItem = styled.li`
    :not(:first-child) {
        margin-top: 16px;
    }
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        :not(:first-child) {
            margin-top: unset;
        }
    }
`;

export const NavigationItemIcon = styled.svg`
    min-width: 32px;
    fill: ${DarkGrey};
`;

export const NavigationItemTitle = styled.span`
    color: ${White};
    padding-left: 8px;
    flex: 1;
    white-space: nowrap;
    ${Typography.Left.M.Normal.DarkGrey.Style}
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        font-size: 12px;
    }
`;

export const NavigationItemWrapper = styled.div<NavigationItemProps>`
    border-radius: 16px;
    width: 100%;
    padding-left: 8px;
    overflow: hidden;

    :hover {
        ${NavigationItemIconWrapperActive}

        ${NavigationItemIcon} {
            fill: ${White};
        }
    }

    ${({ isActive }) => isActive && NavigationItemIconWrapperActive};

    ${NavigationItemIcon} {
        fill: ${({ isActive }) => isActive && White};
    }

    ${NavigationItemTitle} {
        ${({ isActive }) => isActive && Typography.White.Style}
    }
    ${NavigationItemTitle} {
        ${({ isActive }) => isActive && Typography.White.Style}
    }
`;

export const NavigationItemIconWrapper = styled.div`
    width: 100%;
    height: 48px;
    overflow: hidden;
    ${Flex.JustifyLeft.VerticalCenter.Style}
`;

export const SubNavigationTitle = styled.ul`
    ${Typography.Left.M.Normal.DarkGrey.Style}
`;

export const SubNavigationItem = styled.li<NavigationItemProps>`
    ${SubNavigationTitle} {
        ${({ isActive }) => isActive && Typography.White.Style}
    }

    :not(:first-child) {
        margin-top: 8px;
    }
`;

export const SubNavigationList = styled.ul`
    margin: 8px 0 16px 40px;
`;
