import { Form, Formik } from 'formik';
import { useCallback, useMemo } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonSecondary } from '@shared-atom/button/button-secondary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Commission } from '@shared-graphql';
import { ClickEventType } from '@shared-type/click-event.type';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import {
    ButtonWrapper,
    DeleteButton,
} from '@page/commission-profile/commission-profile-info/commission-profile-info.styles';
import {
    HandleDeleteInterface,
    HandleUpdateInterface,
} from '@page/commission-profile/commission-profile-info/commission-profile.interface';
import {
    initialCommissionLegal,
    initialNewCommission,
} from '@page/commission-profile/commission-profile-initials/commission-profile.initials';

import { CommissionProfileFormList } from './commission-profile-info-form-list/commission-profile-form-list';

interface CommissionProfileFormProps {
    template: Commission;
    onSubmit: OnEventType<HandleUpdateInterface>;
    onDelete: OnEventType<HandleDeleteInterface>;
    onClose: OnEventEmptyType;
}

export const CommissionProfileInfoForm = ({ onSubmit, onDelete, onClose, template }: CommissionProfileFormProps) => {
    const handleSubmit = useCallback(({ clientId, isDefault, ...commission }: Commission) => {
        const lastCommission = commission.commissions.pop() ?? initialCommissionLegal.commissions[0];
        const lastCommissionAmount = lastCommission?.maxAmount ?? initialNewCommission.maxAmount;
        const lastMaxAmount = lastCommissionAmount <= 0 ? null : lastCommissionAmount;
        const updateLastCommission = { ...lastCommission, maxAmount: lastMaxAmount };
        commission.commissions.push(updateLastCommission);
        onSubmit({ commissions: [...commission.commissions], id: commission.id, isDefault, clientId });
        onClose();
    }, []);

    const handleDelete = useCallback(() => {
        onDelete({ id: template.id });
        onClose();
    }, []);

    const isTemplateDefault = useMemo(() => template.isDefault, [template]);

    return (
        <Formik initialValues={template} onSubmit={handleSubmit} validateOnChange={false} enableReinitialize>
            <Form>
                <CommissionProfileFormList />
                <ButtonWrapper>
                    <ButtonPrimary type={ButtonTypeEnum.Submit}>Save</ButtonPrimary>
                    <ButtonSecondary type={ButtonTypeEnum.Button} onClick={onClose}>
                        Cancel
                    </ButtonSecondary>
                    {!isTemplateDefault && (
                        <DeleteButton onClick={handleDelete as unknown as ClickEventType}>Delete</DeleteButton>
                    )}
                </ButtonWrapper>
            </Form>
        </Formik>
    );
};
