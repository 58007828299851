import { ExternalLink } from '@shared-atom/external-link/external-link';
import { Link } from '@shared-atom/link/link';
import {
    NavigationListItem,
    NavigationListItemButton,
    NavigationListItemIcon,
} from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/navigation-item-custom.styles';
import { useNavigationItem } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.hook';
import { PageWrapperNavigationItemProps } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.interface';
import {
    NavigationItemIcon,
    SubNavigationItem,
    SubNavigationList,
    SubNavigationTitle,
} from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.styles';
import { CommonObjectType } from '@shared-type/common.type';
import { getPathnameWithParams } from '@shared-util/get-pathname-with-params';
import { isExist } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';

export const NavigationItemCustom = <T extends string>({
    icon,
    iconActive,
    title,
    children,
    pageName,
    modalName,
    onClick,
    temporaryLink = '',
}: PageWrapperNavigationItemProps<T>) => {
    const [isNavigationItemActive, isMenuOpen, onMenuClick, checkSubNavigationActive] = useNavigationItem({
        pageName,
        modalName,
        children,
    });

    const shouldSubNavigationRender = isMenuOpen && isExist(children) && isNavigationItemActive;
    const handleMenuClick = (e: any) => {
        if (temporaryLink === '') {
            onMenuClick(e.currentTarget);
            if (onClick !== undefined) {
                onClick({} as any);
            }
        }
    };
    return (
        <NavigationListItem>
            <NavigationListItemButton onClick={handleMenuClick} active={isNavigationItemActive}>
                {temporaryLink !== '' ? (
                    <ExternalLink href={temporaryLink}>
                        <NavigationListItemIcon>
                            <NavigationItemIcon as={isNavigationItemActive ? iconActive : icon} />
                        </NavigationListItemIcon>
                        <span>{title}</span>
                    </ExternalLink>
                ) : (
                    <>
                        <NavigationListItemIcon>
                            <NavigationItemIcon as={isNavigationItemActive ? iconActive : icon} />
                        </NavigationListItemIcon>
                        <span>{title}</span>
                    </>
                )}
            </NavigationListItemButton>
            {shouldSubNavigationRender && (
                <SubNavigationList>
                    {children?.map(({ title: subItemTitle, pageName: subItemPageName, params }) => (
                        <SubNavigationItem
                            key={subItemPageName}
                            isActive={checkSubNavigationActive(subItemPageName, params)}
                        >
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <Link
                                to={{
                                    pathname: getPathnameWithParams(
                                        subItemPageName as PageEnum,
                                        params as CommonObjectType
                                    ),
                                }}
                            >
                                <SubNavigationTitle>{subItemTitle}</SubNavigationTitle>
                            </Link>
                        </SubNavigationItem>
                    ))}
                </SubNavigationList>
            )}
        </NavigationListItem>
    );
};
