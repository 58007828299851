import { FC, ReactNode } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { FormButtonsRowWrapper } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { ClickEventType } from '@shared-type/click-event.type';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { ArrowLeft, ButtonTitleView } from './back-button.styles';

interface BackButtonProps {
    onClick: OnEventEmptyType | ClickEventType;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const BackButton: FC<BackButtonProps> = ({ onClick, children }) => (
    <FormButtonsRowWrapper>
        <ButtonTransparent type={ButtonTypeEnum.Button} onClick={onClick}>
            <ArrowLeft />
            <ButtonTitleView>
                <Translate langKey={LocalizationEnum.CommonBack} />
            </ButtonTitleView>
        </ButtonTransparent>
        {children}
    </FormButtonsRowWrapper>
);
