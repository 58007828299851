import { observer } from 'mobx-react-lite';

import { CommentsForm } from '@component/comments/comments-form/comments-form';
import { CommentList } from '@component/comments/comments-list/comments-list';
import { useAddPrivateClientComments } from '@page/private-client/private-client-comments/private-client-comments.hook';

import { BlockWrapper } from './private-client-comments.styles';

export const PrivateClientComments = observer(() => {
    const [comments, handleAddComment] = useAddPrivateClientComments();

    return (
        <BlockWrapper>
            <CommentsForm comments={comments} onAddComment={handleAddComment} />
            <CommentList comments={comments} />
        </BlockWrapper>
    );
});
