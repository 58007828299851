import { Instance } from 'mobx-state-tree';

import { DateFormatWithTime } from '@shared-component/input/input-date/input-date-format.helper';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { initialBeneficiary } from '@shared-model/beneficiary-model/beneficiary-model.initial';
import { getDate } from '@shared-util/get-date';

import { AccountTypeUnion } from '@model/account-model/account-model.type';
import { useAccountSelector } from '@selector/account/use-account.selector';
import { usePaymentSelector } from '@selector/payment/use-payment.selector';

const NO_DATA = '-';

export const usePaymentSummary = () => {
    const [
        {
            payment: {
                id,
                type,
                status,
                reason,
                createdAt,
                accountId,
                amount,
                currency,
                payerName,
                operation,
                sender,
                total,
                fee: { total: commissionTotal, amount: commissionAmount },
                beneficiary,
            },
        },
    ] = usePaymentSelector();

    const {
        iban,
        acctNumber,
        bicSwift,
        sortCode,
        bankCountry,
        beneficiaryCountry,
        bankName: beneficiaryBankName,
    } = beneficiary ?? initialBeneficiary;

    const { lang } = useLocalizationStore();
    const [{ ccAccounts }] = useAccountSelector();

    const payerAccount = ccAccounts.find(
        account =>
            (account.accountId as unknown as string) === accountId &&
            (currency?.code as string) === ((account.currency as any).code as string)
    ) as Instance<typeof AccountTypeUnion>;
    const payerAccountBank = payerAccount?.banks;

    return {
        id,
        status,
        reason,
        total: Math.max(commissionTotal, total),
        amount,
        payerName,
        bankCountry,
        beneficiaryName: beneficiary?.beneficiaryName ?? '',
        commissionAmount,
        beneficiaryBankName,
        beneficiaryCountry,
        payerAccountNumber: payerAccountBank?.number,
        payerAccountBalance: payerAccount?.balance,
        payerAccountRealBalance: payerAccount?.realBalance ?? 0,
        payerAccountFreezeBalance: payerAccount?.freezeBalance ?? 0,
        currency,
        operation,
        sender,
        type,
        bic: bicSwift ?? sortCode,
        beneficiaryAccount: iban ?? acctNumber,
        createdAt: createdAt !== null ? getDate(new Date(createdAt), DateFormatWithTime[lang]) : NO_DATA,
    };
};
