import { useMemo } from 'react';

import { CURRENCY_INITIALS } from '@shared-component/cards/cards.options';
import { DateFormatWithTime } from '@shared-component/input/input-date/input-date-format.helper';
import { Address, UserActivatedStatus, UserProfile } from '@shared-graphql';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { getDate } from '@shared-util/get-date';

import { VerificationRejectionTitleEnum } from '@page/common/client-summary/client-summary.enum';
import { usePrivateClientActionsSelector } from '@selector/private-client/use-private-client.actions-selector';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

import { getVerificationRejectionTitle } from './private-client-summary.utils';

interface PrivateClientSummaryInterface {
    id: string;
    isApplicationProfileCompleted: boolean;
    status: string;
    elcoinId: string | null;
    verificationRejectionTitle: VerificationRejectionTitleEnum | null;
    verificationRejectionDate: string;
    moneyTurnover: string;
    email: string;
    phone: string;
    moneyTurnoverCurrency?: string;
    reasonAccount: string | null;
    sourceOfFunds: string | null;
    address: Address | null;
    termsAccepted: number | null;
    profile: Omit<UserProfile, 'id'> | null;
}

const ACTIVATED_STATUSES = [UserActivatedStatus.Approved, UserActivatedStatus.Blocked];
const NO_DATA = '-';

export const usePrivateClientSummary = (): [
    PrivateClientSummaryInterface,
    OnEventType<UserActivatedStatus, OnEventEmptyType>
] => {
    const [{ id, status, elcoinId, statuses, address, service, privateStatus, email, phone, profile }] =
        usePrivateClientSelector();

    const [lastActiveStatus] = statuses;

    const { updateClientStatus } = usePrivateClientActionsSelector();

    const { lang } = useLocalizationStore();

    const handleAction = (actionStatus: UserActivatedStatus) => () => updateClientStatus(actionStatus);

    const currentStatusDataToDisplay = useMemo(
        () => statuses.find(element => ACTIVATED_STATUSES.includes(element.status)) ?? lastActiveStatus,
        [lastActiveStatus]
    );

    const initialValues = useMemo(() => {
        const verificationRejectionTitle = getVerificationRejectionTitle(currentStatusDataToDisplay?.status);

        const {
            hasProfile,
            hasAddress,
            hasDocument,
            hasService,
            hasDeclaration,
            hasAccept,
            termsAccepted: isTermsAccepted,
        } = privateStatus;

        const isApplicationProfileCompleted =
            hasProfile && hasAddress && hasDocument && hasService && hasDeclaration && hasAccept;

        return {
            id,
            isApplicationProfileCompleted,
            status,
            elcoinId,
            verificationRejectionTitle,
            verificationRejectionDate:
                currentStatusDataToDisplay?.date !== undefined
                    ? getDate(new Date(currentStatusDataToDisplay.date), DateFormatWithTime[lang])
                    : NO_DATA,
            moneyTurnover: service?.moneyTurnover ?? '0',
            moneyTurnoverCurrency: service?.currency.code ?? CURRENCY_INITIALS.code,
            email,
            phone,
            reasonAccount: service?.reasonAccount ?? null,
            sourceOfFunds: service?.sourceOfFunds ?? null,
            address: address ?? null,
            termsAccepted: isTermsAccepted ?? null,
            profile: profile ?? null,
        };
    }, [id, lastActiveStatus, address, service, privateStatus, email, profile]);

    return [initialValues, handleAction];
};
