import { Instance } from 'mobx-state-tree';

import { CurrenciesModelType } from '@model/currencies-model/currencies-model';
import { useStore } from '@store/store-context';

export const useCurrenciesSelector = (): [Instance<typeof CurrenciesModelType>, boolean, null | string] => {
    const { currencies } = useStore();

    return [currencies, currencies.loading.isLoading, currencies.error];
};
