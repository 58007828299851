import { Column } from 'react-table';

import { UserDocument } from '@shared-graphql';

export const ClientAccountsTableColumns: Column<UserDocument>[] = [
    {
        id: 'type',
        accessor: 'type',
        Header: 'File type',
    },
    {
        id: 'deleted',
        accessor: 'updatedAt',
        Header: 'Deleted file',
    },
    {
        id: 'update',
        accessor: 'updatedAt',
        Header: 'Updated on',
    },
    {
        id: 'dateUpdate',
        accessor: 'updatedAt',
        Header: 'Date changed',
    },
];
