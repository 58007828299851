import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { AccountCardType as AccountCardTypes, AccountCardStatus } from '@shared-graphql';
import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';

const typeValues = $enum(AccountCardTypes).getValues();
const statusValues = $enum(AccountCardStatus).getValues();

const AccountCardLimitsTypeSchema = {
    internetPayment: types.maybeNull(types.number),
    cashWithdrawal: types.maybeNull(types.number),
};

export const AccountCardTypeSchema = {
    id: types.identifier,
    number: types.string,
    month: types.number,
    year: types.number,
    type: types.enumeration(typeValues),
    balance: types.number,
    currency: CurrencyModelType,
    cashWithdrawalUsed: types.number,
    internetPaymentUsed: types.number,
    limits: types.model(AccountCardLimitsTypeSchema),
    status: types.enumeration(statusValues),
};
