import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    DateTime: { input: Date; output: Date };
    Upload: { input: any; output: any };
    _FieldSet: { input: any; output: any };
};

export type Account = {
    accountId: Scalars['String']['output'];
    balance: Scalars['Float']['output'];
    banks: Array<Maybe<AccountBank>>;
    currency: Currency;
    freezeBalance: Maybe<Scalars['Float']['output']>;
    id: Scalars['ID']['output'];
    legalEntityType: PaymentEntityType;
    name: Maybe<Scalars['String']['output']>;
    realBalance: Maybe<Scalars['Float']['output']>;
    reservedBalance: Maybe<Scalars['Float']['output']>;
    type: AccountType;
};

export type AccountAdministration = {
    accountClosing: Scalars['Float']['output'];
    accountMaintenance: Scalars['Float']['output'];
    accountOpening: Scalars['Float']['output'];
    balanceFee: Scalars['Float']['output'];
    complexStructure: Scalars['Float']['output'];
    foreignCurrencies: Scalars['Float']['output'];
    urgentAccountOpening: Scalars['Float']['output'];
    userType: Maybe<UserType>;
};

export type AccountAdministrationInput = {
    accountClosing: InputMaybe<Scalars['Float']['input']>;
    accountMaintenance: InputMaybe<Scalars['Float']['input']>;
    accountOpening: InputMaybe<Scalars['Float']['input']>;
    balanceFee: InputMaybe<Scalars['Float']['input']>;
    complexStructure: InputMaybe<Scalars['Float']['input']>;
    foreignCurrencies: InputMaybe<Scalars['Float']['input']>;
    urgentAccountOpening: InputMaybe<Scalars['Float']['input']>;
};

export type AccountAdministrationResult = AccountAdministration | Error;

export type AccountAdministrationWithLokalize = {
    accountClosing: LokalizeValue;
    accountMaintenance: LokalizeValue;
    accountOpening: LokalizeValue;
    balanceFee: LokalizeValue;
    complexStructure: LokalizeValue;
    foreignCurrencies: LokalizeValue;
    urgentAccountOpening: LokalizeValue;
    userType: Maybe<UserType>;
};

export type AccountBank = {
    bankAddress: Scalars['String']['output'];
    bankCountry: Scalars['String']['output'];
    bankName: Scalars['String']['output'];
    beneficiaryAddress: Maybe<Scalars['String']['output']>;
    bic: Maybe<Scalars['String']['output']>;
    countrySpecificDetails: Maybe<Scalars['String']['output']>;
    currency: Currency;
    iban: Maybe<Scalars['String']['output']>;
    inboundPayments: Array<Maybe<Scalars['String']['output']>>;
    name: Scalars['String']['output'];
    number: Scalars['String']['output'];
    outboundPayments: Array<Maybe<Scalars['String']['output']>>;
    paymentType: PaymentType;
    sortCode: Maybe<Scalars['String']['output']>;
};

export enum AccountCardStatus {
    Active = 'Active',
    Blocked = 'Blocked',
    Closed = 'Closed',
    Pending = 'Pending',
}

export enum AccountCardType {
    Business = 'Business',
    Gold = 'Gold',
}

export type AccountCurrencyResult = DefaultAnswer | Error;

export type AccountDefault = {
    accountId: Scalars['String']['output'];
    currency: DefaultCurrency;
    id: Scalars['ID']['output'];
    legalEntityType: PaymentEntityType;
    type: AccountType;
};

export type AccountDefaultResult = AccountDefault | Error;

export type AccountFilters = {
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    status: InputMaybe<AccountStatus>;
    type: InputMaybe<AccountType>;
};

export type AccountInput = {
    applicantId: InputMaybe<Scalars['String']['input']>;
    balance: Scalars['Float']['input'];
    client: ClientAccountInput;
    contact: ContactAccountInput;
    currency: Scalars['String']['input'];
    id: InputMaybe<Scalars['ID']['input']>;
    identificationType: InputMaybe<IdentificationType>;
    identificationValue: InputMaybe<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    type: AccountType;
};

export enum AccountStatus {
    Active = 'Active',
    Blocked = 'Blocked',
    Closed = 'Closed',
    Pending = 'Pending',
}

export type AccountSupportAdministration = {
    amount: Scalars['Float']['output'];
    id: Scalars['ID']['output'];
    reason: Scalars['String']['output'];
    title: Scalars['String']['output'];
};

export type AccountSupportAdministrationInput = {
    amount: Scalars['Float']['input'];
    id: InputMaybe<Scalars['ID']['input']>;
    reason: Scalars['String']['input'];
    title: Scalars['String']['input'];
};

export enum AccountType {
    ClientELPA = 'ClientELPA',
    CurrencyCloud = 'CurrencyCloud',
    Elcoin = 'Elcoin',
    IFX = 'IFX',
    Modulr = 'Modulr',
}

export type ActivateGoogleAuthenticator = {
    qrcode: Scalars['String']['output'];
};

export type ActivateGoogleAuthenticatorResult = ActivateGoogleAuthenticator | Error;

export type Address = {
    building: Maybe<Scalars['String']['output']>;
    city: Maybe<Scalars['String']['output']>;
    country: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['ID']['output']>;
    street: Maybe<Scalars['String']['output']>;
    zip: Maybe<Scalars['String']['output']>;
};

export type AddressBook = {
    address: Maybe<Address>;
    beneficiary: AddressBookDetails;
    currency: Currency;
    id: Scalars['ID']['output'];
};

export type AddressBookDetails = {
    acctNumber: Scalars['String']['output'];
    bankAddress: Maybe<Scalars['String']['output']>;
    bankCode: Maybe<Scalars['String']['output']>;
    bankCountry: Maybe<Scalars['String']['output']>;
    bankName: Maybe<Scalars['String']['output']>;
    beneficiaryAddress: Maybe<Scalars['String']['output']>;
    beneficiaryCity: Maybe<Scalars['String']['output']>;
    beneficiaryCompanyName: Maybe<Scalars['String']['output']>;
    beneficiaryCountry: Maybe<Scalars['String']['output']>;
    beneficiaryEntityType: PaymentEntityType;
    beneficiaryFirstName: Maybe<Scalars['String']['output']>;
    beneficiaryLastName: Maybe<Scalars['String']['output']>;
    beneficiaryPostcode: Maybe<Scalars['String']['output']>;
    beneficiaryStateOrProvince: Maybe<Scalars['String']['output']>;
    bicSwift: Scalars['String']['output'];
    branchCode: Maybe<Scalars['String']['output']>;
    clabe: Maybe<Scalars['String']['output']>;
    cnaps: Maybe<Scalars['String']['output']>;
    iban: Maybe<Scalars['String']['output']>;
    ifsc: Maybe<Scalars['String']['output']>;
    nationality: Maybe<Scalars['String']['output']>;
    sortCode: Maybe<Scalars['String']['output']>;
};

export type AddressBookDetailsInput = {
    acctNumber: Scalars['String']['input'];
    bankAddress: InputMaybe<Scalars['String']['input']>;
    bankCode: InputMaybe<Scalars['String']['input']>;
    bankCountry: InputMaybe<Scalars['String']['input']>;
    bankName: InputMaybe<Scalars['String']['input']>;
    beneficiaryAddress: InputMaybe<Scalars['String']['input']>;
    beneficiaryCity: InputMaybe<Scalars['String']['input']>;
    beneficiaryCompanyName: InputMaybe<Scalars['String']['input']>;
    beneficiaryCountry: InputMaybe<Scalars['String']['input']>;
    beneficiaryEntityType: PaymentEntityType;
    beneficiaryFirstName: InputMaybe<Scalars['String']['input']>;
    beneficiaryLastName: InputMaybe<Scalars['String']['input']>;
    beneficiaryPostcode: InputMaybe<Scalars['String']['input']>;
    beneficiaryStateOrProvince: InputMaybe<Scalars['String']['input']>;
    bicSwift: Scalars['String']['input'];
    branchCode: InputMaybe<Scalars['String']['input']>;
    clabe: InputMaybe<Scalars['String']['input']>;
    cnaps: InputMaybe<Scalars['String']['input']>;
    iban: InputMaybe<Scalars['String']['input']>;
    ifsc: InputMaybe<Scalars['String']['input']>;
    nationality: InputMaybe<Scalars['String']['input']>;
    sortCode: InputMaybe<Scalars['String']['input']>;
};

export type AddressBookFilterSort = {
    createdAt: SortEnum;
};

export type AddressBookFilters = {
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    search: InputMaybe<Scalars['String']['input']>;
    sort: InputMaybe<Array<AddressBookFilterSort>>;
};

export type AddressBookInput = {
    address: InputMaybe<AddressInput>;
    beneficiary: AddressBookDetailsInput;
    currency: Scalars['String']['input'];
    id: InputMaybe<Scalars['ID']['input']>;
};

export type AddressBookResult = {
    elements: Array<AddressBook>;
    totalCount: Scalars['Int']['output'];
};

export type AddressBookUpdateResult = AddressBook | Error;

export type AddressInput = {
    building: InputMaybe<Scalars['String']['input']>;
    city: InputMaybe<Scalars['String']['input']>;
    country: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['ID']['input']>;
    street: InputMaybe<Scalars['String']['input']>;
    zip: InputMaybe<Scalars['String']['input']>;
};

export enum AddressType {
    Legal = 'Legal',
    Postal = 'Postal',
}

export type AdminGetElcoinClientDataAnswer = {
    id: Scalars['ID']['output'];
};

export type AdminGetElcoinClientDataResult = AdminGetElcoinClientDataAnswer | Error;

export enum AppPreferredLoginMethod {
    Biometry = 'Biometry',
    Email = 'Email',
    GoogleAuthenticator = 'GoogleAuthenticator',
    Pin = 'Pin',
    Sms = 'Sms',
}

export type ApproveTransactionAccountResult = DefaultAnswer | Error;

export type AvailableBic = {
    bankName: Scalars['String']['output'];
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    countryCode: Scalars['String']['output'];
    swiftCode: Scalars['String']['output'];
};

export type AvailableCurrencies = {
    currencies: Maybe<Array<Maybe<AvailableCurrency>>>;
};

export type AvailableCurrency = {
    canBuy: Scalars['Boolean']['output'];
    canSell: Scalars['Boolean']['output'];
    code: Scalars['String']['output'];
    decimalPlaces: Scalars['Int']['output'];
    name: Scalars['String']['output'];
    onlineTrading: Scalars['Boolean']['output'];
};

export type BankData = {
    accountNumber: Maybe<Scalars['String']['output']>;
    availableBics: Array<Maybe<AvailableBic>>;
    bankCode: Maybe<Scalars['String']['output']>;
    bankName: Maybe<Scalars['String']['output']>;
    bban: Maybe<Scalars['String']['output']>;
    checksum: Maybe<Scalars['String']['output']>;
    country: Maybe<Scalars['String']['output']>;
    iban: Scalars['String']['output'];
    valid: Scalars['Boolean']['output'];
};

export type BaseConversionRate = {
    buyCurrency: Scalars['String']['output'];
    percentFee: Scalars['Float']['output'];
    rate: Scalars['String']['output'];
    sellCurrency: Scalars['String']['output'];
    staticFee: Scalars['Float']['output'];
};

export type BeneficiaryDetails = {
    acctNumber: Maybe<Scalars['String']['output']>;
    bankAddress: Maybe<Scalars['String']['output']>;
    bankCode: Maybe<Scalars['String']['output']>;
    bankCountry: Maybe<Scalars['String']['output']>;
    bankName: Maybe<Scalars['String']['output']>;
    beneficiaryAddress: Maybe<Scalars['String']['output']>;
    beneficiaryCity: Maybe<Scalars['String']['output']>;
    beneficiaryCompanyName: Maybe<Scalars['String']['output']>;
    beneficiaryCountry: Maybe<Scalars['String']['output']>;
    beneficiaryEntityType: PaymentEntityType;
    beneficiaryFirstName: Maybe<Scalars['String']['output']>;
    beneficiaryLastName: Maybe<Scalars['String']['output']>;
    beneficiaryPostcode: Maybe<Scalars['String']['output']>;
    beneficiaryStateOrProvince: Maybe<Scalars['String']['output']>;
    bicSwift: Maybe<Scalars['String']['output']>;
    branchCode: Maybe<Scalars['String']['output']>;
    charityNumber: Maybe<Scalars['String']['output']>;
    clabe: Maybe<Scalars['String']['output']>;
    cnaps: Maybe<Scalars['String']['output']>;
    iban: Maybe<Scalars['String']['output']>;
    ifsc: Maybe<Scalars['String']['output']>;
    invoiceDate: Maybe<Scalars['String']['output']>;
    invoiceNumber: Maybe<Scalars['String']['output']>;
    nationality: Maybe<Scalars['String']['output']>;
    sortCode: Maybe<Scalars['String']['output']>;
};

export type BeneficiaryDetailsInput = {
    acctNumber: InputMaybe<Scalars['String']['input']>;
    bankAddress: InputMaybe<Scalars['String']['input']>;
    bankCode: InputMaybe<Scalars['String']['input']>;
    bankCountry: InputMaybe<Scalars['String']['input']>;
    bankName: InputMaybe<Scalars['String']['input']>;
    beneficiaryAddress: InputMaybe<Scalars['String']['input']>;
    beneficiaryCity: InputMaybe<Scalars['String']['input']>;
    beneficiaryCompanyName: InputMaybe<Scalars['String']['input']>;
    beneficiaryCountry: InputMaybe<Scalars['String']['input']>;
    beneficiaryEntityType: PaymentEntityType;
    beneficiaryFirstName: InputMaybe<Scalars['String']['input']>;
    beneficiaryLastName: InputMaybe<Scalars['String']['input']>;
    beneficiaryPostcode: InputMaybe<Scalars['String']['input']>;
    beneficiaryStateOrProvince: InputMaybe<Scalars['String']['input']>;
    bicSwift: InputMaybe<Scalars['String']['input']>;
    branchCode: InputMaybe<Scalars['String']['input']>;
    charityNumber: InputMaybe<Scalars['String']['input']>;
    clabe: InputMaybe<Scalars['String']['input']>;
    cnaps: InputMaybe<Scalars['String']['input']>;
    iban: InputMaybe<Scalars['String']['input']>;
    ifsc: InputMaybe<Scalars['String']['input']>;
    invoiceDate: InputMaybe<Scalars['String']['input']>;
    invoiceNumber: InputMaybe<Scalars['String']['input']>;
    nationality: InputMaybe<Scalars['String']['input']>;
    sortCode: InputMaybe<Scalars['String']['input']>;
};

export type CanceledPaymentResult = DefaultAnswer | Error;

export type CardWithdrawalInput = {
    accountId: Scalars['ID']['input'];
    address: Scalars['String']['input'];
    amount: Scalars['Float']['input'];
    cardNumber: Scalars['String']['input'];
    country: InputMaybe<Scalars['String']['input']>;
    currency: Scalars['String']['input'];
    dateBirthday: Scalars['DateTime']['input'];
    documentIds: InputMaybe<Array<Scalars['String']['input']>>;
    nationality: Scalars['String']['input'];
    reason: InputMaybe<Scalars['String']['input']>;
    receiverName: Scalars['String']['input'];
    receiverSurname: Scalars['String']['input'];
    region: InputMaybe<Scalars['String']['input']>;
};

export enum CardWithdrawalStatus {
    canceled = 'canceled',
    created = 'created',
    declined = 'declined',
    failed = 'failed',
    processing = 'processing',
    success = 'success',
}

export type CheckDocumentInput = {
    key: Scalars['String']['input'];
    path: Scalars['String']['input'];
    type: UserDocumentType;
};

export type CheckUserExistAnswer = {
    isUserExist: Scalars['Boolean']['output'];
};

export type CheckUserExistResult = CheckUserExistAnswer | Error;

export type Client = {
    createdAt: Scalars['DateTime']['output'];
    elcoinId: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    isActivated: Scalars['Boolean']['output'];
    name: Scalars['String']['output'];
    nationality: Maybe<Scalars['String']['output']>;
    status: UserActivatedStatus;
    stepsStatus: StepsStatus;
    type: UserType;
    updatedAt: Scalars['DateTime']['output'];
};

export type ClientAccountInput = {
    accountName: Scalars['String']['input'];
    city: Scalars['String']['input'];
    country: Scalars['String']['input'];
    id: Scalars['ID']['input'];
    legalEntityType: PaymentEntityType;
    postalCode: Scalars['String']['input'];
    street: Scalars['String']['input'];
};

export type ClientAnswer = {
    elements: Array<Client>;
    totalCount: Scalars['Int']['output'];
};

export type ClientComment = {
    comment: Comment;
    createdAt: Scalars['DateTime']['output'];
};

export type ClientComments = {
    elements: Array<ClientComment>;
};

export type ClientCommentsResult = ClientComments | Error;

export type ClientInternal = {
    email: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    isActivated: Scalars['Boolean']['output'];
    phone: Scalars['String']['output'];
    type: UserType;
};

export type ClientOneInternalResult = ClientInternal | Error;

export type ClientOneResult = Client | Error;

export type ClientResult = ClientAnswer | Error;

export type Comment = {
    comment: Scalars['String']['output'];
    id: Scalars['ID']['output'];
};

export type CommentFilters = {
    limit: InputMaybe<Scalars['Int']['input']>;
};

export type Commission = {
    clientId: Maybe<Scalars['ID']['output']>;
    commissions: Array<CommissionLimits>;
    currency: Scalars['String']['output'];
    id: Scalars['ID']['output'];
    isDefault: Scalars['Boolean']['output'];
    isSepa: Maybe<Scalars['Boolean']['output']>;
    lokaliseKey: Maybe<Scalars['String']['output']>;
    name: Scalars['String']['output'];
    operation: OperationType;
    paymentType: PaymentType;
    type: CommissionType;
    userType: Maybe<UserType>;
};

export type CommissionCreateInput = {
    clientId: Scalars['String']['input'];
    commissions: Array<CommissionLimitInput>;
    parentCommissionId: Scalars['ID']['input'];
};

export type CommissionLimitInput = {
    maxAmount: InputMaybe<Scalars['Float']['input']>;
    maxFee: InputMaybe<Scalars['Float']['input']>;
    minAmount: Scalars['Float']['input'];
    minFee: InputMaybe<Scalars['Float']['input']>;
    percentCommission: InputMaybe<Scalars['Float']['input']>;
    staticCommission: InputMaybe<Scalars['Float']['input']>;
};

export type CommissionLimits = {
    maxAmount: Maybe<Scalars['Float']['output']>;
    maxFee: Maybe<Scalars['Float']['output']>;
    minAmount: Scalars['Float']['output'];
    minFee: Maybe<Scalars['Float']['output']>;
    percentCommission: Maybe<Scalars['Float']['output']>;
    staticCommission: Maybe<Scalars['Float']['output']>;
};

export type CommissionResult = Commission | Error;

export enum CommissionType {
    Acquiring = 'Acquiring',
    CardWithdrawal = 'CardWithdrawal',
    Exchange = 'Exchange',
    Payment = 'Payment',
}

export type CommissionUpdateInput = {
    clientId: InputMaybe<Scalars['String']['input']>;
    commissions: Array<CommissionLimitInput>;
    id: Scalars['ID']['input'];
};

export type CommunityMessageInput = {
    createdAt: Scalars['DateTime']['input'];
    email: Scalars['String']['input'];
    id: Scalars['String']['input'];
    message: Scalars['String']['input'];
    moneyTurnoverAmount: Scalars['Int']['input'];
    moneyTurnoverCurrency: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export enum CompanyActivatedStatus {
    Activated = 'Activated',
    Approved = 'Approved',
    Blocked = 'Blocked',
    Compliance = 'Compliance',
    Created = 'Created',
    Rejected = 'Rejected',
    UnsupportedCountry = 'UnsupportedCountry',
    Updated = 'Updated',
}

export type CompanyAddress = {
    isCompanyAddressSame: Maybe<Scalars['Boolean']['output']>;
    legalAddress: Maybe<Address>;
    postalAddress: Maybe<Address>;
};

export type CompanyAddressInput = {
    isCompanyAddressSame: InputMaybe<Scalars['Boolean']['input']>;
    legalAddress: InputMaybe<AddressInput>;
    postalAddress: InputMaybe<AddressInput>;
};

export type CompanyBiggestClient = {
    country: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['ID']['output']>;
    name: Maybe<Scalars['String']['output']>;
    subjectOfBusiness: Maybe<Scalars['String']['output']>;
    website: Maybe<Scalars['String']['output']>;
};

export type CompanyBiggestClientInput = {
    country: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['ID']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    subjectOfBusiness: InputMaybe<Scalars['String']['input']>;
    website: InputMaybe<Scalars['String']['input']>;
};

export type CompanyDocumentsDeleteAnswer = {
    status: Scalars['Boolean']['output'];
};

export type CompanyDocumentsDeleteResult = CompanyDocumentsDeleteAnswer | Error;

export type CompanyFilters = {
    limit: InputMaybe<Scalars['Int']['input']>;
};

export enum CompanyLegalFormType {
    Limited = 'Limited',
    Llc = 'Llc',
    Ltd = 'Ltd',
    PrivateLimited = 'PrivateLimited',
    PublicLimited = 'PublicLimited',
}

export type CompanyService = {
    annualGross: Maybe<Scalars['String']['output']>;
    companyTradingName: Maybe<Scalars['String']['output']>;
    crimeRelation: Maybe<Scalars['Boolean']['output']>;
    currency: Maybe<Currency>;
    debtsRelation: Maybe<Scalars['Boolean']['output']>;
    externalBankAccount: Maybe<Array<ExternalBankAccount>>;
    id: Maybe<Scalars['ID']['output']>;
    isAccountInAnotherBank: Maybe<Scalars['Boolean']['output']>;
    isAnotherUboExists: Maybe<Scalars['Boolean']['output']>;
    isEurAccount: Maybe<Scalars['Boolean']['output']>;
    isLicenceAvailable: Maybe<Scalars['Boolean']['output']>;
    isMulticurrencyAccount: Maybe<Scalars['Boolean']['output']>;
    isParentCompanyExists: Maybe<Scalars['Boolean']['output']>;
    isRepresentativeExists: Maybe<Scalars['Boolean']['output']>;
    isSeparateDivisionsExists: Maybe<Scalars['Boolean']['output']>;
    isSubmitFinancialTaxReport: Maybe<Scalars['Boolean']['output']>;
    isSwiftAccount: Maybe<Scalars['Boolean']['output']>;
    moneyTurnover: Maybe<Scalars['String']['output']>;
    monthlySpendPlan: Maybe<Scalars['String']['output']>;
    numberOfCustomers: Maybe<Scalars['String']['output']>;
    numberOfPersons: Maybe<Scalars['String']['output']>;
    operationCountry: Maybe<Scalars['String']['output']>;
    parentCompanyInfo: Maybe<Scalars['String']['output']>;
    preferredAccountCurrency: Maybe<Scalars['String']['output']>;
    prosecutionRelation: Maybe<Scalars['Boolean']['output']>;
    separateDivisionsInfo: Maybe<Scalars['String']['output']>;
    spendPlan: Maybe<Scalars['String']['output']>;
    voluntarilyDisclosedCrime: Maybe<Scalars['Boolean']['output']>;
};

export type CompanyServiceInput = {
    annualGross: InputMaybe<Scalars['String']['input']>;
    companyTradingName: InputMaybe<Scalars['String']['input']>;
    crimeRelation: InputMaybe<Scalars['Boolean']['input']>;
    currency: InputMaybe<Scalars['String']['input']>;
    debtsRelation: InputMaybe<Scalars['Boolean']['input']>;
    externalBankAccount: InputMaybe<Array<ExternalBankAccountInput>>;
    id: InputMaybe<Scalars['ID']['input']>;
    isAccountInAnotherBank: InputMaybe<Scalars['Boolean']['input']>;
    isAnotherUboExists: InputMaybe<Scalars['Boolean']['input']>;
    isEurAccount: InputMaybe<Scalars['Boolean']['input']>;
    isLicenceAvailable: InputMaybe<Scalars['Boolean']['input']>;
    isMulticurrencyAccount: InputMaybe<Scalars['Boolean']['input']>;
    isParentCompanyExists: InputMaybe<Scalars['Boolean']['input']>;
    isRepresentativeExists: InputMaybe<Scalars['Boolean']['input']>;
    isSeparateDivisionsExists: InputMaybe<Scalars['Boolean']['input']>;
    isSubmitFinancialTaxReport: InputMaybe<Scalars['Boolean']['input']>;
    isSwiftAccount: InputMaybe<Scalars['Boolean']['input']>;
    moneyTurnover: InputMaybe<Scalars['String']['input']>;
    monthlySpendPlan: InputMaybe<Scalars['String']['input']>;
    numberOfCustomers: InputMaybe<Scalars['String']['input']>;
    numberOfPersons: InputMaybe<Scalars['String']['input']>;
    operationCountry: InputMaybe<Scalars['String']['input']>;
    parentCompanyInfo: InputMaybe<Scalars['String']['input']>;
    preferredAccountCurrency: InputMaybe<Scalars['String']['input']>;
    prosecutionRelation: InputMaybe<Scalars['Boolean']['input']>;
    separateDivisionsInfo: InputMaybe<Scalars['String']['input']>;
    spendPlan: InputMaybe<Scalars['String']['input']>;
    voluntarilyDisclosedCrime: InputMaybe<Scalars['Boolean']['input']>;
};

export type CompanyServiceResult = CompanyService | Error;

export type CompanyStatus = {
    consentToPrivacyPolicyAccepted: Maybe<Scalars['DateTime']['output']>;
    hasAccept: Scalars['Boolean']['output'];
    hasAddress: Scalars['Boolean']['output'];
    hasDocuments: Scalars['Boolean']['output'];
    hasIndustry: Scalars['Boolean']['output'];
    hasPersons: Scalars['Boolean']['output'];
    hasService: Scalars['Boolean']['output'];
    hasShareholders: Scalars['Boolean']['output'];
    termsAccepted: Maybe<Scalars['DateTime']['output']>;
};

export type ContactAccountInput = {
    dateBirthday: Scalars['DateTime']['input'];
    email: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    name: Scalars['String']['input'];
    phone: Scalars['String']['input'];
};

export type Conversion = {
    amount: Scalars['Float']['output'];
    buyAccount: Scalars['String']['output'];
    buyAmount: Scalars['Float']['output'];
    buyCurrency: Currency;
    clientId: Scalars['String']['output'];
    conversionDate: Maybe<Scalars['DateTime']['output']>;
    createdAt: Scalars['DateTime']['output'];
    elcoinStatus: ElcoinConversionStatus;
    fee: Maybe<Fee>;
    id: Scalars['ID']['output'];
    isAgreement: Scalars['Boolean']['output'];
    sellAccount: Scalars['String']['output'];
    sellAmount: Scalars['Float']['output'];
    sellCurrency: Currency;
    settlementDate: Maybe<Scalars['DateTime']['output']>;
    statuses: Array<PaymentStatuses>;
    type: ExchangeType;
    updatedAt: Scalars['DateTime']['output'];
};

export type ConversionAnswer = {
    elements: Array<Conversion>;
    totalCount: Scalars['Int']['output'];
};

export type ConversionAnswerResult = ConversionAnswer | Error;

export type ConversionFilter = {
    elcoinStatus: InputMaybe<ElcoinConversionStatus>;
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    paymentDate: InputMaybe<Range>;
    search: InputMaybe<Scalars['String']['input']>;
    status: InputMaybe<PaymentStatus>;
};

export type ConversionReservation = {
    amount: Scalars['Float']['output'];
    buyCurrency: Currency;
    fee: Maybe<Fee>;
    id: Scalars['ID']['output'];
    rate: Scalars['Float']['output'];
    resultAmount: Scalars['Float']['output'];
    sellCurrency: Currency;
    type: ExchangeType;
};

export type ConversionReservationInput = {
    amount: Scalars['Float']['input'];
    buyCurrency: Scalars['String']['input'];
    isAgreement: Scalars['Boolean']['input'];
    sellCurrency: Scalars['String']['input'];
    type: ExchangeType;
};

export type ConversionReservationResult = ConversionReservation | Error;

export type ConversionResult = Conversion | Error;

export type Country = {
    allowToOpenAccount: Scalars['Boolean']['output'];
    allowToReceiptFunds: Scalars['Boolean']['output'];
    allowToSendFunds: Scalars['Boolean']['output'];
    alpha2: Maybe<Scalars['String']['output']>;
    blacklist: Scalars['Boolean']['output'];
    code: Scalars['String']['output'];
    local: Scalars['Boolean']['output'];
    name: Maybe<Scalars['String']['output']>;
    purposeCodeRequired: Scalars['Boolean']['output'];
    purposeCodes: Array<Maybe<PurposeCode>>;
    sepa: Scalars['Boolean']['output'];
    swift: Scalars['Boolean']['output'];
};

export type CountryCurrency = {
    country: Scalars['String']['output'];
    currency: Currency;
};

export type CountryFilter = {
    allowToOpenAccount: InputMaybe<Scalars['Boolean']['input']>;
    allowToReceiptFunds: InputMaybe<Scalars['Boolean']['input']>;
    allowToSendFunds: InputMaybe<Scalars['Boolean']['input']>;
    blacklist: InputMaybe<Scalars['Boolean']['input']>;
    local: InputMaybe<Scalars['Boolean']['input']>;
    sepa: InputMaybe<Scalars['Boolean']['input']>;
    swift: InputMaybe<Scalars['Boolean']['input']>;
};

export type CreateCardWithdrawalAnswer = {
    accountId: Scalars['ID']['output'];
    amount: Scalars['Float']['output'];
    balance: Scalars['Float']['output'];
    client: Maybe<Client>;
    clientId: Scalars['ID']['output'];
    createdAt: Scalars['DateTime']['output'];
    currency: Scalars['String']['output'];
    fee: Maybe<Fee>;
    id: Scalars['ID']['output'];
    operation: OperationType;
    reason: Maybe<Scalars['String']['output']>;
    status: PaymentStatus;
    statuses: Array<PaymentStatuses>;
    total: Scalars['Float']['output'];
    type: PaymentType;
    updatedAt: Scalars['DateTime']['output'];
};

export type CreateCardWithdrawalInternalResult = {
    created: Scalars['Boolean']['output'];
};

export type CreateCardWithdrawalResult = CreateCardWithdrawalAnswer | Error;

export type CreatedUserCompany = {
    companyName: Scalars['String']['output'];
    id: Scalars['ID']['output'];
};

export type Currency = {
    code: Scalars['String']['output'];
    currency: Scalars['String']['output'];
    digits: Scalars['Int']['output'];
    exchangeEnabled: Scalars['Boolean']['output'];
    isActive: Scalars['Boolean']['output'];
    number: Scalars['Int']['output'];
};

export type CurrencyFilters = {
    isActive: Scalars['Boolean']['input'];
};

export type DateSort = {
    createdAt: InputMaybe<SortEnum>;
    updatedAt: InputMaybe<SortEnum>;
};

export type DefaultAnswer = {
    status: Scalars['Boolean']['output'];
};

export type DefaultAnswerResult = DefaultAnswer | Error;

export type DefaultCurrency = {
    code: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export type DefaultResult = DefaultAnswer | Error;

export type DestroyAddressBookResult = {
    status: Scalars['Boolean']['output'];
};

export enum ElcoinConversionStatus {
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    INVALID = 'INVALID',
    NEW = 'NEW',
}

export enum ElcoinPaymentStatus {
    ABORT_PROCESSING = 'ABORT_PROCESSING',
    ACCEPTED = 'ACCEPTED',
    AML = 'AML',
    CANCELLED = 'CANCELLED',
    COMPLETED = 'COMPLETED',
    INVALID = 'INVALID',
    LIQUIDITY_TRANSFER = 'LIQUIDITY_TRANSFER',
    MANUAL_NOSTRO = 'MANUAL_NOSTRO',
    MANUAL_PROCESSING = 'MANUAL_PROCESSING',
    NEW = 'NEW',
    NOSTRO = 'NOSTRO',
    NOSTRO_MANUAL = 'NOSTRO_MANUAL',
    NOT_IDENTIFIED = 'NOT_IDENTIFIED',
    PREPARE = 'PREPARE',
    READY = 'READY',
    READY_ERROR = 'READY_ERROR',
    REJECTED = 'REJECTED',
    RETURNED = 'RETURNED',
    SENT = 'SENT',
    SENT_ACK = 'SENT_ACK',
    SENT_NACK = 'SENT_NACK',
    SENT_NACK_NOT_SENT = 'SENT_NACK_NOT_SENT',
    SENT_N_A = 'SENT_N_A',
    VALUED = 'VALUED',
    VTRN = 'VTRN',
}

export type Error = {
    extensions: Maybe<Extensions>;
    message: Scalars['String']['output'];
    path: Maybe<Array<Scalars['String']['output']>>;
};

export type ExchangeRate = {
    buyAmount: Scalars['Float']['output'];
    buyCurrency: Currency;
    rate: Scalars['String']['output'];
    sellAmount: Scalars['Float']['output'];
    sellCurrency: Currency;
    settlementTime: Scalars['DateTime']['output'];
    type: ExchangeType;
};

export enum ExchangeType {
    Buy = 'Buy',
    Sell = 'Sell',
}

export type Extensions = {
    invalidArgs: Array<InvalidArgs>;
};

export type ExternalApplicantLink = {
    href: Maybe<Scalars['String']['output']>;
};

export type ExternalBankAccount = {
    accountNumber: Maybe<Scalars['String']['output']>;
    bicCode: Maybe<Scalars['String']['output']>;
    name: Maybe<Scalars['String']['output']>;
};

export type ExternalBankAccountInput = {
    accountNumber: InputMaybe<Scalars['String']['input']>;
    bicCode: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
};

export type FeatureStatus = {
    editable: Scalars['Boolean']['output'];
    featureTag: UserFeatureTag;
    message: Maybe<Scalars['String']['output']>;
    requiredFields: Array<Maybe<FeatureTagRequiredDataField>>;
    status: FeatureTagStatus;
    tagTitle: Scalars['String']['output'];
};

export type FeatureTagRequiredData = {
    fields: Array<Maybe<FeatureTagRequiredDataField>>;
};

export type FeatureTagRequiredDataField = {
    name: Scalars['String']['output'];
    required: Scalars['Boolean']['output'];
    title: Scalars['String']['output'];
    value: Maybe<Scalars['String']['output']>;
};

export type FeatureTagRequiredDataFieldInput = {
    name: Scalars['String']['input'];
    value: Scalars['String']['input'];
};

export type FeatureTagRequiredDataInput = {
    fields: Array<InputMaybe<FeatureTagRequiredDataFieldInput>>;
};

export type FeatureTagRequiredDataResult = Error | FeatureTagRequiredData;

export enum FeatureTagStatus {
    Approved = 'Approved',
    Compliance = 'Compliance',
    New = 'New',
    Rejected = 'Rejected',
}

export type Fee = {
    amount: Scalars['Float']['output'];
    total: Scalars['Float']['output'];
};

export type FeeInput = {
    amount: Scalars['Float']['input'];
    bic: InputMaybe<Scalars['String']['input']>;
    currency: InputMaybe<Scalars['String']['input']>;
    operation: OperationType;
    type: CommissionType;
};

export type FeedbackMessageInput = {
    createdAt: Scalars['DateTime']['input'];
    description: Scalars['String']['input'];
    email: Scalars['String']['input'];
    files: Array<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    subject: Scalars['String']['input'];
};

export type File = {
    comment: Maybe<Comment>;
    comments: Array<Comment>;
    filename: Scalars['String']['output'];
    key: Scalars['ID']['output'];
    path: Scalars['String']['output'];
};

export type GetAvailableCountriesResult = Error | GetCountriesAnswer;

export type GetAvailableFreePaymentsAnswer = {
    sepaIn: Scalars['Int']['output'];
    sepaOut: Scalars['Int']['output'];
    swiftIn: Scalars['Int']['output'];
    swiftOut: Scalars['Int']['output'];
};

export type GetCountriesAnswer = {
    countries: Array<Country>;
};

export type GetExternalApplicantLinkResult = Error | ExternalApplicantLink;

export type GetExternalRegistrationLinkResult = Error | UserVerificationLink;

export type GetMailById = {
    email: Scalars['String']['output'];
};

export type GetMailResult = Error | GetMailById;

export type GetStatusAnswer = {
    activation: Scalars['Boolean']['output'];
};

export type GetVerificationLinkResult = Error | UserVerificationLink;

export type GetVerificationParamsResult = Error | UserVerificationParams;

export type HoldInstruction = {
    accountId: Scalars['ID']['output'];
    amount: Scalars['Float']['output'];
    client: Maybe<Client>;
    clientId: Scalars['ID']['output'];
    createdAt: Scalars['DateTime']['output'];
    currency: Currency;
    details: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    total: Scalars['Float']['output'];
};

export type HoldInstructionAnswer = {
    elements: Array<HoldInstruction>;
    total: Scalars['Int']['output'];
};

export type HoldInstructionFilter = {
    accountId: Scalars['ID']['input'];
    clientId: Scalars['ID']['input'];
    currency: Scalars['String']['input'];
};

export type HoldInstructionResult = Error | HoldInstructionAnswer;

export type IbanqCallbackUrlResponse = {
    url: Scalars['String']['output'];
};

export type IbanqCredentialsInput = {
    clientId: Scalars['ID']['input'];
    ibanqCallbackSecret: Scalars['String']['input'];
    ibanqClientId: Scalars['String']['input'];
    ibanqClientSecret: Scalars['String']['input'];
    ibanqPassword: Scalars['String']['input'];
    ibanqUsername: Scalars['String']['input'];
};

export type IbanqCredentialsResponse = {
    ibanqClientId: Scalars['String']['output'];
    ibanqUsername: Scalars['String']['output'];
};

export type IbanqSepaRequisites = {
    accountName: Scalars['String']['output'];
    bankAddress: Scalars['String']['output'];
    bankName: Scalars['String']['output'];
    beneficiaryAddress: Scalars['String']['output'];
    bicSwiftCode: Scalars['String']['output'];
    iban: Scalars['String']['output'];
};

export type IbanqSepaRequisitesInput = {
    accountName: Scalars['String']['input'];
    bankAddress: Scalars['String']['input'];
    bankName: Scalars['String']['input'];
    beneficiaryAddress: Scalars['String']['input'];
    bicSwiftCode: Scalars['String']['input'];
    iban: Scalars['String']['input'];
};

export type IbanqSepaRequisitesResult = Error | IbanqSepaRequisites;

export enum IdentificationType {
    citizenship_papers = 'citizenship_papers',
    credential_de_elector = 'credential_de_elector',
    drivers_license = 'drivers_license',
    drivers_license_canadian = 'drivers_license_canadian',
    employer_identification_number = 'employer_identification_number',
    existing_credit_card_details = 'existing_credit_card_details',
    green_card = 'green_card',
    incorporation_number = 'incorporation_number',
    matricula_consular = 'matricula_consular',
    national_id = 'national_id',
    none = 'none',
    others = 'others',
    passport = 'passport',
    registro_federal_de_contribuyentes = 'registro_federal_de_contribuyentes',
    social_insurance_number = 'social_insurance_number',
    social_security_number = 'social_security_number',
    visa = 'visa',
}

export type InternalBeneficiaryDetails = {
    bicSwift: Scalars['String']['output'];
    iban: Scalars['String']['output'];
    name: Scalars['String']['output'];
    type: PaymentEntityType;
};

export type InternalBeneficiaryDetailsResult = Error | InternalBeneficiaryDetails;

export type InternalGetAccountDtoAnswer = {
    accountName: Scalars['String']['output'];
    applicantId: Scalars['String']['output'];
    city: Maybe<Scalars['String']['output']>;
    clientId: Scalars['String']['output'];
    country: Scalars['String']['output'];
    dateBirthday: Maybe<Scalars['DateTime']['output']>;
    email: Scalars['String']['output'];
    lastName: Scalars['String']['output'];
    legalAddress: Maybe<Address>;
    legalEntityType: PaymentEntityType;
    name: Scalars['String']['output'];
    phone: Scalars['String']['output'];
    postalAddress: Maybe<Address>;
    postalCode: Maybe<Scalars['String']['output']>;
    street: Scalars['String']['output'];
};

export type InternalGetAccountDtoResult = Error | InternalGetAccountDtoAnswer;

export type InternalUpdateCardWithdrawal = {
    id: Scalars['ID']['input'];
    status: CardWithdrawalStatus;
};

export type InternalUpdateCardWithdrawalsAnswer = {
    status: Scalars['Boolean']['output'];
};

export type InternalUpdateCardWithdrawalsResult = Error | InternalUpdateCardWithdrawalsAnswer;

export type InvalidArgs = {
    message: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export type IpInfoData = {
    area: Scalars['Int']['output'];
    city: Scalars['String']['output'];
    country: Scalars['String']['output'];
    eu: Scalars['String']['output'];
    ll: Array<Scalars['Float']['output']>;
    metro: Scalars['Int']['output'];
    region: Scalars['String']['output'];
    timezone: Scalars['String']['output'];
};

export type IsCardAllowedResult = {
    countryCode: Scalars['String']['output'];
    isAllowed: Scalars['Boolean']['output'];
    message: Maybe<Scalars['String']['output']>;
};

export type IsUserHasAccessToCompanyAnswer = {
    hasAccess: Scalars['Boolean']['output'];
};

export type IsUserHasAccessToCompanyResult = Error | IsUserHasAccessToCompanyAnswer;

export type JoinWaitingListResult = Error | WaitingListElement;

export type Legal = {
    companyLegalForm: Maybe<CompanyLegalFormType>;
    companyName: Scalars['String']['output'];
    companyNumber: Maybe<Scalars['String']['output']>;
    companyService: Maybe<CompanyService>;
    companySite: Maybe<Scalars['String']['output']>;
    companyTaxNumber: Maybe<Scalars['String']['output']>;
    documents: Maybe<Array<UserDocument>>;
    industryDescription: Maybe<Scalars['String']['output']>;
    isCompanyAddressSame: Maybe<Scalars['Boolean']['output']>;
    isConductBusiness: Maybe<Scalars['Boolean']['output']>;
    isGroupPart: Maybe<Scalars['Boolean']['output']>;
    legalAddress: Maybe<Address>;
    postalAddress: Maybe<Address>;
    registrationCountry: Maybe<Scalars['String']['output']>;
    registrationDate: Maybe<Scalars['DateTime']['output']>;
};

export type LegalDocumentsArgs = {
    documentStatuses: InputMaybe<Array<UserDocumentStatus>>;
};

export type LegalAnswer = {
    id: Scalars['String']['output'];
    legal: Legal;
    relation: Relation;
};

export type LegalDataInput = {
    companyLegalForm: InputMaybe<CompanyLegalFormType>;
    companyName: Scalars['String']['input'];
    companyNumber: InputMaybe<Scalars['String']['input']>;
    companySite: InputMaybe<Scalars['String']['input']>;
    companyTaxNumber: InputMaybe<Scalars['String']['input']>;
    documents: InputMaybe<Array<UserDocumentInput>>;
    id: InputMaybe<Scalars['ID']['input']>;
    industryDescription: InputMaybe<Scalars['String']['input']>;
    isCompanyAddressSame: InputMaybe<Scalars['Boolean']['input']>;
    isConductBusiness: InputMaybe<Scalars['Boolean']['input']>;
    isGroupPart: InputMaybe<Scalars['Boolean']['input']>;
    legalAddress: InputMaybe<AddressInput>;
    postalAddress: InputMaybe<AddressInput>;
    registrationCountry: InputMaybe<Scalars['String']['input']>;
    registrationDate: InputMaybe<Scalars['DateTime']['input']>;
};

export type LokalizeValue = {
    currency: Scalars['String']['output'];
    lokalizeKey: Scalars['String']['output'];
    value: Scalars['Float']['output'];
};

export type Mutation = {
    acceptTerms: DefaultResult;
    addComment: Comment;
    addFileComment: File;
    addressBookUpdate: AddressBookUpdateResult;
    adminAddFeatureTag: DefaultResult;
    adminAddPrivateClientComment: DefaultResult;
    adminApprovePayment: DefaultAnswer;
    adminClientRegistration: UserRegistrationResult;
    adminCompanyActivatedStatusUpdate: DefaultResult;
    adminCreateAccount: DefaultAnswer;
    adminCreateCommissionTemplate: CommissionResult;
    adminCreateIbanqCredentials: DefaultAnswer;
    adminDeleteCommissionTemplate: DefaultAnswer;
    adminNextStatusFeatureTag: DefaultResult;
    adminPaymentAddDocuments: DefaultAnswer;
    adminRejectFeatureTag: DefaultResult;
    adminRejectPayment: DefaultAnswer;
    adminSetFeatureTagData: DefaultResult;
    adminUpdateAccountAdministration: AccountAdministrationResult;
    adminUpdateAccountAdministrationByType: AccountAdministrationResult;
    adminUpdateAccountCurrencies: AccountCurrencyResult;
    adminUpdateCommissionTemplate: CommissionResult;
    adminUserActivatedStatusUpdate: DefaultResult;
    cancelPayment: DefaultAnswer;
    checkPhoneCode: SendPhoneCodeResult;
    confirmSignPayment: DefaultAnswer;
    createCardWithdrawal: CreateCardWithdrawalResult;
    createConversion: ConversionResult;
    createConversionReservation: ConversionReservationResult;
    createPayment: Payment;
    deleteAddressBook: DestroyAddressBookResult;
    deleteFirebaseToken: DefaultResult;
    internalCheckOTPCode: DefaultResult;
    internalCreateAccount: DefaultAnswerResult;
    internalCreateCardWithdrawal: CreateCardWithdrawalInternalResult;
    internalCreateIbanqCredentials: DefaultAnswerResult;
    internalCreateIbanqGbpSepaRequisites: DefaultAnswerResult;
    internalCreateIbanqSepaRequisites: DefaultAnswerResult;
    internalCreateUser: UserCreateExternalIdResult;
    internalDeleteIbanqGbpSepaRequisites: DefaultAnswerResult;
    internalSendCommunityMessage: SendMailResult;
    internalSendEmailChangedMail: SendMailResult;
    internalSendEmailChangedSMS: DefaultResult;
    internalSendFeedbackMessage: SendMailResult;
    internalSendOTPCode: SendPhoneCodeResult;
    internalSendPasswordResetSuccessfulMail: SendMailResult;
    internalSendRegistrationMail: SendMailResult;
    internalSendStartLoginMail: SendMailResult;
    internalUpdateAccount: AccountDefaultResult;
    internalUpdateAccountAddress: DefaultAnswer;
    internalUpdateAccountStatus: DefaultAnswerResult;
    internalUserCheckPreferredOtp: DefaultResult;
    internalUserSendPreferredOtp: DefaultResult;
    joinWaitingList: JoinWaitingListResult;
    paymentAddDocuments: DefaultAnswer;
    removeComment: DefaultAnswer;
    removeFileComment: DefaultAnswer;
    saveFirebaseToken: DefaultResult;
    sendFeedback: SendMessage;
    sendJoin: SendMailResult;
    sendMailVerificationLink: SendMailResult;
    sendMessage: SendMessage;
    sendNotificationPush: SendPhoneCodeStatus;
    sendPhoneCode: SendPhoneCodeStatus;
    sendPhoneVerificationLink: SendPhoneCodeResult;
    sendRegistrationMail: SendMailResult;
    sendResetPasswordMail: SendMailResult;
    sendStartLogin: SendMailResult;
    sendSuccessfullyUnsubscribed: SendMailResult;
    signPayment: DefaultAnswer;
    startSignPayment: SignedPaymentResult;
    updateAccountCurrencies: AccountCurrencyResult;
    updatePushLoginStatus: DefaultResult;
    uploadFile: File;
    userActivateGoogleAuthenticator: ActivateGoogleAuthenticatorResult;
    userAddFeatureTag: DefaultResult;
    userBiometryLogin: UserLoginResult;
    userConfirmLogin: UserLoginResult;
    userConfirmPasswordReset: DefaultResult;
    userConfirmPasswordUpdate: DefaultAnswer;
    userConfirmRegistration: UserLoginResult;
    userCreateExternalId: UserCreateExternalIdResult;
    userDeletePin: DefaultResult;
    userEmailUpdate: DefaultResult;
    userExit: DefaultAnswer;
    userGoogleAuthenticatorLogin: UserLoginResult;
    userLogin: UserLoginResult;
    userLoginByExternalId: UserLoginResult;
    userMobileConfirmLogin: UserLoginResult;
    userMobilePasswordReset: DefaultAnswer;
    userMobileStartLogin: UserMobileStartLoginResult;
    userPasswordReset: DefaultAnswer;
    userPasswordUpdate: DefaultAnswer;
    userPinLogin: UserLoginResult;
    userPushNotificationLogin: UserPushLoginResult;
    userRegistration: UserLoginResult;
    userResetPasswordUpdate: DefaultResult;
    userSetAppPreferredLoginMethod: DefaultResult;
    userSetBiometryLogin: UserSetBiometryLoginResult;
    userSetOnboardingStep: UserSetOnboardingStepResult;
    userSetPin: DefaultResult;
    userSetPinWithPreAuth: DefaultResult;
    userSetWebPreferredLoginMethod: DefaultResult;
    userStartLogin: UserStartLoginResult;
    userStartPasswordReset: DefaultAnswer;
    userStartPasswordUpdate: DefaultAnswer;
    userStartRegistration: UserStartRegistration;
    userTerminateSessions: DefaultResult;
    userVerifyCodeGoogleAuthenticator: DefaultResult;
};

export type MutationAddCommentArgs = {
    comment: Scalars['String']['input'];
};

export type MutationAddFileCommentArgs = {
    commentId: InputMaybe<Scalars['ID']['input']>;
    fileId: Scalars['ID']['input'];
};

export type MutationAddressBookUpdateArgs = {
    addressBook: AddressBookInput;
};

export type MutationAdminAddFeatureTagArgs = {
    clientId: Scalars['String']['input'];
    userFeatureTag: UserFeatureTag;
};

export type MutationAdminAddPrivateClientCommentArgs = {
    commentId: Scalars['String']['input'];
    userId: Scalars['String']['input'];
};

export type MutationAdminApprovePaymentArgs = {
    paymentId: Scalars['ID']['input'];
};

export type MutationAdminClientRegistrationArgs = {
    companyName: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    legalCountry: InputMaybe<Scalars['String']['input']>;
    nationality: InputMaybe<Scalars['String']['input']>;
    phone: Scalars['String']['input'];
    postalCountry: InputMaybe<Scalars['String']['input']>;
    residenceCountry: InputMaybe<Scalars['String']['input']>;
    type: UserType;
};

export type MutationAdminCompanyActivatedStatusUpdateArgs = {
    companyId: Scalars['ID']['input'];
    status: CompanyActivatedStatus;
};

export type MutationAdminCreateAccountArgs = {
    clientId: Scalars['ID']['input'];
};

export type MutationAdminCreateCommissionTemplateArgs = {
    commission: CommissionCreateInput;
};

export type MutationAdminCreateIbanqCredentialsArgs = {
    credentials: IbanqCredentialsInput;
};

export type MutationAdminDeleteCommissionTemplateArgs = {
    id: Scalars['ID']['input'];
};

export type MutationAdminNextStatusFeatureTagArgs = {
    clientId: Scalars['String']['input'];
    userFeatureTag: UserFeatureTag;
};

export type MutationAdminPaymentAddDocumentsArgs = {
    documentIds: Array<Scalars['String']['input']>;
    paymentId: Scalars['ID']['input'];
};

export type MutationAdminRejectFeatureTagArgs = {
    clientId: Scalars['String']['input'];
    featureTag: UserFeatureTag;
    message: InputMaybe<Scalars['String']['input']>;
    status: FeatureTagStatus;
};

export type MutationAdminRejectPaymentArgs = {
    message: Scalars['String']['input'];
    paymentId: Scalars['ID']['input'];
};

export type MutationAdminSetFeatureTagDataArgs = {
    clientId: Scalars['String']['input'];
    requiredData: FeatureTagRequiredDataInput;
    userFeatureTag: UserFeatureTag;
};

export type MutationAdminUpdateAccountAdministrationArgs = {
    clientId: InputMaybe<Scalars['ID']['input']>;
    values: AccountAdministrationInput;
};

export type MutationAdminUpdateAccountAdministrationByTypeArgs = {
    userType: UserType;
    values: AccountAdministrationInput;
};

export type MutationAdminUpdateAccountCurrenciesArgs = {
    clientId: Scalars['ID']['input'];
    currencies: Array<Scalars['String']['input']>;
};

export type MutationAdminUpdateCommissionTemplateArgs = {
    commission: CommissionUpdateInput;
};

export type MutationAdminUserActivatedStatusUpdateArgs = {
    status: UserActivatedStatus;
    userId: Scalars['String']['input'];
};

export type MutationCancelPaymentArgs = {
    paymentId: Scalars['ID']['input'];
};

export type MutationCheckPhoneCodeArgs = {
    code: Scalars['String']['input'];
    phone: Scalars['String']['input'];
};

export type MutationConfirmSignPaymentArgs = {
    otp: Scalars['String']['input'];
    paymentId: Scalars['ID']['input'];
};

export type MutationCreateCardWithdrawalArgs = {
    cardWithdrawal: CardWithdrawalInput;
};

export type MutationCreateConversionArgs = {
    conversionReservationId: Scalars['ID']['input'];
};

export type MutationCreateConversionReservationArgs = {
    conversion: ConversionReservationInput;
};

export type MutationCreatePaymentArgs = {
    payment: PaymentInput;
};

export type MutationDeleteAddressBookArgs = {
    id: InputMaybe<Scalars['ID']['input']>;
};

export type MutationDeleteFirebaseTokenArgs = {
    deviceToken: Scalars['String']['input'];
};

export type MutationInternalCheckOtpCodeArgs = {
    otpCode: Scalars['String']['input'];
    userId: Scalars['String']['input'];
};

export type MutationInternalCreateAccountArgs = {
    clientId: Scalars['ID']['input'];
};

export type MutationInternalCreateCardWithdrawalArgs = {
    address: Scalars['String']['input'];
    amount: Scalars['Int']['input'];
    cardNumber: Scalars['String']['input'];
    clientId: Scalars['ID']['input'];
    createdAt: Scalars['DateTime']['input'];
    datebirthday: Scalars['DateTime']['input'];
    firstname: Scalars['String']['input'];
    lastname: Scalars['String']['input'];
    nationality: Scalars['String']['input'];
    uniqId: Scalars['ID']['input'];
};

export type MutationInternalCreateIbanqCredentialsArgs = {
    credentials: IbanqCredentialsInput;
};

export type MutationInternalCreateIbanqGbpSepaRequisitesArgs = {
    clientId: Scalars['ID']['input'];
    requisites: IbanqSepaRequisitesInput;
};

export type MutationInternalCreateIbanqSepaRequisitesArgs = {
    clientId: Scalars['ID']['input'];
    requisites: IbanqSepaRequisitesInput;
};

export type MutationInternalCreateUserArgs = {
    callbackUrl: Scalars['String']['input'];
    companyName: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    legalCountry: InputMaybe<Scalars['String']['input']>;
    nationality: InputMaybe<Scalars['String']['input']>;
    partnerName: Scalars['String']['input'];
    password: Scalars['String']['input'];
    phone: Scalars['String']['input'];
    postalCountry: InputMaybe<Scalars['String']['input']>;
    residenceCountry: InputMaybe<Scalars['String']['input']>;
    shareToken: InputMaybe<Scalars['String']['input']>;
    type: UserType;
};

export type MutationInternalDeleteIbanqGbpSepaRequisitesArgs = {
    clientId: Scalars['ID']['input'];
};

export type MutationInternalSendCommunityMessageArgs = {
    communityMessage: CommunityMessageInput;
};

export type MutationInternalSendEmailChangedMailArgs = {
    newEmail: Scalars['String']['input'];
    oldEmail: Scalars['String']['input'];
};

export type MutationInternalSendEmailChangedSmsArgs = {
    newEmail: Scalars['String']['input'];
    phone: Scalars['String']['input'];
};

export type MutationInternalSendFeedbackMessageArgs = {
    feedbackMessage: FeedbackMessageInput;
};

export type MutationInternalSendOtpCodeArgs = {
    email: Scalars['String']['input'];
    firebaseToken: InputMaybe<Scalars['String']['input']>;
    phone: Scalars['String']['input'];
    userId: Scalars['String']['input'];
};

export type MutationInternalSendPasswordResetSuccessfulMailArgs = {
    email: Scalars['String']['input'];
};

export type MutationInternalSendRegistrationMailArgs = {
    email: Scalars['String']['input'];
};

export type MutationInternalSendStartLoginMailArgs = {
    email: Scalars['String']['input'];
};

export type MutationInternalUpdateAccountArgs = {
    account: AccountInput;
};

export type MutationInternalUpdateAccountAddressArgs = {
    address: AddressInput;
    addressType: AddressType;
    clientId: Scalars['ID']['input'];
};

export type MutationInternalUpdateAccountStatusArgs = {
    clientId: Scalars['ID']['input'];
    status: UserActivatedStatus;
};

export type MutationInternalUserCheckPreferredOtpArgs = {
    otp: Scalars['String']['input'];
    userId: Scalars['ID']['input'];
};

export type MutationInternalUserSendPreferredOtpArgs = {
    userId: Scalars['ID']['input'];
};

export type MutationJoinWaitingListArgs = {
    email: Scalars['String']['input'];
    message: Scalars['String']['input'];
    token: Scalars['String']['input'];
};

export type MutationPaymentAddDocumentsArgs = {
    documentIds: Array<Scalars['String']['input']>;
    paymentId: Scalars['ID']['input'];
};

export type MutationRemoveCommentArgs = {
    id: Scalars['ID']['input'];
};

export type MutationRemoveFileCommentArgs = {
    commentId: Scalars['ID']['input'];
    fileId: Scalars['ID']['input'];
};

export type MutationSaveFirebaseTokenArgs = {
    deviceToken: Scalars['String']['input'];
    firebaseToken: Scalars['String']['input'];
};

export type MutationSendFeedbackArgs = {
    description: Scalars['String']['input'];
    email: Scalars['String']['input'];
    files: Array<Scalars['String']['input']>;
    name: Scalars['String']['input'];
    recaptchaToken: Scalars['String']['input'];
    subject: Scalars['String']['input'];
};

export type MutationSendJoinArgs = {
    mails: Array<SendMailJoin>;
};

export type MutationSendMailVerificationLinkArgs = {
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    link: Scalars['String']['input'];
};

export type MutationSendMessageArgs = {
    email: Scalars['String']['input'];
    message: Scalars['String']['input'];
    moneyTurnoverAmount: Scalars['Int']['input'];
    moneyTurnoverCurrency: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type MutationSendNotificationPushArgs = {
    body: Scalars['String']['input'];
    firebaseToken: Scalars['String']['input'];
    title: Scalars['String']['input'];
};

export type MutationSendPhoneCodeArgs = {
    phone: Scalars['String']['input'];
    token: InputMaybe<Scalars['String']['input']>;
};

export type MutationSendPhoneVerificationLinkArgs = {
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    link: Scalars['String']['input'];
    phone: Scalars['String']['input'];
};

export type MutationSendRegistrationMailArgs = {
    email: Scalars['String']['input'];
    token: InputMaybe<Scalars['String']['input']>;
};

export type MutationSendResetPasswordMailArgs = {
    code: Scalars['String']['input'];
    email: Scalars['String']['input'];
};

export type MutationSendStartLoginArgs = {
    email: Scalars['String']['input'];
};

export type MutationSendSuccessfullyUnsubscribedArgs = {
    email: Scalars['String']['input'];
};

export type MutationSignPaymentArgs = {
    otp: Scalars['String']['input'];
    paymentId: Scalars['ID']['input'];
    phone: Scalars['String']['input'];
};

export type MutationStartSignPaymentArgs = {
    paymentId: Scalars['ID']['input'];
};

export type MutationUpdateAccountCurrenciesArgs = {
    currencies: Array<Scalars['String']['input']>;
};

export type MutationUpdatePushLoginStatusArgs = {
    action: PushLoginStatus;
    otp: Scalars['String']['input'];
};

export type MutationUploadFileArgs = {
    commentId: InputMaybe<Scalars['ID']['input']>;
    file: Scalars['Upload']['input'];
};

export type MutationUserAddFeatureTagArgs = {
    userFeatureTag: UserFeatureTag;
};

export type MutationUserBiometryLoginArgs = {
    bioToken: Scalars['String']['input'];
};

export type MutationUserConfirmLoginArgs = {
    biometry: InputMaybe<Scalars['Boolean']['input']>;
    email: Scalars['String']['input'];
    otp: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationUserConfirmPasswordResetArgs = {
    code: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationUserConfirmPasswordUpdateArgs = {
    currentPassword: Scalars['String']['input'];
    otp: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationUserConfirmRegistrationArgs = {
    otp: Scalars['String']['input'];
    userId: Scalars['String']['input'];
};

export type MutationUserCreateExternalIdArgs = {
    email: Scalars['String']['input'];
    otp: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    token: Scalars['String']['input'];
};

export type MutationUserDeletePinArgs = {
    deviceToken: Scalars['String']['input'];
};

export type MutationUserEmailUpdateArgs = {
    email: Scalars['String']['input'];
};

export type MutationUserGoogleAuthenticatorLoginArgs = {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
    totp: Scalars['String']['input'];
};

export type MutationUserLoginArgs = {
    biometry: InputMaybe<Scalars['Boolean']['input']>;
    email: Scalars['String']['input'];
    otp: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
};

export type MutationUserLoginByExternalIdArgs = {
    externalId: Scalars['String']['input'];
    partnerName: Scalars['String']['input'];
};

export type MutationUserMobileConfirmLoginArgs = {
    biometry: InputMaybe<Scalars['Boolean']['input']>;
    email: Scalars['String']['input'];
    otp: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationUserMobilePasswordResetArgs = {
    email: Scalars['String']['input'];
    token: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserMobileStartLoginArgs = {
    email: Scalars['String']['input'];
    loginMethod: InputMaybe<WebPreferredLoginMethod>;
    password: Scalars['String']['input'];
};

export type MutationUserPasswordResetArgs = {
    email: Scalars['String']['input'];
    token: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserPasswordUpdateArgs = {
    currentPassword: Scalars['String']['input'];
    otp: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationUserPinLoginArgs = {
    biometry: InputMaybe<Scalars['Boolean']['input']>;
    deviceToken: Scalars['String']['input'];
    pin: Scalars['String']['input'];
};

export type MutationUserPushNotificationLoginArgs = {
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationUserRegistrationArgs = {
    companyName: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    firstName: Scalars['String']['input'];
    gaUserId: InputMaybe<Scalars['String']['input']>;
    lastName: Scalars['String']['input'];
    legalCountry: InputMaybe<Scalars['String']['input']>;
    nationality: InputMaybe<Scalars['String']['input']>;
    otp: Scalars['String']['input'];
    password: Scalars['String']['input'];
    phone: Scalars['String']['input'];
    postalCountry: InputMaybe<Scalars['String']['input']>;
    residenceCountry: InputMaybe<Scalars['String']['input']>;
    type: UserType;
};

export type MutationUserResetPasswordUpdateArgs = {
    code: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationUserSetAppPreferredLoginMethodArgs = {
    preferredLoginMethod: AppPreferredLoginMethod;
};

export type MutationUserSetBiometryLoginArgs = {
    accept: Scalars['Boolean']['input'];
    otp: Scalars['String']['input'];
};

export type MutationUserSetOnboardingStepArgs = {
    step: Scalars['String']['input'];
};

export type MutationUserSetPinArgs = {
    deviceToken: Scalars['String']['input'];
    otp: Scalars['String']['input'];
    pin: Scalars['String']['input'];
};

export type MutationUserSetPinWithPreAuthArgs = {
    deviceToken: Scalars['String']['input'];
    pin: Scalars['String']['input'];
};

export type MutationUserSetWebPreferredLoginMethodArgs = {
    preferredLoginMethod: WebPreferredLoginMethod;
};

export type MutationUserStartLoginArgs = {
    email: Scalars['String']['input'];
    loginMethod: InputMaybe<WebPreferredLoginMethod>;
    password: Scalars['String']['input'];
    recaptchaToken: Scalars['String']['input'];
};

export type MutationUserStartPasswordResetArgs = {
    email: Scalars['String']['input'];
    token: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserStartPasswordUpdateArgs = {
    currentPassword: Scalars['String']['input'];
    password: Scalars['String']['input'];
};

export type MutationUserStartRegistrationArgs = {
    dto: UserStartRegistrationInput;
};

export type MutationUserTerminateSessionsArgs = {
    sessionId: InputMaybe<Scalars['String']['input']>;
};

export type MutationUserVerifyCodeGoogleAuthenticatorArgs = {
    code: Scalars['String']['input'];
};

export enum OperationType {
    Credit = 'Credit',
    Debit = 'Debit',
    Exchange = 'Exchange',
    System = 'System',
    Undefined = 'Undefined',
}

export type Passport = {
    dateBirthday: Scalars['DateTime']['output'];
    gender: UserGender;
    lastName: Scalars['String']['output'];
    name: Scalars['String']['output'];
    nationality: Scalars['String']['output'];
};

export type Payment = {
    accountId: Scalars['ID']['output'];
    amount: Scalars['Float']['output'];
    balance: Scalars['Float']['output'];
    beneficiary: Maybe<BeneficiaryDetails>;
    client: Maybe<Client>;
    clientId: Scalars['ID']['output'];
    createdAt: Scalars['DateTime']['output'];
    currency: Currency;
    documents: Array<File>;
    elcoinStatus: ElcoinPaymentStatus;
    fee: Maybe<Fee>;
    id: Scalars['ID']['output'];
    operation: OperationType;
    purpose: Maybe<Scalars['String']['output']>;
    reason: Scalars['String']['output'];
    sender: Maybe<Sender>;
    status: PaymentStatus;
    statuses: Array<PaymentStatuses>;
    total: Scalars['Float']['output'];
    type: PaymentType;
    updatedAt: Scalars['DateTime']['output'];
};

export type PaymentAnswer = {
    elements: Array<Payment>;
    totalCount: Scalars['Int']['output'];
};

export enum PaymentEntityType {
    company = 'company',
    individual = 'individual',
}

export type PaymentFee = {
    amount: Scalars['Float']['output'];
    total: Scalars['Float']['output'];
};

export type PaymentFeeResult = Error | PaymentFee;

export type PaymentFilter = {
    elcoinStatus: InputMaybe<ElcoinPaymentStatus>;
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    paymentDate: InputMaybe<Range>;
    search: InputMaybe<Scalars['String']['input']>;
    status: InputMaybe<PaymentStatus>;
};

export type PaymentInput = {
    accountId: Scalars['ID']['input'];
    amount: Scalars['Float']['input'];
    beneficiary: InputMaybe<BeneficiaryDetailsInput>;
    callbackUrl: InputMaybe<Scalars['String']['input']>;
    currency: Scalars['String']['input'];
    documentIds: InputMaybe<Array<Scalars['String']['input']>>;
    id: InputMaybe<Scalars['ID']['input']>;
    purpose: InputMaybe<Scalars['String']['input']>;
    reason: Scalars['String']['input'];
    type: PaymentType;
};

export type PaymentInternalFilter = {
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    operation: InputMaybe<OperationType>;
    reason: InputMaybe<Scalars['String']['input']>;
    sort: InputMaybe<DateSort>;
    status: InputMaybe<PaymentStatus>;
    type: InputMaybe<PaymentType>;
};

export enum PaymentStatus {
    Approved = 'Approved',
    Canceled = 'Canceled',
    Completed = 'Completed',
    Created = 'Created',
    Declined = 'Declined',
    Error = 'Error',
    Processing = 'Processing',
    Signed = 'Signed',
}

export type PaymentStatuses = {
    date: Scalars['DateTime']['output'];
    id: Scalars['ID']['output'];
    status: PaymentStatus;
};

export enum PaymentType {
    acquiring = 'acquiring',
    card_withdrawal = 'card_withdrawal',
    exchange = 'exchange',
    internal = 'internal',
    local = 'local',
    sepa = 'sepa',
    swift = 'swift',
    system = 'system',
    undefined = 'undefined',
}

export type Person = {
    address: Maybe<Address>;
    documents: Array<UserDocument>;
    email: Maybe<Scalars['String']['output']>;
    isVerified: Maybe<Scalars['Boolean']['output']>;
    phone: Maybe<Scalars['String']['output']>;
    profile: Maybe<UserProfile>;
};

export type PersonDocumentsArgs = {
    documentStatuses: InputMaybe<Array<UserDocumentStatus>>;
};

export type PersonAnswer = {
    id: Scalars['String']['output'];
    person: Person;
    relation: Relation;
};

export type PersonDataInput = {
    address: InputMaybe<AddressInput>;
    documents: InputMaybe<Array<UserDocumentInput>>;
    email: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['ID']['input']>;
    phone: InputMaybe<Scalars['String']['input']>;
    profile: InputMaybe<UserProfileInput>;
    transport: InputMaybe<TransportType>;
};

export type PersonDocumentsDeleteAnswer = {
    status: Scalars['Boolean']['output'];
};

export type PersonDocumentsDeleteResult = Error | PersonDocumentsDeleteAnswer;

export type PersonRelation = {
    id: Scalars['ID']['output'];
    legal: Maybe<Legal>;
    person: Maybe<Person>;
    relation: Relation;
    type: UserType;
};

export enum PlatformType {
    App = 'App',
    Web = 'Web',
}

export type PresignedUploadUrl = {
    url: Scalars['String']['output'];
};

export type PresignedUrl = {
    url: Scalars['String']['output'];
};

export type PrivateClientFilters = {
    country: InputMaybe<Scalars['String']['input']>;
    createdFrom: InputMaybe<Scalars['DateTime']['input']>;
    createdTo: InputMaybe<Scalars['DateTime']['input']>;
    limit: InputMaybe<Scalars['Int']['input']>;
    nationality: InputMaybe<Scalars['String']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    search: InputMaybe<Scalars['String']['input']>;
    sort: InputMaybe<DateSort>;
    status: InputMaybe<UserActivatedStatus>;
    type: InputMaybe<UserType>;
};

export type PurposeAdditionalField = {
    length: Scalars['Int']['output'];
    name: Scalars['String']['output'];
};

export type PurposeCode = {
    additionalFields: Array<Maybe<PurposeAdditionalField>>;
    code: Scalars['String']['output'];
    country: Scalars['String']['output'];
    description: Maybe<Scalars['String']['output']>;
};

export type PushLogin = {
    state: PushLoginStatus;
    status: Scalars['Boolean']['output'];
};

export enum PushLoginStatus {
    Approved = 'Approved',
    Pending = 'Pending',
    Rejected = 'Rejected',
}

export enum PushType {
    Check = 'Check',
    Send = 'Send',
}

export type Query = {
    adminCheckUserDocuments: DefaultResult;
    adminGetAccountAdministration: AccountAdministration;
    adminGetAccounts: Array<Account>;
    adminGetAddressBooks: AddressBookResult;
    adminGetClient: ClientOneResult;
    adminGetClientFeatureTags: Array<Maybe<FeatureStatus>>;
    adminGetClients: ClientResult;
    adminGetCommissionTemplates: Array<Commission>;
    adminGetCommissionTemplatesByType: Array<Commission>;
    adminGetCompany: Maybe<UserCompanyActive>;
    adminGetCompanyPersonVerificationLink: GetVerificationLinkResult;
    adminGetConversion: Conversion;
    adminGetConversions: ConversionAnswer;
    adminGetCustomerAddresses: Array<Address>;
    adminGetElcoinClientData: Maybe<AdminGetElcoinClientDataResult>;
    adminGetExternalApplicantLink: GetExternalApplicantLinkResult;
    adminGetFeatureTagRequiredData: FeatureTagRequiredDataResult;
    adminGetFeePayments: PaymentAnswer;
    adminGetIbanqCallbackUrl: Maybe<IbanqCallbackUrlResponse>;
    adminGetIbanqCredentials: Maybe<IbanqCredentialsResponse>;
    adminGetInternalPayments: PaymentAnswer;
    adminGetInwardPayments: PaymentAnswer;
    adminGetOutwardPayments: PaymentAnswer;
    adminGetPDFTransactionReceipt: TransactionPdfAnswer;
    adminGetPDFTransactions: TransactionPdfAnswer;
    adminGetPayment: Payment;
    adminGetPrivateClient: Maybe<UserResult>;
    adminGetPrivateClientComments: ClientCommentsResult;
    adminGetReport: Report;
    adminGetReportPresignedUrl: PresignedUrl;
    adminGetTransactions: TransactionAnswer;
    adminGetUsers: UserAnswer;
    adminNotifyAboutUser: DefaultResult;
    checkUserExist: CheckUserExistResult;
    getAccountAdministration: AccountAdministration;
    getAccountAdministrationByType: AccountAdministration;
    getAccountAdministrationByTypeWithLokalize: AccountAdministrationWithLokalize;
    getAccountAdministrationWithLokalize: AccountAdministrationWithLokalize;
    getAccounts: Array<Account>;
    getActiveCompany: Maybe<UserCompanyActive>;
    getActiveSessions: UserSessionListResult;
    getAddressBooks: AddressBookResult;
    getAvailableCountries: GetAvailableCountriesResult;
    getAvailableCurrencies: AvailableCurrencies;
    getAvailableFreePayments: GetAvailableFreePaymentsAnswer;
    getBaseRate: BaseConversionRate;
    getBaseRateInternal: BaseConversionRate;
    getBeneficiaryDetailsRequired: Array<BeneficiaryDetails>;
    getBicInfo: Array<AvailableBic>;
    getClient: ClientOneResult;
    getComment: Maybe<Comment>;
    getComments: Maybe<Array<Comment>>;
    getCommissionTemplates: Array<Commission>;
    getCommissionTemplatesByType: Array<Commission>;
    getCompany: Maybe<UserCompanyActive>;
    getCompanyPermissions: Maybe<UserCompanyPermissions>;
    getConversion: Conversion;
    getCountryCurrencies: Array<CountryCurrency>;
    getCurrencies: Array<Currency>;
    getCurrency: Maybe<Currency>;
    getCurrentUser: Maybe<UserResult>;
    getExternalRegistrationLink: GetExternalRegistrationLinkResult;
    getFeatureTags: Array<Maybe<FeatureStatus>>;
    getFee: PaymentFeeResult;
    getHoldInstructions: HoldInstructionResult;
    getIbanInfo: BankData;
    getInternalBeneficiaryDetails: InternalBeneficiaryDetailsResult;
    getMe: UserCompanyPersonResult;
    getPDFTransactionReceipt: TransactionPdfAnswer;
    getPDFTransactions: TransactionPdfAnswer;
    getPayment: Payment;
    getPurposeCodes: Array<PurposeCode>;
    getRate: ExchangeRate;
    getRegistrationMailById: GetMailResult;
    getReport: Report;
    getReportPresignedUrl: PresignedUrl;
    getReports: Array<Maybe<Report>>;
    getStatus: GetStatusAnswer;
    getTermsStatus: TermsStatusResult;
    getTransactions: TransactionAnswer;
    getUpload: Maybe<File>;
    getUploadPassportData: Passport;
    getUploadPresignedUrl: PresignedUploadUrl;
    getUserCompanies: Array<UserCompany>;
    getUserInfoByIp: UserInfoIp;
    getVerificationLink: GetVerificationLinkResult;
    getVerificationParams: GetVerificationParamsResult;
    internalGetAccountDto: InternalGetAccountDtoResult;
    internalGetAccountDtoByEmail: InternalGetAccountDtoResult;
    internalGetAccounts: Array<Account>;
    internalGetAvailableFreePayments: GetAvailableFreePaymentsAnswer;
    internalGetClient: ClientOneInternalResult;
    internalGetClients: ClientResult;
    internalGetCommissionTemplates: Array<Commission>;
    internalGetIbanqCredentials: Maybe<IbanqCredentialsResponse>;
    internalGetIbanqGbpSepaRequisites: IbanqSepaRequisitesResult;
    internalGetIbanqSepaRequisites: IbanqSepaRequisitesResult;
    internalGetStatus: GetStatusAnswer;
    internalIsCardAllowed: IsCardAllowedResult;
    internalIsUserHasAccessToCompany: IsUserHasAccessToCompanyResult;
    isCardAllowed: IsCardAllowedResult;
};

export type QueryAdminCheckUserDocumentsArgs = {
    personId: InputMaybe<Scalars['ID']['input']>;
    userId: InputMaybe<Scalars['String']['input']>;
};

export type QueryAdminGetAccountAdministrationArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryAdminGetAccountsArgs = {
    clientId: Scalars['ID']['input'];
    filters: InputMaybe<AccountFilters>;
};

export type QueryAdminGetAddressBooksArgs = {
    clientId: Scalars['ID']['input'];
    filters: InputMaybe<AddressBookFilters>;
};

export type QueryAdminGetClientArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryAdminGetClientFeatureTagsArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryAdminGetClientsArgs = {
    filters: InputMaybe<PrivateClientFilters>;
};

export type QueryAdminGetCommissionTemplatesArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryAdminGetCommissionTemplatesByTypeArgs = {
    userType: UserType;
};

export type QueryAdminGetCompanyArgs = {
    companyId: Scalars['ID']['input'];
};

export type QueryAdminGetCompanyPersonVerificationLinkArgs = {
    companyPersonId: Scalars['ID']['input'];
};

export type QueryAdminGetConversionArgs = {
    conversionId: Scalars['ID']['input'];
};

export type QueryAdminGetConversionsArgs = {
    filters: ConversionFilter;
};

export type QueryAdminGetCustomerAddressesArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryAdminGetElcoinClientDataArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryAdminGetExternalApplicantLinkArgs = {
    clientId: Scalars['String']['input'];
};

export type QueryAdminGetFeatureTagRequiredDataArgs = {
    featureTag: UserFeatureTag;
};

export type QueryAdminGetFeePaymentsArgs = {
    filters: PaymentFilter;
};

export type QueryAdminGetIbanqCallbackUrlArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryAdminGetIbanqCredentialsArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryAdminGetInternalPaymentsArgs = {
    filters: PaymentFilter;
};

export type QueryAdminGetInwardPaymentsArgs = {
    filters: PaymentFilter;
};

export type QueryAdminGetOutwardPaymentsArgs = {
    filters: PaymentFilter;
};

export type QueryAdminGetPdfTransactionReceiptArgs = {
    accountId: Scalars['ID']['input'];
    clientId: Scalars['ID']['input'];
    paymentId: Scalars['ID']['input'];
};

export type QueryAdminGetPdfTransactionsArgs = {
    clientId: Scalars['ID']['input'];
    filters: InputMaybe<TransactionFilter>;
};

export type QueryAdminGetPaymentArgs = {
    paymentId: Scalars['ID']['input'];
};

export type QueryAdminGetPrivateClientArgs = {
    userId: Scalars['String']['input'];
};

export type QueryAdminGetPrivateClientCommentsArgs = {
    userId: Scalars['String']['input'];
};

export type QueryAdminGetReportArgs = {
    id: Scalars['ID']['input'];
};

export type QueryAdminGetReportPresignedUrlArgs = {
    reportId: Scalars['ID']['input'];
};

export type QueryAdminGetTransactionsArgs = {
    clientId: Scalars['ID']['input'];
    filters: InputMaybe<TransactionFilter>;
};

export type QueryAdminGetUsersArgs = {
    filters: InputMaybe<UsersFilter>;
};

export type QueryAdminNotifyAboutUserArgs = {
    userId: Scalars['ID']['input'];
};

export type QueryCheckUserExistArgs = {
    email: Scalars['String']['input'];
    token: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetAccountAdministrationByTypeArgs = {
    userType: UserType;
};

export type QueryGetAccountAdministrationByTypeWithLokalizeArgs = {
    userType: UserType;
};

export type QueryGetAccountsArgs = {
    filters: InputMaybe<AccountFilters>;
};

export type QueryGetAddressBooksArgs = {
    filters: InputMaybe<AddressBookFilters>;
};

export type QueryGetAvailableCountriesArgs = {
    filter: CountryFilter;
};

export type QueryGetBaseRateArgs = {
    buyCurrency: InputMaybe<Scalars['String']['input']>;
    sellCurrency: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetBaseRateInternalArgs = {
    buyCurrency: InputMaybe<Scalars['String']['input']>;
    sellCurrency: InputMaybe<Scalars['String']['input']>;
};

export type QueryGetBeneficiaryDetailsRequiredArgs = {
    currency: Scalars['String']['input'];
    paymentType: PaymentType;
};

export type QueryGetBicInfoArgs = {
    bic: Scalars['String']['input'];
};

export type QueryGetCommentArgs = {
    id: Scalars['ID']['input'];
};

export type QueryGetCommentsArgs = {
    id: Array<Scalars['ID']['input']>;
};

export type QueryGetCommissionTemplatesByTypeArgs = {
    userType: UserType;
};

export type QueryGetCompanyArgs = {
    companyId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetConversionArgs = {
    conversionId: Scalars['ID']['input'];
};

export type QueryGetCurrencyArgs = {
    code: Scalars['String']['input'];
};

export type QueryGetExternalRegistrationLinkArgs = {
    callbackUrl: Scalars['String']['input'];
    partnerName: Scalars['String']['input'];
    userType: UserType;
};

export type QueryGetFeeArgs = {
    payment: PaymentInput;
};

export type QueryGetHoldInstructionsArgs = {
    filters: InputMaybe<HoldInstructionFilter>;
};

export type QueryGetIbanInfoArgs = {
    iban: Scalars['String']['input'];
};

export type QueryGetInternalBeneficiaryDetailsArgs = {
    currency: Scalars['String']['input'];
    emailOrIban: Scalars['String']['input'];
};

export type QueryGetMeArgs = {
    companyId: InputMaybe<Scalars['ID']['input']>;
};

export type QueryGetPdfTransactionReceiptArgs = {
    accountId: Scalars['ID']['input'];
    paymentId: Scalars['ID']['input'];
};

export type QueryGetPdfTransactionsArgs = {
    filters: InputMaybe<TransactionFilter>;
};

export type QueryGetPaymentArgs = {
    paymentId: Scalars['ID']['input'];
};

export type QueryGetPurposeCodesArgs = {
    countryCode: Scalars['String']['input'];
};

export type QueryGetRateArgs = {
    amount: Scalars['Int']['input'];
    buyCurrency: Scalars['String']['input'];
    sellCurrency: Scalars['String']['input'];
    type: ExchangeType;
};

export type QueryGetRegistrationMailByIdArgs = {
    id: Scalars['String']['input'];
};

export type QueryGetReportArgs = {
    id: Scalars['ID']['input'];
};

export type QueryGetReportPresignedUrlArgs = {
    reportId: Scalars['ID']['input'];
};

export type QueryGetReportsArgs = {
    accountId: Scalars['ID']['input'];
};

export type QueryGetTransactionsArgs = {
    filters: InputMaybe<TransactionFilter>;
};

export type QueryGetUploadArgs = {
    key: Scalars['String']['input'];
};

export type QueryGetUploadPassportDataArgs = {
    fileId: Scalars['ID']['input'];
};

export type QueryGetUploadPresignedUrlArgs = {
    key: Scalars['String']['input'];
};

export type QueryGetUserCompaniesArgs = {
    filters: InputMaybe<CompanyFilters>;
};

export type QueryGetVerificationLinkArgs = {
    transport: TransportType;
};

export type QueryGetVerificationParamsArgs = {
    mobileVersion: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryInternalGetAccountDtoArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryInternalGetAccountDtoByEmailArgs = {
    email: Scalars['String']['input'];
};

export type QueryInternalGetAccountsArgs = {
    clientId: Scalars['ID']['input'];
    filters: InputMaybe<AccountFilters>;
};

export type QueryInternalGetAvailableFreePaymentsArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryInternalGetClientArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryInternalGetClientsArgs = {
    filters: InputMaybe<PrivateClientFilters>;
};

export type QueryInternalGetCommissionTemplatesArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryInternalGetIbanqCredentialsArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryInternalGetIbanqGbpSepaRequisitesArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryInternalGetIbanqSepaRequisitesArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryInternalGetStatusArgs = {
    clientId: Scalars['ID']['input'];
};

export type QueryInternalIsCardAllowedArgs = {
    cardNumber: Scalars['String']['input'];
    region: InputMaybe<Scalars['String']['input']>;
};

export type QueryInternalIsUserHasAccessToCompanyArgs = {
    adminAllow: Scalars['Boolean']['input'];
    clientId: Scalars['ID']['input'];
    userId: Scalars['ID']['input'];
};

export type QueryIsCardAllowedArgs = {
    cardNumber: Scalars['String']['input'];
    region: InputMaybe<Scalars['String']['input']>;
};

export type Range = {
    gte: InputMaybe<Scalars['DateTime']['input']>;
    lte: InputMaybe<Scalars['DateTime']['input']>;
};

export type RangeType = {
    gte: Maybe<Scalars['DateTime']['output']>;
    lte: Maybe<Scalars['DateTime']['output']>;
};

export type RegistrationLegalResult = Error | LegalAnswer;

export type RegistrationPersonResult = Error | PersonAnswer;

export type Relation = {
    beneficiaryPercent: Maybe<Scalars['Int']['output']>;
    companyRelation: Array<UserCompanyRelationType>;
    companyRole: Maybe<UserRolesType>;
    isPowerOfAttorney: Maybe<Scalars['Boolean']['output']>;
    isUserCreate: Scalars['Boolean']['output'];
    ownershipPercent: Maybe<Scalars['Int']['output']>;
};

export type RelationInput = {
    beneficiaryPercent: InputMaybe<Scalars['Int']['input']>;
    companyRelation: InputMaybe<Array<InputMaybe<UserCompanyRelationType>>>;
    companyRole: InputMaybe<UserRolesType>;
    isPowerOfAttorney: InputMaybe<Scalars['Boolean']['input']>;
    isUserCreate: InputMaybe<Scalars['Boolean']['input']>;
    ownershipPercent: InputMaybe<Scalars['Int']['input']>;
};

export type Report = {
    accountId: Scalars['ID']['output'];
    createdAt: Scalars['DateTime']['output'];
    filter: Maybe<TransactionFilterType>;
    id: Scalars['ID']['output'];
    key: Maybe<Scalars['String']['output']>;
    message: Maybe<Scalars['String']['output']>;
    status: ReportStatus;
    updatedAt: Scalars['DateTime']['output'];
};

export enum ReportStatus {
    done = 'done',
    failed = 'failed',
    in_progress = 'in_progress',
    new = 'new',
}

export type SendMailJoin = {
    code: Scalars['String']['input'];
    email: Scalars['String']['input'];
    name: Scalars['String']['input'];
};

export type SendMailResult = Error | SendMailStatus;

export type SendMailStatus = {
    status: Scalars['Boolean']['output'];
};

export type SendMessage = {
    success: Scalars['Boolean']['output'];
};

export enum SendOtpType {
    Email = 'Email',
    Push = 'Push',
    Sms = 'Sms',
}

export type SendPhoneCodeResult = Error | SendPhoneCodeStatus;

export type SendPhoneCodeStatus = {
    status: Scalars['Boolean']['output'];
    type: SendOtpType;
};

export type Sender = {
    bicSwift: Scalars['String']['output'];
    iban: Scalars['String']['output'];
    name: Scalars['String']['output'];
};

export enum SessionStatus {
    Active = 'Active',
    Inactive = 'Inactive',
}

export type SignedPaymentResult = DefaultAnswer | Error;

export enum SortEnum {
    asc = 'asc',
    desc = 'desc',
}

export type StepsStatus = CompanyStatus | UserPrivateStatus;

export type SystemPaymentInput = {
    accountId: Scalars['String']['input'];
    amount: Scalars['Float']['input'];
    clientId: Scalars['ID']['input'];
    currency: Scalars['String']['input'];
    reason: Scalars['String']['input'];
};

export type TermsStatusAnswer = {
    consentToPrivacyPolicyAccepted: Maybe<Scalars['DateTime']['output']>;
    termsAccepted: Maybe<Scalars['DateTime']['output']>;
};

export type TermsStatusResult = Error | TermsStatusAnswer;

export type Transaction = {
    amount: Scalars['Float']['output'];
    balance: Maybe<Scalars['Float']['output']>;
    beneficiary: Maybe<BeneficiaryDetails>;
    buyAmount: Maybe<Scalars['Float']['output']>;
    buyBalance: Maybe<Scalars['Float']['output']>;
    buyCurrency: Maybe<Currency>;
    client: Maybe<Client>;
    createdAt: Scalars['DateTime']['output'];
    currency: Maybe<Currency>;
    elcoinStatus: ElcoinPaymentStatus;
    exchangeType: Maybe<ExchangeType>;
    fee: Maybe<Fee>;
    id: Scalars['ID']['output'];
    operation: OperationType;
    paymentType: Maybe<PaymentType>;
    rate: Maybe<Scalars['Float']['output']>;
    reason: Maybe<Scalars['String']['output']>;
    sellAmount: Maybe<Scalars['Float']['output']>;
    sellBalance: Maybe<Scalars['Float']['output']>;
    sellCurrency: Maybe<Currency>;
    sender: Maybe<Sender>;
    status: PaymentStatus;
    total: Scalars['Float']['output'];
    type: TransactionType;
    updatedAt: Maybe<Scalars['DateTime']['output']>;
};

export type TransactionAnswer = {
    elements: Array<Transaction>;
    total: Scalars['Int']['output'];
};

export type TransactionFilter = {
    accountId: InputMaybe<Scalars['String']['input']>;
    createdAt: InputMaybe<Range>;
    currency: InputMaybe<Scalars['String']['input']>;
    isAll: InputMaybe<Scalars['Boolean']['input']>;
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    operationType: InputMaybe<OperationType>;
    paymentType: InputMaybe<PaymentType>;
    search: InputMaybe<Scalars['String']['input']>;
    status: InputMaybe<PaymentStatus>;
    type: InputMaybe<TransactionType>;
    updatedAt: InputMaybe<Range>;
};

export type TransactionFilterType = {
    accountId: Maybe<Scalars['String']['output']>;
    createdAt: Maybe<RangeType>;
    currency: Maybe<Scalars['String']['output']>;
    isAll: Maybe<Scalars['Boolean']['output']>;
    limit: Maybe<Scalars['Int']['output']>;
    offset: Maybe<Scalars['Int']['output']>;
    operationType: Maybe<OperationType>;
    paymentType: Maybe<PaymentType>;
    search: Maybe<Scalars['String']['output']>;
    status: Maybe<PaymentStatus>;
    type: Maybe<TransactionType>;
    updatedAt: Maybe<RangeType>;
};

export type TransactionPdfAnswer = {
    reportId: Scalars['String']['output'];
};

export type TransactionPdfData = {
    balanceFrom: Scalars['Float']['output'];
    balanceTo: Scalars['Float']['output'];
    clientAddress1: Scalars['String']['output'];
    clientAddress2: Scalars['String']['output'];
    clientBic: Scalars['String']['output'];
    clientCountry: Scalars['String']['output'];
    clientIban: Scalars['String']['output'];
    clientName: Scalars['String']['output'];
    currency: Scalars['String']['output'];
    elements: Array<Transaction>;
    endDate: Scalars['DateTime']['output'];
    incomingBalance: Scalars['Float']['output'];
    outBalance: Scalars['Float']['output'];
    startDate: Scalars['DateTime']['output'];
    total: Scalars['Int']['output'];
};

export enum TransactionType {
    Exchange = 'Exchange',
    Payment = 'Payment',
}

export enum TransportType {
    email = 'email',
    now = 'now',
    skip = 'skip',
    sms = 'sms',
}

export type User = {
    address: Maybe<Address>;
    appPreferredLoginMethod: AppPreferredLoginMethod;
    chatId: Maybe<Scalars['String']['output']>;
    companies: Array<UserCompany>;
    companyPermissions: Maybe<UserCompanyPermissions>;
    createdAt: Scalars['DateTime']['output'];
    declaration: Maybe<UserDeclaration>;
    documents: Array<UserDocument>;
    elcoinId: Maybe<Scalars['String']['output']>;
    email: Scalars['String']['output'];
    gaUserId: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    legalStatus: UserLegalStatus;
    onboardingStep: Maybe<Scalars['String']['output']>;
    phone: Scalars['String']['output'];
    privateStatus: UserPrivateStatus;
    profile: Maybe<UserProfile>;
    service: Maybe<UserService>;
    status: UserActivatedStatus;
    statuses: Array<UserStatuses>;
    telegramLink: Maybe<Scalars['String']['output']>;
    tokenForChat: Maybe<Scalars['String']['output']>;
    type: Maybe<UserType>;
    updatedAt: Scalars['DateTime']['output'];
    webPreferredLoginMethod: WebPreferredLoginMethod;
};

export type UserCompaniesArgs = {
    filters: InputMaybe<CompanyFilters>;
};

export type UserDocumentsArgs = {
    documentStatuses: InputMaybe<Array<UserDocumentStatus>>;
};

export enum UserActivatedStatus {
    Activated = 'Activated',
    Approved = 'Approved',
    Blocked = 'Blocked',
    Compliance = 'Compliance',
    Created = 'Created',
    Rejected = 'Rejected',
    UnsupportedCountry = 'UnsupportedCountry',
    Updated = 'Updated',
}

export type UserAnswer = {
    elements: Array<User>;
    totalCount: Scalars['Int']['output'];
};

export type UserCompany = {
    biggestClient: Maybe<CompanyBiggestClient>;
    companyLegalForm: Maybe<CompanyLegalFormType>;
    companyName: Scalars['String']['output'];
    companyNumber: Maybe<Scalars['String']['output']>;
    companyService: Maybe<CompanyService>;
    companySite: Maybe<Scalars['String']['output']>;
    companyStatus: CompanyStatus;
    companyTaxNumber: Maybe<Scalars['String']['output']>;
    elcoinId: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    industryDescription: Maybe<Scalars['String']['output']>;
    isCompanyAddressSame: Maybe<Scalars['Boolean']['output']>;
    isConductBusiness: Maybe<Scalars['Boolean']['output']>;
    isGroupPart: Maybe<Scalars['Boolean']['output']>;
    legalAddress: Maybe<Address>;
    owner: Person;
    postalAddress: Maybe<Address>;
    registrationCountry: Maybe<Scalars['String']['output']>;
    registrationDate: Maybe<Scalars['DateTime']['output']>;
    relation: Maybe<UserCompanyRelation>;
    status: Maybe<CompanyActivatedStatus>;
    statuses: Maybe<Array<UserStatuses>>;
};

export type UserCompanyActive = {
    biggestClient: Maybe<CompanyBiggestClient>;
    companyLegalForm: Maybe<CompanyLegalFormType>;
    companyName: Scalars['String']['output'];
    companyNumber: Maybe<Scalars['String']['output']>;
    companyService: Maybe<CompanyService>;
    companySite: Maybe<Scalars['String']['output']>;
    companyStatus: Maybe<CompanyStatus>;
    companyTaxNumber: Maybe<Scalars['String']['output']>;
    documents: Maybe<Array<UserDocument>>;
    elcoinId: Maybe<Scalars['String']['output']>;
    id: Scalars['ID']['output'];
    industryDescription: Maybe<Scalars['String']['output']>;
    isCompanyAddressSame: Maybe<Scalars['Boolean']['output']>;
    isConductBusiness: Maybe<Scalars['Boolean']['output']>;
    isGroupPart: Maybe<Scalars['Boolean']['output']>;
    legalAddress: Maybe<Address>;
    owner: Person;
    persons: Maybe<Array<PersonRelation>>;
    postalAddress: Maybe<Address>;
    registrationCountry: Maybe<Scalars['String']['output']>;
    registrationDate: Maybe<Scalars['DateTime']['output']>;
    relation: Maybe<UserCompanyRelation>;
    status: Maybe<CompanyActivatedStatus>;
    statuses: Maybe<Array<UserStatuses>>;
};

export type UserCompanyActiveDocumentsArgs = {
    documentStatuses: InputMaybe<Array<UserDocumentStatus>>;
};

export type UserCompanyInput = {
    biggestClient: InputMaybe<CompanyBiggestClientInput>;
    companyLegalForm: InputMaybe<CompanyLegalFormType>;
    companyName: InputMaybe<Scalars['String']['input']>;
    companyNumber: InputMaybe<Scalars['String']['input']>;
    companyService: InputMaybe<CompanyServiceInput>;
    companySite: InputMaybe<Scalars['String']['input']>;
    companyTaxNumber: InputMaybe<Scalars['String']['input']>;
    id: Scalars['String']['input'];
    industryDescription: InputMaybe<Scalars['String']['input']>;
    isCompanyAddressSame: InputMaybe<Scalars['Boolean']['input']>;
    isConductBusiness: InputMaybe<Scalars['Boolean']['input']>;
    isGroupPart: InputMaybe<Scalars['Boolean']['input']>;
    legalAddress: InputMaybe<AddressInput>;
    postalAddress: InputMaybe<AddressInput>;
    registrationCountry: InputMaybe<Scalars['String']['input']>;
    registrationDate: InputMaybe<Scalars['DateTime']['input']>;
};

export type UserCompanyPermissions = {
    permissions: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type UserCompanyPerson = {
    beneficiaryPercent: Maybe<Scalars['Int']['output']>;
    companyRelation: Maybe<Array<UserCompanyRelationType>>;
    companyRole: Maybe<UserRolesType>;
    isPowerOfAttorney: Maybe<Scalars['Boolean']['output']>;
    isUserCreate: Maybe<Scalars['Boolean']['output']>;
    ownershipPercent: Maybe<Scalars['Int']['output']>;
    personId: Scalars['ID']['output'];
    type: Maybe<UserType>;
};

export type UserCompanyPersonResult = Error | UserCompanyPerson;

export type UserCompanyRelation = {
    companyRelation: Array<UserCompanyRelationType>;
    companyRole: Maybe<UserRolesType>;
    type: UserType;
};

export enum UserCompanyRelationType {
    Beneficiary = 'Beneficiary',
    Director = 'Director',
    Owner = 'Owner',
    Ownership = 'Ownership',
    Representative = 'Representative',
    UBO = 'UBO',
}

export type UserCreateCompanyResult = CreatedUserCompany | Error;

export type UserCreateExternalId = {
    externalId: Maybe<Scalars['ID']['output']>;
    id: Maybe<Scalars['ID']['output']>;
};

export type UserCreateExternalIdResult = Error | UserCreateExternalId;

export type UserDeclaration = {
    isPoliticallyPerson: Scalars['Boolean']['output'];
    pep: Scalars['String']['output'];
};

export type UserDeclarationInput = {
    isPoliticallyPerson: Scalars['Boolean']['input'];
    pep: InputMaybe<Scalars['String']['input']>;
};

export type UserDocument = {
    companyId: Maybe<Scalars['ID']['output']>;
    createdAt: Scalars['DateTime']['output'];
    file: Maybe<File>;
    prevFile: Maybe<File>;
    status: UserDocumentStatus;
    type: UserDocumentType;
    updatedAt: Scalars['DateTime']['output'];
};

export type UserDocumentAnswer = {
    documents: Array<UserDocument>;
};

export type UserDocumentInput = {
    file: Scalars['String']['input'];
    type: UserDocumentType;
};

export enum UserDocumentStatus {
    /** @deprecated No longer supported */
    Archived = 'Archived',
    Deleted = 'Deleted',
    /** @deprecated No longer supported */
    Infected = 'Infected',
    /** @deprecated No longer supported */
    New = 'New',
    /** @deprecated No longer supported */
    Trunked = 'Trunked',
    Uploaded = 'Uploaded',
}

export enum UserDocumentType {
    Articles = 'Articles',
    Business = 'Business',
    Chart = 'Chart',
    Corporate = 'Corporate',
    FinancialStatement = 'FinancialStatement',
    Group = 'Group',
    Location = 'Location',
    Other = 'Other',
    Passport = 'Passport',
    Selfie = 'Selfie',
}

export enum UserFeatureTag {
    acquiring = 'acquiring',
    exchange = 'exchange',
    gbpSepaRequisites = 'gbpSepaRequisites',
    iban = 'iban',
    sepaRequisites = 'sepaRequisites',
}

export enum UserFinancialStatus {
    EmployedFull = 'EmployedFull',
    EmployedPart = 'EmployedPart',
    Retired = 'Retired',
    SelfEmployed = 'SelfEmployed',
    Unemployed = 'Unemployed',
}

export enum UserGender {
    Female = 'Female',
    Male = 'Male',
}

export type UserInfoIp = {
    ipInfo: Maybe<IpInfoData>;
    userIp: Scalars['String']['output'];
};

export type UserLegalStatus = {
    hasCompany: Scalars['Boolean']['output'];
};

export type UserLogin = {
    appPreferredLoginMethod: AppPreferredLoginMethod;
    bioToken: Maybe<Scalars['String']['output']>;
    companyPermissions: Maybe<UserCompanyPermissions>;
    deviceToken: Maybe<Scalars['String']['output']>;
    email: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['ID']['output']>;
    isBiometryAccepted: Maybe<Scalars['Boolean']['output']>;
    phone: Maybe<Scalars['String']['output']>;
    role: Maybe<UserRole>;
    roleCompany: Maybe<UserRolesType>;
    sessionId: Maybe<Scalars['String']['output']>;
    webPreferredLoginMethod: WebPreferredLoginMethod;
};

export type UserLoginResult = Error | UserLogin;

export type UserMobileStartLogin = {
    appAvailableLoginMethods: Array<Maybe<AppPreferredLoginMethod>>;
    appPreferredLoginMethod: AppPreferredLoginMethod;
    phone: Scalars['String']['output'];
    webPreferredLoginMethod: WebPreferredLoginMethod;
};

export type UserMobileStartLoginResult = Error | UserMobileStartLogin;

export type UserOnboardingStep = {
    onboardingStep: Scalars['String']['output'];
};

export type UserPrivateStatus = {
    consentToPrivacyPolicyAccepted: Maybe<Scalars['DateTime']['output']>;
    hasAccept: Scalars['Boolean']['output'];
    hasAddress: Scalars['Boolean']['output'];
    hasDeclaration: Scalars['Boolean']['output'];
    hasDocument: Scalars['Boolean']['output'];
    hasProfile: Scalars['Boolean']['output'];
    hasService: Scalars['Boolean']['output'];
    termsAccepted: Maybe<Scalars['DateTime']['output']>;
};

export type UserProfile = {
    dateBirthday: Maybe<Scalars['DateTime']['output']>;
    financialStatus: Maybe<UserFinancialStatus>;
    gender: Maybe<UserGender>;
    id: Scalars['String']['output'];
    lastName: Maybe<Scalars['String']['output']>;
    name: Maybe<Scalars['String']['output']>;
    nationality: Maybe<Scalars['String']['output']>;
    thirdName: Maybe<Scalars['String']['output']>;
};

export type UserProfileInput = {
    dateBirthday: InputMaybe<Scalars['DateTime']['input']>;
    financialStatus: InputMaybe<UserFinancialStatus>;
    gender: InputMaybe<UserGender>;
    lastName: InputMaybe<Scalars['String']['input']>;
    name: InputMaybe<Scalars['String']['input']>;
    nationality: InputMaybe<Scalars['String']['input']>;
    thirdName: InputMaybe<Scalars['String']['input']>;
};

export type UserPushLoginResult = Error | PushLogin | UserLogin;

export type UserRegistration = {
    email: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['ID']['output']>;
    phone: Maybe<Scalars['String']['output']>;
    role: Maybe<UserRole>;
    type: Maybe<UserType>;
};

export type UserRegistrationResult = Error | UserRegistration;

export type UserResult = Error | User;

export enum UserRole {
    Admin = 'Admin',
    Sudo = 'Sudo',
    User = 'User',
}

export enum UserRolesType {
    RoleA = 'RoleA',
    RoleB = 'RoleB',
    RoleC = 'RoleC',
}

export type UserSaveAddressResult = Address | Error;

export type UserSaveCompanyAddressResult = CompanyAddress | Error;

export type UserSaveCompanyResult = Error | UserCompany;

export type UserSaveDeclarationResult = Error | UserDeclaration;

export type UserSaveDocumentsResult = Error | UserDocumentAnswer;

export type UserSaveProfilleResult = Error | UserProfile;

export type UserSaveServiceResult = Error | UserService;

export type UserService = {
    currency: Currency;
    id: Maybe<Scalars['ID']['output']>;
    /** @deprecated not needed anymore */
    isEurIban: Scalars['Boolean']['output'];
    /** @deprecated not needed anymore */
    isPaymentAccount: Scalars['Boolean']['output'];
    isPep: Maybe<Scalars['Boolean']['output']>;
    moneyTurnover: Maybe<Scalars['String']['output']>;
    monthlyIncome: Maybe<Scalars['String']['output']>;
    reasonAccount: Maybe<Scalars['String']['output']>;
    sourceOfFunds: Maybe<Scalars['String']['output']>;
};

export type UserServiceInput = {
    currency: InputMaybe<Scalars['String']['input']>;
    id: InputMaybe<Scalars['ID']['input']>;
    isPep: InputMaybe<Scalars['Boolean']['input']>;
    moneyTurnover: InputMaybe<Scalars['String']['input']>;
    monthlyIncome: InputMaybe<Scalars['String']['input']>;
    reasonAccount: InputMaybe<Scalars['String']['input']>;
    sourceOfFunds: InputMaybe<Scalars['String']['input']>;
};

export type UserSession = {
    browser: Maybe<Scalars['String']['output']>;
    city: Maybe<Scalars['String']['output']>;
    country: Maybe<Scalars['String']['output']>;
    device: Maybe<Scalars['String']['output']>;
    id: Maybe<Scalars['String']['output']>;
    ip: Maybe<Scalars['String']['output']>;
    isCurrentSession: Scalars['Boolean']['output'];
    lastLogin: Maybe<Scalars['DateTime']['output']>;
    os: Maybe<Scalars['String']['output']>;
    platformType: Maybe<PlatformType>;
    status: Maybe<SessionStatus>;
};

export type UserSessionList = {
    data: Maybe<Array<Maybe<UserSession>>>;
};

export type UserSessionListResult = Error | UserSessionList;

export type UserSetBiometryLogin = {
    bioToken: Maybe<Scalars['String']['output']>;
};

export type UserSetBiometryLoginResult = Error | UserSetBiometryLogin;

export type UserSetOnboardingStepResult = Error | UserOnboardingStep;

export type UserStartLogin = {
    appPreferredLoginMethod: AppPreferredLoginMethod;
    phone: Scalars['String']['output'];
    webAvailableLoginMethods: Array<Maybe<WebPreferredLoginMethod>>;
    webPreferredLoginMethod: WebPreferredLoginMethod;
};

export type UserStartLoginResult = Error | UserStartLogin;

export type UserStartRegistration = {
    appAvailableLoginMethods: Array<Maybe<AppPreferredLoginMethod>>;
    appPreferredLoginMethod: AppPreferredLoginMethod;
    phone: Scalars['String']['output'];
    userId: Scalars['String']['output'];
    webAvailableLoginMethods: Array<Maybe<WebPreferredLoginMethod>>;
    webPreferredLoginMethod: WebPreferredLoginMethod;
};

export type UserStartRegistrationInput = {
    companyName: InputMaybe<Scalars['String']['input']>;
    email: Scalars['String']['input'];
    firstName: InputMaybe<Scalars['String']['input']>;
    gaUserId: InputMaybe<Scalars['String']['input']>;
    lastName: InputMaybe<Scalars['String']['input']>;
    legalCountry: InputMaybe<Scalars['String']['input']>;
    nationality: InputMaybe<Scalars['String']['input']>;
    password: Scalars['String']['input'];
    phone: Scalars['String']['input'];
    postalCountry: InputMaybe<Scalars['String']['input']>;
    recaptchaToken: Scalars['String']['input'];
    residenceCountry: InputMaybe<Scalars['String']['input']>;
    type: UserType;
};

export type UserStatuses = {
    date: Scalars['DateTime']['output'];
    status: UserActivatedStatus;
};

export type UserTransaction = {
    id: Scalars['ID']['output'];
    profile: Maybe<UserProfile>;
};

export enum UserType {
    Legal = 'Legal',
    Private = 'Private',
}

export type UserVerificationLink = {
    href: Maybe<Scalars['String']['output']>;
};

export type UserVerificationParams = {
    token: Maybe<Scalars['String']['output']>;
    /** @deprecated Use only token for sumsub */
    workflowExecutionId: Maybe<Scalars['String']['output']>;
};

export type UsersFilter = {
    country: InputMaybe<Scalars['String']['input']>;
    createdFrom: InputMaybe<Scalars['DateTime']['input']>;
    createdTo: InputMaybe<Scalars['DateTime']['input']>;
    limit: InputMaybe<Scalars['Int']['input']>;
    offset: InputMaybe<Scalars['Int']['input']>;
    sort: InputMaybe<DateSort>;
    type: InputMaybe<UserType>;
    userStatus: InputMaybe<UserActivatedStatus>;
};

export type WaitingListElement = {
    email: Scalars['String']['output'];
    id: Scalars['String']['output'];
    message: Scalars['String']['output'];
};

export enum WebPreferredLoginMethod {
    Email = 'Email',
    GoogleAuthenticator = 'GoogleAuthenticator',
    PushNotification = 'PushNotification',
    Sms = 'Sms',
}

export type InternalUserSendPreferredOtp = {
    appPreferredLoginMethod: AppPreferredLoginMethod;
    webPreferredLoginMethod: WebPreferredLoginMethod;
};

export const AdminUpdateAccountCurrencies = gql`
    mutation adminUpdateAccountCurrencies($currencies: [String!]!, $clientId: ID!) {
        adminUpdateAccountCurrencies(currencies: $currencies, clientId: $clientId) {
            ... on DefaultAnswer {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AdminCreateIbanqCredentials = gql`
    mutation AdminCreateIbanqCredentials($credentials: IbanqCredentialsInput!) {
        adminCreateIbanqCredentials(credentials: $credentials) {
            ... on DefaultAnswer {
                status
            }
        }
    }
`;
export const AdminGetIbanqCallbackUrl = gql`
    query AdminGetIbanqCallbackUrl($clientId: ID!) {
        adminGetIbanqCallbackUrl(clientId: $clientId) {
            url
        }
    }
`;
export const AdminGetIbanqCredentials = gql`
    query AdminGetIbanqCredentials($clientId: ID!) {
        adminGetIbanqCredentials(clientId: $clientId) {
            ibanqClientId
            ibanqUsername
        }
    }
`;
export const AdminCreateCommissionTemplate = gql`
    mutation adminCreateCommissionTemplate($commission: CommissionCreateInput!) {
        adminCreateCommissionTemplate(commission: $commission) {
            ... on Commission {
                id
                name
                type
                commissions {
                    minAmount
                    maxAmount
                    staticCommission
                    percentCommission
                    minFee
                    maxFee
                }
                isDefault
                operation
                isSepa
                clientId
                userType
                paymentType
                currency
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AdminDeleteCommissionTemplate = gql`
    mutation AdminDeleteCommissionTemplate($adminDeleteCommissionTemplateId: ID!) {
        adminDeleteCommissionTemplate(id: $adminDeleteCommissionTemplateId) {
            ... on DefaultAnswer {
                status
            }
        }
    }
`;
export const GetAccountAdministrationByType = gql`
    query getAccountAdministrationByType($userType: UserType!) {
        getAccountAdministrationByType(userType: $userType) {
            accountClosing
            accountMaintenance
            accountOpening
            foreignCurrencies
            urgentAccountOpening
            complexStructure
        }
    }
`;
export const AdminGetAccountAdministration = gql`
    query adminGetAccountAdministration($clientId: ID!) {
        adminGetAccountAdministration(clientId: $clientId) {
            accountClosing
            accountMaintenance
            accountOpening
            foreignCurrencies
            urgentAccountOpening
            complexStructure
        }
    }
`;
export const AdminGetClientAccounts = gql`
    query adminGetClientAccounts($clientId: ID!, $filters: AccountFilters) {
        adminGetAccounts(clientId: $clientId, filters: $filters) {
            ... on Account {
                id
                accountId
                type
                balance
                realBalance
                freezeBalance
                currency {
                    code
                    currency
                    number
                    digits
                }
                banks {
                    name
                    number
                    bankName
                    bankAddress
                    bankCountry
                    currency {
                        code
                        number
                        digits
                        currency
                        isActive
                    }
                    sortCode
                    paymentType
                }
            }
        }
    }
`;
export const GetCommissionTemplatesByType = gql`
    query getCommissionTemplatesByType($userType: UserType!) {
        getCommissionTemplatesByType(userType: $userType) {
            id
            name
            type
            commissions {
                minAmount
                maxAmount
                staticCommission
                percentCommission
            }
            isDefault
            operation
            isSepa
            clientId
            userType
            paymentType
            lokaliseKey
        }
    }
`;
export const AdminGetCommissionTemplates = gql`
    query adminGetCommissionTemplates($clientId: ID!) {
        adminGetCommissionTemplates(clientId: $clientId) {
            id
            name
            type
            commissions {
                minAmount
                maxAmount
                staticCommission
                percentCommission
            }
            isDefault
            operation
            isSepa
            clientId
            userType
            lokaliseKey
            paymentType
            currency
        }
    }
`;
export const AdminUpdateAccountAdministrationByType = gql`
    mutation adminUpdateAccountAdministrationByType($userType: UserType!, $values: AccountAdministrationInput!) {
        adminUpdateAccountAdministrationByType(userType: $userType, values: $values) {
            ... on AccountAdministration {
                accountClosing
                accountMaintenance
                accountOpening
                foreignCurrencies
                urgentAccountOpening
                complexStructure
            }
            ... on Error {
                extensions {
                    invalidArgs {
                        message
                        name
                    }
                }
                message
                path
            }
        }
    }
`;
export const AdminUpdateAccountAdministration = gql`
    mutation adminUpdateAccountAdministration($clientId: ID, $values: AccountAdministrationInput!) {
        adminUpdateAccountAdministration(clientId: $clientId, values: $values) {
            ... on AccountAdministration {
                accountClosing
                accountMaintenance
                accountOpening
                foreignCurrencies
                urgentAccountOpening
            }
            ... on Error {
                extensions {
                    invalidArgs {
                        message
                        name
                    }
                }
                message
                path
            }
        }
    }
`;
export const AdminUpdateCommissionTemplate = gql`
    mutation adminUpdateCommissionTemplate($commission: CommissionUpdateInput!) {
        adminUpdateCommissionTemplate(commission: $commission) {
            ... on Commission {
                id
                name
                type
                commissions {
                    minAmount
                    maxAmount
                    staticCommission
                    percentCommission
                    minFee
                    maxFee
                }
                isDefault
                operation
                isSepa
                clientId
                userType
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const GetAvailableCurrencies = gql`
    query getAvailableCurrencies {
        getAvailableCurrencies {
            currencies {
                code
                name
                onlineTrading
                canBuy
                canSell
            }
        }
    }
`;
export const GetBaseRate = gql`
    query getBaseRate($buyCurrency: String!, $sellCurrency: String!) {
        getBaseRate(buyCurrency: $buyCurrency, sellCurrency: $sellCurrency) {
            buyCurrency
            sellCurrency
            rate
            percentFee
            staticFee
        }
    }
`;
export const AdminClientRegistration = gql`
    mutation adminClientRegistration(
        $email: String!
        $phone: String!
        $type: UserType!
        $companyName: String
        $firstName: String!
        $lastName: String!
        $legalCountry: String
        $nationality: String
        $postalCountry: String
    ) {
        adminClientRegistration(
            email: $email
            phone: $phone
            type: $type
            companyName: $companyName
            firstName: $firstName
            lastName: $lastName
            legalCountry: $legalCountry
            nationality: $nationality
            postalCountry: $postalCountry
        ) {
            ... on UserRegistration {
                id
                email
                phone
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AddComment = gql`
    mutation addComment($comment: String!) {
        addComment(comment: $comment) {
            id
            comment
        }
    }
`;
export const RemoveComment = gql`
    mutation removeComment($id: ID!) {
        removeComment(id: $id) {
            status
        }
    }
`;
export const AdminCompanyActivatedStatusUpdate = gql`
    mutation adminCompanyActivatedStatusUpdate($companyId: ID!, $status: CompanyActivatedStatus!) {
        adminCompanyActivatedStatusUpdate(companyId: $companyId, status: $status) {
            ... on DefaultAnswer {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const GetActiveCompanyDocuments = gql`
    query getActiveCompanyDocuments {
        getActiveCompany {
            id
            documents {
                type
                file {
                    key
                    path
                    filename
                    comment {
                        id
                        comment
                    }
                }
            }
        }
    }
`;
export const GetActiveCompanyAddress = gql`
    query getActiveCompanyAddress {
        getActiveCompany {
            id
            isCompanyAddressSame
            legalAddress {
                id
                street
                zip
                city
                country
                building
            }
            postalAddress {
                id
                street
                zip
                city
                country
                building
            }
        }
    }
`;
export const GetActiveCompanyBusiness = gql`
    query getActiveCompanyBusiness {
        getActiveCompany {
            id
            documents {
                file {
                    filename
                    key
                    path
                }
                type
            }
            industryDescription
            companyService {
                isAccountInAnotherBank
                isSubmitFinancialTaxReport
                numberOfCustomers
                numberOfPersons
                preferredAccountCurrency
                spendPlan
                moneyTurnover
            }
            biggestClient {
                country
                name
                subjectOfBusiness
                website
            }
        }
    }
`;
export const GetActiveCompanyPersons = gql`
    query getActiveCompanyPersons {
        getActiveCompany {
            id
            persons {
                id
                type
                relation {
                    beneficiaryPercent
                    ownershipPercent
                    companyRelation
                    companyRole
                    isUserCreate
                    isPowerOfAttorney
                }
                person {
                    isVerified
                    email
                    phone
                    profile {
                        name
                        lastName
                        thirdName
                        dateBirthday
                        gender
                        financialStatus
                        nationality
                    }
                    address {
                        street
                        zip
                        city
                        country
                    }
                    documents {
                        type
                        file {
                            key
                            path
                            filename
                            comment {
                                id
                                comment
                            }
                        }
                    }
                }
                legal {
                    companyName
                    registrationCountry
                    companyNumber
                    companyTaxNumber
                    companySite
                    registrationDate
                    isConductBusiness
                    isGroupPart
                    isCompanyAddressSame
                    industryDescription
                    legalAddress {
                        street
                        zip
                        city
                        country
                        building
                    }
                    postalAddress {
                        street
                        zip
                        city
                        country
                        building
                    }
                    documents {
                        type
                        file {
                            key
                            path
                            filename
                            comment {
                                id
                                comment
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GetActiveCompanyService = gql`
    query getActiveCompanyService {
        getActiveCompany {
            companyService {
                id
                isEurAccount
                isSwiftAccount
                isMulticurrencyAccount
                currency {
                    code
                }
                moneyTurnover
            }
        }
    }
`;
export const GetActiveCompany = gql`
    query getActiveCompany {
        getActiveCompany {
            id
            status
            companyName
            companyLegalForm
            registrationCountry
            companyNumber
            companyTaxNumber
            companySite
            isCompanyAddressSame
            registrationDate
            isConductBusiness
            isGroupPart
            legalAddress {
                city
                country
                street
                zip
                building
            }
            postalAddress {
                city
                country
                street
                zip
                building
            }
            documents {
                file {
                    filename
                    key
                    path
                }
                type
            }
            companyStatus {
                consentToPrivacyPolicyAccepted
                hasAddress
                hasIndustry
                hasPersons
                hasShareholders
                hasDocuments
                hasService
                hasAccept
                termsAccepted
            }
        }
    }
`;
export const AdminGetCompanyPersons = gql`
    query adminGetCompanyPersons($companyId: ID!) {
        adminGetCompany(companyId: $companyId) {
            id
            persons {
                id
                type
                relation {
                    beneficiaryPercent
                    ownershipPercent
                    companyRelation
                    companyRole
                    isUserCreate
                    isPowerOfAttorney
                }
                person {
                    email
                    phone
                    profile {
                        name
                        lastName
                        thirdName
                        dateBirthday
                        gender
                        financialStatus
                        nationality
                    }
                    address {
                        street
                        zip
                        city
                        country
                        building
                    }
                    documents {
                        type
                        file {
                            key
                            path
                            filename
                            comment {
                                id
                                comment
                            }
                        }
                    }
                }
                legal {
                    companyName
                    registrationCountry
                    companyNumber
                    companyTaxNumber
                    companySite
                    registrationDate
                    isConductBusiness
                    isGroupPart
                    isCompanyAddressSame
                    industryDescription
                    legalAddress {
                        street
                        zip
                        city
                        country
                        building
                    }
                    postalAddress {
                        street
                        zip
                        city
                        country
                        building
                    }
                    documents {
                        type
                        file {
                            key
                            path
                            filename
                            comment {
                                id
                                comment
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const AdminGetCompanyAddress = gql`
    query adminGetCompanyAddress($companyId: ID!) {
        adminGetCompany(companyId: $companyId) {
            id
            isCompanyAddressSame
            legalAddress {
                id
                street
                zip
                city
                country
                building
            }
            postalAddress {
                id
                street
                zip
                city
                country
                building
            }
        }
    }
`;
export const AdminGetCompanyDocuments = gql`
    query adminGetCompanyDocuments($companyId: ID!, $documentStatuses: [UserDocumentStatus!]) {
        adminGetCompany(companyId: $companyId) {
            id
            documents(documentStatuses: $documentStatuses) {
                type
                status
                updatedAt
                file {
                    key
                    path
                    filename
                    comment {
                        id
                        comment
                    }
                }
                prevFile {
                    filename
                    path
                    key
                }
            }
        }
    }
`;
export const AdminGetCompanyService = gql`
    query adminGetCompanyService($companyId: ID!) {
        adminGetCompany(companyId: $companyId) {
            companyService {
                id
                isEurAccount
                isSwiftAccount
                isMulticurrencyAccount
                currency {
                    code
                }
                moneyTurnover
                numberOfPersons
                numberOfCustomers
                isAccountInAnotherBank
                isSubmitFinancialTaxReport
                spendPlan
                preferredAccountCurrency
            }
        }
    }
`;
export const AdminGetCompanyStatuses = gql`
    query adminGetCompanyStatuses($companyId: ID!) {
        adminGetCompany(companyId: $companyId) {
            ... on UserCompanyActive {
                statuses {
                    status
                    date
                }
            }
        }
    }
`;
export const AdminGetCompany = gql`
    query adminGetCompany($companyId: ID!) {
        adminGetCompany(companyId: $companyId) {
            id
            status
            statuses {
                status
                date
            }
            elcoinId
            companyName
            companyLegalForm
            registrationCountry
            companyNumber
            companyTaxNumber
            companySite
            registrationDate
            isConductBusiness
            isGroupPart
            industryDescription
            isCompanyAddressSame
            legalAddress {
                id
                city
                street
                building
                zip
                country
            }
            postalAddress {
                id
                city
                street
                building
                zip
                country
            }
            owner {
                email
                phone
            }
            biggestClient {
                id
                country
                name
                subjectOfBusiness
                website
            }
            companyStatus {
                consentToPrivacyPolicyAccepted
                hasAddress
                hasIndustry
                hasPersons
                hasShareholders
                hasDocuments
                hasService
                hasAccept
                termsAccepted
            }
        }
    }
`;
export const GetMe = gql`
    query getMe($companyId: ID!) {
        getMe(companyId: $companyId) {
            ... on UserCompanyPerson {
                personId
            }
            ... on Error {
                path
            }
        }
    }
`;
export const GetVerificationLink = gql`
    query getVerificationLink($transport: TransportType!) {
        getVerificationLink(transport: $transport) {
            ... on UserVerificationLink {
                href
            }
            ... on Error {
                message
                path
            }
        }
    }
`;
export const GetCurrencies = gql`
    query getCurrencies {
        getCurrencies {
            code
            currency
            isActive
            number
        }
    }
`;
export const GetRate = gql`
    query getRate($buyCurrency: String!, $sellCurrency: String!, $amount: Int!, $type: ExchangeType!) {
        getRate(buyCurrency: $buyCurrency, sellCurrency: $sellCurrency, amount: $amount, type: $type) {
            rate
            type
            buyAmount
            sellAmount
            buyCurrency {
                code
                digits
            }
            sellCurrency {
                code
                digits
            }
            settlementTime
        }
    }
`;
export const AdminAddFeatureTag = gql`
    mutation AdminAddFeatureTag($clientId: String!, $userFeatureTag: UserFeatureTag!) {
        adminAddFeatureTag(clientId: $clientId, userFeatureTag: $userFeatureTag) {
            ... on DefaultAnswer {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AdminGetClientFeatureTags = gql`
    query AdminGetClientFeatureTags($clientId: ID!) {
        adminGetClientFeatureTags(clientId: $clientId) {
            status
            featureTag
            message
            editable
            tagTitle
            requiredFields {
                name
                required
                title
                value
            }
        }
    }
`;
export const AdminNextStatusFeatureTag = gql`
    mutation AdminNextStatusFeatureTag($clientId: String!, $userFeatureTag: UserFeatureTag!) {
        adminNextStatusFeatureTag(clientId: $clientId, userFeatureTag: $userFeatureTag) {
            ... on DefaultAnswer {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AdminRejectFeatureTag = gql`
    mutation AdminRejectFeatureTag(
        $featureTag: UserFeatureTag!
        $clientId: String!
        $status: FeatureTagStatus!
        $message: String
    ) {
        adminRejectFeatureTag(featureTag: $featureTag, clientId: $clientId, status: $status, message: $message) {
            ... on DefaultAnswer {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AdminSetFeatureTagData = gql`
    mutation AdminSetFeatureTagData(
        $clientId: String!
        $userFeatureTag: UserFeatureTag!
        $requiredData: FeatureTagRequiredDataInput!
    ) {
        adminSetFeatureTagData(clientId: $clientId, userFeatureTag: $userFeatureTag, requiredData: $requiredData) {
            ... on DefaultAnswer {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AddFileComment = gql`
    mutation addFileComment($fileId: ID!, $commentId: ID) {
        addFileComment(fileId: $fileId, commentId: $commentId) {
            comment {
                id
            }
        }
    }
`;
export const AdminPaymentAddDocuments = gql`
    mutation AdminPaymentAddDocuments($paymentId: ID!, $documentIds: [String!]!) {
        adminPaymentAddDocuments(paymentId: $paymentId, documentIds: $documentIds) {
            status
        }
    }
`;
export const UploadFile = gql`
    mutation uploadFile($file: Upload!, $commentId: ID) {
        uploadFile(file: $file, commentId: $commentId) {
            filename
            path
            key
        }
    }
`;
export const GetUploadPassportData = gql`
    query getUploadPassportData($fileId: ID!) {
        getUploadPassportData(fileId: $fileId) {
            nationality
            gender
            name
            lastName
            dateBirthday
        }
    }
`;
export const GetUploadPresignedUrl = gql`
    query GetUploadPresignedUrl($key: String!) {
        getUploadPresignedUrl(key: $key) {
            url
        }
    }
`;
export const RemoveFileComment = gql`
    mutation removeFileComment($fileId: ID!, $commentId: ID!) {
        removeFileComment(fileId: $fileId, commentId: $commentId) {
            status
        }
    }
`;
export const AdminGetClients = gql`
    query adminGetClients($filters: PrivateClientFilters) {
        adminGetClients(filters: $filters) {
            ... on ClientAnswer {
                elements {
                    id
                    type
                    isActivated
                    name
                    status
                    updatedAt
                    createdAt
                    nationality
                }
                totalCount
            }
        }
    }
`;
export const AdminApprovePayment = gql`
    mutation adminApprovePayment($paymentId: ID!) {
        adminApprovePayment(paymentId: $paymentId) {
            ... on DefaultAnswer {
                status
            }
        }
    }
`;
export const AdminRejectPayment = gql`
    mutation adminRejectPayment($paymentId: ID!, $message: String!) {
        adminRejectPayment(paymentId: $paymentId, message: $message) {
            ... on DefaultAnswer {
                status
            }
        }
    }
`;
export const AdminGetFullPayment = gql`
    query adminGetFullPayment($paymentId: ID!, $clientId: ID!) {
        adminGetPayment(paymentId: $paymentId) {
            ... on Payment {
                id
                amount
                total
                accountId
                operation
                currency {
                    code
                }
                sender {
                    bicSwift
                    iban
                    name
                }
                fee {
                    amount
                    total
                }
                documents {
                    comment {
                        comment
                        id
                    }
                    filename
                    key
                    path
                }
                type
                reason
                clientId
                status
                beneficiary {
                    iban
                    bicSwift
                    acctNumber
                    sortCode
                    bankName
                    bankAddress
                    bankCountry
                    beneficiaryFirstName
                    beneficiaryLastName
                    beneficiaryAddress
                    beneficiaryPostcode
                    beneficiaryStateOrProvince
                    beneficiaryCity
                    beneficiaryCompanyName
                    beneficiaryCountry
                    beneficiaryEntityType
                }
                createdAt
                updatedAt
            }
        }
        adminGetAccounts(clientId: $clientId) {
            ... on Account {
                id
                type
                accountId
                balance
                realBalance
                freezeBalance
                currency {
                    code
                    currency
                }
                banks {
                    name
                    number
                    bankName
                    bankAddress
                    bankCountry
                    currency {
                        code
                        number
                        digits
                        currency
                        isActive
                    }
                    paymentType
                    sortCode
                }
            }
        }
        adminGetClient(clientId: $clientId) {
            ... on Client {
                name
            }
        }
    }
`;
export const AdminGetInternalPayments = gql`
    query adminGetInternalPayments($filters: PaymentFilter!) {
        adminGetInternalPayments(filters: $filters) {
            elements {
                accountId
                amount
                balance
                beneficiary {
                    beneficiaryEntityType
                    beneficiaryFirstName
                    beneficiaryLastName
                    beneficiaryCompanyName
                    bankName
                    sortCode
                    bicSwift
                    acctNumber
                    iban
                }
                client {
                    id
                }
                clientId
                createdAt
                currency {
                    code
                }
                elcoinStatus
                fee {
                    amount
                    total
                }
                id
                operation
                purpose
                reason
                sender {
                    name
                    iban
                }
                status
                statuses {
                    status
                    date
                }
                total
                type
                updatedAt
            }
            totalCount
        }
    }
`;
export const AdminGetInwardPayments = gql`
    query adminGetInwardPayments($filters: PaymentFilter!) {
        adminGetInwardPayments(filters: $filters) {
            elements {
                accountId
                amount
                balance
                beneficiary {
                    beneficiaryEntityType
                    beneficiaryFirstName
                    beneficiaryLastName
                    beneficiaryCompanyName
                    bankName
                    sortCode
                    bicSwift
                    acctNumber
                    iban
                }
                client {
                    id
                }
                clientId
                createdAt
                currency {
                    code
                }
                elcoinStatus
                fee {
                    amount
                    total
                }
                id
                operation
                purpose
                reason
                sender {
                    name
                    iban
                }
                status
                statuses {
                    status
                    date
                }
                total
                type
                updatedAt
            }
            totalCount
        }
    }
`;
export const AdminGetOutwardPayments = gql`
    query adminGetOutwardPayments($filters: PaymentFilter!) {
        adminGetOutwardPayments(filters: $filters) {
            elements {
                accountId
                amount
                balance
                beneficiary {
                    beneficiaryEntityType
                    beneficiaryFirstName
                    beneficiaryLastName
                    beneficiaryCompanyName
                    bankName
                    sortCode
                    bicSwift
                    acctNumber
                    iban
                }
                client {
                    id
                }
                clientId
                createdAt
                currency {
                    code
                }
                elcoinStatus
                fee {
                    amount
                    total
                }
                id
                operation
                purpose
                reason
                sender {
                    name
                    iban
                }
                status
                statuses {
                    status
                    date
                }
                total
                type
                updatedAt
            }
            totalCount
        }
    }
`;
export const AdminGetPaymentStatus = gql`
    query adminGetPaymentStatus($paymentId: ID!) {
        adminGetPayment(paymentId: $paymentId) {
            ... on Payment {
                status
            }
        }
    }
`;
export const AdminGetPayment = gql`
    query adminGetPayment($paymentId: ID!) {
        adminGetPayment(paymentId: $paymentId) {
            ... on Payment {
                id
                amount
                accountId
                currency {
                    code
                }
                fee {
                    amount
                    total
                }
                type
                reason
                clientId
                beneficiary {
                    iban
                    bicSwift
                    acctNumber
                    sortCode
                    bankName
                    bankAddress
                    bankCountry
                    beneficiaryFirstName
                    beneficiaryLastName
                    beneficiaryAddress
                    beneficiaryPostcode
                    beneficiaryStateOrProvince
                    beneficiaryCity
                    beneficiaryCompanyName
                    beneficiaryCountry
                    beneficiaryEntityType
                }
                documents {
                    filename
                    key
                    path
                    comment {
                        comment
                        id
                    }
                }
                createdAt
                updatedAt
            }
        }
    }
`;
export const CheckPhoneCode = gql`
    mutation checkPhoneCode($phone: String!, $code: String!) {
        checkPhoneCode(phone: $phone, code: $code) {
            ... on SendPhoneCodeStatus {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const SendPhoneCode = gql`
    mutation sendPhoneCode($phone: String!, $token: String) {
        sendPhoneCode(phone: $phone, token: $token) {
            status
        }
    }
`;
export const AdminAddPrivateClientComment = gql`
    mutation adminAddPrivateClientComment($userId: String!, $commentId: String!) {
        adminAddPrivateClientComment(userId: $userId, commentId: $commentId) {
            ... on DefaultAnswer {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AdminGetAddressBooks = gql`
    query adminGetAddressBooks($filters: AddressBookFilters, $clientId: ID!) {
        adminGetAddressBooks(filters: $filters, clientId: $clientId) {
            elements {
                beneficiary {
                    beneficiaryEntityType
                    acctNumber
                    bicSwift
                    bankCountry
                    beneficiaryAddress
                    beneficiaryCity
                    beneficiaryCountry
                    beneficiaryFirstName
                    beneficiaryLastName
                    beneficiaryCompanyName
                    beneficiaryPostcode
                    nationality
                    iban
                    beneficiaryStateOrProvince
                    cnaps
                    bankName
                    bankCode
                    branchCode
                    bankAddress
                    sortCode
                    ifsc
                    clabe
                }
                currency {
                    code
                    number
                    digits
                    currency
                    isActive
                }
                address {
                    id
                    street
                    zip
                    city
                    country
                    building
                }
                id
            }
            totalCount
        }
    }
`;
export const AdminGetPrivateClientAccept = gql`
    query adminGetPrivateClientAccept($userId: String!) {
        adminGetPrivateClient(userId: $userId) {
            ... on User {
                phone
                privateStatus {
                    hasProfile
                    hasAddress
                    hasService
                    hasDocument
                    hasDeclaration
                    hasAccept
                }
            }
        }
    }
`;
export const AdminGetPrivateClientAddress = gql`
    query adminGetPrivateClientAddress($userId: String!) {
        adminGetPrivateClient(userId: $userId) {
            ... on User {
                address {
                    street
                    zip
                    city
                    country
                }
            }
        }
    }
`;
export const AdminGetPrivateClientComments = gql`
    query adminGetPrivateClientComments($userId: String!) {
        adminGetPrivateClientComments(userId: $userId) {
            ... on ClientComments {
                elements {
                    comment {
                        id
                        comment
                    }
                    createdAt
                }
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AdminGetPrivateClientDeclaration = gql`
    query adminGetPrivateClientDeclaration($userId: String!) {
        adminGetPrivateClient(userId: $userId) {
            ... on User {
                declaration {
                    isPoliticallyPerson
                    pep
                }
            }
        }
    }
`;
export const AdminGetPrivateClientDocuments = gql`
    query adminGetPrivateClientDocuments($userId: String!, $documentStatuses: [UserDocumentStatus!]) {
        adminGetPrivateClient(userId: $userId) {
            ... on User {
                documents(documentStatuses: $documentStatuses) {
                    type
                    status
                    updatedAt
                    file {
                        filename
                        path
                        key
                        comment {
                            comment
                            id
                        }
                    }
                    prevFile {
                        filename
                        path
                        key
                    }
                }
            }
        }
    }
`;
export const AdminGetPrivateClientProfile = gql`
    query adminGetPrivateClientProfile($userId: String!) {
        adminGetPrivateClient(userId: $userId) {
            ... on User {
                profile {
                    name
                    lastName
                    thirdName
                    gender
                    dateBirthday
                    nationality
                    financialStatus
                }
            }
        }
    }
`;
export const AdminGetPrivateClientServices = gql`
    query adminGetPrivateClientServices($userId: String!) {
        adminGetPrivateClient(userId: $userId) {
            ... on User {
                service {
                    currency {
                        code
                    }
                    isEurIban
                    isPaymentAccount
                    moneyTurnover
                    reasonAccount
                }
            }
        }
    }
`;
export const AdminGetPrivateClientStatuses = gql`
    query adminGetPrivateClientStatuses($userId: String!) {
        adminGetPrivateClient(userId: $userId) {
            ... on User {
                statuses {
                    status
                    date
                }
            }
        }
    }
`;
export const AdminGetPrivateClient = gql`
    query adminGetPrivateClient($userId: String!) {
        adminGetPrivateClient(userId: $userId) {
            ... on User {
                id
                email
                elcoinId
                phone
                type
                status
                statuses {
                    status
                    date
                }
                address {
                    street
                    zip
                    city
                    country
                    building
                }
                service {
                    isEurIban
                    isPaymentAccount
                    currency {
                        code
                    }
                    moneyTurnover
                    reasonAccount
                    sourceOfFunds
                }
                declaration {
                    isPoliticallyPerson
                    pep
                }
                privateStatus {
                    hasProfile
                    hasAddress
                    hasService
                    hasDeclaration
                    hasDocument
                    hasAccept
                    termsAccepted
                }
                legalStatus {
                    hasCompany
                }
                profile {
                    name
                    lastName
                    thirdName
                    dateBirthday
                    gender
                    financialStatus
                    nationality
                }
            }
        }
    }
`;
export const AdminGetPrivateClientUser = gql`
    query adminGetPrivateClientUser($userId: String!) {
        adminGetPrivateClient(userId: $userId) {
            ... on User {
                id
                email
                phone
            }
        }
    }
`;
export const AdminGetPdfTransactionReceipt = gql`
    query AdminGetPDFTransactionReceipt($paymentId: ID!, $clientId: ID!, $accountId: ID!) {
        adminGetPDFTransactionReceipt(paymentId: $paymentId, clientId: $clientId, accountId: $accountId) {
            reportId
        }
    }
`;
export const AdminGetPdfTransactions = gql`
    query AdminGetPDFTransactions($clientId: ID!, $filters: TransactionFilter) {
        adminGetPDFTransactions(clientId: $clientId, filters: $filters) {
            reportId
        }
    }
`;
export const AdminGetReportPresignedUrl = gql`
    query AdminGetReportPresignedUrl($reportId: ID!) {
        adminGetReportPresignedUrl(reportId: $reportId) {
            url
        }
    }
`;
export const AdminGetReport = gql`
    query AdminGetReport($adminGetReportId: ID!) {
        adminGetReport(id: $adminGetReportId) {
            id
            accountId
            status
            createdAt
            updatedAt
            filter {
                limit
                offset
                status
                type
                isAll
                accountId
                currency
                search
                operationType
                createdAt {
                    lte
                    gte
                }
                updatedAt {
                    lte
                    gte
                }
                paymentType
            }
            key
            message
        }
    }
`;
export const AdminGetTransactionsShort = gql`
    query adminGetTransactionsShort($clientId: ID!, $filters: TransactionFilter) {
        adminGetTransactions(clientId: $clientId, filters: $filters) {
            elements {
                id
                amount
            }
        }
    }
`;
export const AdminGetTransactionsFull = gql`
    query adminGetTransactionsFull($clientId: ID!, $filters: TransactionFilter) {
        adminGetTransactions(clientId: $clientId, filters: $filters) {
            elements {
                type
                operation
                id
                client {
                    id
                    name
                }
                createdAt
                status
                rate
                fee {
                    amount
                    total
                }
                amount
                total
                reason
                beneficiary {
                    beneficiaryEntityType
                    beneficiaryFirstName
                    beneficiaryLastName
                    beneficiaryCompanyName
                    bankName
                    sortCode
                    bicSwift
                    acctNumber
                    iban
                }
                sender {
                    name
                    iban
                }
                currency {
                    code
                }
                balance
                buyCurrency {
                    code
                }
                sellCurrency {
                    code
                }
                buyAmount
                sellAmount
                buyBalance
                sellBalance
                exchangeType
                paymentType
            }
            total
        }
    }
`;
export const AdminCheckUserDocuments = gql`
    query adminCheckUserDocuments($personId: ID, $userId: String) {
        adminCheckUserDocuments(personId: $personId, userId: $userId) {
            ... on DefaultAnswer {
                status
            }
            ... on Error {
                extensions {
                    invalidArgs {
                        message
                        name
                    }
                }
                message
                path
            }
        }
    }
`;
export const GetCurrentUser = gql`
    query getCurrentUser {
        getCurrentUser {
            ... on User {
                id
                email
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const GetExternalApplicantLink = gql`
    query getExternalApplicantLink($clientId: String!) {
        adminGetExternalApplicantLink(clientId: $clientId) {
            ... on ExternalApplicantLink {
                href
            }
        }
    }
`;
export const GetUserInfoByIp = gql`
    query getUserInfoByIp {
        getUserInfoByIp {
            userIp
            ipInfo {
                country
                region
                eu
                timezone
                city
                ll
                metro
                area
            }
        }
    }
`;
export const AdminGetUsers = gql`
    query adminGetUsers($filters: UsersFilter) {
        adminGetUsers(filters: $filters) {
            elements {
                id
                email
                phone
                type
                status
                privateStatus {
                    hasProfile
                    hasAddress
                    hasService
                    hasDeclaration
                    hasDocument
                    hasAccept
                }
                legalStatus {
                    hasCompany
                }
                profile {
                    name
                    lastName
                }
                createdAt
            }
            totalCount
        }
    }
`;
export const SendRegistrationMail = gql`
    mutation sendRegistrationMail($email: String!) {
        sendRegistrationMail(email: $email) {
            ... on SendMailStatus {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const AdminUserActivatedStatusUpdate = gql`
    mutation adminUserActivatedStatusUpdate($userId: String!, $status: UserActivatedStatus!) {
        adminUserActivatedStatusUpdate(userId: $userId, status: $status) {
            ... on DefaultAnswer {
                status
            }
        }
    }
`;
export const UserConfirmLogin = gql`
    mutation UserConfirmLogin($email: String!, $password: String!, $otp: String!) {
        userConfirmLogin(email: $email, password: $password, otp: $otp) {
            ... on UserLogin {
                id
                phone
                role
                roleCompany
                webPreferredLoginMethod
                companyPermissions {
                    permissions
                }
                email
                sessionId
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const UserGoogleAuthenticatorLogin = gql`
    mutation UserGoogleAuthenticatorLogin($email: String!, $password: String!, $totp: String!) {
        userGoogleAuthenticatorLogin(email: $email, password: $password, totp: $totp) {
            ... on UserLogin {
                id
                phone
                role
                roleCompany
                bioToken
                isBiometryAccepted
                companyPermissions {
                    permissions
                }
                email
                sessionId
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const UserResetPasswordUpdate = gql`
    mutation userResetPasswordUpdate($code: String!, $password: String!) {
        userResetPasswordUpdate(code: $code, password: $password) {
            ... on DefaultAnswer {
                status
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export const UserPasswordReset = gql`
    mutation userPasswordReset($email: String!) {
        userPasswordReset(email: $email) {
            status
        }
    }
`;
export const UserStartLogin = gql`
    mutation UserStartLogin(
        $email: String!
        $password: String!
        $recaptchaToken: String!
        $loginMethod: WebPreferredLoginMethod
    ) {
        userStartLogin(email: $email, password: $password, recaptchaToken: $recaptchaToken, loginMethod: $loginMethod) {
            ... on UserStartLogin {
                appPreferredLoginMethod
                webPreferredLoginMethod
                phone
                webAvailableLoginMethods
            }
            ... on Error {
                message
                path
                extensions {
                    invalidArgs {
                        name
                        message
                    }
                }
            }
        }
    }
`;
export type AdminUpdateAccountCurrenciesMutationVariables = Exact<{
    currencies: Array<Scalars['String']['input']> | Scalars['String']['input'];
    clientId: Scalars['ID']['input'];
}>;

export type AdminUpdateAccountCurrenciesMutation = {
    adminUpdateAccountCurrencies:
        | { status: boolean }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type AdminCreateIbanqCredentialsMutationVariables = Exact<{
    credentials: IbanqCredentialsInput;
}>;

export type AdminCreateIbanqCredentialsMutation = { adminCreateIbanqCredentials: { status: boolean } };

export type AdminGetIbanqCallbackUrlQueryVariables = Exact<{
    clientId: Scalars['ID']['input'];
}>;

export type AdminGetIbanqCallbackUrlQuery = { adminGetIbanqCallbackUrl: { url: string } | null };

export type AdminGetIbanqCredentialsQueryVariables = Exact<{
    clientId: Scalars['ID']['input'];
}>;

export type AdminGetIbanqCredentialsQuery = {
    adminGetIbanqCredentials: { ibanqClientId: string; ibanqUsername: string } | null;
};

export type AdminCreateCommissionTemplateMutationVariables = Exact<{
    commission: CommissionCreateInput;
}>;

export type AdminCreateCommissionTemplateMutation = {
    adminCreateCommissionTemplate:
        | {
              id: string;
              name: string;
              type: CommissionType;
              isDefault: boolean;
              operation: OperationType;
              isSepa: boolean | null;
              clientId: string | null;
              userType: UserType | null;
              paymentType: PaymentType;
              currency: string;
              commissions: Array<{
                  minAmount: number;
                  maxAmount: number | null;
                  staticCommission: number | null;
                  percentCommission: number | null;
                  minFee: number | null;
                  maxFee: number | null;
              }>;
          }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type AdminDeleteCommissionTemplateMutationVariables = Exact<{
    adminDeleteCommissionTemplateId: Scalars['ID']['input'];
}>;

export type AdminDeleteCommissionTemplateMutation = { adminDeleteCommissionTemplate: { status: boolean } };

export type GetAccountAdministrationByTypeQueryVariables = Exact<{
    userType: UserType;
}>;

export type GetAccountAdministrationByTypeQuery = {
    getAccountAdministrationByType: {
        accountClosing: number;
        accountMaintenance: number;
        accountOpening: number;
        foreignCurrencies: number;
        urgentAccountOpening: number;
        complexStructure: number;
    };
};

export type AdminGetAccountAdministrationQueryVariables = Exact<{
    clientId: Scalars['ID']['input'];
}>;

export type AdminGetAccountAdministrationQuery = {
    adminGetAccountAdministration: {
        accountClosing: number;
        accountMaintenance: number;
        accountOpening: number;
        foreignCurrencies: number;
        urgentAccountOpening: number;
        complexStructure: number;
    };
};

export type AdminGetClientAccountsQueryVariables = Exact<{
    clientId: Scalars['ID']['input'];
    filters: InputMaybe<AccountFilters>;
}>;

export type AdminGetClientAccountsQuery = {
    adminGetAccounts: Array<{
        id: string;
        accountId: string;
        type: AccountType;
        balance: number;
        realBalance: number | null;
        freezeBalance: number | null;
        currency: { code: string; currency: string; number: number; digits: number };
        banks: Array<{
            name: string;
            number: string;
            bankName: string;
            bankAddress: string;
            bankCountry: string;
            sortCode: string | null;
            paymentType: PaymentType;
            currency: { code: string; number: number; digits: number; currency: string; isActive: boolean };
        } | null>;
    }>;
};

export type GetCommissionTemplatesByTypeQueryVariables = Exact<{
    userType: UserType;
}>;

export type GetCommissionTemplatesByTypeQuery = {
    getCommissionTemplatesByType: Array<{
        id: string;
        name: string;
        type: CommissionType;
        isDefault: boolean;
        operation: OperationType;
        isSepa: boolean | null;
        clientId: string | null;
        userType: UserType | null;
        paymentType: PaymentType;
        lokaliseKey: string | null;
        commissions: Array<{
            minAmount: number;
            maxAmount: number | null;
            staticCommission: number | null;
            percentCommission: number | null;
        }>;
    }>;
};

export type AdminGetCommissionTemplatesQueryVariables = Exact<{
    clientId: Scalars['ID']['input'];
}>;

export type AdminGetCommissionTemplatesQuery = {
    adminGetCommissionTemplates: Array<{
        id: string;
        name: string;
        type: CommissionType;
        isDefault: boolean;
        operation: OperationType;
        isSepa: boolean | null;
        clientId: string | null;
        userType: UserType | null;
        lokaliseKey: string | null;
        paymentType: PaymentType;
        currency: string;
        commissions: Array<{
            minAmount: number;
            maxAmount: number | null;
            staticCommission: number | null;
            percentCommission: number | null;
        }>;
    }>;
};

export type AdminUpdateAccountAdministrationByTypeMutationVariables = Exact<{
    userType: UserType;
    values: AccountAdministrationInput;
}>;

export type AdminUpdateAccountAdministrationByTypeMutation = {
    adminUpdateAccountAdministrationByType:
        | {
              accountClosing: number;
              accountMaintenance: number;
              accountOpening: number;
              foreignCurrencies: number;
              urgentAccountOpening: number;
              complexStructure: number;
          }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ message: string; name: string }> } | null;
          };
};

export type AdminUpdateAccountAdministrationMutationVariables = Exact<{
    clientId: InputMaybe<Scalars['ID']['input']>;
    values: AccountAdministrationInput;
}>;

export type AdminUpdateAccountAdministrationMutation = {
    adminUpdateAccountAdministration:
        | {
              accountClosing: number;
              accountMaintenance: number;
              accountOpening: number;
              foreignCurrencies: number;
              urgentAccountOpening: number;
          }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ message: string; name: string }> } | null;
          };
};

export type AdminUpdateCommissionTemplateMutationVariables = Exact<{
    commission: CommissionUpdateInput;
}>;

export type AdminUpdateCommissionTemplateMutation = {
    adminUpdateCommissionTemplate:
        | {
              id: string;
              name: string;
              type: CommissionType;
              isDefault: boolean;
              operation: OperationType;
              isSepa: boolean | null;
              clientId: string | null;
              userType: UserType | null;
              commissions: Array<{
                  minAmount: number;
                  maxAmount: number | null;
                  staticCommission: number | null;
                  percentCommission: number | null;
                  minFee: number | null;
                  maxFee: number | null;
              }>;
          }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type GetAvailableCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAvailableCurrenciesQuery = {
    getAvailableCurrencies: {
        currencies: Array<{
            code: string;
            name: string;
            onlineTrading: boolean;
            canBuy: boolean;
            canSell: boolean;
        } | null> | null;
    };
};

export type GetBaseRateQueryVariables = Exact<{
    buyCurrency: Scalars['String']['input'];
    sellCurrency: Scalars['String']['input'];
}>;

export type GetBaseRateQuery = {
    getBaseRate: { buyCurrency: string; sellCurrency: string; rate: string; percentFee: number; staticFee: number };
};

export type AdminClientRegistrationMutationVariables = Exact<{
    email: Scalars['String']['input'];
    phone: Scalars['String']['input'];
    type: UserType;
    companyName: InputMaybe<Scalars['String']['input']>;
    firstName: Scalars['String']['input'];
    lastName: Scalars['String']['input'];
    legalCountry: InputMaybe<Scalars['String']['input']>;
    nationality: InputMaybe<Scalars['String']['input']>;
    postalCountry: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminClientRegistrationMutation = {
    adminClientRegistration:
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          }
        | { id: string | null; email: string | null; phone: string | null };
};

export type AddCommentMutationVariables = Exact<{
    comment: Scalars['String']['input'];
}>;

export type AddCommentMutation = { addComment: { id: string; comment: string } };

export type RemoveCommentMutationVariables = Exact<{
    id: Scalars['ID']['input'];
}>;

export type RemoveCommentMutation = { removeComment: { status: boolean } };

export type AdminCompanyActivatedStatusUpdateMutationVariables = Exact<{
    companyId: Scalars['ID']['input'];
    status: CompanyActivatedStatus;
}>;

export type AdminCompanyActivatedStatusUpdateMutation = {
    adminCompanyActivatedStatusUpdate:
        | { status: boolean }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type GetActiveCompanyDocumentsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveCompanyDocumentsQuery = {
    getActiveCompany: {
        id: string;
        documents: Array<{
            type: UserDocumentType;
            file: {
                key: string;
                path: string;
                filename: string;
                comment: { id: string; comment: string } | null;
            } | null;
        }> | null;
    } | null;
};

export type GetActiveCompanyAddressQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveCompanyAddressQuery = {
    getActiveCompany: {
        id: string;
        isCompanyAddressSame: boolean | null;
        legalAddress: {
            id: string | null;
            street: string | null;
            zip: string | null;
            city: string | null;
            country: string | null;
            building: string | null;
        } | null;
        postalAddress: {
            id: string | null;
            street: string | null;
            zip: string | null;
            city: string | null;
            country: string | null;
            building: string | null;
        } | null;
    } | null;
};

export type GetActiveCompanyBusinessQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveCompanyBusinessQuery = {
    getActiveCompany: {
        id: string;
        industryDescription: string | null;
        documents: Array<{
            type: UserDocumentType;
            file: { filename: string; key: string; path: string } | null;
        }> | null;
        companyService: {
            isAccountInAnotherBank: boolean | null;
            isSubmitFinancialTaxReport: boolean | null;
            numberOfCustomers: string | null;
            numberOfPersons: string | null;
            preferredAccountCurrency: string | null;
            spendPlan: string | null;
            moneyTurnover: string | null;
        } | null;
        biggestClient: {
            country: string | null;
            name: string | null;
            subjectOfBusiness: string | null;
            website: string | null;
        } | null;
    } | null;
};

export type GetActiveCompanyPersonsQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveCompanyPersonsQuery = {
    getActiveCompany: {
        id: string;
        persons: Array<{
            id: string;
            type: UserType;
            relation: {
                beneficiaryPercent: number | null;
                ownershipPercent: number | null;
                companyRelation: Array<UserCompanyRelationType>;
                companyRole: UserRolesType | null;
                isUserCreate: boolean;
                isPowerOfAttorney: boolean | null;
            };
            person: {
                isVerified: boolean | null;
                email: string | null;
                phone: string | null;
                profile: {
                    name: string | null;
                    lastName: string | null;
                    thirdName: string | null;
                    dateBirthday: Date | null;
                    gender: UserGender | null;
                    financialStatus: UserFinancialStatus | null;
                    nationality: string | null;
                } | null;
                address: {
                    street: string | null;
                    zip: string | null;
                    city: string | null;
                    country: string | null;
                } | null;
                documents: Array<{
                    type: UserDocumentType;
                    file: {
                        key: string;
                        path: string;
                        filename: string;
                        comment: { id: string; comment: string } | null;
                    } | null;
                }>;
            } | null;
            legal: {
                companyName: string;
                registrationCountry: string | null;
                companyNumber: string | null;
                companyTaxNumber: string | null;
                companySite: string | null;
                registrationDate: Date | null;
                isConductBusiness: boolean | null;
                isGroupPart: boolean | null;
                isCompanyAddressSame: boolean | null;
                industryDescription: string | null;
                legalAddress: {
                    street: string | null;
                    zip: string | null;
                    city: string | null;
                    country: string | null;
                    building: string | null;
                } | null;
                postalAddress: {
                    street: string | null;
                    zip: string | null;
                    city: string | null;
                    country: string | null;
                    building: string | null;
                } | null;
                documents: Array<{
                    type: UserDocumentType;
                    file: {
                        key: string;
                        path: string;
                        filename: string;
                        comment: { id: string; comment: string } | null;
                    } | null;
                }> | null;
            } | null;
        }> | null;
    } | null;
};

export type GetActiveCompanyServiceQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveCompanyServiceQuery = {
    getActiveCompany: {
        companyService: {
            id: string | null;
            isEurAccount: boolean | null;
            isSwiftAccount: boolean | null;
            isMulticurrencyAccount: boolean | null;
            moneyTurnover: string | null;
            currency: { code: string } | null;
        } | null;
    } | null;
};

export type GetActiveCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveCompanyQuery = {
    getActiveCompany: {
        id: string;
        status: CompanyActivatedStatus | null;
        companyName: string;
        companyLegalForm: CompanyLegalFormType | null;
        registrationCountry: string | null;
        companyNumber: string | null;
        companyTaxNumber: string | null;
        companySite: string | null;
        isCompanyAddressSame: boolean | null;
        registrationDate: Date | null;
        isConductBusiness: boolean | null;
        isGroupPart: boolean | null;
        legalAddress: {
            city: string | null;
            country: string | null;
            street: string | null;
            zip: string | null;
            building: string | null;
        } | null;
        postalAddress: {
            city: string | null;
            country: string | null;
            street: string | null;
            zip: string | null;
            building: string | null;
        } | null;
        documents: Array<{
            type: UserDocumentType;
            file: { filename: string; key: string; path: string } | null;
        }> | null;
        companyStatus: {
            consentToPrivacyPolicyAccepted: Date | null;
            hasAddress: boolean;
            hasIndustry: boolean;
            hasPersons: boolean;
            hasShareholders: boolean;
            hasDocuments: boolean;
            hasService: boolean;
            hasAccept: boolean;
            termsAccepted: Date | null;
        } | null;
    } | null;
};

export type AdminGetCompanyPersonsQueryVariables = Exact<{
    companyId: Scalars['ID']['input'];
}>;

export type AdminGetCompanyPersonsQuery = {
    adminGetCompany: {
        id: string;
        persons: Array<{
            id: string;
            type: UserType;
            relation: {
                beneficiaryPercent: number | null;
                ownershipPercent: number | null;
                companyRelation: Array<UserCompanyRelationType>;
                companyRole: UserRolesType | null;
                isUserCreate: boolean;
                isPowerOfAttorney: boolean | null;
            };
            person: {
                email: string | null;
                phone: string | null;
                profile: {
                    name: string | null;
                    lastName: string | null;
                    thirdName: string | null;
                    dateBirthday: Date | null;
                    gender: UserGender | null;
                    financialStatus: UserFinancialStatus | null;
                    nationality: string | null;
                } | null;
                address: {
                    street: string | null;
                    zip: string | null;
                    city: string | null;
                    country: string | null;
                    building: string | null;
                } | null;
                documents: Array<{
                    type: UserDocumentType;
                    file: {
                        key: string;
                        path: string;
                        filename: string;
                        comment: { id: string; comment: string } | null;
                    } | null;
                }>;
            } | null;
            legal: {
                companyName: string;
                registrationCountry: string | null;
                companyNumber: string | null;
                companyTaxNumber: string | null;
                companySite: string | null;
                registrationDate: Date | null;
                isConductBusiness: boolean | null;
                isGroupPart: boolean | null;
                isCompanyAddressSame: boolean | null;
                industryDescription: string | null;
                legalAddress: {
                    street: string | null;
                    zip: string | null;
                    city: string | null;
                    country: string | null;
                    building: string | null;
                } | null;
                postalAddress: {
                    street: string | null;
                    zip: string | null;
                    city: string | null;
                    country: string | null;
                    building: string | null;
                } | null;
                documents: Array<{
                    type: UserDocumentType;
                    file: {
                        key: string;
                        path: string;
                        filename: string;
                        comment: { id: string; comment: string } | null;
                    } | null;
                }> | null;
            } | null;
        }> | null;
    } | null;
};

export type AdminGetCompanyAddressQueryVariables = Exact<{
    companyId: Scalars['ID']['input'];
}>;

export type AdminGetCompanyAddressQuery = {
    adminGetCompany: {
        id: string;
        isCompanyAddressSame: boolean | null;
        legalAddress: {
            id: string | null;
            street: string | null;
            zip: string | null;
            city: string | null;
            country: string | null;
            building: string | null;
        } | null;
        postalAddress: {
            id: string | null;
            street: string | null;
            zip: string | null;
            city: string | null;
            country: string | null;
            building: string | null;
        } | null;
    } | null;
};

export type AdminGetCompanyDocumentsQueryVariables = Exact<{
    companyId: Scalars['ID']['input'];
    documentStatuses: InputMaybe<Array<UserDocumentStatus> | UserDocumentStatus>;
}>;

export type AdminGetCompanyDocumentsQuery = {
    adminGetCompany: {
        id: string;
        documents: Array<{
            type: UserDocumentType;
            status: UserDocumentStatus;
            updatedAt: Date;
            file: {
                key: string;
                path: string;
                filename: string;
                comment: { id: string; comment: string } | null;
            } | null;
            prevFile: { filename: string; path: string; key: string } | null;
        }> | null;
    } | null;
};

export type AdminGetCompanyServiceQueryVariables = Exact<{
    companyId: Scalars['ID']['input'];
}>;

export type AdminGetCompanyServiceQuery = {
    adminGetCompany: {
        companyService: {
            id: string | null;
            isEurAccount: boolean | null;
            isSwiftAccount: boolean | null;
            isMulticurrencyAccount: boolean | null;
            moneyTurnover: string | null;
            numberOfPersons: string | null;
            numberOfCustomers: string | null;
            isAccountInAnotherBank: boolean | null;
            isSubmitFinancialTaxReport: boolean | null;
            spendPlan: string | null;
            preferredAccountCurrency: string | null;
            currency: { code: string } | null;
        } | null;
    } | null;
};

export type AdminGetCompanyStatusesQueryVariables = Exact<{
    companyId: Scalars['ID']['input'];
}>;

export type AdminGetCompanyStatusesQuery = {
    adminGetCompany: { statuses: Array<{ status: UserActivatedStatus; date: Date }> | null } | null;
};

export type AdminGetCompanyQueryVariables = Exact<{
    companyId: Scalars['ID']['input'];
}>;

export type AdminGetCompanyQuery = {
    adminGetCompany: {
        id: string;
        status: CompanyActivatedStatus | null;
        elcoinId: string | null;
        companyName: string;
        companyLegalForm: CompanyLegalFormType | null;
        registrationCountry: string | null;
        companyNumber: string | null;
        companyTaxNumber: string | null;
        companySite: string | null;
        registrationDate: Date | null;
        isConductBusiness: boolean | null;
        isGroupPart: boolean | null;
        industryDescription: string | null;
        isCompanyAddressSame: boolean | null;
        statuses: Array<{ status: UserActivatedStatus; date: Date }> | null;
        legalAddress: {
            id: string | null;
            city: string | null;
            street: string | null;
            building: string | null;
            zip: string | null;
            country: string | null;
        } | null;
        postalAddress: {
            id: string | null;
            city: string | null;
            street: string | null;
            building: string | null;
            zip: string | null;
            country: string | null;
        } | null;
        owner: { email: string | null; phone: string | null };
        biggestClient: {
            id: string | null;
            country: string | null;
            name: string | null;
            subjectOfBusiness: string | null;
            website: string | null;
        } | null;
        companyStatus: {
            consentToPrivacyPolicyAccepted: Date | null;
            hasAddress: boolean;
            hasIndustry: boolean;
            hasPersons: boolean;
            hasShareholders: boolean;
            hasDocuments: boolean;
            hasService: boolean;
            hasAccept: boolean;
            termsAccepted: Date | null;
        } | null;
    } | null;
};

export type GetMeQueryVariables = Exact<{
    companyId: Scalars['ID']['input'];
}>;

export type GetMeQuery = { getMe: { path: Array<string> | null } | { personId: string } };

export type GetVerificationLinkQueryVariables = Exact<{
    transport: TransportType;
}>;

export type GetVerificationLinkQuery = {
    getVerificationLink: { message: string; path: Array<string> | null } | { href: string | null };
};

export type GetCurrenciesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrenciesQuery = {
    getCurrencies: Array<{ code: string; currency: string; isActive: boolean; number: number }>;
};

export type GetRateQueryVariables = Exact<{
    buyCurrency: Scalars['String']['input'];
    sellCurrency: Scalars['String']['input'];
    amount: Scalars['Int']['input'];
    type: ExchangeType;
}>;

export type GetRateQuery = {
    getRate: {
        rate: string;
        type: ExchangeType;
        buyAmount: number;
        sellAmount: number;
        settlementTime: Date;
        buyCurrency: { code: string; digits: number };
        sellCurrency: { code: string; digits: number };
    };
};

export type AdminAddFeatureTagMutationVariables = Exact<{
    clientId: Scalars['String']['input'];
    userFeatureTag: UserFeatureTag;
}>;

export type AdminAddFeatureTagMutation = {
    adminAddFeatureTag:
        | { status: boolean }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type AdminGetClientFeatureTagsQueryVariables = Exact<{
    clientId: Scalars['ID']['input'];
}>;

export type AdminGetClientFeatureTagsQuery = {
    adminGetClientFeatureTags: Array<{
        status: FeatureTagStatus;
        featureTag: UserFeatureTag;
        message: string | null;
        editable: boolean;
        tagTitle: string;
        requiredFields: Array<{ name: string; required: boolean; title: string; value: string | null } | null>;
    } | null>;
};

export type AdminNextStatusFeatureTagMutationVariables = Exact<{
    clientId: Scalars['String']['input'];
    userFeatureTag: UserFeatureTag;
}>;

export type AdminNextStatusFeatureTagMutation = {
    adminNextStatusFeatureTag:
        | { status: boolean }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type AdminRejectFeatureTagMutationVariables = Exact<{
    featureTag: UserFeatureTag;
    clientId: Scalars['String']['input'];
    status: FeatureTagStatus;
    message: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminRejectFeatureTagMutation = {
    adminRejectFeatureTag:
        | { status: boolean }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type AdminSetFeatureTagDataMutationVariables = Exact<{
    clientId: Scalars['String']['input'];
    userFeatureTag: UserFeatureTag;
    requiredData: FeatureTagRequiredDataInput;
}>;

export type AdminSetFeatureTagDataMutation = {
    adminSetFeatureTagData:
        | { status: boolean }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type AddFileCommentMutationVariables = Exact<{
    fileId: Scalars['ID']['input'];
    commentId: InputMaybe<Scalars['ID']['input']>;
}>;

export type AddFileCommentMutation = { addFileComment: { comment: { id: string } | null } };

export type AdminPaymentAddDocumentsMutationVariables = Exact<{
    paymentId: Scalars['ID']['input'];
    documentIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AdminPaymentAddDocumentsMutation = { adminPaymentAddDocuments: { status: boolean } };

export type UploadFileMutationVariables = Exact<{
    file: Scalars['Upload']['input'];
    commentId: InputMaybe<Scalars['ID']['input']>;
}>;

export type UploadFileMutation = { uploadFile: { filename: string; path: string; key: string } };

export type GetUploadPassportDataQueryVariables = Exact<{
    fileId: Scalars['ID']['input'];
}>;

export type GetUploadPassportDataQuery = {
    getUploadPassportData: {
        nationality: string;
        gender: UserGender;
        name: string;
        lastName: string;
        dateBirthday: Date;
    };
};

export type GetUploadPresignedUrlQueryVariables = Exact<{
    key: Scalars['String']['input'];
}>;

export type GetUploadPresignedUrlQuery = { getUploadPresignedUrl: { url: string } };

export type RemoveFileCommentMutationVariables = Exact<{
    fileId: Scalars['ID']['input'];
    commentId: Scalars['ID']['input'];
}>;

export type RemoveFileCommentMutation = { removeFileComment: { status: boolean } };

export type AdminGetClientsQueryVariables = Exact<{
    filters: InputMaybe<PrivateClientFilters>;
}>;

export type AdminGetClientsQuery = {
    adminGetClients:
        | {
              totalCount: number;
              elements: Array<{
                  id: string;
                  type: UserType;
                  isActivated: boolean;
                  name: string;
                  status: UserActivatedStatus;
                  updatedAt: Date;
                  createdAt: Date;
                  nationality: string | null;
              }>;
          }
        | {};
};

export type AdminApprovePaymentMutationVariables = Exact<{
    paymentId: Scalars['ID']['input'];
}>;

export type AdminApprovePaymentMutation = { adminApprovePayment: { status: boolean } };

export type AdminRejectPaymentMutationVariables = Exact<{
    paymentId: Scalars['ID']['input'];
    message: Scalars['String']['input'];
}>;

export type AdminRejectPaymentMutation = { adminRejectPayment: { status: boolean } };

export type AdminGetFullPaymentQueryVariables = Exact<{
    paymentId: Scalars['ID']['input'];
    clientId: Scalars['ID']['input'];
}>;

export type AdminGetFullPaymentQuery = {
    adminGetPayment: {
        id: string;
        amount: number;
        total: number;
        accountId: string;
        operation: OperationType;
        type: PaymentType;
        reason: string;
        clientId: string;
        status: PaymentStatus;
        createdAt: Date;
        updatedAt: Date;
        currency: { code: string };
        sender: { bicSwift: string; iban: string; name: string } | null;
        fee: { amount: number; total: number } | null;
        documents: Array<{
            filename: string;
            key: string;
            path: string;
            comment: { comment: string; id: string } | null;
        }>;
        beneficiary: {
            iban: string | null;
            bicSwift: string | null;
            acctNumber: string | null;
            sortCode: string | null;
            bankName: string | null;
            bankAddress: string | null;
            bankCountry: string | null;
            beneficiaryFirstName: string | null;
            beneficiaryLastName: string | null;
            beneficiaryAddress: string | null;
            beneficiaryPostcode: string | null;
            beneficiaryStateOrProvince: string | null;
            beneficiaryCity: string | null;
            beneficiaryCompanyName: string | null;
            beneficiaryCountry: string | null;
            beneficiaryEntityType: PaymentEntityType;
        } | null;
    };
    adminGetAccounts: Array<{
        id: string;
        type: AccountType;
        accountId: string;
        balance: number;
        realBalance: number | null;
        freezeBalance: number | null;
        currency: { code: string; currency: string };
        banks: Array<{
            name: string;
            number: string;
            bankName: string;
            bankAddress: string;
            bankCountry: string;
            paymentType: PaymentType;
            sortCode: string | null;
            currency: { code: string; number: number; digits: number; currency: string; isActive: boolean };
        } | null>;
    }>;
    adminGetClient: { name: string } | {};
};

export type AdminGetInternalPaymentsQueryVariables = Exact<{
    filters: PaymentFilter;
}>;

export type AdminGetInternalPaymentsQuery = {
    adminGetInternalPayments: {
        totalCount: number;
        elements: Array<{
            accountId: string;
            amount: number;
            balance: number;
            clientId: string;
            createdAt: Date;
            elcoinStatus: ElcoinPaymentStatus;
            id: string;
            operation: OperationType;
            purpose: string | null;
            reason: string;
            status: PaymentStatus;
            total: number;
            type: PaymentType;
            updatedAt: Date;
            beneficiary: {
                beneficiaryEntityType: PaymentEntityType;
                beneficiaryFirstName: string | null;
                beneficiaryLastName: string | null;
                beneficiaryCompanyName: string | null;
                bankName: string | null;
                sortCode: string | null;
                bicSwift: string | null;
                acctNumber: string | null;
                iban: string | null;
            } | null;
            client: { id: string } | null;
            currency: { code: string };
            fee: { amount: number; total: number } | null;
            sender: { name: string; iban: string } | null;
            statuses: Array<{ status: PaymentStatus; date: Date }>;
        }>;
    };
};

export type AdminGetInwardPaymentsQueryVariables = Exact<{
    filters: PaymentFilter;
}>;

export type AdminGetInwardPaymentsQuery = {
    adminGetInwardPayments: {
        totalCount: number;
        elements: Array<{
            accountId: string;
            amount: number;
            balance: number;
            clientId: string;
            createdAt: Date;
            elcoinStatus: ElcoinPaymentStatus;
            id: string;
            operation: OperationType;
            purpose: string | null;
            reason: string;
            status: PaymentStatus;
            total: number;
            type: PaymentType;
            updatedAt: Date;
            beneficiary: {
                beneficiaryEntityType: PaymentEntityType;
                beneficiaryFirstName: string | null;
                beneficiaryLastName: string | null;
                beneficiaryCompanyName: string | null;
                bankName: string | null;
                sortCode: string | null;
                bicSwift: string | null;
                acctNumber: string | null;
                iban: string | null;
            } | null;
            client: { id: string } | null;
            currency: { code: string };
            fee: { amount: number; total: number } | null;
            sender: { name: string; iban: string } | null;
            statuses: Array<{ status: PaymentStatus; date: Date }>;
        }>;
    };
};

export type AdminGetOutwardPaymentsQueryVariables = Exact<{
    filters: PaymentFilter;
}>;

export type AdminGetOutwardPaymentsQuery = {
    adminGetOutwardPayments: {
        totalCount: number;
        elements: Array<{
            accountId: string;
            amount: number;
            balance: number;
            clientId: string;
            createdAt: Date;
            elcoinStatus: ElcoinPaymentStatus;
            id: string;
            operation: OperationType;
            purpose: string | null;
            reason: string;
            status: PaymentStatus;
            total: number;
            type: PaymentType;
            updatedAt: Date;
            beneficiary: {
                beneficiaryEntityType: PaymentEntityType;
                beneficiaryFirstName: string | null;
                beneficiaryLastName: string | null;
                beneficiaryCompanyName: string | null;
                bankName: string | null;
                sortCode: string | null;
                bicSwift: string | null;
                acctNumber: string | null;
                iban: string | null;
            } | null;
            client: { id: string } | null;
            currency: { code: string };
            fee: { amount: number; total: number } | null;
            sender: { name: string; iban: string } | null;
            statuses: Array<{ status: PaymentStatus; date: Date }>;
        }>;
    };
};

export type AdminGetPaymentStatusQueryVariables = Exact<{
    paymentId: Scalars['ID']['input'];
}>;

export type AdminGetPaymentStatusQuery = { adminGetPayment: { status: PaymentStatus } };

export type AdminGetPaymentQueryVariables = Exact<{
    paymentId: Scalars['ID']['input'];
}>;

export type AdminGetPaymentQuery = {
    adminGetPayment: {
        id: string;
        amount: number;
        accountId: string;
        type: PaymentType;
        reason: string;
        clientId: string;
        createdAt: Date;
        updatedAt: Date;
        currency: { code: string };
        fee: { amount: number; total: number } | null;
        beneficiary: {
            iban: string | null;
            bicSwift: string | null;
            acctNumber: string | null;
            sortCode: string | null;
            bankName: string | null;
            bankAddress: string | null;
            bankCountry: string | null;
            beneficiaryFirstName: string | null;
            beneficiaryLastName: string | null;
            beneficiaryAddress: string | null;
            beneficiaryPostcode: string | null;
            beneficiaryStateOrProvince: string | null;
            beneficiaryCity: string | null;
            beneficiaryCompanyName: string | null;
            beneficiaryCountry: string | null;
            beneficiaryEntityType: PaymentEntityType;
        } | null;
        documents: Array<{
            filename: string;
            key: string;
            path: string;
            comment: { comment: string; id: string } | null;
        }>;
    };
};

export type CheckPhoneCodeMutationVariables = Exact<{
    phone: Scalars['String']['input'];
    code: Scalars['String']['input'];
}>;

export type CheckPhoneCodeMutation = {
    checkPhoneCode:
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          }
        | { status: boolean };
};

export type SendPhoneCodeMutationVariables = Exact<{
    phone: Scalars['String']['input'];
    token: InputMaybe<Scalars['String']['input']>;
}>;

export type SendPhoneCodeMutation = { sendPhoneCode: { status: boolean } };

export type AdminAddPrivateClientCommentMutationVariables = Exact<{
    userId: Scalars['String']['input'];
    commentId: Scalars['String']['input'];
}>;

export type AdminAddPrivateClientCommentMutation = {
    adminAddPrivateClientComment:
        | { status: boolean }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type AdminGetAddressBooksQueryVariables = Exact<{
    filters: InputMaybe<AddressBookFilters>;
    clientId: Scalars['ID']['input'];
}>;

export type AdminGetAddressBooksQuery = {
    adminGetAddressBooks: {
        totalCount: number;
        elements: Array<{
            id: string;
            beneficiary: {
                beneficiaryEntityType: PaymentEntityType;
                acctNumber: string;
                bicSwift: string;
                bankCountry: string | null;
                beneficiaryAddress: string | null;
                beneficiaryCity: string | null;
                beneficiaryCountry: string | null;
                beneficiaryFirstName: string | null;
                beneficiaryLastName: string | null;
                beneficiaryCompanyName: string | null;
                beneficiaryPostcode: string | null;
                nationality: string | null;
                iban: string | null;
                beneficiaryStateOrProvince: string | null;
                cnaps: string | null;
                bankName: string | null;
                bankCode: string | null;
                branchCode: string | null;
                bankAddress: string | null;
                sortCode: string | null;
                ifsc: string | null;
                clabe: string | null;
            };
            currency: { code: string; number: number; digits: number; currency: string; isActive: boolean };
            address: {
                id: string | null;
                street: string | null;
                zip: string | null;
                city: string | null;
                country: string | null;
                building: string | null;
            } | null;
        }>;
    };
};

export type AdminGetPrivateClientAcceptQueryVariables = Exact<{
    userId: Scalars['String']['input'];
}>;

export type AdminGetPrivateClientAcceptQuery = {
    adminGetPrivateClient:
        | {
              phone: string;
              privateStatus: {
                  hasProfile: boolean;
                  hasAddress: boolean;
                  hasService: boolean;
                  hasDocument: boolean;
                  hasDeclaration: boolean;
                  hasAccept: boolean;
              };
          }
        | {}
        | null;
};

export type AdminGetPrivateClientAddressQueryVariables = Exact<{
    userId: Scalars['String']['input'];
}>;

export type AdminGetPrivateClientAddressQuery = {
    adminGetPrivateClient:
        | { address: { street: string | null; zip: string | null; city: string | null; country: string | null } | null }
        | {}
        | null;
};

export type AdminGetPrivateClientCommentsQueryVariables = Exact<{
    userId: Scalars['String']['input'];
}>;

export type AdminGetPrivateClientCommentsQuery = {
    adminGetPrivateClientComments:
        | { elements: Array<{ createdAt: Date; comment: { id: string; comment: string } }> }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type AdminGetPrivateClientDeclarationQueryVariables = Exact<{
    userId: Scalars['String']['input'];
}>;

export type AdminGetPrivateClientDeclarationQuery = {
    adminGetPrivateClient: { declaration: { isPoliticallyPerson: boolean; pep: string } | null } | {} | null;
};

export type AdminGetPrivateClientDocumentsQueryVariables = Exact<{
    userId: Scalars['String']['input'];
    documentStatuses: InputMaybe<Array<UserDocumentStatus> | UserDocumentStatus>;
}>;

export type AdminGetPrivateClientDocumentsQuery = {
    adminGetPrivateClient:
        | {
              documents: Array<{
                  type: UserDocumentType;
                  status: UserDocumentStatus;
                  updatedAt: Date;
                  file: {
                      filename: string;
                      path: string;
                      key: string;
                      comment: { comment: string; id: string } | null;
                  } | null;
                  prevFile: { filename: string; path: string; key: string } | null;
              }>;
          }
        | {}
        | null;
};

export type AdminGetPrivateClientProfileQueryVariables = Exact<{
    userId: Scalars['String']['input'];
}>;

export type AdminGetPrivateClientProfileQuery = {
    adminGetPrivateClient:
        | {
              profile: {
                  name: string | null;
                  lastName: string | null;
                  thirdName: string | null;
                  gender: UserGender | null;
                  dateBirthday: Date | null;
                  nationality: string | null;
                  financialStatus: UserFinancialStatus | null;
              } | null;
          }
        | {}
        | null;
};

export type AdminGetPrivateClientServicesQueryVariables = Exact<{
    userId: Scalars['String']['input'];
}>;

export type AdminGetPrivateClientServicesQuery = {
    adminGetPrivateClient:
        | {
              service: {
                  isEurIban: boolean;
                  isPaymentAccount: boolean;
                  moneyTurnover: string | null;
                  reasonAccount: string | null;
                  currency: { code: string };
              } | null;
          }
        | {}
        | null;
};

export type AdminGetPrivateClientStatusesQueryVariables = Exact<{
    userId: Scalars['String']['input'];
}>;

export type AdminGetPrivateClientStatusesQuery = {
    adminGetPrivateClient: { statuses: Array<{ status: UserActivatedStatus; date: Date }> } | {} | null;
};

export type AdminGetPrivateClientQueryVariables = Exact<{
    userId: Scalars['String']['input'];
}>;

export type AdminGetPrivateClientQuery = {
    adminGetPrivateClient:
        | {
              id: string;
              email: string;
              elcoinId: string | null;
              phone: string;
              type: UserType | null;
              status: UserActivatedStatus;
              statuses: Array<{ status: UserActivatedStatus; date: Date }>;
              address: {
                  street: string | null;
                  zip: string | null;
                  city: string | null;
                  country: string | null;
                  building: string | null;
              } | null;
              service: {
                  isEurIban: boolean;
                  isPaymentAccount: boolean;
                  moneyTurnover: string | null;
                  reasonAccount: string | null;
                  sourceOfFunds: string | null;
                  currency: { code: string };
              } | null;
              declaration: { isPoliticallyPerson: boolean; pep: string } | null;
              privateStatus: {
                  hasProfile: boolean;
                  hasAddress: boolean;
                  hasService: boolean;
                  hasDeclaration: boolean;
                  hasDocument: boolean;
                  hasAccept: boolean;
                  termsAccepted: Date | null;
              };
              legalStatus: { hasCompany: boolean };
              profile: {
                  name: string | null;
                  lastName: string | null;
                  thirdName: string | null;
                  dateBirthday: Date | null;
                  gender: UserGender | null;
                  financialStatus: UserFinancialStatus | null;
                  nationality: string | null;
              } | null;
          }
        | {}
        | null;
};

export type AdminGetPrivateClientUserQueryVariables = Exact<{
    userId: Scalars['String']['input'];
}>;

export type AdminGetPrivateClientUserQuery = {
    adminGetPrivateClient: { id: string; email: string; phone: string } | {} | null;
};

export type AdminGetPdfTransactionReceiptQueryVariables = Exact<{
    paymentId: Scalars['ID']['input'];
    clientId: Scalars['ID']['input'];
    accountId: Scalars['ID']['input'];
}>;

export type AdminGetPdfTransactionReceiptQuery = { adminGetPDFTransactionReceipt: { reportId: string } };

export type AdminGetPdfTransactionsQueryVariables = Exact<{
    clientId: Scalars['ID']['input'];
    filters: InputMaybe<TransactionFilter>;
}>;

export type AdminGetPdfTransactionsQuery = { adminGetPDFTransactions: { reportId: string } };

export type AdminGetReportPresignedUrlQueryVariables = Exact<{
    reportId: Scalars['ID']['input'];
}>;

export type AdminGetReportPresignedUrlQuery = { adminGetReportPresignedUrl: { url: string } };

export type AdminGetReportQueryVariables = Exact<{
    adminGetReportId: Scalars['ID']['input'];
}>;

export type AdminGetReportQuery = {
    adminGetReport: {
        id: string;
        accountId: string;
        status: ReportStatus;
        createdAt: Date;
        updatedAt: Date;
        key: string | null;
        message: string | null;
        filter: {
            limit: number | null;
            offset: number | null;
            status: PaymentStatus | null;
            type: TransactionType | null;
            isAll: boolean | null;
            accountId: string | null;
            currency: string | null;
            search: string | null;
            operationType: OperationType | null;
            paymentType: PaymentType | null;
            createdAt: { lte: Date | null; gte: Date | null } | null;
            updatedAt: { lte: Date | null; gte: Date | null } | null;
        } | null;
    };
};

export type AdminGetTransactionsShortQueryVariables = Exact<{
    clientId: Scalars['ID']['input'];
    filters: InputMaybe<TransactionFilter>;
}>;

export type AdminGetTransactionsShortQuery = {
    adminGetTransactions: { elements: Array<{ id: string; amount: number }> };
};

export type AdminGetTransactionsFullQueryVariables = Exact<{
    clientId: Scalars['ID']['input'];
    filters: InputMaybe<TransactionFilter>;
}>;

export type AdminGetTransactionsFullQuery = {
    adminGetTransactions: {
        total: number;
        elements: Array<{
            type: TransactionType;
            operation: OperationType;
            id: string;
            createdAt: Date;
            status: PaymentStatus;
            rate: number | null;
            amount: number;
            total: number;
            reason: string | null;
            balance: number | null;
            buyAmount: number | null;
            sellAmount: number | null;
            buyBalance: number | null;
            sellBalance: number | null;
            exchangeType: ExchangeType | null;
            paymentType: PaymentType | null;
            client: { id: string; name: string } | null;
            fee: { amount: number; total: number } | null;
            beneficiary: {
                beneficiaryEntityType: PaymentEntityType;
                beneficiaryFirstName: string | null;
                beneficiaryLastName: string | null;
                beneficiaryCompanyName: string | null;
                bankName: string | null;
                sortCode: string | null;
                bicSwift: string | null;
                acctNumber: string | null;
                iban: string | null;
            } | null;
            sender: { name: string; iban: string } | null;
            currency: { code: string } | null;
            buyCurrency: { code: string } | null;
            sellCurrency: { code: string } | null;
        }>;
    };
};

export type AdminCheckUserDocumentsQueryVariables = Exact<{
    personId: InputMaybe<Scalars['ID']['input']>;
    userId: InputMaybe<Scalars['String']['input']>;
}>;

export type AdminCheckUserDocumentsQuery = {
    adminCheckUserDocuments:
        | { status: boolean }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ message: string; name: string }> } | null;
          };
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetCurrentUserQuery = {
    getCurrentUser:
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          }
        | { id: string; email: string }
        | null;
};

export type GetExternalApplicantLinkQueryVariables = Exact<{
    clientId: Scalars['String']['input'];
}>;

export type GetExternalApplicantLinkQuery = { adminGetExternalApplicantLink: { href: string | null } | {} };

export type GetUserInfoByIpQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserInfoByIpQuery = {
    getUserInfoByIp: {
        userIp: string;
        ipInfo: {
            country: string;
            region: string;
            eu: string;
            timezone: string;
            city: string;
            ll: Array<number>;
            metro: number;
            area: number;
        } | null;
    };
};

export type AdminGetUsersQueryVariables = Exact<{
    filters: InputMaybe<UsersFilter>;
}>;

export type AdminGetUsersQuery = {
    adminGetUsers: {
        totalCount: number;
        elements: Array<{
            id: string;
            email: string;
            phone: string;
            type: UserType | null;
            status: UserActivatedStatus;
            createdAt: Date;
            privateStatus: {
                hasProfile: boolean;
                hasAddress: boolean;
                hasService: boolean;
                hasDeclaration: boolean;
                hasDocument: boolean;
                hasAccept: boolean;
            };
            legalStatus: { hasCompany: boolean };
            profile: { name: string | null; lastName: string | null } | null;
        }>;
    };
};

export type SendRegistrationMailMutationVariables = Exact<{
    email: Scalars['String']['input'];
}>;

export type SendRegistrationMailMutation = {
    sendRegistrationMail:
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          }
        | { status: boolean };
};

export type AdminUserActivatedStatusUpdateMutationVariables = Exact<{
    userId: Scalars['String']['input'];
    status: UserActivatedStatus;
}>;

export type AdminUserActivatedStatusUpdateMutation = { adminUserActivatedStatusUpdate: { status: boolean } | {} };

export type UserConfirmLoginMutationVariables = Exact<{
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
    otp: Scalars['String']['input'];
}>;

export type UserConfirmLoginMutation = {
    userConfirmLogin:
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          }
        | {
              id: string | null;
              phone: string | null;
              role: UserRole | null;
              roleCompany: UserRolesType | null;
              webPreferredLoginMethod: WebPreferredLoginMethod;
              email: string | null;
              sessionId: string | null;
              companyPermissions: { permissions: Array<string | null> | null } | null;
          };
};

export type UserGoogleAuthenticatorLoginMutationVariables = Exact<{
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
    totp: Scalars['String']['input'];
}>;

export type UserGoogleAuthenticatorLoginMutation = {
    userGoogleAuthenticatorLogin:
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          }
        | {
              id: string | null;
              phone: string | null;
              role: UserRole | null;
              roleCompany: UserRolesType | null;
              bioToken: string | null;
              isBiometryAccepted: boolean | null;
              email: string | null;
              sessionId: string | null;
              companyPermissions: { permissions: Array<string | null> | null } | null;
          };
};

export type UserResetPasswordUpdateMutationVariables = Exact<{
    code: Scalars['String']['input'];
    password: Scalars['String']['input'];
}>;

export type UserResetPasswordUpdateMutation = {
    userResetPasswordUpdate:
        | { status: boolean }
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          };
};

export type UserPasswordResetMutationVariables = Exact<{
    email: Scalars['String']['input'];
}>;

export type UserPasswordResetMutation = { userPasswordReset: { status: boolean } };

export type UserStartLoginMutationVariables = Exact<{
    email: Scalars['String']['input'];
    password: Scalars['String']['input'];
    recaptchaToken: Scalars['String']['input'];
    loginMethod: InputMaybe<WebPreferredLoginMethod>;
}>;

export type UserStartLoginMutation = {
    userStartLogin:
        | {
              message: string;
              path: Array<string> | null;
              extensions: { invalidArgs: Array<{ name: string; message: string }> } | null;
          }
        | {
              appPreferredLoginMethod: AppPreferredLoginMethod;
              webPreferredLoginMethod: WebPreferredLoginMethod;
              phone: string;
              webAvailableLoginMethods: Array<WebPreferredLoginMethod | null>;
          };
};

export interface PossibleTypesResultData {
    possibleTypes: {
        [key: string]: string[];
    };
}
const result: PossibleTypesResultData = {
    possibleTypes: {
        AccountAdministrationResult: ['AccountAdministration', 'Error'],
        AccountCurrencyResult: ['DefaultAnswer', 'Error'],
        AccountDefaultResult: ['AccountDefault', 'Error'],
        ActivateGoogleAuthenticatorResult: ['ActivateGoogleAuthenticator', 'Error'],
        AddressBookUpdateResult: ['AddressBook', 'Error'],
        AdminGetElcoinClientDataResult: ['AdminGetElcoinClientDataAnswer', 'Error'],
        ApproveTransactionAccountResult: ['DefaultAnswer', 'Error'],
        CanceledPaymentResult: ['DefaultAnswer', 'Error'],
        CheckUserExistResult: ['CheckUserExistAnswer', 'Error'],
        ClientCommentsResult: ['ClientComments', 'Error'],
        ClientOneInternalResult: ['ClientInternal', 'Error'],
        ClientOneResult: ['Client', 'Error'],
        ClientResult: ['ClientAnswer', 'Error'],
        CommissionResult: ['Commission', 'Error'],
        CompanyDocumentsDeleteResult: ['CompanyDocumentsDeleteAnswer', 'Error'],
        CompanyServiceResult: ['CompanyService', 'Error'],
        ConversionAnswerResult: ['ConversionAnswer', 'Error'],
        ConversionReservationResult: ['ConversionReservation', 'Error'],
        ConversionResult: ['Conversion', 'Error'],
        CreateCardWithdrawalResult: ['CreateCardWithdrawalAnswer', 'Error'],
        DefaultAnswerResult: ['DefaultAnswer', 'Error'],
        DefaultResult: ['DefaultAnswer', 'Error'],
        FeatureTagRequiredDataResult: ['Error', 'FeatureTagRequiredData'],
        GetAvailableCountriesResult: ['Error', 'GetCountriesAnswer'],
        GetExternalApplicantLinkResult: ['Error', 'ExternalApplicantLink'],
        GetExternalRegistrationLinkResult: ['Error', 'UserVerificationLink'],
        GetMailResult: ['Error', 'GetMailById'],
        GetVerificationLinkResult: ['Error', 'UserVerificationLink'],
        GetVerificationParamsResult: ['Error', 'UserVerificationParams'],
        HoldInstructionResult: ['Error', 'HoldInstructionAnswer'],
        IbanqSepaRequisitesResult: ['Error', 'IbanqSepaRequisites'],
        InternalBeneficiaryDetailsResult: ['Error', 'InternalBeneficiaryDetails'],
        InternalGetAccountDtoResult: ['Error', 'InternalGetAccountDtoAnswer'],
        InternalUpdateCardWithdrawalsResult: ['Error', 'InternalUpdateCardWithdrawalsAnswer'],
        IsUserHasAccessToCompanyResult: ['Error', 'IsUserHasAccessToCompanyAnswer'],
        JoinWaitingListResult: ['Error', 'WaitingListElement'],
        PaymentFeeResult: ['Error', 'PaymentFee'],
        PersonDocumentsDeleteResult: ['Error', 'PersonDocumentsDeleteAnswer'],
        RegistrationLegalResult: ['Error', 'LegalAnswer'],
        RegistrationPersonResult: ['Error', 'PersonAnswer'],
        SendMailResult: ['Error', 'SendMailStatus'],
        SendPhoneCodeResult: ['Error', 'SendPhoneCodeStatus'],
        SignedPaymentResult: ['DefaultAnswer', 'Error'],
        StepsStatus: ['CompanyStatus', 'UserPrivateStatus'],
        TermsStatusResult: ['Error', 'TermsStatusAnswer'],
        UserCompanyPersonResult: ['Error', 'UserCompanyPerson'],
        UserCreateCompanyResult: ['CreatedUserCompany', 'Error'],
        UserCreateExternalIdResult: ['Error', 'UserCreateExternalId'],
        UserLoginResult: ['Error', 'UserLogin'],
        UserMobileStartLoginResult: ['Error', 'UserMobileStartLogin'],
        UserPushLoginResult: ['Error', 'PushLogin', 'UserLogin'],
        UserRegistrationResult: ['Error', 'UserRegistration'],
        UserResult: ['Error', 'User'],
        UserSaveAddressResult: ['Address', 'Error'],
        UserSaveCompanyAddressResult: ['CompanyAddress', 'Error'],
        UserSaveCompanyResult: ['Error', 'UserCompany'],
        UserSaveDeclarationResult: ['Error', 'UserDeclaration'],
        UserSaveDocumentsResult: ['Error', 'UserDocumentAnswer'],
        UserSaveProfilleResult: ['Error', 'UserProfile'],
        UserSaveServiceResult: ['Error', 'UserService'],
        UserSessionListResult: ['Error', 'UserSessionList'],
        UserSetBiometryLoginResult: ['Error', 'UserSetBiometryLogin'],
        UserSetOnboardingStepResult: ['Error', 'UserOnboardingStep'],
        UserStartLoginResult: ['Error', 'UserStartLogin'],
    },
};
export default result;
