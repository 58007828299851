import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { ButtonLink } from '@shared-atom/button/button-link';
import { Translate } from '@shared-atom/translate/translate';
import { InputOtp } from '@shared-component/input/input-otp/input-otp';
import { Timer } from '@shared-component/timer/timer';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { ButtonResendWrapper } from './otp.styles';

const TWO_MINUTE = 2 * 60_000;
const OTP_VALUE_LENGTH = 11;

export interface OtpFormProps {
    name: string;
    error?: string;
    onResendOtpCode: OnEventEmptyType;
}

export const OtpForm = ({ name, error, onResendOtpCode }: OtpFormProps) => {
    const [isResendAvailable, setResendAvailable] = useState(false);

    const [{ value }, { error: otpError }, { setError }] = useField(name);

    const { setErrors, handleSubmit } = useFormikContext();

    const handleTimerComplete = () => setResendAvailable(true);
    const handleOtpResend = () => {
        setResendAvailable(false);
        onResendOtpCode();
    };

    useEffect(
        () =>
            void (
                error !== undefined &&
                otpError !== error &&
                value?.length === OTP_VALUE_LENGTH &&
                setErrors({ [name]: error })
            ),
        [otpError, error]
    );

    useEffect(() => {
        if (value?.length === OTP_VALUE_LENGTH) {
            handleSubmit();
        } else {
            setError(undefined);
        }
    }, [value]);

    return (
        <>
            <InputOtp name={name} />
            <ButtonResendWrapper>
                {isResendAvailable && (
                    <>
                        <Translate langKey={LocalizationEnum.OtpFormDescriptionResend} />{' '}
                        <ButtonLink onClick={handleOtpResend} isDisabled={!isResendAvailable}>
                            <Translate langKey={LocalizationEnum.OtpFormDescriptionResendSms} />
                        </ButtonLink>
                    </>
                )}
                {!isResendAvailable && (
                    <>
                        <Translate langKey={LocalizationEnum.OtpFormDescriptionWait} />{' '}
                        <Timer time={TWO_MINUTE} onComplete={handleTimerComplete} />
                    </>
                )}
            </ButtonResendWrapper>
        </>
    );
};
