import { types } from 'mobx-state-tree';

export const UserPrivateStatusModel = types.model('PrivateStatus', {
    hasProfile: types.boolean,
    hasAddress: types.boolean,
    hasService: types.boolean,
    hasDeclaration: types.boolean,
    hasDocument: types.boolean,
    hasAccept: types.boolean,
    termsAccepted: types.maybeNull(types.number),
});
