import { useField } from 'formik';
import { observer } from 'mobx-react-lite';

import { Input } from '@shared-component/input/input';
import { InputAmount } from '@shared-component/input/input-amount/input-amount';
import { TextArea } from '@shared-component/input/textarea/textarea';
import { PaymentBeneficiaryRadioButtons } from '@shared-component/payment-beneficiary-radio-buttons/payment-beneficiary-radio-buttons';
import { Select } from '@shared-component/select/select';
import { FormHalfRow, FormRow, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { PaymentEntityType } from '@shared-graphql';
import { TEXT_AREA_LENGTH } from '@shared-util/constants';

import { PaymentEditEnum } from '@page/payment/payment-edit/payment-edit.enum';
import { useCurrenciesSelector } from '@selector/currencies/use-currencies.selector';

import { PaymentFormComponentTitle, PaymentFormWrapper } from './payment-edit.styles';

export const PaymentEditForm = observer(() => {
    const [{ currenciesSelect }] = useCurrenciesSelector();
    const [{ value: entityType }] = useField(PaymentEditEnum.BeneficiaryEntityType);
    const isCompany = entityType === PaymentEntityType.company;

    return (
        <PaymentFormWrapper>
            <FormRow>
                <PaymentFormComponentTitle>Payer</PaymentFormComponentTitle>
            </FormRow>
            <FormHalfRow>
                <Input title="Name" name={PaymentEditEnum.PayerName} disabled />
                <Input title="Account" name={PaymentEditEnum.PayerAccountNumber} disabled />
            </FormHalfRow>
            <FormHalfRow>
                <Select title="Currency" name={PaymentEditEnum.Currency} options={currenciesSelect} />
                <InputAmount title="Amount" name={PaymentEditEnum.Amount} />
            </FormHalfRow>
            <FormRow>
                <TextArea title="Description" name={PaymentEditEnum.Reason} maxLength={TEXT_AREA_LENGTH} />
            </FormRow>

            <FormRow>
                <PaymentFormComponentTitle>Beneficiary details</PaymentFormComponentTitle>
            </FormRow>
            <FormHalfRow>
                <Input title="IBAN" name={PaymentEditEnum.Iban} />
                <Input title="BicSwift" name={PaymentEditEnum.BicSwift} />
            </FormHalfRow>
            <FormHalfRow>
                <Input title="Account number" name={PaymentEditEnum.AccountNumber} />
                <Input title="Sort code" name={PaymentEditEnum.SortCode} />
            </FormHalfRow>
            <FormRow>
                <Input title="Bank name" name={PaymentEditEnum.BankName} />
            </FormRow>

            <FormWrapper>
                <PaymentBeneficiaryRadioButtons name={PaymentEditEnum.BeneficiaryEntityType} />
                {isCompany && (
                    <FormRow>
                        <Input title="Company name" name={PaymentEditEnum.BeneficiaryCompanyName} />
                    </FormRow>
                )}
                {!isCompany && (
                    <FormHalfRow>
                        <Input title="First name" name={PaymentEditEnum.BeneficiaryFirstName} />
                        <Input title="Last name" name={PaymentEditEnum.BeneficiaryLastName} />
                    </FormHalfRow>
                )}
                <FormRow>
                    <PaymentFormComponentTitle>Beneficiary address</PaymentFormComponentTitle>
                </FormRow>
                <FormRow>
                    <Input title="Address" name={PaymentEditEnum.BeneficiaryAddress} />
                </FormRow>
                <FormHalfRow>
                    <Input title="City" name={PaymentEditEnum.BeneficiaryCity} />
                    <Input title="Country" name={PaymentEditEnum.BeneficiaryCountry} />
                </FormHalfRow>
                <FormHalfRow>
                    <Input title="State Or Province" name={PaymentEditEnum.BeneficiaryStateOrProvince} />
                    <Input title="Postcode" name={PaymentEditEnum.BeneficiaryPostcode} />
                </FormHalfRow>
            </FormWrapper>
        </PaymentFormWrapper>
    );
});
