import { FormRow } from '@shared-form/form-common/form-common.styles';
import { PaymentEntityType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PaymentBeneficiaryRadioButton } from './payment-beneficiary-radio-button/payment-beneficiary-radio-button';
import { IndividualIcon, CompanyIcon } from './payment-beneficiary-radio-buttons.styles';

interface PaymentBeneficiaryRadioButtonsProps {
    name: string;
}

export const PaymentBeneficiaryRadioButtons = ({ name }: PaymentBeneficiaryRadioButtonsProps) => (
    <FormRow>
        <PaymentBeneficiaryRadioButton
            name={name}
            value={PaymentEntityType.individual}
            label={LocalizationEnum.UserTypePrivate}
        >
            <IndividualIcon />
        </PaymentBeneficiaryRadioButton>
        <PaymentBeneficiaryRadioButton
            name={name}
            value={PaymentEntityType.company}
            label={LocalizationEnum.UserTypeLegacy}
        >
            <CompanyIcon />
        </PaymentBeneficiaryRadioButton>
    </FormRow>
);
