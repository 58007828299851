import { Fragment, memo, useContext } from 'react';

import { InformationModal } from '@shared-component/information-modal/information-modal';
import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';

import { CopyInformationModal } from '@component/informaton-modal/modals/copy-information-modal/copy-information-modal';
import { FailureInformationModal } from '@component/informaton-modal/modals/payment-failure-information-modal/failure-information-modal';
import { SuccessInformationModal } from '@component/informaton-modal/modals/request-success-modal/success-information-modal';

export const ClientInformationModal = memo(() => {
    const { selectedInformationModal } = useContext(InformationModalContext);

    return (
        <InformationModal>
            <>
                {selectedInformationModal === InformationModalEnum.CopyInformationModal && (
                    <CopyInformationModal isVisible />
                )}
                {selectedInformationModal === InformationModalEnum.FailureInformationModal && (
                    <FailureInformationModal isVisible />
                )}
                {selectedInformationModal === InformationModalEnum.SuccessInformationModal && (
                    <SuccessInformationModal isVisible />
                )}
            </>
        </InformationModal>
    );
});
