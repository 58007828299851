import styled from 'styled-components';

import { EerieBlack, Gray65, GreenHaze, White } from '@shared-style/colors';

interface INavigationListItemButtonProps {
    active: boolean;
}

export const NavigationListItem = styled.li`
    color: ${Gray65};
    display: block;
    width: 100%;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    font-feature-settings: 'salt' on;
    line-height: 20px;
`;

export const NavigationListItemButton = styled.button<INavigationListItemButtonProps>`
    width: 100%;
    text-decoration: none;
    padding: 16px 0 16px 16px;
    color: ${({ active }) => (active ? White : 'inherit')};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    border: none;
    outline: none !important;
    border-radius: 8px 0 0 8px;
    border-right: ${({ active }) => (active ? `2px solid ${GreenHaze}` : 'none')};
    background-color: ${({ active }) => (active ? EerieBlack : 'transparent')};
    :hover {
        /* background: ${EerieBlack}; */
        border-right: 2px solid ${GreenHaze};
        /* border-radius: 8px 0px 0px 8px; */
        color: white;
        path {
            fill: ${White};
        }
    }
    a {
        color: inherit;
        display: flex;
        :hover {
            text-decoration: none;
        }
        :visited {
            color: inherit;
        }
    }
`;

export const NavigationListItemIcon = styled.div`
    width: 20px;
    height: 20px;
    margin-right: 8px;
    display: inline-block;
    svg {
        width: 20px;
        height: 20px;
        min-width: 20px !important;
    }
`;
