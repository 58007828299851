import { useLazyQuery } from '@apollo/client';
import { useCallback, useContext, useEffect } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { GetUploadPresignedUrl, Query } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

export const useUploadPresignedUrl = () => {
    const { onOpen } = useContext(InformationModalContext);

    const [getUploadPresignedUrl, { data, loading: isLoading, error }] =
        useLazyQuery<Pick<Query, 'getUploadPresignedUrl'>>(GetUploadPresignedUrl);

    useEffect(() => {
        if (isTrue(error)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: error?.message ?? 'Something went wrong',
            });
        }
    }, [error]);

    const handleUploadPresignedUrl = useCallback(
        (key: string) => {
            getUploadPresignedUrl({ variables: { key } });
        },
        [getUploadPresignedUrl]
    );

    return {
        url: (data?.getUploadPresignedUrl?.url as unknown as string) ?? '',
        isLoading,
        handleUploadPresignedUrl,
    };
};
