import { types } from 'mobx-state-tree';

import { adminActions } from '@model/admin-model/admin-model-actions/admin-model.actions';

import { initialAdminModel } from './admin-model-initials/admin-model.initial';
import { AdminModelType } from './admin-model.type';

export const AdminModelInstance = types.model({
    admin: AdminModelType,
    loading: types.model({
        isLoading: types.boolean,
    }),
    error: types.optional(types.string, ''),
});

export const AdminModel = AdminModelInstance.actions(adminActions);

export const getInitialUserModel = () =>
    AdminModel.create({
        admin: initialAdminModel as any,
        loading: {
            isLoading: false,
        },
    });
