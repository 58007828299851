import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { Translate } from '@shared-atom/translate/translate';
import {
    RegistrationLegalDescription,
    RegistrationLegalPersonTitle,
} from '@shared-component/registration/common.styles';
import { RelationPersonInterface } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.interface';
import { RegistrationLegalPersonPrivateProfileFormValidation } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.validation';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';

import { RegistrationLegalPersonPrivateProfileSubmit } from './registration-legal-person-private-profile-submit/registration-legal-person-private-profile-submit';
import { RegistrationLegalPersonPrivateProfileWrapper } from './registration-legal-person-private-profile-wrapper/registration-legal-person-private-profile-wrapper';

interface RegistrationLegalPersonPrivateProfileProps {
    personal: RelationPersonInterface;
    isCurrentUser?: boolean;
    onSubmit: OnEventType<RelationPersonInterface>;
}

export const RegistrationLegalPersonPrivateProfile = observer(
    ({ personal, isCurrentUser, onSubmit }: RegistrationLegalPersonPrivateProfileProps) => (
        <>
            <RegistrationLegalPersonTitle>
                <Translate langKey={LocalizationEnum.RegistrationLegalPersonsPrivatePersonalTitle} />
            </RegistrationLegalPersonTitle>
            <RegistrationLegalDescription>
                <Translate langKey={LocalizationEnum.RegistrationLegalPersonsPrivatePersonalDescription} />
            </RegistrationLegalDescription>
            <Formik
                initialValues={personal}
                onSubmit={onSubmit}
                validationSchema={RegistrationLegalPersonPrivateProfileFormValidation(false)}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
            >
                <Form>
                    <RegistrationLegalPersonPrivateProfileWrapper isCurrentUser={isCurrentUser} />
                    <RegistrationLegalPersonPrivateProfileSubmit />
                </Form>
            </Formik>
        </>
    )
);
