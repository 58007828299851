import { MenuProps } from 'react-select';
import { OptionTypeBase } from 'react-select/src/types';
import { ListRowProps } from 'react-virtualized/dist/es/List';

import { RowRenderInterface } from '@shared-component/select/select.props';
import { SelectRowWrapperStyle } from '@shared-component/select/select.styles';

export const selectRowWrapper =
    ({ options, setValue }: MenuProps<OptionTypeBase, boolean>, rowRender: RowRenderInterface<OptionTypeBase>) =>
        ({ style, key, index }: ListRowProps) => {
            const currentItem = options[index];

            const handleChoose = () => setValue(currentItem, 'select-option');

            return (
                <SelectRowWrapperStyle key={key} style={style} onClick={handleChoose}>
                    {rowRender(currentItem, index)}
                </SelectRowWrapperStyle>
            );
        };
