import { Form, Formik } from 'formik';
import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { MutationAddCommentArgs } from '@shared-graphql';
import { CommentsModelType } from '@shared-model/comment-model/comments-model';
import { OnEventType } from '@shared-type/on-event.type';

import { initialComment } from './comment-initials';
import { CommentFormValidation } from './comment.validation';
import { FormWrapper, FormButtonWrapper } from './comments-form.styles';
import { FormTextArea } from './comments-text-area';

interface CommentsFormProps {
    comments: Instance<typeof CommentsModelType>[];
    onAddComment: OnEventType<string>;
}

export const CommentsForm: FC<CommentsFormProps> = ({ comments, onAddComment }) => {
    const handleAddComment = ({ comment }: MutationAddCommentArgs) => onAddComment(comment);

    return (
        <Formik
            initialValues={initialComment}
            onSubmit={handleAddComment}
            validationSchema={CommentFormValidation}
            validateOnChange={false}
        >
            <Form>
                <FormWrapper>
                    <FormTextArea comments={comments} />
                    <FormButtonWrapper>
                        <ButtonPrimary type={ButtonTypeEnum.Submit} isFull>
                            Add Comment
                        </ButtonPrimary>
                    </FormButtonWrapper>
                </FormWrapper>
            </Form>
        </Formik>
    );
};
