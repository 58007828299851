import { Instance } from 'mobx-state-tree';

import { CompanyModelTypeInstance } from '@model/company-model/company-model.type';
import { useCompanySelector } from '@selector/company/use-company.selector';

export const useLegalClientProfileCompanyUpdate = (): [Instance<typeof CompanyModelTypeInstance>] => {
    const [currentCompany] = useCompanySelector();

    return [currentCompany];
};
