export enum CreateClientFormEnum {
    Email = 'email',
    Type = 'type',
    UserId = 'userId',
}

export enum ClientTypesEnum {
    PrivateIndividual = 'Private individual',
    LegalEntity = 'Legal entity',
}
