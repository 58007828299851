import { useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useMemo } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal-context';
import { AdminAddFeatureTag, AdminAddFeatureTagMutationVariables, DefaultAnswer, Mutation } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { useServicesByUserType } from '@shared-hook/services-by-user-type/services-by-user-type';
import { isExist, isFalse, isTrue } from '@shared-util/is-data';

export const useAddServiceModal = () => {
    const { params } = useCurrentNavigation();
    const { onOpen } = useContext(InformationModalContext);
    const { props, onClose } = useContext(ModelContext);

    const clientId = useMemo(() => (isTrue(params.userId) ? params.userId : params.companyId), [params]);

    const { options: serviceOptions } = useServicesByUserType(props?.componentProps?.userType);

    const [adminAddFeatureTag, { data: addFeatureTagData, error: addFeatureTagError }] =
        useMutation<Pick<Mutation, 'adminAddFeatureTag'>>(AdminAddFeatureTag);

    useEffect(() => {
        if (isTrue((addFeatureTagData?.adminAddFeatureTag as DefaultAnswer)?.status)) {
            onOpen(InformationModalEnum.SuccessInformationModal, { text: 'Service has been successfully added' });
            props?.componentProps?.adminGetClientFeatureTags();
            onClose();
        }
        if (isExist((addFeatureTagData?.adminAddFeatureTag as DefaultAnswer)?.status)) {
            if (isFalse((addFeatureTagData?.adminAddFeatureTag as DefaultAnswer)?.status)) {
                onOpen(InformationModalEnum.FailureInformationModal, { text: 'Something went wrong' });
                onClose();
            }
        }
    }, [addFeatureTagData]);

    useEffect(() => {
        if (isTrue(addFeatureTagError)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: addFeatureTagError?.message ?? 'Something went wrong',
            });

            onClose();
        }
    }, [addFeatureTagError]);

    const handleSubmit = useCallback(
        (values: AdminAddFeatureTagMutationVariables) => {
            adminAddFeatureTag({ variables: { userFeatureTag: values.userFeatureTag, clientId } });
        },
        [adminAddFeatureTag]
    );

    const onCancelClick = useCallback(() => {
        onClose();
    }, []);

    return { serviceOptions, handleSubmit, onCancelClick };
};
