import { FC, SVGProps } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { Text, Title, Wrapper } from './empty-state.styles';

export interface EmptyStateProps {
    titleKey: LocalizationEnum;
    textKey: LocalizationEnum;
    icon: FC<SVGProps<SVGSVGElement>>;
    isSmall?: boolean;
}

export const EmptyState: FC<EmptyStateProps> = ({ titleKey, textKey, icon: Icon, isSmall = false }) => (
    <Wrapper isSmall={isSmall}>
        <Icon />
        <Title>
            <Translate langKey={titleKey} />
        </Title>
        <Text>
            <Translate langKey={textKey} />
        </Text>
    </Wrapper>
);
