import styled from 'styled-components';

import { getAnimationStyle } from '@shared-style/animation';
import { DarkBlue, LinkWater } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

export const PageNavigationContentWrapper = styled.div`
    width: 100%;
    ${Flex.Column.FlexStart.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding-right: 0px !important;
    }
`;

export const PageNavigationWrapper = styled.nav`
    position: relative;
    padding: 24px;
    padding-right: 2px;
    width: 100%;
    max-width: 288px;
    background-color: ${DarkBlue};
    border-radius: 0 16px 16px 0;
    overflow: hidden;
    ${Flex.Column.FlexStart.SpaceBetween.Style}
    ${getAnimationStyle(['width'])}
    
    ${PageNavigationContentWrapper} {
        ${Flex.FlexStart.Style}
        padding-right: 22px;
    }

    order: 0;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 10px;
        max-width: unset;
        border-radius: unset;
        width: 100vw;
        order: 2;
    }
    @media (max-width: 560px) {
        padding: 5px;
    }
`;

export const PageWrapperRoot = styled.section`
    height: 100%;
    background-color: ${LinkWater};
    ${Flex.Row.Style}
    ${getAnimationStyle(['background-color'])}
    flex-direction: row;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        flex-direction: column;
    }
`;

export const PageContentWrapper = styled.div`
    width: 100%;
    flex: 1;
    padding: 32px 20px 0px 20px;
    ${Flex.Column.Style};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        padding: 32px 10px 0px 10px;
    }
`;

export const LogoWrapper = styled.div`
    width: 74px;
    height: 24px;
    overflow: hidden;
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: 120px;
        height: 20px;
    }
`;
