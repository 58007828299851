import { ReactNode } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { CommissionProfileInfoCurrency } from '@page/commission-profile/commission-profile-info/commission-profile-info-currency/commission-profile-info-currency';

import { Price, TextItem } from './commission-profile-info-item.styles';

interface CommissionProfileListProps {
    title: LocalizationEnum;
    amount: number;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const CommissionProfileInfoItem = ({ title, children, amount }: CommissionProfileListProps) => (
    <TextItem hasBlueDot>
        <Translate langKey={title} />
        <Price>
            <CommissionProfileInfoCurrency currency={amount} />
        </Price>
        <Price>{children}</Price>
    </TextItem>
);
