import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';

import { ViewPageLoader } from '@shared-component/page-loader/page-loader';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { Breadcrumbs } from '@component/breadcrumbs/breadcrumbs';
import { Tabs } from '@component/tabs/tabs';
import { BreadCrumbsWrapper, HeaderWrapper, PageContainerWrapper, PageTitle } from '@page/common/common.styles';
import { breadcrumbList, clientTabsOptions } from '@page/private-client/private-client.options';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const PrivateClientPage = observer(() => {
    const { params } = useCurrentNavigation();
    const [, isLoading] = usePrivateClientSelector();

    return (
        <>
            <BreadCrumbsWrapper>
                <Breadcrumbs breadcrumbs={breadcrumbList} />
            </BreadCrumbsWrapper>
            <HeaderWrapper>
                <PageTitle>Client</PageTitle>
            </HeaderWrapper>
            <PageContainerWrapper>
                <Tabs params={params} options={clientTabsOptions}>
                    {isLoading ? <ViewPageLoader /> : <Outlet />}
                </Tabs>
            </PageContainerWrapper>
        </>
    );
});
