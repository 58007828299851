import { format } from 'date-fns';
import { useRef, useState, useEffect } from 'react';

import { OnEventEmptyType } from '@shared-type/on-event.type';

import { TimeView } from './timer.styles';

const SECOND = 1_000;

export interface TimerProps {
    time: number;
    onComplete: OnEventEmptyType;
    formatType?: string;
}

export const Timer = ({ time, onComplete, formatType = 'mm:ss' }: TimerProps) => {
    const [initialTime, setInitialTime] = useState(new Date().getTime());
    const [currentTime, setCurrentTime] = useState(initialTime);

    const ref = useRef<number>(0);
    const endTime = initialTime + time;
    const timeLeft = Math.round((endTime - currentTime) / SECOND) * SECOND;

    const handleSetTime = () => {
        const currentDateTime = new Date().getTime();
        if (currentDateTime > endTime) {
            clearInterval(ref.current);
            onComplete();
        }
        setCurrentTime(currentDateTime);
    };

    useEffect(() => {
        clearInterval(ref.current);
        ref.current = setInterval(handleSetTime, SECOND) as unknown as number;

        const updatedCurrentTime = new Date().getTime();
        setInitialTime(updatedCurrentTime);
        setCurrentTime(updatedCurrentTime);

        return () => clearInterval(ref.current);
    }, [time]);

    return <TimeView>{format(timeLeft, formatType)}</TimeView>;
};
