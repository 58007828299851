import { useCallback, useContext, useMemo } from 'react';

import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { useServicesByUserType } from '@shared-hook/services-by-user-type/services-by-user-type';

import { ClientServicesHeadingInterface } from '@page/common/client-services/client-services-heading/client-services-heading.interface';

export const useClientServicesHeading = ({
    userType,
    featureTags,
    adminGetClientFeatureTags,
}: ClientServicesHeadingInterface) => {
    const { onOpen } = useContext(ModelContext);

    const onAddServiceClick = useCallback(() => {
        onOpen(ModalEnum.AddServiceModal, { componentProps: { userType, adminGetClientFeatureTags } });
    }, []);

    const { allowedFeaturesByTypeMapping } = useServicesByUserType(userType);

    const shouldAddServiceDisabled = useMemo(() => {
        const flattenedFeatureTags = featureTags?.map(({ featureTag }) => featureTag);
        const featuresForAdding = allowedFeaturesByTypeMapping.filter(
            featureTagFromMapping => flattenedFeatureTags.indexOf(featureTagFromMapping) === -1
        );

        return featuresForAdding.length === 0;
    }, [featureTags, allowedFeaturesByTypeMapping]);

    return { onAddServiceClick, shouldAddServiceDisabled };
};
