import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const ModalWrapper = styled.div`
    padding: 24px;
    height: 100%;
    width: 400px;
    background-color: ${White};
`;
export const ModalTitle = styled.h2`
    ${Typography.XL.SemiBold.Neutral900.Style};
`;

export const FreeDesignModalTitle = styled(ModalTitle)`
    margin-bottom: 24px;
`;
export const FreeDesignModalButtonsWrapper = styled.div`
    ${Flex.Row.Style};
    gap: 20px;
    margin-top: 24px;
`;
