import { PrivateClientDocumentsActive } from '@page/private-client/private-client-documents/private-client-documents-active/private-client-documents-active';

import { BlockWrapper } from './private-client-documents.styles';

export const PrivateClientDocuments = () => (
    // const { name, params } = useCurrentNavigation<PageEnum>();
    // const { navigate } = useRouter();

    // const handleChangeType = (page: PageEnum) => () => navigate(page, params);

    <BlockWrapper>
        <PrivateClientDocumentsActive />
    </BlockWrapper>

    // {/* <BlockWrapper>*/}
    //     {/* <ButtonsWrapper> */}
    //     {/* <ButtonTransparent onClick={handleChangeType(PageEnum.PrivateClientDocuments)}> */}
    //     {/*    <ButtonActiveDocument>Active documents</ButtonActiveDocument> */}
    //     {/* </ButtonTransparent> */}
    //     {/* <ButtonTransparent onClick={handleChangeType(PageEnum.PrivateClientArchivedDocuments)}> */}
    //     {/*    <ButtonArchiveDocument>Archive documents</ButtonArchiveDocument> */}
    //     {/* </ButtonTransparent> */}
    //     {/* </ButtonsWrapper> */}
    //     {/* {name === PageEnum.PrivateClientArchivedDocuments && >} */}
    // {/*</BlockWrapper>*/}
);
