import { useField } from 'formik';
import { useEffect } from 'react';

import { CheckBox } from '@shared-component/checkbox/checkbox';
import { FormRow, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { RegistrationLegalPersonPrivateProfileFormEnum } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.enum';
import { useRegistrationLegalPersonPrivateProfileForm } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.hook';

import { useRegistrationLegalPersonPrivateProfileFormDirectorLocalization } from './registration-legal-person-private-profile-form-director.localization-hook';

export const RegistrationLegalPersonPrivateProfileFormDirector = () => {
    const [, , { setValue: setDirector }] = useField<boolean>(RegistrationLegalPersonPrivateProfileFormEnum.Director);
    const [{ value: isPowerOfAttorney }, , { setValue: setPowerOfAttorney }] = useField<boolean>(
        RegistrationLegalPersonPrivateProfileFormEnum.PowerOfAttorney
    );
    const isDirector = useRegistrationLegalPersonPrivateProfileForm(
        RegistrationLegalPersonPrivateProfileFormEnum.Director
    );

    const { directorTitle, powerOfAttorneyTitle } = useRegistrationLegalPersonPrivateProfileFormDirectorLocalization();

    useEffect(() => {
        if (isDirector && isPowerOfAttorney) {
            setPowerOfAttorney(false);
        }
    }, [isDirector]);

    useEffect(() => {
        if (isPowerOfAttorney && isDirector) {
            setDirector(false);
        }
    }, [isPowerOfAttorney]);

    useEffect(() => {
        setDirector(isDirector);
    }, [isDirector]);

    return (
        <FormWrapper>
            <CheckBox title={directorTitle} name={RegistrationLegalPersonPrivateProfileFormEnum.Director} />
            <FormRow>
                <CheckBox
                    title={powerOfAttorneyTitle}
                    name={RegistrationLegalPersonPrivateProfileFormEnum.PowerOfAttorney}
                />
            </FormRow>
        </FormWrapper>
    );
};
