import { useStore } from '@store/store-context';

export const usePrivateClientActionsSelector = () => {
    const {
        loadClient,
        changeClientStatus,
        updateClientStatus,
        setClientAccept,
        setClientDocuments,
        setClientHasDocuments,
        setClientAcceptAgreement,
        client: {
            loadPrivateClientProfile,
            loadPrivateClientService,
            loadPrivateClientAddress,
            loadPrivateClientComments,
            loadPrivateClientDeclaration,
            updatePrivateClientDocuments,
            addPrivateClientComments,
        },
    } = useStore().privateClient;

    return {
        loadClient,
        changeClientStatus,
        updateClientStatus,
        loadPrivateClientProfile,
        loadPrivateClientService,
        loadPrivateClientAddress,
        loadPrivateClientDeclaration,
        loadPrivateClientComments,
        updatePrivateClientDocuments,
        setClientAccept,
        setClientDocuments,
        setClientHasDocuments,
        setClientAcceptAgreement,
        addPrivateClientComments,
    };
};
