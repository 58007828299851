import { Form, Formik } from 'formik';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonSecondary } from '@shared-atom/button/button-secondary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { InputAmount } from '@shared-component/input/input-amount/input-amount';
import { AccountAdministration } from '@shared-graphql';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { CommissionListAdministrationProps } from '@page/commission-profile/commission-profile-info/commission-profile-info.options';
import {
    ButtonWrapper,
    CommissionItem,
    CommissionTitle,
    Description,
    List,
} from '@page/commission-profile/commission-profile-info/commission-profile-info.styles';

interface AdministrationFormikProps {
    accountAdministration: Omit<AccountAdministration, 'balanceFee' | 'userType'>;
    onSubmit: OnEventType<Omit<AccountAdministration, 'balanceFee' | 'userType'>>;
    onClose: OnEventEmptyType;
    accountAdministrationList: CommissionListAdministrationProps[];
}

export const CommissionProfileInfoAdministrationForm = ({
    accountAdministration,
    onSubmit,
    onClose,
    accountAdministrationList,
}: AdministrationFormikProps) => (
    <Formik
        initialValues={accountAdministration}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        enableReinitialize
    >
        <Form>
            <List>
                {accountAdministrationList.map(({ title, id }) => (
                    <CommissionItem key={title}>
                        <CommissionTitle hasCommission>
                            <Translate langKey={title} />
                        </CommissionTitle>
                        <Description>
                            <InputAmount name={id} title={id} />
                        </Description>
                    </CommissionItem>
                ))}
            </List>
            <ButtonWrapper>
                <ButtonPrimary type={ButtonTypeEnum.Submit}>Save</ButtonPrimary>
                <ButtonSecondary type={ButtonTypeEnum.Button} onClick={onClose}>
                    Cancel
                </ButtonSecondary>
            </ButtonWrapper>
        </Form>
    </Formik>
);
