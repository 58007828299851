import { ReactNode } from 'react';

import { AmountCell } from '@shared-component/transaction-cells/amount-cell/amount-cell';
import { BeneficiaryAccountCell } from '@shared-component/transaction-cells/beneficiary-account-cell/beneficiary-account-cell';
import { DateCell } from '@shared-component/transaction-cells/date-cell';
import { ElcoinStatusCell } from '@shared-component/transaction-cells/status-cell/status-cell';

import { ClientNameCell } from '@page/transactions/transactions-table/transaction-row/cells/client-name-cell/client-name-cell';
import { ActionsCell } from '@page/transactions/transactions-table/transaction-row/payment-row/cells/actions-cell/actions-cell';
import { IDCell } from '@page/transactions/transactions-table/transaction-row/payment-row/cells/id-cell/id-cell';
import { TypeCell } from '@page/transactions/transactions-table/transaction-row/payment-row/cells/type-cell';

export const paymentRowOptions: Array<ReactNode | any> = [
    IDCell,
    ClientNameCell,
    BeneficiaryAccountCell,
    TypeCell,
    ElcoinStatusCell,
    DateCell,
    AmountCell,
    ActionsCell,
];
