import styled, { css } from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { getAnimationStyle } from '@shared-style/animation';
import { White, LinkWater, DodgerBlue } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

interface ItemProps {
    isActive: boolean;
}

export const Wrapper = styled.div`
    height: 100%;
`;

export const Navigation = styled.nav`
    width: 100%;
    background-color: ${LinkWater};
`;

export const List = styled.ul`
    ${Flex.VerticalCenter.Style}
`;

const itemActive = css`
    color: ${DodgerBlue};
    background-color: ${White};
    border-bottom: 1px solid ${DodgerBlue};
`;

export const Item = styled.li<ItemProps>`
    padding: 0 10px;
    border-bottom: 1px solid transparent;
    ${Flex.HorizontalCenter.VerticalCenter.Style}
    ${getAnimationStyle(['background-color'])}
    
    ${({ isActive }) => isActive && itemActive}

    &:hover {
        ${itemActive}
    }
`;

export const Text = styled.p`
    height: 40px;

    ${Typography.Black.Style}
    ${Flex.HorizontalCenter.VerticalCenter.Style}
`;

export const ContentWrapper = styled.div`
    height: 100%;
    flex: 1;
`;
