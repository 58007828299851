import { useContext, useMemo, useRef, useState } from 'react';

import { ModelContext } from '@shared-component/modal/modal-context';
import { AddressValidation } from '@shared-form/address-form/address-form.validation';
import { PrivateProfileFormValidation } from '@shared-form/private-profile-form/private-profile-form.validation';
import { RegistrationLegalPersonPrivateProfileFormValidation } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.validation';
import {
    File as FileUploadType,
    Passport,
    Address,
    UserCompanyRelationType,
    UserDocument,
    UserDocumentType,
} from '@shared-graphql';
import {
    InitialUserProfileInterface,
    initialUserProfileModel,
} from '@shared-model/user-model/user-model-private/user-profile/user-profile.initial';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { DIGIT_TO_PERCENT } from '@shared-util/constants';
import { isExist } from '@shared-util/is-data';
import { isEqualObject } from '@shared-util/is-object';

import { initialUserDocument } from '@model/private-client-model/private-client-model-initials/private-client-documents-model.initial';
import { useCompanySelector } from '@selector/company/use-company.selector';

import {
    RegistrationLegalPersonPassportInterface,
    RelationPersonInterface,
} from './registration-legal-person-private.interface';
import { RegistrationLegalPrivatePersonModalEnum } from './registration-legal-person-private.modal-options';

const initialState = {};
const initialPersonalState: RelationPersonInterface = {
    companyRelation: [] as UserCompanyRelationType[],
} as RelationPersonInterface;

export const useRegistrationLegalPersonPrivate = (
    setView: OnEventType<RegistrationLegalPrivatePersonModalEnum, OnEventEmptyType>
) => {
    const {
        props: { componentProps },
    } = useContext(ModelContext);
    const { relation: companyRelation, id } = componentProps ?? {};
    const [{ persons }] = useCompanySelector();

    const { person: currentPerson, relation = companyRelation } = persons.get(id) ?? {};
    const [personal, setPersonal] = useState<RelationPersonInterface>(
        isExist(id)
            ? {
                email: currentPerson?.email,
                phone: currentPerson?.phone,
                ...relation,
                ownershipPercent: (relation?.ownershipPercent ?? 0) * DIGIT_TO_PERCENT,
                beneficiaryPercent: (relation?.beneficiaryPercent ?? 0) * DIGIT_TO_PERCENT,
            }
            : initialPersonalState
    );

    const [passport, setPassport] = useState<Pick<UserDocument, 'type' | 'file'>>(
        (currentPerson?.documents.find(doc => doc.type === UserDocumentType.Passport) as unknown as UserDocument) ??
            initialUserDocument
    );
    const [profile, setProfile] = useState(currentPerson?.profile ?? initialUserProfileModel);
    const [address, setAddress] = useState<Address>(currentPerson?.address ?? (initialState as Address));

    const closeModalRef = useRef(false);

    const hasPersonal = useMemo(
        () => RegistrationLegalPersonPrivateProfileFormValidation(false).isValidSync(personal) as boolean,
        [personal]
    );
    const hasPassport = hasPersonal && passport.type === UserDocumentType.Passport;
    const hasProfile = useMemo(
        () => hasPassport && PrivateProfileFormValidation.isValidSync(profile),
        [hasPassport, profile]
    );
    const hasAddress = useMemo(() => hasPassport && AddressValidation.isValidSync(address), [address, hasPassport]);
    const status = useMemo(
        () => ({
            hasPersonal,
            hasPassport,
            hasProfile,
            hasAddress,
        }),
        [hasAddress, hasPassport, hasPersonal, hasProfile]
    );

    const onSavePersonal = (newPersonal: RelationPersonInterface) => {
        closeModalRef.current = false;
        setPersonal({
            ...personal,
            ...newPersonal,
            ownershipPercent: parseFloat(`${newPersonal.ownershipPercent ?? 0}`),
            beneficiaryPercent: parseFloat(`${newPersonal.beneficiaryPercent ?? 0}`),
        });
        setView(RegistrationLegalPrivatePersonModalEnum.Passport)();
    };

    const onSavePassport = async (data: Passport) => {
        closeModalRef.current = false;
        if (isEqualObject(profile, initialUserProfileModel)) {
            setProfile({
                ...initialUserProfileModel,
                ...data,
            } as RegistrationLegalPersonPassportInterface);
        }
        setView(RegistrationLegalPrivatePersonModalEnum.PersonalDetails)();
    };

    const onSaveProfile = (data: InitialUserProfileInterface) => {
        closeModalRef.current = false;
        setProfile(data);
        setView(RegistrationLegalPrivatePersonModalEnum.Address)();
    };

    const onSaveAddress = (data: Address) => {
        closeModalRef.current = true;
        setAddress({ ...data });
    };

    const onUploaded = (file: FileUploadType) => setPassport({ type: UserDocumentType.Passport, file });

    const onDeleteUpload = () => setPassport(initialUserDocument);

    return {
        status,
        personal,
        passport,
        profile,
        address,
        onUploaded,
        onSavePersonal,
        onSavePassport,
        onSaveProfile,
        onSaveAddress,
        onDeleteUpload,
    };
};
