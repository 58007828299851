import { rgba } from 'polished';
import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { LinkWater, SelectiveYellow } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const CountryItemWrapper = styled.div`
    height: 56px;
    width: 100%;
    ${Flex.Row.JustifyLeft.VerticalCenter.Style}

    :hover {
        background-color: ${LinkWater};
    }

    :active {
        background-color: ${rgba(SelectiveYellow, 0.1)};
    }
`;

export const CountryTitle = styled.span`
    margin-left: 56px;
    ${Typography.S.Normal.Dark.Style};
`;
