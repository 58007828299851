import { Instance } from 'mobx-state-tree';

import { UserActivatedStatus } from '@shared-graphql';

import { PrivateClientModelType } from '@model/private-client-model/private-client-model';

import { privateClientStatusesActions } from './private-client-statuses.actions';

export const privateClientStatusUpdateActions = (self: Instance<typeof PrivateClientModelType>) => ({
    updateClientStatus: (status: UserActivatedStatus) => {
        try {
            if (self.client.status === status) {
                return;
            }

            (self as unknown as ReturnType<typeof privateClientStatusesActions>).changeClientStatus(
                self.client.id,
                status
            );
        } catch (error) {
            throw new Error(error as string);
        }
    },
});
