import { flow, Instance } from 'mobx-state-tree';

import { AdminGetUsers, Query, UserAnswer, UsersFilter } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { UsersModelType } from '@model/users-model/user-model';
import {
    initialUserLegalStatus,
    initialUserPrivateStatus,
    initialUsersResponse,
} from '@model/users-model/users-model-initials/user-model.initial';
import { initialUserProfileModel } from '@model/users-model/users-model-initials/user-profile-model.initial';

const queryUsers = (filters?: Partial<UsersFilter>) =>
    apolloClient
        .query<Pick<Query, 'adminGetUsers'>>({ query: AdminGetUsers, variables: { filters } })
        .then(result => result.data.adminGetUsers ?? initialUsersResponse);

export const userMainActions = (self: Instance<typeof UsersModelType>) => ({
    setLoading: (loading: boolean) => {
        self.loading.isLoading = loading;
    },
    loadUsers: flow(function* loadUsers(filters?: Partial<UsersFilter>) {
        if (self.loading.isLoading) {
            return;
        }

        (self as any).setLoading(true);

        try {
            const { elements, totalCount }: UserAnswer = yield queryUsers(filters);
            self.users.elements.clear();
            elements.forEach(user => {
                self.users.elements.set(user.id, {
                    ...user,
                    profile: {
                        ...initialUserProfileModel,
                        ...user.profile,
                    },
                    privateStatus: {
                        ...initialUserPrivateStatus,
                        ...user.privateStatus,
                    },
                    legalStatus: {
                        ...initialUserLegalStatus,
                        ...user.legalStatus,
                    },
                } as any);
            });
            self.users.totalCount = totalCount;
        } catch (error: any) {
            throw new Error(error);
        } finally {
            (self as any).setLoading(false);
        }
    }),
});
