import { flow, applySnapshot, getParent, Instance, getSnapshot } from 'mobx-state-tree';

import { Query, User, Address, AdminGetPrivateClientAddress } from '@shared-graphql';
import { isExist } from '@shared-util/is-data';

import { apolloClient } from '@connection/apollo-client';
import { PrivateClientModel } from '@model/private-client-model/private-client-model';
import { PrivateClientTypes } from '@model/private-client-model/private-client-model.type';

const queryPrivateClientAddress = (userId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetPrivateClient'>>({ query: AdminGetPrivateClientAddress, variables: { userId } })
        .then(result => (result.data.adminGetPrivateClient as User).address);

export const privateClientAddressActions = (self: Instance<typeof PrivateClientTypes>) => ({
    loadPrivateClientAddress: flow(function* loadPrivateClientAddress(userId: string) {
        const clientModel = getParent(self) as Instance<typeof PrivateClientModel>;

        if (clientModel.loading.isLoading) {
            return;
        }

        clientModel.setLoading(true);

        try {
            const address: Address = yield queryPrivateClientAddress(userId);

            const currentSnapshot = getSnapshot(self);

            applySnapshot(self, {
                ...currentSnapshot,
                address: isExist(address) ? address : null,
            });
        } catch (error) {
            throw new Error(error as string);
        }

        clientModel.setLoading(false);
    }),
});
