import { RegistrationMenuListInterface } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.interface';

import AddressIcon from '@page/legal-client-profile/assets/address.svg?react';
import ProfileIcon from '@page/legal-client-profile/assets/profile.svg?react';

import { RegistrationLegalPersonPrivateInterface } from './registration-legal-person-private.interface';

export enum RegistrationLegalPrivatePersonModalEnum {
    PersonalDetails = 'personalDetails',
    Passport = 'passport',
    Role = 'role',
    Address = 'address',
}

export const RegistrationLegalPrivatePersonModalNavigationOptions: RegistrationMenuListInterface<RegistrationLegalPrivatePersonModalEnum>[] =
    [
        {
            title: 'Role',
            icon: ProfileIcon,
            chooseKey: RegistrationLegalPrivatePersonModalEnum.Role,
            checkDone: (status: RegistrationLegalPersonPrivateInterface) => status.hasPersonal,
            checkEnable: () => true,
        },
        {
            title: 'Passport',
            icon: AddressIcon,
            chooseKey: RegistrationLegalPrivatePersonModalEnum.Passport,
            checkDone: (status: RegistrationLegalPersonPrivateInterface) => status.hasPassport,
            checkEnable: (status: RegistrationLegalPersonPrivateInterface) => status.hasPersonal,
        },
        {
            title: 'Personal details',
            icon: ProfileIcon,
            chooseKey: RegistrationLegalPrivatePersonModalEnum.PersonalDetails,
            checkDone: (status: RegistrationLegalPersonPrivateInterface) => status.hasProfile,
            checkEnable: (status: RegistrationLegalPersonPrivateInterface) => status.hasPassport,
        },
        {
            title: 'Address',
            icon: AddressIcon,
            chooseKey: RegistrationLegalPrivatePersonModalEnum.Address,
            checkDone: (status: RegistrationLegalPersonPrivateInterface) => status.hasAddress,
            checkEnable: (status: RegistrationLegalPersonPrivateInterface) => status.hasPassport,
        },
    ];
