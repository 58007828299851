import { Instance } from 'mobx-state-tree';
import { Column } from 'react-table';

import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';

export const ClientsTableColumns: Column<Partial<Instance<typeof CurrencyModelType>>>[] = [
    {
        Header: 'Сurrency name',
        accessor: 'currency',
    },
    {
        Header: 'Emitent',
        accessor: 'code',
    },
    {
        Header: 'Digital code',
        accessor: 'number',
    },
    {
        Header: 'Status',
        id: 'isActive',
    },
    {
        Header: 'Actions',
        id: 'actions',
    },
];
