import { CURRENCY_INITIALS } from '@shared-component/cards/cards.options';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';

interface ClientSummaryApplicationAnnualTurnoverProps {
    moneyTurnover?: number;
    currency?: string;
}

export const ApplicationAnnualTurnover = ({ moneyTurnover, currency }: ClientSummaryApplicationAnnualTurnoverProps) => {
    const formatedMoneyTurnover = useCurrencyFormat(moneyTurnover ?? 0, currency ?? CURRENCY_INITIALS.code);

    return (
        <>
            {Boolean(formatedMoneyTurnover) === true && formatedMoneyTurnover}
            {Boolean(formatedMoneyTurnover) === false && null}
        </>
    );
};
