import { useState } from 'react';

import { CompanyAddressEnum } from '@shared-form/address-form/address-form.enum';
import {
    PostalAddressCompanyFormValidation,
    LegalAddressCompanyFormValidation,
} from '@shared-form/address-form/address-form.validation';
import { CompanyAddressInput } from '@shared-graphql';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { noop } from '@shared-util/noop';

export const useRegistrationLegalAddress = <T extends CompanyAddressInput>(
    onSubmit: OnEventType<T>,
    handleBack: OnEventEmptyType = noop
) => {
    const [currentAddress, setCurrentAddress] = useState(CompanyAddressEnum.Legal);

    const isSelectedPostal = currentAddress === CompanyAddressEnum.Postal;

    const validationRules = isSelectedPostal ? PostalAddressCompanyFormValidation : LegalAddressCompanyFormValidation;

    const handleSubmit = async ({ isCompanyAddressSame, legalAddress, postalAddress }: T) => {
        if (!(Boolean(isCompanyAddressSame) === true || isSelectedPostal)) {
            return setCurrentAddress(CompanyAddressEnum.Postal);
        }

        onSubmit({ isCompanyAddressSame, legalAddress, postalAddress } as T);
    };

    const onBack = isSelectedPostal ? () => setCurrentAddress(CompanyAddressEnum.Legal) : handleBack;

    return { currentAddress, isSelectedPostal, validationRules, handleSubmit, onBack };
};
