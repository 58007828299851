import { Translate } from '@shared-atom/translate/translate';
import { CurrenciesEnum } from '@shared-enum/currencies.enum';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

interface CommissionProfileCurrencyProps {
    currency: number;
}

export const CommissionProfileInfoCurrency = ({ currency }: CommissionProfileCurrencyProps) => {
    const amount = useCurrencyFormat(currency, CurrenciesEnum.EUR);
    if (currency === 0) {
        return <Translate langKey={LocalizationEnum.PaymentFormAmount} />;
    }
    return (
        <>
            {Boolean(amount) === true && amount}
            {Boolean(amount) === false && null}
        </>
    );
};
