import { useEffect } from 'react';

import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';

import { PageEnum } from '@enum/page.enum';
import { FilterByOperationInterface } from '@page/transactions/transactions-table/transactions-table-filter/filter-by-operation-type/filter-by-operation-type.interface';
import { OperationTypeEnum } from '@page/transactions/transactions-table/transactions-table.enum';

export const useFilterByOperationType = ({
    operationTypeFilter,
    onOperationTypeChange,
}: FilterByOperationInterface) => {
    const navigate = useRouterNavigate();
    const { searchParams: params } = useCurrentNavigation();

    const { operationType } = params;

    useEffect(
        () =>
            void (
                operationTypeFilter !== undefined &&
                navigate(PageEnum.Transactions, {
                    ...params,
                    operationType: operationTypeFilter === null ? OperationTypeEnum.Inward : operationTypeFilter,
                })
            ),
        [operationTypeFilter]
    );

    useEffect(
        () =>
            void onOperationTypeChange(
                operationType !== undefined && operationType !== OperationTypeEnum.Inward
                    ? operationType
                    : OperationTypeEnum.Inward
            ),
        [operationType]
    );
};
