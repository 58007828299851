import { FC } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { CheckBox } from '@shared-component/checkbox/checkbox';
import { AddressForm } from '@shared-form/address-form/address-form';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { LegalAddressFormEnum } from './legal-address-form.enum';
import { CheckboxTitle } from './legal-address-form.styles';

interface LegalAddressFormProps {
    isSelectedPostal: boolean;
    customPathName?: string;
}

export const LegalAddressForm: FC<LegalAddressFormProps> = ({ customPathName, isSelectedPostal }) => (
    <>
        <AddressForm customPathName={customPathName} />
        {!isSelectedPostal && (
            <FormRow>
                <CheckBox name={LegalAddressFormEnum.AddressSame}>
                    <CheckboxTitle>
                        <Translate langKey={LocalizationEnum.RegistrationLegalAddressCheckInputTitle} />
                    </CheckboxTitle>
                </CheckBox>
            </FormRow>
        )}
    </>
);
