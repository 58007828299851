import { OperationType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { TypeOptionsInterface } from './statement-form.interface';

export const typeOptions: TypeOptionsInterface[] = [
    {
        key: '',
        title: LocalizationEnum.StatementFormTypeAll,
    },
    {
        key: OperationType.Debit,
        title: LocalizationEnum.StatementFormTypeDebit,
    },
    {
        key: OperationType.Credit,
        title: LocalizationEnum.StatementFormTypeCredit,
    },
];
