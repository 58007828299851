import styled from 'styled-components';

import { ButtonTransparentStyle } from '@shared-atom/button/button.style';
import { Typography } from '@shared-atom/typography/typography';
import RefreshIcon from '@shared-component/input/assets/refresh-small.svg?react';
import { Flex } from '@shared-style/flex';

export const StateControlButtonsWrapper = styled.div`
    ${Flex.VerticalCenter.Style};
    gap: 16px;
    ${ButtonTransparentStyle} {
        padding: 10px 12px;
    }
`;
export const StateControlButtonsTitle = styled.div`
    ${Typography.Paragraph14.SemiBold.PrimeBlue.Style};
    white-space: nowrap;
`;

export const ClientsTableRefreshIcon = styled(RefreshIcon)`
    margin-right: 4px;
`;
