import { CompanyActivatedStatus } from '@shared-graphql';

import { VerificationRejectionTitleEnum } from '@page/common/client-summary/client-summary.enum';

export const getVerificationRejectionTitle = (status: CompanyActivatedStatus) => {
    switch (status) {
        case CompanyActivatedStatus.Approved:
            return VerificationRejectionTitleEnum.Verification;
        case CompanyActivatedStatus.Blocked:
            return VerificationRejectionTitleEnum.Rejection;
        default:
            return null;
    }
};
