import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { Translate } from '@shared-atom/translate/translate';
import { SelectedItemWrapper } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form-user-create/registration-legal-person-private-role-select-item.styles';
import { UserRolesType } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { isExist } from '@shared-util/is-data';

import { TooltipWrapper } from '../../private-self-declaration-form/private-self-declaration-form.styles';

export interface PersonRoleSelectItemInterface {
    value: UserRolesType;
    label: UserRolesType;
}
enum UserRoleDescriptionEnum {
    RoleA = 'registration.legal.person.description.role.a',
    RoleB = 'registration.legal.person.description.role.b',
    RoleC = 'registration.legal.person.description.role.c',
}

export const LegalPersonRoleSelectItem = ({ value, label }: PersonRoleSelectItemInterface) => {
    const tooltipText = useLocalizationText(UserRoleDescriptionEnum[value]);

    return (
        <>
            <SelectedItemWrapper>{isExist(label) && <Translate langKey={label} />}</SelectedItemWrapper>
            <TooltipWrapper>
                <Tooltip dataFor={value} content={tooltipText} effect="solid" place="left" />
            </TooltipWrapper>
        </>
    );
};
