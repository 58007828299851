import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { ExternalApplicantLink, GetExternalApplicantLink, Query } from '@shared-graphql';
import { isExist, isTrue } from '@shared-util/is-data';

export const useUserExternal = (clientId: string): [string] => {
    const [externalApplicantLink, setExternalApplicantLink] = useState('');

    const [getExternalApplicantLink, { data }] =
        useLazyQuery<Pick<Query, 'adminGetExternalApplicantLink'>>(GetExternalApplicantLink);

    useEffect(() => {
        if (isTrue(clientId)) {
            getExternalApplicantLink({ variables: { clientId } });
        }
    }, [clientId]);

    useEffect(() => {
        if (isExist((data?.adminGetExternalApplicantLink as ExternalApplicantLink)?.href)) {
            setExternalApplicantLink((data?.adminGetExternalApplicantLink as ExternalApplicantLink)?.href ?? '');
        }
    }, [data]);

    return [externalApplicantLink];
};
