import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { RefObject, useMemo, useRef, useState } from 'react';

import { DayPickerRange } from '@shared-component/day-picker/day-picker-range/day-picker-range';
import { DateRangeFilterProps } from '@shared-component/table/table-filters/date-range-filter/date-range-filter.props';
import {
    ChevronDownIcon,
    DateRangePreview,
    DateRangePreviewData,
    DateRangePreviewTitle,
    DateRangeWrapper,
    Wrapper,
} from '@shared-component/table/table-filters/date-range-filter/date-range-filter.styles';
import { useOutsideClick } from '@shared-hook/utils/use-outside-click';
import { isFalse, isTrue } from '@shared-util/is-data';

const DEFAULT_DATE_RANGE_FILTER_VALUE = 'All time';

export const DateRangeFilter = observer(({ nameFrom, nameTo, title, ...dateRangeProps }: DateRangeFilterProps) => {
    const [{ value: fieldFromValue }] = useField<Date>(nameFrom);
    const [{ value: fieldToValue }] = useField<Date>(nameTo);
    const [isDateRangeVisible, setDateRangeVisible] = useState(false);
    const dateRangeWrapperRef = useRef() as RefObject<HTMLDivElement>;

    useOutsideClick(dateRangeWrapperRef, () => setDateRangeVisible(false));

    const filterValue = useMemo(() => {
        const dateRangePreview = DEFAULT_DATE_RANGE_FILTER_VALUE;
        const fromDate = isTrue(fieldFromValue) ? new Date(fieldFromValue).toLocaleDateString() : null;
        const toDate = isTrue(fieldToValue) ? new Date(fieldToValue).toLocaleDateString() : null;
        if (isFalse(fromDate) && isFalse(toDate)) {
            return dateRangePreview;
        }
        if (isTrue(fromDate) && isFalse(toDate)) {
            return `From ${fromDate}`;
        }
        if (isFalse(fromDate) && isTrue(toDate)) {
            return `Up to ${toDate}`;
        }

        return `${fromDate} - ${toDate}`;
    }, [fieldFromValue, fieldToValue]);

    return (
        <Wrapper ref={dateRangeWrapperRef}>
            <DateRangePreview onClick={() => setDateRangeVisible(true)}>
                <DateRangePreviewTitle>{title}:</DateRangePreviewTitle>
                <DateRangePreviewData isHighlighted={filterValue !== DEFAULT_DATE_RANGE_FILTER_VALUE}>
                    {filterValue}
                </DateRangePreviewData>
                <ChevronDownIcon isVisible={isDateRangeVisible} />
            </DateRangePreview>
            <DateRangeWrapper isVisible={isDateRangeVisible}>
                <DayPickerRange nameFrom={nameFrom} nameTo={nameTo} {...dateRangeProps} />
            </DateRangeWrapper>
        </Wrapper>
    );
});
