import { flow, Instance } from 'mobx-state-tree';

import { GetCurrentUser, Query } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { AdminModelInstance } from '@model/admin-model/admin-model';

const queryAdmin = () =>
    apolloClient
        .query<Pick<Query, 'getCurrentUser'>>({
        query: GetCurrentUser,
    })
        .then(result => result.data.getCurrentUser);

export const adminActions = (self: Instance<typeof AdminModelInstance>) => ({
    loadAdmin: flow(function* loadAdmin() {
        try {
            self.admin = yield queryAdmin();
        } catch (error) {
            throw new Error(error as string);
        }
    }),
});
