import { UserDocumentType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PrivateDocumentFormChoosesInterface } from './private-document-form.interface';
import { LocationIcon, OtherIcon, PassportIcon, SelfieIcon } from './private-document-form.styles';

export const privateDocumentFormChooses: PrivateDocumentFormChoosesInterface[] = [
    {
        type: UserDocumentType.Passport,
        title: LocalizationEnum.DownloadPassportButtonTitle,
        icon: PassportIcon,
    },
    {
        type: UserDocumentType.Location,
        title: LocalizationEnum.DownloadLocationButtonTitle,
        icon: LocationIcon,
    },
    {
        type: UserDocumentType.Selfie,
        title: LocalizationEnum.DownloadSelfieButtonTitle,
        icon: SelfieIcon,
    },
];

export const otherTypeDocument = {
    type: UserDocumentType.Other,
    title: LocalizationEnum.DownloadOtherDocumentButtonTitle,
    icon: OtherIcon,
};
