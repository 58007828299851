import { rgba } from 'polished';
import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Dark, DarkBlue, Polar, PrimeBlue, White, WhiteGrey } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

interface StatementRadioButtonProps extends ThemeTypeInterface {
    isChecked: boolean;
}

export const StatementFormComponentWrapper = styled.div`
    margin-top: 32px;
`;

export const StatementTypeWrapper = styled.div`
    padding-top: 16px;
    ${Flex.Row.Style}
`;

export const StatementTypeStyle = styled.div<StatementRadioButtonProps>`
    margin-right: 16px;
    padding: 16px;
    border-radius: 18px;
    color: ${({ isChecked, theme: { isDarkTheme } }: StatementRadioButtonProps) => isDarkTheme && isChecked && White};
    background-color: ${({ isChecked }) => (isChecked ? rgba(PrimeBlue, 0.2) : WhiteGrey)};

    &:hover {
        background-color: ${({ isChecked }) => !isChecked && Polar};
    }
`;

export const StatementFormTitle = styled.h2`
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : DarkBlue)};
    ${Typography.XXL.Style}
`;

export const StatementFormHead = styled.p`
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    ${Typography.L.Medium.Style}
`;
