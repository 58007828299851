import { Instance } from 'mobx-state-tree';
import React, { FC } from 'react';

import { Choose } from '@shared-atom/choose/choose';
import { Translate } from '@shared-atom/translate/translate';
import Legal from '@shared-component/registration/assets/legal.svg?react';
import { PersonsWrapper } from '@shared-component/registration/common.styles';
import { UserType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { CompanyPersonRelationType } from '@shared-model/company-model/company-persons-model/company-persons-model.type';
import { OnEventEmptyType, OnEventsType, OnEventType } from '@shared-type/on-event.type';

import { RegistrationLegalShareholdersListItem } from './registration-legal-shareholders-list-item/registration-legal-shareholders-list-item';

interface RegistrationLegalShareholdersListInterface {
    relationPersons: Instance<typeof CompanyPersonRelationType>[];
    onDeletePerson: OnEventType<string, OnEventEmptyType>;
    onChooseType: OnEventsType<[UserType, string | undefined]>;
    children?: React.ReactNode;
}

export const RegistrationShareholdersList: FC<RegistrationLegalShareholdersListInterface> = ({
    relationPersons,
    onChooseType,
    onDeletePerson,
    children,
}) => {
    const handleChoosePerson = (type: UserType, id?: string) => () => onChooseType(type, id);

    return (
        <>
            <PersonsWrapper>
                <Choose icon={Legal} onClick={handleChoosePerson(UserType.Legal)}>
                    <Translate langKey={LocalizationEnum.RegistrationLegalPersonsDirectShareholdersLegal} />
                </Choose>
                {relationPersons.map(person => (
                    <RegistrationLegalShareholdersListItem
                        key={person.id}
                        person={person}
                        onClick={handleChoosePerson(person.type, person.id)}
                        onDelete={onDeletePerson(person.id)}
                    />
                ))}
            </PersonsWrapper>

            {children}
        </>
    );
};
