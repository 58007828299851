import styled from 'styled-components';

import { MaskedInputStyle } from '@shared-component/input/input.styles';
import { Dark, DarkBlue, Mystic, PrimeBlue, Red, White } from '@shared-style/colors';
import { resetInputStyle } from '@shared-style/reset-style';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

interface InputSubstrateProps {
    index: number;
    isActive: boolean;
    hasError: boolean;
}

export const OtpInputStyle = styled(MaskedInputStyle)`
    padding: 0;
    font-variant-numeric: tabular-nums;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
    background-color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? DarkBlue : White)};
    ${resetInputStyle}
`;

export const InputCodeWrapper = styled.div`
    max-width: 257px;
    width: 100%;
    position: relative;
    display: inline-block;
`;

export const InputSubstrate = styled.div`
    position: absolute;
    height: 2px;
    width: 32px;
    left: ${({ index }: InputSubstrateProps) => index * (32 + 13)}px;
    bottom: -4px;
    background-color: ${({ isActive, hasError }: InputSubstrateProps) => {
        const standardColor = isActive ? PrimeBlue : Mystic;
        return hasError ? Red : standardColor;
    }};
`;
