import { useMemo } from 'react';

import { BaseOptionInterface } from '@shared-component/select/select.props';
import { UserServiceInput } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

import { useCurrenciesSelector } from '@selector/currencies/use-currencies.selector';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const usePrivateClientProfileServices = (): [
    UserServiceInput,
    OnEventType<UserServiceInput>,
    BaseOptionInterface[]
] => {
    const { params } = useCurrentNavigation();
    const [{ service }] = usePrivateClientSelector();
    const [{ currenciesActiveSelect }] = useCurrenciesSelector();

    const onUpdateServices = (newService: UserServiceInput) => console.log(params.userId, newService);

    const initialValues = useMemo(
        () => (isExist(service) ? { ...service, currency: service?.currency.code } : {}) as UserServiceInput,
        [service]
    );

    return [initialValues, onUpdateServices, currenciesActiveSelect];
};
