import styled from 'styled-components';

import { EerieBlack } from '@shared-style/colors';

interface INavigationWrapperProps {
    isAdmin: boolean;
}

export const NavigationWrapper = styled.nav<INavigationWrapperProps>`
    width: 100%;
    min-height: 200px;
    margin-top: ${({ isAdmin }) => (isAdmin ? '56px' : '0')};
`;

export const NavigationList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
    padding-bottom: 16px;
    border-bottom: 1px solid ${EerieBlack};
    :not(:first-child) {
        padding-top: 16px;
    }
    :last-child {
        border-bottom: none;
    }
`;
