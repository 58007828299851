import { useMutation } from '@apollo/client';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { CreateClientFormInterface } from '@shared-form/create-client-form/create-client-form.interface';
import { AdminClientRegistration, Maybe, Mutation, UserRegistration, UserType } from '@shared-graphql';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist, isString } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';

type MutationClientRegistrationType = Pick<Mutation, 'adminClientRegistration'>;

const USER_ALREADY_EXIST = 'User already exist';

export const useCreateClient = (): [string | undefined, OnEventType<CreateClientFormInterface>] => {
    const navigate = useRouterNavigate();
    const [createClient, { data, error: createClientError }] =
        useMutation<MutationClientRegistrationType>(AdminClientRegistration);

    const clientType = useRef<Maybe<UserType>>(null);
    const newClientId = (data?.adminClientRegistration as UserRegistration)?.id;

    const handleSubmit = useCallback(({ userId, ...fields }: CreateClientFormInterface): any | void => {
        if (isString(userId)) {
            return navigate(PageEnum.LegalClientCreate, { userId });
        }

        clientType.current = fields.type;
        createClient({ variables: fields });
    }, []);

    const error = useMemo(
        () => (isExist(createClientError?.message) ? USER_ALREADY_EXIST : undefined),
        [createClientError]
    );

    const handleNavigate = (isPrivateType: boolean) =>
        navigate(isPrivateType ? PageEnum.PrivateClientProfilePersonal : PageEnum.LegalClientCreate, {
            userId: newClientId,
        });

    useEffect(
        () => void (isString(newClientId) && handleNavigate(clientType.current === UserType.Private)),
        [newClientId]
    );

    return [error, handleSubmit];
};
