import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { PrivateProfileForm } from '@shared-form/private-profile-form/private-profile-form';
import { UserProfileInput } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { initialUserProfileModel } from '@shared-model/user-model/user-model-private/user-profile/user-profile.initial';

import { ButtonWrapper } from '@page/private-client-profile/private-client-profile.styles';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const PrivateClientProfilePersonal = observer(() => {
    const { params } = useCurrentNavigation();
    const [{ profile }] = usePrivateClientSelector();

    const handleUpdateProfile = (newProfile: UserProfileInput) => console.log(params.userId, newProfile);

    return (
        <Formik
            onSubmit={handleUpdateProfile}
            initialValues={(profile ?? initialUserProfileModel) as UserProfileInput}
            enableReinitialize
        >
            <Form>
                <PrivateProfileForm />
                <ButtonWrapper>
                    <ButtonPrimary type={ButtonTypeEnum.Submit}>Update profile</ButtonPrimary>
                </ButtonWrapper>
            </Form>
        </Formik>
    );
});
