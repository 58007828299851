import { Instance } from 'mobx-state-tree';

import { CommentsModelType } from '@shared-model/comment-model/comments-model';
import { OnEventType } from '@shared-type/on-event.type';

import { usePrivateClientActionsSelector } from '@selector/private-client/use-private-client.actions-selector';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const useAddPrivateClientComments = (): [Instance<typeof CommentsModelType>[], OnEventType<string>] => {
    const { addPrivateClientComments } = usePrivateClientActionsSelector();
    const [{ id, comments }] = usePrivateClientSelector();

    const handleAddComment = (comment: string) => addPrivateClientComments(id, comment);

    return [comments, handleAddComment];
};
