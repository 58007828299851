import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserActivatedStatus } from '@shared-graphql';

const userActivatedStatusValues = $enum(UserActivatedStatus).getValues();

export const PrivateClientStatusesType = types.model({
    status: types.enumeration(userActivatedStatusValues),
    date: types.Date,
});
