import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { CopyMessage } from '@shared-atom/copy-message/copy-message';
import { useCopyMessage } from '@shared-atom/copy-message/copy-message.hook';
import { CurrencyIcon } from '@shared-atom/currency-icon/currency-icon';
import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { AccountBank } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';
import { useCopyAccountDetailsText } from '@shared-hook/utils/use-copy-account-details-text';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { noop } from '@shared-util/noop';

import { AccountCardProps } from './account-card.props';
import {
    AccountAmountSimpleView,
    AccountCardSimpleView,
    AccountCardWrapper,
    AccountCardView,
    AccountInfo,
    AccountCurrencyName,
    AccountSchema,
    BadgeBlocked,
    RightContentWrapper,
    CopyIcon,
} from './account-card.styles';
import BlockedIcon from './assets/blocked.svg?react';

export const AccountCard: FC<AccountCardProps> = observer(
    ({ account, isBlocked = false, isSelected = false, hasCopy = false, onClick = noop /* clientId, */ }) => {
        const copyTooltipTitle = useLocalizationText(LocalizationEnum.AccountCardCopyTooltip);

        const { currency, code } = account.currency;
        const balance = useCurrencyFormat(account.balance, code);

        const { banks } = account;
        const copyText = useCopyAccountDetailsText(banks[0] as AccountBank);

        const [isCopyMessageVisible, onCopy] = useCopyMessage();

        return (
            <AccountCardWrapper onClick={onClick}>
                <CopyMessage isVisible={isCopyMessageVisible}>
                    <AccountCardView isBlocked={isBlocked} isSelected={isSelected}>
                        <AccountInfo>
                            <AccountSchema>
                                <CurrencyIcon code={code} />
                            </AccountSchema>
                            <AccountCardSimpleView>
                                <AccountAmountSimpleView>{balance}</AccountAmountSimpleView>
                                <AccountCurrencyName>{currency}</AccountCurrencyName>
                            </AccountCardSimpleView>
                            {isBlocked && (
                                <BadgeBlocked>
                                    <BlockedIcon />
                                </BadgeBlocked>
                            )}
                            {hasCopy && (
                                <RightContentWrapper>
                                    <Tooltip content={copyTooltipTitle} dataFor={`tooltip-copy-for-${code}`}>
                                        <CopyToClipboard text={copyText} onCopy={onCopy}>
                                            <CopyIcon />
                                        </CopyToClipboard>
                                    </Tooltip>
                                </RightContentWrapper>
                            )}
                        </AccountInfo>
                    </AccountCardView>
                </CopyMessage>
            </AccountCardWrapper>
        );
    }
);
