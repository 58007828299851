import { useField } from 'formik';

import { CommissionLimits } from '@shared-graphql';

import { initialNewCommission } from '@page/commission-profile/commission-profile-initials/commission-profile.initials';
import { CommissionProfileEnum } from '@page/commission-profile/commission-profile.enum';

import { CommissionProfileFormListItem } from './commission-profile-form-list-item/commission-profile-form-list-item';

export const CommissionProfileFormList = () => {
    const [{ value }, , { setValue }] = useField(CommissionProfileEnum.Commissions);

    const prevValue = value[value.length - 1].maxAmount ?? 0;

    const handleAdd = () => setValue([...value, { minAmount: prevValue, ...initialNewCommission }]);

    const handleRemove = (index: number) =>
        setValue(value.filter((_: CommissionLimits, indexValue: number) => indexValue !== index));

    return <CommissionProfileFormListItem commissions={value} onAdd={handleAdd} onRemove={handleRemove} />;
};
