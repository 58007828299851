import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';

import { PaymentEditForm } from './payment-edit.form';
import { usePaymentEdit } from './payment-edit.hook';

export const PaymentEdit = observer(() => {
    const [payment, onSave] = usePaymentEdit();

    return (
        <Formik initialValues={payment} onSubmit={onSave} enableReinitialize>
            <Form>
                <PaymentEditForm />
                <ButtonPrimary type={ButtonTypeEnum.Submit}>Save</ButtonPrimary>
            </Form>
        </Formik>
    );
});
