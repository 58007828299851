export const PuceGradient = `
    linear-gradient(180deg, #e0abbc 0%, #cc8ba0 100%), linear-gradient(180deg, #e0abce 0%, #cc8bb6 100%),
    linear-gradient(180deg, #e0abe0 0%, #cc8bcc 100%), linear-gradient(180deg, #ceabe0 0%, #b68bcc 100%),
    linear-gradient(180deg, #bcabe0 0%, #a08bcc 100%), linear-gradient(180deg, #ababe0 0%, #8b8bcc 100%),
    linear-gradient(180deg, #abbce0 0%, #8ba0cc 100%), linear-gradient(180deg, #abcee0 0%, #8bb6cc 100%),
    linear-gradient(180deg, #abe0e0 0%, #8bcccc 100%), linear-gradient(180deg, #abe0ce 0%, #8bccb6 100%),
    linear-gradient(180deg, #abe0bc 0%, #8bcca0 100%), linear-gradient(180deg, #abe0ab 0%, #8bcc8b 100%),
    linear-gradient(180deg, #bce0ab 0%, #a0cc8b 100%), linear-gradient(180deg, #cee0ab 0%, #b6cc8b 100%),
    linear-gradient(180deg, #e0e0ab 0%, #cccc8b 100%), linear-gradient(180deg, #e0ceab 0%, #ccb68b 100%),
    linear-gradient(180deg, #c3d3e3 0%, #aec1d4 100%), linear-gradient(169.46deg, #19bbc8 -16.56%, #004b82 108.32%),
    linear-gradient(180deg, #e0bcab 0%, #cca08b 100%), linear-gradient(180deg, #e0abab 0%, #cc8b8b 100%), #0f4c81;
`;
