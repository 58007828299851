export const typographyWeight = {
    Normal: {
        fontWeight: 400,
    },
    Medium: {
        fontWeight: 500,
    },
    SemiBold: {
        fontWeight: 600,
    },
    Bold: {
        fontWeight: 700,
    },
};
