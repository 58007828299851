export const typographySizes = {
    StepBackgroundTitle: {
        fontSize: '36rem',
        lineHeight: '36rem',
    },
    MobileStepBackgroundTitle: {
        fontSize: '30rem',
        lineHeight: '30rem',
    },
    FirstScreenTitle: {
        fontSize: '13rem',
        lineHeight: '13rem',
    },
    StepButtonNumber: {
        fontSize: '9rem',
        lineHeight: '9rem',
    },
    AnimatedCardTitle: {
        fontSize: '8rem',
        lineHeight: '9.5rem',
    },
    MainPageTitle: {
        fontSize: '69px',
        lineHeight: '82px',
    },
    AboutPageTitle: {
        fontSize: '48px',
        lineHeight: '48px',
    },
    PricingCardTitle: {
        fontSize: '36px',
        lineHeight: '43px',
    },
    HelpLinksItemTitle: {
        fontSize: '30px',
        lineHeight: '36px',
    },
    PricesDownloadTitle: {
        fontSize: '20px',
        lineHeight: '24px',
    },
    XXXL: {
        fontSize: '58px',
        lineHeight: '69px',
    },
    TextTitle: {
        fontSize: '48px',
        lineHeight: '58px',
    },
    XXL: {
        fontSize: '40px',
        lineHeight: '47px',
    },
    XL: {
        fontSize: '24px',
        lineHeight: '28px',
    },
    L: {
        fontSize: '18px',
        lineHeight: '21px',
    },
    LLL: {
        fontSize: '18px',
        lineHeight: '24px',
    },
    LL: {
        fontSize: '18px',
        lineHeight: '32px',
    },
    M: {
        fontSize: '16px',
        lineHeight: '22px',
    },
    S: {
        fontSize: '14px',
        lineHeight: '17px',
    },
    XS: {
        fontSize: '12px',
        lineHeight: '14px',
    },
    XXS: {
        fontSize: '10px',
        lineHeight: '12px',
    },
    Paragraph12: {
        fontSize: '12px',
        lineHeight: '20px',
    },
    Paragraph14: {
        fontSize: '14px',
        lineHeight: '20px',
    },
    Paragraph16: {
        fontSize: '16px',
        lineHeight: '22px',
    },
    Paragraph18: {
        fontSize: '18px',
        lineHeight: '24px',
    },
};
