import { ElcoinPaymentStatus, OperationType, Payment, PaymentStatus, PaymentType } from '@shared-graphql';
import { initialBeneficiary } from '@shared-model/beneficiary-model/beneficiary-model.initial';
import { initialCurrencyModel } from '@shared-model/currency-model/currency-model.initials';

import { initialFeeModel } from '@model/payment-model/payment-model-initials/payment-fee-model.initial';

export const initialPayment: Payment = {
    id: '',
    accountId: '',
    clientId: '',
    client: null,
    currency: initialCurrencyModel,
    amount: 0,
    total: 0,
    balance: 0,
    statuses: [],
    reason: '',
    documents: [],
    type: PaymentType.swift,
    elcoinStatus: ElcoinPaymentStatus.NEW,
    operation: OperationType.Debit,
    sender: null,
    status: PaymentStatus.Created,
    fee: initialFeeModel,
    createdAt: new Date(),
    updatedAt: new Date(),
    beneficiary: initialBeneficiary,
    purpose: '',
};

export const initialPaymentModel = {
    elements: {},
    totalCount: 0,
    payment: initialPayment,
    activeLoadedPaymentId: '',
};
