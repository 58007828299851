import {
    AccountAdministration,
    Commission,
    CommissionType,
    OperationType,
    PaymentType,
    UserType,
} from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import {
    initialCommissionLegal,
    initialCommissionPrivate,
} from '@page/commission-profile/commission-profile-initials/commission-profile.initials';

export interface CommissionListProps {
    title: LocalizationEnum;
    amount: number;
    reason: string;
    id: string;
}

export interface CommissionListAdministrationProps {
    title: LocalizationEnum;
    id: keyof Omit<AccountAdministration, 'balanceFee' | 'userType'>;
    info?: LocalizationEnum;
}

export const administrationList: CommissionListAdministrationProps[] = [
    {
        title: LocalizationEnum.CommissionProfileAdministrationListTitle1,
        id: 'accountOpening',
    },
    {
        title: LocalizationEnum.CommissionProfileAdministrationListTitle2,
        id: 'urgentAccountOpening',
    },
    {
        title: LocalizationEnum.CommissionProfileAdministrationListTitle3,
        id: 'foreignCurrencies',
    },
    {
        title: LocalizationEnum.CommissionProfileAdministrationListTitle4,
        id: 'accountMaintenance',
    },
    {
        title: LocalizationEnum.CommissionProfileAdministrationListTitle5,
        id: 'accountClosing',
    },
    {
        title: LocalizationEnum.CommissionProfileAdministrationListTitle6,
        id: 'complexStructure',
    },
];

export const getPaymentTemplateUserType = (
    templates: Commission[],
    isSepa: boolean,
    userType: UserType,
    hasClientId: boolean
) => {
    const result = templates.find(
        template => template.isSepa === isSepa && template.userType === userType && template.isDefault !== hasClientId
    );

    if (!result) {
        if (userType === UserType.Legal) {
            return initialCommissionLegal;
        }
        return initialCommissionPrivate;
    }

    return result;
};

export const getPaymentTemplateType = (
    templates: Commission[],
    isSepa: boolean,
    type: CommissionType,
    hasClientId: boolean
) =>
    templates.find(
        template => template.isSepa === isSepa && template.type === type && template.isDefault !== hasClientId
    ) ?? initialCommissionLegal;

export const getPaymentTemplateOperation = (
    templates: Commission[],
    paymentType: PaymentType,
    operation: OperationType
) =>
    templates.find(template => template.paymentType === paymentType && template.operation === operation) ??
    initialCommissionLegal;
