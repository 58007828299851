import { types } from 'mobx-state-tree';

const CompanyStatusModelType = types.model('CompanyStatus', {
    consentToPrivacyPolicyAccepted: types.maybeNull(types.number),
    hasAddress: types.boolean,
    hasIndustry: types.boolean,
    hasPersons: types.boolean,
    hasShareholders: types.optional(types.boolean, false),
    hasDocuments: types.boolean,
    hasService: types.boolean,
    hasAccept: types.boolean,
    termsAccepted: types.maybeNull(types.number),
});

export const CompanyStatusModelInstance = CompanyStatusModelType.actions(self => ({
    setHasCompanyAddress: (hasAddress: boolean) => {
        self.hasAddress = hasAddress;
    },
    setHasCompanyIndustries: (hasIndustry: boolean) => {
        self.hasIndustry = hasIndustry;
    },
    setHasCompanyPersons: (hasPersons: boolean) => {
        self.hasPersons = hasPersons;
    },
    setHasCompanyShareholders: (hasShareholders: boolean) => {
        self.hasShareholders = hasShareholders;
    },
    setHasCompanyDocuments: (hasDocuments: boolean) => {
        self.hasDocuments = hasDocuments;
    },
    setHasCompanyService: (hasService: boolean) => {
        self.hasService = hasService;
    },
    setHasCompanyAccept: (hasAccept: boolean) => {
        self.hasAccept = hasAccept;
    },
    setConsentToPrivacyPolicyAccepted: (consentToPrivacyPolicyAccepted: number | null) => {
        self.consentToPrivacyPolicyAccepted = consentToPrivacyPolicyAccepted;
    },
    setTermsAccepted: (termsAccepted: number | null) => {
        self.termsAccepted = termsAccepted;
    },
}));
