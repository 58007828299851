import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { AccountType, AccountStatus, PaymentEntityType } from '@shared-graphql';
import { CurrencyModelType } from '@shared-model/currency-model/currency-model.type';

import { AccountBankTypeSchema } from './account-bank-model/account-bank-model.type';
import { AccountCardTypeSchema } from './account-card-model/account-card-model.type';

const statusValues = $enum(AccountStatus).getValues();
const typeValues = $enum(AccountType).getValues();
const entityValues = $enum(PaymentEntityType).getValues();

export const AccountElpaTypeSchema = {
    id: types.identifier,
    accountId: types.maybeNull(types.string),
    name: types.string,
    number: types.string,
    balance: types.number,
    currency: CurrencyModelType,
    type: types.enumeration(typeValues),
    cards: types.array(types.model(AccountCardTypeSchema)),
    status: types.enumeration(statusValues),
};

export const AccountCcTypeSchema = {
    id: types.identifier,
    accountId: types.string,
    balance: types.number,
    freezeBalance: types.maybeNull(types.number),
    realBalance: types.maybeNull(types.number),
    currency: CurrencyModelType,
    type: types.enumeration(typeValues),
    legalEntityType: types.maybeNull(types.enumeration(entityValues)),
    banks: types.array(types.maybe(types.model(AccountBankTypeSchema))),
};
