import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useRegistrationLegalPersonPrivateProfileFormDirectorLocalization = () => {
    const directorTitle = useLocalizationText(LocalizationEnum.RegistrationLegalPersonsPrivateFormDirector);
    const powerOfAttorneyTitle = useLocalizationText(
        LocalizationEnum.RegistrationLegalPersonsPrivateFormPowerOfAttorney
    );

    return { directorTitle, powerOfAttorneyTitle };
};
