import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const LoginFormTitle = styled.h1`
    ${Typography.XXL.Style}
`;

export const LoginContentDescriptionText = styled.p`
    margin-top: 16px;
    ${Typography.Fiord.Style}
`;
