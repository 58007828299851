import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Casal, Polar } from '@shared-style/colors';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

interface AmountCellWrapperProps extends ThemeTypeInterface {
    hasFill: boolean;
}

export const AmountCellWrapper = styled.span<AmountCellWrapperProps>`
    display: grid;
    place-items: center;
    border-radius: 8px;
    height: 24px;
    ${Typography.Paragraph14.SemiBold.Neutral900.HiddenEllipsis.Style};
    ${({ hasFill, theme }: AmountCellWrapperProps) =>
        hasFill && `background-color: ${theme.isDarkTheme ? Casal : Polar}`};
    @media (max-width: 991px) {
        padding: 0 2px;
        font-size: 12px;
    }
`;
