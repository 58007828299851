import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { AddressForm } from '@shared-form/address-form/address-form';
import { AddressInput } from '@shared-graphql';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';

import { ButtonWrapper } from '@page/private-client-profile/private-client-profile.styles';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const PrivateClientProfileAddress = observer(() => {
    const { params } = useCurrentNavigation();
    const [{ address }] = usePrivateClientSelector();

    const handleUpdateAddress = (newAddress: AddressInput) => console.log(params.userId, newAddress);

    return (
        <Formik onSubmit={handleUpdateAddress} initialValues={(address ?? {}) as AddressInput} enableReinitialize>
            <Form>
                <AddressForm />
                <ButtonWrapper>
                    <ButtonPrimary type={ButtonTypeEnum.Submit}>Update address</ButtonPrimary>
                </ButtonWrapper>
            </Form>
        </Formik>
    );
});
