import { Link } from '@shared-atom/link/link';
import { Transaction } from '@shared-graphql';
import { getPathnameWithParams } from '@shared-util/get-pathname-with-params';
import { isString } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';

import { initialClient } from './actions-cell.initial';
import { ActionsWrapper, IconWrapper, InfoIcon } from './actions-cell.styles';

export const ActionsCell = ({ id, client }: Transaction) => {
    const { id: clientId } = client ?? initialClient;

    const shouldRenderLink = isString(clientId);

    return (
        <ActionsWrapper>
            {shouldRenderLink && (
                <>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link
                        to={{
                            pathname: getPathnameWithParams(PageEnum.PaymentSummary, { paymentId: id, clientId }),
                        }}
                    >
                        <IconWrapper>
                            <InfoIcon />
                        </IconWrapper>
                    </Link>
                </>
            )}
            {!shouldRenderLink && (
                <IconWrapper>
                    <InfoIcon />
                </IconWrapper>
            )}
        </ActionsWrapper>
    );
};
