import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

import { RegistrationLegalPersonPrivateProfileFormEnum } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.enum';
import { Maybe, UserRolesType } from '@shared-graphql';

import { RelationPersonInterface } from '../registration-legal-person-private-profile-form.interface';

export const useLegalPrivateProfileUserCreate = () => {
    const {
        initialValues: { email, phone, companyRole },
    } = useFormikContext<RelationPersonInterface>();
    const [{ value: isCreateUser }] = useField<boolean>(RegistrationLegalPersonPrivateProfileFormEnum.UserCreate);
    const [, , { setValue: setEmail }] = useField<string>(RegistrationLegalPersonPrivateProfileFormEnum.Email);
    const [, , { setValue: setPhone }] = useField<string>(RegistrationLegalPersonPrivateProfileFormEnum.Phone);
    const [, , { setValue: setCompanyRole }] = useField<Maybe<UserRolesType>>(
        RegistrationLegalPersonPrivateProfileFormEnum.UserRole
    );

    useEffect(() => {
        setCompanyRole(isCreateUser ? companyRole ?? UserRolesType.RoleA : null);
        setEmail(isCreateUser ? email ?? '' : '');
        setPhone(isCreateUser ? phone ?? '' : '');
    }, [isCreateUser]);
};
