import { PrivateAcceptAgreementFormEnum } from '@shared-form/private-accept-agreement-form/private-accept-agreement-form.enum';
import { PrivateAcceptAgreementType } from '@shared-form/private-accept-agreement-form/private-accept-agreement-form.type';
import { PrivateAcceptAgreementValidation } from '@shared-form/private-accept-agreement-form/private-accept-agreement-form.validation';
import { useSendPhoneCode } from '@shared-hook/phone-code/send-phone-code.hook';
import { OnEventType } from '@shared-type/on-event.type';

import { usePrivateClientActionsSelector } from '@selector/private-client/use-private-client.actions-selector';
import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const usePrivateClientAcceptAgreement = (
    _: string
): [
    boolean,
    PrivateAcceptAgreementType,
    typeof PrivateAcceptAgreementValidation,
    OnEventType<PrivateAcceptAgreementType> | null
] => {
    const [sendCode] = useSendPhoneCode();

    const { setClientAcceptAgreement } = usePrivateClientActionsSelector();
    const [{ privateStatus, phone }] = usePrivateClientSelector();
    const hasUserFullRegistration =
        privateStatus.hasProfile &&
        privateStatus.hasAddress &&
        privateStatus.hasService &&
        privateStatus.hasDocument &&
        privateStatus.hasDeclaration;

    const initialAcceptAgreement = {
        [PrivateAcceptAgreementFormEnum.AcceptAgreement]: privateStatus.hasAccept,
        [PrivateAcceptAgreementFormEnum.AcceptAgreementTU]: privateStatus.hasAccept,
        [PrivateAcceptAgreementFormEnum.Otp]: '',
    };

    const handleUpdateProfile = ({ isAgreementAccepted }: PrivateAcceptAgreementType): void => {
        sendCode(phone);
        setClientAcceptAgreement(isAgreementAccepted);
    };

    return [
        hasUserFullRegistration,
        initialAcceptAgreement,
        PrivateAcceptAgreementValidation,
        hasUserFullRegistration ? handleUpdateProfile : null,
    ];
};
