import { Instance } from 'mobx-state-tree';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { UserCompanyInput } from '@shared-graphql';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { OnEventType } from '@shared-type/on-event.type';

import { PageEnum } from '@enum/page.enum';
import { CompanyModelTypeInstance } from '@model/company-model/company-model.type';
import { useLegalClientProfileCompanyUpdate } from '@page/legal-client-profile/legal-client-profile-information/legal-client-profile-information.hook';
import { useCompanySelector } from '@selector/company/use-company.selector';

export const useLegalClientCreate = (): [Instance<typeof CompanyModelTypeInstance>, OnEventType<UserCompanyInput>] => {
    const navigate = useRouterNavigate();
    const { userId } = useParams();

    const [{ hasCompany, id }] = useCompanySelector();

    const [initialData] = useLegalClientProfileCompanyUpdate();

    const handleSubmit = useCallback(() => {}, [userId]);

    useEffect(() => void (hasCompany && navigate(PageEnum.LegalClientProfileAddress, { companyId: id })), [id]);

    return [initialData, handleSubmit];
};
