import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { CellHoveredText } from '@shared-component/table/table-cell/table-cell.styles';
import { Transaction } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

export const ClientNameCell = ({ client }: Transaction) => (
    <CellHoveredText>
        {isTrue(client?.name) ? (
            <Tooltip content={client?.name as string} dataFor={`tooltip-for-client-name-${client?.name}-${client?.id}`}>
                {client?.name}
            </Tooltip>
        ) : null}
    </CellHoveredText>
);
