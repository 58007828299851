import styled from 'styled-components';

import { White } from '@shared-style/colors';

import { PageContainerWrapper } from '@page/common/common.styles';

export const PageWrapper = styled(PageContainerWrapper)`
    padding: 32px;
    border-radius: 24px;
    background-color: ${White};
`;

export const CreateClientWrapper = styled.div`
    margin-top: 24px;
    max-width: 600px;
`;
