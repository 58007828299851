import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserDocumentType } from '@shared-graphql';
import { FileModel } from '@shared-model/file-model/file-model';

const userDocumentTypeValues = $enum(UserDocumentType).getValues();

export const UserDocumentsType = types.model('UserDocumentsType', {
    type: types.enumeration(userDocumentTypeValues),
    file: types.maybeNull(FileModel),
    updatedAt: types.optional(types.Date, () => new Date()),
    prevFile: types.maybeNull(FileModel),
});
