import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { CompanyActivatedStatus } from '@shared-graphql';

const companyActivatedStatusValues = $enum(CompanyActivatedStatus).getValues();

export const CompanyStatusesType = types.model({
    status: types.enumeration(companyActivatedStatusValues),
    date: types.Date,
});
