import { observer } from 'mobx-react-lite';

import { CountrySelect } from '@shared-component/country-select/country-select';
import { Input } from '@shared-component/input/input';
import { InputDate } from '@shared-component/input/input-date/input-date';
import { Select } from '@shared-component/select/select';
import { FormRow, FormHalfRow, FieldColumnWrapper } from '@shared-form/form-common/form-common.styles';
import { useProfileFinancialStatusOptions } from '@shared-form/private-profile-form/private-profile-form.localization-options-hook';

import { PrivateProfileFormEnum } from './private-profile-form.enum';
import { usePrivateProfileFormLocalization } from './private-profile-form.localization-hook';
import { useProfileGenderOptions } from './private-profile-form.options';

export const PrivateProfileForm = observer(() => {
    const { nameTitle, lastNameTitle, birthdateTitle, genderTitle, financialSituationTitle, nationalityTitle } =
        usePrivateProfileFormLocalization();
    const profileFinancialStatusOptions = useProfileFinancialStatusOptions();
    const profileGenderOptions = useProfileGenderOptions();

    return (
        <>
            <Input name={PrivateProfileFormEnum.Name} title={nameTitle} />
            <FormRow>
                <Input name={PrivateProfileFormEnum.LastName} title={lastNameTitle} />
            </FormRow>
            <FormHalfRow>
                <InputDate name={PrivateProfileFormEnum.DateBirthday} title={birthdateTitle} />
                <FieldColumnWrapper>
                    <Select name={PrivateProfileFormEnum.Gender} title={genderTitle} options={profileGenderOptions} />
                </FieldColumnWrapper>
            </FormHalfRow>
            <FormRow>
                <Select
                    name={PrivateProfileFormEnum.FinancialStatus}
                    title={financialSituationTitle}
                    options={profileFinancialStatusOptions}
                />
            </FormRow>
            <FormRow>
                <CountrySelect name={PrivateProfileFormEnum.Nationality} title={nationalityTitle} />
            </FormRow>
        </>
    );
});
