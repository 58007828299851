import styled from 'styled-components';

import { ButtonTransparentStyle } from '@shared-atom/button/button.style';
import { Mystic } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { PuceGradient } from '@shared-style/gradient';

export const InfoBlockView = styled.div`
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px ${Mystic};
    min-height: 48px;
    box-sizing: content-box;

    ${Flex.SpaceBetween.VerticalCenter.Style}

    ${ButtonTransparentStyle} {
        width: 100%;
    }
`;

export const CircleIcon = styled.div`
    height: 48px;
    width: 48px;
    border-radius: 48px;
    background: ${PuceGradient};
    ${Flex.VerticalCenter.HorizontalCenter.Style}
`;
