import { FC } from 'react';

import { TableComponent } from '@shared-component/table/table';
import { Cell } from '@shared-component/table/table-cell/table-cell';
import { UserDocument } from '@shared-graphql';

import { ChangedFileCell } from './cells/changed-file-cell';
import { DateCell } from './cells/date-cell';
import { DeletedFileCell } from './cells/deleted-file-cell';
import { ClientAccountsTableColumns } from './client-document-archive-table.columns';

interface ClientDocumentArchiveTableProps {
    documents: UserDocument[];
}

export const ClientDocumentArchiveTable: FC<ClientDocumentArchiveTableProps> = ({ documents }) => (
    <TableComponent data={documents} columns={ClientAccountsTableColumns} hasTableHead>
        <Cell />
        <Cell>{DeletedFileCell}</Cell>
        <Cell>{ChangedFileCell}</Cell>
        <Cell>{DateCell}</Cell>
    </TableComponent>
);
