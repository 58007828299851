import { UserGender } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useProfileGenderOptions = () => {
    const male = useLocalizationText(LocalizationEnum.PrivateFormGenderMaleOption);
    const female = useLocalizationText(LocalizationEnum.PrivateFormGenderFemaleOption);

    return [
        {
            value: UserGender.Male,
            label: male,
        },
        {
            value: UserGender.Female,
            label: female,
        },
    ];
};
