// import AnimationLoading from '@shared-asset/loading-primary-56.animation.json';
import { BackButton } from '@shared-atom/back-button/back-button';
import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
// import { Animation } from '@shared-component/animation/animation';
import { DocumentFileUpload } from '@shared-component/file-upload/document-file-upload/document-file-upload';
import {
    RegistrationLegalDescription,
    RegistrationLegalPersonTitle,
    ButtonSubmit,
    // AnimationWrapper,
} from '@shared-component/registration/common.styles';
import { FormWrapper } from '@shared-form/form-common/form-common.styles';
import { DocumentUploaded } from '@shared-form/private-document-form/document-uploaded/document-uploaded';
import { File as FileUploadType, Passport, UserDocument, UserDocumentType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { useRegistrationLegalPersonPrivateDocument } from './registration-legal-person-private-document.hook';

interface RegistrationLegalPersonPrivateProfileProps {
    passport: Pick<UserDocument, 'type' | 'file'>;
    onUploaded: OnEventType<FileUploadType>;
    onSubmit: OnEventType<Passport>;
    onDeleteUpload: OnEventEmptyType;
    onBack: OnEventEmptyType;
}

export const RegistrationLegalPersonPrivateDocument = ({
    passport,
    onUploaded,
    onSubmit,
    onDeleteUpload,
    onBack,
}: RegistrationLegalPersonPrivateProfileProps) => {
    const [onPassportUploaded, onParseDocument, isLoading] = useRegistrationLegalPersonPrivateDocument(
        onUploaded,
        onSubmit,
        passport
    );
    const shouldRenderUploadInfo = passport.type === UserDocumentType.Passport;

    return (
        <>
            <RegistrationLegalPersonTitle>
                <Translate langKey={LocalizationEnum.RegistrationLegalPersonsDocumentsPassportTitle} />
            </RegistrationLegalPersonTitle>
            <RegistrationLegalDescription>
                <Translate langKey={LocalizationEnum.RegistrationLegalPersonsDocumentsPassportDescription} />
            </RegistrationLegalDescription>
            <FormWrapper>
                {!shouldRenderUploadInfo && <DocumentFileUpload onUploaded={onPassportUploaded} />}
                {shouldRenderUploadInfo && (
                    <DocumentUploaded
                        filename={passport?.file?.filename ?? ''}
                        keyId={passport?.file?.key}
                        onDelete={onDeleteUpload}
                    />
                )}
            </FormWrapper>
            <BackButton onClick={onBack}>
                <ButtonPrimary
                    isLarge
                    type={ButtonTypeEnum.Button}
                    onClick={onParseDocument}
                    isDisabled={isLoading || !shouldRenderUploadInfo}
                >
                    <ButtonSubmit>
                        {/* <AnimationWrapper isVisible={isLoading}> */}
                        {/*    <Animation path={AnimationLoading} speed={0.75} isLoop /> */}
                        {/* </AnimationWrapper> */}
                        {!isLoading && <Translate langKey={LocalizationEnum.RegistrationLegalCompanyButtonTitle} />}
                    </ButtonSubmit>
                </ButtonPrimary>
            </BackButton>
        </>
    );
};
