import { UserActivatedStatus } from '@shared-graphql';
import { initialUserProfileModel } from '@shared-model/user-model/user-model-private/user-profile/user-profile.initial';

import { initialUserDocumentModels } from '@model/users-model/users-model-initials/user-documents-model.initial';

export const initialUserPrivateStatus = {
    hasProfile: false,
    hasAddress: false,
    hasService: false,
    hasDeclaration: false,
    hasDocument: false,
    hasAccept: false,
    isAcceptAgreement: false,
};

export const initialUserLegalStatus = {
    hasCompany: false,
    hasAddress: false,
    hasIndustry: false,
    hasPersons: false,
};

export const initialPrivateClient = {
    id: '',
    email: '',
    elcoinId: '',
    phone: '',
    type: null,
    status: UserActivatedStatus.Approved,
    privateStatus: initialUserPrivateStatus,
    legalStatus: initialUserLegalStatus,
    profile: initialUserProfileModel,
    address: null,
    service: null,
    declaration: null,
    documents: initialUserDocumentModels,
    isAcceptAgreement: false,
    fullName: null,
    comment: {
        elements: {},
    },
};
