import { useState } from 'react';

import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { RegistrationLegalPrivatePersonModalEnum } from './registration-legal-person-private.modal-options';

export const useRegistrationLegalPersonPrivateModal = (): [
    RegistrationLegalPrivatePersonModalEnum,
    OnEventType<RegistrationLegalPrivatePersonModalEnum, OnEventEmptyType>
] => {
    const [selectedView, setSelectedView] = useState(RegistrationLegalPrivatePersonModalEnum.Role);

    const handleSelectedView = (view: RegistrationLegalPrivatePersonModalEnum) => () => setSelectedView(view);

    return [selectedView, handleSelectedView];
};
