import { observer } from 'mobx-react-lite';

import { OperationType, PaymentStatus } from '@shared-graphql';
import { isString } from '@shared-util/is-data';

import { SummaryGrid } from '@component/summary-grid/summary-grid';
import { BlockFooter, Column } from '@component/summary-grid/summary-grid.styles';
import { MoneyCell } from '@component/summary-grid/summary-money-cell/summary-money-cell';
import { Row } from '@component/summary-grid/summary-row/summary-row';

import { PaymentSummaryButtons } from './payment-summary-buttons/payment-summary-buttons';
import { usePaymentSummary } from './payment-summary.hook';
import { SummaryWrapper } from './payment-summary.styles';

const BUTTON_STATUSES = new Set([PaymentStatus.Created, PaymentStatus.Signed]);

export const PaymentSummary = observer(() => {
    const {
        id,
        bic,
        type,
        status,
        reason,
        total,
        createdAt,
        amount,
        currency: { code },
        operation,
        sender,
        payerName,
        bankCountry,
        payerAccountNumber,
        payerAccountBalance,
        payerAccountRealBalance,
        payerAccountFreezeBalance,
        commissionAmount,
        beneficiaryCountry,
        beneficiaryName,
        beneficiaryBankName,
        beneficiaryAccount,
    } = usePaymentSummary();

    const shouldRenderDescription = isString(reason);
    const shouldRenderBeneficiary = operation === OperationType.Debit;
    const shouldRenderCredit = operation === OperationType.Credit;
    const shouldRenderButtons = !shouldRenderCredit && status !== null && BUTTON_STATUSES.has(status);
    const shouldRenderBeneficiaryCountry = beneficiaryCountry !== null;
    const shouldShowStatementButton = status === PaymentStatus.Completed && operation === OperationType.Debit;

    return (
        <SummaryWrapper>
            <SummaryGrid title="Payment details" actions={{ shouldShowStatementButton }}>
                <Column>
                    <Row title="Order ID" data={id} />
                    <Row title="Type" data={type} />
                    <Row title="Status" data={status} />
                    {shouldRenderDescription && <Row title="Description" data={reason} />}
                </Column>
                <Column>
                    <Row title="Date" data={createdAt} />
                    <Row title="Amount" data={<MoneyCell amount={amount} currency={code} />} />
                    <Row title="Commission" data={<MoneyCell amount={commissionAmount} currency={code} />} />
                    <Row title="Total" data={<MoneyCell amount={total} currency={code} />} />
                </Column>
            </SummaryGrid>
            <SummaryGrid title="Client details">
                <Column>
                    <Row title="Client Name" data={payerName} />
                    <Row title="Account" data={payerAccountNumber} />
                    <Row title="Balance" data={<MoneyCell amount={payerAccountBalance} currency={code} />} />
                    <Row title="Real Balance" data={<MoneyCell amount={payerAccountRealBalance} currency={code} />} />
                    <Row
                        title="Freeze Balance"
                        data={<MoneyCell amount={payerAccountFreezeBalance} currency={code} />}
                    />
                </Column>
            </SummaryGrid>
            {shouldRenderCredit && (
                <SummaryGrid title="Sender details">
                    <Column>
                        <Row title="Sender Name" data={sender?.name} />
                        <Row title="Account" data={sender?.iban} />
                        <Row title="BIC/SWIFT" data={sender?.bicSwift} />
                    </Column>
                </SummaryGrid>
            )}
            {shouldRenderBeneficiary && (
                <SummaryGrid title="Beneficiary details">
                    <Column>
                        <Row title="Beneficiary Name" data={beneficiaryName} />
                        <Row title="Account" data={beneficiaryAccount} />
                        <Row title="BIC/SWIFT" data={bic} />
                    </Column>
                    <Column>
                        {shouldRenderBeneficiaryCountry && (
                            <Row title="Beneficiary country" data={beneficiaryCountry} />
                        )}
                        <Row title="Bank Name" data={beneficiaryBankName} />
                        <Row title="Bank country" data={bankCountry} />
                    </Column>
                </SummaryGrid>
            )}
            {shouldRenderButtons && (
                <BlockFooter>
                    <PaymentSummaryButtons id={id} status={status as PaymentStatus} />
                </BlockFooter>
            )}
        </SummaryWrapper>
    );
});
