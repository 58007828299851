import { Field, useField } from 'formik';
import React, { useEffect, FC } from 'react';

import { OnEventType } from '@shared-type/on-event.type';
import { noop } from '@shared-util/noop';

import { RadioButtonWrapper, RadioButtonIconWrapper } from './radio-button.style';

export interface RadioButtonProps {
    name: string;
    value: string | number;
    onChange?: OnEventType<boolean>;
    children?: React.ReactNode;
}

export const RadioButton: FC<RadioButtonProps> = ({ name, value, onChange = noop, children }) => {
    const [field] = useField(name);
    const isChecked = field.value === value;

    useEffect(() => void onChange(field.value), [field.value]);

    return (
        <RadioButtonWrapper>
            <Field type="radio" name={name} value={value} hidden />
            {children ?? <RadioButtonIconWrapper isChecked={isChecked} />}
        </RadioButtonWrapper>
    );
};
