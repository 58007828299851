import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { UserCompanyRelationType, UserDocumentType, UserRolesType, UserType } from '@shared-graphql';
import { AddressType } from '@shared-model/address-type/address.type';
import { UserDocumentsType } from '@shared-model/user-model/user-model-private/user-documents.type';
import { UserProfileType } from '@shared-model/user-model/user-model-private/user-profile/user-profile.type';

const companyRelationValues = $enum(UserCompanyRelationType).getValues();
const companyRoleValues = $enum(UserRolesType).getValues();
const typeValues = $enum(UserType).getValues();

const REQUIRED_PRIVATE_DOCUMENT_TYPES = new Set([
    UserDocumentType.Selfie,
    UserDocumentType.Passport,
    UserDocumentType.Location,
]);

const REQUIRED_LEGAL_DOCUMENT_TYPES = new Set([UserDocumentType.Chart]);

export const CompanyPrivatePersonType = types
    .model('CompanyPrivatePerson', {
        id: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        phone: types.maybeNull(types.string),
        profile: UserProfileType,
        address: AddressType,
        documents: types.array(UserDocumentsType),
    })
    .views(self => ({
        get isDone() {
            return (
                self.documents.filter(upload => REQUIRED_PRIVATE_DOCUMENT_TYPES.has(upload.type)).length >=
                REQUIRED_PRIVATE_DOCUMENT_TYPES.size
            );
        },
    }));

export const CompanyLegalPersonType = types
    .model('CompanyLegalPerson', {
        id: types.maybeNull(types.string),
        companyName: types.maybeNull(types.string),
        registrationCountry: types.maybeNull(types.string),
        companyNumber: types.maybeNull(types.string),
        companyTaxNumber: types.maybeNull(types.string),
        companySite: types.maybeNull(types.string),
        registrationDate: types.Date,
        isConductBusiness: types.boolean,
        isGroupPart: types.boolean,
        isCompanyAddressSame: types.maybeNull(types.boolean),
        legalAddress: types.maybeNull(AddressType),
        postalAddress: types.maybeNull(AddressType),
        documents: types.array(UserDocumentsType),
    })
    .views(self => ({
        get isDone() {
            return (
                self.documents.filter(upload => REQUIRED_LEGAL_DOCUMENT_TYPES.has(upload.type)).length >=
                REQUIRED_LEGAL_DOCUMENT_TYPES.size
            );
        },
    }));

const PersonRelationType = types.model('CompanyPersonRelation', {
    companyRelation: types.array(types.enumeration(companyRelationValues)),
    companyRole: types.maybeNull(types.enumeration(companyRoleValues)),
    ownershipPercent: types.maybeNull(types.number),
    beneficiaryPercent: types.maybeNull(types.number),
    isUserCreate: types.boolean,
    isPowerOfAttorney: types.maybeNull(types.boolean),
});

export const CompanyPersonRelationType = types.model('CompanyPersonRelation', {
    id: types.identifier,
    person: types.maybeNull(CompanyPrivatePersonType),
    legal: types.maybeNull(CompanyLegalPersonType),
    type: types.enumeration(typeValues),
    relation: PersonRelationType,
});
