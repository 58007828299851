import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { BadgeSizeEnum, BadgeTypeEnum } from '@shared-component/badge/types';
import { getBtnPaddingBySize, getStyleByType } from '@shared-component/badge/utils';
import { Flex } from '@shared-style/flex';

interface BadgeProps {
    size: BadgeSizeEnum;
    typeStyle: BadgeTypeEnum;
}

interface BadgeTitleProps {
    size: BadgeSizeEnum;
}

interface BadgeIconProps {
    isBefore?: boolean;
    size: BadgeSizeEnum;
}

export const BadgeComponent = styled.div<BadgeProps>`
    ${Flex.HorizontalCenter.VerticalCenter.InlineFlex.Style};
    ${({ size }) => (size === 's' ? Typography.Paragraph12.Medium.Style : Typography.Paragraph14.Medium.Style)};
    ${({ typeStyle }) => getStyleByType(typeStyle)}
    padding: ${({ size }) => getBtnPaddingBySize(size)};
    text-align: center;
    svg,
    path {
        ${({ typeStyle }) => getStyleByType(typeStyle)};
    }
`;
export const BadgeTitle = styled.span<BadgeTitleProps>`
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    ${({ size }) => (size === 's' ? Typography.Paragraph12.Medium.Style : Typography.Paragraph14.Medium.Style)};
`;
export const BadgeIcon = styled.span<BadgeIconProps>`
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    margin: ${({ isBefore }) => (isBefore === true ? '0 6px 0 0' : '0 0 0 6px')};
`;
