import { observer } from 'mobx-react-lite';

import { CheckBox } from '@shared-component/checkbox/checkbox';
import { Input } from '@shared-component/input/input';
import { Select } from '@shared-component/select/select';
import { BaseOptionInterface } from '@shared-component/select/select.props';
import { FieldColumnWrapper, FormHalfRow, FormRow } from '@shared-form/form-common/form-common.styles';

import { PrivateServiceFormEnum } from './private-service-form.enum';
import { usePrivateServiceFormLocalization } from './private-service-form.localization-hook';

interface PrivateServiceFormProps {
    currencies: BaseOptionInterface[];
}

export const PrivateServiceForm = observer(({ currencies }: PrivateServiceFormProps) => {
    const { eurTitle, paymentTitle, currencyTitle, moneyTitle, accountTitle } = usePrivateServiceFormLocalization();

    return (
        <>
            <CheckBox name={PrivateServiceFormEnum.EuroIban} title={eurTitle} />
            <FormRow>
                <CheckBox name={PrivateServiceFormEnum.PaymentAccount} title={paymentTitle} />
            </FormRow>
            <FormHalfRow>
                <Select name={PrivateServiceFormEnum.Currency} title={currencyTitle} options={currencies} />
                <FieldColumnWrapper>
                    <Input type="number" name={PrivateServiceFormEnum.MoneyTurnover} title={moneyTitle} />
                </FieldColumnWrapper>
            </FormHalfRow>
            <FormRow>
                <Input name={PrivateServiceFormEnum.ReasonAccount} title={accountTitle} />
            </FormRow>
        </>
    );
});
