import { flow, getParent, Instance } from 'mobx-state-tree';

import { Query, User, UserProfile, AdminGetPrivateClientProfile } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { PrivateClientModel } from '@model/private-client-model/private-client-model';
import { PrivateClientTypes } from '@model/private-client-model/private-client-model.type';
import { initialUserProfileModel } from '@model/users-model/users-model-initials/user-profile-model.initial';

const queryClientProfile = (userId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetPrivateClient'>>({ query: AdminGetPrivateClientProfile, variables: { userId } })
        .then(result => (result.data.adminGetPrivateClient as User).profile);

export const privateClientProfileActions = (self: Instance<typeof PrivateClientTypes>) => ({
    loadPrivateClientProfile: flow(function* loadPrivateClientProfile(userId) {
        const clientModel = getParent(self) as Instance<typeof PrivateClientModel>;

        if (clientModel.loading.isLoading) {
            return;
        }

        clientModel.setLoading(true);

        try {
            const profile: UserProfile = yield queryClientProfile(userId);

            self.profile = (profile ?? initialUserProfileModel) as any;
        } catch (error) {
            throw new Error(error as string);
        } finally {
            clientModel.setLoading(false);
        }
    }),
});
