import { mutationAddComment } from '@queries/comment/comment.mutation';
import { applySnapshot, flow, Instance } from 'mobx-state-tree';

import {
    Mutation,
    Query,
    User,
    UserDocument,
    Comment,
    UserDocumentStatus,
    AddFileComment,
    AdminGetPrivateClientDocuments,
} from '@shared-graphql';
import { isString } from '@shared-util/is-data';

import { apolloClient } from '@connection/apollo-client';
import { PrivateClientTypes } from '@model/private-client-model/private-client-model.type';

const getClientDocuments = (userId: string, documentStatuses: UserDocumentStatus[]) =>
    apolloClient
        .query<Pick<Query, 'adminGetPrivateClient'>>({
        query: AdminGetPrivateClientDocuments,
        variables: { userId, documentStatuses },
    })
        .then(result => (result.data?.adminGetPrivateClient as User).documents);

const addFileComment = (fileId: string, commentId: string) =>
    apolloClient
        .mutate<Pick<Mutation, 'addFileComment'>>({ mutation: AddFileComment, variables: { fileId, commentId } })
        .then(result => result.data?.addFileComment as unknown as Comment);

export const privateClientDocumentsActions = (self: Instance<typeof PrivateClientTypes>) => ({
    loadPrivateClientDocuments: flow(function* loadPrivateClientDocuments(
        clientId: string,
        documentStatus: UserDocumentStatus[] = [UserDocumentStatus.Uploaded]
    ) {
        try {
            const documents = yield getClientDocuments(clientId, documentStatus);

            applySnapshot(self.documents, documents);
        } catch (error) {
            throw new Error(error as string);
        }
    }),
    updatePrivateClientDocuments: flow(function* updatePrivateClientDocuments(
        clientId: string,
        documents: UserDocument[]
    ) {
        const prevDocuments = new Map(self.documents.map((document: any) => [document.file.key, document]));

        const comments = documents.map((document: any) => {
            const { comment: prevComment } = prevDocuments.get(document.file.key)?.file.comment ?? {};
            const newComment = document.file.comment?.comment;

            if (isString(newComment) && newComment !== prevComment) {
                return mutationAddComment((document.file.comment as Comment).comment).then(comment =>
                    addFileComment(document.file.key, comment.id)
                );
            }

            return true;
        }) as Promise<any>[];

        yield Promise.all(comments);

        applySnapshot(self.documents, documents);
    }),
});
