import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { Input } from '@shared-component/input/input';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { FreeDesignModalButtonsWrapper } from '@component/modal/admin-modal.styles';
import { AddServiceRequiredFieldsFormInterface } from '@component/modal/modals/add-service-required-fields/add-service-required-fields-form/add-service-required-fields-form.interface';

export const AddServiceRequiredFieldsForm = ({
    requiredFields,
    onCancelClick,
}: AddServiceRequiredFieldsFormInterface) => (
    <>
        {requiredFields.map(({ name, title }) => (
            <FormRow key={name}>
                <Input name={name} title={title} />
            </FormRow>
        ))}
        <FreeDesignModalButtonsWrapper>
            <ButtonPrimary onClick={onCancelClick}>
                <Translate langKey={LocalizationEnum.CommonCancel} />
            </ButtonPrimary>
            <ButtonPrimary type={ButtonTypeEnum.Submit}>
                <Translate langKey={LocalizationEnum.CommonSave} />
            </ButtonPrimary>
        </FreeDesignModalButtonsWrapper>
    </>
);
