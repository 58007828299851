import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const RegistrationLegalPersonTitle = styled.h2`
    ${Typography.XXL.Dark.Style}
`;

export const RegistrationLegalDescription = styled.p`
    margin-top: 16px;
    ${Typography.M.Fiord.Style}
`;

export const ButtonSubmit = styled.div`
    width: 68px;
`;

interface AnimationWrapperInterface {
    isVisible: boolean;
}

export const AnimationWrapper = styled.div<AnimationWrapperInterface>`
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    height: ${({ isVisible }) => (isVisible ? 'auto' : 0)};
`;

export const ButtonWrapper = styled.div`
    margin-top: 40px;
`;

export const AvatarWrapper = styled.div`
    flex: 0 0 48px;
    height: 48px;
`;

export const PersonsWrapper = styled.div`
    display: grid;
    grid-template-rows: repeat(1fr);
    grid-column-gap: 0;
    grid-row-gap: 16px;
    margin-top: 24px;
    padding: 0 1px;
`;
