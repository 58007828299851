import { observer } from 'mobx-react-lite';
import { Instance } from 'mobx-state-tree';
import { FC } from 'react';

import { InfoBlock } from '@shared-atom/info-block/info-block';
import { Avatar } from '@shared-component/avatar/avatar';
import { AvatarWrapper } from '@shared-component/registration/common.styles';
import { UserType } from '@shared-graphql';
import { usePersonsLocalize } from '@shared-hook/legal-client-profile/persons/persons.localize-hook';
import { CompanyPersonRelationType } from '@shared-model/company-model/company-persons-model/company-persons-model.type';
import { OnEventEmptyType } from '@shared-type/on-event.type';

interface RegistrationLegalDocumentsPersonItemProps {
    person: Instance<typeof CompanyPersonRelationType>;
    onClick: OnEventEmptyType;
}

export const RegistrationLegalDocumentsPersonItem: FC<RegistrationLegalDocumentsPersonItemProps> = observer(
    ({ person, onClick }) => {
        const isLegal = person.type === UserType.Legal;

        const isChosen = (isLegal ? person.legal : person.person)?.isDone;

        const [infoTitle, companyRelation] = usePersonsLocalize(isLegal, person);
        return (
            <InfoBlock title={infoTitle} description={companyRelation} onClick={onClick} isChosen={isChosen} isButton>
                <AvatarWrapper>
                    <Avatar name={person.person?.profile.fullName ?? person.legal?.companyName ?? ''} />
                </AvatarWrapper>
            </InfoBlock>
        );
    }
);
