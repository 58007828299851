import { Form, Formik } from 'formik';

import { BackButton } from '@shared-atom/back-button/back-button';
import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import {
    RegistrationLegalDescription,
    RegistrationLegalPersonTitle,
} from '@shared-component/registration/common.styles';
import { AddressForm } from '@shared-form/address-form/address-form';
import { AddressValidation } from '@shared-form/address-form/address-form.validation';
import { FormWrapper } from '@shared-form/form-common/form-common.styles';
import { Address } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

interface RegistrationLegalPersonPrivateProfileProps {
    address: Address;
    onSubmit: OnEventType<Address>;
    onBack: OnEventEmptyType;
}

export const RegistrationLegalPersonPrivateAddress = ({
    address,
    onSubmit,
    onBack,
}: RegistrationLegalPersonPrivateProfileProps) => (
    <>
        <RegistrationLegalPersonTitle>
            <Translate langKey={LocalizationEnum.RegistrationLegalPersonsPrivateAddressTitle} />
        </RegistrationLegalPersonTitle>
        <RegistrationLegalDescription>
            <Translate langKey={LocalizationEnum.RegistrationLegalPersonsPrivateAddressDescription} />
        </RegistrationLegalDescription>
        <Formik
            initialValues={address}
            onSubmit={onSubmit}
            validationSchema={AddressValidation}
            validateOnBlur={false}
            validateOnChange={false}
        >
            <Form>
                <FormWrapper>
                    <AddressForm />
                </FormWrapper>
                <BackButton onClick={onBack}>
                    <ButtonPrimary isLarge type={ButtonTypeEnum.Submit}>
                        <Translate langKey={LocalizationEnum.RegistrationLegalCompanyButtonTitle} />
                    </ButtonPrimary>
                </BackButton>
            </Form>
        </Formik>
    </>
);
