import { parse } from 'date-fns';
import { Instance } from 'mobx-state-tree';
import { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { noop } from 'react-virtualized/dist/es/Masonry';

import { useCountryFilter } from '@shared-component/filters/country-filter/country-filter.hook';
import { useCreatedFromFilter } from '@shared-component/filters/created-from-filter/created-from-filter.hook';
import { useCreatedToFilter } from '@shared-component/filters/created-to-filter/created-to-filter.hook';
import { useFiltersCompose } from '@shared-component/filters/filters.hook';
import { usePaginationFilter } from '@shared-component/filters/pagination-filter/pagination-filter.hook';
import { useSearchFilter } from '@shared-component/filters/search-filter/search-filter.hook';
import { useSortFilter } from '@shared-component/filters/sort-filter/sort-filter.hook';
import { useStatusFilter } from '@shared-component/filters/status-filter/status-filter.hook';
import { useTypeFilter } from '@shared-component/filters/type-filter/status-filter.hook';
import { SortEnum, UserActivatedStatus, UserType } from '@shared-graphql';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { usePrevious } from '@shared-hook/utils/use-previous';
import { OnEventType } from '@shared-type/on-event.type';
import { isTrue } from '@shared-util/is-data';
import { isDeepEqualObject } from '@shared-util/is-object';

import { PageEnum } from '@enum/page.enum';
import { ClientsModelType } from '@model/clients-model/clients-model.type';
import { useClientsActionsSelector } from '@selector/clients/use-clients.actions-selector';
import { useClientsSelector } from '@selector/clients/use-clients.selector';

import { ClientsTableEnum } from './clients-table.enum';

interface ClientTableInterface {
    isLoading: boolean;
    pageSize: number;
    currentPage: number;
    filters: Record<string, any>;
    onSearch: OnEventType<string>;
    onPageChange: OnEventType<number>;
    onStatusChange: OnEventType<UserActivatedStatus | string | null>;
    onTypeChange: OnEventType<UserType | string | null>;
    onCreatedFromChange: OnEventType<Date | string | null>;
    onCreatedToChange: OnEventType<Date | string | null>;
    onCountryChange: OnEventType<string | null>;
    totalClientsCount: number;
    limitRowsPerPage: number;
    clients: Instance<typeof ClientsModelType>[];
    tableRef: MutableRefObject<MutableRefObject<HTMLTableElement> | undefined>;
    onResetFilters: noop;
}

const FIRST_INDEX = 0;
const LIMIT_ROWS_PER_PAGE = 20;

export const useClientsTable = (): ClientTableInterface => {
    const navigate = useRouterNavigate();

    const [{ totalCount, clients }, isLoading] = useClientsSelector();
    const { loadClients } = useClientsActionsSelector();

    const tableRef = useRef<MutableRefObject<HTMLTableElement>>();

    const {
        searchParams: { status, type, createdFrom, createdTo, country },
    } = useCurrentNavigation();

    const [
        filters,
        {
            onPageChange,
            onSearch,
            onStatusChange,
            onTypeChange,
            onCreatedFromChange,
            onCountryChange,
            onCreatedToChange,
        },
        { pageSize, currentPage, isDebounce },
    ] = useFiltersCompose(
        usePaginationFilter({ total: totalCount, limit: LIMIT_ROWS_PER_PAGE }),
        useSearchFilter(),
        useSortFilter({ updatedAt: SortEnum.desc }),
        useStatusFilter<UserActivatedStatus | string>(status === ClientsTableEnum.All ? null : status),
        useTypeFilter<UserType | string>(type === ClientsTableEnum.All ? null : type),
        useCreatedFromFilter<Date | string>(isTrue(createdFrom) ? createdFrom : null),
        useCreatedToFilter<Date | string>(isTrue(createdTo) ? createdTo : null),
        useCountryFilter<string>(country === ClientsTableEnum.All ? null : country)
    );

    const previousFilters = usePrevious(filters, !isLoading);

    useEffect(() => {
        if (!isDeepEqualObject(previousFilters, filters)) {
            loadClients({
                ...filters,
                createdFrom: isTrue(filters.createdFrom) ? parse(filters.createdFrom, 'dd-MM-yyyy', new Date()) : null,
                createdTo: isTrue(filters.createdTo) ? parse(filters.createdTo, 'dd-MM-yyyy', new Date()) : null,
            });
        }
    }, [filters, isLoading]);

    useEffect(
        () => void onPageChange(FIRST_INDEX),
        [filters.status, filters.type, filters.createdFrom, filters.createdTo, filters.country]
    );

    const onResetFilters = useCallback(() => {
        if (
            isTrue(filters.type) ||
            isTrue(filters.status) ||
            isTrue(filters.createdFrom) ||
            isTrue(filters.createdTo) ||
            isTrue(filters.country)
        ) {
            navigate(PageEnum.StatusClients, {
                ...(isTrue(filters.type) && { type: 'All' }),
                ...(isTrue(filters.status) && { status: 'All' }),
                ...(isTrue(filters.createdFrom) && { createdFrom: null }),
                ...(isTrue(filters.createdTo) && { createdTo: null }),
                ...(isTrue(filters.country) && { country: 'All' }),
            });
        }
        if (isTrue(filters.search)) {
            onSearch('');
        }
    }, [filters.status, filters.type, filters.search, filters.createdFrom, filters.createdTo, filters.country]);

    return {
        isLoading: isLoading || isDebounce,
        pageSize,
        currentPage,
        filters,
        onSearch,
        onPageChange,
        onStatusChange,
        onTypeChange,
        onCreatedFromChange,
        onCreatedToChange,
        onCountryChange,
        totalClientsCount: totalCount,
        limitRowsPerPage: LIMIT_ROWS_PER_PAGE,
        clients,
        tableRef,
        onResetFilters,
    };
};
