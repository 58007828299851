import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { CellHoveredText } from '@shared-component/table/table-cell/table-cell.styles';
import { Payment } from '@shared-graphql';

export const TypeCell = ({ type }: Payment) => (
    <CellHoveredText>
        <Tooltip content={`${type}`} dataFor={`tooltip-for-transaction-payment-type-${type}`}>
            {type}
        </Tooltip>
    </CellHoveredText>
);
