import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { FC, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { DayPickerProps } from 'react-day-picker';
import 'react-datepicker/dist/react-datepicker.css';

import { FieldError } from '@shared-component/field-error/field-error';
import { InputProps } from '@shared-component/input/input';
import { InputTypeEnum } from '@shared-component/input/input-type.enum';
import { Label, LabelWrapper } from '@shared-component/input/input.styles';
import { useOutsideClick } from '@shared-hook/utils/use-outside-click';
import { LangKeysEnum } from '@shared-store/store-localization.interface';
import { isExist } from '@shared-util/is-data';

import { DateFormatMap, DateLocaleMaskFormatMap } from './input-date-format.helper';
import { DateIcon, DateMaskedStyle, InputDateWrapper, ErrorWrapper } from './input-date.styles';

export interface InputDateProps
    extends Pick<InputProps, 'title' | 'name'>,
    Omit<DayPickerProps, 'title' | 'name' | 'onChange'> {
    locale?: LangKeysEnum;
}

export const InputDate: FC<InputDateProps> = observer(({ title, name, locale = LangKeysEnum.EN, ...props }) => {
    const [fieldDate, { error }, { setValue }] = useField<Date | ''>(name);
    const [isCalendarOpened, setCalendarOpened] = useState(false);
    const wrapperRef = useRef<HTMLDivElement | null>(null);

    const hasValue = isExist(fieldDate.value);
    const hasError = isExist(error);
    const handleFocus = (isFocused: boolean) => () => isFocused && !isCalendarOpened && setCalendarOpened(true);

    useOutsideClick(wrapperRef, () => setCalendarOpened(false));
    return (
        <InputDateWrapper ref={wrapperRef}>
            <LabelWrapper hasError={hasError} isFocused={isCalendarOpened}>
                <DatePicker
                    selected={typeof fieldDate.value === 'string' ? null : fieldDate.value}
                    onChange={(date: Date) => setValue(date ?? '')}
                    maxDate={new Date()}
                    dateFormat={DateFormatMap[locale]}
                    onFocus={handleFocus(true)}
                    onBlur={handleFocus(false)}
                    customInput={
                        <DateMaskedStyle
                            type={InputTypeEnum.Text}
                            mask={DateLocaleMaskFormatMap[locale]}
                            keepCharPositions
                            guide={false}
                            showMask={false}
                            placeholder={DateFormatMap[locale]}
                            autoComplete="off"
                            {...props}
                        />
                    }
                />
                <DateIcon />
                <Label hasValue={hasValue} isFocused={isCalendarOpened} hasError={hasError}>
                    {title}
                </Label>
            </LabelWrapper>
            <ErrorWrapper>
                <FieldError name={name} />
            </ErrorWrapper>
        </InputDateWrapper>
    );
});
