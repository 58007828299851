import styled from 'styled-components';

import Copy from '@shared-asset/copy.svg?react';
import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

interface RowParagraphInterface {
    isCopyEnabled?: boolean;
}

export const RowParagraph = styled.p<RowParagraphInterface>`
    padding: 8px 0;
    ${Flex.VerticalCenter.Style};
    ${Typography.Paragraph14.SemiBold.Neutral700.Style};
    cursor: ${({ isCopyEnabled = false }) => (isCopyEnabled ? 'pointer' : 'default')};
    a,
    span {
        ${Typography.Paragraph14.SemiBold.Neutral700.Style}
    }
`;

export const RowTitle = styled(RowParagraph)`
    ${Typography.Paragraph14.Normal.Neutral500.Style}
`;

export const SummaryGroup = styled.div`
    ${Flex.Column.Style};
    gap: 12px;
`;
export const SummaryGroupTitle = styled.h3`
    ${Typography.Paragraph16.Bold.Neutral900.Style}
`;

export const RowBlock = styled.div`
    padding: 8px 0;
    ${Flex.VerticalCenter.Style};
    ${Typography.Paragraph14.SemiBold.Neutral700.Style}
    a, span {
        ${Typography.Paragraph14.SemiBold.Neutral700.Style}
    }
`;

export const RowActionWrapper = styled.div`
    ${Flex.HorizontalCenter.Style}
`;
export const CopyIcon = styled(Copy)`
    width: 30px;
    height: 25px;
    margin-right: 10px;
`;
