import { ReactNode, useContext } from 'react';

import { ModelContext } from '@shared-component/modal/modal-context';
import { ButtonCloseGray, CrossIcon, ModalFullCloseWrapper } from '@shared-component/modal/modal.styles';

import { RegistrationLegalPrivateCommonModalWrapper } from './registration-legal-person-common.modal-styles';

type RegistrationLegalPersonCommonWrapperType = {
    children?: ReactNode | ReactNode[];
};

export const RegistrationLegalPersonCommonWrapper = ({ children }: RegistrationLegalPersonCommonWrapperType) => {
    const { onClose } = useContext(ModelContext);

    return (
        <RegistrationLegalPrivateCommonModalWrapper>
            {children}
            <ModalFullCloseWrapper>
                <ButtonCloseGray onClick={onClose}>
                    <CrossIcon />
                </ButtonCloseGray>
            </ModalFullCloseWrapper>
        </RegistrationLegalPrivateCommonModalWrapper>
    );
};
