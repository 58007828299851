import { ReactNode } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import {
    ButtonEdit,
    CommissionItem,
    CommissionTitle,
} from '@page/commission-profile/commission-profile-info/commission-profile-info.styles';

interface CommissionProfilePaymentItemProps {
    title: LocalizationEnum;
    onEdit: OnEventEmptyType;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const CommissionProfilePaymentItem = ({ title, onEdit, children }: CommissionProfilePaymentItemProps) => (
    <CommissionItem>
        <CommissionTitle hasCommission>
            <Translate langKey={title} />
            <ButtonTransparent onClick={onEdit}>
                <ButtonEdit>Edit</ButtonEdit>
            </ButtonTransparent>
        </CommissionTitle>
        {children}
    </CommissionItem>
);
