import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';

import { UserDocumentStatus } from '@shared-graphql';
import { isExist } from '@shared-util/is-data';

import { ClientDocumentArchiveTable } from '@page/common/client-document-archive-table/client-document-archive-table';
import { usePrivateClientDocuments } from '@page/private-client/private-client-documents/private-client-documents-active/private-client-documents.hook';

import { ArchiveWrapper, TableWrapper } from './private-client-document-archive-table.styles';

export const PrivateClientDocumentArchiveTable = observer(() => {
    const documentForm = usePrivateClientDocuments();
    const documents = toJS(documentForm.uploadedFiles).filter(
        (file: any) => file.status === UserDocumentStatus.Deleted || isExist(file.prevFile)
    );

    return (
        <ArchiveWrapper>
            <TableWrapper>
                <ClientDocumentArchiveTable documents={documents as any} />
            </TableWrapper>
        </ArchiveWrapper>
    );
});
