import styled from 'styled-components';

import { Black, DarkGrey } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const RowParagraph = styled.p`
    word-break: break-word;
    color: ${Black};
    ${Flex.VerticalCenter.Style}
`;

export const RowTitle = styled(RowParagraph)`
    color: ${DarkGrey};
`;

export const RowBlock = styled.div``;

export const RowActionWrapper = styled.div`
    ${Flex.HorizontalCenter.Style}
`;
