import { Badge } from '@shared-component/badge';
import { BadgeSizeEnum, BadgeTypeEnum } from '@shared-component/badge/types';
import { ElcoinPaymentStatus, Transaction } from '@shared-graphql';

const elcoinStatusToBadgeTypeMapping = {
    [ElcoinPaymentStatus.COMPLETED]: BadgeTypeEnum.SUCCESS_FILLED,

    [ElcoinPaymentStatus.ACCEPTED]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.AML]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.LIQUIDITY_TRANSFER]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.MANUAL_NOSTRO]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.MANUAL_PROCESSING]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.NEW]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.NOSTRO]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.NOSTRO_MANUAL]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.NOT_IDENTIFIED]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.PREPARE]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.READY]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.SENT]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.SENT_ACK]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.SENT_NACK]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.SENT_NACK_NOT_SENT]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.SENT_N_A]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.VALUED]: BadgeTypeEnum.WARNING_FILLED,
    [ElcoinPaymentStatus.VTRN]: BadgeTypeEnum.WARNING_FILLED,

    [ElcoinPaymentStatus.CANCELLED]: BadgeTypeEnum.NEUTRAL_GHOST,

    [ElcoinPaymentStatus.INVALID]: BadgeTypeEnum.ERROR_FILLED,
    [ElcoinPaymentStatus.READY_ERROR]: BadgeTypeEnum.ERROR_FILLED,
    [ElcoinPaymentStatus.ABORT_PROCESSING]: BadgeTypeEnum.ERROR_FILLED,
    [ElcoinPaymentStatus.REJECTED]: BadgeTypeEnum.ERROR_FILLED,
    [ElcoinPaymentStatus.RETURNED]: BadgeTypeEnum.ERROR_FILLED,
};

export const ElcoinStatusCell = ({ elcoinStatus }: Transaction) => (
    <Badge type={elcoinStatusToBadgeTypeMapping[elcoinStatus]} size={BadgeSizeEnum.S} title={elcoinStatus} />
);
