import { useField } from 'formik';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

import { FieldError } from '@shared-component/field-error/field-error';
import { getAmount } from '@shared-component/input/input-amount/input-amount.util';
import { ErrorWrapper, InputWrapper, Label, LabelWrapper } from '@shared-component/input/input.styles';
import { isNumber, isString } from '@shared-util/is-data';

import { InputAmountView } from './input-amount.styles';

interface InputAmountProps extends Partial<Pick<HTMLInputElement, 'type' | 'tabIndex' | 'disabled'>> {
    name: string;
    title: string;
}

export const InputAmount = observer(({ name, title, disabled }: InputAmountProps) => {
    const [{ value: amount }, { error }, { setError, setValue }] = useField(name);
    const [isFocused, setFocused] = useState(false);
    const [amountValue, setAmountValue] = useState(amount);

    const value = isNumber(amount) ? amount.toFixed(2) : undefined;

    const hasValue = value !== undefined;
    const hasError = error !== undefined;
    const shouldRenderLabel = isString(title);

    const toggleFocus = (isFocus: boolean) => () => setFocused(isFocus);

    const handleChange = (newValue = '0') => {
        setValue(isString(newValue) ? getAmount(newValue) : undefined);
        setError(undefined);
        setAmountValue(newValue);
    };

    useEffect(() => {
        const amountValueNum = getAmount(`${amountValue ?? 0}`);

        if (amountValueNum !== amount && hasValue) {
            setAmountValue(value);
        }
    }, [amountValue, amount]);

    return (
        <InputWrapper>
            <LabelWrapper hasError={hasError} isFocused={isFocused} isDisabled={disabled}>
                <InputAmountView
                    name={name}
                    title={title}
                    value={amountValue}
                    decimalsLimit={2}
                    onFocus={toggleFocus(true)}
                    onBlur={toggleFocus(false)}
                    onValueChange={handleChange}
                />
                {shouldRenderLabel && (
                    <Label hasValue={hasValue} isFocused={isFocused} hasError={hasError}>
                        {title}
                    </Label>
                )}
            </LabelWrapper>
            <ErrorWrapper>
                <FieldError name={name} />
            </ErrorWrapper>
        </InputWrapper>
    );
});
