import { applySnapshot, flow, Instance } from 'mobx-state-tree';

import { AdminGetPrivateClient, Query, UserDocument, UserDocumentType } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { PrivateClientModel, PrivateClientModelType } from '@model/private-client-model/private-client-model';
import { initialPrivateClient } from '@model/private-client-model/private-client-model-initials/private-client-model.initial';

const queryClient = (userId: string) =>
    apolloClient
        .query<Pick<Query, 'adminGetPrivateClient'>>({ query: AdminGetPrivateClient, variables: { userId } })
        .then(result => result.data.adminGetPrivateClient);

const REQUIRED_DOCUMENT_TYPES = new Set([
    UserDocumentType.Selfie,
    UserDocumentType.Passport,
    UserDocumentType.Location,
]);

export const privateClientMainActions = (self: Instance<typeof PrivateClientModelType>) => ({
    setLoading: (loading: boolean) => {
        self.loading.isLoading = loading;
    },
    setCommentLoading: (loading: boolean) => {
        self.loading.isLoadingComment = loading;
    },
    setClientAccept: () => {
        self.client.privateStatus.hasAccept = true;
    },
    setClientAcceptAgreement: (isAcceptAgreement: boolean) => {
        self.client.isAcceptAgreement = isAcceptAgreement;
    },
    setClientHasDocuments: (hasDocument: boolean) => {
        self.client.privateStatus.hasDocument = hasDocument;
    },
    setClientDocuments: (documents: Array<UserDocument>) => {
        applySnapshot(self.client.documents, documents);

        const hasAllDocuments =
            documents.filter(doc => REQUIRED_DOCUMENT_TYPES.has(doc.type)).length === REQUIRED_DOCUMENT_TYPES.size;

        (self as unknown as ReturnType<typeof privateClientMainActions>).setClientHasDocuments(hasAllDocuments);
    },
    loadClient: flow(function* loadClient(userId: string) {
        if (self.loading.isLoading || self.client.id === userId) {
            return;
        }

        (self as Instance<typeof PrivateClientModel>).setLoading(true);

        applySnapshot(self.client, initialPrivateClient);

        try {
            const client = yield queryClient(userId);

            if (client.profile.dateBirthday === 0) {
                client.profile.dateBirthday = null;
            }

            applySnapshot(self.client, client);
        } catch (error) {
            throw new Error(error as string);
        } finally {
            (self as Instance<typeof PrivateClientModel>).setLoading(false);
        }
    }),
});
