import styled from 'styled-components';

import { Dark, White } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const ContentWrapper = styled.div`
    margin-top: 24px;
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    flex-direction: row;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
`;

export const EmptyContentWrapper = styled.div`
    margin-top: 24px;
    min-height: 96px;
    width: 100%;

    ${Flex.HorizontalCenter.VerticalCenter.Style}
`;
