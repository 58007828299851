import React, { FC, memo, SVGProps } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { BadgeComponent, BadgeIcon, BadgeTitle } from '@shared-component/badge/style';
import { BadgeProps } from '@shared-component/badge/types';

export const Badge = memo(({ title, size, type, iconAfter = null, iconBefore = null }: BadgeProps) => {
    const IconComponentBefore = (iconBefore || null) as FC<SVGProps<SVGSVGElement>>;
    const IconComponentAfter = (iconAfter || null) as FC<SVGProps<SVGSVGElement>>;

    return (
        <BadgeComponent size={size} typeStyle={type}>
            {iconBefore !== null && (
                <BadgeIcon size={size} isBefore>
                    <IconComponentBefore />
                </BadgeIcon>
            )}
            <BadgeTitle size={size}>
                <Translate langKey={title} />
            </BadgeTitle>
            {iconAfter !== null && (
                <BadgeIcon size={size}>
                    <IconComponentAfter />
                </BadgeIcon>
            )}
        </BadgeComponent>
    );
});
