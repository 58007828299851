import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import { useContext } from 'react';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { Select } from '@shared-component/select/select';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { useAccountAddForm } from '@page/accounts/accounts-add-form/accounts-add-form.hook';
import { AccountsAddFormInterface } from '@page/accounts/accounts-add-form/accounts-add-form.interface';

import { AccountsAddFormEnum } from './accounts-add-form-enum';
import { initialValues } from './accounts-add-form.initial';
import { accountTypeOptions } from './accounts-add-form.options';
import { FormInnerWrapper } from './accounts-add-form.styles';
import { AccountsAddFormValidation } from './accounts-add-form.validation';
import { ButtonTitle, StatementIcon } from './dashboard-transactions.styles';

export const AccountAddForm = observer(
    ({ isIfxMode, handleAccountChanged, onAssignCredentials }: AccountsAddFormInterface) => {
        const { onOpen } = useContext(ModelContext);

        const { currenciesOptions, handleSubmit } = useAccountAddForm();
        const { name } = useCurrentNavigation();

        const handleOpenModalStatement = () => {
            const modal = name.includes('private')
                ? ModalEnum.PrivateRequestStatementModal
                : ModalEnum.RequestStatementModal;
            onOpen(modal);
        };

        return (
            <Formik
                initialValues={initialValues}
                validationSchema={AccountsAddFormValidation}
                onSubmit={handleSubmit}
                validateOnChange={false}
            >
                <Form>
                    <FormInnerWrapper>
                        <Select
                            name={AccountsAddFormEnum.Type}
                            title="Select type"
                            options={accountTypeOptions}
                            onValueChanged={handleAccountChanged}
                        />
                        <Select
                            name={AccountsAddFormEnum.Currency}
                            title="Select currency"
                            options={currenciesOptions}
                        />
                        {isTrue(isIfxMode) ? (
                            <ButtonPrimary isLarge onClick={onAssignCredentials}>
                                Assign credentials
                            </ButtonPrimary>
                        ) : (
                            <ButtonPrimary isLarge type={ButtonTypeEnum.Submit}>
                                Create new account
                            </ButtonPrimary>
                        )}
                        <ButtonTransparent onClick={handleOpenModalStatement}>
                            <StatementIcon />
                            <ButtonTitle>
                                <Translate langKey={LocalizationEnum.DashboardTransactionsButtonTitle} />
                            </ButtonTitle>
                        </ButtonTransparent>
                    </FormInnerWrapper>
                </Form>
            </Formik>
        );
    }
);
