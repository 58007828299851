import { useMemo, useState } from 'react';

import { Maybe } from '@shared-graphql';

export const useCreatedFromFilter = <T>(initialType: Maybe<T> = null) => {
    const [createdFrom, setCreatedFrom] = useState<Maybe<T>>(initialType);

    const onCreatedFromChange = (newCreatedFrom: T) => setCreatedFrom(newCreatedFrom);

    return useMemo(() => [{ createdFrom }, { onCreatedFromChange }, {}], [createdFrom]);
};
