import { types } from 'mobx-state-tree';

import { PaymentEntityType } from '@shared-graphql';

import { BeneficiaryModelTypeSchema } from './beneficiary-model.type-schema';

export const BeneficiaryModelType = types.model(BeneficiaryModelTypeSchema).views(self => ({
    get beneficiaryName() {
        const { beneficiaryEntityType, beneficiaryCompanyName, beneficiaryFirstName, beneficiaryLastName } = self;

        return beneficiaryEntityType === PaymentEntityType.company
            ? beneficiaryCompanyName
            : `${beneficiaryFirstName} ${beneficiaryLastName}`;
    },
}));
