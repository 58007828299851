import { Mutation, Comment, AddComment, AddFileComment } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';

export const mutationAddComment = (comment: string) =>
    apolloClient
        .mutate<Pick<Mutation, 'addComment'>>({
        mutation: AddComment,
        variables: { comment },
    })
        .then(result => result.data?.addComment as unknown as Comment);

export const mutationAddFileComment = (fileId: string, commentId: string) =>
    apolloClient
        .mutate<Pick<Mutation, 'addFileComment'>>({ mutation: AddFileComment, variables: { fileId, commentId } })
        .then(result => result.data?.addFileComment as unknown as Comment);
