import { useReportUrlGetting } from '@hook/user/report-url-getting';
import { useAccountSelector } from '@selector/account/use-account.selector';

export const useRequestStatement = () => {
    const [, { isLoading: isAccountsLoading }] = useAccountSelector();
    const { isReportUrlLoading, handleSubmit } = useReportUrlGetting();

    return {
        isLoading: isAccountsLoading || isReportUrlLoading,
        handleSubmit,
    };
};
