import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { isString } from '@shared-util/is-data';

import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

export const PrivateClientNameTab = observer(() => {
    const [{ fullName }] = usePrivateClientSelector();
    const userName = useMemo(() => (isString(fullName) ? fullName : 'User'), [fullName]);

    return (
        <>
            {Boolean(userName) === true && userName}
            {Boolean(userName) === false && null}
        </>
    );
});
