import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { ExternalLink } from '@shared-atom/external-link/external-link';
import { Translate } from '@shared-atom/translate/translate';
import { CheckBox } from '@shared-component/checkbox/checkbox';
import { OtpForm } from '@shared-component/otp/otp.form';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useSendPhoneCode } from '@shared-hook/phone-code/send-phone-code.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { CURRENCY_CLOUD_LINK } from '@shared-util/constants';

import { PrivateAcceptAgreementFormEnum } from './private-accept-agreement-form.enum';
import { Text } from './private-accept-agreement-form.styles';

interface PrivateAcceptAgreementProps {
    phone: string;
    isAcceptAgreement: boolean;
    linkTerms: string;
    disabled?: boolean;
}

export const PrivateAcceptAgreementForm: FC<PrivateAcceptAgreementProps> = observer(
    ({ phone, isAcceptAgreement, linkTerms, disabled = false }) => {
        const checkBoxTitle = useLocalizationText(LocalizationEnum.PrivateAcceptAgreementCheckboxTitle);
        const [sendCode] = useSendPhoneCode();

        const handleResendCode = () => sendCode(phone);

        return (
            <>
                {Boolean(isAcceptAgreement) === true && (
                    <OtpForm name={PrivateAcceptAgreementFormEnum.Otp} onResendOtpCode={handleResendCode} />
                )}
                {Boolean(isAcceptAgreement) === false && (
                    <>
                        <CheckBox
                            name={PrivateAcceptAgreementFormEnum.AcceptAgreement}
                            title={checkBoxTitle}
                            disabled={disabled}
                        >
                            <ExternalLink href={linkTerms}>
                                <Text>
                                    <Translate langKey={LocalizationEnum.PrivateAcceptAgreementTitle1} />
                                </Text>
                            </ExternalLink>
                        </CheckBox>
                        <CheckBox
                            name={PrivateAcceptAgreementFormEnum.AcceptAgreementTU}
                            title={checkBoxTitle}
                            disabled={disabled}
                        >
                            <ExternalLink href={CURRENCY_CLOUD_LINK}>
                                <Text>
                                    <Translate langKey={LocalizationEnum.PrivateAcceptAgreementTitle2} />
                                </Text>
                            </ExternalLink>
                        </CheckBox>
                    </>
                )}
            </>
        );
    }
);
