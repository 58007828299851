import { useMemo } from 'react';

export const useFiltersCompose = <F = any, A = any, M = any, T extends any[] = any[]>(...components: T) => {
    const result = useMemo(
        () =>
            components.reduce(
                (acc, [currentFilters, currentActions, currentMeta]) => {
                    const filters = { ...acc.filters, ...currentFilters };
                    const actions = { ...acc.actions, ...currentActions };
                    const meta = { ...acc.meta, ...currentMeta };

                    return { filters, actions, meta };
                },
                {
                    filters: {} as F,
                    actions: {} as A,
                    meta: {} as M,
                }
            ),
        components
    );

    return [result.filters, result.actions, result.meta];
};
