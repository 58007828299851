import { object, string, boolean } from 'yup';

import { LegalCompanyFormEnum } from './legal-company-form.enum';

const LegalCompanyValidations = {
    [LegalCompanyFormEnum.CompanyName]: string().required().min(2).max(100),
    [LegalCompanyFormEnum.RegistrationCountry]: string().required(),
    [LegalCompanyFormEnum.CompanyNumber]: string().required(),
    [LegalCompanyFormEnum.CompanyTaxNumber]: string(),
    [LegalCompanyFormEnum.CompanySite]: string().notRequired().nullable(),
    [LegalCompanyFormEnum.RegistrationDate]: string().required(),
    [LegalCompanyFormEnum.ConductBusiness]: boolean(),
    [LegalCompanyFormEnum.GroupPart]: boolean(),
};

export const LegalCompanyFormValidation = object().shape(LegalCompanyValidations);

export const LegalCompanyPersonFormValidation = object().shape({
    ...LegalCompanyValidations,
    [LegalCompanyFormEnum.OwnershipPercent]: string()
        .required()
        .test('is-min', 'Value must be more then 0', value => parseInt(value ?? '', 10) >= 0)
        .test('is-max', 'Value must be less then 100', value => parseInt(value ?? '', 10) <= 10000),
});
