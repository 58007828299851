import { Instance } from 'mobx-state-tree';

import { UserModelTypeInstance } from '@model/users-model/user-model.type';
import { useStore } from '@store/store-context';

export const useUserSelector = (): [Instance<typeof UserModelTypeInstance>, boolean, null | string] => {
    const { users, loading, error } = useStore().user;

    return [users, loading.isLoading, error];
};
