import { FC, useMemo } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ExternalLink } from '@shared-atom/external-link/external-link';
import { CompanyActivatedStatus } from '@shared-graphql';
import { ClickEventType } from '@shared-type/click-event.type';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

import { useUserEvents } from '@hook/user/user-events.hook';
import { useUserExternal } from '@hook/user/user-external.hook';
import {
    ButtonColor,
    ButtonColorStroke,
    ButtonReject,
    ButtonSendCompliance,
} from '@page/common/summary-buttons/summary-buttons.styles';
import { useCompanySelector } from '@selector/company/use-company.selector';

interface SummaryStatusButtonsProps {
    handleAction: OnEventType<CompanyActivatedStatus, OnEventEmptyType>;
    status: CompanyActivatedStatus;
    isApplicationProfileCompleted: boolean;
    getIbanqCallbackUrl: ClickEventType;
}

const REJECT_BUTTON_VISIBLE_STATUSES = new Set([
    CompanyActivatedStatus.Compliance,
    CompanyActivatedStatus.Created,
    CompanyActivatedStatus.Updated,
]);

export const SummaryStatusButtons: FC<SummaryStatusButtonsProps> = ({
    handleAction,
    status,
    isApplicationProfileCompleted,
    getIbanqCallbackUrl,
}) => {
    const [
        {
            owner: { email },
            id,
        },
    ] = useCompanySelector();
    const hasStatusApproved = useMemo(() => CompanyActivatedStatus.Approved === status, [status]);
    const hasStatusActivated = useMemo(() => CompanyActivatedStatus.Activated === status, [status]);
    const hasStatusCompliance = useMemo(() => CompanyActivatedStatus.Compliance === status, [status]);
    const hasStatusBlocked = useMemo(() => CompanyActivatedStatus.Blocked === status, [status]);
    const shouldRenderBackOnboardingButton = useMemo(
        () => hasStatusBlocked && isApplicationProfileCompleted,
        [hasStatusBlocked, isApplicationProfileCompleted]
    );
    const shouldRenderBackRegistrationButton = useMemo(
        () => hasStatusBlocked && !isApplicationProfileCompleted,
        [hasStatusBlocked, isApplicationProfileCompleted]
    );

    const shouldRenderRejectButton = useMemo(() => REJECT_BUTTON_VISIBLE_STATUSES.has(status), [status]);
    const [handleResetPassword] = useUserEvents(email);
    const [externalApplicantLink] = useUserExternal(id);

    return (
        <>
            {hasStatusApproved && (
                <ButtonTransparent onClick={handleAction(CompanyActivatedStatus.Activated)} isSmall>
                    <ButtonColor>Activate Company</ButtonColor>
                </ButtonTransparent>
            )}

            {hasStatusCompliance && (
                <ButtonTransparent onClick={handleAction(CompanyActivatedStatus.Approved)} isSmall>
                    <ButtonColor>Approve Company</ButtonColor>
                </ButtonTransparent>
            )}

            <ButtonTransparent onClick={getIbanqCallbackUrl} isSmall>
                <ButtonColor>Generate callback URL</ButtonColor>
            </ButtonTransparent>

            {isExist(externalApplicantLink) && (
                <ButtonTransparent isSmall>
                    <ExternalLink href={externalApplicantLink}>
                        <ButtonColorStroke>Edit profile</ButtonColorStroke>
                    </ExternalLink>
                </ButtonTransparent>
            )}

            <ButtonTransparent onClick={handleResetPassword} isSmall>
                <ButtonColorStroke>Reset password</ButtonColorStroke>
            </ButtonTransparent>

            {shouldRenderBackRegistrationButton && (
                <ButtonTransparent onClick={handleAction(CompanyActivatedStatus.Created)} isSmall>
                    <ButtonSendCompliance>Back to Registration</ButtonSendCompliance>
                </ButtonTransparent>
            )}
            {shouldRenderBackOnboardingButton && (
                <ButtonTransparent onClick={handleAction(CompanyActivatedStatus.Updated)} isSmall>
                    <ButtonSendCompliance>Back to Onboarding</ButtonSendCompliance>
                </ButtonTransparent>
            )}

            {(hasStatusApproved || hasStatusActivated) && (
                <ButtonTransparent onClick={handleAction(CompanyActivatedStatus.Blocked)} isSmall>
                    <ButtonReject>Block</ButtonReject>
                </ButtonTransparent>
            )}

            {shouldRenderRejectButton && (
                <ButtonTransparent onClick={handleAction(CompanyActivatedStatus.Rejected)} isSmall>
                    <ButtonReject>Reject</ButtonReject>
                </ButtonTransparent>
            )}
        </>
    );
};
