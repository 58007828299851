import { Column } from 'react-table';

import { Tooltip } from '@shared-atom/tooltip/tooltip';
import { TableHeaderCellTitle, TableHeaderSortingIcon } from '@shared-component/table/table-head/table-head.styles';
import { Transaction } from '@shared-graphql';

export const TransactionsTableOptions: Column<Transaction>[] = [
    {
        Header: (
            <>
                <TableHeaderCellTitle>Payment ID</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-transactionID-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'id',
        width: 1.4,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Client name</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-client-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'client',
        width: 2,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Beneficiary name</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-beneficiary-companyName-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'beneficiary.companyName',
        width: 2,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Type</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-paymentType-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'type',
        width: 1,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Elcoin Status</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-paymentStatus-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'elcoinStatus',
        width: 1.6,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Date</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-paymentCreationDate-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'date',
        width: 1.3,
    },
    {
        Header: (
            <>
                <TableHeaderCellTitle>Amount</TableHeaderCellTitle>
                <Tooltip content="Sorting will be available soon" dataFor="sorting-for-paymentAmount-tooltip">
                    <TableHeaderSortingIcon />
                </Tooltip>
            </>
        ),
        id: 'amount',
        width: 1.2,
    },
    {
        id: 'createdAt',
        width: 0.5,
    },
];
