import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { OperationType } from '@shared-graphql';

export const operationTypes = $enum(OperationType).getValues();

export const PaymentFeeModelType = types.model({
    amount: types.number,
    total: types.number,
});
