import { useFormikContext } from 'formik';

import { RegistrationLegalPersonPrivateProfileFormBeneficiary } from './registration-legal-person-private-profile-form-beneficiary/registration-legal-person-private-profile-form-beneficiary';
import { RegistrationLegalPersonPrivateProfileFormDirector } from './registration-legal-person-private-profile-form-director/registration-legal-person-private-profile-form-director';
import { RegistrationLegalPersonPrivateProfileFormOwnership } from './registration-legal-person-private-profile-form-ownership/registration-legal-person-private-profile-form-ownership';
import { RegistrationLegalPersonPrivateProfileFormUserCreate } from './registration-legal-person-private-profile-form-user-create/registration-legal-person-private-profile-form-user-create';
import { RelationPersonInterface } from './registration-legal-person-private-profile-form.interface';

interface RegistrationLegalPersonPrivateProfileFormInterface {
    isCurrentUser?: boolean;
}

export const RegistrationLegalPersonPrivateProfileForm = ({
    isCurrentUser = false,
}: RegistrationLegalPersonPrivateProfileFormInterface) => {
    const {
        initialValues: { ownershipPercent, beneficiaryPercent },
    } = useFormikContext<RelationPersonInterface>();

    return (
        <>
            <RegistrationLegalPersonPrivateProfileFormOwnership initialOwnershipPercent={ownershipPercent} />
            <RegistrationLegalPersonPrivateProfileFormBeneficiary initialBeneficiaryPercent={beneficiaryPercent} />
            <RegistrationLegalPersonPrivateProfileFormDirector />
            <RegistrationLegalPersonPrivateProfileFormUserCreate isCurrentUser={isCurrentUser} />
        </>
    );
};
