import { useField } from 'formik';
import { useEffect } from 'react';

import { FormWrapper } from '@shared-form/form-common/form-common.styles';
import { RegistrationLegalPersonPrivateProfileForm } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form';
import { RegistrationLegalPersonPrivateProfileFormEnum } from '@shared-form/registration-legal-person-private-profile-form/registration-legal-person-private-profile-form.enum';

interface RegistrationLegalPersonPrivateProfileWrapperProps {
    isCurrentUser?: boolean;
}

export const RegistrationLegalPersonPrivateProfileWrapper = ({
    isCurrentUser,
}: RegistrationLegalPersonPrivateProfileWrapperProps) => {
    const [{ value: isUserCreated }] = useField<boolean>(RegistrationLegalPersonPrivateProfileFormEnum.UserCreate);
    const [{ value: isOwnership }] = useField<boolean>(RegistrationLegalPersonPrivateProfileFormEnum.Ownership);
    const [, , { setError: setErrorOwnershipPercent }] = useField(
        RegistrationLegalPersonPrivateProfileFormEnum.OwnershipPercent
    );
    const [, , { setError: setErrorRole }] = useField(RegistrationLegalPersonPrivateProfileFormEnum.UserRole);
    const [, , { setError: setErrorEmail }] = useField(RegistrationLegalPersonPrivateProfileFormEnum.Email);
    const [, , { setError: setErrorPhone }] = useField(RegistrationLegalPersonPrivateProfileFormEnum.Phone);

    useEffect(() => {
        setErrorRole(undefined);
        setErrorEmail(undefined);
        setErrorPhone(undefined);
    }, [isUserCreated]);

    useEffect(() => {
        setErrorOwnershipPercent(undefined);
    }, [isOwnership]);

    return (
        <FormWrapper>
            <RegistrationLegalPersonPrivateProfileForm isCurrentUser={isCurrentUser} />
        </FormWrapper>
    );
};
