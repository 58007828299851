import React, { useMemo } from 'react';
import { v4 } from 'uuid';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { useTablePagination } from '@shared-component/table/table-pagination/table-pagination.hook';
import {
    PaginationContainer,
    PaginationButtonsWrapper,
    PaginationInfoWrapper,
    ButtonContentWrapper,
    ArrowRightIcon,
    ArrowLeftIcon,
    PaginationContentWrapper,
} from '@shared-component/table/table-pagination/table-pagination.styles';
import { TableHookInterface } from '@shared-component/table/table.interface';
import { isTrue } from '@shared-util/is-data';

export interface TablePaginationProps<T extends Record<string, unknown>> {
    tableInstanceState: TableHookInterface<T>;
}

export const TablePagination = <T extends Record<string, unknown>>({
    tableInstanceState: {
        previousPage,
        nextPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        state,
        totalData,
        limitRowsPerPage,
    },
}: TablePaginationProps<T>) => {
    const { pageIndex } = state;
    const paginationInfoText = useMemo(() => {
        if (isTrue(totalData) && isTrue(limitRowsPerPage)) {
            const startIndex = pageIndex * (limitRowsPerPage as number) + 1;
            const endIndex = Math.min((pageIndex + 1) * (limitRowsPerPage as number), totalData as number);

            return `Showing ${startIndex} - ${endIndex} of ${totalData} items`;
        }
        return `Page ${pageIndex + 1} of ${pageCount}`;
    }, [totalData, limitRowsPerPage, pageIndex]);

    const paginationButtons = useTablePagination(pageIndex, pageCount);
    const handleGotoPageClick = (pageKey: number) => () => gotoPage(pageKey);

    return (
        <PaginationContainer>
            <PaginationContentWrapper>
                <ButtonTransparent type={ButtonTypeEnum.Button} onClick={previousPage} isDisabled={!canPreviousPage}>
                    <ButtonContentWrapper isDisabled={!canPreviousPage}>
                        <ArrowLeftIcon />
                    </ButtonContentWrapper>
                </ButtonTransparent>
                <PaginationButtonsWrapper>
                    {paginationButtons.map(index => {
                        if (index === null) return <ButtonContentWrapper key={v4()}>...</ButtonContentWrapper>;

                        return (
                            <ButtonTransparent
                                key={v4()}
                                type={ButtonTypeEnum.Button}
                                onClick={handleGotoPageClick(index)}
                            >
                                <ButtonContentWrapper isActive={pageIndex === index}>{index + 1}</ButtonContentWrapper>
                            </ButtonTransparent>
                        );
                    })}
                </PaginationButtonsWrapper>
                <ButtonTransparent type={ButtonTypeEnum.Button} onClick={nextPage} isDisabled={!canNextPage}>
                    <ButtonContentWrapper isDisabled={!canNextPage}>
                        <ArrowRightIcon />
                    </ButtonContentWrapper>
                </ButtonTransparent>
            </PaginationContentWrapper>
            <PaginationInfoWrapper>{paginationInfoText}</PaginationInfoWrapper>
        </PaginationContainer>
    );
};
