import { observer } from 'mobx-react-lite';

import { EmptyContentWrapper } from '@shared-component/accounts-list/accounts-list.styles';
import { AnimationLoading } from '@shared-component/animation/animation-loading/animation-loading';
import { FeatureStatus } from '@shared-graphql';

import { ClientServicesHeading } from '@page/common/client-services/client-services-heading/client-services-heading';
import { ClientServicesList } from '@page/common/client-services/client-services-list/client-services-list';
import { useClientServices } from '@page/common/client-services/client-services.hook';
import { ClientServicesInterface } from '@page/common/client-services/client-services.interface';
import { BlockWrapper } from '@page/common/client-services/client-services.styles';

export const ClientServices = observer(({ userType }: ClientServicesInterface) => {
    const {
        isLoading,
        featureTags,
        isRejectFeatureTagLoading,
        isNextStatusFeatureTagLoading,
        onRejectFeatureTag,
        onNextStatusFeatureTag,
        onSetRequiredFields,
        adminGetClientFeatureTags,
    } = useClientServices();

    if (isLoading) {
        return (
            <EmptyContentWrapper>
                <AnimationLoading />
            </EmptyContentWrapper>
        );
    }

    return (
        <BlockWrapper>
            <ClientServicesHeading
                userType={userType}
                featureTags={featureTags as FeatureStatus[]}
                adminGetClientFeatureTags={adminGetClientFeatureTags}
            />
            <ClientServicesList
                featureTags={featureTags as FeatureStatus[]}
                onRejectFeatureTag={onRejectFeatureTag}
                onNextStatusFeatureTag={onNextStatusFeatureTag}
                onSetRequiredFields={onSetRequiredFields}
                isRejectFeatureTagLoading={isRejectFeatureTagLoading}
                isNextStatusFeatureTagLoading={isNextStatusFeatureTagLoading}
            />
        </BlockWrapper>
    );
});
