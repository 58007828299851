import { Form, Formik } from 'formik';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { Translate } from '@shared-atom/translate/translate';
import { Input } from '@shared-component/input/input';
import { FormRow } from '@shared-form/form-common/form-common.styles';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { RejectPaymentFormEnum } from '@component/modal/modals/reject-payment/reject-payment-form/reject-payment-form.enum';
import { useRejectPaymentForm } from '@component/modal/modals/reject-payment/reject-payment-form/reject-payment-form.hook';
import { RejectPaymentFormValidation } from '@component/modal/modals/reject-payment/reject-payment-form/reject-payment-form.validation';
import {
    RejectPaymentTitle,
    ButtonSubmitWrapper,
} from '@component/modal/modals/reject-payment/reject-payment-form/reject-payment.styles';

export const RejectPaymentForm = () => {
    const { initialValues, handleSubmit } = useRejectPaymentForm();

    return (
        <>
            <RejectPaymentTitle>Indicate the reason for the rejection of the transaction</RejectPaymentTitle>
            <Formik
                initialValues={initialValues}
                validationSchema={RejectPaymentFormValidation}
                onSubmit={handleSubmit}
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
            >
                <Form>
                    <FormRow>
                        <Input name={RejectPaymentFormEnum.Message} title="Type the reason here" />
                    </FormRow>
                    <ButtonSubmitWrapper>
                        <ButtonPrimary type={ButtonTypeEnum.Submit}>
                            <Translate langKey={LocalizationEnum.CommonSend} />
                        </ButtonPrimary>
                    </ButtonSubmitWrapper>
                </Form>
            </Formik>
        </>
    );
};
