import { Link } from '@shared-atom/link/link';
import { UserType } from '@shared-graphql';
import { getPathnameWithParams } from '@shared-util/get-pathname-with-params';

import { PageEnum } from '@enum/page.enum';

import { Wrapper } from './cell.styles';
import { ClientCellType } from './cell.type';

export const ActionsCell = ({ id, type }: ClientCellType) => (
    <Wrapper>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
            to={{
                pathname: getPathnameWithParams(
                    type === UserType.Private ? PageEnum.PrivateClientSummary : PageEnum.LegalClientSummary,
                    { userId: id, companyId: id }
                ),
            }}
        >
            Show client
        </Link>
    </Wrapper>
);
