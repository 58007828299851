import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const LabelError = styled.p`
    margin: 0;
    padding: 8px 0;
    ${Typography.XS.Red.Style}
`;

export const FieldErrorWrapper = styled.div`
    padding: 0 16px;
`;
