import { useState } from 'react';

export const getLocalStorageValue = (key: string) => {
    try {
        const item = window.localStorage.getItem(key);
        if (typeof item === 'string') return item;
        return item !== null ? JSON.parse(item) : null;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const setLocalStorageValue = <T>(key: string, value: T) => {
    try {
        window.localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.log(error);
    }
};

export const removeLocalStorageValue = (key: string) => {
    try {
        window.localStorage.removeItem(key);
    } catch (error) {
        console.log(error);
    }
};

export const useLocalStorage = <T>(key: string, initialValue: T) => {
    const [storedValue, setStoredValue] = useState(() => {
        let item = getLocalStorageValue(key);
        if (item === 'false') {
            item = false;
        } else if (item === 'true') {
            item = true;
        }
        return item ?? initialValue;
    });

    const setValue = (value: T) => {
        const valueToStore = value instanceof Function ? value(storedValue) : value;
        setStoredValue(valueToStore);
        setLocalStorageValue(key, valueToStore);
    };

    return [storedValue, setValue];
};
