import { UserCompanyRelationType } from '@shared-graphql';

import { RegistrationLegalPersonPrivateProfileFormEnum } from './registration-legal-person-private-profile-form.enum';

export const getCompanyRelationByFieldName = (
    name: RegistrationLegalPersonPrivateProfileFormEnum
): UserCompanyRelationType => {
    switch (name) {
        case RegistrationLegalPersonPrivateProfileFormEnum.Ownership: {
            return UserCompanyRelationType.Ownership;
        }
        case RegistrationLegalPersonPrivateProfileFormEnum.Beneficiary: {
            return UserCompanyRelationType.Beneficiary;
        }
        case RegistrationLegalPersonPrivateProfileFormEnum.Director: {
            return UserCompanyRelationType.Director;
        }
        default: {
            throw new Error(`${name} is not includes in UserCompanyRelationType. Relation field cannot be continued.`);
        }
    }
};
