import { flow, Instance } from 'mobx-state-tree';

import { AdminGetClients, Client, ClientResult, Query } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { ClientModelType } from '@model/clients-model/clients-model';
import { initialClientsModel } from '@model/clients-model/clients-model-initials/client-model.initial';

const queryClients = <T>(filters: T): Promise<ClientResult> =>
    apolloClient
        .query<Pick<Query, 'adminGetClients'>>({ query: AdminGetClients, variables: { filters } })
        .then(result => result.data?.adminGetClients ?? { elements: [], totalCount: 0 });

export const clientMainActions = (self: Instance<typeof ClientModelType>) => ({
    setLoading: (loading: boolean) => {
        self.loading.isLoading = loading;
    },
    loadClients: flow(function* loadClients<T>(filters?: T) {
        if (self.loading.isLoading) {
            return;
        }

        (self as any).setLoading(true);

        try {
            const { elements, totalCount } = yield queryClients(filters);

            self.clients.elements.clear();
            self.clients.totalCount = initialClientsModel.totalCount;

            elements.forEach((client: Client) => {
                self.clients.elements.set(client.id, client);
            });
            self.clients.totalCount = totalCount;
        } catch (error) {
            throw new Error(error as string);
        } finally {
            (self as any).setLoading(false);
        }
    }),
});
