import { rgba } from 'polished';
import styled from 'styled-components';

import { Black } from '@shared-style/colors';

export const ScrollView = styled.div`
    z-index: 3;
    cursor: pointer;
    border-radius: inherit;
    background-color: ${rgba(Black, 0.2)};
`;
