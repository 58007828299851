import React, { FC } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { CheckIcon, CopyMessageWrapper, CopyWrapper } from './copy-message.style';

interface CopyMessageProps {
    isVisible: boolean;
    message?: LocalizationEnum;
    children?: React.ReactNode;
}

export const CopyMessage: FC<CopyMessageProps> = ({
    isVisible = false,
    message = LocalizationEnum.CommonCopyClipboard,
    children,
}) => (
    <CopyWrapper>
        <CopyMessageWrapper isVisible={isVisible}>
            <CheckIcon />
            <Translate langKey={message} />
        </CopyMessageWrapper>
        {children}
    </CopyWrapper>
);
