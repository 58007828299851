import { usePrivateDocumentForm } from '@shared-form/private-document-form/private-document-form.hook';
import { UserDocumentType, UserType } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { OnEventEmptyType } from '@shared-type/on-event.type';

import { useCompanySelector } from '@selector/company/use-company.selector';

import {
    CompanyDocumentType,
    DEFAULT_DOCUMENT_LOCALIZATIONS,
    RegistrationLegalDocumentLocalizations,
    personLegalDocumentsChooses,
} from './registration-legal-documents-person-view.options';

const REQUIRED_DOCUMENT_TYPES = new Set([UserDocumentType.Chart]);

export const useRegistrationLegalDocumentsPersonView = (personId: string, onBack: OnEventEmptyType) => {
    const [{ persons }] = useCompanySelector();

    const { person, legal, type } = persons.get(personId) ?? {};

    const documentForm = usePrivateDocumentForm(
        (type === UserType.Legal ? legal : person)?.documents ?? [],
        () => {},
        type === UserType.Legal ? REQUIRED_DOCUMENT_TYPES : undefined
    );

    const [title, description] =
        RegistrationLegalDocumentLocalizations[documentForm.selectedUploadType as CompanyDocumentType] ??
        DEFAULT_DOCUMENT_LOCALIZATIONS;

    const titleText = useLocalizationText(title);

    const handleBack = documentForm.selectedUploadType === null ? onBack : documentForm.handleSelectedUploadType(null);

    const listChooses = type === UserType.Legal ? personLegalDocumentsChooses : undefined;

    return {
        handleBack,
        title: documentForm.selectedUploadType === null ? person?.profile.fullName ?? legal?.companyName : titleText,
        description,
        documentForm,
        listChooses,
    };
};
