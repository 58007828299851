import React, { memo } from 'react';

import InfoIcon from '../../../assets/info.svg?react';
import { Translate } from '../../translate/translate';
import { ElpasoNotification, ElpasoNotificationIcon, ElpasoNotificationTitle } from './style';
import { ElpasoNotificationProps } from './types';

export const Notification = memo(({ text, size, type }: ElpasoNotificationProps) => (
    <ElpasoNotification size={size} typeStyle={type}>
        <ElpasoNotificationIcon size={size}>
            <InfoIcon />
        </ElpasoNotificationIcon>
        <ElpasoNotificationTitle size={size}>
            <Translate langKey={text} />
        </ElpasoNotificationTitle>
    </ElpasoNotification>
));
