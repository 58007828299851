import { useQuery } from '@apollo/client';
import { allCountries } from 'country-telephone-data';
import { useField } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import { ChangeEvent, useState } from 'react';

import { GetUserInfoByIp, Query } from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';

const USER_COUNTRY_CODE = window.navigator.language.substr(0, 2);

const defaultMask = ['+', ...new Array(20).fill(null).map(() => /\d/)];

type PhoneMaskReturnType = Array<string | RegExp>;

const getPhoneMask = (format?: string): PhoneMaskReturnType =>
    format !== undefined
        ? `${format}..`
            .replace(/\s?\(/, ' (')
            .replace(/\)\s?/, ') ')
            .split('')
            .map(value => (value === '.' ? /\d/ : value))
        : defaultMask;

const getPhoneCountryCode = (value: string) => {
    const phoneValue = value.charAt(0) === '+' ? value : `+${value}`;

    return parsePhoneNumber(phoneValue)?.country?.toLowerCase();
};

export const useInputPhone = (
    name: string
): [OnEventType<string, PhoneMaskReturnType>, OnEventType<ChangeEvent<HTMLInputElement>>, string | undefined] => {
    const [, , { setValue }] = useField(name);
    const { data } = useQuery<Pick<Query, 'getUserInfoByIp'>>(GetUserInfoByIp);
    const [currentCountryCode, setCountryCode] = useState<string | undefined>();

    const handleChange = ({ target: { value: targetValue }, nativeEvent }: ChangeEvent<HTMLInputElement>) => {
        if ((nativeEvent as InputEvent).inputType === undefined) {
            targetValue = `+${targetValue.replace(/\D+/g, '')}`;
        }

        setValue(targetValue);
    };

    const mask = (rawValue: string) => {
        const countryCode = getPhoneCountryCode(rawValue);
        const phoneCountryCode =
            countryCode ?? data?.getUserInfoByIp.ipInfo?.country.toLowerCase() ?? USER_COUNTRY_CODE;

        setCountryCode(countryCode);

        return getPhoneMask(allCountries.find(country => country.iso2 === phoneCountryCode)?.format);
    };

    return [mask, handleChange, currentCountryCode];
};
