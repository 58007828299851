import { Comment, File } from '@shared-graphql';
import { OnEventsType, OnEventType } from '@shared-type/on-event.type';
import { isString } from '@shared-util/is-data';

interface AddDocumentCommentsInterface {
    initialDocuments: File[] | null;
    documents: File[] | null;
    addFileComment: OnEventsType<string[]>;
    addComment: OnEventType<string, Promise<Comment>>;
}

export const addDocumentComments = ({
    initialDocuments,
    documents,
    addComment,
    addFileComment,
}: AddDocumentCommentsInterface) => {
    const prevDocuments = new Map(initialDocuments?.map(document => [document.key, document]));

    const comments = documents?.map(item => {
        const { comment: prevComment } = prevDocuments.get(item.key)?.comment ?? {};
        const newComment = item.comment?.comment;

        if (isString(newComment) && newComment !== prevComment) {
            return addComment((item.comment as Comment).comment).then(comment => addFileComment(item.key, comment.id));
        }

        return true;
    }) as Promise<any>[];

    return Promise.all(comments);
};
