import { useField } from 'formik';
import { useEffect } from 'react';

import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { getDate } from '@shared-util/get-date';
import { isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { ClientsTableFilterFormInterface } from '@page/clients/clients-table/clients-table-filter/clients-table-filter-form/clients-table-filter-form.interface';
import { ClientsTableEnum } from '@page/clients/clients-table/clients-table.enum';

import { ClientsTableFilterFormEnum } from './clients-table-filter-form.enum';

export const useClientTableFilterForm = ({
    onStatusChange,
    onTypeChange,
    onCreatedFromChange,
    onCreatedToChange,
    onCountryChange,
}: Omit<ClientsTableFilterFormInterface, 'clientsCount'>) => {
    const navigate = useRouterNavigate();

    const { searchParams: params } = useCurrentNavigation();
    const { status, type, createdFrom, createdTo, country } = params;

    const [{ value: statusValue }] = useField(ClientsTableFilterFormEnum.Status);
    const [{ value: typeValue }] = useField(ClientsTableFilterFormEnum.Type);
    const [{ value: createdFromValue }] = useField(ClientsTableFilterFormEnum.CreatedFrom);
    const [{ value: createdToValue }] = useField(ClientsTableFilterFormEnum.CreatedTo);
    const [{ value: countryValue }] = useField(ClientsTableFilterFormEnum.Country);

    useEffect(
        () =>
            void (
                statusValue !== undefined &&
                navigate(PageEnum.StatusClients, {
                    ...params,
                    status: statusValue === null ? ClientsTableEnum.All : statusValue,
                })
            ),
        [statusValue]
    );

    useEffect(
        () =>
            void (
                typeValue !== undefined &&
                navigate(PageEnum.StatusClients, {
                    ...params,
                    type: typeValue === null ? ClientsTableEnum.All : typeValue,
                })
            ),
        [typeValue]
    );

    useEffect(() => {
        if (createdFromValue !== undefined) {
            navigate(PageEnum.StatusClients, {
                ...params,
                createdFrom: isTrue(createdFromValue) ? getDate(createdFromValue, 'dd-MM-yyyy') : null,
            });
        }
    }, [createdFromValue]);

    useEffect(() => {
        if (createdToValue !== undefined) {
            navigate(PageEnum.StatusClients, {
                ...params,
                createdTo: isTrue(createdToValue) ? getDate(createdToValue, 'dd-MM-yyyy') : null,
            });
        }
    }, [createdToValue]);

    useEffect(
        () =>
            void (
                countryValue !== undefined &&
                navigate(PageEnum.StatusClients, {
                    ...params,
                    country: countryValue === null ? ClientsTableEnum.All : countryValue,
                })
            ),
        [countryValue]
    );

    useEffect(
        () => void onStatusChange(status !== undefined && status !== ClientsTableEnum.All ? status : null),
        [status]
    );
    useEffect(() => void onTypeChange(type !== undefined && type !== ClientsTableEnum.All ? type : null), [type]);

    useEffect(() => {
        void onCreatedFromChange(createdFrom !== undefined && isTrue(createdFrom) ? createdFrom : null);
    }, [createdFrom]);

    useEffect(() => {
        void onCreatedToChange(createdTo !== undefined && isTrue(createdTo) ? createdTo : null);
    }, [createdTo]);

    useEffect(
        () => void onCountryChange(country !== undefined && country !== ClientsTableEnum.All ? country : null),
        [country]
    );
};
