import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

import { ClickEventType } from '@shared-type/click-event.type';

import { InfoBlockView } from './info-block.styles';
import { InfoMainBlock, InfoMainBlockProps } from './info-main-block/info-main-block';
import { InfoRightBlock, InfoRightBlockProps } from './info-right-block/info-right-block';

export interface InfoBlockProps extends InfoMainBlockProps, InfoRightBlockProps {
    onDelete?: ClickEventType;
    children?: React.ReactNode;
}

export const InfoBlock: FC<InfoBlockProps> = observer(
    ({ title, customTitle, description, children, onDelete, onClick, isButton, isChosen }) => (
        <InfoBlockView>
            <InfoMainBlock
                title={title}
                customTitle={customTitle}
                description={description}
                isChosen={isChosen}
                onClick={onClick}
            >
                {children}
            </InfoMainBlock>
            <InfoRightBlock onClick={onDelete ?? onClick} isButton={isButton} />
        </InfoBlockView>
    )
);
