import { ColorStyles } from './styles-combine';
import { ButtonSizeEnum, ButtonTypeEnum } from './types';

export const getBtnPaddingBySize = (size: ButtonSizeEnum) => {
    switch (size) {
        case ButtonSizeEnum.XL:
            return '18px';
        case ButtonSizeEnum.L:
            return '16px 18px';
        case ButtonSizeEnum.M:
            return '10px 14px';
        default:
            return '18px';
    }
};

export const getStyleByType = (type: ButtonTypeEnum, reset = false) => {
    switch (type) {
        case ButtonTypeEnum.COLOR:
            return `
                ${reset ? ColorStyles.Color.NoBorder.NoShadow.Style : ColorStyles.Color.Style}
                :hover{
                    ${reset ? ColorStyles.ColorHover.NoBorder.NoShadow.Style : ColorStyles.ColorHover.Style}
                    svg, path {
                        ${reset ? ColorStyles.ColorHover.NoBorder.NoShadow.Style : ColorStyles.ColorHover.Style}
                    }
                }
            `;
        case ButtonTypeEnum.BLACK:
            return `
                 ${reset ? ColorStyles.Black.NoBorder.NoShadow.Style : ColorStyles.Black.Style}
                :hover{
                    ${reset ? ColorStyles.BlackHover.NoBorder.NoShadow.Style : ColorStyles.BlackHover.Style}
                    svg, path {
                        ${reset ? ColorStyles.BlackHover.NoBorder.NoShadow.Style : ColorStyles.BlackHover.Style}
                    }
                }
            `;
        case ButtonTypeEnum.GHOST:
            return `
                ${reset ? ColorStyles.Ghost.NoBorder.NoShadow.Style : ColorStyles.Ghost.Style}
                :hover{
                    ${reset ? ColorStyles.GhostHover.NoBorder.NoShadow.Style : ColorStyles.GhostHover.Style}
                    svg, path {
                        ${reset ? ColorStyles.GhostHover.NoBorder.NoShadow.Style : ColorStyles.GhostHover.Style}
                    }
                }
            `;
        case ButtonTypeEnum.DANGER_GHOST:
            return `
                ${reset ? ColorStyles.DangerGhost.NoBorder.NoShadow.Style : ColorStyles.DangerGhost.Style}
                :hover{
                    ${reset ? ColorStyles.DangerGhostHover.NoBorder.NoShadow.Style : ColorStyles.DangerGhostHover.Style}
                    svg, path {
                        ${
    reset
        ? ColorStyles.DangerGhostHover.NoBorder.NoShadow.Style
        : ColorStyles.DangerGhostHover.Style
}
                    }
                }
            `;
        case ButtonTypeEnum.COLOR_STROKE:
            return `
                ${reset ? ColorStyles.ColorStroke.NoBorder.NoShadow.Style : ColorStyles.ColorStroke.Style}
                :hover{
                    ${reset ? ColorStyles.ColorStrokeHover.NoBorder.NoShadow.Style : ColorStyles.ColorStrokeHover.Style}
                    svg, path {
                        ${
    reset
        ? ColorStyles.ColorStrokeHover.NoBorder.NoShadow.Style
        : ColorStyles.ColorStrokeHover.Style
}
                    }
                }
            `;
        case ButtonTypeEnum.BLACK_STROKE:
            return `
                ${reset ? ColorStyles.BlackStroke.NoBorder.NoShadow.Style : ColorStyles.BlackStroke.Style}
                :hover{
                    ${reset ? ColorStyles.BlackStrokeHover.NoBorder.NoShadow.Style : ColorStyles.BlackStrokeHover.Style}
                    svg, path {
                        ${
    reset
        ? ColorStyles.BlackStrokeHover.NoBorder.NoShadow.Style
        : ColorStyles.BlackStrokeHover.Style
}
                    }
                }
            `;
        case ButtonTypeEnum.WHITE_STROKE:
            return `
                ${reset ? ColorStyles.WhiteStroke.NoBorder.NoShadow.Style : ColorStyles.WhiteStroke.Style}
                :hover{
                    ${reset ? ColorStyles.WhiteStrokeHover.NoBorder.NoShadow.Style : ColorStyles.WhiteStrokeHover.Style}
                    svg, path {
                        ${
    reset
        ? ColorStyles.WhiteStrokeHover.NoBorder.NoShadow.Style
        : ColorStyles.WhiteStrokeHover.Style
}
                    }
                }
            `;
        case ButtonTypeEnum.TETRIARY:
            return `
                ${reset ? ColorStyles.Tetriary.NoBorder.NoShadow.Style : ColorStyles.Tetriary.Style}
                :hover{
                    ${reset ? ColorStyles.TetriaryHover.NoBorder.NoShadow.Style : ColorStyles.TetriaryHover.Style}
                    svg, path {
                        ${reset ? ColorStyles.TetriaryHover.NoBorder.NoShadow.Style : ColorStyles.TetriaryHover.Style}
                    }
                }
            `;
        case ButtonTypeEnum.DANGER:
            return `
                ${reset ? ColorStyles.Danger.NoBorder.NoShadow.Style : ColorStyles.Danger.Style}
                :hover{
                    ${reset ? ColorStyles.DangerHover.NoBorder.NoShadow.Style : ColorStyles.DangerHover.Style}
                    svg, path {
                        ${reset ? ColorStyles.DangerHover.NoBorder.NoShadow.Style : ColorStyles.DangerHover.Style}
                    }
                }
            `;
        default:
            return `
                ${reset ? ColorStyles.Color.NoBorder.NoShadow.Style : ColorStyles.Color.Style}
                :hover{
                    ${reset ? ColorStyles.ColorHover.NoBorder.NoShadow.Style : ColorStyles.ColorHover.Style}
                }
            `;
    }
};

export const getLoaderStyleByType = (type: ButtonTypeEnum) => {
    switch (type) {
        case ButtonTypeEnum.COLOR:
            return `
                ${ColorStyles.ColorLoader.Style}
            `;
        case ButtonTypeEnum.BLACK:
            return `
                ${ColorStyles.BlackLoader.Style}
            `;
        case ButtonTypeEnum.GHOST:
            return `
                ${ColorStyles.GhostLoader.Style}
            `;
        case ButtonTypeEnum.DANGER_GHOST:
            return `
                ${ColorStyles.DangerGhostLoader.Style}
            `;
        case ButtonTypeEnum.COLOR_STROKE:
            return `
                ${ColorStyles.ColorStrokeLoader.Style}
            `;
        case ButtonTypeEnum.BLACK_STROKE:
            return `
                ${ColorStyles.BlackStrokeLoader.Style}
            `;
        case ButtonTypeEnum.WHITE_STROKE:
            return `
                ${ColorStyles.WhiteStrokeLoader.Style}
            `;
        case ButtonTypeEnum.TETRIARY:
            return `
                ${ColorStyles.TetriaryLoader.Style}
            `;
        case ButtonTypeEnum.DANGER:
            return `
                ${ColorStyles.DangerLoader.Style}
            `;
        default:
            return `
                ${ColorStyles.ColorLoader.Style}
            `;
    }
};
