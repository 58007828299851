import { FC } from 'react';

import { DateRangeFilter } from '@shared-component/table/table-filters/date-range-filter/date-range-filter';
import { SelectFilter } from '@shared-component/table/table-filters/select-filter/select-filter';
import {
    SelectPreview,
    SelectPreviewData,
    SelectPreviewTitle,
} from '@shared-component/table/table-filters/select-filter/select-filter.styles';
import { TableFiltersFormWrapper } from '@shared-component/table/table.styles';

import { ClientsTableFilterFormEnum } from '@page/clients/clients-table/clients-table-filter/clients-table-filter-form/clients-table-filter-form.enum';
import { useClientTableFilterForm } from '@page/clients/clients-table/clients-table-filter/clients-table-filter-form/clients-table-filter-form.hook';
import { ClientsTableFilterFormInterface } from '@page/clients/clients-table/clients-table-filter/clients-table-filter-form/clients-table-filter-form.interface';
import {
    clientsTableFilterCountryFormOptions,
    clientsTableFilterStatusFormOptions,
    clientsTableFilterTypeFormOptions,
} from '@page/clients/clients-table/clients-table-filter/clients-table-filter-form/clients-table-filter-status-form.options';

export const ClientsTableFilterForm: FC<ClientsTableFilterFormInterface> = ({
    onStatusChange,
    onTypeChange,
    onCreatedFromChange,
    onCreatedToChange,
    onCountryChange,
    clientsCount,
}) => {
    useClientTableFilterForm({ onStatusChange, onTypeChange, onCreatedFromChange, onCreatedToChange, onCountryChange });

    return (
        <TableFiltersFormWrapper>
            <SelectPreview>
                <SelectPreviewTitle>Found:</SelectPreviewTitle>
                <SelectPreviewData clickable={false}>{clientsCount ?? 0}</SelectPreviewData>
            </SelectPreview>
            <DateRangeFilter
                nameFrom={ClientsTableFilterFormEnum.CreatedFrom}
                nameTo={ClientsTableFilterFormEnum.CreatedTo}
                title="Period"
            />
            <SelectFilter
                name={ClientsTableFilterFormEnum.Country}
                title="Nationality"
                options={clientsTableFilterCountryFormOptions}
            />
            <SelectFilter
                name={ClientsTableFilterFormEnum.Status}
                title="Status"
                options={clientsTableFilterStatusFormOptions}
            />
            <SelectFilter
                name={ClientsTableFilterFormEnum.Type}
                title="Client type"
                options={clientsTableFilterTypeFormOptions}
            />
        </TableFiltersFormWrapper>
    );
};
