import { applySnapshot } from 'mobx-state-tree';

import { Maybe, Address } from '@shared-graphql';
import { initialAddress } from '@shared-model/address-type/address.initial';
import { AddressType } from '@shared-model/address-type/address.type';

export const AddressModelType = AddressType.actions(self => ({
    applyAddress: (address: Maybe<Address>) => {
        applySnapshot(self, address ?? initialAddress);
    },
}));
