import { FC, ReactNode, useContext } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { isString, isTrue } from '@shared-util/is-data';

import { RowActionWrapper, RowParagraph, RowBlock, RowTitle, CopyIcon } from './private-client-summary-row.styles';

interface GridRowProps {
    title: string | ReactNode;
    data: string | ReactNode;
    isCopyEnabled?: boolean;
    children?: ReactNode | ReactNode[] | JSX.Element;
}

export const GridRow: FC<GridRowProps> = ({ title, data, isCopyEnabled, children }) => {
    const { onOpen } = useContext(InformationModalContext);
    const onDataClick = () => {
        if (isTrue(isCopyEnabled)) {
            navigator.clipboard.writeText(data as string);
            onOpen(InformationModalEnum.CopyInformationModal);
        }
    };
    return (
        <>
            {isString(title) ? <RowTitle>{title}</RowTitle> : <RowBlock>{title}</RowBlock>}
            {isString(data) ? (
                <RowParagraph onClick={onDataClick} isCopyEnabled={isCopyEnabled}>
                    {isTrue(isCopyEnabled) && <CopyIcon />}
                    {data}
                </RowParagraph>
            ) : (
                <RowBlock>{data}</RowBlock>
            )}
            <RowActionWrapper>{children}</RowActionWrapper>
        </>
    );
};
