import { ExternalLink } from '@shared-atom/external-link/external-link';

import { GoogleRecaptchaInfoComponent } from '@component/google-recaptcha-info/google-recaptcha-info.styles';

export const GoogleRecaptchaInfo = () => (
    <GoogleRecaptchaInfoComponent>
        This site is protected by reCAPTCHA and the Google
        <ExternalLink href="https://policies.google.com/privacy">Privacy Policy</ExternalLink>
        and
        <ExternalLink href="https://policies.google.com/terms">Terms of Service</ExternalLink>
        apply
    </GoogleRecaptchaInfoComponent>
);
