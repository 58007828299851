import { rgba } from 'polished';
import styled from 'styled-components';

import Check from '@shared-asset/check.svg?react';
import { getAnimationStyle } from '@shared-style/animation';
import { JungleGreen } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

interface CopyMessageWrapperProps {
    isVisible: boolean;
}

export const CopyWrapper = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
`;

export const CopyMessageWrapper = styled.div<CopyMessageWrapperProps>`
    width: 100%;
    position: absolute;
    background-color: ${rgba(JungleGreen, 0.2)};
    padding: 4px 0;
    color: ${JungleGreen};
    transform: translate(0, ${({ isVisible }) => (isVisible ? 0 : -100)}px);

    ${getAnimationStyle(['transform'])}
    ${Flex.HorizontalCenter.Style}
`;

export const CheckIcon = styled(Check)`
    width: 16px;
    height: 16px;
    fill: ${JungleGreen};
`;
