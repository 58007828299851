import { FC, ReactNode } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { useCurrentNavigation } from '@shared-hook/navigation/use-current-navigation.hook';
import { isString, isTrue } from '@shared-util/is-data';

import { useReportUrlGetting } from '@hook/user/report-url-getting';
import { ButtonColorStroke } from '@page/common/summary-buttons/summary-buttons.styles';
import { usePaymentSelector } from '@selector/payment/use-payment.selector';

import { ActionsContainer, Grid, GridHeading, GridTitle } from './summary-grid.styles';

type ActionsType = {
    shouldShowStatementButton?: boolean;
};

interface SummaryGridProps {
    title?: string;
    hasOne?: boolean;
    children?: ReactNode | ReactNode[];
    actions?: ActionsType;
}

export const SummaryGrid: FC<SummaryGridProps> = ({ title, hasOne = false, children, actions = {} }) => {
    const {
        params: { paymentId, clientId },
    } = useCurrentNavigation();
    const { isReportUrlLoading, handleSubmit: handleGenerateStatement } = useReportUrlGetting(paymentId);
    const [
        {
            payment: { accountId },
        },
    ] = usePaymentSelector();

    return (
        <>
            {isString(title) && (
                <GridHeading>
                    <GridTitle>{title}</GridTitle>
                    {Object.values(actions as ActionsType)?.some(value => isTrue(value)) && (
                        <ActionsContainer>
                            {isTrue(actions.shouldShowStatementButton) && (
                                <ButtonTransparent
                                    onClick={() => handleGenerateStatement({ clientId, accountId, operation: '' })}
                                    isSmall
                                    isDisabled={isReportUrlLoading}
                                >
                                    <ButtonColorStroke>Statement</ButtonColorStroke>
                                </ButtonTransparent>
                            )}
                        </ActionsContainer>
                    )}
                </GridHeading>
            )}
            <Grid hasOne={hasOne}>{children}</Grid>
        </>
    );
};
