import { useCallback, useMemo } from 'react';

import { LocalizationEnum } from '@shared-locale/localization.enum';

import { useLocalizationStore } from './use-localization-store.hook';

type LocalizationType = LocalizationEnum | string | number;

export type LocalizationTextType = LocalizationType | LocalizationType[];

export const useLocalizationText = <T = string>(langKey: LocalizationTextType): T => {
    const { keys, isKeysEmpty } = useLocalizationStore();

    const getText = useCallback(
        (key: LocalizationType) => (isKeysEmpty ? '' : keys[key as LocalizationEnum] ?? key),
        [keys, isKeysEmpty]
    );

    return useMemo(
        () => (Array.isArray(langKey) ? langKey.map(getText) : getText(langKey)),
        [keys, langKey]
    ) as unknown as T;
};
