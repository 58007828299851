import React from 'react';

import { HeaderWrapper, PageContainerWrapper, PageTitle } from '@page/common/common.styles';

import { CurrenciesTable } from './currencies-table/currencies-table';

export const CurrenciesPage = () => (
    <>
        <HeaderWrapper>
            <PageTitle>Currencies</PageTitle>
        </HeaderWrapper>
        <PageContainerWrapper>
            <CurrenciesTable />
        </PageContainerWrapper>
    </>
);
