import styled from 'styled-components';

import { CuriousBlue } from '@shared-style/colors';
import { IsDisabledType } from '@shared-type/common.type';

export const ExternalLinkStyles = styled.a<IsDisabledType>`
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: ${CuriousBlue};

    ${({ isDisabled = false }) => (isDisabled ? 'pointer-events: none; cursor: default; opacity: 0.8' : '')}

    :hover {
        text-decoration: underline;
    }

    :active {
        text-decoration: none;
    }

    :visited {
        color: ${CuriousBlue};
    }
`;
