import { RegistrationMenuListInterface } from '@shared-component/page-wrapper/page-wrapper-navigation/page-wrapper-navigation-item/page-wrapper-navigation-item.interface';

import AddressIcon from '@page/legal-client-profile/assets/address.svg?react';
import CompanyIcon from '@page/legal-client-profile/assets/company.svg?react';

export enum RegistrationLegalPersonLegalModalEnum {
    Company = 'company',
    Address = 'address',
}

export const RegistrationLegalPersonLegalModalNavigationOptions: RegistrationMenuListInterface<RegistrationLegalPersonLegalModalEnum>[] =
    [
        {
            title: 'Company',
            icon: CompanyIcon,
            chooseKey: RegistrationLegalPersonLegalModalEnum.Company,
            checkDone: status => status.hasCompany,
            checkEnable: () => true,
        },
        {
            title: 'Address',
            icon: AddressIcon,
            chooseKey: RegistrationLegalPersonLegalModalEnum.Address,
            checkDone: status => status.hasAddress,
            checkEnable: status => status.hasCompany,
        },
    ];
