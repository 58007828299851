import { useField } from 'formik';

import { isExist } from '@shared-util/is-data';

import { LabelError } from './field-error.styles';

interface InputErrorProps {
    name: string;
    style?: any;
}

export const FieldError = ({ name, style = {} }: InputErrorProps) => {
    const [, { error }] = useField(name);
    let errorToShow;

    if (error !== undefined && [name, 'fieldName'].includes(error.split(' ')[0])) {
        const errorArray = error.split(' ').slice(1);
        const fieldNameArray = name
            .replace(/([a-z])([A-Z])/, '$1 $2')
            .replace(/\./, ' ')
            .split(' ')
            .map((el, index) => (index === 0 ? el[index].toUpperCase() + el.slice(1) : el.toLowerCase()));

        errorToShow = fieldNameArray.concat(errorArray).join(' ');
    }

    if (!isExist(error)) {
        return null;
    }

    return <LabelError style={style}>{isExist(errorToShow) ? errorToShow : error}</LabelError>;
};
