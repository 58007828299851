import { observer } from 'mobx-react-lite';

import { Select } from '@shared-component/select/select';

import { initialAllClient } from '@page/commission-profile/commission-profile-initials/commission-profile.initials';
import { CommissionProfileEnum } from '@page/commission-profile/commission-profile.enum';
import { useStore } from '@store/store-context';

import { SelectWrapper } from './commission-profile-header-form.styles';

export const CommissionProfileHeaderForm = observer(() => {
    const userTypeSelect = [...initialAllClient];
    const {
        page: { commissionProfilePage },
    } = useStore();

    const handleChange = (value: any) => {
        commissionProfilePage.setUserType(value);
    };

    return (
        <SelectWrapper>
            <Select
                name={CommissionProfileEnum.UserType}
                onValueChanged={handleChange}
                title="Choose user type"
                options={userTypeSelect}
            />
        </SelectWrapper>
    );
});
