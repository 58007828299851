import { values } from 'mobx';
import { Instance, types } from 'mobx-state-tree';

import { AccountCcTypeSchema, AccountElpaTypeSchema } from '@shared-model/account-model/account-model.type';

import { accountModelActions } from './account-model-actions/account-model-actions';

export const AccountElpaType = types.model(AccountElpaTypeSchema);
export const AccountCcType = types.model(AccountCcTypeSchema);
export const AccountTypeUnion = types.union(AccountCcType);

export const AccountModelType = types
    .model('Account', {
        elements: types.map(AccountTypeUnion),
        selectedId: types.maybeNull(types.string),
        maxInitialShow: 3,
        clientAccountMap: types.map(AccountTypeUnion),
    })
    .views(self => ({
        get selectedAccount() {
            return self.selectedId !== null ? self.clientAccountMap.get(self.selectedId) : null;
        },
        get accounts() {
            return values(self.elements) as unknown as Instance<typeof AccountCcTypeSchema>[];
        },
        get clientAccounts() {
            return values(self.clientAccountMap) as unknown as Instance<typeof AccountCcTypeSchema>[];
        },
        get countAccounts() {
            return self.elements.size;
        },
        get countClientAccounts() {
            return self.clientAccountMap.size;
        },
        get ccAccounts() {
            return values(self.clientAccountMap) as unknown as Instance<typeof AccountCcTypeSchema>[];
        },
    }))
    .views(self => ({
        get hasAccountMore() {
            return self.countAccounts > self.maxInitialShow;
        },
        get limitAccounts() {
            return self.accounts.slice(0, self.maxInitialShow);
        },
        get limitCcAccounts() {
            return self.ccAccounts.slice(0, self.maxInitialShow);
        },
        getCards(_userId?: string, _accountId?: string) {
            return [];
        },
    }));

export const AccountModelInstanceType = AccountModelType.actions(accountModelActions);
