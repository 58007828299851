import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const TitleWrapper = styled.div`
    margin-left: 8px;
`;

export const TitleText = styled.span`
    margin-left: 8px;
    ${Typography.Dark.Style}
`;

export const TooltipWrapper = styled.div`
    margin-left: 8px;
    display: inline-block;
    vertical-align: middle;
`;
