import { FC } from 'react';

import { DateRangeFilter } from '@shared-component/table/table-filters/date-range-filter/date-range-filter';
import { SelectFilter } from '@shared-component/table/table-filters/select-filter/select-filter';
import {
    SelectPreview,
    SelectPreviewData,
    SelectPreviewTitle,
} from '@shared-component/table/table-filters/select-filter/select-filter.styles';
import { TableFiltersFormWrapper } from '@shared-component/table/table.styles';

import { ClientsTableFilterFormEnum } from '@page/clients/clients-table/clients-table-filter/clients-table-filter-form/clients-table-filter-form.enum';
import { TransactionsTableFilterFormEnum } from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter-form/transactions-table-filter-form.enum';
import { useTransactionsTableFilterForm } from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter-form/transactions-table-filter-form.hook';
import { FilterFormInterface } from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter-form/transactions-table-filter-form.interface';
import {
    // transactionTypeOptions,
    // transactionStatusOptions,
    transactionElcoinStatusOptions,
} from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter-form/transactions-table-filter-form.options';

export const TransactionsTableFilterForm: FC<FilterFormInterface> = ({
    onStatusChange,
    onPaymentTypeChange,
    onCreatedFromChange,
    onCreatedToChange,
    onElcoinStatusChange,
    transactionsCount,
}) => {
    useTransactionsTableFilterForm({
        onStatusChange,
        onPaymentTypeChange,
        onCreatedFromChange,
        onCreatedToChange,
        onElcoinStatusChange,
    });

    return (
        <TableFiltersFormWrapper>
            <SelectPreview>
                <SelectPreviewTitle>Found:</SelectPreviewTitle>
                <SelectPreviewData clickable={false}>{transactionsCount ?? 0}</SelectPreviewData>
            </SelectPreview>
            <DateRangeFilter
                nameFrom={ClientsTableFilterFormEnum.CreatedFrom}
                nameTo={ClientsTableFilterFormEnum.CreatedTo}
                title="Period"
            />
            {/* <SelectFilter */}
            {/*    name={TransactionsTableFilterFormEnum.Status} */}
            {/*    title="Status" */}
            {/*    options={transactionStatusOptions} */}
            {/* /> */}
            <SelectFilter
                name={TransactionsTableFilterFormEnum.ElcoinStatus}
                title="Elcoin Status"
                options={transactionElcoinStatusOptions}
            />
            {/* <SelectFilter */}
            {/*    name={TransactionsTableFilterFormEnum.PaymentType} */}
            {/*    title="Payment type" */}
            {/*    options={transactionTypeOptions} */}
            {/* /> */}
        </TableFiltersFormWrapper>
    );
};
