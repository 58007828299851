import { Form, Formik } from 'formik';

import { ButtonPrimary } from '@shared-atom/button/button-primary';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { FormWrapper } from '@shared-component/forms/form-common/form-common.styles';
import { LegalCompanyForm } from '@shared-component/forms/legal-company-form/legal-company-form';
import { LegalCompanyFormValidation } from '@shared-component/forms/legal-company-form/legal-company-form.validation';
import { RegistrationLegalPersonTitle, ButtonWrapper } from '@shared-component/registration/common.styles';
import { UserCompanyInput } from '@shared-graphql';

import { useLegalClientProfileCompanyUpdate } from './legal-client-profile-information.hook';

export const LegalClientProfileInformation = () => {
    const [initialData] = useLegalClientProfileCompanyUpdate();

    return (
        <>
            <RegistrationLegalPersonTitle>Information</RegistrationLegalPersonTitle>
            <FormWrapper>
                <Formik
                    initialValues={initialData as unknown as UserCompanyInput}
                    onSubmit={() => {}}
                    validationSchema={LegalCompanyFormValidation}
                    validateOnChange={false}
                    enableReinitialize
                >
                    <Form>
                        <LegalCompanyForm />
                        <ButtonWrapper>
                            <ButtonPrimary isLarge type={ButtonTypeEnum.Submit}>
                                Save
                            </ButtonPrimary>
                        </ButtonWrapper>
                    </Form>
                </Formik>
            </FormWrapper>
        </>
    );
};
