import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const SelectedItemWrapper = styled.p`
    margin-left: 16px;
    width: 100%;
    max-width: 344px;
    ${Typography.M.Normal.Dark.Left.HiddenEllipsis.Style}
`;
