import { useMemo, useState } from 'react';

import { File as FileUploadType, UserDocument, UserDocumentType } from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist } from '@shared-util/is-data';

type UploadDocumentsType = Array<Pick<UserDocument, 'type' | 'file'>>;

const REQUIRED_DOCUMENT_TYPES = new Set([]);

export const usePrivateDocumentForm = (
    documents: UploadDocumentsType,
    onUpdateDocuments: OnEventType<UserDocument[]>,
    requiredDocuments: Set<UserDocumentType> = REQUIRED_DOCUMENT_TYPES
) => {
    const [uploadedFiles, setUploadedFiles] = useState<UploadDocumentsType>(documents);
    const [selectedUploadType, setSelectedUpload] = useState<UserDocumentType | null>(null);
    const [selectedUploadKey, setSelectedUploadKey] = useState<string | undefined>();

    const selectedUpload = useMemo(
        () =>
            uploadedFiles.find(doc =>
                doc.type === UserDocumentType.Other
                    ? doc.type === selectedUploadType && doc?.file?.key === selectedUploadKey
                    : doc.type === selectedUploadType
            ) ?? null,
        [uploadedFiles, selectedUploadType, selectedUploadKey]
    );

    const isSelectedUploadType = selectedUploadType !== null;
    const hasRequiredFilesUploaded =
        uploadedFiles.filter(upload => requiredDocuments.has(upload.type)).length >= requiredDocuments.size;

    const isSubmitDisabled =
        (isSelectedUploadType && selectedUpload === null) || (!isSelectedUploadType && !hasRequiredFilesUploaded);

    const handleSelectedUploadType = (type: UserDocumentType | null, key?: string) => () => {
        setSelectedUpload(type);
        setSelectedUploadKey(key);
    };

    const handleUploadedFile = (file: FileUploadType) => {
        const uploadFileType = { type: selectedUploadType as UserDocumentType, file };
        const shouldSetKey = UserDocumentType.Other === selectedUploadType;

        if (shouldSetKey) {
            setSelectedUploadKey(file.key);
        }

        return setUploadedFiles(
            !shouldSetKey && isExist(selectedUpload)
                ? uploadedFiles.map(uploadFile =>
                    uploadFile.type === selectedUploadType ? uploadFile : uploadFileType
                )
                : [...uploadedFiles, uploadFileType]
        );
    };

    const handleDeleteFile = (fileKey: string) => () =>
        setUploadedFiles(uploadedFiles.filter(upload => upload?.file?.key !== fileKey));

    const handleDone = (uploadData: UploadDocumentsType) => {
        if (selectedUpload !== null) {
            setUploadedFiles(uploadData);
            return handleSelectedUploadType(null)();
        }

        onUpdateDocuments(uploadData as UserDocument[]);
    };

    return {
        uploadedFiles,
        selectedUploadType,
        selectedUploadKey,
        selectedUpload,
        isSubmitDisabled,

        handleSelectedUploadType,
        handleUploadedFile,
        handleDeleteFile,
        handleDone,
    };
};
