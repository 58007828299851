import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const PaymentFormComponentTitle = styled.h3`
    ${Typography.L.Medium.Dark.Style}
`;

export const PaymentFormWrapper = styled.div`
    padding-bottom: 24px;
`;
