import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';

import { LocalizationTextType, useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';

export interface TranslateProps {
    langKey: LocalizationTextType;
    separator?: string;
    customRender?: any;
}

const NEW_LINE_SEPARATOR = /\n/gm;
const NEW_LINE_BREAKER = <br />;

export const Translate = observer(({ langKey, separator = ' ', customRender }: TranslateProps) => {
    const text = useLocalizationText(langKey);

    if (import.meta.env.MODE !== 'production') {
        if (text === undefined) {
            console.error('langKey', langKey, text);
        }
    }

    const result = useMemo(
        () =>
            ((Array.isArray(text) ? text.join(separator) : text) ?? '')
                .split(NEW_LINE_SEPARATOR)
                .reduce((acc, currentText) => {
                    const newText = customRender instanceof Function ? customRender(currentText) : currentText;
                    return acc.length > 0 ? [...acc, NEW_LINE_BREAKER, newText] : [newText];
                }, [] as any[]),
        [customRender, separator, text]
    );

    return (
        <>
            {Boolean(result) === true && result}
            {Boolean(result) === false && null}
        </>
    );
});
