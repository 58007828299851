import styled, { css } from 'styled-components';

import Copy from '@shared-asset/copy.svg?react';
import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { Typography } from '@shared-atom/typography/typography';
import {
    BlueBayoux,
    Dark,
    DarkBlue,
    DarkGrey,
    GrayChateau,
    Mystic,
    Raven,
    PrimeBlue,
    Red,
    White,
    WhiteGrey,
    WhiteDark,
} from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { ThemeTypeInterface } from '@shared-type/theme-props.type';

export const GRAPH_HEIGHT = 32;
export const GRAPH_WIDTH = 64;

interface AccountCardProps extends ThemeTypeInterface {
    isBlocked: boolean;
    isSelected: boolean;
}

const AccountCurrencyNotBlocked = css`
    padding: 4px 8px;
    color: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : Dark)};
`;

const getAccountNumberColor = ({ isBlocked, theme: { isDarkTheme } }: AccountCardProps) => {
    if (isBlocked) return GrayChateau;
    return isDarkTheme ? White : Raven;
};

const getAccountCardBoxShadowColor = ({ isSelected, theme: { isDarkTheme } }: AccountCardProps) => {
    if (isSelected) return PrimeBlue;
    return isDarkTheme ? BlueBayoux : Mystic;
};

const AccountSchemaWhiteTheme = css`
    background-color: ${White};
    box-shadow: 0 0 2px ${Mystic};
`;

export const AccountInfo = styled.div`
    height: 48px;
    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const AccountSchema = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50px;
    overflow: hidden;
    ${Flex.HorizontalCenter.VerticalCenter.Style}

    ${({ theme: { isDarkTheme } }: ThemeTypeInterface) =>
        isDarkTheme ? `background-color: ${BlueBayoux};` : AccountSchemaWhiteTheme}
`;
export const CopyIcon = styled(Copy)`
    height: 24px;
    width: 24px;
    fill: ${DarkGrey};

    &:hover {
        fill: ${({ theme: { isDarkTheme } }: ThemeTypeInterface) => (isDarkTheme ? White : DarkBlue)};
    }
`;
export const CopyTabIcon = styled(CopyIcon)`
    fill: ${White};
`;

export const AccountCurrencyName = styled.span`
    flex-grow: 1;
    padding: 0 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    ${Typography.S.Style}
`;

export const AccountAmountSimpleView = styled.span`
    padding-bottom: 8px;
    padding-left: 8px;
    ${Typography.M.Medium.Style}
`;

export const AccountCardWrapper = styled(ButtonTransparent)`
    flex: 1;
    width: 100%;
`;

const getAccountCardViewBorderColor = ({ isSelected, theme: { isDarkTheme } }: AccountCardProps) => {
    const themeColor = isDarkTheme ? WhiteDark : Mystic;
    return isSelected ? PrimeBlue : themeColor;
};

export const AccountCardView = styled.div<AccountCardProps>`
    width: 100%;
    border-radius: 8px;
    padding: 24px;
    border: 1px solid ${getAccountCardViewBorderColor};
    box-shadow: 0 0 2px ${getAccountCardBoxShadowColor};
    ${({ isBlocked }) => isBlocked && `background-color: ${WhiteGrey}`};

    :hover {
        border-color: ${PrimeBlue};
        box-shadow: 0 0 2px ${PrimeBlue};
    }

    ${AccountCurrencyName}, ${AccountAmountSimpleView} {
        color: ${getAccountNumberColor};
    }

    ${AccountAmountSimpleView} {
        ${({ isBlocked }) => (isBlocked ? `color: ${GrayChateau};` : AccountCurrencyNotBlocked)};
    }
    @media (max-width: 991px) {
        padding: 10px 5px;
    }
`;

export const AccountCardSimpleView = styled.div`
    flex: 1;
    margin-left: 8px;
    text-align: left;
    overflow: hidden;

    ${Flex.Column.Style}
`;

export const RightContentWrapper = styled.div`
    padding-left: 16px;
    ${Flex.VerticalCenter.Style}
`;

export const BadgeBlocked = styled.div`
    box-shadow: 0 0 2px ${Red};
    padding: 8px 16px;
    border-radius: 8px;
    background-color: ${White};
    ${Flex.VerticalCenter.SpaceBetween.Style}
`;
