import { useState } from 'react';

import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';

import { RegistrationLegalPersonLegalModalEnum } from './registration-legal-person-legal.modal-options';

export const useRegistrationLegalPersonLegalModal = (): [
    RegistrationLegalPersonLegalModalEnum,
    OnEventType<RegistrationLegalPersonLegalModalEnum, OnEventEmptyType>
] => {
    const [selectedView, setSelectedView] = useState(RegistrationLegalPersonLegalModalEnum.Company);

    const handleSelectedView = (view: RegistrationLegalPersonLegalModalEnum) => () => setSelectedView(view);

    return [selectedView, handleSelectedView];
};
