import { useMemo, useState } from 'react';

import { Commission, CommissionType, OperationType, PaymentType, UserType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType, OnEventType } from '@shared-type/on-event.type';
import { isTrue } from '@shared-util/is-data';

import { getPaymentTemplateOperation } from '@page/commission-profile/commission-profile-info/commission-profile-info.options';
import {
    CommissionProfileInfoInterface,
    HandleDeleteInterface,
    HandleUpdateInterface,
} from '@page/commission-profile/commission-profile-info/commission-profile.interface';
import { useStore } from '@store/store-context';

import { useCommissionProfileInfoTemplateByUserType } from './use-commission-profile-info-template-by-user-type.hook';

export interface TemplateListInterface {
    title: LocalizationEnum;
    subTitle?: LocalizationEnum;
    handleEdit: OnEventEmptyType;
    handleClose: OnEventEmptyType;
    handleUpdate: OnEventType<HandleUpdateInterface>;
    handleDelete: OnEventType<HandleDeleteInterface>;
    isEditMode: boolean;
    template: Commission;
    type: CommissionType;
    operation: OperationType;
    isSepa: boolean;
    userType: UserType;
    lokaliseKey: string;
    paymentType: PaymentType;
    isDefault: boolean;
}

export const useCommissionProfileInfoPaymentHook = ({
    userId,
    userType: userTypeFromProps,
}: CommissionProfileInfoInterface): TemplateListInterface[] | boolean => {
    const {
        page: {
            commissionProfilePage: { userType: userTypeFromStore },
        },
    } = useStore();

    const userType = useMemo(
        () => (isTrue(userTypeFromProps) ? userTypeFromProps : userTypeFromStore),
        [userTypeFromProps, userTypeFromStore]
    ) as UserType;

    const [isEditModeSepaDebit, setEditModeSepaDebit] = useState(false);
    const [isEditModeSwiftDebit, setEditModeSwiftDebit] = useState(false);
    const [isEditModeLocalDebit, setEditModeLocalDebit] = useState(false);
    const [isEditModeSepaCredit, setEditModeSepaCredit] = useState(false);
    const [isEditModeSwiftCredit, setEditModeSwiftCredit] = useState(false);
    const [isEditModeLocalCredit, setEditModeLocalCredit] = useState(false);

    const [templates, handleUpdate, handleDelete, isLoading] = useCommissionProfileInfoTemplateByUserType({
        userId,
        userType,
    });

    const paymentTemplates = templates.filter(template => template.type === CommissionType.Payment);

    const sepaTemplatesDebit = getPaymentTemplateOperation(paymentTemplates, PaymentType.sepa, OperationType.Debit);
    const sepaTemplatesCredit = getPaymentTemplateOperation(paymentTemplates, PaymentType.sepa, OperationType.Credit);
    const swiftTemplatesDebit = getPaymentTemplateOperation(paymentTemplates, PaymentType.swift, OperationType.Debit);
    const swiftTemplatesCredit = getPaymentTemplateOperation(paymentTemplates, PaymentType.swift, OperationType.Credit);
    const localTemplatesDebit = getPaymentTemplateOperation(paymentTemplates, PaymentType.local, OperationType.Debit);
    const localTemplatesCredit = getPaymentTemplateOperation(paymentTemplates, PaymentType.local, OperationType.Credit);

    const onClose = (setEditMode: OnEventType<boolean>) => setEditMode(false);

    if (userType === null) {
        return [];
    }
    if (isLoading) {
        return false;
    }

    return [
        {
            title: LocalizationEnum.CommissionProfilePaymentListTitle3,
            subTitle: LocalizationEnum.CommissionProfilePaymentListTitle2,
            handleEdit: () => setEditModeSepaDebit(prevMode => !prevMode),
            handleClose: () => onClose(setEditModeSepaDebit),
            isEditMode: isEditModeSepaDebit,
            template: sepaTemplatesDebit,
            handleUpdate,
            handleDelete,
            type: CommissionType.Payment,
            operation: OperationType.Debit,
            isSepa: true,
            userType,
            lokaliseKey: sepaTemplatesDebit.lokaliseKey ?? '',
            paymentType: PaymentType.sepa,
            isDefault: sepaTemplatesDebit.isDefault ?? true,
        },
        {
            title: LocalizationEnum.CommissionProfilePaymentListTitle4,
            handleEdit: () => setEditModeSepaCredit(prevMode => !prevMode),
            handleClose: () => onClose(setEditModeSepaCredit),
            isEditMode: isEditModeSepaCredit,
            template: sepaTemplatesCredit,
            handleUpdate,
            handleDelete,
            type: CommissionType.Payment,
            operation: OperationType.Credit,
            isSepa: true,
            userType,
            lokaliseKey: sepaTemplatesCredit.lokaliseKey ?? '',
            paymentType: PaymentType.sepa,
            isDefault: sepaTemplatesCredit.isDefault ?? true,
        },
        {
            title: LocalizationEnum.CommissionProfilePaymentListTitle6,
            subTitle: LocalizationEnum.CommissionProfilePaymentListTitle5,
            handleEdit: () => setEditModeSwiftDebit(prevMode => !prevMode),
            handleClose: () => onClose(setEditModeSwiftDebit),
            isEditMode: isEditModeSwiftDebit,
            template: swiftTemplatesDebit,
            handleUpdate,
            handleDelete,
            type: CommissionType.Payment,
            operation: OperationType.Debit,
            isSepa: false,
            userType,
            lokaliseKey: swiftTemplatesDebit.lokaliseKey ?? '',
            paymentType: PaymentType.swift,
            isDefault: swiftTemplatesDebit.isDefault ?? true,
        },
        {
            title: LocalizationEnum.CommissionProfilePaymentListTitle7,
            handleEdit: () => setEditModeSwiftCredit(prevMode => !prevMode),
            handleClose: () => onClose(setEditModeSwiftCredit),
            isEditMode: isEditModeSwiftCredit,
            template: swiftTemplatesCredit,
            handleUpdate,
            handleDelete,
            type: CommissionType.Payment,
            operation: OperationType.Credit,
            isSepa: false,
            userType,
            lokaliseKey: swiftTemplatesCredit.lokaliseKey ?? '',
            paymentType: PaymentType.swift,
            isDefault: swiftTemplatesCredit.isDefault ?? true,
        },
        {
            title: LocalizationEnum.CommissionProfilePaymentListTitle3,
            subTitle: LocalizationEnum.CommissionProfilePaymentListTitle9,
            handleEdit: () => setEditModeLocalDebit(prevMode => !prevMode),
            handleClose: () => onClose(setEditModeLocalDebit),
            isEditMode: isEditModeLocalDebit,
            template: localTemplatesDebit,
            handleUpdate,
            handleDelete,
            type: CommissionType.Payment,
            operation: OperationType.Debit,
            isSepa: false,
            userType,
            lokaliseKey: localTemplatesDebit.lokaliseKey ?? '',
            paymentType: PaymentType.local,
            isDefault: localTemplatesDebit.isDefault ?? true,
        },
        {
            title: LocalizationEnum.CommissionProfilePaymentListTitle8,
            handleEdit: () => setEditModeLocalCredit(prevMode => !prevMode),
            handleClose: () => onClose(setEditModeLocalCredit),
            isEditMode: isEditModeLocalCredit,
            template: localTemplatesCredit,
            handleUpdate,
            handleDelete,
            type: CommissionType.Payment,
            operation: OperationType.Credit,
            isSepa: false,
            userType,
            lokaliseKey: localTemplatesCredit.lokaliseKey ?? '',
            paymentType: PaymentType.local,
            isDefault: localTemplatesCredit.isDefault ?? true,
        },
    ];
};
