import { flow, getParent, Instance } from 'mobx-state-tree';

import { AdminUserActivatedStatusUpdate, Mutation, UserActivatedStatus } from '@shared-graphql';

import { apolloClient } from '@connection/apollo-client';
import { UserModel } from '@model/users-model/user-model';
import { UserModelTypes } from '@model/users-model/user-model.type';

const mutateChangeUserStatus = (userId: string, status: UserActivatedStatus) =>
    apolloClient
        .query<Pick<Mutation, 'adminUserActivatedStatusUpdate'>>({
        query: AdminUserActivatedStatusUpdate,
        variables: { userId, status },
    })
        .then(result => result.data.adminUserActivatedStatusUpdate);

export const UserModelActions = (self: Instance<typeof UserModelTypes>) => ({
    changeUserStatus: flow(function* changeUserStatus(status: UserActivatedStatus) {
        const userInstanceModel = getParent(getParent(getParent(self))) as Instance<typeof UserModel>;

        if (userInstanceModel.loading.isLoading) {
            return;
        }

        userInstanceModel.setLoading(true);

        try {
            const result = yield mutateChangeUserStatus(self.id, status);

            if (result.status as boolean) {
                self.status = status;
            }
        } catch (error) {
            throw new Error(error as string);
        } finally {
            userInstanceModel.setLoading(false);
        }
    }),
});
