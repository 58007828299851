import styled from 'styled-components';

import { Table } from '@shared-component/table/table.styles';
import { White } from '@shared-style/colors';

export const TableWrapper = styled.div`
    padding: 16px 32px 16px 32px;
    border-radius: 16px;
    background-color: ${White};
    height: 100%;

    ${Table} {
        height: 96%;
    }
`;
