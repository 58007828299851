import { combine } from 'flexible-chain';
import styleToCss from 'style-object-to-css-string';
import { CSSObject } from 'styled-components';

import { White, Error500, Primary500, Warning500, Neutral500, Error50, Warning50 } from '@shared-style/colors';

export const styledType = {
    Info: {
        backgroundColor: `${White}`,
        color: `${Neutral500}`,
        fill: `${Primary500}`,
    },
    Accent: {
        backgroundColor: `${Primary500}`,
        color: `${White}`,
        fill: `${White}`,
    },
    Error: {
        backgroundColor: `${Error50}`,
        color: `${Error500}`,
        fill: `${Error500}`,
    },
    Warning: {
        backgroundColor: `${Warning50}`,
        color: `${Warning500}`,
        fill: `${Warning500}`,
    },
    WarningAccept: {
        backgroundColor: `${Warning500}`,
        color: `${Warning50}`,
        fill: `${Warning50}`,
    },
};

const defaultStyles = {
    borderRadius: '8px',
};

const componentResult = {
    Style: (props: CSSObject) => styleToCss({ ...defaultStyles, ...props }),
};

export const ColorStyles = combine(styledType, componentResult);
