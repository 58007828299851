import styled from 'styled-components';

import { ButtonBorderedStyle } from '@shared-atom/button/button.style';

export const NameView = styled.span``;

export const ActionsWrapper = styled.div`
    display: grid;
    grid-template-columns: 56px 56px;
    column-gap: 16px;
    padding-right: 16px;

    ${ButtonBorderedStyle} {
        width: 56px;
        padding: 0 8px;
    }
`;
