import { observer } from 'mobx-react-lite';
import React, { Fragment, useMemo } from 'react';
import { v4 } from 'uuid';

import { UserDocument, UserDocumentType } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

import { usePrivateClientSelector } from '@selector/private-client/use-private-client.selector';

import {
    DocumentPreview,
    DocumentsRow,
    DocumentsWrapper,
    DocumentTitle,
    OtherDocumentsList,
} from './private-client-documents-active.styles';

export const PrivateClientDocumentsActive = observer(() => {
    // const documentForm = usePrivateClientDocuments();

    // const isSelectedUploadType = documentForm.selectedUploadType !== null;
    const [{ documents }] = usePrivateClientSelector();

    const passportDocument = useMemo(
        () => documents.find(({ type }) => type === UserDocumentType.Passport),
        [documents]
    );
    const proofOfAddressDocument = useMemo(
        () => documents.find(({ type }) => type === UserDocumentType.Location),
        [documents]
    );
    const livenessCheckDocument = useMemo(
        () => documents.find(({ type }) => type === UserDocumentType.Selfie),
        [documents]
    );
    const otherDocuments = useMemo(
        () =>
            documents.filter(
                ({ type }) =>
                    ![UserDocumentType.Passport, UserDocumentType.Location, UserDocumentType.Selfie].includes(type)
            ),
        [documents]
    ) as UserDocument[];

    return (
        <DocumentsWrapper>
            <DocumentsRow>
                <DocumentTitle>1. Passport</DocumentTitle>
                {isTrue(passportDocument?.file?.path) ? <DocumentPreview src={passportDocument?.file?.path} /> : '-'}
            </DocumentsRow>
            <DocumentsRow>
                <DocumentTitle>2. Proof of address</DocumentTitle>
                {isTrue(proofOfAddressDocument?.file?.path) ? (
                    <DocumentPreview src={proofOfAddressDocument?.file?.path} />
                ) : (
                    '-'
                )}
            </DocumentsRow>
            <DocumentsRow>
                <DocumentTitle>3. Liveness check</DocumentTitle>
                {isTrue(livenessCheckDocument?.file?.path) ? (
                    <DocumentPreview src={livenessCheckDocument?.file?.path} />
                ) : (
                    '-'
                )}
            </DocumentsRow>
            <DocumentsRow>
                <DocumentTitle>4. Other documents</DocumentTitle>
                {otherDocuments.length > 0 ? (
                    <OtherDocumentsList>
                        {otherDocuments.map(document => (
                            <Fragment key={v4()}>
                                {isTrue(document?.file?.path) ? <DocumentPreview src={document?.file?.path} /> : '-'}
                            </Fragment>
                        ))}
                    </OtherDocumentsList>
                ) : (
                    <>-</>
                )}
            </DocumentsRow>

            {/* <PrivateDocumentForm documentForm={documentForm} hasOtherTypeDocuments> */}
            {/*    <ButtonWrapper> */}
            {/*        {!isSelectedUploadType && ( */}
            {/*            <ButtonPrimary type={ButtonTypeEnum.Submit} disabled={documentForm.isSubmitDisabled}> */}
            {/*                Update documents */}
            {/*            </ButtonPrimary> */}
            {/*        )} */}
            {/*        {isSelectedUploadType && ( */}
            {/*            <BackButton onClick={documentForm.handleSelectedUploadType(null)}> */}
            {/*                <ButtonPrimary onClick={documentForm.handleSelectedUploadType(null)}> */}
            {/*                    Continue */}
            {/*                </ButtonPrimary> */}
            {/*            </BackButton> */}
            {/*        )} */}
            {/*    </ButtonWrapper> */}
            {/* </PrivateDocumentForm> */}
        </DocumentsWrapper>
    );
});
