import { object, string } from 'yup';

import { CreateIfxCredentialsFormEnum } from '@component/modal/modals/create-ifx-credentials/create-ifx-credentials.enum';

export const CreateIfxCredentialsFormValidation = object().shape({
    [CreateIfxCredentialsFormEnum.ClientId]: string().required(),
    [CreateIfxCredentialsFormEnum.IbanqClientSecret]: string().required(),
    [CreateIfxCredentialsFormEnum.IbanqClientId]: string().required(),
    [CreateIfxCredentialsFormEnum.IbanqPassword]: string().required(),
    [CreateIfxCredentialsFormEnum.IbanqUsername]: string().required(),
    [CreateIfxCredentialsFormEnum.IbanqCallbackSecret]: string().required(),
});
