import { useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { Mutation, SendRegistrationMail, UserPasswordReset } from '@shared-graphql';
import { OnEventEmptyType } from '@shared-type/on-event.type';

type UserPasswordResetMutationType = Pick<Mutation, 'userPasswordReset'>;
type UserInviteMutationType = Pick<Mutation, 'sendRegistrationMail'>;

export const useUserEvents = (email: string): [OnEventEmptyType, OnEventEmptyType] => {
    const [userPasswordReset] = useMutation<UserPasswordResetMutationType>(UserPasswordReset);
    const [sendRegistrationMail] = useMutation<UserInviteMutationType>(SendRegistrationMail);
    const handleUserInvite = useCallback(() => sendRegistrationMail({ variables: { email } }), [email]);
    const handleResetPassword = useCallback(() => userPasswordReset({ variables: { email } }), [email]);

    return [handleResetPassword, handleUserInvite];
};
