import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const usePrivateServiceFormLocalization = () => {
    const eurTitle = useLocalizationText(LocalizationEnum.PrivateServiceEuroIbanTitle);
    const paymentTitle = useLocalizationText(LocalizationEnum.PrivateServicePaymentAccountTitle);
    const currencyTitle = useLocalizationText(LocalizationEnum.PrivateServiceCurrencyTitle);
    const moneyTitle = useLocalizationText(LocalizationEnum.PrivateServiceMoneyTurnoverTitle);
    const accountTitle = useLocalizationText(LocalizationEnum.PrivateServiceReasonAccountTitle);

    return {
        eurTitle,
        paymentTitle,
        currencyTitle,
        moneyTitle,
        accountTitle,
    };
};
