import styled from 'styled-components';

import { White } from '@shared-style/colors';

import { PageContainerWrapper } from '@page/common/common.styles';

export const LegalClientProfileWrapper = styled(PageContainerWrapper)`
    background-color: ${White};
`;

export const LegalClientProfileInnerWrapper = styled.div`
    width: 100%;
    max-width: 704px;
    padding: 32px;
`;
