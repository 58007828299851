import { FC, useEffect } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ButtonTypeEnum } from '@shared-atom/button/button-type.enum';
import { ExternalLink } from '@shared-atom/external-link/external-link';
import { useUploadPresignedUrl } from '@shared-hook/getUploadPresignedUrl';
import { ClickEventArgsType } from '@shared-type/click-event.type';
import { OnEventEmptyType } from '@shared-type/on-event.type';
import { isTrue } from '@shared-util/is-data';

import {
    DocumentUploadedWrapper,
    ButtonContentWrapper,
    DeleteIcon,
    ImagePreview,
    FileName,
} from './document-uploaded.styles';

interface DocumentUploadedInterface {
    filename: string;
    onDelete?: OnEventEmptyType;
    keyId?: string;
}

export const DocumentUploaded: FC<DocumentUploadedInterface> = ({ filename, keyId, onDelete }) => {
    const handleDelete = (event: ClickEventArgsType) => {
        event.preventDefault();
        if (onDelete !== undefined) {
            onDelete();
        }
    };

    const { handleUploadPresignedUrl, isLoading, url } = useUploadPresignedUrl();

    useEffect(() => {
        if (isTrue(keyId)) {
            handleUploadPresignedUrl(keyId as string);
        }
    }, [keyId]);

    return (
        <ExternalLink href={url ?? '#'} isDisabled={isLoading}>
            <DocumentUploadedWrapper>
                {isTrue(url) && <ImagePreview src={url} />}
                <FileName>{filename}</FileName>
                {onDelete !== undefined && (
                    <ButtonTransparent type={ButtonTypeEnum.Button} onClick={handleDelete}>
                        <ButtonContentWrapper>
                            <DeleteIcon />
                        </ButtonContentWrapper>
                    </ButtonTransparent>
                )}
            </DocumentUploadedWrapper>
        </ExternalLink>
    );
};
