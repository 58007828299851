import { Formik } from 'formik';
import { FC } from 'react';

import { LinkButtonPrimary } from '@shared-atom/link/link-button-primary';
import { CustomInputSearch } from '@shared-component/input/input-search/custom-input-search';
import { noop } from '@shared-util/noop';

import { PageEnum } from '@enum/page.enum';
import { ClientsTitle, ClientsHeaderWrapper, SearchWrapper } from '@page/clients/clients-header/clients-header.styles';
import { ClientFilterInterface } from '@page/clients/clients-table/clients-table-filter/clients-table-filter.interface';

export const ClientsHeader: FC<Pick<ClientFilterInterface, 'onSearchChange' | 'filters'>> = ({
    filters: { search },
    onSearchChange,
}) => (
    <ClientsHeaderWrapper>
        <ClientsTitle>Clients</ClientsTitle>
        <SearchWrapper>
            <Formik initialValues={{ search }} onSubmit={noop} enableReinitialize>
                <CustomInputSearch placeholder="Search by email or ID" onChange={onSearchChange} />
            </Formik>
        </SearchWrapper>
        <LinkButtonPrimary to={{ pathname: PageEnum.CreateClient }}>Create client</LinkButtonPrimary>
    </ClientsHeaderWrapper>
);
