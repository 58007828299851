import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Neutral200 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

export const AccountCredentialsWrapper = styled.div`
    padding: 36px 24px;
    ${Flex.Column.Style};
    gap: 12px;
    border-bottom: 1px solid ${Neutral200};
    margin-bottom: 48px;
`;
export const AccountCredentialsTitle = styled.h3`
    padding-bottom: 24px;
    ${Typography.Paragraph16.Neutral900.Bold.Style};
`;
export const AccountCredentialsRow = styled.div`
    ${Flex.Row.Style};
`;
export const AccountCredentialsRowTitle = styled.span`
    ${Typography.Paragraph14.Neutral500.Medium.Style};
    width: 300px;
`;
export const AccountCredentialsRowValue = styled.span`
    ${Typography.Paragraph14.Neutral900.Medium.Style};
`;
export const AccountCredentialsAssignedSuccess = styled.p`
    margin-top: 24px;
`;
