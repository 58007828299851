import { observer } from 'mobx-react-lite';

import { Notification } from '@shared-atom/elpaso-kit/notification';
import { NotificationSizeEnum, NotificationTypeEnum } from '@shared-atom/elpaso-kit/notification/types';
import { AccountsList } from '@shared-component/accounts-list/accounts-list';
import { Account, CompanyActivatedStatus, UserActivatedStatus } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

import { AccountCredentials } from '@page/accounts/accounts-add-form/account-credentials/account-credentials';
import { useAccountIFX } from '@page/accounts/accounts-add-form/account-ifx.hook';
import { PageContainerInnerWrapper, PageContainerWrapper } from '@page/common/common.styles';
import { useAccountSelector } from '@selector/account/use-account.selector';

import { AccountAddForm } from './accounts-add-form/accounts-add-form';

export const AccountsPage = observer(({ userStatus }: { userStatus: UserActivatedStatus | CompanyActivatedStatus }) => {
    const [{ ccAccounts, limitCcAccounts, selectedAccount }, { isLoading }] = useAccountSelector();
    const { isIfxMode, ibanqCredentials, handleAccountChanged, onAssignCredentials } = useAccountIFX();

    return (
        <PageContainerWrapper>
            <PageContainerInnerWrapper>
                <AccountAddForm
                    isIfxMode={isIfxMode}
                    handleAccountChanged={handleAccountChanged}
                    onAssignCredentials={onAssignCredentials}
                />
                {isTrue(ibanqCredentials) && isIfxMode && (
                    <AccountCredentials
                        ibanqUsername={ibanqCredentials?.ibanqUsername as string}
                        ibanqClientId={ibanqCredentials?.ibanqClientId as string}
                        userStatus={userStatus}
                    />
                )}
                <AccountsList
                    isShowMore
                    isLoading={isLoading}
                    ccAccounts={ccAccounts as unknown as Account[]}
                    limitCcAccounts={limitCcAccounts as unknown as Account[]}
                    selectedAccount={selectedAccount as unknown as Account}
                />
                {isTrue(ibanqCredentials) && isIfxMode && (
                    <Notification
                        text="* If you need to change this account credentials, please contact sends tech project manager."
                        size={NotificationSizeEnum.M}
                        type={NotificationTypeEnum.WARNING}
                    />
                )}
            </PageContainerInnerWrapper>
        </PageContainerWrapper>
    );
});
