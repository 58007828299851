import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Mystic, PrimeBlue } from '@shared-style/colors';

export const Price = styled.span`
    margin-left: 4px;
`;

interface TextProps {
    hasBlueDot: boolean;
}

export const TextItem = styled.li<TextProps>`
    position: relative;
    padding: 16px 40px;
    ${Typography.Style}

    ::before {
        content: '';
        visibility: ${({ hasBlueDot }) => (hasBlueDot ? 'visible' : 'hidden')};
        position: absolute;
        left: 24px;
        top: 20px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: ${PrimeBlue};
    }

    &:not(:last-child) {
        box-shadow: 0 1px 0 ${Mystic};
    }
`;
