import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { CompanyActivatedStatus } from '@shared-graphql';
import { UserDocumentsType } from '@shared-model/user-model/user-model-private/user-documents.type';

import { AddressModelType } from './company-address-model/company-address-model.type';
import { CompanyBiggestClientType } from './company-biggest-client/company-biggest-client-model.type';
import { CompanyPersonType } from './company-person-model/company-person-model.type';
import { CompanyPersonRelationType } from './company-persons-model/company-persons-model.type';
import { CompanyRelationModelType } from './company-relation-model/company-relation-model.type';
import { CompanyServiceType } from './company-service-model/company-service-model.type';
import { CompanyStatusModelInstance } from './company-status-model/company-status-model.type';
import { CompanyStatusesType } from './company-statuses-model/company-statuses-model.type';

const companyActivatedStatusValues = $enum(CompanyActivatedStatus).getValues();

export const CompanyModelType = types.model('Company', {
    id: types.string,
    elcoinId: types.maybeNull(types.string),
    companyName: types.string,
    registrationCountry: types.maybeNull(types.string),
    companyNumber: types.maybeNull(types.string),
    companyTaxNumber: types.maybeNull(types.string),
    companySite: types.maybeNull(types.string),
    registrationDate: types.maybeNull(types.Date),
    isConductBusiness: types.boolean,
    isGroupPart: types.boolean,
    isCompanyAddressSame: types.maybeNull(types.boolean),
    industryDescription: types.maybeNull(types.string),
    status: types.optional(types.enumeration(companyActivatedStatusValues), CompanyActivatedStatus.Created),
    statuses: types.array(CompanyStatusesType),
    companyStatus: CompanyStatusModelInstance,
    legalAddress: types.maybeNull(AddressModelType),
    owner: CompanyPersonType,
    postalAddress: types.maybeNull(AddressModelType),
    companyService: types.maybeNull(CompanyServiceType),
    relation: types.maybeNull(CompanyRelationModelType),
    persons: types.map(CompanyPersonRelationType),
    documents: types.array(UserDocumentsType),
    biggestClient: types.maybeNull(CompanyBiggestClientType),
});
