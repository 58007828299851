import { Instance } from 'mobx-state-tree';
import { useEffect } from 'react';

import { CurrenciesModelType } from '@model/currencies-model/currencies-model';
import { useCurrenciesActionsSelector } from '@selector/currencies/use-currencies.actions-selector';
import { useCurrenciesSelector } from '@selector/currencies/use-currencies.selector';

export const useCurrenciesTable = (): [Instance<typeof CurrenciesModelType>, boolean] => {
    const [currencies, isLoading] = useCurrenciesSelector();
    const { loadCurrencies } = useCurrenciesActionsSelector();

    useEffect(() => void loadCurrencies(), []);

    return [currencies, isLoading];
};
