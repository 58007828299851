import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';

export const IDCellWrapper = styled.span`
    ${Typography.Paragraph14.SemiBold.Neutral900.HiddenEllipsis.Style};
`;
export const IDCellContent = styled.span`
    ${Typography.Paragraph14.SemiBold.Neutral900.HiddenEllipsis.Style};
    white-space: nowrap;
    cursor: pointer;
`;
