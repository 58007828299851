import styled from 'styled-components';

import Plus from '@shared-asset/plus.svg?react';
import { Typography } from '@shared-atom/typography/typography';
import { Mystic, DarkGrey, JungleGreen } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

import Checked from './assets/checked.svg?react';

export const ChooseContentView = styled.div`
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 0 1px ${Mystic};
    cursor: pointer;
    min-height: 48px;
    box-sizing: content-box;

    ${Flex.SpaceBetween.VerticalCenter.Style}
`;

export const ChooseTitleView = styled.span`
    margin-left: 16px;
    ${Typography.L.Left.Fiord.Style}

    :only-child {
        margin-left: 0;
    }
`;

export const CheckedIcon = styled(Checked)`
    fill: ${JungleGreen};
`;

export const PlusIcon = styled(Plus)`
    fill: ${DarkGrey};
`;

export const ContentWrapper = styled.div`
    height: 100%;
    ${Flex.VerticalCenter.Style}
`;

export const RightContentWrapper = styled(ContentWrapper)`
    position: relative;
    padding-left: 8px;
`;

export const LastIconWrapper = styled.div`
    height: 100%;
    margin-left: 16px;
    padding: 8px 0 8px 16px;
    box-shadow: -1px 0 0 0 ${Mystic};
    ${Flex.VerticalCenter.Style}
`;

export const ChooseIcon = styled.svg`
    min-width: 48px;
    height: 48px;
`;

export const ImagePreview = styled.img`
    width: 48px;
    height: 48px;
    object-fit: cover;
    box-shadow: 0 0 0 1px ${Mystic};
    margin-right: 8px;
    border-radius: 2px;
`;
