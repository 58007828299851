import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Raven } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

import SortingIcon from '@page/assets/sorting.svg?react';

export const TableHeader = styled.thead`
    flex: 0 0 auto;
    color: ${Raven};
    padding: 6px 0;
`;

export const TableRow = styled.tr``;

export const TableHeaderCell = styled.th`
    padding: 11px 20px;
    ${Flex.VerticalCenter.Style};
    ${Typography.Paragraph12.SemiBold.Neutral500.Style};

    :last-child {
        text-align: end;
    }
`;
export const TableHeaderCellTitle = styled.span`
    margin-right: 12px;
    white-space: nowrap;
`;
export const TableHeaderSortingIcon = styled(SortingIcon)`
    cursor: pointer;
    margin-bottom: -4px;
`;
