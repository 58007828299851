import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
    ButtonBorderedStyle,
    ButtonLandingPrimaryStyle,
    ButtonLandingSecondaryStyle,
    ButtonPrimaryStyle,
} from '@shared-atom/button/button.style';
import { DarkBlue, DarkGrey, WaterLeaf, PrimeBlue, Danube } from '@shared-style/colors'; // NileBlue, BondiBlue,

interface LinkDisableProps {
    isSecondary: boolean;
}

interface LinkProps extends LinkDisableProps {
    hasLinkBehaviour: boolean;
}

export const ItemLink = styled(Link)<LinkProps>`
    height: 100%;
    text-decoration: none;
    color: ${({ isSecondary }: LinkProps) => (isSecondary ? DarkBlue : PrimeBlue)};
`;
/** :hover,
    :focus-visible {
        color: ${({ hasLinkBehaviour }: LinkProps) => !hasLinkBehaviour && 'transparent'};
        text-decoration: ${({ hasLinkBehaviour }: LinkProps) => (hasLinkBehaviour ? 'underline' : 'none')};
    }

    :active {
        text-decoration: none;
        color: ${({ isSecondary }: LinkProps) => (isSecondary ? NileBlue : BondiBlue)};
    } */
export const LinkDisable = styled.span<LinkDisableProps>`
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: ${({ isSecondary }) => (isSecondary ? DarkGrey : WaterLeaf)};
`;

const linkButtonCss = css`
    text-decoration: none;
`;

export const LinkButtonLandingPrimaryStyle = styled(ButtonLandingPrimaryStyle)`
    ${linkButtonCss};
`;

export const LinkButtonLandingSecondaryStyle = styled(ButtonLandingSecondaryStyle)`
    ${linkButtonCss};
`;

export const LinkButtonLandingWhiteStyle = styled(ButtonBorderedStyle)`
    box-shadow: 0 0 2px ${Danube};
    border-radius: 8px;
    color: ${Danube};
    ${linkButtonCss};
`;

export const LinkButtonPrimaryStyle = styled(ButtonPrimaryStyle)`
    ${linkButtonCss};
`;
