import { UserCompanyRelationType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const CompanyRelationLocaleEnum = {
    [UserCompanyRelationType.Owner]: LocalizationEnum.CommonCompanyRelationOwner,
    [UserCompanyRelationType.Ownership]: LocalizationEnum.CommonCompanyRelationShareholder,
    [UserCompanyRelationType.Beneficiary]: LocalizationEnum.CommonCompanyRelationUbo,
    [UserCompanyRelationType.Director]: LocalizationEnum.CommonCompanyRelationDirector,
    [UserCompanyRelationType.Representative]: LocalizationEnum.CommonCompanyRelationDirector,
    [UserCompanyRelationType.UBO]: LocalizationEnum.CommonCompanyRelationUBO,
};
