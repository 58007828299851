import { FC, ReactNode } from 'react';

import { isString } from '@shared-util/is-data';

import { RowActionWrapper, RowParagraph, RowBlock, RowTitle } from './summary-row.styles';

interface RowProps {
    title?: string;
    data?: string | ReactNode;
    children?: ReactNode | ReactNode[];
}

export const Row: FC<RowProps> = ({ title, data, children }) => (
    <>
        {isString(title) ? <RowTitle>{title}</RowTitle> : <RowBlock>{title}</RowBlock>}
        {isString(data) ? <RowParagraph>{data}</RowParagraph> : <RowBlock>{data}</RowBlock>}
        <RowActionWrapper>{children}</RowActionWrapper>
    </>
);
