import { CurrentTabName } from '@component/breadcrumbs/breadcrumb-tabs/current-tab-name';
import { PrivateClientNameTab } from '@component/breadcrumbs/breadcrumb-tabs/private-client-name';
import { OptionsInterface } from '@component/tabs/tabs.interface';
import { PageEnum } from '@enum/page.enum';

export const clientTabsOptions: OptionsInterface[] = [
    {
        title: 'Summary',
        name: PageEnum.PrivateClientSummary,
    },
    {
        title: 'Documents',
        name: PageEnum.PrivateClientDocuments,
    },
    {
        title: 'Archived Documents',
        name: PageEnum.PrivateClientArchivedDocuments,
    },
    {
        title: 'Accounts',
        name: PageEnum.PrivateClientAccounts,
    },
    {
        title: 'Comments',
        name: PageEnum.PrivateClientComments,
    },

    {
        title: 'Pricing',
        name: PageEnum.PrivateClientPricing,
    },

    {
        title: 'Services',
        name: PageEnum.PrivateClientServices,
    },
];

export const breadcrumbList = [
    {
        path: PageEnum.StatusClients,
        name: 'Clients',
    },
    {
        name: <PrivateClientNameTab />,
    },
    {
        name: <CurrentTabName options={clientTabsOptions} />,
    },
];
