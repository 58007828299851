import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';

import { DateFormatMap, DateFormatWithTime } from '@shared-component/input/input-date/input-date-format.helper';
import { useLocalizationStore } from '@shared-hook/localization/use-localization-store.hook';
import { LangKeysEnum } from '@shared-store/store-localization.interface';

export interface DateProps {
    date: Date | string;
    locale?: LangKeysEnum;
    hasTime?: boolean;
}

export const DateComponent = observer(({ date, hasTime = false }: DateProps) => {
    const { lang } = useLocalizationStore();
    const dateMap = hasTime ? DateFormatWithTime : DateFormatMap;

    return <>{format(date instanceof Date ? date : new Date(date), dateMap[lang] ?? '')}</>;
});
