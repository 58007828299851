import { ReactNode, useContext, useMemo } from 'react';
import ReactModal from 'react-modal';

import { ModelContext } from '@shared-component/modal/modal-context';
import { useModal } from '@shared-component/modal/modal.hook';
import { ModalOptionProps } from '@shared-component/modal/modal.options';
import {
    ButtonClose,
    ButtonCloseWrapper,
    CrossIcon,
    getModalStylesContentWidth,
    ModalFullWidth,
    Overlay,
} from '@shared-component/modal/modal.styles';
import { HalfSecond } from '@shared-style/animation';
import { noop } from '@shared-util/noop';

ReactModal.setAppElement(document.getElementById('modal') as HTMLElement);

const CLOSE_TIMEOUT = 1000 * HalfSecond;

export const Modal = ({ children }: { children?: ReactNode | ReactNode[] | JSX.Element }) => {
    const { onClose, removeModalProps, selectedModal } = useContext(ModelContext);

    const { onAfterOpen, onAfterClose, savedSelectedModal, isVisible } = useModal({
        selectedModal,
        onModalAfterClose: removeModalProps,
    });

    const isModalFull = useMemo(
        () => savedSelectedModal !== null && ModalOptionProps[savedSelectedModal]?.isFull === true,
        [savedSelectedModal]
    );

    const modalStyle = useMemo(
        () => (isModalFull ? ModalFullWidth : getModalStylesContentWidth(isVisible, isModalFull)),
        [isModalFull, isVisible]
    );

    const shouldRenderCloseButton = !isModalFull && isVisible;

    const overlayStyle = useMemo(
        () => Overlay(shouldRenderCloseButton, isModalFull),
        [isModalFull, shouldRenderCloseButton]
    );

    return (
        <ReactModal
            closeTimeoutMS={isModalFull ? 0 : CLOSE_TIMEOUT}
            onRequestClose={isModalFull ? noop : onClose}
            onAfterOpen={onAfterOpen}
            onAfterClose={onAfterClose}
            isOpen={selectedModal !== null}
            style={{ overlay: overlayStyle, content: modalStyle }}
        >
            {shouldRenderCloseButton && (
                <ButtonCloseWrapper>
                    <ButtonClose onClick={onClose}>
                        <CrossIcon />
                    </ButtonClose>
                </ButtonCloseWrapper>
            )}
            {children}
        </ReactModal>
    );
};
