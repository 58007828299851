import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { SendPhoneCode } from '@shared-graphql';
import { OnEventType } from '@shared-type/on-event.type';

export const useSendPhoneCode = (): [OnEventType<string>, boolean] => {
    const [sendPhoneCode, { data }] = useMutation(SendPhoneCode);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const handleSendPhoneCode = (phone: string, token: string) => sendPhoneCode({ variables: { phone, token } });

    const handleReCaptchaVerify = useCallback(
        async (phone: string) => {
            if (!executeRecaptcha) {
                console.log('Execute recaptcha not yet available');
                return;
            }

            const verifyToken = await executeRecaptcha('sendPhoneCode');
            handleSendPhoneCode(phone, verifyToken);
        },
        [executeRecaptcha]
    );

    return [handleReCaptchaVerify, data?.sendPhoneCode.status ?? false];
};
