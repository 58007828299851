import { useCallback } from 'react';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import {
    ClientsTableRefreshIcon,
    StateControlButtonsTitle,
    StateControlButtonsWrapper,
} from '@shared-component/table/state-control-buttons/state-control-buttons.style';
import { noop } from '@shared-util/noop';

interface StateControlButtonsInterface {
    onResetFilters: typeof noop;
}

export const StateControlButtons = ({ onResetFilters }: StateControlButtonsInterface) => {
    const onReloadPage = useCallback(() => window.location.reload(), []);

    return (
        <StateControlButtonsWrapper>
            <ButtonTransparent onClick={onReloadPage}>
                <ClientsTableRefreshIcon />
                <StateControlButtonsTitle>Refresh</StateControlButtonsTitle>
            </ButtonTransparent>
            <ButtonTransparent onClick={onResetFilters}>
                <StateControlButtonsTitle>Clear filter</StateControlButtonsTitle>
            </ButtonTransparent>
        </StateControlButtonsWrapper>
    );
};
