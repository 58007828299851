import { Instance } from 'mobx-state-tree';

import { AccountModelType } from '@model/account-model/account-model.type';
import { LoadingInterface } from '@selector/selectors.interface';
import { useStore } from '@store/store-context';

export const useAccountSelector = (): [Instance<typeof AccountModelType>, LoadingInterface, string] => {
    const { account, loading, error } = useStore().account;

    return [account, loading, error];
};
