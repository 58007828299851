import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';

import { AdminGetIbanqCallbackUrl, Query } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

export const useIfxCallbackUrl = ({ clientId }: { clientId: string }) => {
    const [callbackURL, setCallbackURL] = useState('');
    const [getIbanqCallbackUrl, { data }] = useLazyQuery<Pick<Query, 'adminGetIbanqCallbackUrl'>>(
        AdminGetIbanqCallbackUrl,
        { variables: { clientId } }
    );

    useEffect(() => {
        if (isTrue(data?.adminGetIbanqCallbackUrl?.url)) {
            setCallbackURL(data?.adminGetIbanqCallbackUrl?.url as string);
        }
    }, [data]);

    return { callbackURL, getIbanqCallbackUrl };
};
