import { CompanyStatus, User, UserAnswer, UserLegalStatus } from '@shared-graphql';

import { InitialUserProfileInterface } from './user-profile-model.initial';

export interface ModelUserInterface extends Omit<User, 'profile' | 'legalStatus' | 'companyStatus' | 'companies'> {
    profile: InitialUserProfileInterface;
    legalStatus: UserLegalStatus & CompanyStatus;
    fullName: string | null;
}

interface InitialModelUserInterface {
    elements: Record<string, ModelUserInterface>;
    totalCount: number;
    selectedId: null | ModelUserInterface;
}

export const initialUserPrivateStatus = {
    hasProfile: false,
    hasAddress: false,
    hasService: false,
    hasDeclaration: false,
    hasDocument: false,
    hasAccept: false,
};

export const initialUserLegalStatus = {
    hasCompany: false,
    hasAddress: false,
    hasIndustry: false,
    hasPersons: false,
};

export const initialUserModel: Omit<InitialModelUserInterface, 'fullName'> = {
    elements: {},
    totalCount: 0,
    selectedId: null,
};

export const initialUsersResponse: UserAnswer = {
    elements: [],
    totalCount: initialUserModel.totalCount,
};
