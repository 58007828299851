import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { ButtonProps } from '@shared-atom/button/button.props';
import {
    LinkWater,
    BondiBlue,
    Dark,
    Mystic,
    PrimeBlue,
    White,
    WaterLeaf,
    Fiord,
    Danube,
    Red,
} from '@shared-style/colors';

export const ButtonStyle = styled.button`
    border: 0;
    outline: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    padding: 10px 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border-radius: 8px;

    :focus,
    :active {
        outline: none;
    }

    :disabled {
        cursor: default;
    }

    ${({ isFull = false }: ButtonProps) => isFull && 'width: 100%;'}

    ${({ isSmall = false }: ButtonProps) => isSmall && 'height: 40px; padding: 0 24px;'}

    ${({ isLarge = false }: ButtonProps) => isLarge && 'height: 56px; font-size: 16px;'}
    ${({ isDisabledPointer = false }: ButtonProps) =>
        isDisabledPointer &&
        css`
            pointer-events: none;
        `}
`;
export const ButtonGreenStyle = styled(ButtonStyle)`
    background-color: #00997a;
    border: 1px solid #00997a;
    color: ${White};
    border-radius: 8px;
    :not(:disabled):hover {
        background: #007d64;
        border: 1px solid #007d64;
    }

    :disabled {
        background: #007d64;
        opacity: 0.5;
    }
`;
export const ButtonPrimaryStyle = styled(ButtonStyle)`
    background-color: ${PrimeBlue};
    color: ${White};

    :not(:disabled):hover,
    :not(:disabled):focus-visible {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 2px 8px rgba(16, 120, 129, 0.24);
    }

    :active {
        box-shadow: none;
        background-color: ${BondiBlue};
    }

    :disabled {
        background-color: ${rgba(PrimeBlue, 0.4)};
        color: ${rgba(White, 0.8)};
    }
`;
export const ButtonRejectStyle = styled(ButtonStyle)`
    background-color: ${Red};
    color: ${White};

    :not(:disabled):hover,
    :not(:disabled):focus-visible {
        box-shadow: 0 1px 4px rgba(255, 0, 0, 0.12);
    }

    :active {
        box-shadow: none;
        background-color: ${BondiBlue};
    }

    :disabled {
        background-color: ${rgba(Red, 0.4)};
        color: ${rgba(White, 0.8)};
    }
`;

export const ButtonSecondaryStyle = styled(ButtonStyle)`
    background-color: ${LinkWater};
    color: ${Dark};

    :not(:disabled):hover,
    :not(:disabled):focus-visible {
        box-shadow: 0 2px 4px rgba(20, 48, 74, 0.06);
    }

    :active {
        box-shadow: none;
        background-color: ${Mystic};
    }

    :disabled {
        background-color: ${rgba(LinkWater, 0.4)};
    }
`;

export const ButtonTransparentStyle = styled(ButtonStyle)`
    background-color: transparent;
    padding: 0;
    height: 100%;
    border-radius: 0;

    :disabled {
        opacity: 0.4;
    }
`;

export const ButtonLinkStyle = styled(ButtonStyle)`
    background-color: transparent;
    padding: 0;
    height: 100%;
    color: ${PrimeBlue};
    font-weight: 500;
    cursor: pointer;

    :hover,
    :focus-visible {
        text-decoration: underline;
    }

    :disabled {
        color: ${WaterLeaf};

        :hover {
            text-decoration: none;
        }
    }
`;

export const ButtonBorderedStyle = styled(ButtonTransparentStyle)`
    color: ${Fiord};
    box-shadow: 0 0 2px ${Mystic};
    border-radius: 20px;
    padding: 0 32px;
    min-height: 48px;

    :not(:disabled) {
        :hover,
        :focus-visible {
            box-shadow: 0 0 2px ${PrimeBlue};
        }
        :active {
            box-shadow: none;
            background-color: ${Mystic};
        }
    }

    :disabled {
        opacity: 0.4;
    }
`;

export const ButtonLandingSecondaryStyle = styled(ButtonStyle)`
    background-color: ${Danube};
    color: ${White};
    border-radius: 8px;

    :not(:disabled):hover,
    :not(:disabled):focus {
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.12), 0 2px 8px rgba(16, 120, 129, 0.24);
    }

    :active {
        box-shadow: none;
        background-color: ${rgba(Danube, 0.8)};
    }

    :disabled {
        background-color: ${rgba(Danube, 0.4)};
        color: ${rgba(White, 0.8)};
    }
`;

export const ButtonLandingPrimaryStyle = styled(ButtonPrimaryStyle)`
    border-radius: 8px;
`;
