import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Flex } from '@shared-style/flex';

export const ClientServicesHeadingWrapper = styled.div`
    ${Flex.VerticalCenter.SpaceBetween.Style};
    padding: 0 20px;
    margin-bottom: 31px;
`;
export const ClientServicesTitle = styled.div`
    ${Typography.Paragraph16.Neutral900.Style}
`;
