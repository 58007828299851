import { parse } from 'date-fns';
import { Formik } from 'formik';
import { useMemo } from 'react';

import { StateControlButtons } from '@shared-component/table/state-control-buttons/state-control-buttons';
import { TableFiltersWrapper } from '@shared-component/table/table.styles';
import { OnEventType } from '@shared-type/on-event.type';
import { isTrue } from '@shared-util/is-data';
import { noop } from '@shared-util/noop';

import { FilterByOperationType } from '@page/transactions/transactions-table/transactions-table-filter/filter-by-operation-type/filter-by-operation-type';
import { TransactionsTableFilterForm } from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter-form/transactions-table-filter-form';
import { FilterFormInterface } from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter-form/transactions-table-filter-form.interface';
import { TopLevelOfFilters } from '@page/transactions/transactions-table/transactions-table-filter/transactions-table-filter.styles';
import { OperationTypeEnum } from '@page/transactions/transactions-table/transactions-table.enum';

interface TransactionsTableFilterInterface extends FilterFormInterface {
    filters: Record<string, any>;
    onResetFilters: typeof noop;
    onOperationTypeChange: OnEventType<OperationTypeEnum | string>;
}

export const TransactionsTableFilter = ({
    filters: { status, elcoinStatus, paymentType, createdFrom, createdTo, operationType },
    onStatusChange,
    onElcoinStatusChange,
    onPaymentTypeChange,
    onCreatedFromChange,
    onCreatedToChange,
    onOperationTypeChange,
    onResetFilters,
    transactionsCount,
}: TransactionsTableFilterInterface) => {
    const initialValues = useMemo(
        () => ({
            // status,
            // paymentType,
            elcoinStatus,
            createdFrom: isTrue(createdFrom) ? parse(createdFrom, 'dd-MM-yyyy', new Date()) : '',
            createdTo: isTrue(createdTo) ? parse(createdTo, 'dd-MM-yyyy', new Date()) : '',
        }),
        [elcoinStatus, status, paymentType, createdFrom, createdTo]
    );

    return (
        <>
            <TopLevelOfFilters>
                <FilterByOperationType
                    operationTypeFilter={operationType}
                    onOperationTypeChange={onOperationTypeChange}
                />
                <StateControlButtons onResetFilters={onResetFilters} />
            </TopLevelOfFilters>
            <TableFiltersWrapper>
                <Formik initialValues={initialValues} onSubmit={noop} enableReinitialize>
                    <TransactionsTableFilterForm
                        onStatusChange={onStatusChange}
                        onPaymentTypeChange={onPaymentTypeChange}
                        onCreatedFromChange={onCreatedFromChange}
                        onCreatedToChange={onCreatedToChange}
                        onElcoinStatusChange={onElcoinStatusChange}
                        transactionsCount={transactionsCount}
                    />
                </Formik>
            </TableFiltersWrapper>
        </>
    );
};
