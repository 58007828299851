import { FC, SVGProps } from 'react';
import { StyledComponent } from 'styled-components';

import { ButtonTransparent } from '@shared-atom/button/button-transparent';
import { ClickEventType } from '@shared-type/click-event.type';
import { noop } from '@shared-util/noop';

import {
    CheckedIcon,
    ChooseContentView,
    ChooseTitleView,
    ContentWrapper,
    LastIconWrapper,
    PlusIcon,
    RightContentWrapper,
    ChooseIcon,
    ImagePreview,
} from './choose.styles';

type IconType = FC<SVGProps<SVGSVGElement>>;

export interface ChooseInterface {
    onClick?: ClickEventType;
    isChosen?: boolean;
    isDisabled?: boolean;
    icon?: IconType | StyledComponent<IconType, Record<string, unknown>>;
    path?: string;
    children?: React.ReactNode;
}

export const Choose: FC<ChooseInterface> = ({
    isChosen = false,
    isDisabled = false,
    onClick = noop,
    icon,
    path,
    children,
    ...props
}) => (
    <ButtonTransparent isFull onClick={onClick} {...props} disabled={isDisabled}>
        <ChooseContentView>
            <ContentWrapper>
                {icon !== undefined && <ChooseIcon as={icon} />}
                {path !== undefined && <ImagePreview src={path} />}
                <ChooseTitleView>{children}</ChooseTitleView>
            </ContentWrapper>
            <RightContentWrapper>
                {isChosen && <CheckedIcon />}
                <LastIconWrapper>
                    <PlusIcon />
                </LastIconWrapper>
            </RightContentWrapper>
        </ChooseContentView>
    </ButtonTransparent>
);
