import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';

export const useAttachFileLocalization = () => {
    const attachFileTitle = useLocalizationText(LocalizationEnum.PaymentFormDetailsStepAttachFileTitle);
    const attachFileDescription = useLocalizationText(LocalizationEnum.PaymentFormDetailsStepAttachFileDescription);

    return {
        attachFileTitle,
        attachFileDescription,
    };
};
