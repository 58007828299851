import { memo, useContext } from 'react';

import { Modal } from '@shared-component/modal/modal';
import { ModelContext } from '@shared-component/modal/modal-context';
import { ModalEnum } from '@shared-component/modal/modal.enum';
import { UserType } from '@shared-graphql';

import { AddServiceRequiredFieldsModal } from '@component/modal/modals/add-service-required-fields/add-service-required-fields';
import { AddServiceModal } from '@component/modal/modals/add-service/add-service';
import { CreateIFXCredentialsModal } from '@component/modal/modals/create-ifx-credentials/create-ifx-credentials.modal';
import { RegistrationLegalPersonLegalModal } from '@component/modal/modals/registration-legal-person/registration-legal-person-legal/registration-legal-person-legal.modal';
import { RegistrationLegalPersonPrivateModal } from '@component/modal/modals/registration-legal-person/registration-legal-person-private/registration-legal-person-private.modal';
import { RejectPaymentModal } from '@component/modal/modals/reject-payment/reject-payment.modal';
import { RequestStatementModal } from '@component/modal/modals/request-statement/request-statement';

export const AdminModal = memo(() => {
    const { selectedModal } = useContext(ModelContext);

    return (
        <Modal>
            {selectedModal === ModalEnum.RegistrationLegalPrivatePersonModal && <RegistrationLegalPersonPrivateModal />}
            {selectedModal === ModalEnum.RegistrationLegalPersonLegalModal && <RegistrationLegalPersonLegalModal />}
            {selectedModal === ModalEnum.RequestStatementModal && <RequestStatementModal userType={UserType.Legal} />}
            {selectedModal === ModalEnum.PrivateRequestStatementModal && (
                <RequestStatementModal userType={UserType.Private} />
            )}
            {selectedModal === ModalEnum.CreateIFXCredentialsModal && <CreateIFXCredentialsModal />}
            {selectedModal === ModalEnum.RejectPaymentModal && <RejectPaymentModal />}
            {selectedModal === ModalEnum.AddServiceModal && <AddServiceModal />}
            {selectedModal === ModalEnum.AddServiceRequiredFieldsModal && <AddServiceRequiredFieldsModal />}
        </Modal>
    );
});
