import styled from 'styled-components';

import { Black, DarkGrey, Mystic, Whisper } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';

import Delete from './assets/delete.svg?react';

export const DocumentUploadedWrapper = styled.div`
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 0 0 1px ${Mystic};
    ${Flex.Row.SpaceBetween.VerticalCenter.Style}
`;

export const FileName = styled.span`
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 8px;
    color: ${Black};
    @media screen and (max-device-width: 480px) {
        max-width: 220px;
    }
`;

export const ButtonContentWrapper = styled.div`
    display: flex;
    padding: 8px;
    box-shadow: 0 0 0 1px ${Mystic};
    border-radius: 8px;

    :hover {
        background-color: ${Whisper};
    }
`;

export const ImagePreview = styled.img`
    width: 56px;
    height: 56px;
    object-fit: cover;
    box-shadow: 0 0 0 1px ${Mystic};
    margin-right: 8px;
    border-radius: 2px;
`;

export const DeleteIcon = styled(Delete)`
    fill: ${DarkGrey};
`;
