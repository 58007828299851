import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';

import { Button } from '@shared-component/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-component/button/types';
import { Scroll } from '@shared-component/scroll/scroll';
import { StatementFormInitial } from '@shared-form/statement-form/statement-form.initial';
import { StatementFormValidation } from '@shared-form/statement-form/statement-form.validation';
import { UserType } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PrivateRequestStatementAccounts } from '@component/modal/modals/request-statement/private-request-statement-accounts/private-request-statement-accounts';
import { RequestStatementAccounts } from '@component/modal/modals/request-statement/request-statement-accounts/request-statement-accounts';
import { useRequestStatement } from '@component/modal/modals/request-statement/request-statement.hook';
import {
    ButtonSubmitWrapper,
    RequestStatementFormWrapper,
    RequestStatementWrapper,
} from '@component/modal/modals/request-statement/request-statement.styles';

export const RequestStatementModal = observer(({ userType }: { userType: UserType }) => {
    const { handleSubmit, isLoading } = useRequestStatement();

    return (
        <RequestStatementWrapper>
            <Scroll>
                <RequestStatementFormWrapper>
                    <Formik
                        initialValues={StatementFormInitial}
                        validationSchema={StatementFormValidation}
                        onSubmit={handleSubmit}
                        validateOnBlur={false}
                        validateOnChange={false}
                        enableReinitialize
                    >
                        <Form>
                            {userType === UserType.Private ? (
                                <PrivateRequestStatementAccounts />
                            ) : (
                                <RequestStatementAccounts />
                            )}
                            <ButtonSubmitWrapper>
                                <Button
                                    title={LocalizationEnum.RequestStatementModalSubmitButtonTitle}
                                    size={ButtonSizeEnum.M}
                                    type={ButtonTypeEnum.COLOR}
                                    isLoading={isLoading}
                                    isSubmit
                                />
                            </ButtonSubmitWrapper>
                        </Form>
                    </Formik>
                </RequestStatementFormWrapper>
            </Scroll>
        </RequestStatementWrapper>
    );
});
