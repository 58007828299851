import { boolean, object, string } from 'yup';

import { UserCompanyRelationType } from '@shared-graphql';
import { emailValidation, percentValidation, phoneValidation } from '@shared-util/validation';

import { RegistrationLegalPersonPrivateProfileFormEnum } from './registration-legal-person-private-profile-form.enum';

export const RegistrationLegalPersonPrivateProfileFormValidation = (isPhoneRequired?: boolean) =>
    object().shape({
        [RegistrationLegalPersonPrivateProfileFormEnum.BeneficiaryPercent]: string().when(
            RegistrationLegalPersonPrivateProfileFormEnum.UserRelation,
            {
                is: (userRelation: UserCompanyRelationType[]) =>
                    userRelation.includes(UserCompanyRelationType.Beneficiary),
                then: percentValidation(),
                otherwise: string(),
            }
        ),
        [RegistrationLegalPersonPrivateProfileFormEnum.OwnershipPercent]: string().when(
            RegistrationLegalPersonPrivateProfileFormEnum.UserRelation,
            {
                is: (userRelation: UserCompanyRelationType[]) =>
                    userRelation.includes(UserCompanyRelationType.Ownership),
                then: string()
                    .required()
                    .test('is-min', 'Value must be more then 0', value => parseInt(value ?? '', 10) >= 0)
                    .test('is-max', 'Value must be less then 100', value => parseInt(value ?? '', 10) <= 10000),
                otherwise: string(),
            }
        ),
        [RegistrationLegalPersonPrivateProfileFormEnum.PowerOfAttorney]: boolean().when(
            RegistrationLegalPersonPrivateProfileFormEnum.UserRelation,
            {
                is: (userRelation: UserCompanyRelationType[]) =>
                    userRelation.includes(UserCompanyRelationType.Director),
                then: boolean(),
                otherwise: boolean(),
            }
        ),
        [RegistrationLegalPersonPrivateProfileFormEnum.UserRole]: string().when(
            RegistrationLegalPersonPrivateProfileFormEnum.UserCreate,
            {
                is: (isUserCreate: boolean) => isUserCreate,
                then: string().required(),
                otherwise: string().nullable(),
            }
        ),
        [RegistrationLegalPersonPrivateProfileFormEnum.Email]: string().when(
            RegistrationLegalPersonPrivateProfileFormEnum.UserCreate,
            {
                is: (isUserCreate: boolean) => isUserCreate,
                then: emailValidation(),
                otherwise: string().nullable(),
            }
        ),
        [RegistrationLegalPersonPrivateProfileFormEnum.Phone]: string().when(
            RegistrationLegalPersonPrivateProfileFormEnum.UserCreate,
            {
                is: (isUserCreate: boolean) => isUserCreate,
                then: phoneValidation(isPhoneRequired),
                otherwise: string().nullable(),
            }
        ),
    });
